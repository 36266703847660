
import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

export const downloadZip = async (fileList) => {
  try {
    const response = await fetch(`${BASE_URL}/support/notification/download-zip`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(fileList),
    });

    if (!response.ok) {
      throw new Error('Failed to download zip file');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'files.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return 'SUCCESS';
  } catch (error) {
    return error.message.replace('Error: ', '');
  }
};