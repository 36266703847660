import React, {useEffect, useState} from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import Snackbar from 'components/component/BasicSnackbar';
import {
  Box,
  Typography,
  Link,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';

import {useNavigate} from "react-router-dom";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import PopAddMultilingualInformation from "./PopAddMultilingualInformation";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));
const imagePrefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;

// DetailItem 컴포넌트 생성
const DetailItem = ({ label, xs, value, maxThWidth = false }) => (
  <Grid item xs={xs} className='list-wrap'>
    <Grid display='flex' item xs={12} alignItems='center'>
      {maxThWidth === true ? (
        <Grid item xs={3}>
          <Typography component='span' className='label'>
            {label}
          </Typography>
        </Grid>
      ) : (
        <Typography component='span' className='label'>
          {label}
        </Typography>
      )}
      <div className='value-wrap'>
        <div className='value'>
          <Typography component='span' className='text'>
            {value}
          </Typography>
        </div>
      </div>
    </Grid>
  </Grid>
);

const tabs = [
  { label: 'Basic Information', badgeType: '' },
  { label: 'Multilingual Information', badgeType: '' },
];

// 메인 컨텐츠
function VODGenreDetail(props) {
  const { darkMode, message } = props;
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Code Management',
    normalMenu: ['VOD Genre Detail'],
  };

  // Tab
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const navigate = useNavigate();

  const navigateBack = (e) => {
    e.preventDefault();
    navigate('/CodeManagement', {
      replace: true, state:
          { move: true,
            targetComponent: 'CodeManagement',
            message:
                {
                  managementType: 'VOD-Genre',
                }
          }
    })
  }

  const [genreDetail, setGenreDetail] = useState({});
  const [imgUrl, setImgUrl] = useState('');
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [multiLanguageList, setMultiLanguageList] = useState([]);
  const [addMultiLangOpen, setAddMultiLangOpen] = useState(false);

  useEffect(() => {
    if (message && message.genreCode) {
      fetch(`${BASE_URL}/code-management/vod-genre/${message.genreCode}`, {
        method: 'GET',
        headers: getHeaders()
      })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          setGenreDetail(body.data);
        }
      })
    }
  }, [message]);

  const refresh = () => {
    if (genreDetail.genreCode) {
      fetch(`${BASE_URL}/code-management/vod-genre/${genreDetail.genreCode}`, {
        method: 'GET',
        headers: getHeaders()
      })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          setGenreDetail(body.data);
        }
      })
    }
  }

  useEffect(() => {
    if (genreDetail && genreDetail.genreImage) {
      const imgUrl = imagePrefix + genreDetail.genreImage;
      setImgUrl(imgUrl);
      const img = new Image();
      img.src = imgUrl;
      img.onload = () => {
        setImgWidth(img.width);
        setImgHeight(img.height);
      }
    }
    // multilingual genre name 양쪽으로 표기하기 위함
    if (genreDetail.multiLanguageList && genreDetail.multiLanguageList.length > 0) {
      const multiLanguageList = genreDetail.multiLanguageList;
      const mid = Math.ceil(multiLanguageList.length / 2);
      const leftHalf = multiLanguageList.slice(0, mid);
      const rightHalf = multiLanguageList.slice(mid, multiLanguageList.length);
      setMultiLanguageList([leftHalf, rightHalf]);
    }
  }, [genreDetail]);

  return (
      <Box display='flex' className='wrapper'>
        <Box className='main-container' component='main'>
          <div className='top-title-container'>
            <div className='top-title-box'>
              <div className='title-area'>
                <Link className='title link' href='' onClick={navigateBack}>
                  VOD Genre Detail
                </Link>
              </div>
              <div className='bread-area'>
                <BreadCrumb optionMenu={optionMenu}/>
              </div>
            </div>
          </div>

          <Box className='content-wrap ' component='div'>
            <div className='tab-container'>
              <CustomTabs tabStyle='type-01' value={tabValue}
                          handleChange={handleTabChange} tabs={tabs}/>

              {tabValue === 0 && (
                <Button
                  color='primary'
                  type='box'
                  size='medium'
                  label='Save'
                  isIcon={false}
                  autosize
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  }}
                />
              )}
              {tabValue === 1 && (
                <Button
                  color='primary'
                  type='box'
                  size='medium'
                  label='Add'
                  isIcon={false}
                  autosize
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  }}
                  onClick={() => setAddMultiLangOpen(true)}
                />
              )}
              {/* Basic Information */}
              {tabValue === 0 && (
                <div className='tab-panel'>
                  <div className='panel-wrap'>
                    <Box className='white-box white-box-wrap'>
                      <Box className='box-content'>
                        <Box className='content-inner-wrap no-page-number'>
                          <Box className='detail-list-container'>
                            <Grid container>
                              <DetailItem label='Genre Name' className='' value={genreDetail.genreName} xs={12}/>
                              <DetailItem xs={12} label='Genre Code' className='' value={genreDetail.genreCode}/>
                              <Grid xs={8} item alignItems='center' className='list-wrap'>
                                <Box className='image-value-area'>
                                  <Typography component='span' className='label'>
                                    Genre Image
                                  </Typography>

                                  <Box className='value no-border' maxWidth={627}>
                                    <Box className='image-wrap'>
                                      <CustomImage src={imagePrefix + genreDetail.genreImage}
                                                   fit={true}
                                                   wrapHeight={202}
                                                   imgWidth='500px'
                                                   imgHeight='200px'
                                                   alt='' rounded/>
                                    </Box>
                                    <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
                                      <Box>
                                        <Typography className='img-size'>
                                          Recommended size : <strong>560px X 560px</strong>
                                        </Typography>
                                        <Typography className='img-size'>
                                          Actual size : {imgWidth}px X {imgHeight}px
                                        </Typography>
                                      </Box>
                                      <Box display='flex' gap={1}>
                                        {/* Image URL Button */}
                                        <Button
                                            color='tertiary'
                                            type='outline'
                                            size='small'
                                            label='Image URL'
                                            isIcon={true}
                                            Icon={BtnTeritaryOutlineRight}
                                            iconPos='right'
                                            onClick={() => window.open(imgUrl)}
                                        />

                                        {/* Upload file Button */}
                                        <Button color='tertiary'
                                                type='outline' size='small'
                                                label='Upload file'
                                                isIcon={false} autosize/>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <DetailItem xs={12} label='Image URL'
                                          className='' value={imagePrefix + genreDetail.genreImage}/>
                              <DetailItem xs={12} label='Last Update'
                                          className=''
                                          value={genreDetail.lastChgDate}/>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </div>
              )}
              {/* Multilingual Information */}
              {tabValue === 1 && (
                <div className='tab-panel'>
                  <div className='panel-wrap'>
                    <Box display='flex' gap={2} height={'100%'}>
                      {multiLanguageList.map((multiLangList, index) => (
                        <Box width={'100%'} key={index}>
                          <Box className='white-box white-box-wrap'>
                            <Box className='box-content'>
                              <Box className='content-inner-wrap no-page-number'>
                                {/* Table */}
                                <CustomTableContainer className='table-container'>
                                  <Table className='custom-table' aria-label='table' stickyHeader>
                                    {/* table Head */}
                                    <TableHead className='thead'>
                                      <TableRow className='tr'>
                                        <TableCell key={'language'} className='th' align={'left'} sx={{width: '40%'}}>
                                          <TableSortLabel active={orderBy === 'language'}
                                                          direction={orderBy === 'language' ? order : 'desc'}
                                                          onClick={() => handleRequestSort('language')}
                                          >
                                            Language
                                          </TableSortLabel>
                                        </TableCell>
                                        <TableCell key={'value'} className='th' align={'left'} sx={{width: 'auto'}}>
                                          <TableSortLabel active={orderBy === 'value'}
                                                          direction={orderBy === 'value' ? order : 'desc'}
                                                          onClick={() => handleRequestSort('value')}
                                          >
                                            Genre Name
                                          </TableSortLabel>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    {/* table Body */}
                                    <TableBody className='tbody'>
                                      {sortRows([...multiLangList], orderBy, order).map((td, index) => (
                                        <TableRow className='tr' key={td.id || index}>
                                          <TableCell key={'language'} className='td' align={'left'}>
                                            {td[`language`]}
                                          </TableCell>
                                          <TableCell key={'value'} className='td' align={'left'}>
                                            {td[`value`]}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </CustomTableContainer>
                                {/* // Table */}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Box>

        {/*
        Toast Pop-Up
        - severity: default, error, warning, info, success
      */}
        <Snackbar severity='success' message='success' open={false}
                  duration={1000} bottom={50} darkMode={darkMode}/>
        {addMultiLangOpen && (
            <PopAddMultilingualInformation onClose={() => setAddMultiLangOpen(false)}
                                           multiLangList={genreDetail.multiLanguageList}
                                           genreCode={genreDetail.genreCode}
                                           afterSave={refresh}/>
        )}
      </Box>
  );
}

export default VODGenreDetail;
