import React, { useEffect, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import { Grid, Box } from '@mui/material';

// data
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";

// 메인 컨텐츠
function PopAddAccount(props) {
  const { roles, cps, open, close, callbackClose } = props;
  const [selectedCp, setSelectedCp] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [reEnterInputPassword, setReEnterInputPassword] = useState('');
  const [inputId, setInputId] = useState('');

  const handleClose = () => {
    setSelectedCp('');
    setSelectedRole('');
    setInputPassword('');
    setReEnterInputPassword('');
    setInputId('');
    close();
  };

  const addAccount = () => {
    let body = {
      "role": selectedRole,
      "cpCode": selectedCp,
      "id": inputId,
      "password": inputPassword
    }
    fetch(`${BASE_URL}/account`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        callbackClose(false);
        handleClose();
      } else {
      }
    })
  };

  useEffect(() => {
    if (roles && roles.length > 0 && roles[0]) {
      setSelectedRole(roles[0].value);
    }
  }, [roles]);

  useEffect(() => {
    if(selectedRole === 'ROLE_CP' && selectedCp === '' && cps && cps.length > 0 && cps[0]) {
      setSelectedCp(cps[0].value);
    }
    // eslint-disable-next-line
  }, [selectedRole]);



  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      disabled: inputPassword.length < 6 || reEnterInputPassword !== inputPassword || inputId === '',
      onClick: addAccount
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={open}
        closeX={true}
        title='Add Admin' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-addChannel'
        contentTextProps={
          <Grid container xs={12}>
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='admin-Role'
                  size='large'
                  shrink={true}
                  label='Role'
                  required={true}
                  selectOptions={roles}
                  selected={selectedRole}
                  onChange={setSelectedRole}
                  disabled={false}
                  placeholder=''
                />
              </Box>
            </Grid>

            {selectedRole === 'ROLE_CP' && (
                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box'>
                    <SelectBox
                        id='admin-Code'
                        size='large'
                        shrink={true}
                        label='CP Code'
                        required={true}
                        selectOptions={cps}
                        selected={selectedCp}
                        onChange={setSelectedCp}
                        disabled={false}
                        placeholder=''
                    />
                  </Box>
                </Grid>
            )}

            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='outlined'
                  size='large'
                  placeholder=''
                  defaultValue=''
                  label='ID'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={inputId}
                  onChange={e => {
                    setInputId(e.target.value);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box component='div' className='field-box'>
                <TextField
                  type='password'
                  margin='normal'
                  size='large'
                  required
                  fullWidth
                  placeholder=''
                  label='Password'
                  id='password'
                  error={false}
                  helperText='At least 6 charactor'
                  InputLabelProps={{ shrink: true }}
                  value={inputPassword}
                  onChange={e => {
                    setInputPassword(e.target.value);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} mt={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='password'
                  margin='normal'
                  size='large'
                  required
                  fullWidth
                  placeholder=''
                  name=''
                  label='Re-enter Password'
                  id='passwordReEnter'
                  error={reEnterInputPassword !== inputPassword}
                  InputLabelProps={{ shrink: true }}
                  value={reEnterInputPassword}
                  onChange={e => {
                    setReEnterInputPassword(e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopAddAccount;
