import React, {useEffect, useState} from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextLink from 'components/component/BasicTextLink';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import SearchField from 'components/component/BasicSearchField';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { styled } from '@mui/material/styles';
import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import Dialog from "components/component/BasicDialog";
import {useNavigate} from "react-router-dom";
// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function AccountPermissions() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayRoleCodes].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayRoleCodes([...sortedData])
  };

  const [roleCodes, setRoleCodes] = useState([]);
  const [displayRoleCodes, setDisplayRoleCodes] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [checkedRoles, setCheckedRoles] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);

  const navigate = useNavigate();

  const navigateAccountPermissionsAdd = () => {
    navigate('/CodeManagement', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'AccountPermissionsAdd',
        message: {
          allRole: roleCodes,
        }
      }
    })
  }

  const navigateAccountPermissionsEdit = (role) => {
    navigate('/CodeManagement', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'AccountPermissionsEdit',
        message: {
          role: role,
          allRole: roleCodes,
        }
      }
    })
  }

  const getRoles = () => {
    fetch(`${BASE_URL}/code-management/role`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setRoleCodes(body.data);
        setDisplayRoleCodes(body.data);
      }
    })
  }

  useEffect(() => {
    getRoles()
  }, []);

  useEffect(() => {
    setDisplayRoleCodes(roleCodes.filter(
        (item) => item.roleCode.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.roleName.toLowerCase().includes(searchTextInput.toLowerCase())));
    setPage(1);
    // eslint-disable-next-line
  }, [searchTextInput]);

  const onClickCheckBox = (e) => {
    let checkedList = [...checkedRoles];
    if (e.target.checked) {
      checkedList.push(e.target.value);
    } else {
      checkedList = checkedList.filter((item) => item !== e.target.value);
    }
    if (displayRoleCodes.length === checkedList.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setCheckedRoles(checkedList);
  }

  useEffect(() => {
    if (displayRoleCodes.length === 0) {
      setAllChecked(false);
    } else if (displayRoleCodes.length === checkedRoles.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }

  }, [displayRoleCodes, checkedRoles]);

  useEffect(() => {
    setCheckedRoles([...checkedRoles.filter(roleCode => displayRoleCodes.map(item => item.roleCode).includes(roleCode))]);
    // eslint-disable-next-line
  }, [displayRoleCodes]);

  const onClickCheckAll = (e) => {
    if (e.target.checked) {
      setCheckedRoles(displayRoleCodes.map((item) => item.roleCode));
      setAllChecked(true);
    } else {
      setCheckedRoles([]);
      setAllChecked(false);
    }
  }

  const getIfChecked = (roleCode) => {
    return checkedRoles.includes(roleCode);
  }

  const handleConfirmDelete = () => {
    if (checkedRoles.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select the Role you want to delete.');
      setAlertOpen(true);
      return;
    }
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to delete the selected Roles?');
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const deleteRoles = () => {
    setConfirmOpen(false);

    fetch(`${BASE_URL}/code-management/role`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(checkedRoles)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info');
        setAlertMessage('Selected Roles have been deleted successfully.');
        setAlertOpen(true);
        getRoles();
      } else {
        setAlertTitle('Error');
        setAlertMessage('Failed to delete Roles.');
        setAlertOpen(true);
      }
    })
    .finally(() => {
      setCheckedRoles([]);
    })
  }

  const crudButtons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      autosize: true,
      onClick: handleConfirmDelete
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Add',
      isIcon: false,
      autosize: true,
      onClick: navigateAccountPermissionsAdd
    },
  ];

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayRoleCodes.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayRoleCodes.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayRoleCodes, page, itemsPerPage]);

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: deleteRoles
    },
  ];

  return (
      <Box className='white-box white-box-wrap'>
        <div className='box-content'>
          <Box className='white-box-content-top-area' alignItems='flex-end'>
            <Box className='left-area column'>
              <Box className='total-count'>
                <Box component='span' className='label'>
                  Total
                </Box>
                <Box component='span' className='value'>
                  {displayRoleCodes.length}
                </Box>
              </Box>
              <Box display='flex' gap={1}>
                <SearchField placeholder='Please enter a search term' maxWidth='230px'
                             value={searchTextInput}
                             onChange={(e) => setSearchTextInput(e.target.value)}/>
              </Box>
            </Box>

            <Box className='right-area'>
              <CustomButtonGroup buttons={crudButtons} justifyContent='flex-end' />
            </Box>
          </Box>
          <Box className='content-inner-wrap'>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table' aria-label='table' stickyHeader>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    <TableCell key={'roleCode'} className='th' align={'left'} sx={{ width: '40%' }}>
                      <>
                        <CheckBox
                          label=''
                          value='select-all'
                          checked={allChecked}
                          inputProps={{
                            'aria-label': 'select all',
                          }}
                          onChange={onClickCheckAll}
                        />
                        <TableSortLabel
                          active={orderBy === 'roleCode'}
                          direction={orderBy === 'roleCode' ? order : 'desc'}
                          onClick={() => handleRequestSort('roleCode')}
                        >
                          Role Code
                        </TableSortLabel>
                      </>
                    </TableCell>
                    <TableCell key={'roleName'} className='th' align={'left'} sx={{ width: '40%' }}>
                      <TableSortLabel
                          active={orderBy === 'roleName'}
                          direction={orderBy === 'roleName' ? order : 'desc'}
                          onClick={() => handleRequestSort('roleName')}
                      >
                        Role Name
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* table Body */}
                <TableBody className='tbody'>
                  {paginatedData.map((td, index) => (
                      <TableRow className='tr' key={index}>
                        <TableCell key={'roleCode'} className='td' align={'left'}>
                          <CheckBox
                              label={<TextLink href='' text={td[`roleCode`]} onClick={() => navigateAccountPermissionsEdit(td)}/>}
                              value={td.roleCode}
                              onChange={onClickCheckBox}
                              checked={getIfChecked(td.roleCode)}
                          />
                        </TableCell>
                        <TableCell key={'roleName'} className='td' align={'left'}>
                          {td[`roleName`]}
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
            {/* // Table */}
          </Box>

          {/* Pagination */}
          <Pagination count={Math.ceil(displayRoleCodes.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
        </div>
        <Dialog
            open={isAlertOpen} // boolean 값으로 설정
            handleClose={handleAlertClose}
            closeX={false}
            size='small' // large, medium, small
            title={alertTitle} // Popup Title
            contentTitle={false} // Content Title
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
        <Dialog
            open={isConfirmOpen} // boolean 값으로 설정
            handleClose={handleConfirmClose}
            closeX={false}
            size='small'
            title='Confirm'
            contentTitle={false}
            bottomDivider={false}
            contentText={confirmMessage}
            buttons={confirmDialogButtons}
        />
      </Box>
  );
}

export default AccountPermissions;
