export const columns = [
  { label: 'Day', rowspan: 4, colspan: 1, minWidth: 70 },
  { label: 'Day of the week', rowspan: 4, colspan: 1, minWidth: 100 },
  { label: 'Weekday', rowspan: 4, colspan: 1, minWidth: 50 },
  { label: 'Start (Local Time)', rowspan: 4, colspan: 1, minWidth: 160 },
  { label: 'End (Local Time)', rowspan: 4, colspan: 1, minWidth: 160 },
  { label: 'Average Duration', colspan: 14, fontWeight: 'bold' },
  { label: 'Content Clicks', colspan: 32, fontWeight: 'bold' },
  { label: 'Clicked Unique Device', colspan: 36, fontWeight: 'bold' },
];

export const columnsWithoutC = [
  { label: 'Day', rowspan: 4, colspan: 1, minWidth: 70 },
  { label: 'Day of the week', rowspan: 4, colspan: 1, minWidth: 100 },
  { label: 'Weekday', rowspan: 4, colspan: 1, minWidth: 50 },
  { label: 'Start (Local Time)', rowspan: 4, colspan: 1, minWidth: 160 },
  { label: 'End (Local Time)', rowspan: 4, colspan: 1, minWidth: 160 },
  { label: 'Average Duration', colspan: 8, fontWeight: 'bold' },
  { label: 'Content Clicks', colspan: 20, fontWeight: 'bold' },
  { label: 'Clicked Unique Device', colspan: 20, fontWeight: 'bold' },
];

export const columns2 = [
  { label: 'Daily', colspan: 7 }, //Average Duration
  { label: 'Cumulative', colspan: 7 }, //Average Duration
  { label: 'Daily', colspan: 16 }, //Content Clicks
  { label: 'Cumulative', colspan: 16 }, //Content Clicks
  { label: 'Daily', colspan: 16 }, //Clicked Unique Device > Daily
  { label: 'Cumulative', colspan: 16 }, //Clicked Unique Device > Cumulative
];

export const columns2WithoutC = [
  { label: 'Daily', colspan: 4 }, //Average Duration
  { label: 'Cumulative', colspan: 4 }, //Average Duration
  { label: 'Daily', colspan: 10 }, //Content Clicks
  { label: 'Cumulative', colspan: 10 }, //Content Clicks
  { label: 'Daily', colspan: 10 }, //Clicked Unique Device > Daily
  { label: 'Cumulative', colspan: 10 }, //Clicked Unique Device > Cumulative
];

export const columns3 = [
  //Average Duration
  { label: 'Average(min)', colspan: 3 },
  { label: 'Improved', colspan: 4 },
  { label: 'Average(min)', colspan: 3 },
  { label: 'Improved', colspan: 4 },

  //Content Clicks
  { label: 'Category', colspan: 3 },
  { label: 'Tab', colspan: 3 },
  { label: 'Home-App', colspan: 3 },
  { label: 'CTR (Click-Through Rate)', colspan: 3 },
  { label: 'Improved', colspan: 4 },
  { label: 'Category', colspan: 3 },
  { label: 'Tab', colspan: 3 },
  { label: 'Home-App', colspan: 3 },
  { label: 'CTR (Click-Through Rate)', colspan: 3 },
  { label: 'Improved', colspan: 4 },

  { label: 'Category', colspan: 3 },
  { label: 'Tab', colspan: 3 },
  { label: 'Home-App', colspan: 3 },
  { label: 'UD Rate', colspan: 3 },
  { label: 'Improved', colspan: 4 },

  { label: 'Category', colspan: 3 },
  { label: 'Tab', colspan: 3 },
  { label: 'Home-App', colspan: 3 },
  { label: 'UD Rate', colspan: 3 },
  { label: 'Improved', colspan: 4 },
];

export const columns3WithoutC = [
  //Average Duration
  { label: 'Average(min)', colspan: 2 },
  { label: 'Improved', colspan: 2 },
  { label: 'Average(min)', colspan: 2 },
  { label: 'Improved', colspan: 2 },

  //Content Clicks
  { label: 'Category', colspan: 2 },
  { label: 'Tab', colspan: 2 },
  { label: 'Home-App', colspan: 2 },
  { label: 'CTR (Click-Through Rate)', colspan: 2 },
  { label: 'Improved', colspan: 2 },
  { label: 'Category', colspan: 2 },
  { label: 'Tab', colspan: 2 },
  { label: 'Home-App', colspan: 2 },
  { label: 'CTR (Click-Through Rate)', colspan: 2 },
  { label: 'Improved', colspan: 2 },

  { label: 'Category', colspan: 2 },
  { label: 'Tab', colspan: 2 },
  { label: 'Home-App', colspan: 2 },
  { label: 'UD Rate', colspan: 2 },
  { label: 'Improved', colspan: 2 },

  { label: 'Category', colspan: 2 },
  { label: 'Tab', colspan: 2 },
  { label: 'Home-App', colspan: 2 },
  { label: 'UD Rate', colspan: 2 },
  { label: 'Improved', colspan: 2 },
];

export const columns4 = [
  // Average Duration > Daily
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'B over A', minWidth: 65, parentLabel: 'Average Duration' },
  { label: 'P-Value', minWidth: 65 },
  { label: 'B over C', minWidth: 65, parentLabel: 'Average Duration' },
  { label: 'P-Value', minWidth: 65 },

  // Average Duration > Cumulative
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'B over A', minWidth: 52, parentLabel: 'Average Duration' },
  { label: 'P-Value', minWidth: 65 },
  { label: 'B over C', minWidth: 52, parentLabel: 'Average Duration' },
  { label: 'P-Value', minWidth: 65 },

  // Content Clicks > Daily
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'B', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'C', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'B over A', minWidth: 65, parentLabel: 'Content Clicks' },
  { label: 'P-Value', minWidth: 65 },
  { label: 'B over C', minWidth: 65, parentLabel: 'Content Clicks' },
  { label: 'P-Value', minWidth: 65 },

  // Content Clicks > Cumulative
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'B', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'C', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'B over A', minWidth: 65, parentLabel: 'Content Clicks' },
  { label: 'P-Value', minWidth: 65 },
  { label: 'B over C', minWidth: 65, parentLabel: 'Content Clicks' },
  { label: 'P-Value', minWidth: 65 },

  // Clicked Unique Device > Daily
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'B', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'C', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'B over A', minWidth: 65, parentLabel: 'Clicked Unique Device' },
  { label: 'P-Value', minWidth: 65 },
  { label: 'B over C', minWidth: 65, parentLabel: 'Clicked Unique Device' },
  { label: 'P-Value', minWidth: 65 },

  // Clicked Unique Device > Cumulative
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'C', minWidth: 52 },
  { label: 'A', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'B', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'C', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'B over A', minWidth: 65, parentLabel: 'Clicked Unique Device' },
  { label: 'P-Value', minWidth: 65 },
  { label: 'B over C', minWidth: 65, parentLabel: 'Clicked Unique Device' },
  { label: 'P-Value', minWidth: 65 },
];

export const columns4WithoutC = [
  // Average Duration > Daily
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'B over A', minWidth: 65, parentLabel: 'Average Duration' },
  { label: 'P-Value', minWidth: 65 },

  // Average Duration > Cumulative
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'B over A', minWidth: 52, parentLabel: 'Average Duration' },
  { label: 'P-Value', minWidth: 65 },

  // Content Clicks > Daily
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'B', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'B over A', minWidth: 65, parentLabel: 'Content Clicks' },
  { label: 'P-Value', minWidth: 65 },

  // Content Clicks > Cumulative
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'B', minWidth: 52, parentLabel: 'Content Clicks' },
  { label: 'B over A', minWidth: 65, parentLabel: 'Content Clicks' },
  { label: 'P-Value', minWidth: 65 },

  // Clicked Unique Device > Daily
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'B', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'B over A', minWidth: 65, parentLabel: 'Clicked Unique Device' },
  { label: 'P-Value', minWidth: 65 },

  // Clicked Unique Device > Cumulative
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52 },
  { label: 'B', minWidth: 52 },
  { label: 'A', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'B', minWidth: 52, parentLabel: 'Clicked Unique Device' },
  { label: 'B over A', minWidth: 65, parentLabel: 'Clicked Unique Device' },
  { label: 'P-Value', minWidth: 65 },
];

export const seriesData = [
  { dataKey: 'A', label: 'A', color: '#FFCE56' },
  { dataKey: 'B', label: 'B', color: '#e57373' },
  { dataKey: 'C', label: 'C', color: '#66c2b9' }
];

export const seriesDataWithoutC = [
  { dataKey: 'A', label: 'A', color: '#FFCE56' },
  { dataKey: 'B', label: 'B', color: '#e57373' }
];

export const validityOption = [
  { id: 'valid', value: 'valid', option: 'valid', type: 'text' },
  { id: 'invalid', value: 'invalid', option: 'invalid', type: 'text' },
];

export const chartSetting = {};