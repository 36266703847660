import React, { useState, useEffect } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  Box,
  IconButton,
  Divider,
  Typography,
  Grid,
} from '@mui/material';

import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
import CustomTabs from 'components/component/BasicTabs';
// Images
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import { getHeaders } from 'utils/Header';
import {BASE_URL} from "constants";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1118px', //팝업사이즈
    maxWidth: 'none',
    maxHeight: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  width: 100%;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

// DetailItem 컴포넌트 생성
const DetailItem = ({ label, value, xs, className }) => (
  <Grid item xs={xs} className={`list-wrap ${className}`}>
    <div className='info-value-wrap'>
      <Typography component='span' className='label'>
        {label}
      </Typography>
      <div className='value'>
        <Typography component='span' className='text'>
          {value}
        </Typography>
      </div>
    </div>
  </Grid>
);

function PopSeasonDetail(props) {
  const { seasonId, open, close } = props;
  const [seasonData, setSeasonData] = useState({});
  const tabs = [
    { label: 'Season Information', index: 0 }
  ];

  const handleClose = () => {
    close();
  };

  useEffect(() => {
    fetch(`${BASE_URL}/metadata/series/${seasonId}`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSeasonData(body.data);
      }
    })
  }, [seasonId]);

  // Button Data
const buttons = [
  {
    minWidth: '150px',
    color: 'primary',
    type: 'box',
    size: 'large',
    label: 'OK',
    isIcon: 'false',
    IconType: '',
    iconPos: undefined,
    className: 'btn',
    onClick: handleClose
  },
];

  return (
    <div className='component-view'>
      <Dialog open={open} size='large' className='pop-series-detail'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>VOD Details</DialogTitle>
        </HeaderArea>

        <DialogContent className='pop-content'>
          <BoxContainer>
            <Box className='detail-content'>
              <CustomTabs tabStyle='type-01' value={0} tabs={tabs} />
              {/* Season Information */}
                <div className='tab-panel'>
                  <Box className='panel-wrap' sx={{ overflow: 'auto', maxHeight: 634 }}>
                    <Box container className='detail-list-container' sx={{ mt: 0 }}>
                      <Grid container component='section' className='section-container' columnSpacing={2.5}>
                        {/* Series */}
                        <Grid item xs={12}>
                          <Typography className='sub-title'>Series</Typography>
                        </Grid>
                        <DetailItem label='Series ID' className='' value={seasonData.seriesId} xs={12} />
                        <DetailItem label='Series Title' className='' value={seasonData.seriesTitle} xs={12} />

                        {/* Season */}
                        <Grid item xs={12} sx={{ mt: 5 }}>
                          <Typography className='sub-title'>Season</Typography>
                        </Grid>
                        <DetailItem label='Season ID' className='' value={seasonData.seasonId} xs={12} />
                        <DetailItem label='Season Number' className='' value={seasonData.seasonNumber} xs={6} />
                        <DetailItem
                          label='Total Episodes'
                          className=''
                          value={seasonData.totalEpisodes}
                          xs={6}
                        />
                        <DetailItem label='Genre Code' className='' value={seasonData.genreCode} xs={6} />
                        <DetailItem label='Caption Flag' className='' value={seasonData.captionFlag} xs={6} />
                      </Grid>
                    </Box>

                    <Box className='media-detail-container '>
                      <Box className='media-inner-wrap'>
                        {/* Season Image List */}
                        <Box className='media-wrap' sx={{ mt: 5 }}>
                          <div className='title-area'>
                            <Typography variant='h3' className='category-title'>
                              Season Image List
                            </Typography>
                          </div>
                          <Grid container spacing={2.5}>
                            {seasonData.thumbnailImageList && seasonData.thumbnailImageList.map((item, index) => (
                              <Grid item xl={12} key={index}>
                                <Box className='media-item-wrap'>
                                  <div className='item-image-area'>
                                    <CustomImage
                                      src={item.originUrl}
                                      fit={false}
                                      sx={{ width: 378, height: 202 }}
                                      alt=''
                                      rounded
                                    />
                                  </div>
                                  <Box className='item-info-area'>
                                    <Box>
                                      <Box className='item-row'>
                                        <Typography className='text label'>Type :</Typography>
                                        <Typography className='text value'>{item.type}</Typography>
                                      </Box>
                                      <Box className='item-row'>
                                        <Typography className='text label'>Ratio/Size :</Typography>
                                        <Typography className='text value'>{item.ratio}/{item.size}</Typography>
                                      </Box>
                                      <Box className='item-row'>
                                        <Typography className='text label'>Group :</Typography>
                                        <Typography className='text value'>{item.imageGroup}</Typography>
                                      </Box>
                                    </Box>
                                    <Box className='link-url'>
                                      <Button
                                        color='tertiary'
                                        type='outline'
                                        size='small'
                                        label='Image URL'
                                        isIcon={true}
                                        Icon={BtnTeritaryOutlineRight}
                                        iconPos='right'
                                        href={item.originUrl}
                                        target='_blank'
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </div>
            </Box>
          </BoxContainer>
        </DialogContent>

        <Divider sx={{ margin: '0 0 24px' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={buttons} justifyContent='center' />
        </DialogActions>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={false}
        Duration='1000'
        bottom={'8%'}
      />
    </div>
  );
}

export default PopSeasonDetail;
