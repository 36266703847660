import React, { useState, useEffect } from 'react';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import Dialog from 'components/component/BasicDialog';
import CustomRadio from 'components/component/BasicRadio';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import FileUpload from 'components/component/BasicFileUpload';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import SortLabel from 'components/component/BasicTableSortLabel';
import { isEmpty } from 'utils/Valid';
import { getRequest, multiRequest } from 'utils/Request';
// DATA
import noImage378 from 'assets/images/No-Image_378.png';
import noImageVer from 'assets/images/NoImage_ver.png';
import { defaultButton, defaultPopupType, defaultImageInfo, DeepLinkChannelHeader, DeepLinkHubHeader, DeepLinkNotificationHeader } from 'pages/Organization/TabCategory/Carousel/DefaultData';
import { deepLinkCarouselTypeList, contentTypeList } from 'pages/Organization/TabCategory/data/TabCategoryCarouselData';
// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '170px',
  overflowY: 'auto',
}));

// ****** 메인 컨텐츠 *******
function EventEditPop({ deviceType, countryCode, categoryIdx, event, closeHandler, refreshContents }) {
  useEffect(() => {
    if (!isEmpty(event)) {
      setEventBannerIdx(event.bannerIdx);
      setEventBannerTitle(event.contentTitle);
      if (event.deepLink === 'Y') {
        setDeepLinkParameter({ ...deepLinkParameter, carouselType: event.linkContentType, contentId: event.linkContentId });
        getDeepLinkContent(deviceType, categoryIdx, event.linkContentType, 'live', event.linkContentId);
        setDeepLink({ isDeepLink: 'Y', contentType: event.linkContentType, contentId: event.linkContentId });
      }

      if (!isEmpty(event.image)) {
        const img = new Image();
        img.onload = () => {
          setHorizontalImage({
            ...defaultImageInfo,
            preview: event.image,
            height: img.height,
            width: img.width,
          });
        };
        img.src = event.image;
      }
      if (!isEmpty(event.verticalImage)) {
        const img = new Image();
        img.onload = () => {
          setVerticalImage({
            ...defaultImageInfo,
            preview: event.verticalImage,
            height: img.height,
            width: img.width,
          });
        };
        img.src = event.verticalImage;
      }

      if (!isEmpty(event.focusImage)) {
        const img = new Image();
        img.onload = () => {
          setFocusImage({
            ...defaultImageInfo,
            preview: event.focusImage,
            height: img.height,
            width: img.width,
          });
        };
        img.src = event.focusImage;
      }
    }
    // eslint-disable-next-line
  }, [event]);

  const [eventBannerIdx, setEventBannerIdx] = useState('');
  const [eventBannerTitle, setEventBannerTitle] = useState('');
  const [horizontalImage, setHorizontalImage] = useState(null);
  const [verticalImage, setVerticalImage] = useState(null);
  const [focusImage, setFocusImage] = useState(null);
  const [deepLink, setDeepLink] = useState({ isDeepLink: 'N', contentType: '', contentId: '' });
  let defaultLinkParameter = {
    carouselType: 'noLink',
    contentType: '',
    contentId: '',
    contentName: '',
  };
  const [deepLinkParameter, setDeepLinkParameter] = useState(defaultLinkParameter);
  const [deepLinkContentList, setDeepLinkContentList] = useState([]);
  const [header, setHeader] = useState(DeepLinkChannelHeader);
  const [popupType, setPopupType] = useState(defaultPopupType);
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    if (closeHandler) {
      setIsOpen(false);
      closeHandler();
    }
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const dataSorting = (col, desc) => {
    setDeepLinkContentList(sortRows([...deepLinkContentList], col, desc));
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  // Title
  const titleChangeHandler = (e) => {
    setEventBannerTitle(e.target.value);
  };

  // Image
  const getImageInfo = (file, setImage) => {
    let imageInfo = { ...defaultImageInfo };
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e2) => {
      imageInfo.name = file.name;
      imageInfo.preview = e2.target.result;
      const img = new Image();
      img.src = e2.target.result;
      img.onload = () => {
        imageInfo.height = img.height;
        imageInfo.width = img.width;
        imageInfo.file = file;
        setImage(imageInfo);
      };
    };
  };

  const onFileChangeHandler = (uploadTarget, file) => {
    if (file) {
      let ext = file.name.split('.').pop().toLowerCase();
      if (!['jpeg', 'jpg', 'png'].includes(ext)) {
        alert('jpeg, jpg, png 파일만 업로드 할수 있습니다.');
        return;
      }
      switch (uploadTarget) {
        case 'horizontal':
          getImageInfo(file, setHorizontalImage);
          return;
        case 'vertical':
          getImageInfo(file, setVerticalImage);
          return;
        case 'focus':
          getImageInfo(file, setFocusImage);
          return;
        default:
          return;
      }
    }
  };

  const deepLinkParameterHandler = (name, value) => {
    if (name === 'carouselType') {
      let initList = contentTypeList.filter((f) => f.carouselType === value);
      setDeepLinkParameter({ ...defaultLinkParameter, [name]: value, contentType: isEmpty(initList) ? '' : initList[0].value });
      setDeepLink({ isDeepLink: value === 'noLink' ? 'N' : 'Y', contentType: '', contentId: '' });
    } else if (name === 'contentType') {
      setDeepLinkParameter({ ...deepLinkParameter, [name]: value, contentId: '', contentName: '' });
    } else {
      setDeepLinkParameter({ ...deepLinkParameter, [name]: value });
    }
  };

  const getDeepLinkContent = async (deviceType, categoryIdx, carouselType, contentType, contentId, contentName) => {
    if (!isEmpty(contentId) || !isEmpty(contentName)) {
      let param = `?carouselType=${carouselType}&deviceType=${deviceType}&countryCode=${countryCode}&categoryIdx=${categoryIdx}`;

      if (!isEmpty(contentType)) {
        param += `&contentType=${contentType}`;
      }
      if (!isEmpty(contentId)) {
        param += `&contentId=${contentId}`;
      }
      if (!isEmpty(contentName)) {
        param += `&contentName=${contentName}`;
      }
      const body = await getRequest(`/home-app/carousel/event/deep-link${param}`, null);
      if (body && body.result === 'SUCCESS') {
        if (carouselType === 'channel' || carouselType === 'vod') {
          setHeader(DeepLinkChannelHeader);
        } else if (carouselType === 'hub') {
          setHeader(DeepLinkHubHeader);
        } else if (carouselType === 'notification') {
          setHeader(DeepLinkNotificationHeader);
        }
        setDeepLinkContentList(!isEmpty(body.data) ? body.data : []);
      }
    }
  };

  const deepLinkContentCheckHandler = () => {
    getDeepLinkContent(deviceType, categoryIdx, deepLinkParameter.carouselType, deepLinkParameter.contentType, deepLinkParameter.contentId, deepLinkParameter.contentName);
    setDeepLink({ ...deepLink, contentType: '', contentId: '' });
  };

  const deepLinkRadioHandler = (contentType, contentId) => {
    setDeepLink({ isDeepLink: 'Y', contentType: contentType, contentId: contentId });
  };

  const saveEventBanner = async () => {
    let requestBody = new FormData();
    requestBody.append('eventBannerTitle', eventBannerTitle);
    if (horizontalImage.file) requestBody.append('horizontalImage', horizontalImage.file);
    if (verticalImage.file) requestBody.append('verticalImage', verticalImage.file);
    if (focusImage.file) requestBody.append('focusImage', focusImage.file);
    if (deepLink && deepLink.isDeepLink === 'Y') {
      requestBody.append('deepLink', deepLink.isDeepLink);
      requestBody.append('contentType', deepLink.contentType);
      requestBody.append('contentId', deepLink.contentId);
    }

    let title = '';
    let content = '';
    const body = await multiRequest('PUT', `/home-app/carousel/event/${eventBannerIdx}`, requestBody);
    if (body && body.result === 'SUCCESS') {
      title = body.result;
      content = 'Saved successfully.';
      setPopupType({ ...defaultPopupType, success: true, title: title, content: content });
    } else {
      if (body && body.result === 'FAILED') {
        title = 'Image';
        content = body.message;
      } else {
        title = 'Image';
        content = 'Contact your administrator!';
      }
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
    }
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const successDialog = () => {
    if (refreshContents) {
      refreshContents();
    }
    onClose();
  };

  const saveDialog = () => {
    if (isEmpty(eventBannerTitle)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Edit Event', content: 'Please enter an event title' });
      return;
    }
    if (isEmpty(horizontalImage) || isEmpty(horizontalImage.preview)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Edit Event', content: 'Please register a horizontal image' });
      return;
    }
    if (isEmpty(verticalImage) || isEmpty(verticalImage.preview)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Edit Event', content: 'Please register a vertical image' });
      return;
    }
    if (isEmpty(focusImage) || isEmpty(focusImage.preview)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Edit Event', content: 'Please register a focus image' });
      return;
    }
    if (!isEmpty(deepLink) && !isEmpty(deepLink.isDeepLink) && deepLink.isDeepLink === 'Y') {
      if (isEmpty(deepLink.contentType) || isEmpty(deepLink.contentId)) {
        setPopupType({ ...defaultPopupType, alarm: true, title: 'Edit Event', content: 'Please check the deep link' });
        return;
      }
    }
    setPopupType({ ...defaultPopupType, save: true, title: 'Edit Event', content: 'Would you like to save your Event?' });
  };

  // Button Data
  const editCancelButton = {
    buttonId: 'editCancelButtonId',
    width: '150px',
    size: 'large',
    type: 'outline',
    color: 'tertiary',
    label: 'Cancel',
    onClick: onClose,
  };
  const eventSaveButton = {
    buttonId: 'eventSaveButtonId',
    width: '150px',
    size: 'large',
    type: 'box',
    color: 'primary',
    label: 'Save',
    onClick: saveDialog,
  };

  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const successButton = {
    ...defaultButton,
    buttonId: 'successButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: successDialog,
  };
  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButtonId',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const saveButton = {
    ...defaultButton,
    buttonId: 'saveButtonId',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: saveEventBanner,
  };

  return (
    <div className='component-view'>
      <Dialog
        open={isOpen}
        closeX={true}
        title='Edit  Event' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={onClose}
        size='large' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='pop-edit-event'
        contentTextProps={
          <>
            <Grid container>
              {/* Event Title */}
              <Grid item xs={12} mb={1}>
                <Box component='div' className='field-box'>
                  <TextField
                    type='outlined'
                    size='large'
                    required={false}
                    placeholder=''
                    defaultValue={null}
                    label='Event Title'
                    autoComplete='off'
                    inputProps={{ maxLength: 200 }}
                    InputLabelProps={{ shrink: true }}
                    value={eventBannerTitle}
                    onChange={(event) => titleChangeHandler(event)}
                  />
                </Box>
              </Grid>
              <Box className='media-detail-container'>
                <Box className='media-inner-wrap'>
                  {/* Image List */}
                  <Box className='media-wrap'>
                    <Grid container spacing={5}>
                      <Grid item xs={12} key={1}>
                        <Typography component='p' className='field-label required' mb={1}>
                          Horizontal Image
                        </Typography>
                        <Box className='media-item-wrap' justifyContent={'justifyContent'} alignItems={''} flexDirection={'column'} gap={1}>
                          <div className='item-image-area'>
                            {/* noImage378 */}
                            <CustomImage
                              src={!isEmpty(horizontalImage) && !isEmpty(horizontalImage.preview) ? horizontalImage.preview : noImage378}
                              fit={true}
                              imgWidth={'936px'}
                              imgHeight={'202px'}
                              wrapWidth={'936px'}
                              wrapHeight={'202px'}
                              sx={{ width: 936, height: 202 }}
                              alt=''
                              rounded={true}
                            />
                          </div>
                          <Box className={'item-info-area hor'} padding={0} gap={1}>
                            <Box className=''>
                              <Box className='item-row'>
                                <Typography className='text label'>Recommended Size : </Typography>
                                <Typography className='text value'>{'1280px X 720px'}</Typography>
                              </Box>
                              {!isEmpty(horizontalImage) && !isEmpty(horizontalImage.height) && !isEmpty(horizontalImage.height) && (
                                <Box className='item-row'>
                                  <Typography className='text label'>Actual Image Size : </Typography>
                                  <Typography className='text value'>
                                    {horizontalImage.height} x {horizontalImage.width}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Box className='link-url'>
                              <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='Upload File'
                                isIcon={true}
                                Icon={BtnTeritaryOutlineRight}
                                iconPos='right'
                                onClick={() => document.getElementById('horizontalFileUpload').click()}
                              />
                              <Box className='field-box' style={{ display: 'none' }}>
                                <FileUpload
                                  id='horizontalFileUpload'
                                  buttonLabel='Upload'
                                  size='large'
                                  label='Horizontal Image'
                                  placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                                  accept=''
                                  required={true}
                                  InputLabelProps={{ shrink: true }}
                                  disabled={true}
                                  onFileChange={(file) => onFileChangeHandler('horizontal', file)}
                                  value={isEmpty(horizontalImage) ? '' : horizontalImage.name}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} key={1}>
                        <Typography component='p' className='field-label required' mb={1}>
                          Vertical Image
                        </Typography>
                        <Box className='media-item-wrap' justifyContent={'justifyContent'} alignItems={''} flexDirection={''} gap={6}>
                          <div className='item-image-area'>
                            <CustomImage
                              src={!isEmpty(verticalImage) && !isEmpty(verticalImage.preview) ? verticalImage.preview : noImageVer}
                              fit={true}
                              imgWidth={'202px'}
                              imgHeight={'378px'}
                              wrapWidth={'202px'}
                              wrapHeight={'378px'}
                              sx={{ width: 202, height: 378 }}
                              alt=''
                              rounded={true}
                            />
                          </div>
                          <Box className={'item-info-area'} padding={0} gap={1}>
                            <Box className=''>
                              <Box className='item-row'>
                                <Typography className='text label'>Recommended Size : </Typography>
                                <Typography className='text value'>{'610px X 968px'}</Typography>
                              </Box>
                              {!isEmpty(verticalImage) && !isEmpty(verticalImage.height) && !isEmpty(verticalImage.height) && (
                                <Box className='item-row'>
                                  <Typography className='text label'>Actual Image Size : </Typography>
                                  <Typography className='text value'>
                                    {verticalImage.height} x {verticalImage.width}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Box className='link-url'>
                              <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='Upload File'
                                isIcon={true}
                                Icon={BtnTeritaryOutlineRight}
                                iconPos='right'
                                onClick={() => document.getElementById('verticalFileUpload').click()}
                              />
                              <Box className='field-box' style={{ display: 'none' }}>
                                <FileUpload
                                  id='verticalFileUpload'
                                  buttonLabel='Upload'
                                  size='large'
                                  label='Vertical Image'
                                  placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                                  accept=''
                                  required={true}
                                  InputLabelProps={{ shrink: true }}
                                  disabled={true}
                                  onFileChange={(file) => onFileChangeHandler('vertical', file)}
                                  value={isEmpty(verticalImage) ? '' : verticalImage.name}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} key={1}>
                        <Typography component='p' className='field-label required' mb={1}>
                          Focus Image
                        </Typography>
                        <Box className='media-item-wrap' justifyContent={'justifyContent'} alignItems={''} flexDirection={'column'} gap={1}>
                          <div className='item-image-area'>
                            <CustomImage
                              src={!isEmpty(focusImage) && !isEmpty(focusImage.preview) ? focusImage.preview : noImage378}
                              fit={true}
                              imgWidth={'936px'}
                              imgHeight={'202px'}
                              wrapWidth={'936px'}
                              wrapHeight={'202px'}
                              sx={{ width: 936, height: 202 }}
                              alt=''
                              rounded={true}
                            />
                          </div>
                          <Box className={'item-info-area hor'} padding={0} gap={1}>
                            <Box className=''>
                              <Box className='item-row'>
                                <Typography className='text label'>Recommended Size : </Typography>
                                <Typography className='text value'>{'2435px X 1525px'}</Typography>
                              </Box>
                              {!isEmpty(focusImage) && !isEmpty(focusImage.height) && !isEmpty(focusImage.height) && (
                                <Box className='item-row'>
                                  <Typography className='text label'>Actual Image Size : </Typography>
                                  <Typography className='text value'>
                                    {focusImage.height} x {focusImage.width}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Box className='link-url'>
                              <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='Upload File'
                                isIcon={true}
                                Icon={BtnTeritaryOutlineRight}
                                iconPos='right'
                                onClick={() => document.getElementById('focusFileUpload').click()}
                              />
                              <Box className='field-box' style={{ display: 'none' }}>
                                <FileUpload
                                  id='focusFileUpload'
                                  buttonLabel='Upload'
                                  size='large'
                                  label='Focus Image'
                                  placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                                  accept=''
                                  required={true}
                                  InputLabelProps={{ shrink: true }}
                                  onFileChange={(file) => onFileChangeHandler('focus', file)}
                                  value={isEmpty(focusImage) ? '' : focusImage.name}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* Landing Type */}
            <Grid item xs={12} mb={2}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='deepLink-carouselType'
                  fullWidth
                  size='large'
                  width='100%'
                  selectOptions={deepLinkCarouselTypeList}
                  disabled={false}
                  required={false}
                  label='Deep Link'
                  isFirstSelected={true}
                  placeholder=''
                  shrink
                  selected={deepLinkParameter.carouselType}
                  onChange={(value) => deepLinkParameterHandler('carouselType', value)}
                />
              </Box>
            </Grid>

            {!isEmpty(deepLinkParameter) && deepLinkParameter.carouselType !== 'noLink' && (
              <Grid item xs={12} mb={1}>
                <Box className='field-wrap' display={'flex'} justifyContent={'space-between'}>
                  <Box display={'flex'} gap={1}>
                    {deepLinkParameter.carouselType !== 'notification' && (
                      <Box component='div' className='field-box' width={220}>
                        <SelectBox
                          id='deepLink-contentType'
                          fullWidth
                          size='large'
                          label='Content Type'
                          required={false}
                          selectOptions={isEmpty(contentTypeList) ? [] : contentTypeList.filter((f) => f.carouselType === deepLinkParameter.carouselType)}
                          disabled={false}
                          isFirstSelected={true}
                          placeholder=''
                          shrink
                          selected={deepLinkParameter.contentType}
                          onChange={(value) => deepLinkParameterHandler('contentType', value)}
                        />
                      </Box>
                    )}
                    <Box component='div' className='field-box' width={220}>
                      <TextField
                        id='deepLink-contentId'
                        type='outlined'
                        size='large'
                        maxWidth='220px'
                        placeholder=''
                        label='Content ID'
                        autoComplete='off'
                        InputLabelProps={{ shrink: true }}
                        value={deepLinkParameter.contentId}
                        onChange={(event) => deepLinkParameterHandler('contentId', event.target.value)}
                      />
                    </Box>
                    <Box component='div' className='field-box' width={220}>
                      <TextField
                        id='deepLink-contentName'
                        type='outlined'
                        size='large'
                        maxWidth='220px'
                        placeholder='Content Name'
                        defaultValue={null}
                        label='Content Name'
                        autoComplete='off'
                        InputLabelProps={{ shrink: true }}
                        value={deepLinkParameter.contentName}
                        onChange={(event) => deepLinkParameterHandler('contentName', event.target.value)}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      color={!isEmpty(deepLinkParameter.contentId) || !isEmpty(deepLinkParameter.contentName) ? 'primary' : 'tertiary'}
                      type='box'
                      size='medium'
                      label='Search'
                      isIcon={false}
                      onClick={deepLinkContentCheckHandler}
                    />
                  </Box>
                </Box>
              </Grid>
            )}

            {!isEmpty(deepLinkParameter) && deepLinkParameter.carouselType !== 'noLink' && (
              <Box mt={1}>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {header.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {
                              <SortLabel
                                labelText={column.label}
                                labelId={column.labelId}
                                sortable={column.sortable}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={dataSorting}
                              />
                            }
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* table Body */}
                    <TableBody className='tbody'>
                      {deepLinkContentList.map((td, rowIndex) => (
                        <TableRow className='tr' key={`deepLink_${td.contentId}`}>
                          {header.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {column.radio ? (
                                <CustomRadio
                                  checked={deepLink.contentId === td.contentId}
                                  label={td[column.labelId]}
                                  onChange={() => deepLinkRadioHandler(deepLinkParameter.carouselType, td.contentId)}
                                  //disabled={column.disabled}
                                  name={`radio-group`}
                                  sx={{ mr: column.mr }}
                                />
                              ) : column.labelId === 'contentImage' ? (
                                <CustomImage src={td[column.labelId]} fit={false} alt='' wrapWidth='147px' wrapHeight='64px' rounded />
                              ) : (
                                td[column.labelId]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>
            )}
          </>
        }
        buttons={[editCancelButton, eventSaveButton]}
      />
      {(popupType.alarm || popupType.save || popupType.success) && (
        <Dialog
          title={popupType.title}
          contentTitle={popupType.content}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={closeDialog}
          buttons={popupType.alarm ? [closeButton] : popupType.save ? [cancelButton, saveButton] : popupType.success ? [successButton] : []}
        />
      )}
    </div>
  );
}
export default EventEditPop;
