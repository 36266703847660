export const PlatformOption = [
  { id: 'all', value: 'all', option: 'All', type: 'checkbox', defaultChecked: true },
  { id: 'Android', value: 'Android', option: 'Android', type: 'checkbox' },
  { id: 'IOS', value: 'IOS', option: 'IOS', type: 'checkbox' },
];

export const CountryOption = [
  { id: 'country-1', value: 'Us', option: 'Us', type: 'text' },
  { id: 'country-2', value: 'Italy', option: 'Italy', type: 'text' },
  { id: 'country-3', value: 'Ger', option: 'Ger', type: 'text' },
];

export const statusData = [
  { id: 'Saved for Testing', value: 'Save for Test', option: 'Save for Test', type: 'text' },
  { id: 'Saved for Publish', value: 'Save for Publish', option: 'Save for Publish', type: 'text' },
  { id: 'Testing', value: 'Testing', option: 'Testing', type: 'text' },
  { id: 'Published', value: 'Published', option: 'Published', type: 'text' },
];

export const CategoryOption = [
  { id: 'Contents', value: '2', option: 'Contents', type: 'text' },
  { id: 'Event', value: '3', option: 'Event', type: 'text' },
];

export const NotificationSearchOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'Title', value: 'Title', option: 'Title', type: 'text' },
  { id: 'Version', value: 'Version', option: 'Version', type: 'text' },
]

export const ContentType = [
  { id: 'Channel', value: 'Channel', option: 'Channel', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const LanguageOption = [
  { id: 'lang-en', value: 'En', option: 'En', type: 'text' },
  { id: 'lang-fr', value: 'fr', option: 'fr', type: 'text' },
  { id: 'lang-es', value: 'es', option: 'es', type: 'text' },
];

export const DeviceNotificationTd = [
  {
    id: 'DeviceNotificationTd-1',
    country: { label: 'US+11', tooltip: true },
    version: '20240605-075535',
    enable: 'On',
    title: 'Upcoming Changes to Your LG Channels Lineup',
    startDate: '2024-06-05',
    endDate: '2024-06-08',
    lastChgDate: '2024-06-05T07:55:35Z',
  },
  {
    id: 'DeviceNotificationTd-2',
    country: { label: 'US', tooltip: false },
    version: '20240605-075535',
    enable: 'On',
    title: 'Upcoming Changes to Your LG Channels Lineup',
    startDate: '2024-06-05',
    endDate: '2024-06-08',
    lastChgDate: '2024-06-05T07:55:35Z',
  },
  {
    id: 'DeviceNotificationTd-3',
    country: { label: 'US', tooltip: false },
    version: '20240605-075535',
    enable: 'On',
    title: 'Upcoming Changes to Your LG Channels Lineup',
    startDate: '2024-06-05',
    endDate: '2024-06-08',
    lastChgDate: '2024-06-05T07:55:35Z',
  },
];

export const HistoryDeviceNotiTh = [
  {
    id: 'HistoryDevice-Noti-Th-Date',
    align: 'left',
    label: 'Version',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'HistoryDevice-Noti-Th-Name',
    align: 'left',
    label: 'Last Chg Update',
    width: '60%',
    checkbox: false,
    sortable: true,
  },
];

export const HistoryDeviceNotiTd = [
  {
    id: 'HistoryDeviceNotiTd-1',
    data01: '20221121-104914',
    data02: '2024-04-16 16:24:39',
  },
  {
    id: 'HistoryDeviceNotiTd-2',
    data01: '20221121-104914',
    data02: '2024-04-16 16:24:39',
  },
  {
    id: 'HistoryDeviceNotiTd-3',
    data01: '20221121-104914',
    data02: '2024-04-16 16:24:39',
  },
  {
    id: 'HistoryDeviceNotiTd-4',
    data01: '20221121-104914',
    data02: '2024-04-16 16:24:39',
  },
  {
    id: 'HistoryDeviceNotiTd-5',
    data01: '20221121-104914',
    data02: '2024-04-16 16:24:39',
  },
  {
    id: 'HistoryDeviceNotiTd-6',
    data01: '20221121-104914',
    data02: '2024-04-16 16:24:39',
  },
  {
    id: 'HistoryDeviceNotiTd-7',
    data01: '20221121-104914',
    data02: '2024-04-16 16:24:39',
  },
  {
    id: 'HistoryDeviceNotiTd-8',
    data01: '20221121-104914',
    data02: '2024-04-16 16:24:39',
  },
];