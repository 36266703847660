import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectBox from 'components/component/BasicSelectBox';
import SearchField from 'components/component/BasicSearchField';
import BasicSwitches from 'components/component/BasicSwitch';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import Tooltip from 'components/component/BasicTooltip';
import TextField from 'components/component/BasicTextField';
import CustomImage from 'components/component/BasicImage';
import TextLink from 'components/component/BasicTextLink';
import Button from 'components/component/BasicButton';
import DatePicker from 'components/component/BasicDatePicker';
import CustomBasicMenu from 'components/component/BasicMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import CheckBox from 'components/component/BasicCheckBox';
import { Box, Typography, Grid, TableSortLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import Snackbar from 'components/component/BasicSnackbar';
import dayjs from 'dayjs';
import calendarIconLight from 'assets/images/icon/ic_calendar_24_outline.svg';
import calendarIconDark from 'assets/images/icon/ic_calendar_24_outline_dark.svg';
import { getRequest } from 'utils/Request';
import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import {
  ShelfManualTh,
  ScheduledManualTh,
} from './data/LGChannelsShelfData';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { BtnArrowRight } from 'components/component/BasicIcon';
import Dialog from "../../../components/component/BasicDialog";
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import PopChannelDetail from "pages/Common/PopChannelDetail";
import PopSeriesDetail from "pages/Common/PopSeriesDetail";
import Loading from 'components/component/Loading';
import * as XLSX from 'xlsx';

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

// 메인 컨텐츠
function LGChannelsShelfManual(props) {
  const { darkMode, message, providerByCountry } = props;
  const navigate = useNavigate();
  // 화면에 보이는 필터 정보
  const [optionCountryList, setOptionCountryList] = useState([]);
  const [optionTypeList, setOptionTypeList] = useState([]);
  const [optionProviderNameList, setOptionProviderNameList] = useState([]);
  // 선택된 필터값
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [selectedType, setSelectedType] = useState('All');
  const [selectedProviderId, setSelectedProviderId] = useState('All');
  // Recommend Month Select box
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];
  const [contentList, setContentList] = useState([]);
  // Scheduled Content List영역
  const [scheduleList, setScheduleList] = useState([]);
  const [leftCheckedItems, setLeftCheckedItems] = useState({});
  const [rightCheckedItems, setRightCheckedItems] = useState({});
  const [isAllLeftChecked, setIsAllLeftChecked] = useState(false);
  const [isAllRightChecked, setIsAllRightChecked] = useState(false);
  const [dragDropDisabled, setDragDropDisabled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isAllowMove, setAllowMove] = useState(false);
  const [isDisplayData, setDisplayData] = useState(false);
  const [isEmptyContentData, setEmptyContentData] = useState(false);
  const [isEmptyScheduleData, setEmptyScheduleData] = useState(false);
  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [contentOrder, setContentOrder] = useState('asc');
  const [contentOrderBy, setContentOrderBy] = useState('');
  const [scheduleOrder, setScheduleOrder] = useState('asc');
  const [scheduleOrderBy, setScheduleOrderBy] = useState('');
  // 토스트 팝업
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);
  // Detail Popup
  const [currentContentId, setCurrentContentId] = useState('');
  const [channelDetailOpen, setChannelDetailOpen] = useState(false);
  const [vodDetailOpen, setVodDetailOpen] = useState(false);

  const [searchTermInContentList, setSearchTermInContentList] = useState('');
  const [searchTermInScheduleList, setSearchTermInScheduleList] = useState('');
  const [displayContentList, setDisplayContentList] = useState([]);
  const [displayScheduleList, setDisplayScheduleList] = useState([]);

  const DateIcon = styled('span')({
    display: 'block',
    width: '24px',
    height: '24px',
    backgroundImage: `url(${calendarIconLight})`,
    backgroundRepeat: 'no-repeat',
    '.dark &': {
      backgroundImage: `url(${calendarIconDark})`,
    },
  });

  // 테이블
  const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    height: '100%',
    overflowY: 'auto',
  }));

  const IconDraggable = styled('span')`
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url(${IconDraggableImg});
    opacity: ${props => props.disabled ? 0.3 : 1};

    .dark & {
      background: url(${IconDraggableImgDark});
    }
  `;

  const typeList = ['All', 'tvshow', 'movie', 'tvshow-series', 'live', 'livestream'];

  useEffect(() => {
    getLocalStorage();
  }, []);

  useEffect(() => {
    if (message) {
      if (message.filter) {
        setSelectedCountry(message.filter.selectedCountry);
        setSelectedType(message.filter.selectedType);
        setSelectedProviderId(message.filter.selectedProviderId);
        setSelectedDate(message.filter.selectedDate);
      }
    }
  }, [message]);

  // 필터 초기 세팅 useEffect
  useEffect(() => {
    if (providerByCountry) {
      const countryCodeList = providerByCountry.map(item => item.countryCode).sort();
      setOptionCountryList(countryCodeList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      })));
      setOptionTypeList(typeList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      })));
    }
    // eslint-disable-next-line
  }, [providerByCountry]);

  useEffect(() => {
    if (optionCountryList?.length > 0) {
      getContentList();
      let tempProviderList = providerByCountry
        .filter(item => item.countryCode === selectedCountry)
        .map(item => item.providerList)
        .reduce((acc, curr) => acc.concat(curr), []);
      setOptionProviderNameList([
        { id: 'All', value: 'All', option: 'All', type: 'text' },
        ...tempProviderList.map(item => ({
          id: item.providerId,
          value: item.providerId,
          option: item.providerName,
          type: 'text'
        }))
      ]);
    }
    // eslint-disable-next-line
  }, [selectedCountry, selectedType, selectedProviderId, optionCountryList]);

  useEffect(() => {
    if (optionCountryList?.length > 0) {
      getScheduleList();
    }
    // eslint-disable-next-line
  }, [selectedCountry, selectedDate, optionCountryList]);

  // paging
  useEffect(() => {
    if (displayContentList && displayContentList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayContentList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [displayContentList, page, itemsPerPage]);

  useEffect(() => {
    if (isAllRightChecked) {
      setDragDropDisabled(false);
    } else {
      setDragDropDisabled(true);
    }
  }, [isAllRightChecked]);

  useEffect(() => {
    const allUnchecked = Object.values(rightCheckedItems).every(value => value === false);
    if (allUnchecked || Object.keys(rightCheckedItems).length === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [rightCheckedItems]);

  useEffect(() => {
    if (contentList) {
      if (searchTermInContentList) {
        const excludeKeyList = ['resultOrder', 'imageUrl'];
        const filteredData = contentList.filter((item) => {
          return Object.entries(item)
            .filter(([key, value]) => !excludeKeyList.includes(key))
            .some(([, value]) => String(value).toLowerCase().includes(searchTermInContentList));
        });
        setDisplayContentList([...filteredData]);
      } else {
        setDisplayContentList([...contentList]);
      }
    }
    // eslint-disable-next-line
  }, [searchTermInContentList]);

  useEffect(() => {
    if (!searchTermInContentList) {
      setContentList([...displayContentList]);
    }
    if (displayContentList && displayContentList.length > 0) {
      setEmptyContentData(false);
    } else {
      setEmptyContentData(true);
    }
    // eslint-disable-next-line
  }, [displayContentList]);

  useEffect(() => {
    if (searchTermInScheduleList) {
      const excludeKeyList = ['resultOrder', 'imageUrl'];
      const filteredData = scheduleList.filter((item) => {
        return Object.entries(item)
          .filter(([key, value]) => !excludeKeyList.includes(key))
          .some((value) => String(value).toLowerCase().includes(searchTermInScheduleList));
      });
      setDisplayScheduleList([...filteredData]);
    } else {
      setDisplayScheduleList([...scheduleList]);
    }
    // eslint-disable-next-line
  }, [searchTermInScheduleList]);

  useEffect(() => {
    const checkedItem = displayScheduleList.filter(item => item.checked);
    if (checkedItem.length > 0 && checkedItem.length === displayScheduleList.length) {
      setIsAllRightChecked(true);
    } else {
      setIsAllRightChecked(false);
    }
    setAllowMove(isSortedAscending(displayScheduleList));
    if (!searchTermInScheduleList) {
      setScheduleList([...displayScheduleList]);
    }
    if (displayScheduleList && displayScheduleList.length === 0) {
      setEmptyScheduleData(true);
    } else {
      setEmptyScheduleData(false);
    }
    // eslint-disable-next-line
  }, [displayScheduleList]);

  const isSortedAscending = (data) => {
    return data.every((item, index) => {
      return index === 0 || item.resultOrder >= data[index - 1].resultOrder;
    });
  };

  const getLocalStorage = () => {
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    setSelectedCountry(savedSettings && savedSettings.preferredCountry ? savedSettings.preferredCountry : 'US');
  };

  const getContentList = async () => {
    setEmptyContentData(false);
    setPage(1);
    setDisplayData(false);
    setDisplayContentList([]);
    setSearchTermInContentList('');

    let paramProviderId = selectedProviderId === 'All' ? '' : selectedProviderId;
    let paramType = selectedType === 'All' ? '' : selectedType;
    const body = await getRequest(`/sdepth/content-list?countryCode=${selectedCountry}&contentType=${paramType}&providerId=${paramProviderId}`);
    if (body && body.data && body.result === 'SUCCESS') {
      setDisplayContentList(body.data);
      setContentList(body.data);
      if (body.data.length === 0) {
        setEmptyContentData(true);
      }
      setDisplayData(true);
    }
  };

  const getScheduleList = async () => {
    setEmptyScheduleData(false);
    setDisplayScheduleList([]);
    setSearchTermInScheduleList('');
    let date = new Date(selectedDate);
    let formattedDate = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}`;
    const body = await getRequest(`/sdepth/schedule?countryCode=${selectedCountry}&recommendMonth=${formattedDate}`, null);
    if (body && body.result === 'SUCCESS') {
      setDisplayScheduleList(body.data);
      setScheduleList(body.data);
      if (body.data.length === 0) {
        setEmptyScheduleData(true);
      }
    }
  };

  // 테이블 내림, 오름차순
  const handleRequestSort = (property, item) => {
    if (item === 'content') {
      const isAsc = contentOrderBy === property && contentOrder === 'asc';
      setContentOrder(isAsc ? 'desc' : 'asc');
      setContentOrderBy(property);
      const sortedData = [...displayContentList].sort((a, b) => {
        if (a[property] < b[property]) {
          return isAsc ? -1 : 1;
        }
        if (a[property] > b[property]) {
          return isAsc ? 1 : -1;
        }
        return 0;
      });
      setDisplayContentList([...sortedData]);
    } else {
      const isAsc = scheduleOrderBy === property && scheduleOrder === 'asc';
      setScheduleOrder(isAsc ? 'desc' : 'asc');
      setScheduleOrderBy(property);
      const sortedData = [...displayScheduleList].sort((a, b) => {
        if (a[property] < b[property]) {
          return isAsc ? -1 : 1;
        }
        if (a[property] > b[property]) {
          return isAsc ? 1 : -1;
        }
        return 0;
      });
      setDisplayScheduleList([...sortedData]);
    }
  };

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(displayScheduleList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const updatedItems = items.map((itme, index) => ({ ...itme, resultOrder: index + 1 }));
    setDisplayScheduleList(updatedItems);
  };

  // Recommend Month Select box
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const handleSwitchChange = (contentId, value) => {
    setDisplayScheduleList(prev =>
      prev.map(item => ({
        ...item,
        fixFlag: item.contentId === contentId ? (value ? 'Y' : 'N') : item.fixFlag
      }))
    );
  };

  const handleSelectedCountry = (country) => {
    const providerNameList = providerByCountry.find(item => item.countryCode === country)?.providerList.map(item => item.providerName).sort();
    setOptionProviderNameList([
      { id: 'All', value: 'All', option: 'All', type: 'text' },
      ...providerNameList.map(item => ({
        id: item.providerId,
        value: item.providerId,
        option: item.providerName,
        type: 'text'
      }))
    ]);
    setSelectedCountry(country);
    setSelectedProviderId('All');
  };

  const handleSelectedProvider = (provider) => {
    if (provider && provider.length > 0) {
      setSelectedProviderId(provider);
    }
  };

  const handleSelectedType = (value) => {
    setSelectedType(value);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleConfirmUpdate = () => {
    setConfirmOpen(false);
    let date = new Date(selectedDate);
    let formattedDate = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}`;
    scheduleList.forEach((item, index) => {
      item.resultOrder = index + 1;
    });
    fetch(`${BASE_URL}/sdepth/countryCode/${selectedCountry}/recommendMonth/${formattedDate}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(scheduleList)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info');
        setAlertMessage('Successfully Updated.');
        setAlertOpen(true);
      } else {
        throw new Error(`[LG Channels Shelf] Save error`);
      }
    })
    .catch(error => {
      setSnackbarMessage(error.message.replace('Error: ', ''));
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSaveButton = () => {
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to save the selected items?');
    setConfirmAction(() => () => handleConfirmUpdate('save'));
  };

  const handleDeleteButton = () => {
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to delete the selected items?');
    setConfirmAction(() => () => handleConfirmDelete());
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmDelete = () => {
    setConfirmOpen(false);
    const survivalItems = displayScheduleList.filter(item => !rightCheckedItems[item.contentId]);

    if (survivalItems) {
      survivalItems.forEach((item, index) => {
        item.resultOrder = index + 1;
        item.score = survivalItems.length - index;
      });
      setDisplayScheduleList(survivalItems);
    } else {
      setDisplayScheduleList([]);
    }

    if (searchTermInScheduleList) {
      const deleteContentIds = displayScheduleList.filter(item => rightCheckedItems[item.contentId]).map(item => item.contentId);
      const saveItems = scheduleList.filter(item => !deleteContentIds.includes(item.contentId));
      setScheduleList([...saveItems]);
    }
    setRightCheckedItems({});
  };

  const handleScheduleList = () => {
    const newItems = displayContentList
    .filter((item) => leftCheckedItems[item.contentId] === true);

    if (displayScheduleList.length + newItems.length > 15) {
      setSnackbarMessage('Up to 15 items can be added');
      setSnackbarOpen(true);
    } else {
      const filteredBuffer = newItems.map((item, index) => {
        return {
          ...item,
          resultOrder: displayScheduleList.length + index + 1,
          fixFlag: 'N',
        };
      });

    const result = [...displayScheduleList, ...filteredBuffer];
    setLeftCheckedItems([]);
    setIsAllLeftChecked(false);
    setDisplayScheduleList(result);
    }
  };

  const handleCheckboxChange = (event, id, position) => {
    event.preventDefault();
    event.stopPropagation();

    if (position === 'left') {
      setLeftCheckedItems((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    } else if (position === 'right') {
      setRightCheckedItems((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    }
  };

  const handleCheckBoxTitle = (value) => {
    if (value === 'Select All Page') {
      handleCheckboxAllChange('left');
    } else {
      const newCheckedItemsPage = {};
      paginatedData.forEach((item) => {
        newCheckedItemsPage[item.contentId] = true;
      });
      setLeftCheckedItems(newCheckedItemsPage);
    }
  };

  const handleCheckboxAllChange = (position) => {
    const newCheckedItems = {};
    if (position === 'left') {
      displayContentList.forEach((item) => {
        newCheckedItems[item.contentId] = !isAllLeftChecked;
      });
      setLeftCheckedItems(newCheckedItems);
      setIsAllLeftChecked(!isAllLeftChecked);
    } else if (position === 'right') {
      displayScheduleList.forEach((item) => {
        newCheckedItems[item.contentId] = !isAllRightChecked;
      });
      setRightCheckedItems(newCheckedItems);
      setIsAllRightChecked(!isAllRightChecked);
    }
  };

  const handleHistory = () => {
    navigate('/LGChannelsShelf',
      {  replace: true, state:
        { move: true,
          targetComponent: 'History',
          message:
          {
            // filter 의 용도는 전달화면에서 사용하고, 그 뒤에 back 했을 때 main으로 돌려 주기 위한 용도임
            filter : {
              selectedCountry: selectedCountry,
              selectedType: selectedType,
              selectedProviderId: selectedProviderId,
              selectedDate: selectedDate,
            },
          }
        }
      }
    );
  };

  const handleSearchContent = (value, item) => {
    if (item === 'left') {
      setSearchTermInContentList(value.toLowerCase());
    } else {
      setSearchTermInScheduleList(value.toLowerCase());
    }
  };

  const handleItemClick = (event, contentId, type) => {
    event.preventDefault();
    setCurrentContentId(contentId);

    if (type === 'live') {
      setVodDetailOpen(false);
      setChannelDetailOpen(true);
    } else {
      setChannelDetailOpen(false);
      setVodDetailOpen(true);
    }
  };

  const handleDownload = (event) => {
    event.preventDefault();
    const worksheet = XLSX.utils.json_to_sheet(scheduleList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `lgchannels_shelf_manual_data`);
    XLSX.writeFile(workbook, `lgchannels_shelf_manual_data.xlsx`);
  };

  // Button Data
  const ScheduledButtons = [
    {
      index: 1,
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Download',
      isIcon: false,
      autosize: true,
      disabled: scheduleList?.length === 0,
      onClick: handleDownload,
    },
    {
      index: 2,
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      autosize: true,
      disabled: isEmptyScheduleData || buttonDisabled,
      onClick: handleDeleteButton,
    },
    {
      index: 3,
      color: 'tertiary',
      type: 'box',
      size: 'small',
      label: 'History',
      isIcon: false,
      autosize: true,
      disabled: false,
      onClick: handleHistory,
    },
    {
      index: 4,
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Save',
      autosize: true,
      disabled: scheduleList?.length === 0,
      onClick: () => handleSaveButton(),
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: confirmAction
    },
  ];

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div>
      <Box className='content-wrap category-list' component='div'>
        <Box component='div' className='white-box filter-box'>
          <Box className='form-wrap-box'>
            <Box className='form-area left'>
              <Box className='component-wrap col-5'>
                {/* Country */}
                <Box component='div' className='col'>
                  <SelectBox
                    id='country-slt1'
                    size='large'
                    shrink={true}
                    label='Country'
                    required={false}
                    selectOptions={optionCountryList}
                    disabled={false}
                    selected={selectedCountry}
                    onChange={handleSelectedCountry}
                  />
                </Box>

                {/* Type */}
                <Box component='div' className='col'>
                  <SelectBox
                    id='Type-1498'
                    size='large'
                    shrink={true}
                    label='Type'
                    required={false}
                    selectOptions={optionTypeList}
                    disabled={false}
                    selected={selectedType}
                    onChange={handleSelectedType}
                  />
                </Box>

                {/* CP Name */}
                <Box component='div' className='col'>
                  <SelectBox
                    id='slt-Name'
                    size='large'
                    shrink={true}
                    label='CP Name'
                    required={false}
                    selectOptions={optionProviderNameList}
                    disabled={false}
                    selected={selectedProviderId}
                    onChange={handleSelectedProvider}
                  />
                </Box>

                <Box component='div' className='col'>
                  <DatePicker
                    views={['year', 'month']}
                    className='input-date-picker'
                    format='YYYY/MM'
                    size='large'
                    shrink
                    label='Recommend Month'
                    value={selectedDate}
                    onChange={handleDateChange}
                    singleDate={true}
                    slots={{
                      openPickerIcon: DateIcon,
                      Textfield: params => <TextField {...params} size='large' />
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {isDisplayData ? (<Grid container columnSpacing={2.5} className='content-pack-area'>
          {/* Content List */}
          <Grid item xs={6}>
            <Box className='white-box white-box-wrap'>
              {/* Box Title Area */}
              <Box className='title-wrap space-between'>
                <Box className='left-area'>
                  <Box
                    className='box-title-area'
                    display='flex'
                    alignItems='center'
                  >
                    <Typography variant='h2'>Result for Content</Typography>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <Box className='show-count-wrap'>
                    <Typography component='span' className='label'>
                      Showing All
                    </Typography>
                    <Typography component='span' className='value'>
                      {displayContentList.length}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Box Content Area */}
              <Box className='box-content'>
                <Box className='white-box-content-top-area'>
                  <Box className='left-area'>
                    <SearchField
                      id='search-1'
                      placeholder='Please enter a search term'
                      label=''
                      maxWidth='230px'
                      size='medium'
                      required={false}
                      shrink={false}
                      onChange={(event) => handleSearchContent(event.target.value, 'left')}
                    />
                  </Box>
                  <Box className='right-area'>
                    <Button
                      color='tertiary'
                      type='box'
                      size='small'
                      label='Select Ch Input'
                      isIcon={true}
                      autosize
                      Icon={BtnArrowRight}
                      iconPos='right'
                      onClick={() => handleScheduleList()}
                    />
                  </Box>
                </Box>

                <Box className='content-inner-wrap'>
                  {/* Table */}
                  <CustomTableContainer className='table-container'>
                    <Table
                      className='custom-table auto'
                      aria-label='table'
                      stickyHeader
                    >
                      {/* table Head */}
                      <TableHead className='thead'>
                        <TableRow className='tr'>
                          {ShelfManualTh.map((column, index) => (
                            <TableCell
                              key={column.id}
                              className='th'
                              align={column.align}
                              sx={{ width: column.width }}
                            >
                              {column.checkbox ? (
                                <Box
                                  component='div'
                                  sx={{ display: 'flex', gap: '8px' }}
                                >
                                  <Box
                                    component='div'
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <CheckBox
                                      label=''
                                      value='select-all'
                                      defaultChecked={isAllLeftChecked}
                                      onChange={(event) => handleCheckboxAllChange('left')}
                                      inputProps={{
                                        'aria-label': 'select all'
                                      }}
                                      style={{ width: '18px' }}
                                    />
                                    {index === 0 && (
                                      <CustomBasicMenu
                                        type='icon'
                                        title={
                                          <ArrowDropDownIcon
                                            sx={{
                                              fontSize: '18px',
                                              color: '#000'
                                            }}
                                          />
                                        }
                                        options={selPageData}
                                        onClick={handleCheckBoxTitle}
                                      />
                                    )}
                                  </Box>
                                </Box>
                              ) : (
                                <>
                                  {column.sortable ? (
                                    <TableSortLabel
                                      active={contentOrderBy === column.id}
                                      direction={
                                        contentOrderBy === column.id ? contentOrder : 'desc'
                                      }
                                      onClick={() => handleRequestSort(column.id, 'content')}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  ) : (
                                    column.label
                                  )}
                                </>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      {/* table Body */}
                      <TableBody className='tbody'>
                        {isEmptyContentData ? (
                          <NoRecords>
                            No records to display
                          </NoRecords>
                        ) : (
                          paginatedData.map((td, index) => (
                          <TableRow className='tr' key={td.id || index}>
                            {ShelfManualTh.map((column, colIndex) => (
                              <TableCell
                                key={column.id}
                                className='td'
                                align={column.align}
                              >
                                {colIndex === 0 ? (
                                  <CheckBox
                                    label=''
                                    value='value'
                                    defaultChecked={false}
                                    onChange={(event) => handleCheckboxChange(event, td.contentId, 'left')}
                                    checked={!!leftCheckedItems[td.contentId]}
                                  />
                                ) : colIndex === 2 ? (
                                  <TextLink href='#' text={td[column.id]} onClick={(event) => handleItemClick(event, td.contentId, td.contentType)}/>
                                ) : colIndex === 4 ? (
                                  <CustomImage
                                    src={td[column.id]}
                                    fit={false}
                                    alt=''
                                    wrapWidth='62px'
                                    wrapHeight='64px'
                                    rounded
                                  />
                                ) : colIndex === 5 ? (
                                  td.toolTip && td.toolTip.includes(',') ? (
                                    <div className='td-tooltip-wrap'>
                                      <Tooltip
                                        title={`Genre : ${td['toolTip']}`}
                                        placement='top'
                                        className='tooltip-wrap'
                                      >
                                        <Box display='flex'>
                                          <span className='tooltip-text'>{td[column.id]}</span>
                                          <span className='tooltip-icon'></span>
                                        </Box>
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    <span>{td[column.id]}</span>
                                  )
                                ) : (
                                  td[column.id]
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        )))}
                      </TableBody>
                    </Table>
                  </CustomTableContainer>
                  {/* // Table */}
                </Box>

                {/* Pagination */}
                <Pagination
                  id='pagination'
                  count={Math.ceil(displayContentList.length / itemsPerPage)}
                  selected={itemsPerPage}
                  onChangeHandle={handlePagination}
                  page={page}
                />
              </Box>
            </Box>
          </Grid>

          {/* Scheduled Content List */}
          <Grid item xs={6}>
            <Box className='white-box white-box-wrap'>
              {/* Box Title Area */}
              <Box className='title-wrap space-between'>
                <Box className='left-area'>
                  <Box
                    className='box-title-area'
                    display='flex'
                    alignItems='center'
                  >
                    <Typography variant='h2'>Scheduled Content List</Typography>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <Box className='show-count-wrap'>
                    <Typography component='span' className='label'>
                      Showing All
                    </Typography>
                    <Typography component='span' className='value'>
                      {displayScheduleList.length}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box className='box-content'>
                <Box className='white-box-content-top-area'>
                  <Box className='left-area'>
                    <SearchField
                      id='search-2'
                      label=''
                      size='medium'
                      maxWidth='120px'
                      placeholder='Please enter a search term'
                      required={false}
                      shrink={false}
                      onChange={(event) => handleSearchContent(event.target.value, 'right')}
                    />
                  </Box>
                  <Box className='right-area'>
                    <CustomButtonGroup
                      buttons={ScheduledButtons}
                      justifyContent='center'
                      gap='4px'
                    />
                  </Box>
                </Box>

                {/* Selected Content */}
                {/* 241011 no-page-number 클래스 추가하였습니다. */}
                <Box className='content-inner-wrap no-page-number'>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId='droppable-table' isDropDisabled={!dragDropDisabled}>
                      {provided => (
                        <CustomTableContainer
                          className='table-container'
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <Table
                            className='custom-table auto'
                            aria-label='table'
                            stickyHeader
                          >
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {ScheduledManualTh.map((column, index) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <Box
                                        component='div'
                                        sx={{ display: 'flex', gap: '8px' }}
                                      >
                                        <Box
                                          component='div'
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                          }}
                                        >
                                          <CheckBox
                                            label=''
                                            value='select-all'
                                            defaultChecked={isAllRightChecked}
                                            onChange={() => handleCheckboxAllChange('right')}
                                            inputProps={{
                                              'aria-label': 'select all'
                                            }}
                                            style={{ width: '18px' }}
                                          />
                                        </Box>
                                      </Box>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={scheduleOrderBy === column.id}
                                            direction={
                                              scheduleOrderBy === column.id
                                                ? scheduleOrder
                                                : 'desc'
                                            }
                                            onClick={() => handleRequestSort(column.id, 'schedule')}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}

                            <TableBody className='tbody'>
                              {isEmptyScheduleData ? (
                                <TableRow>
                                  <TableCell
                                    className={isEmptyScheduleData ? 'noData' : ''}
                                    colSpan={ShelfManualTh.length}
                                    align='center'
                                  >
                                    <Box className='no-data-area'>
                                      <Typography className='text'>
                                        Expose in order of popularity when not organized
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                displayScheduleList.map((td, index) => (
                                  <Draggable
                                    key={td.contentId || index}
                                    draggableId={td.contentId || index.toString()}
                                    index={index}
                                    isDragDisabled={isAllowMove ? false : true}
                                  >
                                    {provided => (
                                      <TableRow
                                        className='tr'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {ScheduledManualTh.map(
                                          (column, colIndex) => (
                                            <TableCell
                                              key={column.id}
                                              className='td'
                                              align={column.align}
                                              sx={{ height: '88px' }}
                                            >
                                              {colIndex === 0 ? (
                                                <Box
                                                  display='flex'
                                                  alignItems='center'
                                                  {...provided.dragHandleProps}
                                                >
                                                  <Tooltip
                                                    title={isAllowMove ? '' : 'You can only change the order when the sorting is in ascending order'}
                                                    placement='top'
                                                  >
                                                    <IconDraggable
                                                      className='icon-draggable'
                                                      disabled={isAllowMove ? false : true}
                                                    />
                                                  </Tooltip>
                                                  <CheckBox
                                                    label={td[column.id]}
                                                    value='value'
                                                    defaultChecked={false}
                                                    onChange={event =>
                                                      handleCheckboxChange(
                                                        event,
                                                        td.contentId,
                                                        'right'
                                                      )
                                                    }
                                                    checked={
                                                      !!rightCheckedItems[
                                                        td.contentId
                                                      ]
                                                    }
                                                  />
                                                </Box>
                                              ) : colIndex === 4 ? (
                                                <TextLink
                                                  href='#'
                                                  text={td[column.id]}
                                                  onClick={(event) => handleItemClick(event, td.contentId, td.contentType)}
                                                />
                                              ) : colIndex === 6 ? (
                                                <BasicSwitches
                                                  id={td.contentId}
                                                  onOffMode={td.fixFlag === 'Y' ? true : false}
                                                  setSwitchOnOff={(value) =>
                                                    handleSwitchChange(
                                                      td.contentId,
                                                      value
                                                    )
                                                  }
                                                  isLabelText={true}
                                                  onText=''
                                                  offText=''
                                                />
                                              ) : colIndex === 7 ? (
                                                <CustomImage
                                                  src={td[column.id]}
                                                  fit={false}
                                                  alt=''
                                                  wrapWidth='64px'
                                                  wrapHeight='64px'
                                                  rounded
                                                />
                                              ) : (
                                                td[column.id]
                                              )}
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))
                              )}
                              {provided?.placeholder}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>) : (
          <Loading/>
        )}
        {channelDetailOpen && <PopChannelDetail channelId={currentContentId} open={channelDetailOpen} close={() => setChannelDetailOpen(false)} />}
        {vodDetailOpen && <PopSeriesDetail vodId={currentContentId} countryCode={selectedCountry} open={vodDetailOpen} close={() => setVodDetailOpen(false)} />}
        <Dialog
          open={isAlertOpen}
          handleClose={handleAlertClose}
          closeX={false}
          size='small'
          title={alertTitle}
          contentTitle={false}
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
        />
        <Dialog
          open={isConfirmOpen}
          handleClose={handleConfirmClose}
          closeX={false}
          size='small'
          title='Confirm'
          contentTitle={false}
          bottomDivider={false}
          contentText={confirmMessage}
          buttons={confirmDialogButtons}
        />
        <Snackbar
          severity='warning'
          message={snackbarMessage}
          open={isSnackbarOpen}
          duration={1000}
          bottom={'8%'}
          darkMode={darkMode}
          onClose={handleSnackbarClose}
        />
      </Box>
    </div>
  )
}

LGChannelsShelfManual.propTypes = {
  providerByCountry: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  providerByCountry: state.common.provider.country
});

export default connect(mapStateToProps)(LGChannelsShelfManual);
