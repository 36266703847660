export const segOrgTypeOption = [
  { id: "live", value: "live", option: "live", type: "text" },
  { id: "tvshow", value: "tvshow", option: "tvshow", type: "text" },
  { id: "movie", value: "movie", option: "movie", type: "text" },
  { id: "tvshow-series", value: "tvshow-series", option: "tvshow-series", type: "text"},
  { id: "livestream", value: "livestream", option: "livestream", type: "text" },
];
export const segOrgCpNameOption = [
  { id: "All", value: "All", option: "All", type: "text" },
];
export const SegOrgResultTh = [
  {
    id: "cpName",
    align: "left",
    label: "CP Name",
    width: "10%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "id",
    align: "left",
    label: "Content ID",
    width: "20%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "contentName",
    align: "left",
    label: "Content Name",
    width: "20%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "image",
    align: "left",
    label: "Image",
    width: "16%",
    checkbox: false,
    sortable: false,
  },
  {
    id: "genre",
    align: "left",
    label: "Genre",
    width: "15%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "priority",
    align: "left",
    label: "Priority",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
];

export const SegOrgScheduledTh = [
  {
    id: "resultOrder",
    align: "left",
    label: "Order",
    width: "17%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "type",
    align: "left",
    label: "Type",
    width: "12%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "cpName",
    align: "left",
    label: "CP Name",
    width: "12%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "id",
    align: "left",
    label: "Content ID",
    width: "16%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "contentName",
    align: "left",
    label: "Content Name",
    width: "18%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "image",
    align: "left",
    label: "Image",
    width: "14%",
    checkbox: false,
    sortable: false,
  },
  {
    id: "genre",
    align: "left",
    label: "Genre",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
];
