import React, {useEffect, useState} from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import {Box, Typography} from '@mui/material';
import MetadataChannel from "./MetadataChannel";
import MetadataVod from "./MetadataVod";
import MetadataProgram from "./MetadataProgram";
import MetadataSeries from "./MetadataSeries";

import PartnerMetadataChannel from './PartnerMetadataChannel';
import PartnerMetadataProgram from './PartnerMetadataProgram';
import PartnerMetadataVod from './PartnerMetadataVod';
import PartnerMetadataSeries from './PartnerMetadataSeries';

import { getHeaders } from 'utils/Header';
import { BASE_URL } from "constants";

import { metaDataTab, metaDataEditTab } from './data/MetaData';

function Metadata (props) {
  const { darkMode, type, message, isPartnerPageShow, currentUser } = props;
  const [tabValue, setTabValue] = useState(null);
  const [tabs, setTabs] = useState([]);
  // preferred country
  const [preferredCountry, setPreferredCountry] = useState('');

  // Partner 페이지에서 사용하는 provider 별 country 정보
  const [countryByProvider, setCountryByProvider] = useState([]);

  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: type === 'common' ? 'Metadata' : 'Metadata Edit',
  };

  useEffect(() => {
    if (isPartnerPageShow) {
      getCountryByCp();
    }
  }, [isPartnerPageShow]);

  useEffect(() => {
    type === 'common' ? setTabs(metaDataTab) : setTabs(metaDataEditTab);
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    const tempPreferredCountry = savedSettings && savedSettings.preferredCountry ? savedSettings.preferredCountry : 'US';
    setPreferredCountry(tempPreferredCountry);

    setTabValue(0);
  }, [type]);

  useEffect(() => {
    if (message) {
      if (message.tabValue) {
        setTabValue(message.tabValue);
      }
    }
  }, [message]);

  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setTabValue(newValue);
    if (message.filters) {
      message.filters = null;
    }
  };

  const getCountryByCp = () => {
    fetch(`${BASE_URL}/statistics/filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        if (body.data.countryByCp) {
          setCountryByProvider(body.data.countryByCp);
        }
      } else {
        throw new Error(`countryByCp request fail`);
      }
    })
    .catch(error => {
      console.error('Partner Metadata:', error);
    });
  }

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main'>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography className='title'>{type === 'common' ? 'Metadata' : 'Metadata Edit'}</Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu}/>
            </div>
          </div>
        </div>

        <Box className='content-wrap' component='div'>
          <div className='tab-container'>
            <CustomTabs tabStyle='type-01' value={tabValue}
                        handleChange={handleTabChange} tabs={tabs}/>
              {
                type === 'common' ? (
                  <>
                    {tabValue === 0 && (
                      isPartnerPageShow ? (
                        <PartnerMetadataChannel
                          type={type}
                          darkMode={darkMode}
                          message={message}
                          preferredCountry={preferredCountry}
                          tabValue={tabValue}
                          countryByProvider={countryByProvider}
                          currentUser={currentUser}
                        />
                      ) : (
                        <MetadataChannel
                          type={type}
                          darkMode={darkMode}
                          message={message}
                          preferredCountry={preferredCountry}
                          tabValue={tabValue}/>
                      )
                    )}

                    {tabValue === 1 && (
                      isPartnerPageShow ? (
                        <PartnerMetadataProgram
                          darkMode={darkMode}
                          message={message}
                          preferredCountry={preferredCountry}
                          tabValue={tabValue}
                          countryByProvider={countryByProvider}
                          currentUser={currentUser}
                        />
                      ) : (
                        <MetadataProgram
                          darkMode={darkMode}
                          message={message}
                          preferredCountry={preferredCountry}
                          tabValue={tabValue}
                        />
                      )
                    )}

                    {tabValue === 2 && (
                      isPartnerPageShow ? (
                        <PartnerMetadataVod
                          type={type}
                          darkMode={darkMode}
                          message={message}
                          preferredCountry={preferredCountry}
                          tabValue={tabValue}
                          countryByProvider={countryByProvider}
                          currentUser={currentUser}
                        />
                      ) : (
                        <MetadataVod
                          type={type}
                          darkMode={darkMode}
                          message={message}
                          preferredCountry={preferredCountry}
                          tabValue={tabValue}
                        />
                      )
                    )}

                    {tabValue === 3 && (
                      isPartnerPageShow ? (
                        <PartnerMetadataSeries
                          darkMode={darkMode}
                          message={message}
                          preferredCountry={preferredCountry}
                          tabValue={tabValue}
                          countryByProvider={countryByProvider}
                          currentUser={currentUser}
                        />
                      ) : (
                        <MetadataSeries
                          darkMode={darkMode}
                          message={message}
                          preferredCountry={preferredCountry}
                          tabValue={tabValue}
                        />
                      )
                    )}
                  </>
                ) : (
                  <>
                    {tabValue === 0 && (
                      <MetadataChannel
                        type={type}
                        darkMode={darkMode}
                        message={message}
                        preferredCountry={preferredCountry}
                        tabValue={tabValue}
                      />
                    )}

                    {tabValue === 1 && (
                      <MetadataVod
                        type={type}
                        darkMode={darkMode}
                        message={message}
                        preferredCountry={preferredCountry}
                        tabValue={tabValue}
                      />
                    )}
                  </>
                )
              }
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default Metadata;