export const RoleOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
];

export const selectOption = [
  { id: 'roleCode', value: 'Role Code', option: 'Role Code', type: 'text' },
  { id: 'id', value: 'Id', option: 'Id', type: 'text' },
  { id: 'cpCode', value: 'CP Code', option: 'CP Code', type: 'text' },
  { id: 'cpName', value: 'CP Name', option: 'CP Name', type: 'text' },
];

export const CPOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
];

export const CPParentsOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'CPParents-1', value: 'CPParents1', option: 'CPParents1', type: 'text' },
  { id: 'CPParents-2', value: 'CPParents2', option: 'CPParents2', type: 'text' },
];

export const LGPlayerOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'player-1', value: 'Role', option: 'Role', type: 'text' },
];

export const AdminTh = [
  {
    id: 'cpIcon',
    align: 'left',
    label: 'CP Icon',
    width: '12%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'id',
    align: 'left',
    label: 'ID',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'roleCode',
    align: 'left',
    label: 'Role',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cpCode',
    align: 'left',
    label: 'CP Code',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cpName',
    align: 'left',
    label: 'CP Name',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cpParentCode',
    align: 'left',
    label: 'CP Parents Code',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'cpParentName',
    align: 'left',
    label: 'CP Parents Name',
    width: '11%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'password',
    align: 'left',
    label: 'Password',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
];

export const AdminTd = [
  {
    id: 'AdminTd-1',
    data01: '',
    data02: 'admin',
    data03: 'SUPER_ADMIN',
    data04: 'RKT',
    data05: 'Rakuten',
    data06: '',
  },
  {
    id: 'AdminTd-2',
    data01: '',
    data02: 'admin',
    data03: 'SUPER_ADMIN',
    data04: 'RKT',
    data05: 'Rakuten',
    data06: '',
  },
  {
    id: 'AdminTd-3',
    data01: '',
    data02: 'admin',
    data03: 'SUPER_ADMIN',
    data04: 'RKT',
    data05: 'Rakuten',
    data06: '',
  },
];

export const CPMemberTh = [
  {
    id: 'cpm-id',
    align: 'left',
    label: 'ID',
    width: '30%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'cpm-Name',
    align: 'left',
    label: 'CP Name',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cpm-Access',
    align: 'left',
    label: 'Access',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cpm-Password',
    align: 'left',
    label: 'Password',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const CPMemberTd = [
  {
    id: 'AdminTd-1',
    data01: 'admin',
    data02: 'LGP',
    data03: 'On',
    data04: '',
  },
  {
    id: 'AdminTd-2',
    data01: 'lgchannels01',
    data02: 'XUMO',
    data03: 'On',
    data04: '',
  },
  {
    id: 'AdminTd-3',
    data01: 'lgchannels',
    data02: 'XUMO',
    data03: 'On',
    data04: '',
  },
  {
    id: 'AdminTd-4',
    data01: 'lgchannels',
    data02: 'XUMO',
    data03: 'On',
    data04: '',
  },
];
