import React, { useState, useEffect } from 'react';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { postRequest } from 'utils/Request';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

const homeAppStatus =[
  {
    id: 'S-T',
    value: 'S-T',
    option: 'Saved-Testing',
    type: 'text',
  },
  {
    id: 'S-P',
    value: 'S-P',
    option: 'Saved-Published',
    type: 'text',
  },
]

// 메인 컨텐츠
function PopCopyHomeApp(props) {
  const { regionList, regionPlatformList, deviceType, region, platformVersion, status, onClose, handelSetShowSnakBar, callTabCategoryList } = props;
  const [tab, setTab] = useState({
    deviceType: deviceType,
    region: region,
    platformVersion: platformVersion,
    status: status,
    cloneVersion: '',
  });

  const [deviceOptions, setDeviceOptions] = React.useState([]);
  const [regionOptions, setRegionOptions] = React.useState([]);
  const [platformVersionOptions, setPlatformVersionOptions] = React.useState([]);

  // deviceType selectBox 전체 목록 값
  useEffect(() => {
    const device = regionList.map(n => {
      const newItem = {
        id: n.deviceType,
        value: n.deviceType,
        option: n.deviceType,
        type: 'text',
        //list: n.list
      }
      return newItem;
    });
    setDeviceOptions(device);
    // eslint-disable-next-line
  }, [regionList]);

  useEffect(() => {
    const region = (regionList
      .find(n => n.deviceType === tab.deviceType) || { list: [] }).list
      .map(m => {
        const newItem = {
          id: m.region,
          value: m.region,
          option: m.region,
          type: 'text',
        }
        return newItem;
      });
    setRegionOptions(region);
    // eslint-disable-next-line
  }, [tab.deviceType]);

  useEffect(() => {
    const platform = regionPlatformList
      .filter(n => n.deviceType === tab.deviceType && n.region === tab.region)
      .map(m => {
        const newItem = {
          id: m.platformVersion,
          value: m.platformVersion,
          option: m.platformVersion,
          type: 'text',
        }
        return newItem;
      });
    setPlatformVersionOptions(platform);
    // eslint-disable-next-line
  }, [tab.region]);

  const handleClose = () => {
    onClose();
  };
  const onCancelBtn = () => {
    handleClose();
  };

  /* 탭 정보 수정 시 editTab 객체에 값을 변경 한다 Start */
  // Tab Device Type 값
  const onChangeDeviceType = (value) => {
    setTab((pevState) => ({
      ...pevState,
      deviceType: value,
    }));
  };
  // Tab Region 값
  const onChangeRegion = (value) => {
    setTab((pevState) => ({
      ...pevState,
      region: value,
    }));
  };
  // Tab Platform Version 값
  const onChangePlatformVersion = (value) => {
    setTab((pevState) => ({
      ...pevState,
      platformVersion: value,
    }));
  };
  // Tab Status 값
  const onChangeStatus = (value) => {
    setTab((pevState) => ({
      ...pevState,
      status: value,
    }));
  };
  // Clone PlatformVersion 값
  const onChangeCloneVersion = (event) => {
    const value = event.target.value;
    setTab((pevState) => ({
      ...pevState,
      cloneVersion: value,
    }));
  };
  /* 탭 정보 수정 시 editTab 객체에 값을 변경 한다 End */

  // Save버튼 클릭 시 탭 생성
  const clickSave = async event => {
    event.preventDefault();
    // device가 tv일 때 platformVersion(x.x.x) 포맷 체크
    if (tab.deviceType === 'tv') {
      if (!validatedVersion(tab.cloneVersion)) {
        alert('[Clone Home-App] Web os version format is incorrect.');
        return;
      }
    }

    const body = await postRequest(`/home-app/device/${tab.deviceType}/region/${tab.region}/platform/${tab.platformVersion}/status/${tab.status}/clone?cloneVersion=${tab.cloneVersion}`, null, null);
    if (body && body.result === 'SUCCESS') {
      //
    }
    console.log(body.result)

    handleClose();
    handelSetShowSnakBar({
      open: true,
      message: body.message,
      severity: body.severity,
    });
    callTabCategoryList();
  }

  const validatedVersion = text => {
    const pattern = /\d{1,3}.\d{1,3}.\d{1,3}/;
    return pattern.test(text);
  };

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: onCancelBtn,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: clickSave,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Copy Home-APP' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='large' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={6} mb={1}>
                {/* Device Type */}
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-01'
                    fullWidth
                    size='large'
                    width='100%'
                    disabled={false}
                    required={true}
                    selectOptions={deviceOptions}
                    label='Device Type'
                    selected={tab.deviceType}
                    onChange={onChangeDeviceType}
                    placeholder='Select'
                    shrink
                  />
                </Box>
              </Grid>
              {/* Region */}
              <Grid item xs={6} mb={1}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-02'
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    selectOptions={regionOptions}
                    disabled={false}
                    label='Region'
                    selected={regionOptions.find(n => n.value === tab.region) ? tab.region : regionOptions.length > 0 ? regionOptions[0].value : ''}
                    onChange={onChangeRegion}
                    placeholder='Select'
                    shrink
                  />
                </Box>
              </Grid>
              {/* Platform Version */}
              <Grid item xs={6} mb={1}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-03'
                    fullWidth
                    size='large'
                    width='100%'
                    disabled={false}
                    required={true}
                    selectOptions={platformVersionOptions}
                    label='Platform Version'
                    selected={platformVersionOptions.find(n => n.value === tab.platformVersion) ? tab.platformVersion : platformVersionOptions.length > 0 ? platformVersionOptions[0].value : ''}
                    onChange={onChangePlatformVersion}
                    placeholder='Select'
                    isFirstSelected={true}
                    shrink
                  />
                </Box>
              </Grid>
              {/* Status */}
              <Grid item xs={6} mb={1}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-04'
                    fullWidth
                    size='large'
                    width='100%'
                    disabled={false}
                    required={true}
                    selectOptions={homeAppStatus}
                    label='Status'
                    placeholder='Select'
                    selected={tab.status}
                    onChange={onChangeStatus}
                    shrink
                  />
                </Box>
              </Grid>
              {/* Clone Platform Version */}
              <Grid item xs={12} mb={1}>
                <Box component='div' className='field-box'>
                  <TextField
                    type='outlined'
                    size='large'
                    required={true}
                    placeholder='ex) 4.3.0'
                    label='Clone Platform Version'
                    autoComplete='off'
                    onChange={onChangeCloneVersion}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Typography component='p' className='input-sub-text' sx={{ paddingLeft: '14px' }}>
                  Please enter the platform version you wish to clone.
                </Typography>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopCopyHomeApp;
