import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';

import './App.css';
import 'assets/scss/common/reset.scss';
import 'assets/scss/common/common.scss';
import 'assets/scss/common/theme.scss';

// [Common]
import Entry from 'pages/Main/Launch/Entry';
import TopBar from 'components/layout/TopBar';
import Gnb from 'components/layout/Gnb';
import { initialCurrentUser, initialSettings } from 'pages/Main/Login/data/User';
import MyProfile from 'pages/Main/MyProfile/MyProfile';
import Notification from 'pages/Main/Support/Notification';

import { getCountry } from 'actions/common/countryActions';
import { getProvider } from 'actions/common/providerActions';

import { menuItemData, partnerMenuItemData } from 'components/layout/data/GnbItemData';

// [Inspection]
// Channel Status
import ChannelStatus from 'pages/Inspect/ChannelStatus/ChannelStatus';

// Metadata
import Metadata from 'pages/Inspect/Metadata/Metadata';
import MetadataChannelEdit from "./pages/Inspect/Metadata/MetadataChannelEdit";
import MetadataVodEdit from "./pages/Inspect/Metadata/MetadataVodEdit";
import MetadataChannelDetail from "./pages/Inspect/Metadata/MetadataChannelDetail";
import MetadataVodDetail from "./pages/Inspect/Metadata/MetadataVodDetail";
import MetadataProgramDetail from "./pages/Inspect/Metadata/MetadataProgramDetail";
import MetadataSeriesDetail from "./pages/Inspect/Metadata/MetadataSeriesDetail";

// [Organization]
// Channel Mapping
import ChannelMapping from 'pages/Organization/ChannelMapping/ChannelMapping';
import BulkUpload from 'pages/Organization/ChannelMapping/BulkUploadSaved';
import Reservation from 'pages/Organization/ChannelMapping/Reservation';
import ReservationEdit from 'pages/Organization/ChannelMapping/ReservationEdit';

// Tab/Category
import TabCategoryList from 'pages/Organization/TabCategory/TabCategoryList';
import CategoryList from 'pages/Organization/TabCategory/CategoryList';
import CommonManual from 'pages/Organization/TabCategory/CommonManual';
import CommonAuto from 'pages/Organization/TabCategory/CommonAuto';
import OrganizeSlider from 'pages/Organization/TabCategory/OrganizeSlider';
import OrganizeCarousel from 'pages/Organization/TabCategory/OrganizeCarousel';
import OrganizeUpcoming from 'pages/Organization/TabCategory/OrganizeUpcoming';
import CategoryListReorder from 'pages/Organization/TabCategory/CategoryListReorder';
import HistoryContents from 'pages/Organization/TabCategory/HistoryContents';
import WebService from 'pages/Organization/WebService/WebService';
import ListBanner from 'pages/Organization/WebService/PRPage/Banner/ListBanner';
import WebLiveManual from 'pages/Organization/WebService/PRPage/ContentChannelVod/WebLiveManual';
import CategoryOrganize from 'pages/Organization/WebService/PRPage/ContentChannelVod/CategoryOrganize';
import BrandingImageA from 'pages/Organization/WebService/PRPage/BrandingImage/BrandingImageA';
import CategoryDetailBrandingA from 'pages/Organization/WebService/PRPage/BrandingImage/CategoryDetailBrandingA';
import CategoryDetailBanner from 'pages/Organization/WebService/PRPage/Banner/CategoryDetailBanner';
import BrandingImageB from 'pages/Organization/WebService/PRPage/BrandingImage/BrandingImageB';
import CategoryDetailBrandingB from 'pages/Organization/WebService/PRPage/BrandingImage/CategoryDetailBrandingB';
import BannerOrder from 'pages/Organization/WebService/PRPage/Banner/BannerOrder';

// AN Test & AN Service
import ANTestService from 'pages/Organization/ANTestService/ANTestService';
import ANTestServiceSegment from 'pages/Organization/ANTestService/ANTestServiceSegment';
import ANTestServiceSegmentOrganize from 'pages/Organization/ANTestService/ANTestServiceSegmentOrganize';
import ANTestServiceSegmentOrganizeAddGroup from 'pages/Organization/ANTestService/ANTestServiceSegmentOrganizeAddGroup';
import ANTestServiceSegmentOrganizeOrganize from 'pages/Organization/ANTestService/ANTestServiceSegmentOrganizeOrganize';
import ANTestServiceResult from 'pages/Organization/ANTestService/ANTestServiceResult';

// Device Notification
import DeviceNotification from 'pages/Organization/DeviceNotification/DeviceNotification';
import DeviceNotificationDetail from 'pages/Organization/DeviceNotification/DeviceNotificationDetail';
import DeviceNotificationHistory from 'pages/Organization/DeviceNotification/DeviceNotificationHistory';

// [Promotion]
// Free On LG Channels Shelf
import FreeOnLGChannelsShelf from 'pages/Promotion/FreeOnLGChannelsShelf/FreeOnLGChannelsShelf';
import FreeOnLGChannelsShelfHistory from 'pages/Promotion/FreeOnLGChannelsShelf/FreeOnLGChannelsShelfHistory';
import FreeOnLGChannelsShelfHistoryDetail from 'pages/Promotion/FreeOnLGChannelsShelf/FreeOnLGChannelsShelfHistoryDetail';

// Watch On LG Channels Shelf
import WatchOnLGChannelsShelf from 'pages/Promotion/WatchOnLGChannelsShelf/WatchOnLGChannelsShelf';

// LG Channels Shelf
import LGChannelsShelf from 'pages/Promotion/LGChannelsShelf/LGChannelsShelf';

// Interactive Dock - Recommendation
import InteractiveDock from 'pages/Promotion/InteractiveDock/InteractiveDock';
import CategoryTitle from 'pages/Promotion/InteractiveDock/CategoryTitle';
import InteractiveDockOrganize from 'pages/Promotion/InteractiveDock/InteractiveDockOrganize';

// [Configuration]
// Account
import Account from 'pages/Configuration/Account/Account';

// Code Management
import CodeManagement from './pages/Configuration/CodeManagement/CodeManagement';
import VODGenreDetail
  from "./pages/Configuration/CodeManagement/VODGenreDetail";
import AccountPermissionsAdd
  from "./pages/Configuration/CodeManagement/AccountPermissionsAdd";
import AccountPermissionsEdit
  from "./pages/Configuration/CodeManagement/AccountPermissionsEdit";

// Policy Common
import PolicyAdd from 'pages/Configuration/Policy/PolicyAdd';
import PolicyEdit from 'pages/Configuration/Policy/PolicyEdit';
import PolicyHistory from 'pages/Configuration/Policy/PolicyHistory';
// Terms Of Use
import TermsOfUse from 'pages/Configuration/Policy/TermsOfUse';
// Privacy Policy
import PrivacyPolicy from 'pages/Configuration/Policy/PrivacyPolicy';

// Support
import Support from 'pages/Configuration/Support/Support';

// [Monitoring]
// Emulate
import Emulate from 'pages/Monitoring/Emulate/Emulate';

// API Call Logs
import APICallLogs from 'partner_pages/Monitoring/APICallLogs/APICallLogs';

// [Statistics]
import Statistics from 'partner_pages/Statistics/Statistics';

function App({ getCountry, getProvider }) {
  const [currentUser, setCurrentUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [message, setMessage] = useState('');
  const [isDisplayApp, setDisplayApp] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [targetComponent, setTargetComponent] = useState('');
  const [isPartnerPageShow, setPartnerPageShow] = useState(null);
  const [settings, setSettings] = useState(null);
  const [recentlyUsedMenu, setRecentlyUsedMenu] = useState({ adminMenu: [], partnerMenu: [] });

  // Lnb menu 가 아닌 다른 곳에서 선택한 경우를 처리하기 위한 용도, 값이 10000이면 Lnb 메뉴 선택 초기화
  const [selectedIdFromNonLnb, setSelectedIdFromNonLnb] = useState(null);

  // Recent Used Menu 로 부터 변경된 favorite menu 를 저장하기 위한 용도
  const [changedFavoriteMenuItem, setChangedFavoriteMenuItem] = useState(null);

  // Favorite Menu Popup 에서 favorite item이 변경된 경우에 Recent Used Menu 에 반영하기 위한 용도
  const [changedFavoriteMenuItems, setChangedFavoriteMenuItems] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setMessage('');
    setTargetComponent('');
    location.state = null;

    const savedCurrentUser = JSON.parse(localStorage.getItem('currentUser')) || initialCurrentUser;
    setCurrentUser(savedCurrentUser);

    if (savedCurrentUser && savedCurrentUser.isAuthenticated && !isSessionExpired(savedCurrentUser)) {
      setIsAuthenticated(true);
      updateSessionTime(savedCurrentUser);
    } else {
      setIsAuthenticated(false);
    }

    const darkMode = localStorage.getItem('darkMode') || 'off';
    if (darkMode === 'on') {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }

    const tempSavedSettings = JSON.parse(localStorage.getItem('settings'));
    if (tempSavedSettings) {
      setPartnerPageShow(tempSavedSettings.isPartnerPageShow || false);
      setSettings(tempSavedSettings);
    } else {
      setSettings(initialSettings);
    }

    const SavedRecentlyUsedMenu = JSON.parse(localStorage.getItem('recentlyUsedMenu'));

    if (SavedRecentlyUsedMenu) {
      setRecentlyUsedMenu(SavedRecentlyUsedMenu);
    } else {
      setRecentlyUsedMenu({ adminMenu: [], partnerMenu: [] });
    }

    setDisplayApp(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Admin 실행시에 로그인이 완료된 이후에 서버에서 국가 정보와 CP 정보를 가져옴
    if (isAuthenticated) {
      getCountry();
      getProvider();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (location.state) {
      if (location.state.auth) {
        const savedCurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        setCurrentUser(savedCurrentUser);

        if (location.state.auth === 'true') {
          setIsAuthenticated(true);
        } else if (location.state.auth === 'false') {
          setIsAuthenticated(false);
        }

        if (savedCurrentUser.role === 'ROLE_CP' || savedCurrentUser.role === 'ROLE_STUDIO') {
          setPartnerPageShow(true);
        } else {
          setPartnerPageShow(false);
        }

        setMessage(location.state.message);
        setTargetComponent('');
      } else if (location.state.move) {
        if (location.state.targetComponent) {
          setMessage(location.state.message);
          setTargetComponent(location.state.targetComponent);

          if (location.state.targetComponent === 'PartnerMain') {
            setPartnerPageShow(true);
          } else if (location.state.targetComponent === 'Main') {
            setPartnerPageShow(false);
          }
        } else {
          setMessage('');
          setTargetComponent('');
        }

        if (location.state.selectedId) {
          setSelectedIdFromNonLnb(location.state.selectedId);
        } else {
          setSelectedIdFromNonLnb(null);
        }
      }

      location.state = null;
    } else {
      setMessage('');
      setTargetComponent('');
    }
  }, [location]);

  useEffect(() => {
    if (settings) {
      localStorage.setItem('settings', JSON.stringify(settings));
    }
  }, [settings]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }

    updateSettings({ darkMode: darkMode ? 'on' : 'off' });
  }, [darkMode]);

  useEffect(() => {
    if (isPartnerPageShow !== null) {
      updateSettings({ isPartnerPageShow: isPartnerPageShow });
    }
  }, [isPartnerPageShow]);

  useEffect(() => {
    if (recentlyUsedMenu) {
      localStorage.setItem('recentlyUsedMenu', JSON.stringify(recentlyUsedMenu));
    }
  }, [recentlyUsedMenu]);

  const updateSessionTime = (currentUser) => {
    const updateCurrentUser = {
      ...currentUser,
      loginTime: new Date().toISOString(),
    };

    localStorage.setItem('currentUser', JSON.stringify(updateCurrentUser));
  };

  const isSessionExpired = (currentUser) => {
    if (!currentUser.loginTime) return true;

    const loginTime = new Date(currentUser.loginTime);
    const currentTime = new Date();
    const timeDifference = currentTime - loginTime;
    const minutesDifference = timeDifference / 1000;

    return minutesDifference > currentUser.sessionTimeout; // 30 minutes
  };

  const handleDarkMode = (value) => {
    setDarkMode(value);
  };

  const updateSettings = (newSettings) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...newSettings,
    }));
  };

  const handleSelectedId = (selectedId) => {
    const currentMenuItemData = isPartnerPageShow ? partnerMenuItemData : menuItemData;

    let selectedItem;
    currentMenuItemData.some((item) => {
      const subItem = item.subItems.find((sub) => sub.id === (selectedId > 1000 ? selectedId - 1000 : selectedId));
      if (subItem) {
        selectedItem = {
          id: 1,
          title: subItem.text,
          breadCrumb: `Home > ${item.primaryText} > ${subItem.text}`,
          path: subItem.path,
          menuId: subItem.id,
        };
        return true;
      }
      return false;
    });

    let currentRecentlyUsedMenu = isPartnerPageShow ? recentlyUsedMenu.partnerMenu : recentlyUsedMenu.adminMenu;

    const existingItemIndex = currentRecentlyUsedMenu.findIndex((item) => item.title === selectedItem.title);
    if (existingItemIndex !== -1) {
      currentRecentlyUsedMenu.splice(existingItemIndex, 1);
    }

    currentRecentlyUsedMenu = currentRecentlyUsedMenu.map((item) => ({ ...item, id: item.id + 1 }));
    currentRecentlyUsedMenu.unshift(selectedItem);

    currentRecentlyUsedMenu.forEach((item, index) => {
      item.id = index + 1;
    });

    const limitedRecentlyUsedMenu = currentRecentlyUsedMenu.slice(0, 10); // 최근 사용 메뉴는 최대 10개까지만 저장

    const updatedRecentlyUsedMenu = {
      ...recentlyUsedMenu,
      [isPartnerPageShow ? 'partnerMenu' : 'adminMenu']: limitedRecentlyUsedMenu,
    };

    setRecentlyUsedMenu(updatedRecentlyUsedMenu);
  };

  // Recently Used Menu 에서 Favorite item이 변경된 경우에 Favorite Menu에 반영하기 위한 용도
  const handleFavoriteMenuItemChange = (item) => {
    setChangedFavoriteMenuItem(item);
  };

  // Favorite Menu Popup 에서 favorite item이 변경된 경우에 Recent Used Menu 에 반영하기 위한 용도
  const handleFavoriteMenuItemsChange = (items) => {
    setChangedFavoriteMenuItems(items);
  };

  return (
    isDisplayApp && (
      <>
        <CssBaseline />
        <Box display='flex' className='wrapper'>
          {isAuthenticated &&
            <Gnb
              currentUser={currentUser}
              darkMode={darkMode}
              isPartnerPageShow={isPartnerPageShow}
              callbackSelectedId={handleSelectedId}
              selectedIdFromNonLnb={selectedIdFromNonLnb}
              changedFavoriteMenuItem={changedFavoriteMenuItem}
              callbackFavoriteMenuItemsChange={handleFavoriteMenuItemsChange}
            />
          }
          <Box className='main-container' sx={{ flexGrow: 1 }}>
            {isAuthenticated && <TopBar currentUser={currentUser} darkMode={darkMode} isPartnerPageShow={isPartnerPageShow} />}
            <Routes>
              {/* Main */}
              <Route
                path='/'
                element={
                  targetComponent === 'MyProfile' ? (
                    <MyProfile currentUser={currentUser} darkMode={darkMode} callBackDarkMode={handleDarkMode} />
                  ) : targetComponent === 'Notification' ? (
                    <Notification currentUser={currentUser} darkMode={darkMode} isPartnerPageShow={isPartnerPageShow} />
                  ) : (
                    <Entry
                      isAuthenticated={isAuthenticated}
                      message={message}
                      currentUser={currentUser}
                      darkMode={darkMode}
                      isPartnerPageShow={isPartnerPageShow}
                      recentlyUsedMenu={recentlyUsedMenu}
                      callbackFavoriteMenuChange={handleFavoriteMenuItemChange}
                      changedFavoriteMenuItems={changedFavoriteMenuItems}
                    />
                  )
                }
              />

              {/* Inspection */}
              <Route
                path='/ChannelStatus'
                element={isAuthenticated ?
                  <ChannelStatus
                    darkMode={darkMode}
                    isPartnerPageShow={isPartnerPageShow}
                    currentUser={currentUser}
                  /> :
                  <Navigate to='/' replace />} />
              <Route
                path='/Metadata'
                element={
                  isAuthenticated ? (
                    targetComponent === 'MetadataChannelDetail' ? (
                      <MetadataChannelDetail darkMode={darkMode} message={message} />
                    ) : targetComponent === 'MetadataProgramDetail' ? (
                      <MetadataProgramDetail darkMode={darkMode} message={message} />
                    ) : targetComponent === 'MetadataVodDetail' ? (
                      <MetadataVodDetail darkMode={darkMode} message={message} />
                    ) : targetComponent === 'MetadataSeriesDetail' ? (
                      <MetadataSeriesDetail darkMode={darkMode} message={message} />
                    ) : (
                      <Metadata
                        type='common'
                        darkMode={darkMode}
                        message={message}
                        isPartnerPageShow={isPartnerPageShow}
                        currentUser={currentUser}
                      />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              {/* Organization */}
              <Route
                path='/ChannelMapping'
                element={
                  isAuthenticated ? (
                    targetComponent === 'BulkUpload' ? (
                      <BulkUpload darkMode={darkMode} message={message} />
                    ) : targetComponent === 'Reservation' ? (
                      <Reservation darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ReservationEdit' ? (
                      <ReservationEdit darkMode={darkMode} message={message} />
                    ) : (
                      <ChannelMapping darkMode={darkMode} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route
                path='/TabCategoryList'
                element={
                  isAuthenticated ? (
                    targetComponent === 'CategoryList' ? (
                      <CategoryList darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ManualOrganize' ? (
                      <CommonManual darkMode={darkMode} message={message} />
                    ) : targetComponent === 'AutoOrganize' ? (
                      <CommonAuto darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CarouselOrganize' ? (
                      <OrganizeCarousel darkMode={darkMode} message={message} />
                    ) : targetComponent === 'SliderOrganize' ? (
                      <OrganizeSlider darkMode={darkMode} message={message} />
                    ) : targetComponent === 'UpcomingOrganize' ? (
                      <OrganizeUpcoming darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CategoryListReorder' ? (
                        <CategoryListReorder darkMode={darkMode} message={message} />
                    ) : targetComponent === 'HistoryContents' ? (
                        <HistoryContents darkMode={darkMode} message={message} />
                    ) : (
                      <TabCategoryList darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route
                path='/WebService'
                element={
                  isAuthenticated ? (
                    targetComponent === 'ListBanner' ? (
                      <ListBanner darkMode={darkMode} message={message} />
                    ) : targetComponent === 'WebLiveManual' ? (
                      <WebLiveManual darkMode={darkMode} message={message} />
                    ) : targetComponent === 'WebVodManual' ? (
                      <WebLiveManual darkMode={darkMode} message={message} />
                    ) : targetComponent === 'BrandingImageA' ? (
                      <BrandingImageA darkMode={darkMode} message={message} />
                    ) : targetComponent === 'BrandingImageB' ? (
                      <BrandingImageB darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CategoryDetailBanner' ? (
                      <CategoryDetailBanner darkMode={darkMode} message={message} />
                    ) : targetComponent === 'BannerOrder' ? (
                      <BannerOrder darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CategoryDetailBrandingA' ? (
                      <CategoryDetailBrandingA darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CategoryDetailBrandingB' ? (
                      <CategoryDetailBrandingB darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CategoryOrganize' ? (
                      <CategoryOrganize darkMode={darkMode} message={message} />
                    ) : (
                      <WebService darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route
                path='/ANTest'
                element={
                  isAuthenticated ? (
                    targetComponent === 'ANTestServiceSegment' ? (
                      <ANTestServiceSegment type='antest' darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestServiceSegmentOrganize' ? (
                      <ANTestServiceSegmentOrganize type='antest' darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestServiceSegmentOrganizeAddGroup' ? (
                      <ANTestServiceSegmentOrganizeAddGroup type='antest' darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestServiceSegmentOrganizeOrganize' ? (
                      <ANTestServiceSegmentOrganizeOrganize type='antest' darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestServiceResult' ? (
                      <ANTestServiceResult type='antest' darkMode={darkMode} message={message} />
                    ) : (
                      <ANTestService type='antest' darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route
                path='/ANService'
                element={
                  isAuthenticated ? (
                    targetComponent === 'ANTestServiceSegment' ? (
                      <ANTestServiceSegment type='anservice' darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestServiceSegmentOrganize' ? (
                      <ANTestServiceSegmentOrganize type='anservice' darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestServiceSegmentOrganizeAddGroup' ? (
                      <ANTestServiceSegmentOrganizeAddGroup type='anservice' darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestServiceSegmentOrganizeOrganize' ? (
                      <ANTestServiceSegmentOrganizeOrganize type='anservice' darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestServiceResult' ? (
                      <ANTestServiceResult type='anservice' darkMode={darkMode} message={message} />
                    ) : (
                      <ANTestService type='anservice' darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route
                path='/DeviceNotification'
                element={
                  isAuthenticated ? (
                    targetComponent === 'DeviceNotificationDetail' ? (
                        <DeviceNotificationDetail darkMode={darkMode} message={message} />
                    ) : targetComponent === 'DeviceNotificationHistory' ? (
                        <DeviceNotificationHistory darkMode={darkMode} message={message} />
                    ) : (
                        <DeviceNotification darkMode={darkMode} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              {/* Promotion */}
              <Route
                path='/FreeOnLGChannelsShelf'
                element={
                  isAuthenticated ? (
                    targetComponent === 'History' ? (
                      <FreeOnLGChannelsShelfHistory currentUser={currentUser} darkMode={darkMode} message={message} />
                    ) : targetComponent === 'HistoryDetail' ? (
                      <FreeOnLGChannelsShelfHistoryDetail currentUser={currentUser} darkMode={darkMode} message={message} />
                    ) : (
                      <FreeOnLGChannelsShelf currentUser={currentUser} darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route path='/WatchOnLGChannelsShelf' element={isAuthenticated ? <WatchOnLGChannelsShelf darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/LGChannelsShelf' element={isAuthenticated ? <LGChannelsShelf darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route
                path='/InteractiveDock'
                element={
                  isAuthenticated ? (
                    targetComponent === 'CategoryTitle' ? (
                      <CategoryTitle currentUser={currentUser} darkMode={darkMode} message={message} />
                    ) : targetComponent === 'InteractiveDockOrganize' ? (
                      <InteractiveDockOrganize currentUser={currentUser} darkMode={darkMode} message={message} />
                    ) : (
                      <InteractiveDock currentUser={currentUser} darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />

              {/* Configuration */}
              <Route path='/Account' element={isAuthenticated ? <Account darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route
                  path='/CodeManagement'
                  element={isAuthenticated ? (
                    targetComponent === 'VODGenreDetail' ? (
                        <VODGenreDetail darkMode={darkMode} message={message} />
                    ) : targetComponent === 'AccountPermissionsAdd' ? (
                       <AccountPermissionsAdd darkmode={darkMode} message={message} />
                    ) : targetComponent === 'AccountPermissionsEdit' ? (
                       <AccountPermissionsEdit darkMode={darkMode} message={message} />
                    ) : (
                        <CodeManagement darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )} />
              <Route path='/MetadataEdit' element={isAuthenticated ?
                  targetComponent === 'MetadataChannelEdit' ? (
                      <MetadataChannelEdit darkMode={darkMode} message={message}/>
                  ) : targetComponent === 'MetadataVodEdit' ? (
                      <MetadataVodEdit darkMode={darkMode} message={message}/>
                  ) : <Metadata type='edit' darkMode={darkMode} message={message}/> :
                  <Navigate to='/' replace/>}/>
              <Route
                path='/TermsOfUse'
                element={
                  isAuthenticated ? (
                    targetComponent === 'PolicyAdd' ? (
                      <PolicyAdd darkMode={darkMode} message={message} />
                    ) : targetComponent === 'PolicyEdit' ? (
                      <PolicyEdit darkMode={darkMode} message={message} />
                    ) : targetComponent === 'PolicyHistory' ? (
                      <PolicyHistory darkMode={darkMode} message={message} />
                    ) : (
                      <TermsOfUse darkMode={darkMode} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route
                path='/PrivacyPolicy'
                element={
                  isAuthenticated ? (
                    targetComponent === 'PolicyAdd' ? (
                      <PolicyAdd darkMode={darkMode} message={message} />
                    ) : targetComponent === 'PolicyEdit' ? (
                      <PolicyEdit darkMode={darkMode} message={message} />
                    ) : targetComponent === 'PolicyHistory' ? (
                      <PolicyHistory darkMode={darkMode} message={message} />
                    ) : (
                      <PrivacyPolicy darkMode={darkMode} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route path='/Support' element={isAuthenticated ? <Support darkMode={darkMode} /> : <Navigate to='/' replace />} />

              {/* Monitoring */}
              <Route path='/Emulate' element={isAuthenticated ? <Emulate darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/APICallLogs' element={isAuthenticated ? <APICallLogs currentUser={currentUser} darkMode={darkMode} /> : <Navigate to='/' replace />} />

              {/* Statistics */}
              <Route path='/Channel' element={isAuthenticated ? <Statistics type='channel' currentUser={currentUser} darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/Program' element={isAuthenticated ? <Statistics type='program' currentUser={currentUser} darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/Vod' element={isAuthenticated ? <Statistics type='vod' currentUser={currentUser} darkMode={darkMode} /> : <Navigate to='/' replace />} />
            </Routes>
          </Box>
        </Box>
      </>
    )
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getCountry: () => dispatch(getCountry()),
  getProvider: () => dispatch(getProvider()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
