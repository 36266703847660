import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CustomTabs from 'components/component/BasicTabs';
import Snackbar from 'components/component/BasicSnackbar';
import TextLink from 'components/component/BasicTextLink';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import Dialog from 'components/component/BasicDialog';
import Loading from 'components/component/Loading';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Divider,
} from '@mui/material';

import { SupportTitle } from 'pages/Main/Support/data/NotificationData';

import {
  ArrowUpIcon,
  ArrowDownIcon,
  DownloadGrayIcon,
  DarkDownloadGrayIcon,
  ArrowUpDarkIcon,
  ArrowDownDarkIcon,
} from 'components/component/BasicIcon';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import PopNotification from './PopNotification';
import { downloadZip } from './DownloadAll';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

const SupportTab = [
  { label: 'Notification', badgeType: '' },
  { label: 'FAQ', badgeType: '' },
];

const optionMenu = {
  normalMenu: ['Support'],
};

function Notification(props) {
  const { darkMode, isPartnerPageShow } = props;

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // 로딩바를 보여 줄 때 사용
  const [isDisplayData, setDisplayData] = useState(false);

  // 서버에서 받은 원본 데이터
  const [originalDataList, setOriginalDataList] = useState([]);

  // 화면에서 사용할 Channel List
  const [displayDataList, setDisplayDataList] = useState([]);

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // 테이블 결과 검색에 입력한 값
  const [searchTerm, setSearchTerm] = useState('');

  // 화면에 보여 줄 데이터가 없는 경우 체크
  const [isEmptyData, setEmptyData] = useState(false);

  // Current tab value
  const [currentTabValue, setCurrentTabValue] = useState(0);

  // Current channel (CMP or Partner)
  const [currentChannel, setCurrentChannel] = useState(null);

  // 현재 열려있는 테이블 행의 ID를 관리하는 state
  const [openRow, setOpenRow] = useState(null);

  // 현재 선택된 Support data
  const [currentSupportData, setCurrentSupportData] = useState({});

  // file list
  const [fileList, setFileList] = useState([]);

  // Notification popup
  const [isOpen, setIsOpen] = useState(false);

  // Snackbar
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (currentSupportData.hasAttachment === 'Y') {
      const fileNames = currentSupportData.attachmentFileNames.split(',');
      const filePaths = currentSupportData.attachmentFilePaths.split(',');
      const files = fileNames.map((fileName, index) => ({
        id: index,
        fileName: fileName.trim(),
        filePath: filePaths[index].trim(),
      }));
      setFileList(files);
    }
  }, [currentSupportData]);

  useEffect(() => {
    if (isPartnerPageShow !== null) {
      setCurrentChannel(isPartnerPageShow ? 'Partner' : 'CMP');
    }
  }, [isPartnerPageShow]);

  useEffect(() => {
    if (currentChannel !== null) {
      currentTabValue === 0 ? getNotificationAll() : getFaqAll();
    }
    // eslint-disable-next-line
  }, [currentTabValue, currentChannel]);

  // Paging 처리
  useEffect(() => {
    if (displayDataList && displayDataList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayDataList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setDisplayData(true);
    }
  }, [displayDataList, page, itemsPerPage]);

  useEffect(() => {
    if (searchTerm) {
      const filteredData = originalDataList.filter((item) => {
        return Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        );
      });

      setDisplayDataList([...filteredData]);
      if (filteredData.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    } else {
      setDisplayDataList([...originalDataList]);
      if (originalDataList.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    }
  }, [searchTerm, originalDataList]);

  // Tab
  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setCurrentTabValue(newValue);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayDataList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayDataList([...sortedData]);
  };

  const handleSearchContent = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  const initialize = () => {
    setOriginalDataList([]);
    setDisplayDataList([]);
    setPaginatedData([]);
    setPage(1);
    setItemsPerPage(20);
    setOrder('asc');
    setOrderBy('');
    setSearchTerm('');
    setEmptyData(false);
    setDisplayData(false);
    setOpenRow(null);
  };

  const handleItemClick = (event, idx) => {
    event.preventDefault();
    setCurrentSupportData(displayDataList.find((item) => item.idx === idx));

    if (currentTabValue === 0) {
      setIsOpen(true);
    } else {
      setOpenRow(openRow === idx ? null : idx);
    }
  };

  const renderIcon = (idx) => {
    return openRow === idx ? (
      darkMode ? (
        <ArrowUpDarkIcon />
      ) : (
        <ArrowUpIcon />
      )
    ) : darkMode ? (
      <ArrowDownDarkIcon />
    ) : (
      <ArrowDownIcon />
    );
  };

  const getNotificationAll = () => {
    const param = `channel=${currentChannel}`;

    initialize();

    fetch(`${BASE_URL}/support/notification/all?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setOriginalDataList(body.data.notifications);
        if (body.data.notifications.length === 0) {
          setEmptyData(true);
          setDisplayData(true);
        }
      } else {
        throw new Error(`support/notification/all request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const getFaqAll = () => {
    const param = `channel=${currentChannel}`;

    initialize();

    fetch(`${BASE_URL}/support/faq/all?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setOriginalDataList(body.data.faqs);
        if (body.data.faqs.length === 0) {
          setEmptyData(true);
          setDisplayData(true);
        }
      } else {
        throw new Error(`support/faq/all request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDownloadAll = async () => {
    const response = await downloadZip(fileList);

    if (response !== 'SUCCESS') {
      setSnackbarMessage(response);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Support
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap ' component='div'>
          <Box className='notification-container'>
            <Box className='white-box white-box-wrap single-box '>
              <div className='tab-content-area'>
                <CustomTabs tabStyle='type-01' value={currentTabValue} handleChange={handleTabChange} tabs={SupportTab} />
                {isDisplayData ? (
                  <div className='box-content'>
                    <div className='tab-panel'>
                      <div className='panel-wrap'>
                        <Box className='white-box-content-top-area'>
                          <Box className='left-area'>
                            <SearchField
                              placeholder='Please enter a search term'
                              maxWidth='230px'
                              onChange={(event) => handleSearchContent(event.target.value)}
                            />
                          </Box>
                          <Box className='right-area'></Box>
                        </Box>
                        <Box className='content-inner-wrap'>
                          <CustomTableContainer className='table-container'>
                            <Table className='custom-table' aria-label='table' stickyHeader>
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {SupportTitle.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {isEmptyData ? (
                                <NoRecords>
                                  No records to display
                                </NoRecords>
                              ) : (
                                paginatedData.map((td, index) => (
                                  <React.Fragment key={td.idx}>
                                    <TableRow
                                      className={currentTabValue === 1 && openRow === td.idx ? 'expanded' : ''}
                                    >
                                      {SupportTitle.map((column, colIndex) => (
                                        <TableCell key={column.id} align={column.align}>
                                          {colIndex === 0 ? (
                                            <>
                                              {
                                                currentTabValue === 1 &&
                                                  <IconButton onClick={(event) => handleItemClick(event, td.idx)}>
                                                    {renderIcon(td.idx)}
                                                  </IconButton>
                                              }
                                              {currentTabValue === 0 && td.fixFlag === 'Y' ? '[fix]' : td.idx}
                                            </>
                                          ) : colIndex === 1 ? (
                                            td.category
                                          ) : colIndex === 2 ? (
                                            currentTabValue === 0 ? (
                                              <TextLink
                                                href='#'
                                                text={td.title}
                                                onClick={(event) => handleItemClick(event, td.idx)}
                                              />
                                            ) : (
                                              td.title
                                            )
                                          ) : colIndex === 3 ? (
                                            td.crtDate
                                          ) : (
                                            <></>
                                          )}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    {currentTabValue === 1 && (
                                      <TableRow className='dropwdown-content-wrap'>
                                        <TableCell colSpan={SupportTitle.length}>
                                          <Collapse
                                            in={openRow === td.idx}
                                            timeout='auto'
                                            unmountOnExit
                                            className='dropwdown-box'
                                          >
                                            <Box className='dropwdown-content' margin={1}>
                                              <Typography className='faq-title' variant='p' gutterBottom component='div'>
                                                FAQ Title
                                              </Typography>
                                              <Box className='faq-text-body'>
                                                <Typography className='faq-text'>{td.content}</Typography>
                                              </Box>
                                              <Divider className='divider' />
                                              <Box className='down-list-wrap'>
                                                <Box className='down-box-header'>
                                                  <Box className='total-count'>
                                                    <Box component='span' className='label'>
                                                      Total
                                                    </Box>
                                                    <Box component='span' className='value'>
                                                      {fileList.length}
                                                    </Box>
                                                  </Box>
                                                  <Button
                                                    color=''
                                                    type='icon-text'
                                                    size='medium'
                                                    label='Download All'
                                                    isIcon={true}
                                                    Icon={darkMode ? DarkDownloadGrayIcon : DownloadGrayIcon}
                                                    iconPos='left'
                                                    onClick={handleDownloadAll}
                                                    disabled={fileList.length === 0}
                                                  />
                                                </Box>
                                                <Box className='down-box-body'>
                                                  <Box className='file-item'>
                                                    {fileList.map((item) => (
                                                      <TextLink
                                                        href={item.filePath}
                                                        className='down-file-item'
                                                        text={item.fileName}
                                                      />
                                                    ))}
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </React.Fragment>
                                ))
                              )}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                          <Pagination count={Math.ceil(displayDataList.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
                        </Box>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
      <PopNotification darkMode={darkMode} currentNotification={currentSupportData} isOpen={isOpen} callbackClose={handleClose} />
      <Snackbar
        severity='warning'
        message={snackbarMessage}
        open={isSnackbarOpen}
        duration={1000}
        bottom={'8%'}
        darkMode={darkMode}
        onClose={handleSnackbarClose}
      />
      <Dialog
        open={isAlertOpen}
        handleClose={handleAlertClose}
        closeX={false}
        size='small'
        title={alertTitle}
        contentTitle={false}
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </Box>
  );
}

export default Notification;
