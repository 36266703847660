import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CustomImage from 'components/component/BasicImage';
import TextLink from 'components/component/BasicTextLink';
import Button from 'components/component/BasicButton';
import CustomBasicMenu from 'components/component/BasicMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getRequest } from 'utils/Request';
import CheckBox from 'components/component/BasicCheckBox';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Dialog from "../../../components/component/BasicDialog";
import { styled } from '@mui/material/styles';
import PopDepthHomeShelf from './PopDepthHomeShelf';
import { BASE_URL } from 'constants/index';
import { ShelfTh, PreviewTh } from './data/LGChannelsShelfData';
import { getHeaders } from 'utils/Header';

// 메인 컨텐츠
function LGChannelsShelfLink(props) {
  const { message, serviceCountry } = props;
  const [optionRegionList, setOptionRegionList] = useState([]);
  const [optionCountryList, setOptionCountryList] = useState([]);
  const [optionDeviceTypeList, setOptionDeviceTypeList] = useState([]);
  const [optionSdkVersionList, setOptionSdkVersionList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('All');
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [selectedDeviceType, setSelectedDeviceType] = useState('webOSAuto');
  const [selectedSdkVersion, setSelectedSdkVersion] = useState('');
  const [settingList, setSettingList] = useState([]);
  const [previewList, setPreviewList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    height: '100%',
    overflowY: 'auto',
  }));

  useEffect(() => {
    if (message) {
      if (message.filter) {
        setSelectedRegion(message.filter.selectedRegion);
        setSelectedCountry(message.filter.selectedCountry);
        setSelectedDeviceType(message.filter.selectedDeviceType);
      }
    }
  }, [message]);

  useEffect(() => {
    if (serviceCountry) {
      getSdkVersionList();
      const regionList = ["All", ...new Set(serviceCountry.map((item) => item.bizArea))];
      setOptionRegionList(regionList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      })));
      const deviceTypeList = [...new Set(serviceCountry.flatMap(item => item.channelMapList.map(subItem => `webOS${subItem.deviceType}`)))];
      setOptionDeviceTypeList(deviceTypeList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      })));
    }
  }, [serviceCountry]);

  useEffect(() => {
    if (selectedRegion && serviceCountry) {
      const countryList = selectedRegion === "All"
      ? serviceCountry.map(item => item.countryCode)
      : serviceCountry.filter((item) => item.bizArea === selectedRegion).map(item => item.countryCode);
      setOptionCountryList(countryList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      })));

      if (selectedRegion === "All") {
        setSelectedCountry('US');
      } else if (countryList.length > 0) {
        setSelectedCountry(countryList[0]);
      }
      setCheckedItems({});
    }
  }, [selectedRegion, serviceCountry]);

  useEffect(() => {
    getContentList();
    setCheckedItems({});
    // eslint-disable-next-line
  }, [selectedCountry, selectedDeviceType]);

  useEffect(() => {
    getPreviewList();
    // eslint-disable-next-line
  }, [selectedSdkVersion]);

  const getSdkVersionList = async () => {
    const body = await getRequest('/sdepth/feedmap/sdksversion', null);
    if (body && body.result === 'SUCCESS') {
      setOptionSdkVersionList(body.data.sdepthSdkVerList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      })));
      if (body.data.length > 0) {
        setSelectedSdkVersion(body.data.sdepthSdkVerList[0]);
      }
    }
  };

  const getContentList = async () => {
    let feedMapId = selectedCountry + ':' + selectedDeviceType;
    const body = await getRequest(`/sdepth/feedmap/sdks/${feedMapId}`, null);
    if (body && body.result === 'SUCCESS') {
      setSettingList(body.data);
    }
  };

  const getPreviewList = async () => {
    let feedMapId = selectedCountry + ':' + selectedDeviceType;
    if (selectedSdkVersion !== '') {
      const body = await getRequest(`/sdepth/feedmap/${feedMapId}/sdkVer/${selectedSdkVersion}/channels`, null);
      if (body && body.result === 'SUCCESS') {
        setPreviewList(body.data);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...previewList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setPreviewList([...sortedData]);
  };

  const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: confirmAction
    },
  ];

  const handleSelectedRegion = (region) => {
    setSelectedRegion(region);
  };

  const handleSelectedCountry = (country) => {
    setSelectedCountry(country);
  };

  const handleSelectedDeviceType = (deviceType) => {
    setSelectedDeviceType(deviceType);
  };

  const handleSelectedSdkVersion = (sdkVersion) => {
    setSelectedSdkVersion(sdkVersion);
  };

  const handleDelete = () => {
    setConfirmOpen(false);
    const feedMapId = selectedCountry + ':' + selectedDeviceType;
    fetch(`${BASE_URL}/sdepth/feedmap/${feedMapId}/sdkVer`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(
        Object.keys(checkedItems)
        .filter(key => checkedItems[key] === true)
        .map(key => settingList[key].sdkVer)
      )
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSettingList(settingList.filter((item, index) => !checkedItems[index]));
        setCheckedItems({});
        if (isAllChecked) {
          setIsAllChecked(false);
        }
        setAlertTitle('Info');
        setAlertMessage('Successfully deleted.');
        setAlertOpen(true);
      } else {
        throw new Error(`[LGChannelsShelf] Delete error`);
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    })
  };

  const handleConfirmDelete = () => {
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to delete the selected items?');
    setConfirmAction(() => () => handleDelete());
  };

  const handleAddButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handlePopupSave = ({ selectedSdkVersion, selectedPlatformVersion, selectedTabCode, selectedCategoryCode }) => {
    const newSetting = {
      sdkVer: selectedSdkVersion,
      platformVersion: selectedPlatformVersion,
      tabCode: selectedTabCode,
      categoryCode: selectedCategoryCode,
      feedMapId: selectedCountry + ':' + selectedDeviceType,
      region: selectedRegion,
    };
    setSettingList([...settingList, newSetting]);
    setIsPopupOpen(false);
  };

  const handleCheckboxChange = (index) => {
    setCheckedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleAllCheckboxChange = () => {
    const newCheckedItems = {};
    settingList.forEach((item, index) => {
      newCheckedItems[index] = !isAllChecked;
    });
    setCheckedItems(newCheckedItems);
    setIsAllChecked(!isAllChecked);
  };

  const handleSaveSetting = () => {
    fetch(`${BASE_URL}/sdepth/feedmap/sdk`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(
        settingList.filter((item, index) => checkedItems[index])
      )
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info');
        setAlertMessage('Successfully saved.');
        setAlertOpen(true);
      } else {
        throw new Error(`[LGChannelsShelf] Save error`);
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    })
  };

  const buttonGroup = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Delete',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: true,
      onClick: handleConfirmDelete
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Add',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: true,
      onClick: handleAddButtonClick
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Save',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: true,
      onClick: handleSaveSetting
    },
  ];

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div>
      <Box className='content-wrap category-list' component='div'>
        <Box component='div' className='white-box filter-box'>
          <Box className='form-wrap-box'>
            <Box className='form-area left'>
              <Box className='component-wrap col-5'>
                {/* Region */}
                <Box component='div' className='col'>
                  <SelectBox
                    id='shelf-region'
                    size='large'
                    shrink={true}
                    label='Region'
                    required={false}
                    selectOptions={optionRegionList}
                    disabled={false}
                    selected={selectedRegion}
                    onChange={handleSelectedRegion}
                  />
                </Box>
                {/* Country */}
                <Box component='div' className='col'>
                  <SelectBox
                    id='country-slt1'
                    size='large'
                    shrink={true}
                    label='Country'
                    required={false}
                    selectOptions={optionCountryList}
                    disabled={false}
                    selected={selectedCountry}
                    onChange={handleSelectedCountry}
                  />
                </Box>
                {/* Platform */}
                <Box component='div' className='col'>
                  <SelectBox
                    id='slt-country'
                    size='large'
                    shrink={true}
                    label='Device Type'
                    required={false}
                    selectOptions={optionDeviceTypeList}
                    disabled={false}
                    selected={selectedDeviceType}
                    onChange={handleSelectedDeviceType}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* 2nd Depth / Home Shelf Setting */}
        <Box className='white-box white-box-wrap single-box' pb={3}>
          <Box className='title-wrap space-between'>
            <Box className='left-area'>
              <Box className='box-title-area' flexDirection={'column'}>
                <Typography variant='h2'>2nd Depth / Home Shelf Setting</Typography>
                <Typography variant='p' component='p' className='sm-sub-text'>
                  The selected Tab and Category Detail will be mapped with the 2nd
                  Depth / Home Shelf data, and will be displayed in TV
                </Typography>
              </Box>
            </Box>
            <Box className='right-area'>
              <CustomButtonGroup buttons={buttonGroup} justifyContent='flex-end' />
            </Box>
          </Box>
          <div className='box-content'>
            <Box className='content-inner-wrap no-page-number'>
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      {ShelfTh.map((column, index) => (
                        <TableCell
                          key={column.id}
                          className='th'
                          align={column.align}
                          sx={{ width: column.width }}
                        >
                          {column.checkbox ? (
                            <Box
                              component='div'
                              sx={{ display: 'flex', gap: '8px' }}
                            >
                              <Box
                                component='div'
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                                }}
                              >
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={isAllChecked}
                                  onChange={event => handleAllCheckboxChange()}
                                  inputProps={{
                                    'aria-label': 'select all'
                                  }}
                                  style={{ width: '24px' }}
                                />
                                {index === 0 && (
                                  <CustomBasicMenu
                                    type='icon'
                                    title={
                                      <ArrowDropDownIcon
                                        sx={{ fontSize: '18px', color: '#000' }}
                                      />
                                    }
                                    options={selPageData}
                                  />
                                )}
                              </Box>
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </Box>
                          ) : (
                            <>
                              {column.sortable ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* table Body */}
                  <TableBody className='tbody'>
                    {settingList.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        {ShelfTh.map((column, colIndex) => (
                          <TableCell
                            key={column.id}
                            className='td'
                            align={column.align}
                          >
                            {colIndex === 0 ? (
                              <CheckBox
                                label={td[column.id]}
                                value='value'
                                defaultChecked={false}
                                onChange={event => handleCheckboxChange(index)}
                                checked={!!checkedItems[index]}
                              />
                            ) : colIndex === 2 ? (
                              <TextLink href='' text={td[column.id]} />
                            ) : colIndex === 4 ? (
                              <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='EDIT'
                                isIcon={false}
                                Icon=''
                                iconPos={undefined}
                                autosize
                                onClick={handleAddButtonClick}
                              />
                            ) : (
                              td[column.id]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
              {/* // Table */}
            </Box>
          </div>
        </Box>

        {/* Preview */}
        <Box className='white-box white-box-wrap single-box' pb={3}>
          <Box className='title-wrap space-between'>
            <Box className='left-area'>
              <Box className='box-title-area' alignItems='center'>
                <Typography variant='h2'>Preview</Typography>
                <Typography variant='p' component='p' className='sm-sub-text'>
                  The following items are displayed by the current RSS feeds, and
                  may be updated by batch process
                </Typography>
              </Box>
            </Box>
            <Box className='right-area'>
              <SelectBox
                id='slt-country'
                size='large'
                maxWidth='230px'
                shrink={true}
                label='SDK Version'
                required={false}
                selectOptions={optionSdkVersionList}
                disabled={false}
                selected={selectedSdkVersion}
                onChange={handleSelectedSdkVersion}
              />
            </Box>
          </Box>
          <div className='box-content'>
            {/* no-page-number 클래스 추가하였습니다. */}
            <Box className='content-inner-wrap no-page-number'>
              {/* Table */}
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  {/* table Head */}
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      {PreviewTh.map((column, index) => (
                        <TableCell
                          key={column.id}
                          className='th'
                          align={column.align}
                          sx={{ width: column.width }}
                        >
                          {column.checkbox ? (
                            <Box
                              component='div'
                              sx={{ display: 'flex', gap: '8px' }}
                            >
                              <Box
                                component='div'
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                                }}
                              >
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all'
                                  }}
                                  style={{ width: '24px' }}
                                />
                                {index === 0 && (
                                  <CustomBasicMenu
                                    type='icon'
                                    title={
                                      <ArrowDropDownIcon
                                        sx={{ fontSize: '18px', color: '#000' }}
                                      />
                                    }
                                    options={selPageData}
                                  />
                                )}
                              </Box>
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </Box>
                          ) : (
                            <>
                              {column.sortable ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* table Body */}
                  <TableBody className='tbody'>
                    {previewList.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        {PreviewTh.map((column, colIndex) => (
                          <TableCell
                            key={column.id}
                            className='td'
                            align={column.align}
                          >
                            {colIndex === 2 ? (
                              <CustomImage
                                src={td[`data0${colIndex + 1}`]}
                                fit={false}
                                alt=''
                                wrapWidth='238px'
                                wrapHeight='64px'
                                rounded
                              />
                            ) : (
                              td[`data0${colIndex + 1}`]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
              {/* // Table */}
            </Box>
          </div>
        </Box>
      </Box>
      <Dialog
        open={isAlertOpen}
        handleClose={handleAlertClose}
        closeX={false}
        size='small'
        title={alertTitle}
        contentTitle={false}
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
      <Dialog
        open={isConfirmOpen}
        handleClose={handleConfirmClose}
        closeX={false}
        size='small'
        title='Confirm'
        contentTitle={false}
        bottomDivider={false}
        contentText={confirmMessage}
        buttons={confirmDialogButtons}
      />
      {isPopupOpen && (
        <PopDepthHomeShelf
          onClose={handleClosePopup}
          onSave={handlePopupSave}
          countryCode={selectedCountry}
          deviceType={selectedDeviceType}
          sdkversionList={optionSdkVersionList}
        />
      )}
    </div>
  );
}
LGChannelsShelfLink.propTypes = {
  serviceCountry: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  serviceCountry: state.common.country.serviceCountry,
})
export default connect(mapStateToProps)(LGChannelsShelfLink);
