import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import SearchField from 'components/component/BasicSearchField';

import TextLink from 'components/component/BasicTextLink';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// TODO
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'utils/Valid';
import { connect } from 'react-redux';
import { getCountry } from 'actions/common/countryActions';
import { getRequest, postRequest } from 'utils/Request';
import SortLabel from 'components/component/BasicTableSortLabel';
// data
import { defaultPolicy, PolicyTableHeader, devicePlatform } from 'pages/Configuration/Policy/data/PolicyData';
import PolicyHistory from 'pages/Configuration/Policy/PolicyHistory';
import BasicDialog from 'components/component/BasicDialog';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// ************************** 메인 컨텐츠 ***********************************
function PrivacyPolicy({ getCountry, country }) {
  const navigate = useNavigate();
  // BreadCrumb Data
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Privacy Policy',
  };

  // 테이블 정렬 함수
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const dataSorting = (col, desc) => {
    setDisplayPolicyList(sortRows([...policyList], col, desc));
  };

  // start
  const defaultOption = { id: 'All', value: 'All', option: 'All', type: 'text' };
  const [searchFilter, setSearchFilter] = useState([]);
  const [editFilter, setEditFilter] = useState([]);
  const [searchParameter, setSearchParameter] = useState({
    deviceType: 'All',
    countryCode: 'All',
  });
  const [originalDataList, setOriginalDataList] = useState([]);
  const [policyList, setPolicyList] = useState([]);
  const [displayPolicyList, setDisplayPolicyList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isPageNavigate, setIsPageNavigate] = useState(false);
  const [searchFieldText, setSearchFieldText] = useState('');
  const [supportLanguage, setSupportLanguage] = useState({});
  const [supportedCountry, setSupportedCountry] = useState({});
  const [history, setHistory] = useState({ policyId: '', policyType: '' });
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(true);

  const defaultPopupType = {
    publish: false,
    success: false,
    alarm: false,
    title: '',
    content: '',
  };
  const [popupType, setPopupType] = useState(defaultPopupType);
  // Search Filter Button
  const getSupportLanguage = async () => {
    const body = await getRequest('/common/support-language', null);
    if (body && body.result === 'SUCCESS') {
      setSupportLanguage(body.data.distinctLanguage);
    }
  };
  useEffect(() => {
    if (isEmpty(supportLanguage)) {
      getSupportLanguage();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEmpty(country)) {
      getCountry();
    } else {
      let codeAll = country.regionCountry.map((m) => m.countryCode);
      const countryAll = new Set(codeAll);
      let allOption = {
        ...defaultOption,
        countryList: [
          defaultOption,
          ...Array.from(countryAll).map((code) => {
            return {
              id: code,
              value: code,
              option: code,
              type: 'text',
            };
          }),
        ],
      };
      const set = new Set();
      country.regionCountry.forEach((n) => {
        if (n.deviceType !== 'mobile') {
          set.add(n.deviceType);
        }
      });
      let filterList = Array.from(set).map((device) => {
        return {
          id: device,
          value: device,
          option: device,
          type: 'text',
          countryList: [
            defaultOption,
            ...country.regionCountry
              .filter((f) => f.deviceType === device)
              .map((n) => {
                const { countryCode } = n;
                return {
                  id: countryCode,
                  value: countryCode,
                  option: countryCode,
                  type: 'text',
                };
              }),
          ],
        };
      });
      filterList = [allOption, ...filterList];
      //country sort
      filterList.forEach((f) => {
        f.countryList.sort((a, b) => a.id.localeCompare(b.id));
      });
      setSearchFilter(filterList);
      getPolicyList('All', 'All');
      let editFilterList = Array.from(set).map((device) => {
        return {
          id: device,
          value: device,
          option: device,
          type: 'text',
          countryList: [
            ...country.regionCountry
              .filter((f) => f.deviceType === device)
              .map((n) => {
                const { countryCode, ricCode } = n;
                return {
                  id: countryCode,
                  value: countryCode,
                  option: countryCode,
                  ric: ricCode,
                  type: 'text',
                };
              }),
          ],
          platformList: [
            ...devicePlatform
              .filter((f) => f.type === device)
              .map((n) => {
                const { value } = n;
                return {
                  id: value,
                  value: value,
                  option: value,
                  type: 'text',
                };
              }),
          ],
        };
      });
      editFilterList.forEach((f) => {
        f.countryList.sort((a, b) => a.id.localeCompare(b.id));
      });
      setEditFilter(editFilterList);
      setSupportedCountry(country.supportedCountry);
    }

    // eslint-disable-next-line
  }, [country]);

  const onChangeHandler = (name, value) => {
    if (name === 'deviceType') {
      setSearchParameter({ [name]: value, countryCode: 'All' });
    } else {
      setSearchParameter({ ...searchParameter, [name]: value });
    }
  };

  // Search Filter Button
  const getPolicyList = async (deviceType, countryCode) => {
    const body = await getRequest(`/policy/privacy?deviceType=${deviceType}&countryCode=${countryCode}`, null);
    if (body && body.result === 'SUCCESS') {
      setOriginalDataList(body.data);
      setPolicyList(body.data);
      setPageNavigate(body.data, currentPage, pageSize);
      if (!isEmpty(body.data)) {
        if (body.data.length > 0 && body.data.find((f) => f.publishedFlag === 'N')) {
          setPublishButtonDisabled(false);
        } else {
          setPublishButtonDisabled(true);
        }
      }
    }
  };

  const setPageNavigate = (list, current, size) => {
    if (!isEmpty(list)) {
      setIsPageNavigate(true);
      const startIndex = (current - 1) * size;
      const endIndex = startIndex + size;
      const displayList = list.slice(startIndex, endIndex);
      setDisplayPolicyList([...displayList]);
    } else {
      setIsPageNavigate(false);
      setDisplayPolicyList([]);
    }
  };

  const paginationHandler = (paging) => {
    if (paging) {
      if (paging.type === 'page') {
        setCurrentPage(paging.value);
        setPageNavigate(policyList, paging.value, pageSize);
      } else if (paging.type === 'size') {
        setPageSize(paging.value);
        setCurrentPage(1);
        setPageNavigate(policyList, 1, paging.value);
      }
    }
  };

  const listSearch = () => {
    getPolicyList(searchParameter.deviceType, searchParameter.countryCode);
  };

  const refreshList = () => {
    listSearch();
    setPopupType(defaultPopupType);
  };

  const listReset = () => {
    setSearchParameter({
      deviceType: 'All',
      countryCode: 'All',
    });
    getPolicyList('All', 'All');
  };

  const searchButton = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      autosize: true,
      onClick: listReset,
    },
    {
      minWidth: '120px',
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      onClick: listSearch,
    },
  ];

  const searchFieldHandler = (searchText) => {
    setSearchFieldText(searchText);
    let rows = [];
    if (!isEmpty(originalDataList)) {
      rows = [...originalDataList];
      if (!isEmpty(searchText)) {
        rows = rows.filter((search) => isIncludeSearchText(search, searchText));
      }
    }
    setPolicyList(rows);
    setPageNavigate(rows, 1, pageSize);
  };

  const isIncludeSearchText = (row, searchText) => {
    let deviceType = '';
    let countries = '';
    let devicePlatforms = '';
    let policyVersion = '';
    let policyComment = '';
    let lastChgUsrId = '';
    if (!isEmpty(searchText)) {
      searchText = searchText.toString().toLowerCase();
    } else {
      return true;
    }
    if (!isEmpty(row.deviceType)) {
      deviceType = row.deviceType.toString().toLowerCase();
    }
    if (!isEmpty(row.countries)) {
      countries = row.countries.toString().toLowerCase();
    }
    if (!isEmpty(row.devicePlatforms)) {
      devicePlatforms = row.devicePlatforms.toString().toLowerCase();
    }
    if (!isEmpty(row.policyVersion)) {
      policyVersion = row.policyVersion.toString().toLowerCase();
    }
    if (!isEmpty(row.policyComment)) {
      policyComment = row.policyComment.toString().toLowerCase();
    }
    if (!isEmpty(row.lastChgUsrId)) {
      lastChgUsrId = row.lastChgUsrId.toString().toLowerCase();
    }
    if (deviceType.indexOf(searchText) > -1) {
      return true;
    }
    if (countries.indexOf(searchText) > -1) {
      return true;
    }
    if (devicePlatforms.indexOf(searchText) > -1) {
      return true;
    }
    if (policyVersion.indexOf(searchText) > -1) {
      return true;
    }
    if (policyComment.indexOf(searchText) > -1) {
      return true;
    }
    if (lastChgUsrId.indexOf(searchText) > -1) {
      return true;
    }
    return false;
  };

  // Top Button & Link
  const policyAddComponentLink = () => {
    policyComponentLink(defaultPolicy);
  };

  const getPolicyDetail = async (policyIdx) => {
    const body = await getRequest(`/policy/${policyIdx}`, null);
    if (body && body.result === 'SUCCESS') {
      policyComponentLink(body.data);
    } else {
      // TODO
      // 메세지 처리
    }
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const historyDialog = (policyId, policyType) => {
    setHistory({ policyId: policyId, policyType: policyType });
    setPopupType({ ...defaultPopupType, history: true });
  };

  const publishDialog = () => {
    setPopupType({ ...defaultPopupType, publish: true, title: 'Publish', content: 'Would you like to distribute the Terms of Use?' });
  };

  const policyComponentLink = (policyData) => {
    navigate('/PrivacyPolicy', {
      replace: true,
      state: {
        move: true,
        targetComponent: policyData.policyIdx > -1 ? 'PolicyEdit' : 'PolicyAdd',
        message: {
          policy: { ...policyData, policyType: 'privacy' },
          editFilter: editFilter,
          supportLanguage: supportLanguage,
          supportedCountry: supportedCountry,
        },
      },
    });
  };

  const policyPublish = async () => {
    const body = await postRequest('/policy/publish/privacy', null);
    let content = '';
    if (body && body.result === 'SUCCESS') {
      content = 'Saved successfully.';
      setPopupType({ ...defaultPopupType, success: true, title: 'Publish', content: content });
    } else {
      if (body.result === 'INVALID') {
        content = isEmpty(body.data) ? 'Please check the policies before posting.' : body.data.toString();
      } else {
        content = body.message;
      }
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Publish', content: content });
    }
  };
  // display Button
  const addButton = {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Add',
    isIcon: false,
    autosize: true,
    onClick: policyAddComponentLink,
  };
  const publishButton = {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Publish',
    isIcon: false,
    autosize: true,
    disabled: false,
    onClick: publishDialog,
  };
  // pop Button
  const defaultButton = {
    buttonId: 'buttonId',
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    isIcon: false,
    Icon: null,
    fontWeight: 'bold',
    disabled: false,
    label: 'Button',
  };

  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButton',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const publishSaveButton = {
    ...defaultButton,
    buttonId: 'saveButton',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: policyPublish,
  };
  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButton',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  //cancelButton, saveButton]
  const publishActionButtons = [cancelButton, publishSaveButton];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography className='title'>Privacy Policy</Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap' component='div'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <div className='form-area left'>
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    {!isEmpty(searchFilter) && (
                      <SelectBox
                        id='policy-device-type'
                        size='large'
                        shrink={true}
                        label='Device type'
                        required={false}
                        disabled={false}
                        placeholder=''
                        selectOptions={searchFilter}
                        selected={searchParameter.deviceType}
                        onChange={(value) => onChangeHandler('deviceType', value)}
                      />
                    )}
                  </Box>

                  <Box component='div' className='col'>
                    {!isEmpty(searchFilter) && searchParameter.deviceType && (
                      <SelectBox
                        id='policy-country'
                        size='large'
                        shrink={true}
                        label='Country'
                        disabled={false}
                        placeholder=''
                        selectOptions={searchFilter.find((f) => f.id === searchParameter.deviceType).countryList}
                        selected={searchParameter.countryCode}
                        onChange={(value) => onChangeHandler('countryCode', value)}
                      />
                    )}
                  </Box>
                </Box>
              </div>
              <div className='form-area right'>
                <CustomButtonGroup buttons={searchButton} />
              </div>
            </Box>
          </Box>
          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <SearchField
                    id='searchFieldText'
                    placeholder='Please enter a search term'
                    label='Ch.no or ch.Name'
                    maxWidth='230px'
                    size='medium'
                    required={false}
                    shrink={false}
                    value={searchFieldText}
                    setSearchText={searchFieldHandler}
                  />
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={[addButton, { ...publishButton, disabled: publishButtonDisabled }]} />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {PolicyTableHeader.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox && (
                              <CheckBox
                                label=''
                                value='select-all'
                                defaultChecked={false}
                                inputProps={{
                                  'aria-label': 'select all',
                                }}
                              />
                            )}
                            <SortLabel
                              labelText={column.label}
                              labelId={column.labelId}
                              sortable={column.sortable}
                              orderBy={orderBy}
                              setOrderBy={setOrderBy}
                              order={order}
                              setOrder={setOrder}
                              sorting={dataSorting}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* table Body */}
                    {!isEmpty(displayPolicyList) ? (
                      <TableBody className='tbody'>
                        {displayPolicyList.map((row, index) => (
                          <TableRow className='tr' key={row.policyIdx || index}>
                            {PolicyTableHeader.map((column) => (
                              <TableCell key={column.id} className='td' align={column.align}>
                                {column.labelId === 'policyVersion' ? (
                                  <TextLink href='' text={row[column.labelId]} />
                                ) : column.labelId === 'publishedFlag' ? (
                                  <span> {isEmpty(row.publishedVersion) ? `${row.publishedFlag}` : `${row.publishedFlag} (Released:${row.publishedVersion}`}</span>
                                ) : column.labelId === 'Action' ? (
                                  <Box display='flex' gap={1}>
                                    <Button color='tertiary' type='outline' size='small' label='EDIT' isIcon={false} autosize onClick={() => getPolicyDetail(row.policyIdx)} />
                                    <Button color='tertiary' type='outline' size='small' label='HISTORY' isIcon={false} autosize onClick={() => historyDialog(row.policyId, row.policyType)} />
                                  </Box>
                                ) : (
                                  row[column.labelId]
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody className='tbody'>
                        <TableRow className='tr'>
                          <TableCell colSpan={12} className={'td noData'} align='center'>
                            <Box className='no-data-message' sx={{ height: '400px', padding: '50px 0' }}>
                              <Typography>No data found.</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>
              {isPageNavigate && <Pagination count={Math.ceil(policyList.length / pageSize)} id='reservation-pagination' selected={pageSize} onChangeHandle={paginationHandler} page={currentPage} />}
            </div>
          </Box>
        </Box>
      </Box>
      {popupType.history && <PolicyHistory isDialogOpen={popupType.history} history={history} closeHandler={closeDialog} />}
      {(popupType.publish || popupType.alarm || popupType.success) && (
        <BasicDialog
          title={popupType.title}
          contentTitle={popupType.content}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={closeDialog}
          buttons={popupType.publish ? publishActionButtons : popupType.alarm ? [closeButton] : [{ ...closeButton, onClick: refreshList }]}
        />
      )}
    </Box>
  );
}

PrivacyPolicy.propTypes = {};

const mapStateToProps = (state) => ({
  country: state.common.country,
});

const mapDispatchToProps = (dispatch) => ({
  getCountry: () => dispatch(getCountry()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);

//export default PrivacyPolicy;
