import { BASE_URL } from "constants/index";
import { getHeaders } from "utils/Header";


export const saveCreateTab = async (
  tabData,
  editLangList
) => {
  let apiUrl = `${BASE_URL}/home-app/device/${tabData.deviceType}/region/${tabData.region}/platform/${tabData.platformVersion}/status/${tabData.status}/tab`;
  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      deviceType: tabData.deviceType,
      region: tabData.region,
      platformVersion: tabData.platformVersion,
      status: tabData.status,
      tabCode: tabData.tabCode,
      tabName: tabData.tabName,
      type: tabData.type,
      skeletonType: tabData.skeletonType,
      locationType: tabData.locationType,
      linkType: tabData.linkType,
      tabOrder: tabData.tabOrder,
      categoryFlag: tabData.categoryFlag,
      themeApply: tabData.themeApply,
      title: tabData.title,
      description: tabData.description,
      updateFlag: tabData.updateFlag,
      randomOrderFlag: tabData.randomOrderFlag,
      tabMultiLangs: editLangList,
    })
  );
  if (tabData.tabImg) {
    formData.append("tabImg", tabData.tabImg);
  }
  if (tabData.bgImg) {
    formData.append("bgImg", tabData.bgImg);
  }
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: getHeaders().get("Authorization"),
      },
      body: formData,
    });
    const body = await response.json();
    if (body.result === "SUCCESS") {
      return {
        isSuccess: true,
        severity: "success",
        message: body.message || "Successfully Created Area!",
      };
    } else {
      return {
        isSuccess: false,
        severity: "error",
        message: body.message || "Something went wrong!",
      };
    }
  } catch (error) {
    return {
      isSuccess: false,
      severity: "error",
      message: "An error occurred while saving the data.",
    };
  }
};

export const saveEditTab = async (
  tabData,
  editLangList
) => {
  let apiUrl = `${BASE_URL}/home-app/tab/${tabData.idx}`;
  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      deviceType: tabData.deviceType,
      region: tabData.region,
      platformVersion: tabData.platformVersion,
      status: tabData.status,
      tabCode: tabData.tabCode,
      tabName: tabData.tabName,
      type: tabData.type,
      skeletonType: tabData.skeletonType,
      locationType: tabData.locationType,
      linkType: tabData.linkType,
      tabOrder: tabData.tabOrder,
      categoryFlag: tabData.categoryFlag,
      themeApply: tabData.themeApply,
      title: tabData.title,
      description: tabData.description,
      updateFlag: tabData.updateFlag,
      randomOrderFlag: tabData.randomOrderFlag,
      tabMultiLangs: editLangList,
    })
  );
  if (tabData.tabImg) {
    formData.append("tabImg", tabData.tabImg);
  }
  formData.append("tabImgDelete", tabData.tabImgDelete);
  if (tabData.bgImg) {
    formData.append("bgImg", tabData.bgImg);
  }
  formData.append("bgImgDelete", tabData.bgImgDelete);
  try {
    const response = await fetch(apiUrl, {
      method: "PATCH",
      headers: {
        Authorization: getHeaders().get("Authorization"),
      },
      body: formData,
    });
    const body = await response.json();
    if (body.result === "SUCCESS") {
      return {
        isSuccess: true,
        severity: "success",
        message: body.message || "Successfully Created Area!",
      };
    } else {
      return {
        isSuccess: false,
        severity: "error",
        message: body.message || "Something went wrong!",
      };
    }
  } catch (error) {
    return {
      isSuccess: false,
      severity: "error",
      message: "An error occurred while saving the data.",
    };
  }
};

export const saveSliderImage = async (
  data
) => {
  let apiUrl = `${BASE_URL}/home-app/category/slider/image`;
  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      categoryIdx: data.categoryIdx,
      sliderIdx: data.sliderIdx,
      contentId: data.contentId,
    })
  );
  if (data.horizontalImage) {
    formData.append("horizontalImage", data.horizontalImage);
  }
  if (data.bigImage) {
    formData.append("bigImage", data.bigImage);
  }
  try {
    const response = await fetch(apiUrl, {
      method: "PATCH",
      headers: {
        Authorization: getHeaders().get("Authorization"),
      },
      body: formData,
    });
    const body = await response.json();
    if (body.result === "SUCCESS") {
      return {
        isSuccess: true,
        severity: "success",
        message: body.message || "Successfully Created Area!",
        data: body.data,
      };
    } else {
      return {
        isSuccess: false,
        severity: "error",
        message: body.message || "Something went wrong!",
      };
    }
  } catch (error) {
    return {
      isSuccess: false,
      severity: "error",
      message: "An error occurred while saving the data.",
    };
  }
};