import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Pagination from 'components/component/BasicPagination';
import CustomImage from 'components/component/BasicImage';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from 'components/component/BasicTextField';
import CustomRadio from 'components/component/BasicRadio';
import SelectBox from 'components/component/BasicSelectBox';
import SearchField from 'components/component/BasicSearchField';
import Button from 'components/component/BasicButton';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CheckBox from 'components/component/BasicCheckBox';
import TextButton from 'components/component/BasicTextButton';
import PopInformationAutoRule from 'pages/Organization/TabCategory/PopInformationAutoRule';
import PopInformationKeywordCount from 'pages/Organization/TabCategory/PopInformationKeywordCount';
import PopInformationGenreCount from 'pages/Organization/TabCategory/PopInformationGenreCount';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'utils/Valid';
import { getRequest, patchRequest, deleteRequest } from 'utils/Request';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Link,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

// Data
import {
  CommonAutoTh,
} from 'pages/Organization/TabCategory/data/TabCategoryData';

// images
import { AddPlus, DelMinus } from 'components/component/BasicIcon';
import { ReactComponent as Icinfo24 } from 'assets/images/icon/ic_info_24_outline_primary.svg';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Ordering Options
const orderingOptions = [
  { id: 1, checked: false, value: 'latest', label: 'Latest first', name: 'radio' },
  { id: 2, checked: false, value: 'popular', label: 'Popular first', name: 'radio' },
  { id: 3, checked: false, value: 'time', label: 'Start time first', name: 'radio2' },
];

// Criteria Type Options
const criteriaOptions = [
  {id: 'channelGenre', value: 'channelGenre', option: 'channelGenre', type: 'text'},
  {id: 'programGenre', value: 'programGenre', option: 'programGenre', type: 'text'},
  {id: 'keyword', value: 'keyword', option: 'keyword', type: 'text'},
];

// 메인 컨텐츠
function CommonAuto(props) {
  const { message } = props;
  const { tabIdx, categoryIdx, tab, category, countryOptions, providerOptions } = message;

  // const initialCheckedValue = orderingOptions.find((option) => option.checked)?.value || 'option1';
  const [ordering, setOrdering] = useState(category.orderType && category.orderType);
  // 검색 Country 필터
  const [countryFilter, setCountryFilter] = useState(countryOptions[0].value);
  // 검색 Content Type 필터
  const [contentTypeFilter, setContentTypeFilter] = useState(category.contentType && category.contentType.split(',')[0]);

  // 카테고리에 설정된 contentType으로 Select Box Option 값 생성
  const [contentTypeOptions, setContentTypeOptions] = useState([]);
  useEffect(() => {
    if (category.contentType) {
      const contentTypeSplit = category.contentType.split(',').map(n => {
        const newItem = {
          id: n,
          value: n,
          option: n,
          type: 'text',
        }
        return newItem;
      });
      setContentTypeOptions(contentTypeSplit);
    }
    if (category.cpList) {
      const list = (category.cpList || '').split(',').map(n => ({ name: n === 'All' ? n : n.slice(0, n.indexOf(':')), value: n === 'All' ? '' : n.slice(n.indexOf(':') + 1) }));
      setCpList(list);
    }
    if (category.categoryMappingRule) {
      const list = (category.categoryMappingRule.rule || '').split(',').map(n => ({ criterion: n === 'All' ? n : n.slice(0, n.indexOf(':')), criterionDetail: n.slice(n.indexOf(':') + 1) }));
      setCriteriaList(list);
    }
    // eslint-disable-next-line
  }, [category]);

  // Creterion Detail 1-1
  useEffect(() => {
    if (!isEmpty(countryFilter)) {
      getCountryByGenre();
      getCountryByKeyword();
    }
    // eslint-disable-next-line
  }, [countryFilter]);

  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Category List'],
  };

  // Search 그리드에 연관검색 키워드 input
  const [searchAutoContentFilter, setSearchAutoContentFilter] = useState('');
  // Search Content 연관 검색 시
  useEffect(() => {
    if (searchAutoContentFilter) {
      const filteredData = searchAutoContent.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchAutoContentFilter));
      });
      setDisplaySearchAutoContent([...filteredData]);
    } else {
      setDisplaySearchAutoContent(searchAutoContent);
    }
    // eslint-disable-next-line
  }, [searchAutoContentFilter]);

  const navigate = useNavigate();
  // PopUp창 노출(information, check keyword, check genre)
  const [actionDialog, setActionDialog] = useState("");

  const handleInformationButtonClick = () => {
    console.log('info')
    setActionDialog('INFORMATION');
  };
  const handleKeywordButtonClick = () => {
    setActionDialog('CHECK KEYWORD');
  };
  const handleGenreButtonClick = () => {
    setActionDialog('CHECK GENRE');
  };

  const [criteriaList, setCriteriaList] = useState([{criterion: 'keyword', criterionDetail: '', criterionDetailCode: ''}]);
  const [cpList, setCpList] = useState([{name: 'All', value: '1'}]);

  const [searchAutoContent, setSearchAutoContent] = useState([]);
  const [displaySearchAutoContent, setDisplaySearchAutoContent] = useState([]);
  useEffect(() => {
    if (!isEmpty(searchAutoContent)) {
      setDisplaySearchAutoContent(searchAutoContent);
    } else {
      setDisplaySearchAutoContent([]);
    }
    // eslint-disable-next-line
  }, [searchAutoContent]);

  useEffect(() => {
    console.log('cpList reflesh');
    // eslint-disable-next-line
  }, [cpList]);

  const [criteriaDetailKeywordOptions, setCriteriaDetailKeywordOptions] = useState([]);
  const [criteriaDetailChannelOptions, setCriteriaDetailChannelOptions] = useState([]);
  const [criteriaDetailProgramOptions, setCriteriaDetailProgramOptions] = useState([]);

  const [countryByGenreList, setCountryByGenreList] = useState([]);
  const [countryByKeywordList, setCountryByKeywordList] = useState([]);

  // Creterion Detail 1-2
  const getCountryByGenre = async () => {
    const body = await getRequest(`/home-app/category/auto-genre?country=${countryFilter}`, null);
    if (body && body.result === 'SUCCESS') {
      setCountryByGenreList(body.data);
    }
  };

  // Creterion Detail 1-2
  const getCountryByKeyword = async () => {
    const body = await getRequest(`/home-app/category/auto-keyword?country=${countryFilter}`, null);
    if (body && body.result === 'SUCCESS') {
      setCountryByKeywordList(body.data);
    }
  };

  // Creterion Detail 1-3
  useEffect(() => {
    makeGenreCriteriaDetailOptions();
    // eslint-disable-next-line
  }, [countryByGenreList]);

  // Creterion Detail 1-3
  useEffect(() => {
    makeKeywordCriteriaDetailOptions();
    // eslint-disable-next-line
  }, [countryByKeywordList]);

  // Creterion Detail 1-4
  useEffect(() => {
    makeGenreCriteriaDetailOptions();
    makeKeywordCriteriaDetailOptions();
    // eslint-disable-next-line
  }, [criteriaList]);

  // Creterion Detail 1-5
  useEffect(() => {
    makeGenreCriteriaDetailOptions();
    makeKeywordCriteriaDetailOptions();
    // eslint-disable-next-line
  }, [cpList]);

  const makeKeywordCriteriaDetailOptions = () => {
    if (!isEmpty(countryByKeywordList)) {
      let criteria = [];
      const contentTypes = category.contentType && category.contentType.split(',');
      if (contentTypes && isKeyword(contentTypes)) {
        const cpNames = providerOptions.filter(c => c.id !== "All").map(c => c.id);
        const keywordData = countryByKeywordList
          .filter(n => contentTypes.includes(n.contentType) && (cpList.map(c => c.name === "All" ? cpNames : [c.name])).some(c => c.includes(n.providerId)))
          .map(n => n.keyword)
          .filter((value, index, self) => self.indexOf(value) === index);
        criteria.push({ key: 'keyword', value: keywordData });
        const keyword = criteria.find(n => n.key === 'keyword').value.map(n2 => {
          const newItem = {
            id: n2,
            value: n2,
            option: n2,
            type: 'text',
          }
          return newItem;
        });
        setCriteriaDetailKeywordOptions(keyword);
      }
    }
  }

  const makeGenreCriteriaDetailOptions = () => {
    if (!isEmpty(countryByGenreList)) {
      let criteria = [];
      if (category.contentType) {
        const contentTypes = category.contentType && category.contentType.split(',');
        if (contentTypes && contentTypes.includes('live')) {
          criteria.push({ key: 'channelGenre', value: countryByGenreList ? distinctGenre(countryByGenreList.filter(n => n.type === 'channelGenre').map(n => n.genreName)) : [] })
          const channelGenre = criteria.find(n => n.key === 'channelGenre').value.map(n2 => {
            const newItem = {
              id: n2,
              value: n2,
              option: n2,
              type: 'text',
            }
            return newItem;
          });
          setCriteriaDetailChannelOptions(channelGenre);
        }
        if (contentTypes && isProgramGenre(contentTypes)) {
          criteria.push({ key: 'programGenre', value: countryByGenreList ? distinctGenre(countryByGenreList.filter(n => n.type === 'programGenre').map(n => n.genreName)) : [] })
          const programGenre = criteria.find(n => n.key === 'programGenre').value.map(n2 => {
            const newItem = {
              id: n2,
              value: n2,
              option: n2,
              type: 'text',
            }
            return newItem;
          });
          setCriteriaDetailProgramOptions(programGenre);
        }
      }
    }
  }

  function distinctGenre(countryByGenreList) {
    return [...new Set(countryByGenreList)];
  }

  function isProgramGenre(contentTypes) {
    if (contentTypes && contentTypes.includes('movie')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('tvshow')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('tvshow-series')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('livestream')) {
      return true;
    }
    return false;
  }

  function isKeyword(contentTypes) {
    if (contentTypes && contentTypes.includes('movie')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('tvshow')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('live')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('livestream')) {
      return true;
    }
    return false;
  }

  const selectCriteriaDetailOptions = (type) => {
    if (type === 'programGenre') return criteriaDetailProgramOptions;
    if (type === 'channelGenre') return criteriaDetailChannelOptions;
    if (type === 'keyword') return criteriaDetailKeywordOptions;
  }

  // Save 버튼 클릭 시 Mapping Rule 저장
  const saveMappingRule = async () => {
    const newCpList = cpList.map(n => n.name === 'All' ? n.name : `${n.name}:${n.value}`).join(',');

    const newData = criteriaList.map((n, i) => {
      console.log('criterionDetail : ' + n.criterionDetail)
      const newItem = {
        criterion: n.criterion,
        criterionDetail: n.criterionDetail,
        criterionDetailCode: ((n.criterion === 'programGenre' || n.criterion === 'channelGenre') && n.criterionDetail !== '')
                          ? countryByGenreList.find(n2 => n2.genreName === n.criterionDetail && n2.type === n.criterion).genreCode
                          : '',
      }
      return newItem;
    });

    const res = await patchRequest(`/home-app/category/${categoryIdx}`, {cpList: newCpList, orderType: ordering, mappingType: category.mappingType, resultUpdateFlag: 'Y'}, null);
    if (res && res.result === 'SUCCESS') {
      const body = await patchRequest(`/home-app/category/${categoryIdx}/rule`, newData, null);
      if (body && body.result === 'SUCCESS') {
      }
    }

  };

  // Delete 버튼 클릭 시 Mapping Rule 삭제
  const deleteMappingRule = async () => {
    const res = await patchRequest(`/home-app/category/${categoryIdx}`, {cpList: '', orderType: '', mappingType: category.mappingType, resultUpdateFlag: 'Y'}, null);
    if (res && res.result === 'SUCCESS') {
      const body = await deleteRequest(`/home-app/category/${categoryIdx}/rule`, null, null);
      if (body && body.result === 'SUCCESS') {

      }
    }
  };

  /* 정보 수정 시 객체에 값을 변경 한다 Start */
  // Country Filter 값
  const onChangeCountry = (value) => {
    setCountryFilter(value);
  };
  // Content Type Filter 값
  const onChangeContentType = (value) => {
    setContentTypeFilter(value);
  };
  // Criteria 값
  const onChangeCriteria = (i, type, value) => {
    console.log(value)
    let newData = [...criteriaList];
    newData[i][type] = value;
    setCriteriaList(newData);
  };
  // Criteria Detail Code 값
  /*
  const onChangeCriteriaCode = (i, type, value, criterionType) => {
    let getCode;
    if (criterionType === 'programGenre') {
      getCode = countryByGenreList.find(n => n.genreName === value && n.type === criterionType).genreCode;
    }
    if (criterionType === 'channelGenre') {
      getCode = countryByGenreList.find(n => n.genreName === value && n.type === criterionType).genreCode;
    }

    let newData = [...criteriaList];
    newData[i][type] = getCode;
    setCriteriaList(newData);
  };
  */
  // CP 값
  const onChangeCp = (i, type, value) => {
    let newData = [...cpList];
    newData[i][type] = value;
    setCpList(newData);
  };
  // Ordering 값
  const onChangeOrdering = (event) => {
    setOrdering(event.target.value);
  };
  /* 정보 수정 시 객체에 값을 변경 한다 End */

  // history back 이전 페이지
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/TabCategoryList', { replace: true, state:
      { move: true,
        targetComponent: 'CategoryList',
        message:
        {
          tabIdx: tabIdx,
        }
      }
    });
  };

  // Search 버튼 클릭 시 Auto 컨텐츠 검색
  const searchContent = async () => {
    const channelGenreValue = !isEmpty(criteriaList.find(n => n.criterion === 'channelGenre')) ? criteriaList.find(n => n.criterion === 'channelGenre').criterionDetail : '';
    const programGenreValue = !isEmpty(criteriaList.find(n => n.criterion === 'programGenre')) ? criteriaList.find(n => n.criterion === 'programGenre').criterionDetail : '';
    const keywordValue = !isEmpty(criteriaList.find(n => n.criterion === 'keyword')) ? criteriaList.find(n => n.criterion === 'keyword').criterionDetail : '';

    // CP를 별도 지정시 AMG:1,PLT:1 방식으로 파라미터 전송
    // CP 값이 All일 경우 All 값만 서버로 파라미터 전달되며 전체 CP 조건으로 프로세스 수행 All:1 과 같이 전달되면 CP 값이 All인 것을 검색
    let newCpList = "";
    if (cpList.findIndex((item) => item.name === 'All') > -1) {
      newCpList = 'All';
    } else {
      newCpList = cpList.map(n => `${n.name}:${n.value}`).join(',');
    }

    const body = await getRequest(`/home-app/auto-search?country=${countryFilter}&type=${category.contentType}&order=${ordering}&cp=${newCpList}&channelGenre=${channelGenreValue}&programGenre=${programGenreValue}&keyword=${keywordValue}`, null);
    if (body && body.result === 'SUCCESS') {
      if (!isEmpty(body.data)) {
        let content = body.data.map((item, index) => {
          const {
            content_idx,
            content_id,
            name,
            cp_idx,
            cp_id,
            content_type,
            country_code,
            genre_code,
            genre_name,
            image,
            keywords,
            crt_date,
            last_chg_date,
            season,
            episode,
            start_date_time,
          } = item;
          return {
            order: index + 1,
            content_idx: content_idx,
            content_id: content_id,
            name: name,
            cp_idx: cp_idx,
            cp_id: cp_id,
            content_type: content_type,
            country_code: country_code,
            genre_code: genre_code,
            genre_name: genre_name,
            image: image,
            keywords: keywords,
            crt_date: crt_date,
            last_chg_date: last_chg_date,
            season: season,
            episode: episode,
            start_date_time: start_date_time,
          };
        });
        setSearchAutoContent(content.length > 0 ? content : []);
      } else {
        setSearchAutoContent([]);
      }
    }
  };

  const saveContent = async event => {
    event.preventDefault();
    if (!searchAutoContent.length) {
      alert('No search result ! Click search button first');
    }
    else if (searchAutoContent.length < 5 && !category.contentType.includes('livestream')) {
      alert('The search result count is less then 5 ! Change the rule !');
    }
    else {
      alert('Are you sure to apply the search result?');
      const newCategoryResult = searchAutoContent.slice(0, 100).map((n, i) => {
        let categoryResult = {};
        const { cp_idx, content_id, content_type } = n;
        categoryResult.categoryIdx = category.idx;
        categoryResult.countryCode = countryFilter;
        categoryResult.providerIdx = cp_idx;
        categoryResult.contentType = content_type;
        categoryResult.contentId = content_id;
        categoryResult.resultOrder = i + 1;
        return categoryResult;
      });
      const body = await patchRequest(`/home-app/category/${category.idx}/result?country=${countryFilter}`, newCategoryResult, null);
      if (body && body.result === 'SUCCESS') {

      }
    }
  };

  // SearchContent 연관 검색창에 검색어 입력 시
  const handleSearchAutoContent = (value) => {
    setSearchAutoContentFilter(value);
  };

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // Paging 처리
  useEffect(() => {
    if (displaySearchAutoContent && displaySearchAutoContent.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displaySearchAutoContent.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [displaySearchAutoContent, page, itemsPerPage]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displaySearchAutoContent].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplaySearchAutoContent([...sortedData]);
  };

  const buttons = [
    {
      index: 1,
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Delete',
      fontWeight: 'bold',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: true,
      onClick: deleteMappingRule,
    },
    {
      index: 2,
      color: 'tertiary',
      type: 'box',
      size: 'medium',
      label: 'CHECK KEYWORD',
      fontWeight: 'bold',
      isIcon: false,
      autosize: true,
      onClick: handleKeywordButtonClick,
    },
    {
      index: 3,
      color: 'tertiary',
      type: 'box',
      size: 'medium',
      label: 'CHECK GENRE',
      fontWeight: 'bold',
      isIcon: false,
      autosize: true,
      onClick: handleGenreButtonClick,
    },
    {
      index: 4,
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Save',
      fontWeight: 'bold',
      isIcon: false,
      autosize: true,
      onClick: saveMappingRule,
    },
  ];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container common-auto' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' onClick={handleBack}>
                Category List
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {/* Category Type */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Region</Typography>
                    <Typography className='value'>{tab.region}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Content Type */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Tab</Typography>
                    <Typography className='value'>{tab.tabName}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Category Name */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Category</Typography>
                    <Typography className='value'>{category.categoryName}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Tab name */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Content Type</Typography>
                    <Typography className='value'>{category.contentType}</Typography>
                  </ListItem>
                </React.Fragment>
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={countryOptions}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={countryFilter}
                      onChange={onChangeCountry}
                    />
                  </Box>

                  {/* Content Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='common-Content'
                      size='large'
                      shrink={true}
                      label='Content Type'
                      required={false}
                      selectOptions={contentTypeOptions}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={contentTypeFilter}
                      onChange={onChangeContentType}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>

          <Box className='white-box filter-box'>
            <Box className='setting-container'>
              <Box className='layout-container'>
                {/* Ordering */}
                <Box display='flex' className='row'>
                  <Box className='left-area'>
                    <Typography className='filter-label'>Ordering</Typography>
                  </Box>
                  <Box className='right-area' display='flex'>
                    <Box sx={{ flexShrink: 0 }}>
                      <Box>
                        <RadioGroup value={ordering} onChange={onChangeOrdering} className='align-h'>
                          {orderingOptions.map((option) => (
                            <CustomRadio
                              key={option.value}
                              value={option.value}
                              checked={ordering === option.value}
                              label={option.label}
                              onChange={onChangeOrdering}
                              disabled={option.disabled}
                              name=''
                            />
                          ))}
                        </RadioGroup>
                      </Box>
                      <Box className='update-date'>
                        <Typography component='p'>
                          <Typography component='span' className='date label'>
                            Duration, Batch time :
                          </Typography>
                          <Typography component='span' className='date value'>
                            daily UTC 19:10
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    {/* Latest first 선택할 시에 노출 */}
                    <Box>
                      <span onClick={() => handleInformationButtonClick()} >
                        <TextButton
                          label='Information'
                          isIcons={true}
                          icon={Icinfo24}
                          underline
                          iconWidth='24px'
                          iconHeight='24px'
                        />
                      </span>
                    </Box>

                    {/* Popular first 선택할 시에 버튼 노출 */}
                    <CustomButtonGroup buttons={buttons} justifyContent='flex-end' />
                  </Box>
                </Box>
                <Divider sx={{ margin: '24px 0' }} />

                {/* Criteria */}
                <Box display='flex' className='row'>
                  <div className='left-area'>
                    <Typography className='filter-label'>Criteria</Typography>
                  </div>
                    <div className='right-area'>
                    {criteriaList.map((n, i) => (
                      <Box component='div' className='field-box flex-start'>
                        <SelectBox
                          id='Criteria-01'
                          fullWidth
                          required={false}
                          size='large'
                          maxWidth='300px'
                          selectOptions={criteriaOptions}
                          disabled={false}
                          label='Criteria'
                          isFirstSelected={false}
                          selected={n.criterion}
                          onChange={e => {
                            onChangeCriteria(i, 'criterion', e);
                          }}
                          shrink
                        />

                        <SelectBox
                          id='Criteria-02'
                          fullWidth
                          required={false}
                          size='large'
                          maxWidth='500px'
                          selectOptions={[{id:'', value:'', option:'', type:'text'}, ...selectCriteriaDetailOptions(n.criterion)]}
                          disabled={false}
                          label='Detail Criteria'
                          isFirstSelected={false}
                          selected={n.criterionDetail}
                          onChange={e => {
                            onChangeCriteria(i, 'criterionDetail', e);
                           //onChangeCriteriaCode(i, 'criterionDetailCode', e, n.criterion);
                          }}
                          shrink
                        />
                        {/* 행 추가 버튼 */}
                        <Button
                          color='tertiary'
                          type='outline icon'
                          size='medium'
                          label=''
                          isIcon={true}
                          Icon={i === 0 ? AddPlus : DelMinus}
                          onClick={() => {
                            i === 0 ?
                            setCriteriaList(criteriaList.concat({criterion: 'keyword', criterionDetail: '', criterionDetailCode: ''}))
                            :
                            setCriteriaList(criteriaList.filter((n2, i2) => i2 !== i))
                          }}
                          autosize
                        />
                      </Box>
                      ))}
                    </div>
                </Box>

                {/* CP */}
                <Divider sx={{ margin: '24px 0' }} />
                <Box display='flex' className='row'>
                  <div className='left-area'>
                    <Typography className='filter-label'>CP</Typography>
                  </div>
                  <div className='right-area'>
                  {cpList.map((n, i) => (
                    <Box component='div' className='field-box flex-start'>
                      <SelectBox
                        id='select-01'
                        fullWidth
                        size='large'
                        maxWidth='300px'
                        selectOptions={[{id: 'All', value: 'All', option: 'All', type: 'text'}, ...providerOptions]}
                        disabled={false}
                        label='CP Name'
                        isFirstSelected={false}
                        selected={n.name}
                        onChange={e => {
                          onChangeCp(i, 'name', e);
                        }}
                        shrink
                      />
                      <TextField
                        type='outlined'
                        size='large'
                        placeholder=''
                        defaultValue=''
                        maxWidth='500px'
                        label='Count'
                        autoComplete='off'
                        required={false}
                        InputLabelProps={{ shrink: true }}
                        value={n.value}
                        onChange={e => {
                          onChangeCp(i, 'value', e.target.value);
                        }}
                      />
                      {/* 행 추가 버튼 */}
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={i === 0 ? AddPlus : DelMinus}
                        onClick={() => {
                          i === 0 ?
                          setCpList(cpList.concat({name: 'All', value: '1'}))
                          :
                          setCpList(cpList.filter((n2, i2) => i2 !== i))
                        }}
                        autosize
                      />
                    </Box>
                  ))}
                  </div>
                  <Box component='div' className='field-box flex-start'>
                    <Box display='flex' gap={1} marginLeft={'auto'}>
                      <Button
                        color='primary'
                        type='box'
                        size='medium'
                        label='Search'
                        iconPos={undefined} //left, right
                        isIcon={false} //true, false
                        Icon=''
                        onClick={searchContent}
                      />
                      <Button
                        color='primary'
                        type='box'
                        size='medium'
                        label='Save'
                        iconPos={undefined} //left, right
                        isIcon={false} //true, false
                        Icon=''
                        onClick={saveContent}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className=''></Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            {/* Box Content Area */}
            <Box className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box display='flex' alignItems={'center'} gap={1}>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        {displaySearchAutoContent && displaySearchAutoContent.length}
                      </Box>
                    </Box>
                    <Typography className='sm-sub-text' ml={3}>
                      *The expected results based on the above configuration are displayed below.
                    </Typography>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SearchField
                      id='search-1'
                      placeholder='Please enter a search term'
                      label='Ch.no or ch.Name'
                      maxWidth='230px'
                      size='medium'
                      required={false}
                      shrink={false}
                      onChange={(event) => handleSearchAutoContent(event.target.value)}
                    />
                  </Box>
                </Box>
                <Box className='right-area'></Box>
                {/* right-area */}
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  {/* thead Th 줄바꿈 클래스: auto */}
                  <Table className='custom-table auto' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {CommonAutoTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {paginatedData.map((row, index) => (
                        <TableRow className='tr' key={row.id || index}>
                          {CommonAutoTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                row.order
                              ) : colIndex === 1 ? (
                                row.cp_id
                              ) : colIndex === 2 ? (
                                row.content_type
                              ) : colIndex === 3 ? (
                                row.content_id
                              ) : colIndex === 4 ? (
                                row.name
                              ) : colIndex === 5 ? (
                                row.season
                              ) : colIndex === 6 ? (
                                row.episode
                              ) : colIndex === 7 ? (
                                <CustomImage
                                  src={row.image}
                                  fit={false}
                                  alt=''
                                  wrapWidth='81px'
                                  wrapHeight='64px'
                                  rounded
                                />
                              ) : colIndex === 8 ? (
                                row.start_date_time
                              ) : colIndex === 9 ? (
                                row.genre_name
                              ) : colIndex === 10 ? (
                                row.keywords
                              ) : colIndex === 11 ? (
                                row.last_chg_date
                              ) : (
                                row.content_id
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>
              <div>
                {actionDialog === 'INFORMATION' && (
                  <PopInformationAutoRule
                    onClose={() => setActionDialog('')}
                  />
                )}
              </div>
              <div>
                {actionDialog === 'CHECK KEYWORD' && (
                  <PopInformationKeywordCount
                    countryByKeywordList={countryByKeywordList}
                    onClose={() => setActionDialog('')}
                  />
                )}
              </div>
              <div>
                {actionDialog === 'CHECK GENRE' && (
                  <PopInformationGenreCount
                    countryByGenreList={countryByGenreList}
                    onClose={() => setActionDialog('')}
                  />
                )}
              </div>
              {/* Pagination */}
              <Pagination count={Math.ceil(displaySearchAutoContent.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CommonAuto;
