import React, { useEffect, useState } from 'react';
import Button from 'components/component/BasicButton';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { AddPlus, DelMinus } from 'components/component/BasicIcon';
import { Grid, Box } from '@mui/material';
import { postRequest } from 'utils/Request';
import { isEmpty } from 'utils/Valid';
import BasicDialog from 'components/component/BasicDialog';
import { CountrySelectData } from 'pages/Organization/WebService/data/OrganizationData';

function PopChannelAdd({ isPopUpOpen, countryCode, channelMapId, saveType, savedChannel, addManualChannelHandler, closeHandler }) {
  const [isOpen, setIsOpen] = useState(true);
  const [openSelects, setOpenSelects] = useState([]);
  const changeSetSelects = (index) => {
    const newOpenSelects = [...openSelects];
    newOpenSelects[index] = !newOpenSelects[index];
    setOpenSelects(newOpenSelects);
  };
  const [organizedChannel, setOrganizedChannel] = useState([]);
  const [addCountryCode, setAddCountryCode] = useState('');
  const [addChannelMapId, setAddChannelMapId] = useState('');
  const [addSaveType, setAddSaveType] = useState('');
  const [addIdx, setAddIdx] = useState(1);

  useEffect(() => {
    if (isPopUpOpen) {
      setAddCountryCode(countryCode);
      setAddChannelMapId(channelMapId);
      setAddSaveType(saveType);
    }
    setIsOpen(isPopUpOpen);
    // eslint-disable-next-line
  }, [isPopUpOpen]);

  useEffect(() => {
    setOrganizedChannel(savedChannel);
    // eslint-disable-next-line
  }, [savedChannel]);

  const onClose = () => {
    if (closeHandler) {
      setIsOpen(false);
      closeHandler();
    }
  };

  const manualChannel = {
    addIdx: 0,
    channelNumber: '',
    channelId: '',
    channelName: '',
    dmaGroupCode: '',
    dmaGroupName: '',
  };

  const [manualList, setManualList] = useState([{ ...manualChannel }]);
  const [verifiedManualList, setVerifiedManualList] = useState([]);

  useEffect(() => {
    let disabledButton = false;
    if (!isEmpty(manualList)) {
      manualList.forEach((f) => {
        if (isEmpty(f.channelNumber) || isEmpty(f.channelId)) {
          disabledButton = true;
        }
      });
    }
    setAddPopButton([cancelButton, { ...saveButton, disabled: disabledButton }]);
    // eslint-disable-next-line
  }, [manualList]);

  const addChannelInput = () => {
    let addList = [...manualList];
    const idx = addIdx + 1;
    addList.push({
      addIdx: idx,
      channelNumber: '',
      channelId: '',
      channelName: '',
      dmaGroupCode: '',
      dmaGroupName: '',
    });
    setAddIdx(idx);
    setManualList(addList);
  };

  const removeChannelInput = (removeIdx) => {
    let list = [...manualList];
    if (list.length > 1) {
      list = list.filter((f) => f.addIdx !== removeIdx);
    }
    setManualList(list);
  };

  const addChannelHandler = (event, addIdx) => {
    event.preventDefault();
    let { name, value } = event.target;
    if (!isEmpty(value)) {
      value = value.trim();
    }
    let list = [...manualList];
    list.forEach((f) => {
      if (f.addIdx === addIdx) {
        if (name === 'addChannelNumber') {
          if (!isEmpty(value)) {
            if (isFinite(value)) {
              f.channelNumber = value;
            }
          } else {
            f.channelNumber = '';
          }
        } else if (name === 'addChannelId') {
          f.channelId = value;
        } else if (name === 'addChannelName') {
          f.channelName = value;
        }
      }
    });
    setManualList(list);
  };

  const channelIdDuplicate = (manual, organizedList) => {
    return !isEmpty(organizedList.find((organized) => manual.channelId === organized.channelId));
  };

  const channelNumberDuplicate = (manual, organizedList) => {
    return !isEmpty(organizedList.find((organized) => manual.channelNumber === organized.displayChannelNumber));
  };

  const checkManualChannel = async () => {
    let title = 'Add';
    let content = '';
    if (isEmpty(manualList)) {
      return;
    }

    const manualIdSet = new Set(manualList.map((m) => m.channelId));
    if (manualIdSet.size !== manualList.length) {
      content = 'The channel ID is duplicated.!';
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
      return;
    }

    const manualNumberSet = new Set(manualList.map((m) => m.channelNumber));
    if (manualNumberSet.size !== manualList.length) {
      content = 'The channel number is duplicated.!';
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
      return;
    }

    let duplicate;
    duplicate = [...manualList].find((manual) => channelIdDuplicate(manual, organizedChannel));
    if (!isEmpty(duplicate)) {
      content = '[' + duplicate.channelId + '] Channel ID already configured';
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
      return;
    }

    duplicate = [...manualList].find((manual) => channelNumberDuplicate(manual, organizedChannel));
    if (!isEmpty(duplicate)) {
      content = '[' + duplicate.channelNumber + '] Duplicate channel numbers';
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
      return;
    }

    const requestBody = {
      countryCode: addCountryCode,
      channelMapId: addChannelMapId,
      saveType: addSaveType,
      savedList: manualList,
    };

    const body = await postRequest(`/channel-map/manual`, requestBody, null);
    if (body && body.result === 'SUCCESS') {
      title = 'Add';
      content = 'Would you like to organize the channels?';
      setVerifiedManualList(body.data);
      setPopupType({ ...defaultPopupType, add: true, title: title, content: content });
    } else {
      if (body && body.result === 'FAILED') {
        title = body.result;
        content = body.message;
      } else if (body && body.result === 'INVALID') {
        title = body.result;
        content = body.message;
        if (!isEmpty(body.data.feed)) {
          content = '[' + body.data.feed.join(', ') + '] Channel ID not feed';
        }
      } else {
        title = body.error;
        content = 'Contact your administrator!';
      }
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
    }
  };

  const addManualChannel = () => {
    if (!isEmpty(verifiedManualList)) {
      let addList = [];
      verifiedManualList.forEach((manual) => {
        const add = {
          id: -1,
          savedId: 'manual_'.concat(manual.channelIdx),
          channelMapIdx: -1,
          channelIdx: manual.channelIdx,
          channelId: manual.channelId,
          channelNumber: manual.channelNumber,
          channelName: manual.channelName,
          displayChannelNumber: manual.channelNumber,
          displayLogoUrl: manual.logoUrl,
          providerId: manual.providerId,
          providerName: manual.providerName,
          genreName: manual.genreName,
          dmaGroupSet: manual.dmaGroupSet,
          dmaGroupCode: manual.dmaGroupCode,
          dmaGroupName: manual.dmaGroupName,
          dmaFlag: manual.dmaFlag,
          dmaList: manual.dmaList,
          saveType: addSaveType,
          countryCode: addCountryCode,
          lastChgDate: '',
          lastChgUsrId: 'new',
          editAction: 'added',
        };
        addList.push(add);
      });
      addManualChannelHandler(addList);
      setPopupType(defaultPopupType);
      onClose();
    }
  };

  // Button
  const cancelButton = {
    width: '150px',
    size: 'large',
    label: 'Cancel',
    type: 'outline',
    color: 'tertiary',
    onClick: closeHandler,
  };
  const saveButton = {
    width: '150px',
    size: 'large',
    label: 'Save',
    type: 'box',
    color: 'primary',
    disabled: true,
    onClick: checkManualChannel,
  };
  const [addPopButton, setAddPopButton] = useState([cancelButton, saveButton]);
  const defaultPopupType = {
    add: false,
    alarm: false,
    title: '',
    content: '',
  };
  const [popupType, setPopupType] = useState(defaultPopupType);

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const closeButton = {
    buttonId: 'closeButton',
    label: 'Close',
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    isIcon: false,
    Icon: null,
    fontWeight: 'bold',
    disabled: false,
    autosize: true,
    onClick: closeDialog,
  };
  const alarmButtons = [closeButton];

  const addChannelButton = {
    buttonId: 'addChannelButton',
    size: 'medium',
    isIcon: false,
    Icon: null,
    fontWeight: 'bold',
    disabled: false,
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    onClick: addManualChannel,
  };
  const channelAddButtons = [{ ...closeButton, label: 'Cancel' }, addChannelButton];

  return (
    <div className='component-view'>
      <Dialog
        open={isOpen}
        closeX={true}
        title='Channel Add' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={onClose}
        size='large' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-addChannel'
        contentTextProps={
          <Grid container sx={{ marginLeft: 0 }}>
            {/* add channel */}
            {manualList.map((row) => (
              <Grid container xs={12} gap={1} alignItems='center'>
                {/* 1st Tab Name */}
                <Grid item xs={2.5}>
                  <Box component='div' className='field-box flex-start'>
                    <TextField
                      id={`addChannelNumber${row.addIdx}`}
                      name={'addChannelNumber'}
                      type='outlined'
                      size='large'
                      placeholder='Ch.No'
                      value={row.channelNumber}
                      label='Ch.No'
                      autoComplete='off'
                      required={true}
                      InputLabelProps={{ shrink: true }}
                      maxLength={4}
                      onChange={(event) => addChannelHandler(event, row.addIdx)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={2.5}>
                  <Box component='div' className='field-box flex-start'>
                    <TextField
                      id={`addChannelId${row.addIdx}`}
                      name={'addChannelId'}
                      type='outlined'
                      size='large'
                      placeholder='Ch.ID'
                      defaultValue=''
                      label='Ch.ID'
                      autoComplete='off'
                      required={true}
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => addChannelHandler(event, row.addIdx)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={2.5}>
                  <Box component='div' className='field-box flex-start'>
                    <TextField
                      id={`addChannelName${row.addIdx}`}
                      name={'addChannelName'}
                      type='outlined'
                      size='large'
                      placeholder='Ch.Name'
                      defaultValue=''
                      label='Ch.Name'
                      autoComplete='off'
                      required={false}
                      //required={true}
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => addChannelHandler(event, row.addIdx)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={2.5}>
                  <Box component='div' className='field-box flex-start'>
                    <SelectBox
                      id={`addDmaGroupName${row.addIdx}`}
                      name={'addDmaGroupName'}
                      size='large'
                      maxWidth='201px'
                      required={false}
                      selectOptions={CountrySelectData}
                      disabled={false}
                      label='DMA Group Name'
                      isFirstSelected={true}
                      open={openSelects[1]}
                      setOpen={() => changeSetSelects(1)}
                      shrink
                    />
                    {/* <TextField
                      name={'addChannelName'}
                      type='outlined'
                      size='large'
                      placeholder='Ch.Name'
                      defaultValue=''
                      label='Ch.Name'
                      autoComplete='on'
                      required={false}
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => addChannelHandler(event, row.addIdx)}
                    /> */}
                  </Box>
                </Grid>
                <Grid container xs={1.2} gap={1} sx={{ marginLeft: '16px' }}>
                  <Grid item>
                    <Button
                      name={'addChannelButton'}
                      color='tertiary'
                      type='outline icon'
                      size='medium'
                      label=''
                      isIcon={true}
                      Icon={AddPlus}
                      iconPos='center'
                      autosize
                      onClick={() => addChannelInput()}
                    />
                  </Grid>
                  {row.addIdx !== 0 && (
                    <Grid item>
                      <Button
                        name={'removeChannelButton'}
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={DelMinus}
                        iconPos='center'
                        autosize
                        onClick={() => removeChannelInput(row.addIdx)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
            {/* add channel */}
          </Grid>
        }
        buttons={addPopButton}
      />
      {popupType.add && <BasicDialog title={popupType.title} contentTitle={popupType.content} size={'small'} closeX={true} open={true} handleClose={closeDialog} buttons={channelAddButtons} />}
      {popupType.alarm && <BasicDialog title={popupType.title} contentTitle={popupType.content} size={'small'} closeX={true} open={true} handleClose={closeDialog} buttons={alarmButtons} />}
    </div>
  );
}

export default PopChannelAdd;
