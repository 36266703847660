import React, { useState, useEffect, Fragment } from 'react';
import Button from 'components/component/BasicButton';
import SearchField from 'components/component/BasicSearchField';
import Tooltip from 'components/component/BasicTooltip';
import Pagination from 'components/component/BasicPagination';
import CustomImage from 'components/component/BasicImage';
import CheckBox from 'components/component/BasicCheckBox';
import SortLabel from 'components/component/BasicTableSortLabel';
import IconButton from '@mui/material/IconButton';
import Dialog from 'components/component/BasicDialog';
import { styled } from '@mui/material/styles';
import TextLink from 'components/component/BasicTextLink';
import { Box, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// ICON
import { BtnArrowRight, PrimaryInfoIcon } from 'components/component/BasicIcon';

import {
  defaultPopupType,
  defaultButton,
  CarouselChannelHeader,
  CarouselVodHeader,
  CarouselMoreHeader,
  CarouselHubTabHeader,
  CarouselNoticeHeader,
  CarouselEventHeader,
} from 'pages/Organization/TabCategory/Carousel/DefaultData';
import { isEmpty } from 'utils/Valid';
import EventCreatePop from 'pages/Organization/TabCategory/Carousel/EventCreatePop';
import EventEditPop from 'pages/Organization/TabCategory/Carousel/EventEditPop';
import { postRequest, deleteRequest } from 'utils/Request';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Tooltip 내부에 출력될 자식 컴포넌트 //
const InfoTooltip = (props, ref) => {
  return (
    <div className='info-list-box'>
      {props.list.map((infoField, i) => (
        <div className='info'>
          <Typography className='label'>{`${infoField.info}`}</Typography>
        </div>
      ))}
    </div>
  );
};

// 메인 컨텐츠
function CarouselSearchList({ carouselTab, carouselCountry, carouselCategory, carouselType, carouselContentsList, disabledContentList, setCarouselResult, refreshContents }) {
  const [headerType, setHeaderType] = useState('channel');
  const [header, setHeader] = useState(CarouselChannelHeader);
  const [contentsList, setContentsList] = useState([]);
  const [disabledList, setDisabledList] = useState([]);
  const [displayContentsList, setDisplayContentsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isPageNavigate, setIsPageNavigate] = useState(false);
  const [contentsCheckedList, setContentsCheckedList] = useState([]);
  const [contentsAllChecked, setContentsAllChecked] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [popupType, setPopupType] = useState(defaultPopupType);
  const [editEvent, setEditEvent] = useState(null);

  const initSearchList = () => {
    setContentsList([]);
    setDisplayContentsList([]);
    setIsPageNavigate(false);
    setContentsCheckedList([]);
    setContentsAllChecked(false);
    setSearchField('');
  };

  useEffect(() => {
    setHeaderType(carouselType);
    switch (carouselType) {
      case 'vod':
        setHeader(CarouselVodHeader);
        break;
      case 'more':
        setHeader(CarouselMoreHeader);
        break;
      case 'hub':
        setHeader(CarouselHubTabHeader);
        break;
      case 'notification':
        setHeader(CarouselNoticeHeader);
        break;
      case 'event':
        setHeader(CarouselEventHeader);
        break;
      default:
        setHeader(CarouselChannelHeader);
        break;
    }
    // eslint-disable-next-line
  }, [carouselType]);

  useEffect(() => {
    if (isEmpty(carouselContentsList)) {
      initSearchList();
    } else {
      setContentsList(carouselContentsList);
      setPageNavigate(carouselContentsList, 1, pageSize);
      setContentsCheckedList();
      setContentsAllChecked(false);
      setSearchField('');
    }
    // eslint-disable-next-line
  }, [carouselContentsList]);

  useEffect(() => {
    if (!isEmpty(displayContentsList) && !isEmpty(disabledContentList)) {
      displayContentsList.forEach((f) => {
        f.isOrganized = disabledContentList.includes(f.contentId);
      });
    }
    setDisplayContentsList([...displayContentsList]);
    setDisabledList(disabledContentList);
    // eslint-disable-next-line
  }, [disabledContentList]);

  useEffect(() => {
    // TODO
    console.log('displayContentsList', displayContentsList);
    console.log('displayContentsList - disabledList', disabledList);
    // eslint-disable-next-line
  }, [displayContentsList]);

  const paginationHandler = (paging) => {
    if (paging) {
      if (paging.type === 'page') {
        setPageNavigate(contentsList, paging.value, pageSize);
      } else if (paging.type === 'size') {
        setPageNavigate(contentsList, 1, paging.value);
      }
      setContentsCheckedList([]);
      setContentsAllChecked(false);
    }
  };

  const setPageNavigate = (list, current, size) => {
    if (!isEmpty(list)) {
      setIsPageNavigate(true);
      const startIndex = (current - 1) * size;
      const endIndex = startIndex + size;
      const displayList = list.slice(startIndex, endIndex);
      if (!isEmpty(disabledList)) {
        displayList.forEach((f) => {
          f.isOrganized = disabledContentList.includes(f.contentId);
        });
      }
      setDisplayContentsList([...displayList]);
    } else {
      setIsPageNavigate(false);
      setDisplayContentsList([]);
    }
    setCurrentPage(current);
    setPageSize(size);
  };

  const firstMappingInfo = (mapList) => {
    let map = '-';
    if (!isEmpty(mapList)) {
      map = mapList.split(',')[0];
    }
    return map;
  };

  const setMappingInfo = (mapList) => {
    let list = [];
    if (!isEmpty(mapList)) {
      list = mapList.split(',').map((m) => {
        return {
          info: m,
        };
      });
    }
    return list;
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const dataSorting = (col, desc) => {
    setDisplayContentsList(sortRows([...displayContentsList], col, desc));
  };

  const isContentsAllChecked = (e) => {
    if (!isEmpty(displayContentsList)) {
      let checkedList = [];
      let checked = e.target.checked;
      if (checked) {
        checkedList = displayContentsList.filter((f) => !f.isOrganized).map((f) => f.contentId);
        if (!isEmpty(checkedList)) {
          setContentsCheckedList(checkedList);
        } else {
          checkedList = [];
          checked = false;
        }
      }
      setContentsCheckedList(checkedList);
      setContentsAllChecked(checked);
    }
  };

  const setResultContents = () => {
    if (isEmpty(contentsCheckedList) && contentsCheckedList.length < 1) {
      return;
    }
    let checkedList = contentsList.filter((f) => contentsCheckedList.includes(f.contentId));
    if (!isEmpty(checkedList) && checkedList.length > 0) {
      if (setCarouselResult) {
        checkedList.forEach((f) => {
          f.editAction = 'added';
        });
        setCarouselResult(checkedList);
        setContentsCheckedList([]);
        setContentsAllChecked(false);
      }
    }
  };

  const isContentsChecked = (contentId) => {
    if (isEmpty(contentsCheckedList)) {
      return false;
    } else {
      return contentsCheckedList.includes(contentId);
    }
  };

  const setContentsChecked = (e) => {
    let checkList = [];
    if (!isEmpty(contentsCheckedList)) {
      checkList = [...contentsCheckedList];
    }
    if (e.target.checked) {
      checkList.push(e.target.value);
    } else {
      checkList = checkList.filter((f) => f !== e.target.value);
    }
    let allCheck = false;
    let availableList = [];

    if (!isEmpty(checkList) && !isEmpty(displayContentsList)) {
      availableList = displayContentsList.filter((f) => (isEmpty(f.isOrganized) ? false : !f.isOrganized));
      let unCheckedList = availableList.filter((f) => !checkList.includes(f.contentId));
      if (isEmpty(unCheckedList)) {
        allCheck = true;
      }
    }
    setContentsAllChecked(allCheck);
    setContentsCheckedList(checkList);
  };

  const eventDetail = (content) => {
    console.log('eventDetail', content);
    if (!isEmpty(content)) {
      setEditEvent(content);
      setPopupType({ ...defaultPopupType, edit: true });
    }
  };

  const searchFieldHandler = (searchText) => {
    setSearchField(searchText);
    let rows = [];
    if (!isEmpty(carouselContentsList)) {
      rows = [...carouselContentsList];

      if (!isEmpty(searchText)) {
        searchText = searchText.toString().toLowerCase();
        rows = rows.filter((search) => isIncludeSearchText(search, searchText));
      }
    }
    setContentsList(rows);
    setPageNavigate(rows, 1, pageSize);
    // // init filter
    setContentsCheckedList([]);
    setContentsAllChecked(false);
  };

  const isIncludeSearchText = (row, searchText) => {
    let isContentId = false;
    let isContentName = false;
    let isChannelMap = false;
    let isTabName = false;
    let isProviderId = false;

    if (!isEmpty(row.contentId)) {
      isContentId = row.contentId.toString().toLowerCase().indexOf(searchText) > -1;
    }
    if (!isEmpty(row.contentName)) {
      isContentName = row.contentName.toString().toLowerCase().indexOf(searchText) > -1;
    }
    if (!isEmpty(row.contentName)) {
      isChannelMap = row.contentName.toString().toLowerCase().indexOf(searchText) > -1;
    }
    if (!isEmpty(row.channelMap)) {
      isTabName = row.channelMap.toString().toLowerCase().indexOf(searchText) > -1;
    }
    if (!isEmpty(row.providerId)) {
      isProviderId = row.providerId.toString().toLowerCase().indexOf(searchText) > -1;
    }
    return isContentId || isContentName || isChannelMap || isTabName || isProviderId;
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const createEventDialog = () => {
    setPopupType({ ...defaultPopupType, create: true });
  };

  const requestEvent = async () => {
    let body = null;
    if (popupType.publish) {
      body = await postRequest('/home-app/carousel/event/publish', contentsCheckedList, null);
    } else if (popupType.remove) {
      body = await deleteRequest('/home-app/carousel/event', contentsCheckedList, null);
    } else {
      return;
    }

    let title = '';
    let content = '';
    if (body && body.result === 'SUCCESS') {
      title = body.result;
      content = 'Saved successfully.';
      setPopupType({ ...defaultPopupType, success: true, title: title, content: content });
    } else {
      if (body && body.result === 'FAILED') {
        title = body.result;
        content = body.message;
      } else {
        title = body.error;
        content = 'Contact your administrator!';
      }
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
    }
  };

  const publishEventHandler = () => {
    if (carouselType !== 'event') {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Publish Event',
        content: 'carousel-type can only distribute event to RS server content is already exist !',
      });
      return;
    } else if (isEmpty(contentsCheckedList) || contentsCheckedList.length < 1) {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Publish Event',
        content: 'Please select an event to distribute to the RS server',
      });
      return;
    }
    setPopupType({
      ...defaultPopupType,
      publish: true,
      title: 'Publish Event',
      content: 'Do you want to deploy to the RS server?',
    });
  };

  const deleteEventHandler = () => {
    if (carouselType !== 'event') {
      alert('carousel-type can only distribute event to RS server content is already exist !');
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Delete Event',
        content: 'carousel-type can only distribute event to RS server content is already exist !',
      });
      return;
    } else if (isEmpty(contentsCheckedList) || contentsCheckedList.length < 1) {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Delete Event',
        content: 'Select an event to delete',
      });
      return;
    }
    console.log('deleteEventHandler2');
    setPopupType({
      ...defaultPopupType,
      remove: true,
      title: 'Delete Event',
      content: 'Are you sure you want to delete the event?',
    });
  };

  const successHandler = () => {
    refreshContents();
    closeDialog();
  };

  // Button Data
  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const successButton = {
    ...defaultButton,
    buttonId: 'successButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: successHandler,
  };
  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButtonId',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  // event publish
  const publishButton = {
    ...defaultButton,
    buttonId: 'publishButtonId',
    color: 'primary',
    type: 'box',
    label: 'Publish',
    autosize: true,
    disabled: false,
    onClick: requestEvent,
  };
  // event delete
  const removeButton = {
    ...defaultButton,
    buttonId: 'removeButtonId',
    color: 'primary',
    type: 'box',
    label: 'Delete',
    autosize: true,
    disabled: false,
    onClick: requestEvent,
  };

  return (
    <Grid item xs={6}>
      <Box className='white-box white-box-wrap'>
        {/* Box Title Area */}
        <Box className='title-wrap space-between'>
          <Box className='left-area'>
            <Box className='box-title-area' display='flex' alignItems='center'>
              <Typography variant='h2'>Result for Content</Typography>
              <Typography component='p' className='select-count-wrap'>
                Select <span className='set-color'>{isEmpty(contentsCheckedList) ? 0 : contentsCheckedList.length}</span> item
              </Typography>
            </Box>
          </Box>
          <Box className='right-area'>
            <Box className='show-count-wrap'>
              <Typography component='span' className='label'>
                Showing All
              </Typography>
              <Typography component='span' className='value'>
                {isEmpty(contentsList) ? 0 : contentsList.length}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Box Content Area */}
        <Box className='box-content'>
          <Box className='white-box-content-top-area'>
            <Box className='left-area'>
              <SearchField
                id='search-1'
                placeholder='Please enter a search term'
                label='Ch.no or ch.Name'
                maxWidth='230px'
                size='medium'
                required={false}
                shrink={false}
                value={searchField}
                setSearchText={searchFieldHandler}
              />
            </Box>
            <Box className='right-area'>
              {headerType === 'event' && <Button color='primary' type='box' size='small' label='Create Event' isIcon={true} autosize={true} Icon='' iconPos={undefined} onClick={createEventDialog} />}
              {headerType === 'event' && (
                <Button
                  color={!isEmpty(contentsCheckedList) && contentsCheckedList.length > 0 ? 'primary' : 'tertiary'}
                  type='box'
                  size='small'
                  label='Delete'
                  isIcon={true}
                  autosize={true}
                  Icon=''
                  iconPos={undefined}
                  onClick={deleteEventHandler}
                />
              )}
              {headerType === 'event' && (
                <Button
                  color={!isEmpty(contentsCheckedList) && contentsCheckedList.length > 0 ? 'primary' : 'tertiary'}
                  type='box'
                  size='small'
                  label='Deploy'
                  isIcon={true}
                  autosize={true}
                  Icon=''
                  iconPos={undefined}
                  onClick={publishEventHandler}
                />
              )}
              <Button
                color={!isEmpty(contentsCheckedList) && contentsCheckedList.length > 0 ? 'primary' : 'tertiary'}
                type='box'
                size='small'
                label='Select Ch Input'
                isIcon={true}
                autosize
                Icon={BtnArrowRight}
                iconPos='right'
                onClick={setResultContents}
              />
            </Box>
          </Box>

          <Box className='content-inner-wrap'>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table' aria-label='table' stickyHeader sx={{ width: 'auto' }}>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    {header.map((column, index) => (
                      <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                        {column.checkbox ? (
                          <Box component='div' sx={{ display: 'flex', gap: '10px' }}>
                            <Box component='div' sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                              <CheckBox
                                label=''
                                value='select-all'
                                checked={contentsAllChecked}
                                onChange={isContentsAllChecked}
                                inputProps={{
                                  'aria-label': 'select all',
                                }}
                              />
                            </Box>
                            <SortLabel
                              labelText={column.label}
                              labelId={column.labelId}
                              sortable={column.sortable}
                              orderBy={orderBy}
                              setOrderBy={setOrderBy}
                              order={order}
                              setOrder={setOrder}
                              sorting={dataSorting}
                            />
                          </Box>
                        ) : (
                          <>
                            {column.sortable ? (
                              <SortLabel
                                labelText={column.label}
                                labelId={column.labelId}
                                sortable={column.sortable}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={dataSorting}
                              />
                            ) : (
                              column.label
                            )}
                          </>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* table Body */}
                {!isEmpty(displayContentsList) && (
                  <TableBody className='tbody'>
                    {displayContentsList.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        {header.map((column, colIndex) => (
                          <TableCell key={column.id} className='td' align={column.align}>
                            {column.checkbox ? (
                              <CheckBox
                                label={td[column.labelId]}
                                value={td.contentId}
                                checked={isEmpty(contentsCheckedList) ? false : isContentsChecked(td.contentId)}
                                onChange={(event) => setContentsChecked(event)}
                                disabled={td.isOrganized}
                              />
                            ) : column.labelId === 'contentName' && td.carouselType === 'event' ? (
                              <TextLink href='' text={td[column.labelId]} onClick={() => eventDetail(td)} />
                            ) : column.labelId === 'deepLink' ? (
                              td[column.labelId] === 'Y' ? (
                                `${td[column.labelId]} (${td.linkContentType})`
                              ) : (
                                td[column.labelId]
                              )
                            ) : column.labelId === 'image' ? (
                              <CustomImage src={td[column.labelId]} fit={false} alt='' wrapWidth='66px' wrapHeight='64px' rounded />
                            ) : column.labelId === 'verticalImage' ? (
                              <CustomImage src={td[column.labelId]} fit={false} alt='' wrapWidth='66px' wrapHeight='64px' rounded />
                            ) : column.labelId === 'focusImage' ? (
                              <CustomImage src={td[column.labelId]} fit={false} alt='' wrapWidth='66px' wrapHeight='64px' rounded />
                            ) : column.labelId === 'channelMap' ? (
                              <>
                                <Typography component='span' className={firstMappingInfo(td[column.labelId])} sx={{ verticalAlign: 'bottom' }}>
                                  {firstMappingInfo(td[column.labelId])}
                                </Typography>
                                <>
                                  {/* Icon Tooltip */}
                                  <Tooltip title={<InfoTooltip list={setMappingInfo(td[column.labelId])} />} placement='top'>
                                    <IconButton size='small' sx={{ width: '24px', height: '24px', marginLeft: '4px' }}>
                                      <PrimaryInfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              </>
                            ) : (
                              <Typography component='p'>{td[column.labelId]}</Typography>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </CustomTableContainer>
            {/* // Table */}
          </Box>
          {isPageNavigate && <Pagination count={Math.ceil(contentsList.length / pageSize)} id='reservation-pagination' selected={pageSize} onChangeHandle={paginationHandler} page={currentPage} />}
        </Box>
      </Box>
      {popupType.create && (
        <EventCreatePop deviceType={carouselTab.deviceType} countryCode={carouselCountry} categoryIdx={carouselCategory.idx} closeHandler={closeDialog} refreshContents={refreshContents} />
      )}
      {popupType.edit && (
        <EventEditPop
          deviceType={carouselTab.deviceType}
          countryCode={carouselCountry}
          categoryIdx={carouselCategory.idx}
          event={editEvent}
          closeHandler={closeDialog}
          refreshContents={refreshContents}
        />
      )}
      {(popupType.alarm || popupType.publish || popupType.remove || popupType.success) && (
        <Dialog
          title={popupType.title}
          contentTitle={popupType.content}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={closeDialog}
          buttons={popupType.alarm ? [closeButton] : popupType.publish || popupType.remove ? [cancelButton, popupType.remove ? removeButton : publishButton] : popupType.success ? [successButton] : []}
        />
      )}
    </Grid>
  );
}
export default CarouselSearchList;
