import React, { useEffect, useState } from 'react';

import Dialog from 'components/component/BasicDialog';
import {
  Link,
  List,
  Box,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

import { FileIcon } from 'components/component/BasicIcon';
import CustomTabs from 'components/component/BasicTabs';
import Tag from 'components/component/BasicTag';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import PopNotification from './PopNotification';

const StyledLink = styled('a')`
  width: 24px;
  height: 24px;
  display: inline-block;
`;

function NotificationList(props) {
  const { darkMode, channel } = props;

  // Notification category
  const [notificationCategory, setNotificationCategory] = useState([]);

  // tab name
  const [tabs, setTabs] = useState([]);

  // current tab value
  const [currentTabValue, setCurrentTabValue] = useState(0);

  // tab 별 data
  const [notifications, setNotifications] = useState({});

  // Local storage에 저장되어 있는 Support data
  const [savedSupportData, setSavedSupportData] = useState({ CMP: [], Partner: [] });

  // data 표시 여부
  const [isDisplayData, setDisplayData] = useState(false);

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // Notification popup
  const [isOpen, setIsOpen] = useState(false);

  // 현재 선택된 Notification data
  const [currentNotification, setCurrentNotification] = useState({});

  useEffect(() => {
    const savedData = localStorage.getItem('supportData');
    setSavedSupportData(savedData ? JSON.parse(savedData) : { CMP: [], Partner: [] });

    getNotificationCategory();
  }, [channel]);

  useEffect(() => {
    if (notificationCategory.length > 0) {
      getNotificationData();
    }
    // eslint-disable-next-line
  }, [notificationCategory]);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setCurrentTabValue(newValue);
  };

  const getNotificationCategory = () => {
    fetch(`${BASE_URL}/support/notification/filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setNotificationCategory(body.data.categories);

        const tempTabs = [
          { label: 'All', badgeType: '' },
            ...body.data.categories.map(category => {
              return { label: category, badgeType: '' };
          })
        ];

        setTabs(tempTabs);
      } else {
        throw new Error(`support/notification/filter request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const getNotificationData = () => {
    const param = `channel=${channel}&limit=5`;

    setDisplayData(false);

    fetch(`${BASE_URL}/support/notification/Category?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const notificationsData = body.data.notifications;

        const updatedSupportData = notificationsData.map(notification => {
          const existingSupport = savedSupportData[channel].find(s => s.idx === notification.idx);
          return existingSupport ? { ...existingSupport } : { idx: notification.idx, category: notification.category, readFlag: 'N' };
        });

        // idx 값으로 정렬하여 저장
        const sortedSupport = updatedSupportData.sort((a, b) => a.id - b.id);

        // supportData 업데이트
        const newSupportData = { ...savedSupportData, [channel]: sortedSupport };

        localStorage.setItem('supportData', JSON.stringify(newSupportData));
        setSavedSupportData(newSupportData);

        const categorizedNotifications = tabs.reduce((acc, tab) => {
          acc[tab.label] = notificationsData
            .filter(notification => notification.category === tab.label)
            .map(notification => {
              const existingSupport = savedSupportData[channel].find(s => s.idx === notification.idx);
              return existingSupport ? { ...notification, readFlag: existingSupport.readFlag } : { ...notification, readFlag: 'N' };
            });
          return acc;
        }, {});

        categorizedNotifications.All = notificationsData
        .sort((a, b) => new Date(b.crtDate) - new Date(a.crtDate))
        .slice(0, 5)
        .map(notification => {
          const existingSupport = savedSupportData[channel].find(s => s.idx === notification.idx);
          return existingSupport ? { ...notification, readFlag: existingSupport.readFlag } : { ...notification, readFlag: 'N' };
        });

        const updatedTabs = tabs.map(tab => {
          const hasUnread = categorizedNotifications[tab.label]?.some(notification => notification.readFlag === 'N');
          return hasUnread ? { ...tab, badgeType: 'new' } : tab;
        });

        setTabs(updatedTabs);
        setNotifications(categorizedNotifications);
      } else {
        throw new Error(`support/notification request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    })
    .finally(() => {
      setDisplayData(true);
    });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleNotificationClick = (event, idx) => {
    event.preventDefault();

    const updatedSupportData = savedSupportData[channel].map(item => item.idx === idx ? { ...item, readFlag: 'Y' } : item);
    const newSupportData = { ...savedSupportData, [channel]: updatedSupportData };

    localStorage.setItem('supportData', JSON.stringify(newSupportData));
    setSavedSupportData(newSupportData);

    setNotifications(prevNotifications => {
      const updatedNotifications = { ...prevNotifications };
      Object.keys(updatedNotifications).forEach(tabName => {
        updatedNotifications[tabName] = updatedNotifications[tabName].map(notification =>
          notification.idx === idx ? { ...notification, readFlag: 'Y' } : notification
        );
      });
      return updatedNotifications;
    });

    setTabs(prevTabs => {
      return prevTabs.map(tab => {
        const hasUnread = notifications[tab.label]?.some(notification => notification.idx === idx ? false : notification.readFlag === 'N');
        return hasUnread ? { ...tab, badgeType: 'new' } : { ...tab, badgeType: '' };
      });
    });

    setCurrentNotification(notifications[tabs[currentTabValue].label].find(notification => notification.idx === idx));

    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isDisplayData && <Box className='notification-list-container'>
        <CustomTabs value={currentTabValue} handleChange={handleChange} tabs={tabs} />
        <div className='tab-panel' >
          <div className='panel-wrap'>
            <List>
              <Box>
                {notifications[tabs[currentTabValue].label] && notifications[tabs[currentTabValue].label].map((data, idx) => (
                  <ListItem key={data.id}>
                    <ListItemText
                      primary={
                        <div className={`row ${data.readFlag === 'N'}`}>
                          <div className='col col-01'>
                            <Typography component='span' variant='body1' sx={{ fontSize: '14px' }}>
                              {data.idx}
                            </Typography>
                          </div>
                          <div className='col col-02'>
                            {data.newFlag === 'T' && <Tag type='new' label='new' className='chip' /> }
                          </div>
                          <div className='col col-03'>
                            <Typography component='p' variant='body1' sx={{ fontSize: '14px' }}>
                              <Link
                                href='#'
                                underline='hover'
                                onClick={(event) => handleNotificationClick(event, data.idx)}
                              >
                                {`[${data.category}] `}
                                {data.title}
                              </Link>
                            </Typography>
                          </div>
                          <div className='col col-04'>
                            {data.hasAttachment === 'Y' && (
                              <StyledLink href='' className='file-icon'>
                                <FileIcon />
                              </StyledLink>
                            )}
                            <Typography component='span' variant='body1' className='notifi-date'>
                              {data.crtDate}
                            </Typography>
                          </div>
                        </div>
                      }
                    />
                  </ListItem>
                ))}
              </Box>
            </List>
          </div>
        </div>
      </Box>}
      <PopNotification darkMode={darkMode} currentNotification={currentNotification} isOpen={isOpen} callbackClose={handleClose} />
      <Dialog
        open={isAlertOpen}
        handleClose={handleAlertClose}
        closeX={false}
        size='small'
        title={alertTitle}
        contentTitle={false}
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </>
  );
}

export default NotificationList;