import { InputLabel, FormControl } from '@mui/material';
import styled from '@emotion/styled';

export const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
    &.large {
      height: 40px;

      .MuiInputBase-root {
        height: 40px;
      }
      .MuiSelect-select {
        height: 40px;
        box-sizing: border-box;
        padding: 12px 30px 12px 12px;
        overflow: hidden;
        text-overflow: ellipsis;

        .MuiListItemText-root {
          margin: 0;
        }
      }
    }
    &.medium {
      height: 32px;

      .MuiSelect-select {
        height: 32px;
        box-sizing: border-box;
        padding: 10px 12px 4px;

        .MuiListItemText-root {
          margin: 0;
        }
      }
    }
    &.small {
      height: 28px;

      .MuiInputBase-root {
        height: 28px;
      }
      .MuiSelect-select {
        height: 28px;
        box-sizing: border-box;
        padding: 8px 12px;

        .MuiListItemText-root {
          margin: 0;
        }
      }
    }
    // Pagination 왼쪽 출력할 행의 수 선택 Select box
    .print-per-count {
      min-width: 100px;
      padding: 0;

      .MuiSelect-select {
        width: 100%;
        padding: 6px 12px;
        border-color: #a3a3a3;
        font-size: 14px;
        font-weight: 400;
        color: #525252;
        line-height: 20px;
      }
    }
  }
  .MuiFormLabel-root {
    top: 50%;
    transform: translate(14px, -50%);
    font-size: 12px;
    line-height: 20px;

    &.MuiInputLabel-sizeLarge {
      &.MuiFormLabel-filled {
        margin-top: 0;
      }
    }

    &.MuiInputLabel-sizeSmall {
      &.MuiFormLabel-filled {
        margin-top: 0;
      }
    }

    &.Mui-focused {
      font-size: 12px;
      background-color: #fff;
      margin-top: 0;
    }

    &.Mui-disabled {
      opacity: 0.4;
    }
  }

  .MuiInputBase-root {
    padding-top: 6px;
    padding-bottom: 6px;
    height: 32px;
    width: 100%;

    .MuiSelect-select {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;

      .dark & {
        color: #fff;
      }

      .MuiTypography-root {
        font-size: 14px;
        line-height: 20px;
        color: #000;
      }
      .placeholder {
        color: #bbb;
      }
    }
    .MuiSvgIcon-root {
      .dark & {
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        color: #ffffff;
      }
    }
    &.MuiInputBase-sizeLarge {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 40px;
    }

    &.MuiInputBase-sizeSmall {
      height: 28px;
    }
  }

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #a3a3a3;

      .dark & {
        border-color: #484848;
      }
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: #2196f3;
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: #b91c1c;
    }

    &.Mui-disabled {
      background-color: #ececec;
      opacity: 0.7;
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }
  }
  legend {
    width: 0;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    &.MuiInputLabel-shrink {
      position: absolute;
      left: 0;
      padding: 0 3px;
      background: #fff;
      font-size: 12px;
      line-height: 1;
      z-index: 10;

      .dark & {
        background: #1c1c1c;
        color: #b1b1b1;
      }

      &.MuiInputLabel-sizeLarge,
      &.MuiInputLabel-sizeMedium,
      &.MuiInputLabel-sizeSmall {
        top: 0;
      }
    }
    .required {
      &:after {
        content: '*';
        color: #a50034;
        font-size: 14px;
        margin-left: 3px;
      }
    }
  }
`;