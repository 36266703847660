import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import SearchField from 'components/component/BasicSearchField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import TextField from 'components/component/BasicTextField';
import CustomImage from 'components/component/BasicImage';
import Button from 'components/component/BasicButton';
import CustomBasicMenu from 'components/component/BasicMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dialog from 'components/component/BasicDialog';
import { RefreshIcon } from 'components/component/BasicIcon';
import CheckBox from 'components/component/BasicCheckBox';
import { useNavigate } from 'react-router-dom';
import Snackbar from 'components/component/BasicSnackbar';
import Tooltip from 'components/component/BasicTooltip';
import TextLink from 'components/component/BasicTextLink';
import {
  Box,
  Typography,
  Grid,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  TypeOption,
  OrganizeTitle,
  OrganizeScheduleTitle,
} from './data/InteractiveDockData';

import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import { BtnArrowRight } from 'components/component/BasicIcon';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import PopChannelDetail from "pages/Common/PopChannelDetail";
import PopSeriesDetail from "pages/Common/PopSeriesDetail";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});
  opacity: ${props => props.disabled ? 0.3 : 1};

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

const StyledTableCell = styled(TableCell)`
  ${({ disabled }) => disabled && `
    background-color: #808080 !important;
    pointer-events: none;
    opacity: 0.8;
  `}
`;

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

function InteractiveDockOrganize(props) {
  const { currentUser, darkMode, message } = props;
  const { providerList
        , selectedChannelId
        , channelList
        , filter
        } = message;

  const navigate = useNavigate();

  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Interactive Dock - Recommendation',
    normalMenu: ['Organize'],
  };

  // 화면에 보이는 필터 정보
  const [providerOptionList, setProviderOptionList] = useState([]);

   // 화면에 보이는 필터에서 선택한 값
  const [selectedType, setSelectedType] = useState('live');
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedContentId, setSelectedContentId] = useState('');
  const [selectedContentName, setSelectedContentName] = useState('');

  // 서버에서 받은 원본 데이터
  const [originalContentList, setOriginalContentList] = useState([]);
  // 화면에서 사용할 Content List
  const [displayContentList, setDisplayContentList] = useState([]);

  // 서버로 전달할 Related Content List
  const [savingRelatedContentList, setSavingRelatedContentList] = useState([]);
  // 화면에서 사용할 Related Content List
  const [displayRelatedContentList, setDisplayRelatedContentList] = useState([]);

  // 검색에 입력한 값
  const [searchTermInContentList, setSearchTermInContentList] = useState('');
  const [searchTermInRelatedContentList, setSearchTermInRelatedContentList] = useState('');

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [resultOrder, setResultOrder] = useState('asc');
  const [resultOrderBy, setResultOrderBy] = useState('');
  const [scheduledOrder, setScheduleOrder] = useState('asc');
  const [seheduleOrderBy, setScheduleOrderBy] = useState('');

  // content list 화면에서 선택한 것이 있는지 체크
  const [isAnyChecked, setAnyChecked] = useState(false);

  // related content list 화면에서 checkbox 전체 선택 유무 체크
  const [isAllChecked, setAllChecked] = useState(false);

  // drag&drop 과 top, bottom 기능 허락 유무 체크
  // order 순서가 오름차순으로 되어 있는 경우에만 이동이 가능함
  const [isAllowMove, setAllowMove] = useState(false);

  // 서버에서 가져온 content 데이터가 없는 경우 체크 (좌측 화면)
  const [isEmptyContentData, setEmptyContentData] = useState(false);

  // 서버에서 가져온 related content 데이터가 없는 경우 체크 (우측 화면)
  const [isEmptyRelatedContentData, setEmptyRelatedContentData] = useState(false);

  // Related Content list 항목 저장 유무 -> back 으로 이동했을 때 Testing 버튼 활성화
  const [isRelatedContentListSaved, setIsRelatedContentListSaved] = useState(false);

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // 토스트 팝업
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Detail Popup
  const [currentContentId, setCurrentContentId] = useState('');     // channel id or vod id
  const [channelDetailOpen, setChannelDetailOpen] = useState(false);
  const [vodDetailOpen, setVodDetailOpen] = useState(false);

  // message 받자 마자 서버에서 related content 정보를 가져옴
  useEffect(() => {
    if (message) {
      setIsRelatedContentListSaved(false);
      getRelatedContent();
    }
    // eslint-disable-next-line
  }, [message]);

  // Provider 필터 설정
  useEffect(() => {
    if (providerList) {
      const tempProvider = [
        {
          id: 'all',
          value: 'All',
          option: 'All',
          type: 'text'
        },
        ...providerList.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text',
        })),
      ];
      setProviderOptionList([...tempProvider]);
    }
  }, [providerList]);

  // Paging 처리
  useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newPaginatedData = displayContentList.slice(startIndex, endIndex);
    setPaginatedData([...newPaginatedData]);

    setAnyChecked(displayContentList.some(item => item.checked));
  }, [displayContentList, page, itemsPerPage]);

  // 좌측 content list 에서 결과 내 검색할 때
  useEffect(() => {
    if (originalContentList) {
      if (searchTermInContentList) {
        const excludeKeyList = ['contentOrder', 'contentType', 'imageUrl'];

        const filteredData = originalContentList.filter((item) => {
          return Object.entries(item)
            .filter(([key, value]) => !excludeKeyList.includes(key))
            .values(item)
            .some((value) => String(value).toLowerCase().includes(searchTermInContentList));
        });

        setDisplayContentList([...filteredData]);
      } else {
        setDisplayContentList([...originalContentList]);
      }
    }
  }, [searchTermInContentList, originalContentList]);

  // 우측 related content list 에서 결과 내 검색할 때
  useEffect(() => {
    if (searchTermInRelatedContentList) {
      const excludeKeyList = ['contentOrder', 'contentType', 'imageUrl'];

      const filteredData = savingRelatedContentList.filter((item) => {
        return Object.entries(item)
          .filter(([key, value]) => !excludeKeyList.includes(key))
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchTermInRelatedContentList));
      });
      setDisplayRelatedContentList([...filteredData]);
    } else {
      setDisplayRelatedContentList([...savingRelatedContentList]);
    }
    // eslint-disable-next-line
  }, [searchTermInRelatedContentList]);

  // 우측에 있는 related content list 정보가 변경되었을 때
  useEffect(() => {
    const checkedItem = displayRelatedContentList.filter(item => item.checked);

    if (checkedItem.length > 0 && checkedItem.length === displayRelatedContentList.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }

    setAllowMove(isSortedAscending(displayRelatedContentList));
    if (!searchTermInRelatedContentList) {
      setSavingRelatedContentList([...displayRelatedContentList]);
    }

    if (displayRelatedContentList && displayRelatedContentList.length === 0) {
      setEmptyRelatedContentData(true);
    } else {
      setEmptyRelatedContentData(false);
    }
    // eslint-disable-next-line
  }, [displayRelatedContentList]);

  const isSortedAscending = (data) => {
    return data.every((item, index) => {
      return index === 0 || item.contentOrder >= data[index - 1].contentOrder;
    });
  };

  // paging 처리
  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property, item) => {
    let isAsc = '';
    let data = [];

    if (item === 'displayContentList') {
      isAsc = resultOrderBy === property && resultOrder === 'asc';
      data = displayContentList;
      setResultOrder(isAsc ? 'desc' : 'asc');
      setResultOrderBy(property);
    } else {
      isAsc = seheduleOrderBy === property && scheduledOrder === 'asc';
      data = displayRelatedContentList;
      setScheduleOrder(isAsc ? 'desc' : 'asc');
      setScheduleOrderBy(property);
    }

    const sortedData = [...data].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    if (item === 'displayContentList') {
      setDisplayContentList([...sortedData]);
    } else {
      setDisplayRelatedContentList([...sortedData]);
    }
  };

  // 우측에 있는 related content item 이동 완료
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(displayRelatedContentList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    items.forEach((item, index) => {
      item.contentOrder = index + 1;
      item.checked = false;
    });
    setDisplayRelatedContentList([...items]);
  };

  // 상단에 있는 reset / search 버튼 처리
  const handleReset = () => {
    setSelectedType('live');
    setSelectedProvider('All');
    setSelectedContentId('');
    setSelectedContentName('');
  };

  const handleSearch = () => {
    getContentList();
  };

  const filterButtons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      autosize: true,
      onClick: handleReset,
    },
    {
      minWidth: '120px',
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      onClick: handleSearch
    },
  ];

  // 최상단에 있는 필터를 가지고 content list 정보를 가져올 때
  const getContentList = () => {
    setEmptyContentData(false);
    setPage(1);
    setDisplayContentList([]);

    const status = filter.selectedStatus === 'Saved for Testing' ? 'T' : 'P';

    const param = `contentType=${selectedType}&country=${filter.selectedCountry}&providerName=${selectedProvider}&status=${status}&contentId=${selectedContentId}&contentName=${selectedContentName}`;

    fetch(`${BASE_URL}/interactivedock/content-list?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const modifyContentList = body.data.contentList.map(item => ({
          ...item,
          checked: false
        }));
        setOriginalContentList([...modifyContentList]);

        if (modifyContentList && modifyContentList.length === 0) {
          setEmptyContentData(true);
        }
      } else {
        throw new Error(`[Interactive] content-list request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  // 우측 related content list 화면에 기존에 저장되어 있는 것을 가져올때
  const getRelatedContent = () => {
    setEmptyRelatedContentData(false);
    const status = filter.selectedStatus === 'Saved for Testing' ? 'T' : 'P';

    const param = `country=${filter.selectedCountry}&channelId=${selectedChannelId}&status=${status}`;

    fetch(`${BASE_URL}/interactivedock/related-content?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const modifyContentList = body.data.relatedContentList.map(item => ({
          ...item,
          checked: false
        }));

        setDisplayRelatedContentList([...modifyContentList]);

        if (modifyContentList && modifyContentList.length === 0) {
          setEmptyRelatedContentData(true);
        }
      } else {
        throw new Error(`[Interactive] related-content request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    }).finally(() => {});
  };

  // 상단에 있는 필터 값 변경 저장
  const handleSelectedType = (value) => {
    setSelectedType(value);
  };

  const handleSelectedProvider = (value) => {
    setSelectedProvider(value);
  };

  const handleSelectedContentId = (event) => {
    setSelectedContentId(event.target.value);
  };

  const handleSelectedContentName = (event) => {
    setSelectedContentName(event.target.value);
  };

  // 좌측 content list 상단에 있는 Select Ch input 버튼 선택시
  const handleSelectedContents = () => {
    const checkedItem = displayContentList.filter(item => item.checked);

    if (displayRelatedContentList.length + checkedItem.length > 15) {
      setSnackbarMessage('Up to 15 items can be added');
      setSnackbarOpen(true);
    } else {
      const filteredBuffer = checkedItem.map((item, index) => {
        return {
          ...item,
          contentOrder: displayRelatedContentList.length + index + 1,
          checked: false
        };
      });

      const result = [...displayRelatedContentList, ...filteredBuffer];
      setDisplayRelatedContentList([...result]);

      setDisplayContentList(prev =>
        prev.map(item => ({
          ...item,
          checked: false
        }))
      );
    }
  };

  // 좌측 content list 상단에 checkbox 화살표 선택
  const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];
  const handleCheckBoxTitle = (value) => {
    if (value === 'Select All Page') {
      setDisplayContentList(prev =>
        prev.map(item => ({
          ...item,
          checked: !displayRelatedContentList.map(item => item.contentId).includes(item.contentId) && item.providerName !== 'Pluto TV' ? true : false
        }))
      );
    } else {
      setDisplayContentList(prev =>
        prev.map(item => ({
          ...item,
          checked: paginatedData.map(item => item.contentId).includes(item.contentId) && item.providerName !== 'Pluto TV' &&
            !displayRelatedContentList.map(item => item.contentId).includes(item.contentId) ? true : false
        }))
      );
    }
  };

  // 상단에 있는 전체 check box 선택 toggle
  const handleAllChecked = (event, item) => {
    if (item === 'displayContentList') {
      setDisplayContentList((prev) =>
        prev.map((item) => ({
          ...item,
          checked: !displayRelatedContentList.map(item => item.contentId).includes(item.contentId) && item.providerName !== 'Pluto TV' ? event.target.checked : false
        }))
      );
    } else {
      setDisplayRelatedContentList((prev) =>
        prev.map((item) => ({
          ...item,
          checked: event.target.checked
        }))
      );
    }
  };

  // Check box 선택
  const handleCheckedItem = (event, contentId, item) => {
    if (item === 'displayContentList') {
      setDisplayContentList((prev) =>
        prev.map((item) =>
          item.contentId === contentId ? { ...item, checked: event.target.checked } : item
        )
      );
    } else {
      setDisplayRelatedContentList((prev) =>
        prev.map((item) =>
          item.contentId === contentId ? { ...item, checked: event.target.checked } : item
        )
      );
    }
  };

  // 우측 related content list 에서 item 이동
  const handleMove = (direction) => {
    if (displayRelatedContentList.length > 1) {
      const checkedItemList = displayRelatedContentList.filter(item => item.checked);
      const uncheckedItemList = displayRelatedContentList.filter(item => !item.checked);

      let changedRelatedContentList = [];

      if (direction === 'Top') {
        changedRelatedContentList = [...checkedItemList, ...uncheckedItemList];
      } else {
        changedRelatedContentList = [...uncheckedItemList, ...checkedItemList];
      }

      changedRelatedContentList.forEach((item, index) => {
        item.contentOrder = index + 1;
        item.checked = false
      });

      setDisplayRelatedContentList([...changedRelatedContentList]);
    }
  };

  // 우측 related content list 에서 item 삭제
  const handleDeleteItems = () => {
    const survivalItems = displayRelatedContentList.filter(item => !item.checked);

    if (survivalItems) {
      survivalItems.forEach((item, index) => {
        item.checked = false;
      });
      setDisplayRelatedContentList([...survivalItems]);
    } else {
      setDisplayRelatedContentList([]);
    }

    if (searchTermInRelatedContentList) {
      const deleteContentIds = displayRelatedContentList.filter(item => item.checked).map(item => item.contentId);

      const saveItems = savingRelatedContentList.filter(item => !deleteContentIds.includes(item.contentId));

      setSavingRelatedContentList([...saveItems]);
    }
  };

  // Related content list 에서 save
  const saveRelatedContent = (method) => {
    setIsRelatedContentListSaved(false);

    savingRelatedContentList.forEach((item, index) => {
      item.contentOrder = index + 1;
    });

    const changeChannelList = channelList.map(item => ({
      ...item,
      relatedContentCategoryType: item.relatedRule === 'cp' ? 'cp' :
        item.relatedRule === 'block' ? 'none' :
        method === 'allManualSave' || item.channelId === selectedChannelId ? 'manual' : item.relatedRule,
      relatedRule: item.relatedRule === 'cp' || item.relatedRule === 'block' ? item.relatedRule :
        method === 'allManualSave' || item.channelId === selectedChannelId ? 'manual' : item.relatedRule,
      relatedContentLists: method === 'allManualSave' || item.channelId === selectedChannelId ? savingRelatedContentList : [],
    }));

    const requestData = {
      user: currentUser.name,
      country: filter.selectedCountry,
      status: filter.selectedStatus === 'Saved for Testing' ? 'T' : 'P',
      saveChannelId: method === 'allManualSave' ? 'all' : selectedChannelId,
      channelLists: changeChannelList
    };

    fetch(`${BASE_URL}/interactivedock/saved-related-content`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(requestData)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSnackbarMessage('Successfully saved !');
        setSnackbarOpen(true);
        setIsRelatedContentListSaved(true);
      } else {
        throw new Error(`[Interactive] Save error`);
      }
    })
    .catch(error => {
      setSnackbarMessage(error.message.replace('Error: ', ''));
      setSnackbarOpen(true);
    });
  };

  // 우측 related content list 위에 있는 버튼 리스트
  const ScheduledButtons = [
    {
      index: 1,
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Top',
      isIcon: false,
      autosize: true,
      disabled: isAllowMove && displayRelatedContentList.some(item => item.checked) ? false : true,
      onClick: () => handleMove('Top'),
    },
    {
      index: 2,
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Bottom',
      isIcon: false,
      autosize: true,
      disabled: isAllowMove && displayRelatedContentList.some(item => item.checked) ? false : true,
      onClick: () => handleMove('Bottom'),
    },
    {
      index: 3,
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      autosize: true,
      disabled: displayRelatedContentList.some(item => item.checked) ? false : true,
      onClick: handleDeleteItems,
    },
    {
      index: 4,
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'All Manual Save',
      isIcon: false,
      autosize: true,
      disabled: savingRelatedContentList.length > 0 ? false : true,
      onClick: () => saveRelatedContent('allManualSave'),
    },
    {
      index: 5,
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Save',
      autosize: true,
      disabled: savingRelatedContentList.length > 0 ? false : true,
      onClick: () => saveRelatedContent('save'),
    },
  ];

  // content list 또는 related content list 에서 검색
  const handleSearchContent = (value, item) => {
    if (item === 'displayContentList') {
      setSearchTermInContentList(value.toLowerCase());
    } else {
      setSearchTermInRelatedContentList(value.toLowerCase());
    }
  };

  // 최상단에서 back
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/InteractiveDock', { replace: true, state:
      { move: true,
        targetComponent: 'InteractiveDock',
        message:
        {
          filter,
          isRelatedContentListSaved
        }
      }
    });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleItemClick = (event, contentId, type) => {
    event.preventDefault();
    setCurrentContentId(contentId);

    if (type === 'channel') {
      setVodDetailOpen(false);
      setChannelDetailOpen(true);
    } else {
      setChannelDetailOpen(false);
      setVodDetailOpen(true);
    }
  };

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area' sx={{ cursor: 'pointer' }}>
              <Link
                className='title link'
                to='#'
                onClick={handleBack}
              >
                Organize
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>
        <Box className='content-wrap' component='div'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='type'
                      size='large'
                      shrink={true}
                      label='Type'
                      required={false}
                      selectOptions={TypeOption}
                      disabled={false}
                      onChange={handleSelectedType}
                      selected={selectedType}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='cpName'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      required={false}
                      selectOptions={providerOptionList}
                      disabled={false}
                      onChange={handleSelectedProvider}
                      selected={selectedProvider}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                      id='contentId'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='ID'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                      value={selectedContentId}
                      onChange={handleSelectedContentId}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                      id='contentName'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Name'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                      value={selectedContentName}
                      onChange={handleSelectedContentName}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'>
                <CustomButtonGroup buttons={filterButtons} />
              </div>
            </Box>
          </Box>
          <Grid container columnSpacing={2.5} className='content-pack-area'>
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Result for Content</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {displayContentList.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label=''
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                        onChange={(event) => handleSearchContent(event.target.value, 'displayContentList')}
                      />
                    </Box>
                    <Box className='right-area'>
                      <Button
                        color={ isAnyChecked ? 'primary' : 'tertiary'}
                        type='box'
                        size='small'
                        label='Select Ch Input'
                        isIcon={true}
                        autosize
                        Icon={BtnArrowRight}
                        iconPos='right'
                        disabled={!isAnyChecked}
                        onClick={handleSelectedContents}
                      />
                    </Box>
                  </Box>
                  <Box className='content-inner-wrap'>
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table auto' aria-label='table' stickyHeader>
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {OrganizeTitle.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '18px' }}
                                        onChange={(event) => handleAllChecked(event, 'displayContentList')}
                                      />
                                      {index === 0 && (
                                        <CustomBasicMenu
                                          type='icon'
                                          title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                          options={selPageData}
                                          onClick={handleCheckBoxTitle}
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={resultOrderBy === column.id}
                                        direction={resultOrderBy === column.id ? resultOrder : 'desc'}
                                        onClick={() => handleRequestSort(column.id, 'displayContentList')}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody className='tbody'>
                          {isEmptyContentData ? (
                            <NoRecords>
                              No records to display
                            </NoRecords>
                          ) : (
                            paginatedData.map((td, index) => (
                              <TableRow className='tr' key={td.contentId + index}>
                                {OrganizeTitle.map((column, colIndex) => (
                                  <StyledTableCell
                                    key={column.contentId}
                                    className='td'
                                    align={column.align}
                                    disabled={td.providerName === 'Pluto TV' || savingRelatedContentList.some(item => item.contentId === td.contentId) ? true : false}
                                  >
                                    {colIndex === 0 ? (
                                      <CheckBox
                                        label=''
                                        value='value'
                                        defaultChecked={false}
                                        checked={td.checked}
                                        onChange={(event) => handleCheckedItem(event, td.contentId, 'displayContentList')}
                                      />
                                    ) : colIndex === 1 ? (
                                      td.providerName
                                    ) : colIndex === 2 ? (
                                      <TextLink
                                        href='#'
                                        text={td.contentId}
                                        onClick={(event) => handleItemClick(event, td.contentId, td.contentType === 'live' ? 'channel' : 'vod')}
                                      />
                                    ) : colIndex === 3 ? (
                                      td.contentName
                                    ) : colIndex === 4 ? (
                                      <CustomImage
                                        src={td.imageUrl}
                                        fit={false}
                                        alt=''
                                        wrapWidth='96px'
                                        wrapHeight='64px'
                                        rounded
                                      />
                                    ) : colIndex === 5 ? (
                                      td.genre
                                    ) : <></>}
                                  </StyledTableCell>
                                ))}
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                  </Box>
                  <Pagination count={Math.ceil(displayContentList.length / itemsPerPage)} id='pagination' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Scheduled Content List</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {displayRelatedContentList.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label=''
                        size='medium'
                        maxWidth='200px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                        onChange={(event) => handleSearchContent(event.target.value, 'displayRelatedContentList')}
                      />
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={ScheduledButtons} justifyContent='center' gap='4px' />
                    </Box>
                  </Box>
                  <Box className='content-inner-wrap no-page-number'>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table'>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table auto' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {OrganizeScheduleTitle.map((column, index) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                          <Box
                                            component='div'
                                            ml='29px'
                                            sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                          >
                                            <CheckBox
                                              label=''
                                              value='select-all'
                                              defaultChecked={false}
                                              inputProps={{
                                                'aria-label': 'select all',
                                              }}
                                              style={{ width: '18px' }}
                                              checked={isAllChecked ? true : false}
                                              onChange={(event) => handleAllChecked(event, 'displayRelatedContentList')}
                                            />
                                          </Box>
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={seheduleOrderBy === column.id}
                                              direction={seheduleOrderBy === column.id ? scheduledOrder : 'desc'}
                                              onClick={() => handleRequestSort(column.id, 'displayRelatedContentList')}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody className='tbody'>
                                {isEmptyRelatedContentData ? (
                                  <NoRecords>
                                    No records to display
                                  </NoRecords>
                                ) : (
                                  displayRelatedContentList.map((td, index) => (
                                    <Draggable
                                      key={td.id || index}
                                      draggableId={td.id || index.toString()}
                                      index={index}
                                      isDragDisabled={isAllowMove ? false : true}
                                    >
                                      {(provided) => (
                                        <TableRow
                                          className='tr'
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          {OrganizeScheduleTitle.map((column, colIndex) => (
                                            <TableCell
                                              key={column.id}
                                              className='td'
                                              align={column.align}
                                              sx={{ height: '88px' }}
                                            >
                                              {colIndex === 0 ? (
                                                <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                  <Tooltip
                                                    title={isAllowMove ? '' : 'You can only change the order when the sorting is in ascending order'}
                                                    placement='top'
                                                  >
                                                    <IconDraggable
                                                      className='icon-draggable'
                                                      disabled={isAllowMove ? false : true}
                                                    />
                                                  </Tooltip>

                                                  <CheckBox
                                                    label=''
                                                    value='value'
                                                    defaultChecked={false}
                                                    checked={td.checked}
                                                    onChange={(event) => handleCheckedItem(event, td.contentId, 'displayRelatedContentList')}
                                                  />
                                                </Box>
                                              ) : colIndex === 1 ? (
                                                td.contentOrder
                                              ) : colIndex === 2 ? (
                                                td.providerName
                                              ) : colIndex === 3 ? (
                                                <TextLink
                                                  href='#'
                                                  text={td.contentId}
                                                  onClick={(event) => handleItemClick(event, td.contentId, td.contentType === 'live' ? 'channel' : 'vod')}
                                                />
                                              ) : colIndex === 4 ? (
                                                td.contentName
                                              ) : colIndex === 5 ? (
                                                <CustomImage
                                                  src={td.imageUrl}
                                                  fit={false}
                                                  alt=''
                                                  wrapWidth='64px'
                                                  wrapHeight='64px'
                                                  rounded
                                                />
                                              ) : colIndex === 6 ? (
                                                td.genre
                                              ) :
                                                <></>
                                              }
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      )}
                                    </Draggable>
                                  ))
                                )}
                                {provided?.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {channelDetailOpen && <PopChannelDetail channelId={currentContentId} open={channelDetailOpen} close={() => setChannelDetailOpen(false)} />}
      {vodDetailOpen && <PopSeriesDetail vodId={currentContentId} countryCode={filter.selectedCountry} open={vodDetailOpen} close={() => setVodDetailOpen(false)} />}
      <Dialog
        open={isAlertOpen} // boolean 값으로 설정
        handleClose={handleAlertClose}
        closeX={false}
        size='small' // large, medium, small
        title={alertTitle} // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
      <Snackbar
        severity='warning'
        message={snackbarMessage}
        open={isSnackbarOpen}
        duration={1000}
        bottom={'8%'}
        darkMode={darkMode}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
}
export default InteractiveDockOrganize;
