import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Link } from '@mui/material';

import DownloadIcon from 'assets/images/icon/ic_download_24_outline.svg';
import { DownloadGrayIcon, DarkDownloadGrayIcon } from 'components/component/BasicIcon';
import Dialog from 'components/component/BasicDialog';
import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';

import { downloadZip } from './DownloadAll';
import QuillEditor from "components/component/QuillEditor";

const CustomDivider = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root': {
    borderColor: '#ddd',

    '.dark &': {
      borderColor: '#323232',
    },
  },
}));

const FileListArea = styled('div')({
  maxHeight: '85px',
  overflowY: 'auto',
});

const DownArea = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '4px',
});
const FileLink = styled(Link)({
  textDecoration: 'none',
  fontSize: '14px',
  color: '#2196F3',
  display: 'inline-flex',
  alignItems: 'center',

  '&:not(:last-of-type)': {
    marginBottom: '4px',
  },
  '&:hover': {
    textDecoration: 'underline',
  },
  '.dark &': {
    color: '#2196F3',
  },

  '&:before': {
    content: '""',
    width: '24px',
    height: '24px',
    marginRight: '12px',
    display: 'inline-block',
    backgroundImage: `url(${DownloadIcon})`,
  },
});

const TotalCount = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& .text': {
    fontSize: '1.2rem',
    fontWeight: 700,
    color: '#808080',
  },
  '& .number': {
    paddingLeft: '3px',
    fontSize: '1.2rem',
    fontWeight: 700,
    color: '#A50034',

    '&:before': {
      content: '"("',
      color: '#525252',
    },
    '&:after': {
      content: '")"',
      color: '#525252',
    },
  },
});

function PopNotification(props) {
  const { darkMode, currentNotification, isOpen, callbackClose } = props;

  // file list
  const [fileList, setFileList] = useState([]);

  // 토스트 팝업
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (currentNotification.hasAttachment === 'Y') {
      const fileNames = currentNotification.attachmentFileNames.split(',');
      const filePaths = currentNotification.attachmentFilePaths.split(',');
      const files = fileNames.map((fileName, index) => ({
        id: index,
        fileName: fileName.trim(),
        filePath: filePaths[index].trim(),
      }));
      setFileList(files);
    } else {
      setFileList([]);
    }
  }, [currentNotification]);

  const handleClose = () => {
    callbackClose(false);
  };

  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose,
    }
  ];

  const handleDownloadAll = async () => {
    const response = await downloadZip(fileList);

    if (response !== 'SUCCESS') {
      setSnackbarMessage(response);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className='component-view'>
      <Dialog
        open={isOpen}
        title='Notice' // Popup Title
        noticeBgBox={true}
        noticeBoxTitle={`[${currentNotification.category}] ${currentNotification.title}`}
        noticeDate={currentNotification.crtDate}
        handleClose={handleClose}
        closeX={true}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <div className='list-container'>
              <QuillEditor
                htmlContent={currentNotification.content}
                readOnly={true}
              />
            </div>
            <CustomDivider className='divider' sx={{ margin: '24px 0 12px' }} />

            {/* File Down */}
            <div className='download-container'>
              <DownArea className='down-area'>
                <TotalCount className='total-count'>
                  <span className='text'>Total</span>
                  <span className='number'>{fileList.length}</span>
                </TotalCount>
                <Button
                  color=''
                  type='icon-text'
                  size=''
                  label='Download All'
                  isIcon={true}
                  Icon={darkMode ? DarkDownloadGrayIcon : DownloadGrayIcon}
                  iconPos='left'
                  onClick={handleDownloadAll}
                  disabled={fileList.length === 0}
                />
              </DownArea>
              <FileListArea className='file-area'>
                {fileList.map((item) => (
                  <FileLink href={item.filePath} key={item.id} className='file-item'>
                    {item.fileName}
                  </FileLink>
                ))}
              </FileListArea>
            </div>
          </>
        }
        buttons={dialogButtons}
      />
      <Snackbar
        severity='warning'
        message={snackbarMessage}
        open={isSnackbarOpen}
        duration={1000}
        bottom={'8%'}
        darkMode={darkMode}
        onClose={handleSnackbarClose}
      />
    </div>
  );
}

export default PopNotification;
