// src/data.js
// select option
export const displayOption = [
  { id: 'option1', value: 'mandatory', option: 'Mandatory', type: 'text' },
  { id: 'option2', value: 'optional', option: 'optional', type: 'text' },
  { id: 'option3', value: 'notice', option: 'notice', type: 'text' },
];
export const devicePlatform = [
  { id: 'devicePlatform1', type: 'tv', value: 'webOS' },
  { id: 'devicePlatform2', type: 'auto', value: 'webOS' },
  { id: 'devicePlatform3', type: 'mobile', value: 'Android' },
  { id: 'devicePlatform4', type: 'mobile', value: 'IOS' },
  { id: 'devicePlatform5', type: 'pc', value: 'pc_platform' },
  { id: 'devicePlatform6', type: 'pc', value: 'pc_platform2' },
];
// default Policy
export const defaultPolicy = {
  // COMMON
  policyIdx: -1,
  policyId: '',
  policyType: '',
  policyVersion: '1.0.0',
  definedVersion: '1.0.0',
  policyMajorVersion: 1,
  policyMinorVersion: 0,
  policyPatchVersion: 0,
  deviceType: '',
  policyComment: '',
  countryCode: '',
  ricCode: '',
  contentLanguageCodeList: [],
  devicePlatformCodeList: [],
  contentList: [],
  multipleDisplay: [],
  // EDIT
  updateVersion: '',
  devicePlatform: '',
  displayOption: '',
  displayOrder: 1,
  enableFlag: '',
  publishedFlag: '',
  releaseFlag: 'N',
  publishedVersion: '',
  countries: '',
  crtUsrId: '',
  crtDate: '',
  countryCodeList: [],
  countryList: [],
  devicePlatformList: [],
};

export const PolicyTableHeader = [
  {
    id: 'termsDeviceTypeTh',
    label: 'Device Type',
    align: 'left',
    width: '7%',
    checkbox: false,
    sortable: true,
    labelId: 'deviceType',
  },
  {
    id: 'termsPolicyTypeTh',
    label: 'Terms Type',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
    labelId: 'policyType',
  },
  {
    id: 'termsCountryTh',
    label: 'Country',
    align: 'left',
    width: '7%',
    checkbox: false,
    sortable: true,
    labelId: 'countries',
  },
  {
    id: 'termsDevicePlatformTh',
    label: 'Platform',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
    labelId: 'devicePlatforms',
  },
  {
    id: 'termsDisplayOptionTh',
    label: 'Display',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
    labelId: 'displayOption',
  },
  {
    id: 'termsPublishedVersionTh',
    label: 'Version',
    align: 'left',
    width: '7%',
    checkbox: false,
    sortable: true,
    labelId: 'policyVersion',
  },
  {
    id: 'termsEnableFlagTh',
    label: 'Enabled',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: false,
    labelId: 'enableFlag',
  },
  {
    id: 'termsPublishedFlagTh',
    label: 'Published',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
    labelId: 'publishedFlag',
  },
  {
    id: 'termsPolicyCommentTh',
    label: 'Comment',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
    labelId: 'policyComment',
  },
  {
    id: 'termsLastChgDateTh',
    label: 'Change Date',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
    labelId: 'lastChgDate',
  },
  {
    id: 'termsLastChgUsrIdTh',
    label: 'Change ID',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
    labelId: 'lastChgUsrId',
  },
  {
    id: 'termsActionTh',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: false,
    labelId: 'Action',
  },
];

export const HistoryTableHeader = [
  {
    id: 'versionTh',
    label: 'Version',
    labelId: 'policyVersion',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'countryTh',
    label: 'Country',
    labelId: 'countries',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentSizeTh',
    label: 'Content Size',
    labelId: 'contentSize',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'actionTh',
    label: 'Action',
    labelId: 'userAction',
    align: 'left',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'publishedTh',
    label: 'Published',
    labelId: 'publishedFlag',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'changeIdTh',
    label: 'Change ID',
    labelId: 'lastChgUsrId',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'lastChgDateTh',
    label: 'Last Chg Date',
    labelId: 'lastChgDate',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

// TODO DELETE

export const dtypeOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'device-1', value: 'device', option: 'device', type: 'text' },
  { id: 'device-2', value: 'device', option: 'device', type: 'text' },
];

export const CountryData = [
  { id: 'country-1', value: 'Us', option: 'Us', type: 'text' },
  { id: 'country-2', value: 'Italy', option: 'Italy', type: 'text' },
  { id: 'country-3', value: 'Ger', option: 'Ger', type: 'text' },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const DeviceTypeOption = [
  { id: 'PC', value: 'PC', option: 'PC', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];
export const DeviceTypeMobileOption = [
  { id: 'Mobile', value: 'Mobile', option: 'Mobile', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
];
export const PlatformOption = [
  { id: 'pl1', value: 'pc-platform', option: 'pc-platform', type: 'text' },
  { id: 'pl12', value: 'pc-2', option: 'platform-2', type: 'text' },
  { id: 'pl13', value: 'pc-3', option: 'platform-3', type: 'text' },
];
export const PlatformMobileOption = [
  { id: 'mpf-1', value: 'Android', option: 'Android', type: 'text' },
  { id: 'mpf-2', value: 'm-2', option: 'platform-2', type: 'text' },
  { id: 'mpf-3', value: 'm-3', option: 'platform-3', type: 'text' },
];
export const viewTermsOption = [
  { id: 'EN', value: 'EN', option: 'EN', type: 'text' },
  { id: 'Ko', value: 'Ko', option: 'Ko', type: 'text' },
];
export const displayOrderOption = [
  { id: 'order', value: 'Mandatory', option: 'Mandatory', type: 'text' },
  { id: 'order-2', value: 'option2', option: 'option2', type: 'text' },
];
export const countryOption = [
  { id: 'c-1', value: 'us', option: 'US(en)', type: 'text' },
  { id: 'c-2', value: 'ko', option: 'ko', type: 'text' },
];
export const LanguageOption = [
  { id: 'en-ss', value: 'en', option: 'en', type: 'text' },
  { id: 'order-2', value: 'option2', option: 'option2', type: 'text' },
];
export const SelOption = [
  { id: 'mandatory-ss', value: 'mandatory', option: 'mandatory', type: 'text' },
  { id: 'optional-2', value: 'optional', option: 'optional', type: 'text' },
];

export const TermsofUseTh = [
  {
    id: 'TermsofUseTh',
    label: 'Device Type',
    align: 'left',
    width: '7%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsofUseTh2',
    label: 'Terms Type',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsofUseTh3',
    label: 'Country',
    align: 'left',
    width: '7%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsofUseTh4',
    label: 'Platform',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsofUseTh5',
    label: 'Display',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsofUseTh6',
    label: 'Version',
    align: 'left',
    width: '7%',
    checkbox: false,
    sortable: true,
    test: 'data6',
  },
  {
    id: 'TermsofUseTh7',
    label: 'Enabled',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'TermsofUseTh8',
    label: 'Published',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsofUseTh9',
    label: 'Comment',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsofUseTh10',
    label: 'Change Date',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsofUseTh11',
    label: 'Change ID',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsofUseTh12',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const TermsofUseTd = [
  {
    id: 'MetaChannelTd-01',
    data1: 'pc',
    data2: 'Terms of Use',
    data3: 'US',
    data4: 'pc_platform',
    data5: 'mandatory',
    data6: '1.0.0',
    data7: 'Off',
    data8: 'Y(Released : 1.0.0)',
    data9: 'Terms of Use',
    data10: '07/22/2024 15:46:32',
    data11: 'admin',
    data12: '',
  },
  {
    id: 'MetaChannelTd-01',
    data1: 'pc',
    data2: 'Terms of Use',
    data3: 'US',
    data4: 'pc_platform',
    data5: 'mandatory',
    data6: '1.0.0',
    data7: 'Off',
    data8: 'Y(Released : 1.0.0)',
    data9: 'Terms of Use',
    data10: '07/22/2024 15:46:32',
    data11: 'admin',
    data12: '',
  },
  {
    id: 'MetaChannelTd-01',
    data1: 'pc',
    data2: 'Terms of Use',
    data3: 'US',
    data4: 'pc_platform',
    data5: 'mandatory',
    data6: '1.0.0',
    data7: 'Off',
    data8: 'Y(Released : 1.0.0)',
    data9: 'Terms of Use',
    data10: '07/22/2024 15:46:32',
    data11: 'admin',
    data12: '',
  },
];

export const TermsofUseMobileTh = [
  {
    id: 'TermsMobileTh',
    label: 'Device Type',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsMobileTh2',
    label: 'Country',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsMobileTh3',
    label: 'Version',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsMobileTh4',
    label: 'Enabled',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsMobileTh5',
    label: 'Comment',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsMobileTh6',
    label: 'Last Change Date',
    align: 'left',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'TermsMobileTh7',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const TermsofUseMobileTd = [
  {
    id: 'TermsofUseMobileTd-01',
    data1: 'mobile',
    data2: 'DE',
    data3: '20221124-045705',
    data4: '',
    data5: 'aos de 20230101',
    data6: '07/22/2024 15:46:32',
    data7: '',
  },
  {
    id: 'TermsofUseMobileTd-02',
    data1: 'mobile',
    data2: 'DE',
    data3: '20221124-045705',
    data4: '',
    data5: 'aos de 20230101',
    data6: '07/22/2024 15:46:32',
    data7: '',
  },
  {
    id: 'TermsofUseMobileTd-03',
    data1: 'mobile',
    data2: 'DE',
    data3: '20221124-045705',
    data4: '',
    data5: 'aos de 20230101',
    data6: '07/22/2024 15:46:32',
    data7: '',
  },
];

export const TermsHistoryTh = [
  {
    id: 'TermsHistoryTh',
    label: 'Version',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsHistoryTh2',
    label: 'Country',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsHistoryTh3',
    label: 'Content Size',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsHistoryTh4',
    label: 'Action',
    align: 'left',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsHistoryTh5',
    label: 'Published',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TermsHistoryTh6',
    label: 'Change ID',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'TermsHistoryTh7',
    label: 'Last Chg Date',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const TermsHistoryTd = [
  {
    id: 'TermsHistoryTd-01',
    data01: '1.0.0',
    data02: 'US',
    data03: '2',
    data04: 'update',
    data05: 'Y (Released: 1.0.0)',
    data06: 'admin',
    data07: '2024-04-16 23:37:29',
  },
  {
    id: 'TermsHistoryTd-02',
    data01: '1.0.0',
    data02: 'US',
    data03: '2',
    data04: 'update',
    data05: 'N',
    data06: 'admin',
    data07: '2024-04-16 23:37:29',
  },
  {
    id: 'TermsHistoryTd-01',
    data01: '1.0.0',
    data02: 'US',
    data03: '2',
    data04: 'update',
    data05: 'Y (Released: 1.0.0)',
    data06: 'admin',
    data07: '2024-04-16 23:37:29',
  },
  {
    id: 'TermsHistoryTd-02',
    data01: '1.0.0',
    data02: 'US',
    data03: '2',
    data04: 'update',
    data05: 'Y (Released: 1.0.0)',
    data06: 'admin',
    data07: '2024-04-16 23:37:29',
  },
  {
    id: 'TermsHistoryTd-01',
    data01: '1.0.0',
    data02: 'US',
    data03: '2',
    data04: 'update',
    data05: 'N',
    data06: 'admin',
    data07: '2024-04-16 23:37:29',
  },
  {
    id: 'TermsHistoryTd-02',
    data01: '1.0.0',
    data02: 'US',
    data03: '2',
    data04: 'update',
    data05: 'N',
    data06: 'admin',
    data07: '2024-04-16 23:37:29',
  },
  {
    id: 'TermsHistoryTd-01',
    data01: '1.0.0',
    data02: 'US',
    data03: '2',
    data04: 'update',
    data05: 'N',
    data06: 'admin',
    data07: '2024-04-16 23:37:29',
  },
  {
    id: 'TermsHistoryTd-02',
    data01: '4.0.0',
    data02: 'US',
    data03: '2',
    data04: 'update',
    data05: 'N',
    data06: 'admin',
    data07: '2024-04-16 23:37:29',
  },
];
