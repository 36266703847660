import React, { useMemo, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { Box, Grid, Typography } from '@mui/material';
import {
  validityOption
} from 'pages/Organization/ANTestService/data/ANTestServiceResultdata';
import { updateValidityReason } from './api/restApi';

// 메인 컨텐츠
function PopEditValidReason(props) {
  const { onClose, id, sid, handelSetShowSnackBar, validity, reasonData, setValidity, setReason, type } = props;
  const [validityReason, setValidityReason] = useState({
    validity: validity,
    reason: reasonData,
  });

  const handleClose = () => {
    onClose();
  };

  const onChangeReason = (value) => {
    setValidityReason((prevState) => ({
      ...prevState,
      reason: value,
    }));
  }

  const onChangeValid = (value) => {
    setValidityReason((prevState) => ({
      ...prevState,
      validity: value,
    }));

  }

  const handleUpdate = async () => {
    if (!validityReason.validity) {
      alert('Please Select the Validity');
      return;
    } else if (validityReason.reason === null || validityReason.reason.trim().length === 0) {
      alert('Please Enter the reason');
      return;
    } else if (validityReason.reason.length > 250) {
      alert('The Reason should be within 250 characters');
      return;
    }
    const res = await updateValidityReason(id, sid, validityReason, type);
    handelSetShowSnackBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    setReason(validityReason.reason);
    setValidity(validityReason.validity);
    onClose();
  }

  const dialogButtons = useMemo(() => {
    return [
      {
        width: '150px',
        size: 'large',
        label: 'Cancel',
        type: 'outline',
        color: 'tertiary',
        onClick: handleClose,
        disabled: false,
      },
      {
        width: '150px',
        size: 'large',
        label: 'Update',
        type: 'box',
        color: 'primary',
        onClick: handleUpdate,
        disabled: false,
      },
    ];
    // eslint-disable-next-line
  }, [validityReason]);

  const fields = [
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <Box pt={1}>
          <SelectBox
            id='edit-validity'
            fullWidth
            label='Validity'
            size='large'
            required={true}
            selectOptions={validityOption}
            onChange={onChangeValid}
            selected={validityReason.validity}
          />
        </Box>
      ),
    },
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <TextField
            type='outlined'
            size='large'
            placeholder=''
            defaultValue=''
            label='Reason'
            autoComplete='off'
            InputLabelProps={{ shrink: true }}
            value={validityReason.reason ? validityReason.reason : ''}
            onChange={(e) => onChangeReason(e.target.value)}
          />
        </>
      ),
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Update Valid/Reason' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <Box className='layout-label-container'>
            <Box className='detail-list-container'>
              <Grid container columnSpacing={3}>
                {fields.map(({ xs, label, required, value, isLabel, isBorder }, index) => (
                  <Grid item xs={xs} className='list-wrap' key={index}>
                    <Box mb={1} className='info-value-wrap'>
                      {isLabel && (
                        <Typography component='span' className={`label ${required ? 'required' : ''}`}>
                          <span className='text'>{label}</span>
                        </Typography>
                      )}
                      <div className={`value ${isBorder ? 'border' : ''}`}>{value}</div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopEditValidReason;
