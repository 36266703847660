import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Snackbar from 'components/component/BasicSnackbar';
import SelectBox from 'components/component/BasicSelectBox';
import Tooltip from 'components/component/BasicTooltip';
import Dialog from 'components/component/BasicDialog';
import { ArrowRight24 } from 'components/component/BasicIcon';
import NotificationList from 'pages/Main/Support/NotificationList';

import { Button, Box, Grid, Link, List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/system';
import dayjs from 'dayjs';

import 'assets/scss/page/main.scss';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

const CardContainer = styled('div')({
  display: 'block',
});
const BoxContainer = styled('div')({
  paddingRight: '.3rem',
});

const LogWrap = ({ name, api, file, message, startTime, endTime }) => (
  <Box className='log-wrap'>
    <Box className='name-area'>
      <Typography className='log-name'>{name}</Typography>
    </Box>
    <Box className='log-area'>
      <Box mb={1.5}>
        <Typography component={'p'} className='log-item'>
          <span className='label-text'>API : </span>
          <span className='value-text'>{api}</span>
        </Typography>
        <Typography component={'p'} className='log-item'>
          <span className='label-text'>File : </span>
          <span className='value-text'>{file}</span>
        </Typography>
      </Box>
      {message && (
        <Box mb={1.5}>
          <Typography component={'p'} className='log-item'>
            <span className='label-text'>Message : </span>
            <Tooltip
              title={message.length > 90 ? message : ''}
              placement='top'
              width='500px'
            >
              <span className='value-text'>{message.length > 90 ? message.substring(0, 90) + '...' : message}</span>
            </Tooltip>
          </Typography>
        </Box>
      )}
      <Box>
        <Typography component={'p'} className='log-item'>
          <span className='value-text'>Start time : </span>
          <span className='value-text'>{startTime}</span>
        </Typography>
        <Typography component={'p'} className='log-item'>
          <span className='value-text'>End time : </span>
          <span className='value-text'>{endTime}</span>
        </Typography>
      </Box>
    </Box>
  </Box>
);

function PartnerMain(props) {
  const { currentUser, darkMode, recentlyUsedMenu, providerAll } = props;
  const navigate = useNavigate();

  const [partnerUsedMenu, setParterUsedMenu] = useState([]);

  // Api call log data
  const [logData, setLogData] = useState([]);

  // Provider Option Filter
  const [providerOption, setProviderOption] = useState([]);

  // Selected Provider
  const [selectedProvider, setSelectedProvider] = useState('');

  // Statistics Data
  const [statisticsData, setStatisticsData] = useState({});

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // CP 계정으로 로그인 시 CP 이름을 저장하고 그렇지 않으면 All로 설정
  const [currentProviderName, setCurrentProviderName] = useState('All');

  useEffect(() => {
    if (currentUser && (currentUser.role === 'ROLE_CP' || currentUser.role === 'ROLE_STUDIO')) {
      setCurrentProviderName(currentUser.provider.providerName);
      getApiCallLog(currentUser.provider.providerName);
    } else {
      setCurrentProviderName('All');
      getApiCallLog('All');
    }
  }, [currentUser]);

  useEffect(() => {
    if (providerAll && providerAll.length > 0) {
      const providerOption = providerAll.map((provider) => {
        return { id: provider.providerName, value: provider.providerName, option: provider.providerName, type: 'text' };
      });

      setSelectedProvider(providerOption[0].value);
      setProviderOption(providerOption.sort());
    }
  }, [providerAll]);

  useEffect(() => {
    if (selectedProvider) {
      getStatisticsMain();
    };
    // eslint-disable-next-line
  }, [selectedProvider]);

  useEffect(() => {
    if (recentlyUsedMenu && recentlyUsedMenu.partnerMenu) {
      setParterUsedMenu(recentlyUsedMenu.partnerMenu);
    }
  }, [recentlyUsedMenu]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleMoveToOtherPage = (event, moveToPage, targetComponent, selectedId) => {
    event.preventDefault();
    navigate(moveToPage, { replace: true, state: { move: true, targetComponent: targetComponent, selectedId: selectedId } });
  };

  const getApiCallLog = (provider) => {
    const providerName = provider;  // 'All' or CP name
    const api = 'All';
    const status = 'fail';
    const limit = 10;

    const param = `providerName=${providerName}&api=${api}&status=${status}&limit=${limit}`;

    fetch(`${BASE_URL}/apicalllogs/logs?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setLogData(body.data.apiCallLogList);
      } else {
        throw new Error(`apicalllogs/logs request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const getStatisticsMain = () => {
    const param = `cpName=${selectedProvider}`;

    fetch(`${BASE_URL}/statistics/main?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setStatisticsData(body.data);
      } else {
        throw new Error(`statistics/main request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container partner-main' component='main' sx={{ flexGrow: 1 }}>
        <Box className='main-wrap' component='div'>
          <Grid container spacing={2.5} columnSpacing={{ md: 2.5 }} className='activity-box left-container'>
            <Grid item sm={12}>
              <Box className='white-box white-box-wrap statistic'>
                <Box className='title-wrap'>
                  <Box className='box-title-area'>
                    <Typography variant='h2' className='bot-title-text'>
                      Statistics
                    </Typography>
                  </Box>
                  <Link
                    href='#'
                    underline='none'
                    className='title-link'
                    onClick={(event) => handleMoveToOtherPage(event, '/Channel', '', 201)}  // Channel id
                  >
                    <ArrowRight24 />
                  </Link>
                </Box>
                <CardContainer className='card-container'>
                  <BoxContainer className='box-container'>
                    <Box className='statistic-container'>
                      <Box className='statistic-area'>
                        {currentProviderName === 'All' && <Box className='area-top'>
                          <Box className='top-right'>
                            <Box className='field-area'>
                              <Box className='field-item'>
                                <SelectBox
                                  id='Cp014'
                                  label='CP Name'
                                  fullWidth
                                  size='medium'
                                  width='150px'
                                  require
                                  selectOptions={providerOption}
                                  disabled={false}
                                  required={false}
                                  shrink
                                  selected={selectedProvider}
                                  onChange={setSelectedProvider}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>}
                        <Box className='area-middle'>
                          <Box className='data-container'>
                            <Box className='data-box'>
                              <Typography className='label'>Channels</Typography>
                              <Typography className='value'>{statisticsData.channelCount}</Typography>
                            </Box>
                            <Box className='data-box'>
                              <Typography className='label'>VODs</Typography>
                              <Typography className='value'>{statisticsData.vodCount}</Typography>
                            </Box>
                            <Box className='data-box'>
                              <Typography className='label'>Launch countries</Typography>
                              <Typography className='value'>{statisticsData.launchedCountries}</Typography>
                            </Box>
                          </Box>
                          <Box className='data-container merge'>
                            <Box className='box-top'>
                              <Typography className='date'>
                                Date : {dayjs(statisticsData.date).startOf('month').format('YYYY-MM-DD')} ~
                                       {dayjs(statisticsData.date).endOf('month').format('YYYY-MM-DD')}
                              </Typography>
                            </Box>
                            <Box className='box-wrap'>
                              <Box className='data-box'>
                                <Box className='box-inner'>
                                  <Typography className='label'>Unique Device</Typography>
                                  <Typography className='value'>{statisticsData.uniqueDevice}</Typography>
                                  <Box className={statisticsData.uniqueDeviceChangeRate > 0 ? 'number-box up' :
                                                  statisticsData.uniqueDeviceChangeRate < 0 ? 'number-box down' : 'number-box same'}>
                                    <span className='text'>{statisticsData.uniqueDeviceChangeRate}</span>
                                  </Box>
                                </Box>
                              </Box>
                              <Box className='data-box'>
                                <Box className='box-inner'>
                                  <Typography className='label'>Total Duration</Typography>
                                  <Typography className='value'>{statisticsData.totalDuration}</Typography>
                                  <Box className={statisticsData.totalDurationChangeRate > 0 ? 'number-box up' :
                                                  statisticsData.totalDurationChangeRate < 0 ? 'number-box down' : 'number-box same'}>
                                    <span className='text'>{statisticsData.totalDurationChangeRate}</span>
                                  </Box>
                                </Box>
                              </Box>
                              <Box className='data-box'>
                                <Box className='box-inner'>
                                  <Typography className='label'>Play</Typography>
                                  <Typography className='value'>{statisticsData.play}</Typography>
                                  <Box className={statisticsData.playChangeRate > 0 ? 'number-box up' :
                                                  statisticsData.playChangeRate < 0 ? 'number-box down' : 'number-box same'}>
                                    <span className='text'>{statisticsData.playChangeRate}</span>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </BoxContainer>
                </CardContainer>
              </Box>
            </Grid>

            {/* Recently Used Menu */}
            <Grid item sm={12}>
              <Box className='white-box white-box-wrap recently'>
                <Box className='title-wrap'>
                  <Box className='box-title-area'>
                    <Typography variant='h2' className='box-title'>
                      Recently Used Menu
                    </Typography>
                  </Box>
                </Box>
                <CardContainer className='card-container'>
                  <BoxContainer className='box-container'>
                    <List className='recently-used'>
                      {partnerUsedMenu && partnerUsedMenu.map((recentData) => (
                        <ListItem key={recentData.id} className='list-item'>
                          <Box className='item-wrap'>
                            <Box component='div' className='text-left-wrap'>
                              <Typography className='channelTitle' component='div' variant='body1'>
                                {recentData.title}
                              </Typography>
                              <Typography className='channelBreadCrumb' component='div' variant='body1'>
                                {recentData.breadCrumb}
                              </Typography>
                            </Box>
                            <Button
                              href='#'
                              variant=''
                              className='link-arrow'
                              onClick={(event) => handleMoveToOtherPage(event, recentData.path, '', recentData.menuId)}
                            >
                              <ArrowRight24 />
                            </Button>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </BoxContainer>
                </CardContainer>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2.5} direction='row' className='right-container'>
            {/* API Call Fail Log */}
            <Grid item sm={12}>
              <Box className='white-box white-box-wrap fail-log'>
                <Box className='title-wrap'>
                  <Box className='box-title-area'>
                    <Typography variant='h2' className='box-title'>
                      API Call Fail Log
                    </Typography>
                    <Link
                      href='#'
                      underline='none'
                      className='title-link'
                      onClick={(event) => handleMoveToOtherPage(event, '/APICallLogs', '', 301)} // Api Call Logs Id
                    >
                      <ArrowRight24 />
                    </Link>
                  </Box>
                </Box>
                <CardContainer className='card-container'>
                  <BoxContainer className='box-container'>
                    <Box className='fail-log-container'>
                      {logData.map((log, index) => (
                        <LogWrap
                          key={index}
                          name={log.providerName}
                          api={log.apiName}
                          file={log.file}
                          message={log.message}
                          startTime={log.crtDate.replace('T', ' ').replace('Z','')}
                          endTime={log.lastChgDate.replace('T', ' ').replace('Z','')}
                        />
                      ))}
                    </Box>
                  </BoxContainer>
                </CardContainer>
              </Box>
            </Grid>

            {/* Support */}
            <Grid item sm={12}>
              <Box className='white-box white-box-wrap support'>
                <Box className='title-wrap'>
                  <Box className='box-title-area'>
                    <Typography variant='h2' className='bot-title-text'>
                      Support
                    </Typography>
                  </Box>
                  <Link
                    href='#'
                    underline='none'
                    className='title-link'
                    onClick={(event) => handleMoveToOtherPage(event, '/', 'Notification', 10000)} // Lnb 선택할 필요가 없어서 10000 으로 설정
                  >
                    <ArrowRight24 />
                  </Link>
                </Box>
                <NotificationList channel={'Partner'}/>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
      <Dialog
        open={isAlertOpen}
        handleClose={handleAlertClose}
        closeX={false}
        size='small'
        title={alertTitle}
        contentTitle={false}
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </Box>
  );
}

PartnerMain.propTypes = {
  providerAll: PropTypes.array.isRequired
};
const mapStateToProps = state => ({
  providerAll: state.common.provider.entire
});

export default connect(mapStateToProps)(PartnerMain);
