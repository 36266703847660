import React, { useState } from 'react';
import Dialog from 'components/component/BasicDialog';

import TextField from 'components/component/BasicTextField';
import { Grid, Box } from '@mui/material';
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";

// 메인 컨텐츠
function PopAccountChangePassword(props) {
  const { id, open, close } = props;
  const [inputPassword, setInputPassword] = useState('');
  const [reEnterInputPassword, setReEnterInputPassword] = useState('');

  const handleClose = () => {
    setInputPassword('');
    setReEnterInputPassword('');
    close();
  };

  const changePassword = () => {
    let param = `id=${id}&password=${inputPassword}`;
    fetch(`${BASE_URL}/account/password?${param}`, {
      method: 'PATCH',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        handleClose();
      } else {
      }
    })
  };

  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      autosize: true,
      disabled: inputPassword.length < 6 || reEnterInputPassword !== inputPassword,
      onClick: changePassword
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={open}
        closeX={true}
        title='Change Password' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <Grid container>
              <Grid item xs={12}>
                <Box component='div' className='field-box'>
                  <TextField
                    id='pwd'
                    type='outlined'
                    size='large'
                    placeholder='Password'
                    defaultValue=''
                    label='Password'
                    autoComplete='off'
                    required={true}
                    helperText='At least 6 characters'
                    InputLabelProps={{ shrink: true }}
                    value={inputPassword}
                    onChange={e => {
                      setInputPassword(e.target.value);
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Box component='div' className='field-box'>
                  <TextField
                    id='pwd-re'
                    type='outlined'
                    size='large'
                    placeholder='Re-enter Password'
                    defaultValue=''
                    label='Re-enter Password'
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    error={reEnterInputPassword !== inputPassword}
                    value={reEnterInputPassword}
                    onChange={e => {
                      setReEnterInputPassword(e.target.value);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopAccountChangePassword;
