// src/data.js
import imgDummy01 from 'assets/images/img_dummy_04.png';
export const CountryData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Eu', value: 'Eu', option: 'Eu', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const SliderData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Slider-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'Slider-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];

export const contentTypeOption = [
  { id: 'contentType1', value: 'Live', option: 'Live', type: 'text' },
  { id: 'contentType2', value: 'Live2', option: 'Live2', type: 'text' },
  { id: 'contentType3', value: 'Live3', option: 'Live3', type: 'text' },
];
export const cpNameOption = [
  { id: 'cpName-all', value: 'All', option: 'All', type: 'text' },
  { id: 'cpName-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'cpName-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];

export const selectTextOption = [
  { id: 'EN', value: 'EN', option: 'EN', type: 'text' },
  { id: 'selectText-1', value: 'Option-1', option: 'Slider-1', type: 'text' },
  { id: 'selectText-2', value: 'Option-2', option: 'Slider-2', type: 'text' },
];
export const selectTextOption2 = [
  { id: 'DA', value: 'DA', option: 'DA', type: 'text' },
  { id: 'selectText-1', value: 'Option-1', option: 'Slider-1', type: 'text' },
  { id: 'selectText-2', value: 'Option-2', option: 'Slider-2', type: 'text' },
];

export const ContentResultTh = [
  {
    id: 'cp',
    align: 'left',
    label: 'CP Name',
    width: '20%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'id',
    align: 'left',
    label: 'Content ID',
    width: '24%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'name',
    align: 'left',
    label: 'Content Name',
    width: '24%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'image',
    align: 'left',
    label: 'Image',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'genre',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const ContentResultTd = [
  {
    id: 'cResult-1',
    data01: 'AMG',
    data02: 'aliennationbydust',
    data03: 'The Wedding Video',
    data04: imgDummy01,
    data05: 'Drama',
    tooltip: false,
  },
  {
    id: 'cResult-2',
    data01: 'AMG',
    data02: 'aliennationbydust',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
    data05: 'Crime+1',
    tooltip: true,
  },
  {
    id: 'cResult-3',
    data01: 'AMG',
    data02: 'aliennationbydust',
    data03: 'Pluto TV Cine Accion',
    data04: imgDummy01,
    data05: 'Thriller+2',
    tooltip: true,
  },
  {
    id: 'cResult-4',
    data01: 'AMG',
    data02: 'aliennationbydust',
    data03: 'Pluto TV Cine Accion',
    data04: imgDummy01,
    data05: 'Romance',
    tooltip: false,
  },
];
export const SelectedContentTh = [
  {
    id: 'ScheduledTh-01',
    align: 'left',
    label: 'Order',
    width: '20%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'ScheduledTh-02',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ScheduledTh-03',
    align: 'left',
    label: 'Content ID',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ScheduledTh-04',
    align: 'left',
    label: 'Content Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ScheduledTh-05',
    align: 'center',
    label: 'Fix',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'ScheduledTh-06',
    align: 'left',
    label: 'Horizontal Image',
    width: '17%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'ScheduledTh-07',
    align: 'left',
    label: 'Big Image',
    width: '17%',
    checkbox: false,
    sortable: false,
  },
];

export const SelectedContentTd = [
  {
    id: 'order-jmyrm',
    data01: '1',
    data02: 'Live',
    data03: '99992398',
    data04: 'Xumo Free MoviesLG DRM Test Channel',
    data05: '',
    data06: imgDummy01,
    data07: imgDummy01,
  },
  {
    id: 'order-sgd',
    data01: '2',
    data02: 'Live',
    data03: '99992398',
    data04: 'Xumo Free MoviesLG DRM Test Channel',
    data05: '',
    data06: imgDummy01,
    data07: imgDummy01,
  },
];

export const ScheduleListTh = [
  {
    id: 'contentTh-01',
    align: 'left',
    label: 'Category Code',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentTh-02',
    align: 'left',
    label: 'Category Name',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentTh-03',
    align: 'left',
    label: 'Schedule',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
];

export const ScheduleListTd = [
  {
    id: 'ScheduleTd-1',
    data01: 'C-Favorite',
    data02: 'C-Favorite',
    data03: '1',
  },
  {
    id: 'ScheduleTd-2',
    data01: 'C-Favorite',
    data02: 'C-Favorite',
    data03: '-',
  },
  {
    id: 'ScheduleTd-3',
    data01: 'C-Favorite',
    data02: 'C-Favorite',
    data03: '3',
  },
  {
    id: 'ScheduleTd-4',
    data01: 'C-Favorite',
    data02: 'C-Favorite',
    data03: '4',
  },
];

export const SelectedScheduleTh = [
  {
    id: 'ScheduleTh-01',
    align: 'left',
    label: 'Start Date',
    width: '25%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'ScheduleTh-02',
    align: 'left',
    label: 'End Date',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ScheduleTh-03',
    align: 'left',
    label: 'Schedule Day',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ScheduleTh-05',
    align: 'left',
    label: 'Status',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ScheduleTh-06',
    align: 'left',
    label: 'Action',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
];
export const SelectedScheduleTd = [
  {
    id: 'ScheduleTd-1',
    data01: '2024-08-20',
    data02: '2024-08-24',
    data03: 'Tuesday',
    data04: 'Scheduled',
  },
  {
    id: 'ScheduleTd-2',
    data01: '2024-08-20',
    data02: '2024-08-24',
    data03: 'Tuesday',
    data04: 'Scheduled',
  },
  {
    id: 'ScheduleTd-3',
    data01: '2024-08-20',
    data02: '2024-08-24',
    data03: 'Tuesday',
    data04: 'Scheduled',
  },
];

export const countryData = [
  {
    id: 1,
    value: 'option1',
    option: 'Option 1',
    type: 'text',
    defaultChecked: true,
  },
  {
    id: 2,
    value: 'option2',
    option: 'Option 2',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 3,
    value: 'option3',
    option: 'Option 3',
    type: 'text',
    defaultChecked: false,
  },
];

export const selectOption = [
  { id: 'cp-1', value: 'All', option: 'All', type: 'text' },
  { id: 'cp-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'cp-3', value: 'option-3', option: 'option-3', type: 'text' },
];
export const selectPlatform = [
  { id: 'p-1', value: '3.4.0', option: '3.4.0', type: 'text' },
  { id: 'p-2', value: '3.4.1', option: '3.4.1', type: 'text' },
  { id: 'p-3', value: '3.4.2', option: '3.4.2', type: 'text' },
];
export const selectDevice = [
  { id: 'd-1', value: 'Tv', option: 'Tv', type: 'text' },
  { id: 'd-2', value: 'phone', option: 'phone', type: 'text' },
  { id: 'd-3', value: 'Tablet', option: 'Tablet', type: 'text' },
];

export const TC_HistoryTh = [
  {
    id: 'historyTh-01',
    align: 'left',
    label: 'Tab Change Date',
    width: '18%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'historyTh-02',
    align: 'left',
    label: 'Tab Code',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'historyTh-03',
    align: 'left',
    label: 'Tab Name',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'historyTh-04',
    align: 'left',
    label: 'Status',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'historyTh-05',
    align: 'left',
    label: 'Enable',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'historyTh-06',
    align: 'left',
    label: 'Last Modifier',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
];

export const TC_HistoryTd = [
  {
    id: 'HistoryTd-1',
    data01: '2024-08-23 00:34:29',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'HistoryTd-2',
    data01: '2024-08-23 00:34:29',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'HistoryTd-3',
    data01: '2024-08-23 00:34:29',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'HistoryTd-4',
    data01: '2024-08-23 00:34:29',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'HistoryTd-5',
    data01: '2024-08-23 00:34:29',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
];

export const TC_CategoryTh = [
  {
    id: 'CategoryTh-01',
    align: 'left',
    label: 'Category Change Date',
    width: '14%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'CategoryTh-02',
    align: 'left',
    label: 'Category Code',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryTh-03',
    align: 'left',
    label: 'Category Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryTh-04',
    align: 'left',
    label: 'Content Type',
    width: '13%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'CategoryTh-05',
    align: 'left',
    label: 'Mapping Type ',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryTh-06',
    align: 'left',
    label: 'Category Type',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryTh-07',
    align: 'left',
    label: 'Auto',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryTh-07',
    align: 'left',
    label: 'Last Modifire',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const TC_CategoryTd = [
  {
    id: 'HistoryTd-1',
    data01: '2024-08-23 00:34:29',
    data02: 'C-Favorite',
    data03: 'My favorite',
    data04: 'Live',
    data05: 'Manual',
    data06: 'Live',
    data07: { status: '[i]', notice: true },
    data08: 'taeil.lim',
  },
  {
    id: 'HistoryTd-2',
    data01: '2024-08-23 00:34:29',
    data02: 'C-Favorite',
    data03: 'My favorite',
    data04: 'Live',
    data05: 'Manual',
    data06: 'Live',
    data07: { status: '-', notice: false },
    data08: 'taeil.lim',
  },
  {
    id: 'HistoryTd-3',
    data01: '2024-08-23 00:34:29',
    data02: 'C-Favorite',
    data03: 'My favorite',
    data04: 'Live',
    data05: 'Manual',
    data06: 'Live',
    data07: { status: '[i]', notice: true },
    data08: 'taeil.lim',
  },
  {
    id: 'HistoryTd-4',
    data01: '2024-08-23 00:34:29',
    data02: 'C-Favorite',
    data03: 'My favorite',
    data04: 'Live',
    data05: 'Manual',
    data06: 'Live',
    data07: { status: '-', notice: false },
    data08: 'taeil.lim',
  },
];

export const TC_HistoryDetailTh = [
  {
    id: 'Code',
    align: 'left',
    label: 'Category Code',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Name',
    align: 'left',
    label: 'Category Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Type',
    align: 'left',
    label: 'Category Type',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Mapping',
    align: 'left',
    label: 'Mapping Type',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'CategoryType',
    align: 'left',
    label: 'Category Type',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Order',
    align: 'left',
    label: 'Order',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Status',
    align: 'left',
    label: 'Status',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'LastModifier',
    align: 'left',
    label: 'Last Modifier',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
];

export const TC_HistoryDetailTd = [
  {
    id: 'HistoryTd-1',
    data01: 'C-Favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'Manual',
    data05: 'Live',
    data06: '1',
    data07: { text: 'Added', status: '' },
    data08: 'taeil.lim',
  },
  {
    id: 'HistoryTd-2',
    data01: 'C-Favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'Manual',
    data05: 'Live',
    data06: '1',
    data07: { text: 'Removed', status: 'remove' },
    data08: 'taeil.lim',
  },
  {
    id: 'HistoryTd-3',
    data01: 'C-Favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'Manual',
    data05: 'Live',
    data06: '1',
    data07: { text: 'Updated', status: 'updated' },
    data08: 'taeil.lim',
  },
  {
    id: 'HistoryTd-4',
    data01: 'C-Favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'Manual',
    data05: 'Live',
    data06: '1',
    data07: { text: 'Added', status: '' },
    data08: 'taeil.lim',
  },
];

export const TC_DetailCategoryTh = [
  {
    id: 'Type',
    align: 'left',
    label: 'Contents Type',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Name',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ID',
    align: 'left',
    label: 'Content ID',
    width: '15%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'ContentName',
    align: 'left',
    label: 'Content Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Order',
    align: 'left',
    label: 'Order',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Status',
    align: 'left',
    label: 'Status',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'LastModifier',
    align: 'left',
    label: 'Last Modifier',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
];

export const TC_DetailCategoryTd = [
  {
    id: 'HistoryTd-1',
    data01: 'live',
    data02: 'NCAA',
    data03: 'us-lg_ncaachannel_1',
    data04: 'Monthly Picks : NCAA',
    data05: '1',
    data06: 'Added',
    data07: 'taeil.lim',
  },
  {
    id: 'HistoryTd-1',
    data01: 'live',
    data02: 'NCAA',
    data03: 'us-lg_ncaachannel_1',
    data04: 'Monthly Picks : NCAA',
    data05: '1',
    data06: 'Added',
    data07: 'taeil.lim',
  },
  {
    id: 'HistoryTd-1',
    data01: 'live',
    data02: 'NCAA',
    data03: 'us-lg_ncaachannel_1',
    data04: 'Monthly Picks : NCAA',
    data05: '1',
    data06: 'Added',
    data07: 'taeil.lim',
  },
  {
    id: 'HistoryTd-1',
    data01: 'live',
    data02: 'NCAA',
    data03: 'us-lg_ncaachannel_1',
    data04: 'Monthly Picks : NCAA',
    data05: '1',
    data06: 'Added',
    data07: 'taeil.lim',
  },
];

export const CommonAutoTh = [
  {
    id: 'order',
    align: 'left',
    label: 'Order',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cp_id',
    align: 'left',
    label: 'CP',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'content_type',
    align: 'left',
    label: 'Type',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'content_id',
    align: 'left',
    label: 'Content ID',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'name',
    align: 'left',
    label: 'Name',
    width: '7%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'season',
    align: 'left',
    label: 'Season',
    width: '7%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'episode',
    align: 'left',
    label: 'Episode',
    width: '7%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'img',
    align: 'left',
    label: 'Img',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'start_date_time',
    align: 'left',
    label: 'Start time',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'genre_name',
    align: 'left',
    label: 'Genre',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'keywords',
    align: 'left',
    label: 'Keyword',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'last_chg_date',
    align: 'left',
    label: 'Last Chg Date',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
];

export const CommonAutoTd = [
  {
    id: 'CommonAutoTd-1',
    data01: '1',
    data02: 'PLT',
    data03: 'Live',
    data04: '61a52615cbef2500072876e2',
    data05: 'MTV shore',
    data06: '-',
    data07: '-',
    data08: '',
    data09: '2024-08-23 15:34:27',
    data010: 'talkShow&Entertainment',
    data011: '-',
    data012: '2024-08-04T14:35',
    data013: 'N',
  },
  {
    id: 'CommonAutoTd-2',
    data01: '1',
    data02: 'PLT',
    data03: 'Live',
    data04: '61a52615cbef2500072876e2',
    data05: 'MTV shore',
    data06: '-',
    data07: '-',
    data08: '',
    data09: '2024-08-23 15:34:27',
    data010: 'talkShow&Entertainment',
    data011: '-',
    data012: '2024-08-04T14:35',
    data013: 'N',
  },
  {
    id: 'CommonAutoTd-3',
    data01: '1',
    data02: 'PLT',
    data03: 'Live',
    data04: '61a52615cbef2500072876e2',
    data05: 'MTV shore',
    data06: '-',
    data07: '-',
    data08: '',
    data09: '2024-08-23 15:34:27',
    data010: 'talkShow&Entertainment',
    data011: '-',
    data012: '2024-08-04T14:35',
    data013: 'N',
  },
  {
    id: 'CommonAutoTd-4',
    data01: '1',
    data02: 'PLT',
    data03: 'Live',
    data04: '61a52615cbef2500072876e2',
    data05: 'MTV shore',
    data06: '-',
    data07: '-',
    data08: '',
    data09: '2024-08-23 15:34:27',
    data010: 'talkShow&Entertainment',
    data011: '-',
    data012: '2024-08-04T14:35',
    data013: 'N',
  },
];

export const KeywordCountTh = [
  {
    id: 'Type',
    align: 'left',
    label: 'Type',
    width: '16%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Country',
    align: 'left',
    label: 'Country',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CP Name',
    align: 'left',
    label: 'CP Name',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Keyword',
    align: 'left',
    label: 'Keyword',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'Count',
    align: 'left',
    label: 'Count',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
];

export const ContentListTh = [
  {
    id: 'contentTh-01',
    align: 'left',
    label: 'CP Name',
    width: '24%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'contentTh-02',
    align: 'left',
    label: 'Content ID',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentTh-03',
    align: 'left',
    label: 'Content Name',
    width: '26%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentTh-04',
    align: 'left',
    label: 'Image',
    width: '18%',
    checkbox: false,
    sortable: false,
  },
];

export const ContentListTd = [
  {
    id: 'cl-1',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
  {
    id: 'cl-2',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
  {
    id: 'cl-3',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
  {
    id: 'cl-4',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
  {
    id: 'cl-5',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
];

export const CommonManualTh = [
  {
    id: 'cp',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'id',
    align: 'left',
    label: 'Content ID',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'name',
    align: 'left',
    label: 'Content Name',
    width: '24%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'image',
    align: 'left',
    label: 'Image',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'genre',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const CommonManualTd = [
  {
    id: 'cm-1',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
    data05: 'sport',
  },
  {
    id: 'cm-2',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
    data05: 'sport',
  },
  {
    id: 'cm-3',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
    data05: 'sport',
  },
  {
    id: 'cm-4',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
    data05: 'sport',
  },
  {
    id: 'cm-5',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
    data05: 'sport',
  },
];

export const SelectedOrgContentTh = [
  {
    id: 'SelectedTh-01',
    align: 'left',
    label: 'Order',
    width: '18%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'SelectedTh-02',
    align: 'left',
    label: 'Type',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-03',
    align: 'left',
    label: 'CP Name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-04',
    align: 'left',
    label: 'Content ID',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'SelectedTh-05',
    align: 'left',
    label: 'Content Name',
    width: '23%',
    checkbox: false,
    sortable: false,
  },
];

export const SelectedOrgContentTd = [
  {
    id: 'SelectedOrder-1',
    data01: '1',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
  {
    id: 'SelectedOrder-2',
    data01: '2',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
  {
    id: 'SelectedOrder-3',
    data01: '3',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
  {
    id: 'SelectedOrder-4',
    data01: '4',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
];

export const ScheduledCommonManualTh = [
  {
    id: 'SelectedTh-01',
    align: 'left',
    label: 'Order',
    width: '17%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'SelectedTh-02',
    align: 'left',
    label: 'Type',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-03',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-04',
    align: 'left',
    label: 'Content ID',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-05',
    align: 'left',
    label: 'Content Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-06',
    align: 'left',
    label: 'Image',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'SelectedTh-07',
    align: 'left',
    label: 'Genre',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
];

export const ScheduledCommonManualTd = [
  {
    id: 'order-rww',
    data01: '1',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
    data06: '',
    data07: 'sport',
  },
  {
    id: 'order-24',
    data01: '2',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
    data06: '',
    data07: 'sport',
  },
  {
    id: 'order-mjkd',
    data01: '3',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
    data06: '',
    data07: 'sport',
  },
  {
    id: 'order-fjg',
    data01: '4',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
    data06: '',
    data07: 'sport',
  },
];

export const Region = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Eu', value: 'Eu', option: 'Eu', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const KeywordCountTd = [
  {
    id: 'KeywordCountTd-1',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'KeywordCountTd-2',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'KeywordCountTd-3',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'KeywordCountTd-4',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'KeywordCountTd-5',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'KeywordCountTd-6',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'KeywordCountTd-7',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'KeywordCountTd-8',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'KeywordCountTd-9',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'KeywordCountTd-10',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
];

export const GenreCountTh = [
  {
    id: 'Type',
    align: 'left',
    label: 'Type',
    width: '16%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Country',
    align: 'left',
    label: 'Country',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Code',
    align: 'left',
    label: 'Genre Code',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Name',
    align: 'left',
    label: 'Genre Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'Count',
    align: 'left',
    label: 'Count',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
];
export const GenreCountTd = [
  {
    id: 'GenreCountTd-1',
    data01: 'Live',
    data02: 'KR',
    data03: '100',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'GenreCountTd-2',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'GenreCountTd-3',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'GenreCountTd-4',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
];

export const GenreCountCPTh = [
  {
    id: 'Type',
    align: 'left',
    label: 'Type',
    width: '16%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Country',
    align: 'left',
    label: 'Country',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cp-code',
    align: 'left',
    label: 'CP Name',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Code',
    align: 'left',
    label: 'Genre Code',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Genre-Name',
    align: 'left',
    label: 'Genre Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Count',
    align: 'left',
    label: 'Count',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
];
export const GenreCountCPTd = [
  {
    id: 'GenreCountTd-1',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '100',
    data05: 'Movie',
    data06: '1',
  },
  {
    id: 'GenreCountTd-2',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '100',
    data05: 'Movie',
    data06: '1',
  },
  {
    id: 'GenreCountTd-3',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '100',
    data05: 'Movie',
    data06: '1',
  },
  {
    id: 'GenreCountTd-4',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '100',
    data05: 'Movie',
    data06: '1',
  },
  {
    id: 'GenreCountTd-5',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '100',
    data05: 'Movie',
    data06: '1',
  },
  {
    id: 'GenreCountTd-6',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '100',
    data05: 'Movie',
    data06: '1',
  },
  {
    id: 'GenreCountTd-7',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '100',
    data05: 'Movie',
    data06: '1',
  },
];

export const InformationTh = [
  {
    id: 'Type',
    align: 'left',
    label: '구분',
    width: '10%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'ContentType',
    align: 'left',
    label: 'Content Type',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Creiteria',
    align: 'left',
    label: 'Creiteria',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Detail',
    align: 'left',
    label: 'Detail Creiteria',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'result',
    align: 'left',
    label: '결과값',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const InformationTd = [
  {
    id: 'InformationTd-1',
    data01: '기존 정리',
    data02: [
      { text: 'Live', status: '' },
      { text: 'Movie', status: 'lv1' },
      { text: 'Tv Show', status: 'lv2' },
    ],
    data03: 'Channel Genre',
    data04: '선택필수',
    data05: '선택한 Content 중 LastChgDate가 가장 빠른순으로 Auto 편성',
  },
  {
    id: 'InformationTd-2',
    data01: '기존 정리',
    data02: [{ text: 'Live', status: '' }],
    data03: 'Channel Genre',
    data04: '선택필수',
    data05: '선택한 Content 중 LastChgDate가 가장 빠른순으로 Auto 편성',
  },
  {
    id: 'InformationTd-3',
    data01: '기존 정리',
    data02: [
      { text: 'Live', status: '' },
      { text: 'Movie', status: '' },
      { text: 'Tv Show', status: '' },
    ],
    data03: 'Program Genre',
    data04: '선택필수',
    data05: '선택한 Content 중 LastChgDate가 가장 빠른순으로 Auto 편성',
  },
  {
    id: 'InformationTd-3',
    data01: '신규',
    data02: [
      { text: 'Movie', status: '' },
      { text: 'TvShow', status: '' },
    ],
    data03: 'Keyword',
    data04: '선택필수',
    data05: '선택한 Content 중 LastChgDate가 가장 빠른순으로 Auto 편성',
  },
];

export const programData = {
  TabCategory: 'Home/CAROUSEL',
  Country: 'US',
  ContentID: 'notification_US_20240626-004926',
  ContentName: 'LG Channels Now on Your Phone!',
  CPName: 'LG Channels Now on Your Phone!',
  CarouselType: 'Notification',
};

export const ResultOrgMovieTh = [
  {
    id: 'ResultOrgMovieTh-01',
    align: 'left',
    label: 'CP Name',
    width: '18%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'ResultOrgMovieTh-02',
    align: 'left',
    label: 'Content ID',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ResultOrgMovieTh-03',
    align: 'left',
    label: 'Content Name',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ResultOrgMovieTh-04',
    align: 'left',
    label: 'Image',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ResultOrgMovieTh-05',
    align: 'left',
    label: 'Genre',
    width: '15%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'ResultOrgMovieTh-05',
    align: 'left',
    label: 'License Date',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const ResultOrgMovieTd = [
  {
    id: 'cResult-1',
    data01: 'AMG',
    data02: 'aliennationbydust',
    data03: 'The Wedding Video',
    data04: imgDummy01,
    data05: { text: 'Sport', tooltip: true },
    data06: '2024-08-20~2040-08-22',
  },
  {
    id: 'cResult-2',
    data01: 'AMG',
    data02: 'aliennationbydust',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
    data05: { text: 'Sport', tooltip: true },
    data06: '2024-08-20~2040-08-22',
  },
  {
    id: 'cResult-3',
    data01: 'AMG',
    data02: 'aliennationbydust',
    data03: 'Pluto TV Cine Accion',
    data04: imgDummy01,
    data05: { text: 'Sport', tooltip: true },
    data06: '2024-08-20~2040-08-22',
  },
  {
    id: 'cResult-4',
    data01: 'AMG',
    data02: 'aliennationbydust',
    data03: 'Pluto TV Cine Accion',
    data04: imgDummy01,
    data05: { text: 'Sport', tooltip: true },
    data06: '2024-08-20~2040-08-22',
  },
];

export const ScheduledOrgMovieTh = [
  {
    id: 'orgM-Order',
    align: 'left',
    label: 'Order',
    width: '15%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'orgM-Type',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'orgM-CPName',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'orgM-ContentID',
    align: 'left',
    label: 'Content ID',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'orgM-ContentName',
    align: 'left',
    label: 'Content Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'orgM-Image',
    align: 'left',
    label: 'Image',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'orgM-Genre',
    align: 'left',
    label: 'Genre',
    width: '15%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'orgM-License',
    align: 'left',
    label: 'License Date',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const ScheduledOrgMovieTd = [
  {
    id: 'ScheduledOrgMovieTd-1',
    data01: '1',
    data02: 'live',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'Xumo FreeMovies LG DRMTest Channel',
    data06: '',
    data07: { text: 'Sport', tooltip: false },
    data08: '2024-08-20~2040-08-22',
  },
  {
    id: 'ScheduledOrgMovieTd-2',
    data01: '2',
    data02: 'live',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'Xumo FreeMovies LG DRMTest Channel',
    data06: '',
    data07: { text: 'Sport', tooltip: true },
    data08: '2024-08-20~2040-08-22',
  },
  {
    id: 'ScheduledOrgMovieTd-3',
    data01: '3',
    data02: 'live',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'Xumo FreeMovies LG DRMTest Channel',
    data06: '',
    data07: { text: 'Sport', tooltip: true },
    data08: '2024-08-20~2040-08-22',
  },
  {
    id: 'ScheduledOrgMovieTd-4',
    data01: '4',
    data02: 'live',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'Xumo FreeMovies LG DRMTest Channel',
    data06: '',
    data07: { text: 'Sport', tooltip: true },
    data08: '2024-08-20~2040-08-22',
  },
];

export const UpcomingVodTh = [
  {
    id: 'providerId',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'contentId',
    align: 'left',
    label: 'Content ID',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentName',
    align: 'left',
    label: 'Content Name',
    width: '24%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'imageUrl',
    align: 'left',
    label: 'Image',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'genre',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'startDateTime',
    align: 'left',
    label: 'License Date',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const UpcomingProgramTh = [
  {
    id: 'SelectedTh-01',
    align: 'left',
    label: 'Order',
    width: '17%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'SelectedTh-02',
    align: 'left',
    label: 'Type',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-03',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-04',
    align: 'left',
    label: 'Content ID',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-05',
    align: 'left',
    label: 'Content Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-06',
    align: 'left',
    label: 'Image',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'SelectedTh-07',
    align: 'left',
    label: 'Genre',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
];

export const UpcomingResultTh = [
  {
    id: 'resultOrder',
    align: 'left',
    label: 'Order',
    width: '15%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'contentType',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerId',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentId',
    align: 'left',
    label: 'Content ID',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentName',
    align: 'left',
    label: 'Content Name',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'imageUrl',
    align: 'left',
    label: 'Image',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'genre',
    align: 'left',
    label: 'Genre',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'startDateTime',
    align: 'left',
    label: 'License Date',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];