import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { Box, Link, ListItem, List, Grid, Divider, Typography, IconButton } from '@mui/material';


import { useNavigate } from 'react-router-dom';
import { getAnTestServiceUserTotal, getSegmentDetails, saveCreateAnTestServiceSegment } from '../ANTestService/api/restApi';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
import { styled } from '@mui/system';
import BasicSnackbar from 'components/component/BasicSnackbar';
import BasicDialog from 'components/component/BasicDialog';
import { DayOfWeekOption } from './data/SegmentData';
import SelectCheckBox from 'components/component/BasicSelectCheckBox';
import { PieChart } from '@mui/x-charts';
// 메인 컨텐츠
function ANTestServiceSegmentOrganize(props) {
  const { message, type } = props;
  const { selectedRow, mainFilteringData } = message ?? {};
  const [showCGroup, setShowCGroup] = useState(false);
  const [isCGroupDisabled, setIsCGroupDisabled] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: '',
    content: '',
    button: [],
  });
  let segIdx = '';
  let organize = false;
  const [showSnackBar, setShowSnackBar] = useState({
    open: false,
    message: "",
    severity: "",
    action: null,
  });
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth() + 1 > 9 ? now.getUTCMonth() + 1 : '0' + (now.getUTCMonth() + 1);
  const day = now.getUTCDate() > 9 ? now.getUTCDate() : '0' + now.getUTCDate();
  const hours = now.getUTCHours() > 9 ? now.getUTCHours() : '0' + now.getUTCHours();
  const minutes = now.getUTCMinutes() > 9 ? now.getUTCMinutes() : '0' + now.getUTCMinutes();
  const seconds = now.getUTCSeconds() > 9 ? now.getUTCSeconds() : '0' + now.getUTCSeconds();
  const [time, setTime] = useState();
  const [segmentInfoBList, setSegmentInfoBList] = useState([]);
  const [segmentInfoCList, setSegmentInfoCList] = useState([]);
  const [segmentIdx, setSegmentIdx] = useState();
  const [userTotalInfo, setUserTotalInfo] = useState(null); // chart 위한 각 keyword의 user total count 정보

  const CloseIcon = styled('span')({
    display: 'block',
    width: '32px',
    height: '32px',
    backgroundImage: `url(${CloseIcon32})`,
    backgroundRepeat: 'no-repeat',
  });

  const navigate = useNavigate();
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: type === 'antest' ? 'AN Test' : 'AN Service',
    normalMenu: ['Segment', 'Segment Organize'],
  };

  const getSegmentDetailsData = async () => {
    const res = await getSegmentDetails(selectedRow.countryCode, type);
    setSegmentInfoBList(res.data.filter(item => item.segmentGroup === 'B'));
    setSegmentInfoCList(res.data.filter(item => item.segmentGroup === 'C'));
  };

  const getUserTotalInfo = async () => {
    const userTotalData = await getAnTestServiceUserTotal(selectedRow.countryCode, type);
    setUserTotalInfo({
      ALL: userTotalData?.total_unique_users_beacon,
      drama: userTotalData?.total_unique_users_drama_adts,
      hobbyLeisure: userTotalData?.total_unique_users_hobbyLeisure_adts,
      movies: userTotalData?.total_unique_users_movies_adts,
      music: userTotalData?.total_unique_users_music_adts,
      news: userTotalData?.total_unique_users_news_adts,
      sport: userTotalData?.total_unique_users_sport_adts
    });
  }

  useEffect(() => {
    setTime(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
    getSegmentDetailsData();
    getUserTotalInfo();
    // eslint-disable-next-line
  }, []);

  const [createANTestServiceSegment, setCreateANTestServiceSegment] = useState({
    idx: selectedRow.idx,
    title: selectedRow.title,
    startTime: type === 'antest' ? selectedRow.startTime : selectedRow.startTimeLocal,
    endTime: type === 'antest' ? selectedRow.endTime : selectedRow.endTimeLocal,
    segmentTarget: 'All',
    segmentDescription: '',
    sampleRate: 0,
    recommendationContentsBType: '',
    mappingType: '',
    sampleRateB: 100,
    contentPolicy: '',
    recommendationContentsCType: '',
    comparativeMappingType: '',
    comparativeSegmentSampleRate: 0,
    comparativeContentPolicy: '',
    countryCode: selectedRow.countryCode,
    region: selectedRow.countryCode,
    segmentId: '',
    abtestId: selectedRow.abtestId,
    segmentType: '',
    tabCode: selectedRow.tabCode,
    categoryCode: selectedRow.categoryCode,
    status: selectedRow.status,
    defaultContent: 'N',
    deviceType: selectedRow.deviceType,
    platformVersion: selectedRow.platformVersion,
    comparativeSegmentType: '',
    comparativeSegmentId: '',
    ...(type === 'anservice' ? { dayOfWeek: [] } : {})
  });

  // Chart
  const isChart = useMemo(() => {
    return createANTestServiceSegment.sampleRate !== 0 && createANTestServiceSegment.sampleRateB !== 0;
  }, [createANTestServiceSegment.sampleRate, createANTestServiceSegment.sampleRateB]);

  const infos = [
    {
      index: 1,
      label: type === 'antest' ? '#Current Time(UTC)' : '#Current Time(Local)',
      value: time,
      divide: true,
    },
    {
      index: 2,
      label: '#Title',
      value: selectedRow.title,
      divide: true,
    },
    {
      index: 3,
      label: '#Date',
      value: type === 'antest' ? selectedRow.startTime : selectedRow.startTimeLocal + ' ~ ' + type === 'antest' ? selectedRow.endTime : selectedRow.endTimeLocal,
      divide: true,
    },
    {
      index: 4,
      label: '#Device Type',
      value: selectedRow.deviceType,
      divide: true,
    },
    {
      index: 5,
      label: '#Country',
      value: selectedRow.region,
      divide: true,
    },
    {
      index: 6,
      label: '#Platform Version',
      value: selectedRow.platformVersion,
      divide: true,
    },
    {
      index: 7,
      label: '#Status',
      value: selectedRow.status,
      divide: true,
    },
    {
      index: 8,
      label: '#Tab',
      value: `${selectedRow.tabCode} ${selectedRow.tabName}`,
      divide: true,
    },
    {
      index: 9,
      label: '#Category',
      value: `${selectedRow.categoryCode} ${selectedRow.categoryName}`,
      divide: true,
    },
  ];

  const chartData = useMemo(() => {
    if (createANTestServiceSegment.segmentTarget && segmentInfoBList && userTotalInfo && createANTestServiceSegment.sampleRate && createANTestServiceSegment.sampleRateB) {
      // 사람 수
      let total, bGroup, cGroup = 0, all;
      if (createANTestServiceSegment.segmentTarget === 'ALL') {
        total = userTotalInfo.ALL;
      } else {
        const nowSegment = segmentInfoBList.filter(item => item.segmentTarget === createANTestServiceSegment.segmentTarget);
        if (nowSegment.length > 0) {
          total = userTotalInfo[nowSegment[0].keyword];
        } else {
          return null;
        }
      }

      if (total == null || typeof total !== 'number') return null;

      bGroup = Math.floor(total * createANTestServiceSegment.sampleRate / 100 * createANTestServiceSegment.sampleRateB / 100);
      if (createANTestServiceSegment.comparativeSegmentSampleRate && createANTestServiceSegment.comparativeSegmentSampleRate !== 0) {
        cGroup = Math.floor(total * createANTestServiceSegment.sampleRate / 100 * createANTestServiceSegment.comparativeSegmentSampleRate / 100);
      }
      all = total - bGroup - cGroup;

      // 비율
      let bRate, cRate, allRate;
      bRate = Math.floor(createANTestServiceSegment.sampleRate * createANTestServiceSegment.sampleRateB / 100);
      cRate = createANTestServiceSegment.sampleRate - bRate;
      allRate = 100 - createANTestServiceSegment.sampleRate;
      return [
        { id: 0, value: all, label: 'All', color: '#38df9f', rate: allRate },
        { id: 1, value: bGroup, label: 'B Group', color: '#0077b5', rate: bRate },
        { id: 2, value: cGroup, label: 'C Group', color: '#0d9488', rate: cRate },
      ];
    } else {
      return null;
    }
  }, [createANTestServiceSegment.segmentTarget, segmentInfoBList, userTotalInfo, createANTestServiceSegment.sampleRate, createANTestServiceSegment.sampleRateB, createANTestServiceSegment.comparativeSegmentSampleRate]);

  const segmentTargetOptions = useMemo(() => {
    return Array.from(
      new Set(segmentInfoBList.map(targetData => targetData.segmentTarget))
    ).sort((a, b) => a.localeCompare(b))
      .map(targetData => ({
        id: targetData,
        value: targetData,
        option: targetData,
        type: "text",
      }));
  }, [segmentInfoBList]);

  useEffect(() => {
    if (segmentTargetOptions.length > 0) {
      setCreateANTestServiceSegment(prevState => ({
        ...prevState,
        segmentTarget: segmentTargetOptions[0].value,
      }));
    } else {
      setCreateANTestServiceSegment(prevState => ({
        ...prevState,
        segmentTarget: '',
      }));
    }
  }, [segmentTargetOptions]);

  const onChangeSegmentTarget = (value) => {
    const bSegmentData = segmentInfoBList.find(target => target.segmentTarget === value);
    const cSegmentData = segmentInfoCList.find(target => target.segmentTarget === value);

    setCreateANTestServiceSegment((prevState) => ({
      ...prevState,
      segmentTarget: value,
      sampleRateB: 100,
      comparativeSegmentSampleRate: 0,
      contentPolicy: createANTestServiceSegment.mappingType === 'auto' && bSegmentData ? bSegmentData.contentPolicyAuto : '',
      comparativeContentPolicy: cSegmentData ? cSegmentData.contentPolicyAuto : createANTestServiceSegment.comparativeContentPolicy,
    }));
  }

  useEffect(() => {
    if (!createANTestServiceSegment.segmentTarget || !segmentInfoBList) {
      return;
    }

    const targetData = segmentInfoBList.find(target =>
      target.segmentTarget === createANTestServiceSegment.segmentTarget);
    setCreateANTestServiceSegment((prevState) => ({
      ...prevState,
      segmentDescription: targetData?.description || '',
    }));
  }, [createANTestServiceSegment.segmentTarget, segmentInfoBList]);

  const onChangeSampleRate = e => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && (value === '' || (Number(value) >= 0 && Number(value) <= 100))) {
      const numericValue = Number(value);
      setCreateANTestServiceSegment((prevState) => ({
        ...prevState,
        sampleRate: numericValue,
      }));
    } else {
      e.preventDefault();
    }
  };

  const recommendationContentBOptions = useMemo(() => {
    if (segmentInfoBList && createANTestServiceSegment.segmentTarget) {
      return segmentInfoBList
        .filter(item => item.segmentTarget === createANTestServiceSegment.segmentTarget)
        .reduce((uniqueOptions, targetData) => {
          if (!uniqueOptions.some(option => option.id === targetData.segmentId)) {
            uniqueOptions.push({
              id: targetData.segmentId,
              value: targetData.recommendationContents,
              option: targetData.recommendationContents,
              type: "text",
              segType: targetData.segmentType,
            });
          }
          return uniqueOptions;
        }, []);
    }
    return [];
  }, [segmentInfoBList, createANTestServiceSegment.segmentTarget]);


  useEffect(() => {
    if (recommendationContentBOptions.length > 0) {
      const { id, value, segType } = recommendationContentBOptions[0];
      setCreateANTestServiceSegment(prevState => ({
        ...prevState,
        recommendationContentsBType: value,
        segmentId: id,
        segmentType: segType,
      }));
    } else {
      setCreateANTestServiceSegment(prevState => ({
        ...prevState,
        recommendationContentsBType: '',
        segmentId: '',
        segmentType: '',
      }));
    }
  }, [recommendationContentBOptions]);

  const mappingTypeBOption = useMemo(() => {
    if (segmentInfoBList && createANTestServiceSegment.segmentTarget) {
      return segmentInfoBList
        .filter(item => item.segmentTarget === createANTestServiceSegment.segmentTarget
          && item.segmentGroup === 'B' && item.recommendationContents === createANTestServiceSegment.recommendationContentsBType
        )
        .map(targetData => ({
          id: targetData.segmentId,
          value: targetData.segmentMappingType,
          option: targetData.segmentMappingType,
          type: "text",
          segType: targetData.segmentType,
        }));
    }
    return [];
  }, [segmentInfoBList, createANTestServiceSegment.segmentTarget, createANTestServiceSegment.recommendationContentsBType]);

  const mappingTypeCOption = useMemo(() => {
    if (segmentInfoCList && createANTestServiceSegment.segmentTarget) {
      return segmentInfoCList
        .filter(item => item.segmentTarget === createANTestServiceSegment.segmentTarget
          && item.segmentGroup === 'C' && item.recommendationContents === createANTestServiceSegment.recommendationContentsCType
        )
        .map(targetData => ({
          id: targetData.segmentId,
          value: targetData.segmentMappingType,
          option: targetData.segmentMappingType,
          type: "text",
          segType: targetData.segmentType,
        }));
    }
    return [];
  }, [segmentInfoCList, createANTestServiceSegment.segmentTarget, createANTestServiceSegment.recommendationContentsCType]);

  useEffect(() => {
    if (mappingTypeBOption.length > 0) {
      setCreateANTestServiceSegment(prevState => ({
        ...prevState,
        mappingType: mappingTypeBOption[0].value
      }));
    }
  }, [mappingTypeBOption]);

  useEffect(() => {
    if (mappingTypeCOption.length > 0) {
      setCreateANTestServiceSegment(prevState => ({
        ...prevState,
        comparativeMappingType: mappingTypeCOption[0].value
      }));
    }
  }, [mappingTypeCOption]);

  const onRecommendationContentsBChange = (value) => {
    const selectedSegment = segmentInfoBList.find(
      (segment) => segment.recommendationContents === value
    );
    if (selectedSegment) {
      setCreateANTestServiceSegment((prevState) => ({
        ...prevState,
        recommendationContentsBType: value,
        segmentType: selectedSegment.segmentType,
        segmentId: selectedSegment.segmentId,
        contentPolicy: selectedSegment.contentPolicyAuto,
      }));
    }
  }

  const onChangeMappingTypeB = (value) => {
    const selectedSegment = segmentInfoBList.find(
      (segment) => segment.recommendationContents === createANTestServiceSegment.recommendationContentsBType
    );
    setCreateANTestServiceSegment((prevState) => ({
      ...prevState,
      mappingType: value,
    }));
    if (value === 'auto') {
      setCreateANTestServiceSegment((prevState) => ({
        ...prevState,
        contentPolicy: selectedSegment ? selectedSegment.contentPolicyAuto : '',
      }));
    } else {  // manual
      setCreateANTestServiceSegment((prevState) => ({
        ...prevState,
        contentPolicy: '',
      }));
    }
  }

  const onChangeBGroupRate = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && (value === '' || (Number(value) >= 0 && Number(value) <= 100))) {
      const numericValue = Number(value);
      setCreateANTestServiceSegment((prevState) => ({
        ...prevState,
        sampleRateB: numericValue,
        comparativeSegmentSampleRate: 100 - numericValue,

      }));
    } else {
      e.preventDefault();
    }
  }

  const onChangeBContentPolicy = (e) => {
    setCreateANTestServiceSegment((prevState) => ({
      ...prevState,
      contentPolicy: e.target.value,
    }));
  }

  const onCGroupAddClick = () => {
    setShowCGroup(true);
    setCreateANTestServiceSegment((prevState) => ({
      ...prevState,
      defaultContent: 'Y',
    }));
  }

  const onCGroupCloseClick = () => {
    setShowCGroup(false);
    setCreateANTestServiceSegment((prevState) => ({
      ...prevState,
      defaultContent: 'N',
    }));
  }

  useEffect(() => {
    const selectedTarget = createANTestServiceSegment.segmentTarget;
    if (selectedTarget) {
      const filteredRows = segmentInfoCList.filter((targetData) => targetData.segmentTarget === selectedTarget);
      setIsCGroupDisabled(filteredRows.length === 0);
      setShowCGroup(false);
    } else {
      setIsCGroupDisabled(true);
    }
  }, [createANTestServiceSegment.segmentTarget, segmentInfoCList]);

  const recommendationContentCOptions = useMemo(() => {
    return segmentInfoCList
      .filter(item => item.segmentTarget === createANTestServiceSegment.segmentTarget)
      .reduce((uniqueOptions, targetData) => {
        if (!uniqueOptions.some(option => option.id === targetData.segmentId)) {
          uniqueOptions.push({
            id: targetData.segmentId,
            value: targetData.recommendationContents,
            option: targetData.recommendationContents,
            type: "text",
            segCType: targetData.segmentType,
          });
        }
        return uniqueOptions;
      }, []);
  }, [segmentInfoCList, createANTestServiceSegment.segmentTarget]);

  useEffect(() => {
    if (recommendationContentCOptions.length > 0) {
      const { id, value, segCType } = recommendationContentCOptions[0];
      setCreateANTestServiceSegment(prevState => ({
        ...prevState,
        recommendationContentsCType: value,
        comparativeSegmentId: id,
        comparativeSegmentType: segCType,
      }));
    } else {
      setCreateANTestServiceSegment(prevState => ({
        ...prevState,
        recommendationContentsCType: '',
        comparativeSegmentId: '',
        comparativeSegmentType: '',
      }));
    }
  }, [recommendationContentCOptions]);

  const onRecommendationContentsCChange = (value) => {
    const selectedSegment = segmentInfoCList.find(
      (segment) => segment.recommendationContents === value &&
        segment.segmentTarget === createANTestServiceSegment.segmentTarget
    );
    if (selectedSegment) {
      setCreateANTestServiceSegment((prevState) => ({
        ...prevState,
        recommendationContentsCType: value,
        comparativeContentPolicy: selectedSegment.contentPolicyAuto,
        comparativeSegmentType: selectedSegment.segmentType,
        comparativeSegmentId: selectedSegment.segmentId,
      }));
    }
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  const isActive =
    createANTestServiceSegment.segmentTarget &&
    createANTestServiceSegment.sampleRate !== undefined &&
    createANTestServiceSegment.sampleRate !== null &&
    createANTestServiceSegment.recommendationContentsBType &&
    createANTestServiceSegment.mappingType &&
    createANTestServiceSegment.sampleRateB;

  const handelSegmentSave = () => {
    if (showCGroup === false && createANTestServiceSegment.sampleRateB < 100) {
      setShowSnackBar({
        open: true,
        message: 'Unless the C group is turned on, the B group rate should be 100%.',
        severity: 'error',
        action: null,
      });
      handleDialogClose();
      return;
    } else if (createANTestServiceSegment.sampleRateB < 50) {
      setShowSnackBar({
        open: true,
        message: 'B group rate must be 50 or higher.',
        severity: 'error',
        action: null,
      });
      handleDialogClose();
      return;
    }

    if (createANTestServiceSegment.sampleRate === 0) {
      setOpenDialog(true);
      setDialogContent({
        title: 'Create Segment',
        content: <div>The Segment Sample Rate is 0.<br />Are you sure you want to save it this way?</div>,
        button: [
          {
            color: 'tertiary',
            type: 'outline',
            size: 'medium',
            label: 'Cancel',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 85,
            disabled: false,
            onClick: handleDialogClose,
          },
          {
            color: 'primary',
            type: 'box',
            size: 'medium',
            label: 'OK',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 103,
            disabled: false,
            onClick: () => {
              if (!organize && createANTestServiceSegment.mappingType === 'manual') {
                setOpenDialog(true);
                setDialogContent({
                  title: 'Create Segment',
                  content: <div>The current settings will redirect you to the Organize page due to the 'manual' Mapping Type for B Group.<br />Do you wish to proceed with saving?</div>,
                  button: [
                    {
                      color: 'tertiary',
                      type: 'outline',
                      size: 'medium',
                      label: 'Cancel',
                      isIcon: false,
                      Icon: null,
                      iconPos: undefined,
                      minWidth: 85,
                      disabled: false,
                      onClick: handleDialogClose,
                    },
                    {
                      color: 'primary',
                      type: 'box',
                      size: 'medium',
                      label: 'OK',
                      isIcon: false,
                      Icon: null,
                      iconPos: undefined,
                      minWidth: 103,
                      disabled: false,
                      onClick: handleSaveConfirmation,
                    }
                  ],
                });
              } else {
                handleSaveConfirmation();
              }
            }
          }
        ],
      });
    } else if (!organize && createANTestServiceSegment.mappingType === 'manual') {
      setOpenDialog(true);
      setDialogContent({
        title: 'Create Segment',
        content: <div>The current settings will redirect you to the Organize page due to the 'manual' Mapping Type for B Group.<br />Do you wish to proceed with saving?</div>,
        button: [
          {
            color: 'tertiary',
            type: 'outline',
            size: 'medium',
            label: 'Cancel',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 85,
            disabled: false,
            onClick: handleDialogClose,
          },
          {
            color: 'primary',
            type: 'box',
            size: 'medium',
            label: 'OK',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 103,
            disabled: false,
            onClick: handleSaveConfirmation,
          }
        ],
      });
    } else {
      handleSaveConfirmation();
    }
  }

  const handleSaveConfirmation = async () => {
    try {

      const res = await saveCreateAnTestServiceSegment(createANTestServiceSegment, type);
      setShowSnackBar({
        open: true,
        message: res.message || 'Segment saved successfully!',
        severity: res.severity || 'success',
        action: null,
      });
      setSegmentIdx(res.data);
      segIdx = res.data;
    } catch (error) {
      setShowSnackBar({
        open: true,
        message: 'Error saving segment. Please try again.',
        severity: 'error',
        action: null,
      });
    }
    handleDialogClose();

    if (segIdx) {
      const targetComponent = organize || createANTestServiceSegment.mappingType === 'manual'
        ? 'ANTestServiceSegmentOrganizeOrganize'
        : 'ANTestServiceSegmentOrganizeAddGroup';

      let message = {
        selectedRow: selectedRow,
        segmentIdx: segmentIdx || segIdx,
        mainFilteringData: mainFilteringData,
      };

      if (!organize && createANTestServiceSegment.mappingType === 'manual') {  // click Save && B mapping type == manual
        // navigate to ANTestSegmentOrganizeOrganize
        message.firstSearch = true;
        message.selectedSegment = {
          segmentIdx: segmentIdx,
          sampleBRate: createANTestServiceSegment.sampleRateB,
          bSegmentInfo: segmentInfoBList.filter(item => item.segmentTarget === createANTestServiceSegment.segmentTarget
            && item.segmentMappingType === createANTestServiceSegment.mappingType)
        };
      } else if (!organize) { // click Save && B mapping type == auto
        // navigate to ANTestSegmentOrganizeAddGroup
        message.selectedTestService = selectedRow;
      } else {  // click Organize
        // navigate to ANTestSegmentOrganizeOrganize
        message.firstSearch = true;
        message.selectedSegment = {
          segmentIdx: segmentIdx,
          sampleBRate: createANTestServiceSegment.sampleRateB,
          bSegmentInfo: segmentInfoBList.filter(item => item.segmentTarget === createANTestServiceSegment.segmentTarget
            && item.segmentMappingType === createANTestServiceSegment.mappingType)
        };
      }

      navigate(type === 'antest' ? '/ANTest' : '/ANService', {
        replace: true,
        state: {
          move: true,
          targetComponent: targetComponent,
          message: message
        }
      });
    }
  };

  const handleOrganizeConfirmation = async () => {
    organize = true;
    handelSegmentSave();
  };
  const handleDayOfWeekChange = (newSelectedValues) => {
    setCreateANTestServiceSegment((prevState) => ({
      ...prevState,
      dayOfWeek: newSelectedValues,
    }));
  };

  useEffect(() => {
    setCreateANTestServiceSegment((prev) => ({
      ...prev,
      dayOfWeek: createANTestServiceSegment.dayOfWeek,
    }));
  }, [createANTestServiceSegment.dayOfWeek, setCreateANTestServiceSegment]);


  const handelSegmentOrganize = () => {
    if (!segmentIdx) {
      setOpenDialog(true);
      setDialogContent({
        title: 'Organize Segment',
        content: <div>To navigate to the Organize page,<br />you need to save the current state.<br />Do you want to save it?</div>,
        button: [
          {
            color: 'tertiary',
            type: 'outline',
            size: 'medium',
            label: 'Cancel',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 85,
            disabled: false,
            onClick: handleDialogClose,
          },
          {
            color: 'primary',
            type: 'box',
            size: 'medium',
            label: 'OK',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 103,
            disabled: false,
            onClick: handleOrganizeConfirmation,
          }
        ],
      });
    }
  }

  const handleSnackbarClose = () => {
    setShowSnackBar({ open: false, message: "", severity: "" });
  };

  useEffect(() => {
    if (createANTestServiceSegment.sampleRateB === 100) {
      setIsCGroupDisabled(true);
      setShowCGroup(false);
      setCreateANTestServiceSegment((prevState) => ({
        ...prevState,
        defaultContent: 'N',
      }));
    } else if (createANTestServiceSegment.sampleRateB < 100) {
      const filteredCSegments = segmentInfoCList.filter(item => item.segmentTarget === createANTestServiceSegment.segmentTarget);
      if (filteredCSegments.length > 0) setIsCGroupDisabled(false);
      else setIsCGroupDisabled(true);
    }
  }, [createANTestServiceSegment.sampleRateB, createANTestServiceSegment.segmentTarget, segmentInfoCList]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container segment-container' component='main' sx={{ flexGrow: 1 }}>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='#'
                onClick={(e) => {
                  e.preventDefault();
                  if (!segmentIdx) {
                    setOpenDialog(true);
                    setDialogContent({
                      title: 'Update Segment',
                      content: <div>Do you want to navigate away from this page?<br />Unsaved changes may be lost.</div>,
                      button: [
                        {
                          color: 'tertiary',
                          type: 'outline',
                          size: 'medium',
                          label: 'Cancel',
                          isIcon: false,
                          Icon: null,
                          iconPos: undefined,
                          minWidth: 85,
                          disabled: false,
                          onClick: handleDialogClose,
                        },
                        {
                          color: 'primary',
                          type: 'box',
                          size: 'medium',
                          label: 'OK',
                          isIcon: false,
                          Icon: null,
                          iconPos: undefined,
                          minWidth: 103,
                          disabled: false,
                          onClick: () => {
                            navigate(type === 'antest' ? '/ANTest' : '/ANService', {
                              replace: true, state: {
                                move: true,
                                targetComponent: 'ANTestServiceSegment',
                                message: {
                                  selectedRow: selectedRow,
                                  mainFilteringData: mainFilteringData
                                }
                              }
                            });
                          }
                        }
                      ],
                    });
                  } else {
                    navigate(type === 'antest' ? '/ANTest' : '/ANService', {
                      replace: true, state: {
                        move: true,
                        targetComponent: 'ANTestServiceSegment',
                        message: {
                          selectedRow: selectedRow,
                          mainFilteringData: mainFilteringData
                        }
                      }
                    });
                  }
                }}
              >
                Segment
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap height-auto' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'>
              <Button color='primary' type='box' size='medium' label='Save' autosize isIcon={false} disabled={!isActive} onClick={handelSegmentSave} />
            </Box>
          </Box>

          <Box className='white-box white-box-wrap segment-box' sx={{ flexShrink: 0 }}>
            <Box className='title-wrap'>
              <Box className='box-title-area' display='flex' alignItems='center'>
                <Typography className='segment-box-title'>Segmentation</Typography>
              </Box>
            </Box>
            <Box className='box-content'>
              <Box className='segment-contgent-wrap'>
                <Grid container gap={1}>
                  <Grid item xs={1.7}>
                    <Box component='div'>
                      <SelectBox
                        id='slt-country'
                        size='large'
                        shrink
                        label='Segment Target'
                        selectOptions={segmentTargetOptions}
                        disabled={false}
                        required={true}
                        selected={createANTestServiceSegment.segmentTarget}
                        onChange={onChangeSegmentTarget}
                        placeholder=''
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={1.7}>
                    <Box component='div'>
                      <TextField
                        type='outlined'
                        size='large'
                        placeholder=''
                        defaultValue=''
                        label='Segment Sample Rate'
                        autoComplete='off'
                        required={false}
                        InputLabelProps={{ shrink: true }}
                        value={createANTestServiceSegment.sampleRate}
                        onChange={onChangeSampleRate}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box component='div'>
                      <TextField
                        type='outlined'
                        size='large'
                        placeholder=''
                        label='Segment Description'
                        autoComplete='off'
                        required={false}
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                        value={createANTestServiceSegment.segmentDescription}
                      />
                    </Box>
                  </Grid>{' '}
                  {type === 'anservice' && (
                    <Grid item xs={1.7}>
                      <Box component='div'>
                        <SelectCheckBox
                          id='slt-country'
                          fullWidth
                          size='large'
                          width='100%'
                          placeholder="Day of Week"
                          required={false}
                          selectOptions={DayOfWeekOption}
                          disabled={false}
                          label=''
                          onChange={handleDayOfWeekChange}
                          selected={createANTestServiceSegment.dayOfWeek}
                          shrink
                          multiple={true}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>

          {/* Group B */}
          <Box className='group-addbox-container group-b'>
            <Box className='white-box white-box-wrap segment-box'>
              <Box className='title-wrap'>
                <Box className='box-title-area' display='flex' alignItems='center'>
                  <Typography className='segment-box-title'>B Group</Typography>
                </Box>
              </Box>
              <Box className='box-content'>
                <Box className='segment-contgent-wrap'>
                  <Grid container gap={1}>
                    {/* Recommendation */}
                    <Grid item xs={2}>
                      <Box component='div' title={createANTestServiceSegment.recommendationContentsBType}>
                        <SelectBox
                          id='slt-Recommendation'
                          size='large'
                          shrink={true}
                          label='Recommendation Content'
                          require={false}
                          selectOptions={recommendationContentBOptions}
                          disabled={false}
                          placeholder=''
                          selected={createANTestServiceSegment.recommendationContentsBType}
                          onChange={onRecommendationContentsBChange}
                        />
                      </Box>
                    </Grid>

                    {/* Mapping Type */}
                    <Grid item xs={2}>
                      <Box component='div'>
                        <SelectBox
                          id='slt-Mapping'
                          size='large'
                          shrink={true}
                          label='Mapping Type'
                          require={false}
                          selectOptions={mappingTypeBOption}
                          disabled={false}
                          placeholder=''
                          selected={createANTestServiceSegment.mappingType}
                          onChange={onChangeMappingTypeB}
                        />
                      </Box>
                    </Grid>

                    {/* B Group Rate(%) */}
                    <Grid item xs={2}>
                      <Box component='div'>
                        <TextField
                          type='outlined'
                          size='large'
                          placeholder='50 ~ 100'
                          defaultValue=''
                          label='B Group Rate(%)'
                          autoComplete='off'
                          helperText='At least 50%'
                          InputLabelProps={{ shrink: true }}
                          value={createANTestServiceSegment.sampleRateB}
                          onChange={onChangeBGroupRate}
                        />
                      </Box>
                    </Grid>

                    {/* Contents Policy*/}
                    <Grid item xs={4.5}>
                      <Box component='div'>
                        <TextField
                          type='outlined'
                          size='large'
                          placeholder='Insert your manual contents policy'
                          defaultValue=''
                          label='Contents Policy'
                          autoComplete='off'
                          required={false}
                          InputLabelProps={{ shrink: true }}
                          disabled={createANTestServiceSegment.mappingType === 'auto'}
                          value={createANTestServiceSegment.contentPolicy}
                          onChange={onChangeBContentPolicy}
                          maxLength={255}
                        />
                      </Box>
                    </Grid>

                    {/* Button */}
                    {createANTestServiceSegment.mappingType === 'manual' &&
                      <Grid item xs={1.2} textAlign={'right'}>
                        <Box component='div'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='medium'
                            label='Organize'
                            isIcon={false}
                            disabled={!isActive}
                            autosize
                            onClick={handelSegmentOrganize}
                          />
                        </Box>
                      </Grid>
                    }
                    {!showCGroup &&
                      <Grid container mt={1.5}>
                        <Grid item xs={12} textAlign={'right'}>
                          <Box component='div'>
                            <Button
                              color='tertiary'
                              type='outline'
                              size='medium'
                              label='C Group Add'
                              isIcon={false}
                              autosize
                              disabled={isCGroupDisabled}
                              onClick={onCGroupAddClick}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Group C */}
          {showCGroup &&
            <Box className='group-addbox-container group-c' open={true}>
              <Box className='white-box white-box-wrap segment-box'>
                <Box className='title-wrap'>
                  <Box className='box-title-area' display='flex' alignItems='center'>
                    <Typography className='segment-box-title'>C Group</Typography>
                  </Box>
                </Box>
                <Box className='box-content'>
                  <Box className='segment-contgent-wrap'>
                    <IconButton
                      aria-label='close'
                      onClick={onCGroupCloseClick}
                      sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Grid container gap={1}>
                      {/* Recommendation */}
                      <Grid item xs={2}>
                        <Box component='div' title={createANTestServiceSegment.recommendationContentsCType}>
                          <SelectBox
                            id='slt-Recommendation'
                            size='large'
                            shrink={true}
                            label='Recommendation Content'
                            require={false}
                            selectOptions={recommendationContentCOptions}
                            disabled={false}
                            placeholder=''
                            selected={createANTestServiceSegment.recommendationContentsCType}
                            onChange={onRecommendationContentsCChange}
                          />
                        </Box>
                      </Grid>

                      {/* Mapping Type */}
                      <Grid item xs={2}>
                        <Box component='div'>
                          <SelectBox
                            id='slt-Mapping'
                            size='large'
                            shrink={true}
                            label='Mapping Type'
                            require={false}
                            selectOptions={mappingTypeCOption}
                            disabled={false}
                            placeholder=''
                            selected={createANTestServiceSegment.comparativeMappingType}
                          />
                        </Box>
                      </Grid>

                      {/* C Group Rate(%) */}
                      <Grid item xs={2}>
                        <Box component='div'>
                          <TextField
                            type='outlined'
                            size='large'
                            placeholder=''
                            defaultValue=''
                            label='C Group Rate(%)'
                            autoComplete='off'
                            disabled={true}
                            helperText='100% - B Group rate = C Group rate'
                            InputLabelProps={{ shrink: true }}
                            value={createANTestServiceSegment.comparativeSegmentSampleRate}
                          />
                        </Box>
                      </Grid>

                      {/* Contents Policy*/}
                      <Grid item xs={4.5}>
                        <Box component='div'>
                          <TextField
                            type='outlined'
                            size='large'
                            placeholder='Insert your manual contents policy'
                            defaultValue=''
                            label='Contents Policy'
                            autoComplete='off'
                            required={false}
                            InputLabelProps={{ shrink: true }}
                            disabled={true}
                            value={createANTestServiceSegment.comparativeContentPolicy}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
          {/* Chart area */}
          {isChart && chartData &&
            <Box className='white-box white-box-wrap chart-box' mt={1.5} mb={2} sx={{ maxHeight: 'fit-content' }}>
              <Box className='title-wrap'>
                <Box className='box-title-area' display='flex' alignItems='center'>
                  <Typography className='segment-box-title'>User Population Segmentation Chart</Typography>
                </Box>
              </Box>
              <Box className='box-content'>
                <Box className='chart-container'>
                  <Box className='chart-wrap align-center'>
                    <PieChart
                      series={[
                        {
                          data: chartData,
                          valueFormatter: (v => `${v.value.toLocaleString()} (${v.rate}%)`)
                        },
                      ]}
                      width={400}
                      height={200}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        </Box>
      </Box>
      <BasicDialog
        title={dialogContent.title}
        contentTitle={dialogContent.content}
        size={'small'}
        closeX={true}
        open={openDialog}
        handleClose={handleDialogClose}
        buttons={dialogContent.button}
      />
      <div>
        <BasicSnackbar onClose={handleSnackbarClose} severity={showSnackBar.severity} message={showSnackBar.message} open={showSnackBar.open} duration={1000} action={null} />
      </div>
    </Box >
  );
}
export default ANTestServiceSegmentOrganize;
