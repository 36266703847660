export const RoleOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'r-1', value: 'Role', option: 'Role', type: 'text' },
  { id: 'r-2', value: 'Role2', option: 'Role2', type: 'text' },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const RoleSearchOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Role Code', value: 'Role Code', option: 'Role Code', type: 'text' },
  { id: 'Role Name', value: 'Role Name', option: 'Role Name', type: 'text' },
]

export const DefaultSettingOption = [
  { id: 'ROLE_ADMIN_FC', value: 'ROLE_ADMIN_FC', option: 'ROLE_ADMIN_FC', type: 'text' },
  { id: 'ROLE_ADMIN_OP', value: 'ROLE_ADMIN_OP', option: 'ROLE_ADMIN_OP', type: 'text' },
  { id: 'ROLE_CP', value: 'ROLE_CP', option: 'ROLE_CP', type: 'text' },
  { id: 'ROLE_STUDIO', value: 'ROLE_STUDIO', option: 'ROLE_STUDIO', type: 'text' },
];

export const EditDefaultSettingOption = [
  { id: 'None', value: 'None', option: 'None', type: 'text' },
  { id: 'ROLE_ADMIN_FC', value: 'ROLE_ADMIN_FC', option: 'ROLE_ADMIN_FC', type: 'text' },
  { id: 'ROLE_ADMIN_OP', value: 'ROLE_ADMIN_OP', option: 'ROLE_ADMIN_OP', type: 'text' },
  { id: 'ROLE_CP', value: 'ROLE_CP', option: 'ROLE_CP', type: 'text' },
  { id: 'ROLE_STUDIO', value: 'ROLE_STUDIO', option: 'ROLE_STUDIO', type: 'text' },
];
export const MenuGroupOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'All', value: 'All', option: 'All', type: 'text' },

];

export const OperationModeOption = [
  { id: 'Editor', value: 'Editor', option: 'Editor', type: 'text' },
  { id: 'Viewer', value: 'Viewer', option: 'Viewer', type: 'text' },
];

export const AccTh = [
  {
    id: 'AccTh-Code1',
    align: 'left',
    label: 'Role Code',
    width: '40%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'AccTh-Role',
    align: 'left',
    label: 'Role Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const AccTd = [
  {
    id: 'AccTd-1',
    data01: 'super admin',
    data02: 'Super Admin',
  },
  {
    id: 'AccTd-2',
    data01: 'admin_fc',
    data02: 'admin - 법인',
  },
  {
    id: 'AccTd-3',
    data01: 'super admin',
    data02: 'Super Admin',
  },
  {
    id: 'AccTd-4',
    data01: 'admin_fc',
    data02: '컨텐츠 파트너(CP)',
  },
];

export const accAddTh = [
  {
    id: 'accAddTh-1',
    align: 'left',
    label: 'Menu Group',
    width: '24%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'accAddTh-2',
    align: 'left',
    label: 'Menu Name',
    width: '26%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'accAddTh-3',
    align: 'left',
    label: 'Access Status',
    width: '24%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'accAddTh-4',
    align: 'left',
    label: 'Operation Mode',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const accAddTd = [
  {
    id: 'AccTd-1',
    data01: 'ISS',
    data02: 'Dashboard',
  },
  {
    id: 'AccTd-2',
    data01: 'ISS',
    data02: 'Content',
  },
  {
    id: 'AccTd-3',
    data01: 'Inspection',
    data02: 'Content',
  },
  {
    id: 'AccTd-4',
    data01: 'Inspection',
    data02: 'Content',
  },
];
