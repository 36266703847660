import React, { useState, useEffect } from 'react';
import { Typography, ListItem, Divider } from '@mui/material';

const FreeOnLGChannelsShelfBatch = () => {
  const [info, setInfo] = useState({
    label: '#Current Time',
    value: new Date().toISOString().replace('T', ' ').substring(0, 19),
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setInfo(prevState => ({
        ...prevState,
        value: new Date().toISOString().replace('T', ' ').substring(0, 19),
      }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <React.Fragment>
      <ListItem className="list-item" key={1}>
        <Typography className="label">{info.label}</Typography>
        <Typography className="value">{info.value}</Typography>
      </ListItem>
      <Divider className="divider" />
    </React.Fragment>
  );
};
export default FreeOnLGChannelsShelfBatch;
