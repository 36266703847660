import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import SearchField from 'components/component/BasicSearchField';
import Pagination from 'components/component/BasicPagination';
import CustomImage from 'components/component/BasicImage';
import SelectBox from 'components/component/BasicSelectBox';
import CheckBox from 'components/component/BasicCheckBox';
import TextField from 'components/component/BasicTextField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import TextLink from 'components/component/BasicTextLink';
import Tooltip from 'components/component/BasicTooltip';
import { getContentType, getCPDetailsByCountry, getGenre, getOrganizeContent, getOrganizeResult, saveOrganizeResult } from '../ANTestService/api/restApi';

import { styled } from '@mui/material/styles';

import {
  Box,
  Typography,
  Link,
  Grid,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// ICON
import { BtnArrowRight } from 'components/component/BasicIcon';

// Data
import {
  SegOrgResultTh,
  SegOrgScheduledTh
} from 'pages/Organization/ANTestService/data/SegmentOrganizeData';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import { RefreshIcon } from 'components/component/BasicIcon';
import { useNavigate } from 'react-router-dom';
import BasicDialog from 'components/component/BasicDialog';
import BasicSnackbar from 'components/component/BasicSnackbar';
import PopChannelDetail from 'pages/Common/PopChannelDetail';
import PopSeriesDetail from 'pages/Common/PopSeriesDetail';


const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line

// 메인 컨텐츠
function ANTestServiceSegmentOrganizeOrganize(props) {
  const navigate = useNavigate();
  const { message, type } = props;
  const { selectedRow, segmentIdx, mainFilteringData, selectedSegment, firstSearch } = message ?? {};
  const [isFirstSearch, setIsFirstSearch] = useState(firstSearch);
  const [actionDialog, setActionDialog] = useState("");
  const [showSnackBar, setShowSnackBar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  // Detail Popup
  const [currentContentId, setCurrentContentId] = useState('');     // channel id or vod id
  const [channelDetailOpen, setChannelDetailOpen] = useState(false);
  const [vodDetailOpen, setVodDetailOpen] = useState(false);

  const segmentBInfo = [...selectedSegment.bSegmentInfo];

  const [organizeContent, setOrganizeContent] = useState({
    data: [],
    total: 0,
  });
  const [organizeResult, setOrganizeResult] = useState({
    data: [],
    total: 0,
  });
  const [existingOrganizeResult, setExistingOrganizeResult] = useState({
    data: [],
    total: 0,
  });
  const [originalOrganizeContent, setOriginalOrganizeContent] = useState(organizeContent);
  const [originalOrganizeResult, setOriginalOrganizeResult] = useState(organizeResult);
  const [checkedOrganizeResult, setCheckedOrganizeResult] = useState({
    data: [],
    total: 0,
  });
  const [checkedOrganizeContent, setCheckedOrganizeContent] = useState({
    data: [],
    total: 0,
  });
  const [resultModified, setResultModified] = useState('false')
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: type === 'antest' ? 'AN Test' : 'AN Service',
    normalMenu: ['Segment', 'Segment Organize', 'Organize'],
  };

  const infos = [
    {
      index: 1,
      label: '#Recommendation Content',
      value: segmentBInfo && segmentBInfo.length > 0 ? segmentBInfo[0].recommendationContents : '',
      divide: true,
    },
    {
      index: 2,
      label: '#Rate',
      value: selectedSegment.sampleBRate,
      divide: true,
    },
  ];

  // 테이블 내림, 오름차순
  const handleRequestSortContent = (property) => {
    const isAsc = params.order === property && params.order_type === "asc";
    setParams((prevState) => ({
      ...prevState,
      order: property,
      order_type: isAsc ? "desc" : "asc"
    }));
  };

  const handleRequestSortResult = (property) => {
    const isAsc = paramsRes.order === property && paramsRes.order_type === "asc";
    setParamsRes((prevState) => ({
      ...prevState,
      order: property,
      order_type: isAsc ? "desc" : "asc"
    }));
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  // Table Body Data
  const [segOrgTypeOption, setSegOrgTypeOption] = React.useState([]);
  const [segOrgCpNameOption, setSegOrgCpNameOption] = React.useState([]);
  const [segOrgGenreOption, setSegOrgGenreOption] = React.useState([]);
  const [selectedType, setSelectedType] = useState();
  const [selectedCp, setSelectedCp] = useState('All');
  const [selectedGenre, setSelectedGenre] = useState();
  const [id, setId] = useState('');
  const [name, setName] = useState('');

  const [isAllowMove, setIsAllowMove] = useState(false);
  // Draggable Table
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index) return;
    const updatedOrganizeData = [...organizeResult.data];
    const [removedRow] = updatedOrganizeData.splice(source.index, 1);
    updatedOrganizeData.splice(destination.index, 0, removedRow);
    const reorderedData = updatedOrganizeData.map((item, index) => ({
      ...item,
      resultOrder: index + 1,
    }));
    setOrganizeResult((prev) => ({
      ...prev,
      data: reorderedData,
    }));
    setOriginalOrganizeResult((prev) => ({
      ...prev,
      data: reorderedData,
    }));
  };

  const handelSetShowSnackBar = ({ open, message, severity }) => {
    setShowSnackBar({
      open,
      message,
      severity,
    });
    setParams({ ...params });
    setParamsRes({ ...paramsRes });
  };

  const [showDialog, setShowDialog] = useState({
    open: false,
    title: '',
    content: '',
    button: [],
  });

  const handleSnackbarClose = () => {
    setShowSnackBar({ open: false, message: "", severity: "" });
  };

  useEffect(() => {
    const fetchContentTypeDetails = async () => {
      const segmentTarget = segmentBInfo[0]?.segmentTarget;
      const segmentGroup = segmentBInfo[0]?.segmentGroup;
      const contentType = await getContentType(selectedRow, segmentTarget, segmentGroup, type);
      const uniqueType = Array.from(new Set(contentType.data.map(data => data.feed)))
        .map(feed => contentType.data.find(data => data.feed === feed))
        .sort((a, b) => a.feed.localeCompare(b.feed));
      const types = uniqueType.map(feed => ({
        id: feed,
        value: feed,
        option: feed,
        type: 'text'
      }));
      setSegOrgTypeOption(types);
      setSelectedType(types[0]?.value);
    };
    fetchContentTypeDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchCPDetails = async () => {
      const cpData = await getCPDetailsByCountry(selectedRow.countryCode, type);
      const uniqueCp = Array.from(new Set(cpData.data.map(data => data.cpName)))
        .map(cpName => cpData.data.find(data => data.cpName === cpName))
        .sort((a, b) => a.cpName.localeCompare(b.cpName));
      const cpNames = uniqueCp.map(feed => ({
        id: feed.cpName,
        value: feed.cpName,
        option: feed.cpName,
        type: 'text'
      }));
      const allOption = { id: 'All', value: 'All', option: 'All', type: 'text' };
      setSegOrgCpNameOption([allOption, ...cpNames]);
      setSelectedCp('All');
    };
    fetchCPDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedType && selectedCp) {

      const fetchGenreDetails = async () => {
        const genreData = await getGenre(selectedRow, selectedType, selectedCp, type);

        const uniqueGenres = Array.from(new Set(genreData.data.map(data => data.genreName)))
          .map(genreName => genreData.data.find(data => data.genreName === genreName))
          .sort((a, b) => a.genreName.localeCompare(b.genreName));

        const genre = uniqueGenres.map(data => ({
          id: data.genreName,
          value: data.genreName,
          option: data.genreName,
          type: 'text'
        }));
        const allOption = { id: 'All', value: 'All', option: 'All', type: 'text' };
        setSegOrgGenreOption([allOption, ...genre]);
        const targetGenre = segmentBInfo[0].segmentTarget === 'ALL' ? 'all' : segmentBInfo[0].segmentTarget;
        const matchedGenre = genre.find(g => g.value === targetGenre);
        setSelectedGenre(matchedGenre ? matchedGenre.value : genre[0]?.value);
      };

      fetchGenreDetails();
    }
    // eslint-disable-next-line
  }, [selectedType, selectedCp]);


  useEffect(() => {
    getOrganizeResultData(selectedRow);
    // eslint-disable-next-line
  }, [selectedRow]);

  useEffect(() => {
    if (isFirstSearch && selectedType && selectedGenre) {
      onClickSearch();
      setIsFirstSearch(false);
    }
    // eslint-disable-next-line
  }, [selectedType, selectedCp, selectedGenre]);

  useEffect(() => {
    const checkDifference = () => {

      if (originalOrganizeResult.data.length !== existingOrganizeResult.data.length) {
        setResultModified(true);
        return;
      }

      const compareItems = (item1, item2) => {
        return (
          item1.id === item2.id &&
          item1.cpName === item2.cpName &&
          item1.genre === item2.genre &&
          item1.type === item2.type &&
          item1.contentName === item2.contentName &&
          item1.resultOrder === item2.resultOrder &&
          item1.image === item2.image
        );
      };

      const isEqual = originalOrganizeResult.data.every((item, index) =>
        compareItems(item, existingOrganizeResult.data[index])
      );
      setResultModified(!isEqual);
    };
    checkDifference();
  }, [existingOrganizeResult, originalOrganizeResult]);


  const onChangeType = (value) => {
    setSelectedType(value);
  }

  const onChangeCp = (value) => {
    setSelectedCp(value);
  }

  const onChangeGenre = (value) => {
    setSelectedGenre(value);
  }

  const onClickSelectedInput = () => {
    let startOrder =
      Math.max(...organizeResult.data.map((n) => n.resultOrder)) + 1;
    startOrder = startOrder === -Infinity ? 1 : startOrder;
    const selectedItems = organizeContent.data
      .filter((item) => checkedOrganizeContent.includes(item.id))
      .map((item, index) => ({
        ...item,
        resultOrder: startOrder + index,
        type: selectedType,
      }));
    setOrganizeResult((prev) => ({
      ...prev,
      data: [...prev.data, ...selectedItems],
      total: (prev.total || 0) + selectedItems.length,
    }));
    setOriginalOrganizeResult((prev) => ({
      ...prev,
      data: [...prev.data, ...selectedItems],
      total: (prev.total || 0) + selectedItems.length,
    }));
    setCheckedOrganizeContent([]);
  };

  const onChangeSearchOrganizeContent = (event) => {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey === "") {
      setOrganizeContent(originalOrganizeContent);
      return;
    }
    const filteredData = organizeContent.data.filter((item) => {
      return Object.entries(item)
        .filter(([key, value]) => key !== "image")
        .values(item)
        .some((value) => String(value).toLowerCase().includes(searchKey));
    });

    setOrganizeContent((prev) => ({
      ...prev,
      data: filteredData,
      total: filteredData.length,
    }));
  };

  const onChangeSearchOrganizeResult = (event) => {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey === "") {
      setOrganizeResult(originalOrganizeResult);
      return;
    }
    const filteredData = organizeResult.data.filter((item) => {
      return Object.entries(item)
        .filter(([key, value]) => key !== "image")
        .values(item)
        .some((value) => String(value).toLowerCase().includes(searchKey));
    });
    setOrganizeResult((prev) => ({
      ...prev,
      data: filteredData,
      total: filteredData.length,
    }));
  };

  const onDeleteOKBtn = async () => {
    if (
      !Array.isArray(checkedOrganizeResult) ||
      checkedOrganizeResult.length === 0
    ) {
      return;
    }
    const updatedOrganizeResults = organizeResult.data.filter(
      (item) => !checkedOrganizeResult.includes(item.id)
    );
    const selectedItems = organizeResult.data.filter((item) =>
      checkedOrganizeResult.includes(item.id)
    );
    updatedOrganizeResults.forEach((item, idx) => item.resultOrder = (idx + 1));
    setOrganizeResult((prev) => ({
      ...prev,
      data: updatedOrganizeResults,
      total: updatedOrganizeResults.length || 0,
    }));
    setOrganizeContent((prev) => {
      const existingIds = prev.data.map((item) => item.id);
      const nonDuplicateItems = selectedItems.filter(
        (item) => !existingIds.includes(item.id)
      );
      const newData = [...prev.data, ...nonDuplicateItems];

      return {
        ...prev,
        data: newData,
        total: newData.length || 0,
      };
    });
    const updatedOrganizeResult = originalOrganizeResult.data.filter(
      (item) => !checkedOrganizeResult.includes(item.id)
    );

    setOriginalOrganizeResult((prev) => ({
      ...prev,
      data: updatedOrganizeResult,
      total: updatedOrganizeResult.length,
    }));
    setCheckedOrganizeResult([]);
    setActionDialog("");
    setShowSnackBar({
      open: true,
      message: "Successfully Deleted Selected Categories!",
      severity: "success",
    });
  };

  const onActionCancelBtn = () => {
    setActionDialog("");
  };

  const onSaveOKBtn = () => {
    onClickSaveButton();
  };

  const onClickSaveButton = async () => {
    const organizeResultData = { ...originalOrganizeResult };
    const result = organizeResultData.data.map((item, index) => ({
      resultOrder: index + 1,
      contentType: item.type,
      contentId: item.id,
      providerIdx: item.cp_idx,
      image: item.image,
      genre: item.genre,
      countryCode: selectedRow.countryCode
    }));
    const res = await saveOrganizeResult(selectedRow, result, segmentIdx, type);
    setActionDialog("");
    handelSetShowSnackBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    getOrganizeResultData(selectedRow, params, segmentIdx);
  };

  const onClickReset = () => {
    setSelectedType(segOrgTypeOption[0]?.value);
    setSelectedCp(segOrgCpNameOption[0]?.value);
    setSelectedGenre(segOrgGenreOption[0]?.value);
    setId('');
    setName('');
  }

  const onClickSearch = () => {
    getOrganizeContentData();
  }

  const getOrganizeContentData = async () => {
    const res = await getOrganizeContent({
      ...params,
      country: selectedRow.countryCode,
      type: selectedType,
      cp: selectedCp,
      genre: selectedGenre,
      id: id,
      name: name
    }, type);
    setCheckedOrganizeContent([]);
    setOrganizeContent(res);
    setOriginalOrganizeContent(res)
    setParams({
      ...params,
      page: 1,
    });
  };

  const handlePaginationChange = ({ type, value }) => {
    if (type === "page") {
      setParams((prevState) => ({
        ...prevState,
        page: value,
      }));
    } else if (type === "size") {
      setParams((prevState) => ({
        ...prevState,
        page: 1,
        size: parseInt(value),
      }));
    }
  };

  const handlePaginationChangeRes = ({ type, value }) => {
    if (type === "page") {
      setParamsRes((prevState) => ({
        ...prevState,
        page: value,
      }));
    } else if (type === "size") {
      setParamsRes((prevState) => ({
        ...prevState,
        page: 1,
        size: parseInt(value),
      }));
    }
  };

  const FilterButtons = [
    {
      color: 'tertiary',
      type: 'outline icon',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      autosize: false,
      minWidth: '107px',
      onClick: onClickReset
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      //Icon: null,
      //iconPos: undefined,
      autosize: false,
      //minWidth: '120px',
      onClick: onClickSearch
    },
  ];

  const Buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Top',
      autosize: true,
      onClick: () => onClickTopBottom("Top")
    },
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Bottom',
      autosize: true,
      onClick: () => onClickTopBottom("Bottom")
    },
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      autosize: true,
      disabled: checkedOrganizeResult.length === 0,
      onClick: () => setActionDialog("Delete")
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Save',
      autosize: true,
      disabled: !resultModified,
      onClick: () => setActionDialog("Save")
    },
  ];

  const deleteActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onDeleteOKBtn,
    },
  ];

  const saveActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onSaveOKBtn,
    },
  ];

  const [params, setParams] = useState({
    order_type: "asc",
    order: "cpName",
    country: selectedRow.countryCode,
    type: selectedType,
    cp: selectedCp,
    genre: selectedGenre,
    id: id,
    name: name,
    page: 1,
    size: 10,
  });

  const [paramsRes, setParamsRes] = useState({
    order_type: "asc",
    order: "areaOrder",
    country: selectedRow.countryCode,
    type: selectedType,
    cp: selectedCp,
    id: "",
    name: "",
    page: 1,
    size: 10,
  });

  const handleCheckBoxContent = (event) => {
    let idxs = [];
    if (event.target.checked && event.target.name === "select-all") {
      idxs = organizeContent.data
        .filter(row => !resultIds.has(row.id))  // filter content in "Selected Content"
        .map(item => item.id);
    } else if (!event.target.checked && event.target.name === "select-all") {
      idxs = [];
    } else if (event.target.checked) {
      idxs = [...checkedOrganizeContent, event.target.name];
    } else if (!event.target.checked) {
      idxs = checkedOrganizeContent.filter((row) => row !== event.target.name);
    }
    setCheckedOrganizeContent(idxs);
  };

  const handleCheckBoxResult = (event) => {
    let idxs = [];
    if (event.target.checked && event.target.name === "select-all") {
      idxs = organizeResult.data.map((row) => row.id);
    } else if (!event.target.checked && event.target.name === "select-all") {
      idxs = [];
    } else if (event.target.checked) {
      idxs = [...checkedOrganizeResult, event.target.name];
    } else if (!event.target.checked) {
      idxs = checkedOrganizeResult.filter(
        (row) => row !== event.target.name
      );
    }
    setCheckedOrganizeResult(idxs);
  };;

  const getOrganizeResultData = async (params) => {
    const res = await getOrganizeResult(selectedRow, segmentIdx, type);
    setCheckedOrganizeResult([]);
    setOrganizeResult(res);
    setOriginalOrganizeResult(res);
    setExistingOrganizeResult(res);
  };

  const handleDialogClose = () => {
    setShowDialog({
      open: false,
      title: '',
      content: '',
      button: [],
    });
  }

  let sortedContentTdRows = sortRows([...organizeContent.data], params.order, params.order_type);
  const count = Math.ceil(organizeContent.total / params.size);
  const total = organizeContent.total || 0;
  const pageIndex = params.page > 1 ? (params?.page - 1) * params?.size : 0;
  const sizeIndex = params?.size || 10;
  sortedContentTdRows = sortedContentTdRows.splice(pageIndex, sizeIndex);

  let sortedResultTdRows = sortRows([...organizeResult.data], paramsRes.order, paramsRes.order_type);
  const countRes = Math.ceil(organizeResult.total / paramsRes.size);
  const totalRes = organizeResult.total || 0;
  const pageIndexRes = paramsRes.page > 1 ? (paramsRes?.page - 1) * paramsRes?.size : 0;
  const sizeIndexRes = paramsRes?.size || 10;
  sortedResultTdRows = sortedResultTdRows.splice(pageIndexRes, sizeIndexRes);

  const resultIds = new Set(organizeResult.data.map(resultRow => resultRow.id));
  const contentCountExceptSelectedContent = organizeContent.data.reduce((acc, row) => {
    return resultIds.has(row.id) ? acc : acc + 1;
  }, 0);

  const onClickTopBottom = (label) => {
    const checked = organizeResult.data.filter((item) =>
      checkedOrganizeResult.includes(item.id)
    );

    const unchecked = organizeResult.data.filter(
      (item) => !checkedOrganizeResult.includes(item.id)
    );

    let reorderedList;
    if (label === "Top") {
      reorderedList = [...checked, ...unchecked];
    } else {
      // Bottom
      reorderedList = [...unchecked, ...checked];
    }

    reorderedList.forEach((item, index) => {
      item.resultOrder = index + 1;
    });
    setOrganizeResult((prev) => ({
      ...prev,
      data: reorderedList,
    }));
    setOriginalOrganizeResult((prev) => ({
      ...prev,
      data: reorderedList,
    }));
  };

  const isSortedByResultOrder = (arr) => {
    for (let i = 1; i < arr.length; i++) {
      if (arr[i - 1].resultOrder > arr[i].resultOrder) {
        return false;
      }
    }
    return true;
  }

  const handleItemClick = (event, contentId) => {
    event.preventDefault();
    setCurrentContentId(contentId);

    if (selectedType === 'live') {  // channel (live)
      setVodDetailOpen(false);
      setChannelDetailOpen(true);
    } else {  // vod (tvshow, movie)
      setChannelDetailOpen(false);
      setVodDetailOpen(true);
    }
  }

  useEffect(() => {
    if (selectedRow.status === "ready" && isSortedByResultOrder(sortedResultTdRows)) {
      setIsAllowMove(true);
    } else {
      setIsAllowMove(false);
    }
  }, [selectedRow.status, sortedResultTdRows]);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='#'
                onClick={(e) => {
                  e.preventDefault();
                  if (resultModified) {
                    setShowDialog({
                      open: true,
                      title: 'Organize Segment',
                      content: <div>Do you want to navigate away from this page?<br />Unsaved changes may be lost.</div>,
                      button: [
                        {
                          color: 'tertiary',
                          type: 'outline',
                          size: 'medium',
                          label: 'Cancel',
                          isIcon: false,
                          Icon: null,
                          iconPos: undefined,
                          minWidth: 85,
                          disabled: false,
                          onClick: handleDialogClose,
                        },
                        {
                          color: 'primary',
                          type: 'box',
                          size: 'medium',
                          label: 'OK',
                          isIcon: false,
                          Icon: null,
                          iconPos: undefined,
                          minWidth: 103,
                          disabled: false,
                          onClick: () => {
                            navigate(type === 'antest' ? '/ANTest' : '/ANService', {
                              replace: true, state: {
                                move: true,
                                targetComponent: 'ANTestServiceSegmentOrganizeAddGroup',
                                message: {
                                  selectedTestService: selectedRow,  // selected antest
                                  segmentIdx: segmentIdx,
                                  mainFilteringData: mainFilteringData,
                                  selectedSegment: selectedSegment.selectedSegment
                                }
                              }
                            });
                          }
                        }
                      ],
                    });
                  } else {
                    navigate(type === 'antest' ? '/ANTest' : '/ANService', {
                      replace: true, state: {
                        move: true,
                        targetComponent: 'ANTestServiceSegmentOrganizeAddGroup',
                        message: {
                          selectedTestService: selectedRow,  // selected antest
                          segmentIdx: segmentIdx,
                          mainFilteringData: mainFilteringData,
                          selectedSegment: selectedSegment.selectedSegment
                        }
                      }
                    });
                  }
                }}
              >
                Organize
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          <Box className='group-title-area'>
            <Typography className='group-title'>B Group</Typography>
          </Box>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='orgSeg-Type'
                      size='large'
                      shrink={true}
                      label='Type'
                      require={false}
                      selectOptions={segOrgTypeOption}
                      disabled={false}
                      placeholder=''
                      selected={selectedType}
                      onChange={onChangeType}
                    />
                  </Box>
                  {/* CP Name */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='orgSeg-cp-name'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      require={false}
                      selectOptions={segOrgCpNameOption}
                      disabled={false}
                      placeholder=''
                      selected={selectedCp}
                      onChange={onChangeCp}
                    />
                  </Box>
                  {/* Genre */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='orgSeg-Genre'
                      size='large'
                      shrink={true}
                      label='Genre'
                      require={false}
                      selectOptions={segOrgGenreOption}
                      disabled={false}
                      placeholder=''
                      selected={selectedGenre}
                      onChange={onChangeGenre}
                    />
                  </Box>
                  {/* ID */}
                  <Box component='div' className='col'>
                    <TextField
                      id='orgSeg-id-1'
                      type='outlined'
                      size='large'
                      required
                      placeholder='ID'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                    />
                  </Box>
                  {/* Name */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      id='orgSeg-name-1'
                      type='outlined'
                      size='large'
                      required
                      placeholder='Name'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'>
                <CustomButtonGroup buttons={FilterButtons} />
              </Box>
            </Box>
          </Box>

          <Grid container columnSpacing={2.5} className='content-pack-area'>
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Result for Content</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {total}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label='Ch.no or ch.Name'
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                        onChange={onChangeSearchOrganizeContent}
                      />
                    </Box>
                    {selectedRow.status === 'ready' &&
                      <Box className='right-area'>
                        <Button
                          color='tertiary'
                          type='box'
                          size='small'
                          label='Select Ch Input'
                          isIcon={true}
                          autosize
                          Icon={BtnArrowRight}
                          onClick={onClickSelectedInput}
                          iconPos='right'
                          disabled={checkedOrganizeContent.length === 0}
                        />
                      </Box>
                    }
                  </Box>

                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader sx={{ width: 'auto' }}>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {SegOrgResultTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        checked={checkedOrganizeContent.length !== 0 && contentCountExceptSelectedContent === checkedOrganizeContent.length}
                                        onChange={handleCheckBoxContent}
                                        indeterminate={checkedOrganizeContent.length !== 0 && organizeContent.total > checkedOrganizeContent.length}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '24px' }}
                                      />
                                    </Box>
                                    {column.sortable && <TableSortLabel
                                      active={params.order === column.id}
                                      direction={params.order === column.id ? params.order_type : 'desc'}
                                      onClick={() => handleRequestSortContent(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>}
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={params.order === column.id}
                                        direction={params.order === column.id ? params.order_type : 'desc'}
                                        onClick={() => handleRequestSortContent(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {sortedContentTdRows.map((td, index) => {
                            const isInSelectedContentList = organizeResult.data.some(contentItem => contentItem.id === td.id);
                            return (
                              <TableRow className='tr' key={td.id || index}
                                style={{
                                  pointerEvents: isInSelectedContentList ? 'none' : 'auto',
                                }}
                              >
                                {SegOrgResultTh.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align} sx={{ height: '88px' }}>
                                    {colIndex === 0 ? (
                                      <CheckBox
                                        label={td[`cpName`]}
                                        value={td[`id`]}
                                        onChange={handleCheckBoxContent}
                                        checked={checkedOrganizeContent.indexOf(td['id']) > -1}
                                        disabled={isInSelectedContentList}
                                      />
                                    ) : colIndex === 1 ? (
                                      <TextLink
                                        href='#'
                                        text={td[`id`]}
                                        onClick={(event) => handleItemClick(event, td.id)}
                                      />
                                    ) : colIndex === 3 ? (
                                      <CustomImage
                                        src={td[`image`]}
                                        fit={false}
                                        alt=''
                                        wrapWidth='90px'
                                        wrapHeight='64px'
                                        rounded
                                      />
                                    ) : (
                                      td[column.id]
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>
                  <Pagination id='pagination-01' count={count} selected={params.size} onChangeHandle={handlePaginationChange} />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Scheduled Content List</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {totalRes}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label='Ch.no or ch.Name'
                        size='medium'
                        maxWidth='230px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                        onChange={onChangeSearchOrganizeResult}
                      />
                    </Box>
                    {selectedRow.status === 'ready' &&
                      <Box className='form-area'>
                        <CustomButtonGroup buttons={Buttons} justifyContent='center' />
                      </Box>
                    }
                  </Box>

                  {/* Selected Content */}

                  <Box className='content-inner-wrap '>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table'>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {SegOrgScheduledTh.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box display='flex' alignContent='center'>
                                          <IconDraggable className='icon-draggable' sx={{ flexShrink: 0 }} />
                                          <CheckBox
                                            label=''
                                            value='select-all'
                                            checked={checkedOrganizeResult.length !== 0 && organizeResult.total === checkedOrganizeResult.length}
                                            onChange={handleCheckBoxResult}
                                            indeterminate={checkedOrganizeResult.length !== 0 && organizeResult.total > checkedOrganizeResult.length}
                                            inputProps={{
                                              'aria-label': 'select all',
                                            }}
                                            sx={{ marginRight: '4px' }}
                                          />
                                          <TableSortLabel
                                            active={paramsRes.order === column.id}
                                            direction={paramsRes.order === column.id ? paramsRes.order_type : 'desc'}
                                            onClick={() => handleRequestSortResult(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={paramsRes.order === column.id}
                                              direction={paramsRes.order === column.id ? paramsRes.order_type : 'desc'}
                                              onClick={() => handleRequestSortResult(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}
                              <TableBody className='tbody'>
                                {sortedResultTdRows.map((td, index) => (
                                  <Draggable
                                    key={td.id || index}
                                    draggableId={td.id || index.toString()}
                                    index={index}
                                    isDragDisabled={isAllowMove ? false : true}
                                  >
                                    {(provided) => (
                                      <TableRow
                                        className='tr'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {SegOrgScheduledTh.map((column, colIndex) => (
                                          <TableCell
                                            key={column.id}
                                            className='td'
                                            align={column.align}
                                            sx={{ height: '88px' }}
                                          >
                                            {colIndex === 0 ? (
                                              <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                <Tooltip
                                                  title={isAllowMove ? '' : selectedRow.status !== "ready" ? 'You can only change the order when the Status is Ready.' : 'You can only change the order when the sorting is in ascending order.'}
                                                  placement='top'
                                                >
                                                  <IconDraggable
                                                    className='icon-draggable'
                                                    disabled={isAllowMove ? false : true}
                                                  />
                                                </Tooltip>

                                                <CheckBox
                                                  onChange={handleCheckBoxResult}
                                                  label={td.resultOrder}
                                                  value={td[`id`]}
                                                  checked={checkedOrganizeResult.indexOf(td['id']) > -1}
                                                  defaultChecked={false}
                                                />
                                              </Box>
                                            ) : colIndex === 3 ? (
                                              <TextLink
                                                href='#'
                                                text={td[`id`]}
                                                onClick={(event) => handleItemClick(event, td.id)}
                                              />
                                            ) : colIndex === 5 ? (
                                              <CustomImage
                                                src={td[`image`]}
                                                fit={false}
                                                alt=''
                                                wrapWidth='69px'
                                                wrapHeight='64px'
                                                rounded
                                              />
                                            ) : (
                                              td[column.id]
                                            )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>

                    {/* Pagination */}
                    <Pagination id='pagination-01' count={countRes} selected={paramsRes.size} onChangeHandle={handlePaginationChangeRes} />
                    <div>
                      {(actionDialog === 'Delete' || actionDialog === 'Save') && <BasicDialog
                        title={actionDialog === 'Delete' ? 'Delete Result' : actionDialog === 'Save' ? 'Save Result' : ''}
                        contentTitle={actionDialog === 'Delete' ? ' Are you sure to delete?' : actionDialog === 'Save' ? ' Are you sure to save?' : ''}
                        size={'small'}
                        closeX={true}
                        open={true}
                        handleClose={() => setActionDialog('')}
                        buttons={actionDialog === 'Delete' ? deleteActionButtons : actionDialog === 'Save' ? saveActionButtons : []}
                      />}
                    </div>
                    <div>
                      <BasicSnackbar onClose={handleSnackbarClose} severity={showSnackBar.severity} message={showSnackBar.message} open={showSnackBar.open} duration={1000} />
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {channelDetailOpen && <PopChannelDetail channelId={currentContentId} open={channelDetailOpen} close={() => setChannelDetailOpen(false)} />}
      {vodDetailOpen && <PopSeriesDetail vodId={currentContentId} open={vodDetailOpen} close={() => setVodDetailOpen(false)} />}
      <BasicDialog
        title={showDialog.title}
        contentTitle={showDialog.content}
        size={'small'}
        closeX={true}
        open={showDialog.open}
        handleClose={handleDialogClose}
        buttons={showDialog.button}
      />
    </Box>
  );
}

export default ANTestServiceSegmentOrganizeOrganize;
