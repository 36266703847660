import React, { useState, useEffect } from 'react';
import BasicSwitches from 'components/component/BasicSwitch';
import SortLabel from 'components/component/BasicTableSortLabel';
import CustomImage from 'components/component/BasicImage';
import CheckBox from 'components/component/BasicCheckBox';
import Dialog from 'components/component/BasicDialog';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// Data
import { defaultPopupType, defaultButton, CarouselResultHeader } from 'pages/Organization/TabCategory/Carousel/DefaultData';
import ResultImageUpload from 'pages/Organization/TabCategory/Carousel/ResultImageUpload';
import ResultTextUpload from 'pages/Organization/TabCategory/Carousel/ResultTextUpload';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import { isEmpty } from 'utils/Valid';
//
import { postRequest } from 'utils/Request';

// Styled
const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function CarouselResultList({ carouselTab, carouselCategory, carousel, carouselCountry, resetResult, carouselResultList, editCarouselResultList, refreshResult }) {
  const [resultList, setResultList] = useState([]);
  const [resultCheckedList, setResultCheckedList] = useState([]);
  const [resultAllChecked, setResultAllChecked] = useState(false);
  // TODO
  //const [searchField, setSearchField] = useState('');
  const [resultRemoveList, setResultRemoveList] = useState([]);
  const [popupType, setPopupType] = useState(defaultPopupType);
  const [resultImageView, setResultImageView] = useState({});
  const [resultTextView, setResultTextView] = useState({});

  useEffect(() => {
    setResultList(carouselResultList);
    // eslint-disable-next-line
  }, [carouselResultList]);

  const getResetResult = () => {
    if (resetResult) {
      resetResult();
    }
  };

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from([...resultList]);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.forEach((item, index) => {
      item.resultOrder = index + 1;
      item.checked = false;
    });
    editCarouselResultList([...items]);
  };

  const manualHandler = (row, value) => {
    if (row.carouselType === 'more' || row.carouselType === 'notification' || row.carouselType === 'event') {
      alert('Content of this type cannot be unlocked. ex) more, event, notification');
      return;
    }
    let updateList = [...resultList];
    updateList.forEach((element) => {
      if (element.contentId === row.contentId) {
        element.manualFlag = value ? 'Y' : 'N';
      }
    });
    editCarouselResultList(updateList);
  };

  /*
  // TODO
  const searchFieldHandler = (searchText) => {
    setSearchField(searchText);
    let rows = [];
    if (!isEmpty(resultList)) {
      rows = [...resultList];
      if (!isEmpty(searchText)) {
        searchText = searchText.toString().toLowerCase();
        rows = rows.filter((search) => isIncludeSearchText(search, searchText));
      }
    }
    // init filter
    setResultCheckedList([]);
    setResultAllChecked(false);
  };
  // TODO
  const isIncludeSearchText = (row, searchText) => {
    // let isResultOrder = false;
    // let isCarouselType = false;
    // let isContentType = false;
    // let isContentId = false;
    // let isContentName = false;
    // if (!isEmpty(row.contentId)) {
    //   isContentId = row.contentId.toString().toLowerCase().indexOf(searchText) > -1;
    // }
    // if (!isEmpty(row.contentName)) {
    //   isContentName = row.contentName.toString().toLowerCase().indexOf(searchText) > -1;
    // }
    // if (!isEmpty(row.contentName)) {
    //   isChannelMap = row.contentName.toString().toLowerCase().indexOf(searchText) > -1;
    // }
    // if (!isEmpty(row.channelMap)) {
    //   isTabName = row.channelMap.toString().toLowerCase().indexOf(searchText) > -1;
    // }
    // if (!isEmpty(row.providerId)) {
    //   isProviderId = row.providerId.toString().toLowerCase().indexOf(searchText) > -1;
    // }
    // return isContentId || isContentName || isChannelMap || isTabName || isProviderId;
  };
  */
  const isResultAllChecked = (e) => {
    if (!isEmpty(resultList)) {
      let checkedList = [];
      let checked = e.target.checked;
      if (checked) {
        checkedList = resultList.filter((f) => f.manualFlag === 'Y').map((m) => m.contentId);
        if (!isEmpty(checkedList)) {
          setResultCheckedList(checkedList);
        } else {
          checkedList = [];
          checked = false;
        }
      }
      setResultCheckedList(checkedList);
      setResultAllChecked(checked);
    }
  };

  const isResultChecked = (contentId) => {
    if (isEmpty(resultCheckedList)) {
      return false;
    } else {
      return resultCheckedList.includes(contentId);
    }
  };

  const setResultChecked = (e) => {
    let checkList = [];
    if (!isEmpty(resultCheckedList)) {
      checkList = [...resultCheckedList];
    }
    if (e.target.checked) {
      checkList.push(e.target.value);
    } else {
      checkList = checkList.filter((f) => f !== e.target.value);
    }
    let allCheck = false;
    setResultCheckedList(checkList);
    if (!isEmpty(checkList) && !isEmpty(resultList)) {
      if (checkList.length === resultList.length) {
        allCheck = true;
      }
    }
    setResultAllChecked(allCheck);
  };

  const resultRemove = () => {
    if (isEmpty(resultCheckedList)) {
      closeDialog();
    }

    let savedList = [...resultList];
    let excludedList = savedList.filter((f) => !resultCheckedList.includes(f.contentId));

    editCarouselResultList(excludedList);
    let removeList = savedList.filter((f) => resultCheckedList.includes(f.contentId));
    if (!isEmpty(removeList)) {
      removeList.forEach((f) => {
        f.editAction = 'removed';
      });
    }
    if (!isEmpty(resultRemoveList)) {
      removeList = [...resultRemoveList, ...removeList];
    }
    setResultRemoveList(isEmpty(removeList) ? [] : removeList);
    setResultCheckedList([]);
    setResultAllChecked(false);
    closeDialog();
  };

  const setResultImage = (contentId, horizontalImage, customHorizontalImagePath, verticalImage, customVerticalImagePath) => {
    if (!isEmpty(customHorizontalImagePath) || !isEmpty(customVerticalImagePath)) {
      let list = [...resultList];
      list.forEach((f) => {
        if (f.contentId === contentId) {
          if (!isEmpty(customHorizontalImagePath)) {
            f.image = horizontalImage;
            f.customHorizontalImage = customHorizontalImagePath;
          }
          if (!isEmpty(customVerticalImagePath)) {
            f.verticalImage = verticalImage;
            f.customVerticalImage = customVerticalImagePath;
          }
          if (!isEmpty(customHorizontalImagePath) || !isEmpty(customVerticalImagePath)) {
            if (f.editAction !== 'added') {
              f.editAction = 'update';
            }
          }
        }
      });
      // check box init
      editCarouselResultList([...list]);
    }
    setResultCheckedList([]);
  };

  const setResultText = (contentId, title, description) => {
    let list = [...resultList];
    list.forEach((f) => {
      if (f.contentId === contentId) {
        f.resultTitle = title;
        f.resultDescription = description;
      }
    });
    editCarouselResultList([...list]);
  };

  const textUploadHandler = () => {
    if (resultCheckedList.length !== 1) {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Text',
        content: 'You can select only one content to upload an text. !',
      });
      return;
    }
    let displayList = [...resultList];
    let result = displayList.find((f) => f.contentId === resultCheckedList[0]);
    if (result.manualFlag !== 'Y') {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Text',
        content: 'Only fixed content can be uploaded. !',
      });
      return;
    }
    if (result.carouselType !== 'hub') {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Text',
        content: 'Can only be written as type [Hub]. !',
      });
      return;
    }
    setResultTextView(result);
    setPopupType({ ...defaultPopupType, text: true });
  };

  const imageUploadHandler = () => {
    if (resultCheckedList.length !== 1) {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Image',
        content: 'You can select only one content to upload an image. !',
      });
      return;
    }

    let displayList = [...resultList];
    let result = displayList.find((f) => f.contentId === resultCheckedList[0]);
    if (result.manualFlag !== 'Y') {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Image',
        content: 'Only fixed content can be uploaded. !',
      });
      return;
    }
    if (result.carouselType === 'event') {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Image',
        content: 'The event cannot register an image. !',
      });
      return;
    }
    setResultImageView(result);
    setPopupType({ ...defaultPopupType, view: true });
  };

  const deleteHandler = () => {
    if (isEmpty(resultCheckedList) || resultCheckedList.length < 1) {
      setPopupType({
        ...defaultPopupType,
        alarm: true,
        title: 'Delete Result',
        content: 'Scheduled Content List to delete',
      });
      return;
    }
    setPopupType({
      ...defaultPopupType,
      remove: true,
      title: 'Delete Result',
      content: 'Are you sure you want to delete the Result?',
    });
  };

  const saveHandler = () => {
    setPopupType({
      ...defaultPopupType,
      save: true,
      title: 'Save Result',
      content: 'Would you like to save the content?',
    });
  };

  const saveCarouselResult = async () => {
    let title = '';
    let content = '';
    const body = await postRequest(`/home-app/carousel/${carousel.carouselIdx}/country/${carouselCountry}/result`, resultList);
    if (body && body.result === 'SUCCESS') {
      title = 'Success';
      content = 'Saved successfully.';
      setPopupType({ ...defaultPopupType, success: true, title: title, content: content });
      setResultCheckedList([]);
      setResultAllChecked(false);
      if (refreshResult) {
        refreshResult();
      }
    } else {
      if (body && body.result === 'FAILED') {
        title = 'Error';
        content = body.message;
      } else {
        title = 'Error';
        content = 'Contact your administrator!';
      }
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
    }
  };

  const successHandler = () => {
    closeDialog();
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  // Button Data
  const defaultScheduledButton = { type: 'outline', size: 'small', autosize: true, isIcon: false };
  const ScheduledListButtons = [
    { ...defaultScheduledButton, color: 'tertiary', label: 'Delete', onClick: deleteHandler },
    { ...defaultScheduledButton, color: 'primary', label: 'Hub Text', onClick: textUploadHandler },
    { ...defaultScheduledButton, color: 'primary', label: 'Upload Image', type: 'box', onClick: imageUploadHandler },
    { ...defaultScheduledButton, color: 'tertiary', label: 'Reset', onClick: getResetResult },
    { ...defaultScheduledButton, color: 'primary', label: 'Save', type: 'box', onClick: saveHandler },
  ];

  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButtonId',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };

  const removeButton = {
    ...defaultButton,
    buttonId: 'removeButtonId',
    color: 'primary',
    type: 'box',
    label: 'Delete',
    autosize: true,
    disabled: false,
    onClick: resultRemove,
  };

  const saveButton = {
    ...defaultButton,
    buttonId: 'saveButtonId',
    color: 'primary',
    type: 'box',
    label: 'Save',
    autosize: true,
    disabled: false,
    onClick: saveCarouselResult,
  };

  const successButton = {
    ...defaultButton,
    buttonId: 'successButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: successHandler,
  };

  return (
    <Grid item xs={6}>
      <Box className='white-box white-box-wrap'>
        {/* Box Title Area */}
        <Box className='title-wrap space-between'>
          <Box className='left-area'>
            <Box className='box-title-area' display='flex' alignItems='center'>
              <Typography variant='h2'>Scheduled Content List</Typography>
            </Box>
          </Box>
          <Box className='right-area'>
            <Box className='show-count-wrap'>
              <Typography component='span' className='label'>
                Showing All
              </Typography>
              <Typography component='span' className='value'>
                {isEmpty(resultList) ? 0 : resultList.length}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className='box-content'>
          <Box className='white-box-content-top-area'>
            <Box className='left-area'>
              {/* //TODO */}
              {/* <SearchField
                id='search-2'
                placeholder='Please enter a search term'
                label='Ch.no or ch.Name'
                size='medium'
                maxWidth='180px'
                required={false}
                shrink={false}
                value={searchField}
                setSearchText={searchFieldHandler}
              /> */}
            </Box>
            <Box className='right-area'>
              <CustomButtonGroup buttons={ScheduledListButtons} />
            </Box>
          </Box>

          {/* Selected Content */}
          <Box className='content-inner-wrap'>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId='droppable-table'>
                {(provided) => (
                  <CustomTableContainer className='table-container' {...provided.droppableProps} ref={provided.innerRef}>
                    <Table className='custom-table' aria-label='table' stickyHeader>
                      {/* table Head */}
                      <TableHead className='thead'>
                        <TableRow className='tr'>
                          {CarouselResultHeader.map((column) => (
                            <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                              {column.checkbox ? (
                                <Box display='flex' alignContent='center'>
                                  <IconDraggable className='icon-draggable' />
                                  <CheckBox
                                    label=''
                                    value='select-all'
                                    defaultChecked={false}
                                    checked={resultAllChecked}
                                    onChange={isResultAllChecked}
                                    inputProps={{
                                      'aria-label': 'select all',
                                    }}
                                  />
                                  <SortLabel labelText={column.label} labelId={column.labelId} sortable={column.sortable} />
                                </Box>
                              ) : (
                                <>{column.sortable ? <SortLabel labelText={column.label} labelId={column.labelId} sortable={column.sortable} /> : column.label}</>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {/* table Body */}
                      <TableBody className='tbody'>
                        {resultList.map((td, index) => (
                          <Draggable key={td.id || index} draggableId={td.id || index.toString()} index={index}>
                            {(provided) => (
                              <TableRow
                                className={!isEmpty(td.editAction) && (td.editAction === 'added' || td.editAction === 'update' || td.editAction === 'exclude') ? 'tr unsaved' : 'tr'}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                {CarouselResultHeader.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align}>
                                    {column.labelId === 'resultOrder' ? (
                                      <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                        <IconDraggable className='icon-draggable' />
                                        <CheckBox
                                          label={td[column.labelId]}
                                          value={td.contentId}
                                          checked={isEmpty(resultCheckedList) ? false : isResultChecked(td.contentId)}
                                          onChange={(event) => setResultChecked(event)}
                                          // disabled={td.manualFlag === 'N'}
                                        />
                                      </Box>
                                    ) : column.labelId === 'manualFlag' ? (
                                      <BasicSwitches disabled={false} onOffMode={td[column.labelId] === 'Y'} setSwitchOnOff={(value) => manualHandler(td, value)} isLabelText={true} onText='' />
                                    ) : column.labelId === 'focusImage' ? (
                                      <>{isEmpty(td[column.labelId]) ? '' : <CustomImage src={td[column.labelId]} fit={false} alt='' wrapWidth='54px' wrapHeight='64px' rounded />}</>
                                    ) : column.labelId === 'image' ? (
                                      <>{isEmpty(td[column.labelId]) ? '' : <CustomImage src={td[column.labelId]} fit={false} alt='' wrapWidth='54px' wrapHeight='64px' rounded />}</>
                                    ) : column.labelId === 'horizontalImage' ? (
                                      <>{isEmpty(td[column.labelId]) ? '' : <CustomImage src={td[column.labelId]} fit={false} alt='' wrapWidth='54px' wrapHeight='64px' rounded />}</>
                                    ) : column.labelId === 'verticalImage' ? (
                                      <>{isEmpty(td[column.labelId]) ? '' : <CustomImage src={td[column.labelId]} fit={false} alt='' wrapWidth='54px' wrapHeight='64px' rounded />}</>
                                    ) : (
                                      td[column.labelId]
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    </Table>
                  </CustomTableContainer>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
        {popupType.view && (
          <ResultImageUpload tabCategory={`${carouselTab.tabName}/${carouselCategory.categoryName}`} resultImage={resultImageView} setResultImage={setResultImage} closeHandler={closeDialog} />
        )}
        {popupType.text && (
          <ResultTextUpload tabCategory={`${carouselTab.tabName}/${carouselCategory.categoryName}`} resultText={resultTextView} setResultText={setResultText} closeHandler={closeDialog} />
        )}
        {(popupType.alarm || popupType.remove || popupType.save || popupType.success) && (
          <Dialog
            title={popupType.title}
            contentTitle={popupType.content}
            size={'small'}
            closeX={true}
            open={true}
            handleClose={closeDialog}
            buttons={popupType.alarm ? [closeButton] : popupType.remove ? [cancelButton, removeButton] : popupType.save ? [cancelButton, saveButton] : popupType.success ? [successButton] : []}
          />
        )}
      </Box>
    </Grid>
  );
}

export default CarouselResultList;
