export const menuItemData = [
  {
    id: 1000,
    iconClass: 'favorite',
    primaryText: 'Favorite Menu',
    collapseOpen: true,
    subItems: [],
  },
  {
    id: 61,
    iconClass: 'iss',
    primaryText: 'Iss',
    collapseOpen: true,
    subItems: [
      { id: 611, text: 'Dashboard', selected: '', path: '' },
      { id: 612, text: 'Service', selected: '', path: '' },
      { id: 613, text: 'Content', selected: '', path: '' },
      { id: 614, text: 'Statistics', selected: '', path: '' },
      { id: 615, text: 'FeatureBat', selected: '', path: '' },
      { id: 616, text: 'Issue History', selected: '', path: '' },
      { id: 617, text: 'Assign History', selected: '', path: '' },
      { id: 618, text: 'Issue Trend', selected: '', path: '' },
    ],
  },
  {
    id: 62,
    iconClass: 'inspect',
    primaryText: 'Inspection',
    collapseOpen: true,
    subItems: [
      { id: 621, text: 'Channel Status', selected: '', path: '/ChannelStatus' },
      { id: 622, text: 'Metadata', selected: '', path: '/Metadata' },
    ],
  },
  {
    id: 63,
    iconClass: 'organization',
    primaryText: 'Organization',
    collapseOpen: true,
    subItems: [
      { id: 631, text: 'Channel Mapping', selected: '', path: '/ChannelMapping' },
      { id: 632, text: 'Tab/Category', selected: '', path: '/TabCategoryList' },
      { id: 633, text: 'Web Service', selected: '', path: '/WebService' },
      { id: 634, text: 'AN Test', selected: '', path: '/ANTest' },
      { id: 635, text: 'AN Service', selected: '', path: '/ANService' },
      { id: 636, text: 'Device Notification', selected: '', path: '/DeviceNotification' },
    ],
  },
  {
    id: 64,
    iconClass: 'promotion',
    primaryText: 'Promotion',
    collapseOpen: true,
    subItems: [
      { id: 641, text: 'Free On LG Channels Shelf', selected: '', path: '/FreeOnLGChannelsShelf' },
      { id: 642, text: 'Watch On LG Channels Shelf', selected: '', path: '/WatchOnLGChannelsShelf' },
      { id: 643, text: 'LG Channels Shelf', selected: '', path: '/LGChannelsShelf' },
      { id: 644, text: 'Interactive Dock-Recommendation', selected: '', path: '/InteractiveDock' },
    ],
  },
  {
    id: 65,
    iconClass: 'config',
    primaryText: 'Configuration',
    collapseOpen: true,
    subItems: [
      { id: 651, text: 'Account', selected: '', path: '/Account' },
      { id: 652, text: 'Code Management', selected: '', path: '/CodeManagement' },
      { id: 653, text: 'Metadata Edit', selected: '', path: '/MetadataEdit' },
      { id: 654, text: 'Terms Of Use', selected: '', path: '/TermsOfUse' },
      { id: 655, text: 'Privacy Policy', selected: '', path: '/PrivacyPolicy' },
      { id: 656, text: 'Support', selected: '', path: '/Support' },
    ],
  },
  {
    id: 66,
    iconClass: 'monitoring',
    primaryText: 'Monitoring',
    collapseOpen: true,
    subItems: [
      { id: 661, text: 'Emulate', selected: '', path: '/Emulate' },
    ],
  },
];

// Partner
export const partnerMenuItemData = [
  {
    id: 100,
    iconClass: 'inspect',
    primaryText: 'Inspection',
    collapseOpen: true,
    subItems: [
      { id: 101, text: 'Channel Status', selected: '', path: '/ChannelStatus' },
      { id: 102, text: 'Metadata', selected: '', path: '/Metadata' },
    ],
  },
  {
    id: 200,
    iconClass: 'statistics',
    primaryText: 'Statistics',
    collapseOpen: true,
    subItems: [
      { id: 201, text: 'Channel', selected: '', path: '/Channel' },
      { id: 202, text: 'Program', selected: '', path: '/Program' },
      { id: 203, text: 'Vod', selected: '', path: '/Vod' },
    ],
  },
  {
    id: 300,
    iconClass: 'monitoring',
    primaryText: 'Monitoring',
    collapseOpen: true,
    subItems: [
      { id: 301, text: 'API Call Logs', selected: '', path: '/APICallLogs' },
    ],
  },
];