import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CheckBox from 'components/component/BasicCheckBox';
import SelectBox from 'components/component/BasicSelectBox';
import Button from 'components/component/BasicButton';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import TextLink from 'components/component/BasicTextLink';
import SearchField from 'components/component/BasicSearchField';
import SortLabel from 'components/component/BasicTableSortLabel';
import { styled } from '@mui/system';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Box, Typography, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getRequest } from 'utils/Request';
import { isEmpty } from 'utils/Valid';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const CellType = styled(TableCell)(({ theme }) => ({
  '& .success': {
    color: '#0077B5',
  },
  '& .fail': {
    color: '#B91C1C',
  },
  '& .update': {
    color: '#0D9488',
  },
}));

// main
function Reservation({ message }) {
  const navigate = useNavigate();
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Channel Mapping',
    normalMenu: ['Channel Map reservation'],
  };

  // Sort
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const reservationSort = (col, desc) => {
    let list = sortRows([...filteredReservationList], col, desc);
    setPageNavigate(list, currentPage, pageSize);
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  // option
  const reservationSaveTypeOption = [
    { id: 'saveType1', value: 'All', option: 'All', type: 'text' },
    { id: 'saveType2', value: 'T', option: 'Reservation For Test', type: 'text' },
    { id: 'saveType3', value: 'P', option: 'Reservation For Publish', type: 'text' },
  ];
  const reservationStatusOption = [
    { id: 'status1', value: 'All', option: 'All', type: 'text' },
    { id: 'status2', value: 'R', option: 'Ready', type: 'text' },
    { id: 'status3', value: 'D', option: 'Done', type: 'text' },
    { id: 'status3', value: 'E', option: 'Error', type: 'text' },
  ];
  const defaultOption = { id: 'All', value: 'All', option: 'All', type: 'text' };
  const defaultParameter = { countryCode: 'US', channelMapId: 'All', saveType: 'All', status: 'All', startDate: dayjs().subtract(7, 'day'), endDate: dayjs() };
  const [reservationParameter, setReservationParameter] = useState(defaultParameter);
  const [countryFilter, setCountryFilter] = useState('');
  const [channelMapList, setChannelMapList] = useState([]);
  const [channelMapParameter, setChannelMapParameter] = useState({});

  // page
  const [reservationList, setReservationList] = useState([]);
  const [filteredReservationList, setFilteredReservationList] = useState([]);
  const [filteredReservationCount, setFilteredReservationCount] = useState(0);
  const [sortedReservationRows, setSortedReservationRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isPageNavigate, setIsPageNavigate] = useState(false);

  useEffect(() => {
    let countryCode = 'US';
    if (message && !isEmpty(message.channelMapParameter)) {
      setChannelMapParameter(message.channelMapParameter);
    }

    if (message && !isEmpty(message.pageMode)) {
      if (message.pageMode === 'init') {
        countryCode = message.countryCode;
        setReservationParameter({ ...defaultParameter, ...{ countryCode: countryCode, endDate: isEmpty(message.endDate) ? dayjs() : dayjs(message.endDate) } });
      } else if (message.pageMode === 'historyBack') {
        countryCode = message.reservationParameter.countryCode;
        setReservationParameter(message.reservationParameter);
      }
    }

    if (message && !isEmpty(message.countryFilter)) {
      setCountryFilter(message.countryFilter);
      let country = message.countryFilter.find((f) => f.value === countryCode);
      if (!isEmpty(country)) {
        setChannelMapList([defaultOption, ...setChannelMapOption(country.channelMapList)]);
      }
    }
    // eslint-disable-next-line
  }, [message]);

  const setChannelMapOption = (mapList) => {
    return mapList.map((n) => {
      const { channelMapId } = n;
      return {
        id: channelMapId,
        value: channelMapId,
        option: channelMapId,
        type: 'text',
      };
    });
  };

  useEffect(() => {
    if (countryFilter) {
      getReservationList();
    }
    // eslint-disable-next-line
  }, [reservationParameter]);

  const addReservationLink = () => {
    navigate('/ChannelMapping', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'ReservationEdit',
        message: {
          mode: 'add',
          countryFilter: countryFilter,
          channelMapParameter: channelMapParameter,
          reservationParameter: reservationParameter,
        },
      },
    });
  };

  const editReservationLink = (reservation) => {
    navigate('/ChannelMapping', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'ReservationEdit',
        message: {
          mode: 'edit',
          countryFilter: countryFilter,
          channelMapParameter: channelMapParameter,
          reservationParameter: reservationParameter,
          reservation: reservation,
        },
      },
    });
  };

  const getReservationList = async () => {
    let param = `?countryCode=${reservationParameter.countryCode}`;
    if (reservationParameter.channelMapId && reservationParameter.channelMapId !== 'All') {
      param += `&channelMapId=${reservationParameter.channelMapId}`;
    }
    if (reservationParameter.saveType && reservationParameter.saveType !== 'All') {
      param += `&saveType=${reservationParameter.saveType}`;
    }
    if (reservationParameter.status && reservationParameter.status !== 'All') {
      param += `&reservationStatus=${reservationParameter.status}`;
    }
    if (reservationParameter.startDate && reservationParameter.endDate) {
      param += `&startDate=${reservationParameter.startDate.format('YYYY-MM-DD')}&endDate=${reservationParameter.endDate.format('YYYY-MM-DD')}`;
    }

    const body = await getRequest(`/channel-map/reservation${param}`, null);
    if (body && body.result === 'SUCCESS') {
      let list = body.data.map((item, index) => {
        const {
          reservationIdx,
          channelMapId,
          reservationDate,
          reservationUtcDate,
          reservationStatus,
          reservationSaveType,
          reservationResultMessage,
          channelCount,
          reservationLastChgUsrId,
          reservationLastChgDate,
        } = item;
        return {
          id: reservationIdx,
          reservationIdx: reservationIdx,
          channelMapId: channelMapId,
          reservationDate: reservationDate,
          reservationUtcDate: reservationUtcDate,
          reservationStatus: reservationStatus,
          reservationSaveType: reservationSaveType,
          reservationResultMessage: reservationResultMessage,
          channelCount: channelCount,
          reservationLastChgUsrId: reservationLastChgUsrId,
          reservationLastChgDate: reservationLastChgDate,
        };
      });
      setReservationList(list);
      setFilteredReservationList(list);
      setFilteredReservationCount(isEmpty(list) ? 0 : list.length);
      setPageNavigate(list, currentPage, pageSize);
    }
  };

  const setPageNavigate = (list, current, size) => {
    if (!isEmpty(list)) {
      setIsPageNavigate(true);
      const startIndex = (current - 1) * size;
      const endIndex = startIndex + size;
      const displayReservation = list.slice(startIndex, endIndex);
      setSortedReservationRows([...displayReservation]);
    } else {
      setIsPageNavigate(false);
      setSortedReservationRows([]);
    }
  };

  const paginationHandler = (paging) => {
    if (paging) {
      if (paging.type === 'page') {
        setCurrentPage(paging.value);
        setPageNavigate(filteredReservationList, paging.value, pageSize);
      } else if (paging.type === 'size') {
        setPageSize(paging.value);
        setCurrentPage(1);
        setPageNavigate(filteredReservationList, 1, paging.value);
      }
    }
  };

  const onChangeHandler = (name, value) => {
    if (name === 'countryCode') {
      setReservationParameter({ ...reservationParameter, [name]: value, channelMapId: 'All', saveType: 'All', status: 'All' });
      let country = countryFilter.find((f) => f.value === value);
      if (!isEmpty(country)) {
        setChannelMapList([defaultOption, ...setChannelMapOption(country.channelMapList)]);
      }
    } else {
      setReservationParameter({ ...reservationParameter, [name]: value });
    }
  };

  // inner Filter
  const [reservationInnerField, setReservationInnerField] = useState('');
  const reservationInnerSearchHandler = (searchText) => {
    setReservationInnerField(searchText);
    let rows = [];
    if (!isEmpty(reservationList)) {
      rows = [...reservationList];

      if (!isEmpty(searchText)) {
        rows = rows.filter((search) => isIncludeSearchText(search, searchText));
      }
    }
    setFilteredReservationList(rows);
    setCurrentPage(1);
    setPageNavigate(rows, 1, pageSize);
    setFilteredReservationCount(isEmpty(rows) ? 0 : rows.length);
  };

  const isIncludeSearchText = (row, searchText) => {
    let lowerSearchText = '';
    let lowerMapId = '';
    let lowerLastChgUsrId = '';
    if (!isEmpty(searchText)) {
      lowerSearchText = searchText.toString().toLowerCase();
    }
    if (!isEmpty(row.channelMapId)) {
      lowerMapId = row.channelMapId.toString().toLowerCase();
    }
    if (!isEmpty(row.reservationLastChgUsrId)) {
      lowerLastChgUsrId = row.reservationLastChgUsrId.toString().toLowerCase();
    }
    return lowerMapId.indexOf(lowerSearchText) > -1 || row.reservationSaveType === searchText || lowerLastChgUsrId.indexOf(lowerSearchText) > -1;
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container bulk-upload' component='main'>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Channel Map reservation
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-6'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    {!isEmpty(countryFilter) && (
                      <SelectBox
                        id='reservation-country'
                        size='large'
                        shrink={true}
                        label='Country'
                        required={false}
                        selectOptions={countryFilter}
                        disabled={false}
                        isFirstSelected={true}
                        placeholder=''
                        selected={reservationParameter.countryCode}
                        onChange={(value) => onChangeHandler('countryCode', value)}
                      />
                    )}
                  </Box>
                  {/* Channel Map */}
                  <Box component='div' className='col'>
                    {!isEmpty(channelMapList) && (
                      <SelectBox
                        id='reservation-channelMap'
                        size='large'
                        shrink={true}
                        label='Channel Map'
                        required={false}
                        selectOptions={channelMapList}
                        disabled={false}
                        isFirstSelected={true}
                        placeholder=''
                        selected={reservationParameter.channelMapId}
                        onChange={(value) => onChangeHandler('channelMapId', value)}
                      />
                    )}
                  </Box>
                  {/* Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='reservation-type'
                      size='large'
                      shrink={true}
                      label='Type'
                      required={false}
                      selectOptions={reservationSaveTypeOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={reservationParameter.saveType}
                      onChange={(value) => onChangeHandler('saveType', value)}
                    />
                  </Box>

                  {/* status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='reservation-status'
                      size='large'
                      shrink={true}
                      label='Reservation Status'
                      required={false}
                      selectOptions={reservationStatusOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={reservationParameter.status}
                      onChange={(value) => onChangeHandler('status', value)}
                    />
                  </Box>
                  <Box component='div' className='col auto'>
                    <DatePicker
                      format='YYYY-MM-DD'
                      size='large'
                      singleDate={false}
                      rangeDate={true}
                      wave={true}
                      sx={{ maxWidth: '210px' }}
                      startDate={reservationParameter.startDate}
                      endDate={reservationParameter.endDate}
                      onChangeStartDate={(value) => onChangeHandler('startDate', value)}
                      onChangeEndDate={(value) => onChangeHandler('endDate', value)}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'></Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box display='flex' gap={1}>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        {filteredReservationCount}
                      </Box>
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    {/* TODO */}
                    {/* <SelectBox
                      id='reservation-inner-search-select'
                      maxWidth='180px'
                      fullWidth
                      size='medium'
                      selectOptions={reservationInnerFilter}
                      disabled={false}
                      label=''
                      isFirstSelected={true}
                      shrink
                      selected={reservationInnerSelect}
                      onChange={setReservationInnerSelect}
                    /> */}

                    <SearchField
                      id='reservation-inner-search-field'
                      placeholder='Please enter a search term'
                      label='Ch.no or ch.Name'
                      maxWidth='230px'
                      size='medium'
                      required={false}
                      shrink={false}
                      value={reservationInnerField}
                      setSearchText={reservationInnerSearchHandler}
                    />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <Button color='tertiary' type='box' size='small' label='New Reservation' isIcon={false} autosize onClick={addReservationLink} />
                </Box>
                {/* right-area */}
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table auto' aria-label='table'>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr' key={'reservation-tableHead-row'}>
                        <TableCell key={'reservation-channel-map-th'} className='th' align={'left'} sx={{ width: '14%' }}>
                          <Box display='flex' alignContent='center'>
                            <CheckBox
                              label=''
                              value='select-all'
                              defaultChecked={false}
                              inputProps={{
                                'aria-label': 'select all',
                              }}
                            />
                            <SortLabel
                              labelText={'Channel Map ID'}
                              labelId={'channelMapId'}
                              sortable={true}
                              orderBy={orderBy}
                              setOrderBy={setOrderBy}
                              order={order}
                              setOrder={setOrder}
                              sorting={reservationSort}
                            />
                          </Box>
                        </TableCell>
                        <TableCell key={'reservation-save-status-th'} className='th' align={'left'} sx={{ width: '14%' }}>
                          <SortLabel
                            labelText={'Status'}
                            labelId={'reservationSaveType'}
                            sortable={true}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            order={order}
                            setOrder={setOrder}
                            sorting={reservationSort}
                          />
                        </TableCell>
                        <TableCell key={'reservation-date-th'} className='th' align={'left'} sx={{ width: '14%' }}>
                          <SortLabel
                            labelText={'Reservation Date Time'}
                            labelId={'reservationDate'}
                            sortable={true}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            order={order}
                            setOrder={setOrder}
                            sorting={reservationSort}
                          />
                        </TableCell>
                        <TableCell key={'reservation-status-th'} className='th' align={'left'} sx={{ width: '14%' }}>
                          <SortLabel
                            labelText={'Reservation Status'}
                            labelId={'reservationStatus'}
                            sortable={true}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            order={order}
                            setOrder={setOrder}
                            sorting={reservationSort}
                          />
                        </TableCell>
                        <TableCell key={'reservation-result-message-th'} className='th' align={'left'} sx={{ width: '13%' }}>
                          <SortLabel
                            labelText={'Result Message'}
                            labelId={'reservationResultMessage'}
                            sortable={true}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            order={order}
                            setOrder={setOrder}
                            sorting={reservationSort}
                          />
                        </TableCell>
                        <TableCell key={'reservation-channel-count-th'} className='th' align={'left'} sx={{ width: '8%' }}>
                          <SortLabel
                            labelText={'Channel'}
                            labelId={'channelCount'}
                            sortable={true}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            order={order}
                            setOrder={setOrder}
                            sorting={reservationSort}
                          />
                        </TableCell>
                        <TableCell key={'reservation-modifier-th'} className='th' align={'left'} sx={{ width: '8%' }}>
                          <SortLabel
                            labelText={'Modifier'}
                            labelId={'lastChgUsrId'}
                            sortable={true}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            order={order}
                            setOrder={setOrder}
                            sorting={reservationSort}
                          />
                        </TableCell>
                        <TableCell key={'reservation-createDate-th'} className='th' align={'left'} sx={{ width: '14%' }}>
                          <SortLabel
                            labelText={'Create Date'}
                            labelId={'lastChgDate'}
                            sortable={true}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            order={order}
                            setOrder={setOrder}
                            sorting={reservationSort}
                          />
                        </TableCell>
                        <TableCell key={'reservation-action-th'} className='th' align={'left'} sx={{ width: 'auto' }}>
                          <SortLabel labelText={'Action'} sortable={false} labelId={'reservationAction'} />
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    {isEmpty(sortedReservationRows) ? (
                      <TableBody className='tbody'>
                        <TableRow className='tr'>
                          <TableCell colSpan={9} className={'td noData'} align='center'>
                            <Box className='no-data-message' sx={{ height: '400px', padding: '50px 0' }}>
                              <Typography>No Reservation data</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody className='tbody'>
                        {sortedReservationRows.map((row) => (
                          <TableRow className='tr' key={`reservation-tr-row-${row.id}`}>
                            <CellType key={`reservation-channel-map-${row.id}`} className='td' align={'left'}>
                              <CheckBox label={<TextLink href='' text={row.channelMapId} />} value='value' defaultChecked={false} />
                            </CellType>
                            <CellType key={`reservation-save-status-${row.id}`} className='td' align={'left'}>
                              {row.reservationSaveType === 'P' ? 'Reservation For Publish' : 'Reservation For Test'}
                            </CellType>
                            <CellType key={`reservation-date-${row.id}`} className='td' align={'left'}>
                              {row.reservationDate}
                            </CellType>
                            <CellType key={`reservation-status-${row.id}`} className='td' align={'left'}>
                              {row.reservationStatus === 'D' ? 'Done' : row.reservationStatus === 'E' ? 'Error' : 'Ready'}
                            </CellType>
                            <CellType key={`reservation-result-message-${row.id}`} className='td' align={'left'}>
                              {isEmpty(row.reservationResultMessage) ? '-' : row.reservationResultMessage}
                            </CellType>
                            <CellType key={`reservation-channel-count-${row.id}`} className='td' align={'left'}>
                              {row.channelCount}
                            </CellType>
                            <CellType key={`reservation-modifier-${row.id}`} className='td' align={'left'}>
                              {row.reservationLastChgUsrId}
                            </CellType>
                            <CellType key={`reservation-createDate-${row.id}`} className='td' align={'left'}>
                              {row.reservationLastChgDate}
                            </CellType>
                            <CellType key={`reservation-action-${row.id}`} className='td' align={'left'}>
                              <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='Edit'
                                isIcon={false}
                                disabled={row.reservationStatus === 'D'}
                                autosize
                                onClick={() => editReservationLink(row)}
                              />
                            </CellType>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </CustomTableContainer>
              </Box>
              {isPageNavigate && (
                <Pagination count={Math.ceil(reservationList.length / pageSize)} id='reservation-pagination' selected={pageSize} onChangeHandle={paginationHandler} page={currentPage} />
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

Reservation.propTypes = {};

const mapStateToProps = (state) => ({
  country: state.common.country,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Reservation);
