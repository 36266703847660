import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import Snackbar from 'components/component/BasicSnackbar';
import Tooltip from 'components/component/BasicTooltip';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { Box, Typography, Link, Grid } from '@mui/material';
import DetailItem from './component/DetailItem';
import DetailImageItem from './component/DetailImageItem';
import {deleteRequest, getRequest, multiRequest} from "utils/Request";
import {useNavigate} from "react-router-dom";

const MetadataChannelEdit = React.memo((props) => {
  const { darkMode, message} = props;
  const { contentId, channelMapId, countryCode, tabValue, filters } = message;
  const [channelData, setChannelData] = useState(null)
  const [genres, setGenres] = useState([]);
  const [modifiedChannel, setModifiedChannel] = useState({});
  const [isSearchSuccessful, setIsSearchSuccessful] = useState(false);
  // Modified file list
  const [modifiedFiles, setModifiedFiles] = useState([]);

  const navigate = useNavigate();
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Metadata Edit',
    normalMenu: ['Channel Detail Edit'],
  };

  useEffect(() => {
    getGenres();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (message) {
      searchChannelDetail();
    }
    // eslint-disable-next-line
  }, [message]);

  const searchChannelDetail = async () => {
    setIsSearchSuccessful(false);
    setModifiedFiles([]);

    const body = await getRequest(`/metadata/channel/${contentId}?countryCode=${countryCode}&channelMapId=${channelMapId}`, null);
    if (body && body.result === 'SUCCESS') {
      setChannelData(body.data);

      let originalChannelData = {};
      originalChannelData.channelId = body.data.channelId;
      originalChannelData.providerId = body.data.providerId;
      originalChannelData.channelName = body.data.channelName;
      originalChannelData.genreCode = body.data.channelGenreCode;
      originalChannelData.logo = body.data.logoImage?.replacedUrl;
      originalChannelData.representative1920 = body.data.representativeImageList.find(image => image.width === '1920')?.replacedUrl;
      originalChannelData.representative768 = body.data.representativeImageList.find(image => image.width === '768')?.replacedUrl;

      setModifiedChannel(originalChannelData);
    }
  };

  const getGenres = async () => {
    const body = await getRequest(`/metadata/genres?genreType=channel&countryCode=${countryCode}`, null);
    if (body && body.result === 'SUCCESS') {
      const genreObjects = body.data.map(genre => ({
        id: genre.genreCode,
        value: `(${genre.genreCode}) ${genre.genreName}`,
        option: `(${genre.genreCode}) ${genre.genreName}`,
        type: 'text'
      }));
      setGenres(genreObjects);
    }
  };

  useEffect(() => {
    if (channelData && genres.length > 0) {
      setIsSearchSuccessful(true);
    }
  }, [genres, channelData]);

  const saveMetadata = async () => {
    const requestBody = new FormData();
    requestBody.append('modifiedChannel', new Blob([JSON.stringify(modifiedChannel)], { type: 'application/json' }));

    if (modifiedFiles.length > 0) {
      modifiedFiles.forEach(({ fileType, file }) => {
        requestBody.append(fileType, file);
      });
    }

    const body = await multiRequest('POST', `/metadata-edit/channel/${modifiedChannel.channelId}`, requestBody);

    if (body && body.result === 'SUCCESS') {
      setIsSearchSuccessful(false);
      await searchChannelDetail();
    }
  };

  const restoreMetadata = async () => {
    const body = await deleteRequest(`/metadata-edit/channel/${modifiedChannel.channelId}`,
        null);
    if (body && body.result === 'SUCCESS') {
      setIsSearchSuccessful(false);
      await searchChannelDetail();
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    navigate('/MetadataEdit', {
      replace: true, state: {
        move: true,
        targetComponent: 'Metadata',
        type: 'edit',
        message: {
          filters: filters,
          tabValue: tabValue,
        }
      }
    });
  };

  const handleChannelNameChange = (event) => {
    setModifiedChannel({
      ...modifiedChannel,
      channelName: event.target.value,
    });
  };

  const handleChannelGenreCodeChange = (value) => {
    setModifiedChannel({
      ...modifiedChannel,
      genreCode: value,
    });
  };

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Restore',
      autosize: true,
      onClick: () => restoreMetadata(),
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Save',
      autosize: true,
      onClick: () => saveMetadata(),
    },
  ];

  const handleUploadFile = (type, size, file) => {
    let fileType = '';

    if (type === 'logo') {
      fileType = 'logoFile';
    } else {
      if (size === '1920') {
        fileType = 'representative1920File';
      } else {
        fileType = 'representative768File';
      }
    }

    const tempModifiedFile = {
      fileType : fileType,
      file : file,
    }

    setModifiedFiles([...modifiedFiles, tempModifiedFile]);
  };

  const getModifiedFile = (type, size) => {
    let fileType = '';

    if (type === 'logo') {
      fileType = 'logoFile';
    } else {
      if (size === '1920') {
        fileType = 'representative1920File';
      } else {
        fileType = 'representative768File';
      }
    }

    const modifiedFile = modifiedFiles.find(file => file.fileType === fileType);
    return modifiedFile ? URL.createObjectURL(modifiedFile.file) : null;
  };


  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Link className='title link' href='#' onClick={handleBack}>
                Channel Detail Edit
              </Link>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        <Box className='content-wrap channel-detail' component='div'>
          {isSearchSuccessful && (
            <Box className='white-box white-box-wrap single-box'>
              <Box className='title-wrap space-between'>
                <Box className='left-area'>
                  <Box className='box-title-area' display='flex' alignItems='center'>
                    <Typography variant='h2'>Basic Information</Typography>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={buttons} justifyContent='flex-end'/>
                </Box>
              </Box>
              <div className='box-content'>
                <Box className='detail-desc-container'>*Items modified in CMP are highlighted in red.</Box>
                <Box className='content-inner-wrap' mt={2}>
                  <Box className='detail-list-container'>
                    <Grid className='section' container columnSpacing={2.5}>
                      <Grid item xs={6}>
                        <DetailItem label='Channel ID' value={channelData.channelId}/>
                        <DetailItem label='Channel Type' value={channelData.channelType}/>
                        <Grid item xs={12}>
                          <Box className='content-tab-container'>
                            <Box className='info-value-wrap align-top'>
                              <Tooltip title={`OriginData : ${channelData.replacedChannelName || channelData.channelName}`} placement='top-start'>
                                <Typography component='span' className='label' >
                                  Channel Name
                                </Typography>
                              </Tooltip>
                              <Box className='value no-border'>
                                <Box className='field-box'>
                                  <TextField
                                    id='channel-name-textfield'
                                    type='outlined'
                                    size='large'
                                    placeholder=''
                                    defaultValue={channelData.channelName}
                                    onChange={handleChannelNameChange}
                                    label=''
                                    autoComplete='off'
                                    required={false}
                                    InputLabelProps={{shrink: false}}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <DetailItem label='Channel Number' value={channelData.channelNumber}/>
                        <DetailItem label='CP Name' value={channelData.cpName}/>
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={2.5}>
                      <DetailItem
                        label={<>Active Time <br/> Period Start</>}
                        value={channelData.activeTimePeriodStart}
                        xs={6}
                      />
                      <DetailItem
                        label={<>Active Time <br/> Period End</>}
                        value={channelData.activeTimePeriodEnd}
                        xs={6}
                      />
                      <Box display='flex' gap='1px' marginLeft='20px'>
                        {channelData.representativeImageList.map((item, index) => (
                          <DetailImageItem xs={8} label='Representative Image' uiType='SIZE_EDIT'
                                            src={getModifiedFile('representativeImage', item.width) || item.replacedUrl || item.originUrl || ''}
                                            uiWidth='600px'
                                            uiHeight='300px'
                                            actualWidth={item.width || 0}
                                            actualHeight={item.height || 0}
                                            type={item.type}
                                            ratio={item.ratio}
                                            group={item.imageGroup}
                                            index={index}
                                            uploadImageId={'representativeImage' + index}
                                            uploadFile={(file) => handleUploadFile('representativeImage', item.width, file)}
                          />
                        ))}
                      </Box>
                      <DetailItem xs={12} label='Media URL' value={channelData.mediaUrl}/>
                      <DetailImageItem xs={8} label='Logo' uiType='SIZE_EDIT'
                                        src={getModifiedFile('logo', channelData.logoImage.width) || channelData.logoImage.replacedUrl || channelData.logoImage.originUrl || ''}
                                        uiWidth='600px'
                                        uiHeight='200px'
                                        actualWidth={channelData.logoImage.width}
                                        actualHeight={channelData.logoImage.height}
                                        index={0}
                                        uploadImageId={'logo' + channelData.logoImage.width}
                                        uploadFile={(file) => handleUploadFile('logo', channelData.logoImage.width, file)}
                      />
                      <DetailItem
                        xs={6}
                        label='Channel Genre Code'
                        content={
                          <SelectBox
                            id='channel-genres'
                            size='large'
                            shrink={false}
                            label=''
                            required={false}
                            selectOptions={genres}
                            disabled={false}
                            selected={channelData.channelGenreName}
                            onChange={(value) => {
                              const match = value.match(/\((\d+)\)/);
                              if (match) {
                                handleChannelGenreCodeChange(match[1]);
                              }
                            }}
                          />
                        }
                      />
                      <DetailItem xs={6} label='Keywords' value={channelData.keywords}/>
                      <DetailItem xs={6} label='Adult Flag' value={channelData.adultFlag ? 'Yes' : 'No'}/>
                      <DetailItem xs={6} label='Paid Flag' value={channelData.paidFlag ? 'Yes' : 'No'}/>
                      <DetailItem xs={6} label='AD Flag' value={channelData.adFlag ? 'Yes' : 'No'}/>
                      <DetailItem xs={6} label='Promotion Flag' value={channelData.promotionFlag ? 'Yes' : 'No'}/>
                      <DetailItem xs={6} label='Visible' value={channelData.visible ? 'Yes': 'No'}/>
                      <DetailItem xs={6} label='Last Update' value={channelData.lastUpdateDate}/>
                    </Grid>
                    <Grid container component='section'
                          className='section-container' columnSpacing={2.5}
                          sx={{mt: 5}}>
                      <Grid item xs={12}>
                        <Typography className='sub-title'>Device List</Typography>
                      </Grid>
                      <DetailItem label='Device Name / Version' className='bold' value={channelData.deviceName} xs={6}/>
                    </Grid>
                  </Box>
                </Box>
              </div>
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode}/>
    </Box>
  );
});

MetadataChannelEdit.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    contentId: PropTypes.string.isRequired,
    channelMapId: PropTypes.string,
    filters: PropTypes.object,
    tabValue: PropTypes.number,
  }).isRequired,
};

export default MetadataChannelEdit;