import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import TextField from 'components/component/BasicTextField';
import BasicSwitches from 'components/component/BasicSwitch';
import SelectBox from 'components/component/BasicSelectBox';
import SelectMultipleBox from 'components/component/BasicSelectMultipleBox';
import { Box, Typography, Link, Grid } from '@mui/material';
// data
import { defaultPolicy, displayOption } from 'pages/Configuration/Policy/data/PolicyData';

// common
import EditContent from 'pages/Configuration/Policy/PolicyEditContent';
import BasicDialog from 'components/component/BasicDialog';
import { useNavigate } from 'react-router-dom';
import { putRequest } from 'utils/Request';
import { isEmpty } from 'utils/Valid';

// DetailItem 컴포넌트 생성
const DetailItem = ({ label, xs, value, required, maxThWidth = false }) => (
  <Grid item xs={xs} className='list-wrap'>
    <Grid display='flex' item xs={12} alignItems='center'>
      <Typography component='span' className={`label ${required ? 'required' : ''}`}>
        {label}
      </Typography>

      <div className='value-wrap'>
        <div className='value'>
          <Typography component='span' className='text'>
            {value}
          </Typography>
        </div>
      </div>
    </Grid>
  </Grid>
);

// ************************** 메인 컨텐츠 ***********************************
function PolicyEdit({ darkMode, message }) {
  const navigate = useNavigate();
  const compareVersions = require('compare-versions');

  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Terms of Use',
    normalMenu: ['Terms Edit'],
  };
  const defaultPopupType = {
    edit: false,
    cancel: false,
    alarm: false,
    success: false,
    title: '',
    content: '',
  };
  const defaultContent = {
    contentIdx: null,
    languageCode: '',
    policyTitle: '',
    policyDescription: '',
    contentCrtUsrId: '',
    contentCrtDate: null,
  };
  const [popupType, setPopupType] = useState(defaultPopupType);
  const [policy, setPolicy] = useState(defaultPolicy);
  const [savedCountryList, setSavedCountryList] = useState([]);
  const [editFilter, setEditFilter] = useState([]);
  const [supportLanguage, setSupportLanguage] = useState({});

  useEffect(() => {
    if (!isEmpty(message)) {
      setEditFilter(message.editFilter);
      let version = message.policy.policyVersion.split('.');
      setPolicy({
        ...message.policy,
        policyMajorVersion: version[0],
        policyMinorVersion: version[1],
        policyPatchVersion: version[2],
      });
      setSavedCountryList(message.policy.countryList);

      let language = [];
      if (!isEmpty(message.supportLanguage)) {
        language = message.supportLanguage.map((m) => {
          return {
            id: m.languageCode,
            value: m.languageCode,
            option: m.languageCode,
            type: 'text',
          };
        });
      }
      setSupportLanguage(language);
    }
    // eslint-disable-next-line
  }, [message]);

  const isValidVersion = (previousVersion, inputVersion) => {
    if (
      isEmpty(previousVersion) ||
      isEmpty(inputVersion) ||
      !compareVersions.validate(previousVersion) ||
      !compareVersions.validate(inputVersion) ||
      !compareVersions.compare(previousVersion, inputVersion, '<=')
    ) {
      return false;
    }
    return true;
  };

  const versionInputHandler = (event) => {
    event.preventDefault();
    let { name, value } = event.target;
    if (!isEmpty(value)) {
      if (!isFinite(value)) {
        return;
      }
    }
    let inputVersion = isEmpty(value) ? '' : Number(value);
    if (name === 'policyMajorVersion') {
      setPolicy({
        ...policy,
        ...{
          policyMajorVersion: inputVersion,
          policyVersion: ''.concat(inputVersion, '.', policy.policyMinorVersion, '.', policy.policyPatchVersion),
        },
      });
    } else if (name === 'policyMinorVersion') {
      setPolicy({
        ...policy,
        ...{
          policyMinorVersion: inputVersion,
          policyVersion: ''.concat(policy.policyMajorVersion, '.', inputVersion, '.', policy.policyPatchVersion),
        },
      });
    } else if (name === 'policyPatchVersion') {
      setPolicy({
        ...policy,
        ...{
          policyPatchVersion: inputVersion,
          policyVersion: ''.concat(policy.policyMajorVersion, '.', policy.policyMinorVersion, '.', inputVersion),
        },
      });
    }
  };

  // EDIT
  const policyInputChangeHandler = (name, value) => {
    if (name === 'policyComment') {
      setPolicy({ ...policy, ...{ [name]: value } });
    } else if (name === 'enableFlag') {
      setPolicy({ ...policy, ...{ [name]: value === 'N' ? 'Y' : 'N' } });
    } else if (name === 'displayOrder') {
      if (isEmpty(value)) {
        setPolicy({ ...policy, ...{ [name]: '' } });
      } else {
        if (isFinite(value)) {
          setPolicy({ ...policy, ...{ [name]: Number(value) < 1 ? 1 : value } });
        }
      }
    } else if (name === 'contentLanguageCodeList') {
      const policyContentList = [...policy.contentList];
      if (value.length === 0) {
        setPolicy({ ...policy, ...{ [name]: value, contentList: [] } });
      } else if (policy.contentList.length > value.length) {
        let filterContentList = policyContentList.filter((f) => value.includes(f.languageCode));
        setPolicy({ ...policy, ...{ [name]: value, contentList: filterContentList } });
      } else if (policy.contentList.length < value.length) {
        let newContentCountry = value.filter((f) => ![...policy.contentList].map((m) => m.languageCode).includes(f));
        let addContent = { ...defaultContent };
        addContent.languageCode = newContentCountry.toString();
        policyContentList.push(addContent);
        setPolicy({ ...policy, ...{ [name]: value, contentList: policyContentList } });
      }
    } else if (name === 'countryCodeList') {
      let list = editFilter
        .find((f) => policy.deviceType === f.id)
        .countryList.filter((f) => f.id === value)
        .map((m) => ({
          countryCode: m.id,
          ricCode: m.ric,
          countryCrtUsrId: savedCountryList.find((f) => f.countryCode === m.id) ? savedCountryList.find((f) => f.countryCode === m.id).countryCrtUsrId : '',
          countryCrtDate: savedCountryList.find((f) => f.countryCode === m.id) ? savedCountryList.find((f) => f.countryCode === m.id).countryCrtDate : null,
        }));
      setPolicy({ ...policy, ...{ [name]: [value], countryList: list } });
    } else {
      setPolicy({ ...policy, ...{ [name]: value } });
    }
  };

  const contentChangeHandler = (contentData) => {
    setPolicy({ ...policy, ...{ contentList: contentData } });
  };

  const editConfirmHandler = (e) => {
    e.preventDefault();
    let title = 'Policy';
    let dialogContent = '';
    // common
    if (isEmpty(policy.policyType)) {
      dialogContent = 'No policy type has been selected.?';
    } else if (isEmpty(policy.deviceType)) {
      dialogContent = 'No device type has been selected.';
    } else if (isEmpty(policy.devicePlatformCodeList)) {
      dialogContent = 'No device Platform has been selected.';
      // } else if (isEmpty(policy.countryCode)) {
      //   dialogContent = 'Select the country this policy applies to.';
    } else if (isEmpty(policy.policyVersion) || !compareVersions.validate(policy.policyVersion)) {
      dialogContent = 'The version format to apply was entered incorrectly.';
    } else if (!compareVersions.compare(policy.definedVersion, policy.policyVersion, '<=')) {
      dialogContent = 'It should be upgraded from the previous version.';
    } else if (isEmpty(policy.displayOrder) || !isFinite(policy.displayOrder) || Number(policy.displayOrder) < 1) {
      dialogContent = 'The display order was entered incorrectly. (number 1 or more)';
    } else if (isEmpty(policy.displayOption) || !displayOption.map((m) => m.value).includes(policy.displayOption)) {
      dialogContent = 'Please select a display option';
    } else if (isEmpty(policy.countryCodeList) || isEmpty(policy.countryList)) {
      dialogContent = 'Select the country this policy applies to.';
    } else if (isEmpty(policy.contentLanguageCodeList) || isEmpty(policy.contentList)) {
      dialogContent = 'Select the language in which you want to write your policy.';
    }

    if (!isEmpty(dialogContent)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: dialogContent });
      return;
    }
    // content
    policy.contentList.forEach((f) => {
      if (isEmpty(f.policyTitle)) {
        dialogContent = 'Please enter a title for your[' + f.languageCode + '] policy.';
        return;
      } else if (isEmpty(f.policyDescription)) {
        dialogContent = 'Please enter description for your [' + f.languageCode + '] policy.';
        return;
      }
    });
    if (!isEmpty(dialogContent)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: dialogContent });
      return;
    }
    let policyVersion = '';
    policyVersion = ''.concat(policy.policyMajorVersion, '.', policy.policyMinorVersion, '.', policy.policyPatchVersion);
    setPolicy({ ...policy, ...{ policyVersion: policyVersion } });
    editPolicyDialog();
  };

  //action
  const cancelPolicyDialog = () => {
    setPopupType({ ...defaultPopupType, cancel: true, title: 'Policy', content: 'Do you want to cancel writing?' });
  };

  const editPolicyDialog = () => {
    setPopupType({ ...defaultPopupType, edit: true, title: 'Policy', content: 'Would you like to save your Policy?' });
  };

  const editPolicy = async () => {
    let requestBody = { ...policy };
    const body = await putRequest(`/policy/${policy.policyIdx}`, requestBody, null);
    let title = '';
    let content = '';
    if (body && body.result === 'SUCCESS') {
      title = body.result;
      content = 'Saved successfully.';
      setPopupType({ ...defaultPopupType, success: true, title: title, content: content });
    } else {
      if (body && body.result === 'FAILED') {
        title = body.result;
        content = body.message;
      } else {
        title = body.error;
        content = 'Contact your administrator!';
      }
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
    }
  };

  // Button
  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const returnList = () => {
    let link = '/TermsOfUse';
    if (policy.policyType !== 'terms') {
      link = '/PrivacyPolicy';
    }
    navigate(link, {
      replace: true,
      state: {
        move: true,
      },
    });
  };

  const defaultButton = {
    buttonId: 'buttonId',
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    isIcon: false,
    Icon: null,
    fontWeight: 'bold',
    disabled: false,
    label: 'Button',
  };
  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButton',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButton',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };

  const saveButton = {
    ...defaultButton,
    buttonId: 'saveButton',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: editPolicy,
  };
  const linkButton = {
    ...defaultButton,
    buttonId: 'linkButton',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: returnList,
  };

  const cancelActionButtons = [cancelButton, linkButton];
  const saveActionButtons = [cancelButton, saveButton];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Link className='title link' href=''>
                Terms Edit
              </Link>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap vod-detail' component='div'>
          <Box className='white-box white-box-wrap single-box'>
            <Box component='div' className='title-wrap space-between flex-end'>
              <Box component='div' className='left-area'></Box>
              <Box component='div' className='right-area' gap={1}>
                <Button color='tertiary' type='outline' size='medium' label='Cancel' isIcon={false} autosize onClick={cancelPolicyDialog} />
                <Button color='primary' type='box' size='medium' label='Save' isIcon={false} autosize onClick={editConfirmHandler} />
              </Box>
            </Box>
            <div className='box-content'>
              <Box className='content-inner-wrap'>
                <Box className='detail-list-container' mt={0}>
                  <Grid container columnSpacing={2.5}>
                    {/* Device Type */}
                    <DetailItem xs={6} label='Device Type' className='' value={policy.deviceType} />
                    {/* Platform */}
                    <DetailItem xs={6} label='Platform' className='' value={!isEmpty(policy.devicePlatformCodeList) ? policy.devicePlatformCodeList.toString() : ''} />
                    {/* Comment */}
                    <Grid item xs={12} mt={1}>
                      <Box className='field-box'>
                        <TextField
                          name='policyComment'
                          type='outlined'
                          size='large'
                          label='Comment'
                          placeholder=''
                          autoComplete='off'
                          required={true}
                          InputLabelProps={{ shrink: true }}
                          value={policy.policyComment}
                          onChange={(event) => policyInputChangeHandler('policyComment', event.target.value)}
                        />
                      </Box>
                    </Grid>
                    {/* Enable */}
                    <Grid item xs={6} className='list-wrap'>
                      <Grid display='flex' item xs={12} alignItems='center'>
                        <Typography component='span' className='label'>
                          Enable
                        </Typography>
                        <div className='value-wrap'>
                          <div className='value no-border'>
                            <BasicSwitches
                              disabled={false}
                              onOffMode={policy.enableFlag === 'Y'}
                              setSwitchOnOff={() => policyInputChangeHandler('enableFlag', policy.enableFlag)}
                              isLabelText={true}
                              onText='Enable'
                              offText='Enable'
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    {/* Published */}
                    <DetailItem xs={6} label='Published' className='' value={policy.publishedVersion ? policy.publishedFlag + ' (Released: ' + policy.publishedVersion + ')' : policy.publishedFlag} />
                    {/* Version */}
                    <Grid item xs={6} className='list-wrap'>
                      <Typography component='span' className='label'>
                        Version
                      </Typography>
                      <Box className='value-wrap'>
                        <Box className='value no-border' display='flex' gap={1.5}>
                          <TextField
                            name='policyMajorVersion'
                            type='outlined'
                            size='large'
                            maxWidth={'94px'}
                            placeholder=''
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            inputProps={{ maxLength: 3 }}
                            InputLabelProps={{ shrink: false }}
                            value={policy.policyMajorVersion}
                            onChange={(event) => versionInputHandler(event)}
                          />
                          <TextField
                            name='policyMinorVersion'
                            type='outlined'
                            size='large'
                            maxWidth={'94px'}
                            placeholder=''
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            inputProps={{ maxLength: 3 }}
                            InputLabelProps={{ shrink: false }}
                            value={policy.policyMinorVersion}
                            onChange={(event) => versionInputHandler(event)}
                          />
                          <TextField
                            name='policyPatchVersion'
                            type='outlined'
                            size='large'
                            maxWidth={'94px'}
                            placeholder=''
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            inputProps={{ maxLength: 3 }}
                            InputLabelProps={{ shrink: false }}
                            value={policy.policyPatchVersion}
                            onChange={(event) => versionInputHandler(event)}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    {/* Update Version */}
                    <Grid item xs={6} className='list-wrap'>
                      <Grid display='flex' item xs={12} alignItems='center'>
                        <Typography component='span' className={`label ${true ? 'required' : ''}`}>
                          {'Update version'}
                        </Typography>
                        <div className='value-wrap'>
                          <div className='value'>
                            <Typography component='span' className='text' style={{ color: isValidVersion(policy.definedVersion, policy.policyVersion, policy.upgradedVersionFlag) ? '#000' : 'red' }}>
                              {policy.policyVersion}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    {/* Display Order */}
                    <Grid item xs={6}>
                      <Box className='field-box'>
                        <TextField
                          name='displayOrder'
                          type='outlined'
                          label='Display Order'
                          inputProps={{ maxLength: 3 }}
                          size='large'
                          placeholder=''
                          defaultValue=''
                          autoComplete='off'
                          required={true}
                          InputLabelProps={{ shrink: true }}
                          value={policy.displayOrder}
                          onChange={(event) => policyInputChangeHandler('displayOrder', event.target.value)}
                        />
                      </Box>
                    </Grid>
                    {/* Display Option */}
                    <Grid item xs={6}>
                      <Box className='field-box'>
                        <SelectBox
                          label='Display Option'
                          fullWidth
                          required={true}
                          size='large'
                          disabled={false}
                          isEmpty={true}
                          shrink
                          selectOptions={isEmpty(displayOption) ? [] : displayOption}
                          selected={policy.displayOption}
                          onChange={(event) => policyInputChangeHandler('displayOption', event.target.value)}
                        />
                      </Box>
                    </Grid>
                    {/* Country */}
                    <Grid item xs={6}>
                      <Box className='field-box'>
                        <SelectMultipleBox
                          id='policy-country'
                          size='large'
                          shrink={true}
                          label='Country'
                          required={false}
                          disabled={false}
                          multiple={false}
                          isEmpty={true}
                          placeholder=''
                          selectOptions={isEmpty(policy.deviceType) ? [] : editFilter.find((f) => f.id === policy.deviceType).countryList}
                          selected={isEmpty(policy.countryCodeList) ? '' : policy.countryCodeList}
                          onChange={(value) => policyInputChangeHandler('countryCodeList', value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className='field-box'>
                        <SelectMultipleBox
                          id='policy-language'
                          size='large'
                          shrink={true}
                          label='Country'
                          required={false}
                          disabled={false}
                          multiple={true}
                          placeholder=''
                          selectOptions={isEmpty(supportLanguage) ? [] : supportLanguage}
                          selected={isEmpty(policy.contentLanguageCodeList) ? [] : policy.contentLanguageCodeList}
                          onChange={(value) => policyInputChangeHandler('contentLanguageCodeList', value)}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <EditContent contentData={policy.contentList && policy.contentList.length > 0 ? policy.contentList : []} contentChangeHandler={contentChangeHandler}></EditContent>
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
      {(popupType.edit || popupType.cancel || popupType.alarm || popupType.success) && (
        <BasicDialog
          title={popupType.title}
          contentTitle={popupType.content}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={closeDialog}
          buttons={popupType.edit ? saveActionButtons : popupType.cancel ? cancelActionButtons : popupType.alarm ? [closeButton] : [{ ...closeButton, onClick: returnList }]}
        />
      )}
    </Box>
  );
}

export default PolicyEdit;
