import React, { useState } from 'react';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import Dialog from 'components/component/BasicDialog';
import CustomRadio from 'components/component/BasicRadio';
import FileUpload from 'components/component/BasicFileUpload';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import BasicDialog from 'components/component/BasicDialog';
import SortLabel from 'components/component/BasicTableSortLabel';
import { CloseIcon24Black } from 'components/component/BasicIcon';
import { styled } from '@mui/material/styles';
import { Box, Grid, IconButton, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { defaultPopupType, defaultImageInfo, DeepLinkChannelHeader, DeepLinkHubHeader, DeepLinkNotificationHeader } from 'pages/Organization/TabCategory/Carousel/DefaultData';
import { deepLinkCarouselTypeList, contentTypeList } from 'pages/Organization/TabCategory/data/TabCategoryCarouselData';
import { isEmpty } from 'utils/Valid';
import { getRequest, multiRequest } from 'utils/Request';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '210px',
  overflowY: 'auto',
}));

// ****** 메인 컨텐츠 *******
function EventCreatePop({ deviceType, countryCode, categoryIdx, closeHandler, refreshContents }) {
  const [isOpen, setIsOpen] = useState(true);
  const [eventBannerTitle, setEventBannerTitle] = useState('');
  const [horizontalImage, setHorizontalImage] = useState(null);
  const [verticalImage, setVerticalImage] = useState(null);
  const [focusImage, setFocusImage] = useState(null);
  const [deepLink, setDeepLink] = useState({ isDeepLink: 'N', contentType: '', contentId: '' });
  let defaultLinkParameter = {
    carouselType: 'noLink',
    contentType: '',
    contentId: '',
    contentName: '',
  };
  const [deepLinkParameter, setDeepLinkParameter] = useState(defaultLinkParameter);
  const [deepLinkContentList, setDeepLinkContentList] = useState([]);
  const [header, setHeader] = useState(DeepLinkChannelHeader);
  const onClose = () => {
    if (closeHandler) {
      setIsOpen(false);
      closeHandler();
    }
  };

  const [popupType, setPopupType] = useState(defaultPopupType);

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const successDialog = () => {
    if (refreshContents) {
      refreshContents();
    }
    onClose();
  };

  const saveDialog = () => {
    if (isEmpty(eventBannerTitle)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Create Event', content: 'Please enter an event title' });
      return;
    }
    if (isEmpty(horizontalImage) || isEmpty(horizontalImage.name)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Create Event', content: 'Please register a horizontal image' });
      return;
    }
    if (isEmpty(verticalImage) || isEmpty(verticalImage.name)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Create Event', content: 'Please register a vertical image' });
      return;
    }
    if (isEmpty(focusImage) || isEmpty(focusImage.name)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Create Event', content: 'Please register a focus image' });
      return;
    }
    if (!isEmpty(deepLink) && !isEmpty(deepLink.isDeepLink) && deepLink.isDeepLink === 'Y') {
      if (isEmpty(deepLink.contentType) || isEmpty(deepLink.contentId)) {
        setPopupType({ ...defaultPopupType, alarm: true, title: 'Create Event', content: 'Please check the deep link' });
        return;
      }
    }
    setPopupType({ ...defaultPopupType, save: true, title: 'Create Event', content: 'Would you like to save your Event?' });
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const dataSorting = (col, desc) => {
    setDeepLinkContentList(sortRows([...deepLinkContentList], col, desc));
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const titleChangeHandler = (e) => {
    setEventBannerTitle(e.target.value);
  };

  const getImageInfo = (file, setImage) => {
    let imageInfo = { ...defaultImageInfo };
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e2) => {
      imageInfo.name = file.name;
      imageInfo.preview = e2.target.result;
      const img = new Image();
      img.src = e2.target.result;
      img.onload = () => {
        imageInfo.height = img.height;
        imageInfo.width = img.width;
        imageInfo.file = file;
        setImage(imageInfo);
      };
    };
  };

  const onFileChangeHandler = (uploadTarget, file) => {
    if (file) {
      let ext = file.name.split('.').pop().toLowerCase();
      if (!['jpeg', 'jpg', 'png'].includes(ext)) {
        alert('jpeg, jpg, png 파일만 업로드 할수 있습니다.');
        return;
      }
      switch (uploadTarget) {
        case 'horizontal':
          getImageInfo(file, setHorizontalImage);
          return;
        case 'vertical':
          getImageInfo(file, setVerticalImage);
          return;
        case 'focus':
          getImageInfo(file, setFocusImage);
          return;
        default:
          return;
      }
    }
  };

  const removeImageHandler = (removeTarget) => {
    switch (removeTarget) {
      case 'horizontal':
        setHorizontalImage(null);
        return;
      case 'vertical':
        setVerticalImage(null);
        return;
      case 'focus':
        setFocusImage(null);
        return;
      default:
        return;
    }
  };

  const deepLinkParameterHandler = (name, value) => {
    if (name === 'carouselType') {
      let initList = contentTypeList.filter((f) => f.carouselType === value);
      setDeepLinkParameter({ ...defaultLinkParameter, [name]: value, contentType: isEmpty(initList) ? '' : initList[0].value });
      setDeepLink({ isDeepLink: value === 'noLink' ? 'N' : 'Y', contentType: '', contentId: '' });
    } else if (name === 'contentType') {
      setDeepLinkParameter({ ...deepLinkParameter, [name]: value, contentId: '', contentName: '' });
    } else {
      setDeepLinkParameter({ ...deepLinkParameter, [name]: value });
    }
  };

  const deepLinkContentCheckHandler = async () => {
    if (!isEmpty(deepLinkParameter.contentId) || !isEmpty(deepLinkParameter.contentName)) {
      let param = `?carouselType=${deepLinkParameter.carouselType}&deviceType=${deviceType}&countryCode=${countryCode}&categoryIdx=${categoryIdx}`;

      if (!isEmpty(deepLinkParameter.contentType)) {
        param += `&contentType=${deepLinkParameter.contentType}`;
      }
      if (!isEmpty(deepLinkParameter.contentId)) {
        param += `&contentId=${deepLinkParameter.contentId}`;
      }
      if (!isEmpty(deepLinkParameter.contentName)) {
        param += `&contentName=${deepLinkParameter.contentName}`;
      }
      const body = await getRequest(`/home-app/carousel/event/deep-link${param}`, null);
      if (body && body.result === 'SUCCESS') {
        if (deepLinkParameter.carouselType === 'channel' || deepLinkParameter.carouselType === 'vod') {
          setHeader(DeepLinkChannelHeader);
        } else if (deepLinkParameter.carouselType === 'hub') {
          setHeader(DeepLinkHubHeader);
        } else if (deepLinkParameter.carouselType === 'notification') {
          setHeader(DeepLinkNotificationHeader);
        }
        setDeepLinkContentList(!isEmpty(body.data) ? body.data : []);
      }
      setDeepLink({ ...deepLink, contentType: '', contentId: '' });
    }
  };

  const deepLinkRadioHandler = (contentType, contentId) => {
    setDeepLink({ isDeepLink: 'Y', contentType: contentType, contentId: contentId });
  };

  const saveEventBanner = async () => {
    let requestBody = new FormData();
    requestBody.append('eventBannerTitle', eventBannerTitle);
    if (horizontalImage.file) requestBody.append('horizontalImage', horizontalImage.file);
    if (verticalImage.file) requestBody.append('verticalImage', verticalImage.file);
    if (focusImage.file) requestBody.append('focusImage', focusImage.file);
    if (deepLink && deepLink.isDeepLink === 'Y') {
      requestBody.append('deepLink', deepLink.isDeepLink);
      requestBody.append('contentType', deepLink.contentType);
      requestBody.append('contentId', deepLink.contentId);
    }

    let title = '';
    let content = '';
    const body = await multiRequest('POST', '/home-app/carousel/event', requestBody);
    if (body && body.result === 'SUCCESS') {
      title = body.result;
      content = 'Saved successfully.';
      setPopupType({ ...defaultPopupType, success: true, title: title, content: content });
    } else {
      if (body && body.result === 'FAILED') {
        title = 'Image';
        content = body.message;
      } else {
        title = 'Image';
        content = 'Contact your administrator!';
      }
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
    }
  };
  // Button Data
  const defaultButton = {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    isIcon: false,
    Icon: null,
    fontWeight: 'bold',
    disabled: false,
    label: 'Button',
  };

  const editCancelButton = {
    buttonId: 'editCancelButtonId',
    width: '150px',
    size: 'large',
    type: 'outline',
    color: 'tertiary',
    label: 'Cancel',
    onClick: onClose,
  };
  const eventSaveButton = {
    buttonId: 'eventSaveButtonId',
    width: '150px',
    size: 'large',
    type: 'box',
    color: 'primary',
    label: 'Save',
    onClick: saveDialog,
  };

  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const successButton = {
    ...defaultButton,
    buttonId: 'successButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: successDialog,
  };
  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButtonId',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const saveButton = {
    ...defaultButton,
    buttonId: 'saveButtonId',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: saveEventBanner,
  };

  return (
    <div className='component-view'>
      <Dialog
        open={isOpen}
        closeX={true}
        title='Create  Event' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={onClose}
        size='large' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className=''
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              {/* Event Title */}
              <Grid item xs={12} mb={3}>
                <Box component='div' className='field-box'>
                  <TextField
                    type='outlined'
                    size='large'
                    required={false}
                    placeholder=''
                    defaultValue={null}
                    label='Event Title'
                    autoComplete='off'
                    inputProps={{ maxLength: 200 }}
                    InputLabelProps={{ shrink: true }}
                    value={eventBannerTitle}
                    onChange={(event) => titleChangeHandler(event)}
                  />
                </Box>
              </Grid>
              {/* Horizontal Image */}
              <Grid item xs={12} mb={1}>
                <Box className='field-box'>
                  <FileUpload
                    id='horizontalFileUpload'
                    buttonLabel='Upload'
                    size='large'
                    label='Horizontal Image'
                    placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                    accept=''
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    onFileChange={(file) => onFileChangeHandler('horizontal', file)}
                    value={isEmpty(horizontalImage) ? '' : horizontalImage.name}
                  />
                  <Typography component='p' className='upload-info-text'>
                    Recommended Size : <strong>78 x 78</strong>
                  </Typography>
                </Box>
              </Grid>
              {/* 파일 업로드*/}
              <Grid item xs={12} mb={3}>
                <Box className='upload-box'>
                  <div className='upload-total-count'>Upload File</div>
                  {!isEmpty(horizontalImage) && (
                    <Box component='div' className='upload-file-list' key={'uploaded-background-horizontalImage'}>
                      <Box className='box-content align-row' display='flex'>
                        <Box className='left-area'>
                          <CustomImage src={horizontalImage.preview} fit={false} wrapWidth='120px' wrapHeight='64px' alt='' rounded />
                        </Box>
                        <Box className='right-area'>
                          <Box component='div' className='upload-info-wrap'>
                            <Typography component='p' className='file-name'>
                              {horizontalImage.name}
                            </Typography>
                            <Box component='ul' className='file-list-area'>
                              <Box component='li' className='item'>
                                Recommended Size : 78 x 78{' '}
                              </Box>
                              <Box component='li' className='item'>
                                Actual Image Size: {horizontalImage.height} x {horizontalImage.width}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <IconButton className='btn-close' onClick={() => removeImageHandler('horizontal')}>
                        <CloseIcon24Black />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Vertical Image */}
              <Grid item xs={12} mb={0}>
                <Box className='field-box'>
                  <FileUpload
                    id='verticalFileUpload'
                    buttonLabel='Upload'
                    size='large'
                    label='Vertical Image'
                    placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                    accept=''
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    onFileChange={(file) => onFileChangeHandler('vertical', file)}
                    value={isEmpty(verticalImage) ? '' : verticalImage.name}
                  />
                  <Typography component='p' className='upload-info-text'>
                    Recommended Size : <strong>78 x 78</strong>
                  </Typography>
                </Box>
              </Grid>

              {/* 파일 업로드  */}
              <Grid item xs={12} mb={3}>
                <Box className='upload-box'>
                  <div className='upload-total-count'>Upload File</div>
                  {!isEmpty(verticalImage) && (
                    <Box component='div' className='upload-file-list' key={'uploaded-background-vertical-image'}>
                      <Box className='box-content align-row' display='flex'>
                        <Box className='left-area'>
                          <CustomImage src={verticalImage.preview} fit={false} wrapWidth='120px' wrapHeight='64px' alt='' rounded />
                        </Box>
                        <Box className='right-area'>
                          <Box component='div' className='upload-info-wrap'>
                            <Typography component='p' className='file-name'>
                              {verticalImage.name}
                            </Typography>
                            <Box component='ul' className='file-list-area'>
                              <Box component='li' className='item'>
                                Recommended Size : 78 x 78{' '}
                              </Box>
                              <Box component='li' className='item'>
                                Actual Image Size: {verticalImage.height} x {verticalImage.width}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <IconButton className='btn-close' onClick={() => removeImageHandler('vertical')}>
                        <CloseIcon24Black />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Focus Image */}
              <Grid item xs={12} mb={0}>
                <Box className='field-box'>
                  <FileUpload
                    id='focusFileUpload'
                    buttonLabel='Upload'
                    size='large'
                    label='Focus Image'
                    placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                    accept=''
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    onFileChange={(file) => onFileChangeHandler('focus', file)}
                    value={isEmpty(focusImage) ? '' : focusImage.name}
                  />
                  <Typography component='p' className='upload-info-text'>
                    Recommended Size : <strong>78 x 78</strong>
                  </Typography>
                </Box>
              </Grid>

              {/* 파일 업로드  */}
              <Grid item xs={12} mb={3}>
                <Box className='upload-box'>
                  <div className='upload-total-count'>Upload File</div>
                  {!isEmpty(focusImage) && (
                    <Box component='div' className='upload-file-list' key={'uploaded-background-vertical-image'}>
                      <Box className='box-content align-row' display='flex'>
                        <Box className='left-area'>
                          <CustomImage src={focusImage.preview} fit={false} wrapWidth='120px' wrapHeight='64px' alt='' rounded />
                        </Box>
                        <Box className='right-area'>
                          <Box component='div' className='upload-info-wrap'>
                            <Typography component='p' className='file-name'>
                              {focusImage.name}
                            </Typography>
                            <Box component='ul' className='file-list-area'>
                              <Box component='li' className='item'>
                                Recommended Size : 78 x 78{' '}
                              </Box>
                              <Box component='li' className='item'>
                                Actual Image Size: {focusImage.height} x {focusImage.width}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <IconButton className='btn-close' onClick={() => removeImageHandler('focus')}>
                        <CloseIcon24Black />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Deep Link */}
              <Grid item xs={12} mb={3}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='deepLink-carouselType'
                    fullWidth
                    size='large'
                    width='100%'
                    selectOptions={deepLinkCarouselTypeList}
                    disabled={false}
                    required={false}
                    label='Deep Link'
                    isFirstSelected={true}
                    placeholder=''
                    shrink
                    selected={deepLinkParameter.carouselType}
                    onChange={(value) => deepLinkParameterHandler('carouselType', value)}
                  />
                </Box>
              </Grid>
              {!isEmpty(deepLinkParameter) && deepLinkParameter.carouselType !== 'noLink' && (
                <Grid item xs={12} mb={1}>
                  <Box className='field-wrap' display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} gap={1}>
                      {deepLinkParameter.carouselType !== 'notification' && (
                        <Box component='div' className='field-box' width={220}>
                          <SelectBox
                            id='deepLink-contentType'
                            fullWidth
                            size='large'
                            label='Content Type'
                            required={false}
                            selectOptions={isEmpty(contentTypeList) ? [] : contentTypeList.filter((f) => f.carouselType === deepLinkParameter.carouselType)}
                            disabled={false}
                            isFirstSelected={true}
                            placeholder=''
                            shrink
                            selected={deepLinkParameter.contentType}
                            onChange={(value) => deepLinkParameterHandler('contentType', value)}
                          />
                        </Box>
                      )}
                      <Box component='div' className='field-box' width={220}>
                        <TextField
                          id='deepLink-contentId'
                          type='outlined'
                          size='large'
                          maxWidth='220px'
                          placeholder=''
                          label='Content ID'
                          autoComplete='off'
                          InputLabelProps={{ shrink: true }}
                          value={deepLinkParameter.contentId}
                          onChange={(event) => deepLinkParameterHandler('contentId', event.target.value)}
                        />
                      </Box>
                      <Box component='div' className='field-box' width={220}>
                        <TextField
                          id='deepLink-contentName'
                          type='outlined'
                          size='large'
                          maxWidth='220px'
                          placeholder='Content Name'
                          defaultValue={null}
                          label='Content Name'
                          autoComplete='off'
                          InputLabelProps={{ shrink: true }}
                          value={deepLinkParameter.contentName}
                          onChange={(event) => deepLinkParameterHandler('contentName', event.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        color={!isEmpty(deepLinkParameter.contentId) || !isEmpty(deepLinkParameter.contentName) ? 'primary' : 'tertiary'}
                        type='box'
                        size='medium'
                        label='Search'
                        isIcon={false}
                        onClick={deepLinkContentCheckHandler}
                      />
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
            {!isEmpty(deepLinkParameter) && deepLinkParameter.carouselType !== 'noLink' && (
              <Box mt={2}>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {header.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {
                              <SortLabel
                                labelText={column.label}
                                labelId={column.labelId}
                                sortable={column.sortable}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={dataSorting}
                              />
                            }
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* table Body */}
                    <TableBody className='tbody'>
                      {deepLinkContentList.map((td, rowIndex) => (
                        <TableRow className='tr' key={td.id || rowIndex}>
                          {header.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {column.radio ? (
                                <CustomRadio
                                  //value={column.value}
                                  checked={deepLink.contentId === td.contentId}
                                  label={td[column.labelId]}
                                  onChange={() => deepLinkRadioHandler(deepLinkParameter.carouselType, td.contentId)}
                                  //disabled={column.disabled}
                                  name={`radio-group`}
                                  sx={{ mr: column.mr }}
                                />
                              ) : column.labelId === 'contentImage' ? (
                                <CustomImage src={td[column.labelId]} fit={false} alt='' wrapWidth='147px' wrapHeight='64px' rounded />
                              ) : (
                                td[column.labelId]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>
            )}
          </>
        }
        buttons={[editCancelButton, eventSaveButton]}
      />
      {(popupType.alarm || popupType.save || popupType.success) && (
        <BasicDialog
          title={popupType.title}
          contentTitle={popupType.content}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={closeDialog}
          buttons={popupType.alarm ? [closeButton] : popupType.save ? [cancelButton, saveButton] : popupType.success ? [successButton] : []}
        />
      )}
    </div>
  );
}
//{ ...successButton, onClick: returnList }
export default EventCreatePop;
