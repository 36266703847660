import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import RadioGroup from '@mui/material/RadioGroup';
import CustomRadio from 'components/component/BasicRadio';
import { Box, Typography } from '@mui/material';
import LGChannelsShelfLink from './LGChannelsShelfLink';
import LGChannelsShelfManual from './LGChannelsShelfManual';

// 메인 컨텐츠
function LGChannelsShelf(props) {
  const { name } = props;
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'LG Channels Shelf',
  };
  const radioOptions = [
    { id: 'Link', checked: true, value: 'Link', label: 'Link', name: 'radio' },
    { id: 'Manual', checked: false, value: 'Manual', label: 'Manual', name: 'radio' },
  ];

  const initialCheckedValue = radioOptions.find((option) => option.checked)?.value || 'option1';
  const [selectedValue, setSelectedValue] = useState(initialCheckedValue);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <Box sx={{ display: 'flex' }} className='wrapper'>
        <Box className='main-container category-tab-list' component='main'>
          <Box className='top-title-container'>
            <Box className='top-title-box'>
              <Box className='title-area'>
                <Typography component='p' className='title'>
                  LG Channels shelf
                </Typography>
              </Box>
              <Box className='bread-area'>
                <BreadCrumb optionMenu={optionMenu} />
              </Box>
            </Box>
          </Box>

          <Box className='content-wrap category-list' component='div'>
            <Box component='div' className='white-box filter-box'>
              <Box mb={2}>
                <RadioGroup
                  value={selectedValue}
                  onChange={handleRadioChange}
                  className='align-h'
                >
                  {radioOptions.map(option => (
                    <Box key={option.value} sx={{ mr: 7 }}>
                      <CustomRadio
                        value={option.value}
                        label={option.label}
                        onChange={handleRadioChange}
                        disabled={option.disabled}
                        name={name}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </Box>
              {selectedValue === 'Link' ? (
                <LGChannelsShelfLink/>
              ) : (
                <LGChannelsShelfManual/>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
LGChannelsShelf.propTypes = {
  serviceCountry: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  serviceCountry: state.common.country.serviceCountry,
})
export default connect(mapStateToProps)(LGChannelsShelf);
