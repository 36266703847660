import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import SelectBox from "../../../components/component/BasicSelectBox";
import {SchedulesTh} from "./data/ChannelStatusData";
import DatePicker from "../../../components/component/BasicDatePicker";
import Button from "../../../components/component/BasicButton";
import CustomImage from "../../../components/component/BasicImage";
import SearchField from "../../../components/component/BasicSearchField";
import CheckBox from "../../../components/component/BasicCheckBox";
import TableSortLabel from "@mui/material/TableSortLabel";
import React, {useEffect, useState} from "react";

import { styled } from '@mui/material/styles';
import dayjs from "dayjs";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import PopProgramDetail from "../../Common/PopProgramDetail";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '',
  overflowY: 'auto',
}));

function PartnerProgramScheduleList(props) {
  const { currentUser } = props;

  // 서버에서 받은 provider 별로 지원하는 국가 목록 리스트
  const [countryByProvider, setCountryByProvider] = useState([]);

  const [paramProvider, setParamProvider] = useState([]);
  const [paramCountry, setParamCountry] = useState([]);
  const [paramChannelName, setParamChannelName] = useState([]);
  const [paramDate, setParamDate] = useState(dayjs());

  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedChannelName, setSelectedChannelName] = useState('All');
  const [selectedChannelInfo, setSelectedChannelInfo] = useState({});

  const [allScheduleInfo, setAllScheduleInfo] = useState([]);
  const [channelInfo, setChannelInfo] = useState([]);
  const [scheduleTableData, setScheduleTableData] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState('');

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [programDetailOpen, setProgramDetailOpen] = useState(false);
  const [popupProgramId, setPopupProgramId] = useState('');

  useEffect(() => {
    getCountryByCp();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedProvider && countryByProvider.length > 0) {
      const tempCountry = countryByProvider.filter(item => item.providerName === selectedProvider).map(item => item.country).join(',').split(',');
      setSelectedCountry(tempCountry[0]);

      const tempCountryOption = [
        ...tempCountry.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text'
        })),
      ];

      setParamCountry([...tempCountryOption]);
    }
  }, [selectedProvider, countryByProvider]);

  useEffect(() => {
    const channelNames = allScheduleInfo.map(schedule => (
      { id: schedule.channelName, value: schedule.channelName, option: schedule.channelName, type: 'text' }
    ))
    setParamChannelName(channelNames)
    if (allScheduleInfo.length > 0) {
      const channelName = allScheduleInfo[0].channelName
      setSelectedChannelName(channelName);

      setChannelAndScheduleInfoByChannelName(channelName)
    }
    // eslint-disable-next-line
  }, [allScheduleInfo]);

  useEffect(() => {
    setChannelAndScheduleInfoByChannelName(selectedChannelName)
    // eslint-disable-next-line
  }, [selectedChannelName]);

  useEffect(() => {
    if (paramDate && selectedCountry) {
      getSchedules();
    }
    // eslint-disable-next-line
  }, [paramDate, selectedCountry]);

  useEffect(() => {
    setScheduleTableData(channelInfo.filter(
      (item) => item.programId?.toLowerCase().includes(searchTextInput.toLowerCase())
          || item.title?.toLowerCase().includes(searchTextInput.toLowerCase())));
    // eslint-disable-next-line
  }, [searchTextInput]);

  const handleSelectedProvider = (provider) => {
    setSelectedCountry('');
    setSelectedProvider(provider);
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedSchedulesTdRows = sortRows([...scheduleTableData], orderBy, order);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const setChannelAndScheduleInfoByChannelName = (channelName) => {
    const channel = allScheduleInfo.find(schedule => schedule.channelName === channelName);
    setSelectedChannelInfo(channel);

    if (channel && channel.schedules) {
      const tableInfo = channel.schedules.map((schedule, idx) => (
        {
          id: schedule.startDateTime, time: schedule.startDateTime.substring(11, 16), programId: schedule.programId,
          title: schedule.programTitle
        }
      ));
      setChannelInfo(tableInfo);
      setScheduleTableData(tableInfo);
    }
  }

  const getCountryByCp = () => {
    fetch(`${BASE_URL}/statistics/filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        if (body.data.countryByCp) {
          setCountryByProvider(body.data.countryByCp);

          let tempProviderOption = [];
          if (currentUser && (currentUser.role === 'ROLE_CP' || currentUser.role === 'ROLE_STUDIO')) {
            setSelectedProvider(currentUser.provider.providerName);

            tempProviderOption = [
              {
                id: currentUser.provider.providerName,
                value: currentUser.provider.providerName,
                option: currentUser.provider.providerName,
                type: 'text',
              },
            ];
          } else {
            const tempProviderName = body.data.countryByCp.map((item) => item.providerName);
            setSelectedProvider(tempProviderName[0]);

            tempProviderOption = [
              ...tempProviderName.map(item => ({
                id: item,
                value: item,
                option: item,
                type: 'text',
              })),
            ];
          }

          setParamProvider([...tempProviderOption]);
        }
      } else {
        throw new Error(`countryByCp request fail`);
      }
    })
    .catch(error => {
      console.error('Partner Program Schedule List:', error);
    });
  }

  const getSchedules = () => {
    const param = `?countryCode=${selectedCountry}&providerName=${selectedProvider}&date=${paramDate}`;
    fetch(`${BASE_URL}/channel-status/channel/schedules${param}`, {
      method: 'GET',
      headers: getHeaders()
    })
      .then(res => res.json())
      .then(body => {
        const schedules = body.data.map(schedule => {
          return {
            ...schedule,
            schedules: schedule.schedules.sort((a, b) => new Date(a.endDateTime) - new Date(b.endDateTime))
          };
        });
        setAllScheduleInfo(schedules);
      })
  }

  const programDialogOpen = (programId) => {
    setPopupProgramId(programId);
    setProgramDetailOpen(true);
  }

  return (
    <div className='tab-panel schedule'>
      <Box className='panel-wrap'>
        <Box component='div' className='white-box filter-box'>
          <Box className='form-wrap-box'>
            <Box className='form-area left'>
              <Box className='component-wrap col-5'>
                <Box component='div' className='col'>
                  <SelectBox
                    id='select-cp'
                    size='large'
                    shrink={true}
                    label='CP Name'
                    selectOptions={paramProvider}
                    disabled={false}
                    placeholder='CP Name'
                    selected={selectedProvider}
                    onChange={handleSelectedProvider}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='tab2-country'
                    size='large'
                    shrink={true}
                    label='Country'
                    required={true}
                    selectOptions={paramCountry}
                    disabled={false}
                    isFirstSelected={true}
                    placeholder='Country'
                    selected={selectedCountry}
                    onChange={setSelectedCountry}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='tab2-channel-name'
                    size='large'
                    shrink={true}
                    label='Channel Name'
                    selectOptions={paramChannelName}
                    disabled={false}
                    placeholder='Channel Name'
                    selected={selectedChannelName}
                    onChange={setSelectedChannelName}
                  />
                </Box>
                <Box component='div' className='col auto'>
                  <DatePicker
                    className='input-date-picker'
                    format='MM/DD/YYYY'
                    size='large'
                    shrink
                    label='Date'
                    singleDate={true}
                    sx={{width: '250px'}}
                    value={paramDate}
                    onChange={(newValue) => setParamDate(newValue)}
                  />
                  <Button
                    color='tertiary'
                    type='outline'
                    size='medium'
                    label='Today'
                    isIcon={false}
                    autosize
                    onClick={() => setParamDate(dayjs())}
                  />
                </Box>
              </Box>
            </Box>
            <div className='form-area right'></div>
          </Box>
        </Box>

        <Box className='white-box white-box-wrap'>
          <Box component='div' className='title-wrap space-between flex-end'>
            <Box component='div' className='left-area'>
              <Box component='div' className='monthly-picks-wrap'>
                <div className='img'>
                  <CustomImage
                    src={selectedChannelInfo ? selectedChannelInfo.channelLogoUrl : ''}
                    fit={true}
                    wrapWidth='120px'
                    wrapHeight='64px'
                    imgWidth='120px'
                    imgHeight='64px'
                    alt=''
                    rounded
                  />
                </div>
                <Box component='div' className='box-top-label'>
                  <Box component='span' className='label'>
                    {selectedChannelInfo ? selectedChannelInfo.channelName : ''}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box component='div' className='right-area'>
              <Box display='flex' justifyContent='flex-end' gap={1} sx={{width: '100%'}}>
                {/* 검색영역 */}
                <SearchField
                  maxWidth='230px'
                  placeholder='Please enter a search term'
                  size='medium'
                  value={searchTextInput}
                  onChange={(e) => setSearchTextInput(e.target.value)}
                />
              </Box>
            </Box>
          </Box>

          <Box className='box-content'>
            <Box className='content-inner-wrap'>
              {/* Table */}
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  {/* table Head */}
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      {SchedulesTh.map((column) => (
                        <TableCell
                          key={column.id}
                          className='th'
                          align={column.align}
                          sx={{width: column.width}}
                        >
                          {column.checkbox ? (
                            <>
                              <CheckBox
                                label=''
                                value='select-all'
                                defaultChecked={false}
                                inputProps={{
                                  'aria-label': 'select all',
                                }}
                              />
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </>
                          ) : (
                            <>
                              {column.sortable ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* table Body */}
                  <TableBody className='tbody'>
                    {sortedSchedulesTdRows.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        {SchedulesTh.map(function (column, colIndex) {
                          return <TableCell key={column.id} className='td' align={column.align}>
                            {colIndex === 0 ? (
                              td[`${column.id}`]
                            ) : colIndex === 1 ? (
                              td[`${column.id}`]
                            ) : colIndex === 2 ? (
                              td[`${column.id}`]
                            ) : (
                              <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='Detail'
                                isIcon={false}
                                Icon=''
                                sx={{minWidth: 'auto'}}
                                onClick={() => programDialogOpen(td.programId)}
                              />
                            )}
                          </TableCell>;
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
      {programDetailOpen && <PopProgramDetail open={programDetailOpen} close={() => setProgramDetailOpen(false)} programId={popupProgramId} />}
    </div>
  )
}

export default PartnerProgramScheduleList;