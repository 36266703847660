// src/data.js

export const InformationKeywordTh = [
  {
    id: 'contentType',
    align: 'left',
    label: 'Type',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'countryCode',
    align: 'left',
    label: 'Country',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerId',
    align: 'left',
    label: 'CP Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'keyword',
    align: 'left',
    label: 'Keyword',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'count',
    align: 'right',
    label: 'Count',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
];

export const InformationKeywordTd = [
  {
    id: 'InformationKeywordTd-1',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationKeywordTd-2',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationKeywordTd-3',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationKeywordTd-4',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationKeywordTd-5',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationKeywordTd-6',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationKeywordTd-7',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationKeywordTd-8',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationKeywordTd-9',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: 'Movie',
    data05: '1',
  },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const InformationGenreTh = [
  {
    id: 'contentType',
    align: 'left',
    label: 'Type',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'countryCode',
    align: 'left',
    label: 'Country',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'genreCode',
    align: 'left',
    label: 'Genre Code',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'genreName',
    align: 'left',
    label: 'Genre Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'count',
    align: 'right',
    label: 'Count',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const InformationGenreTd = [
  {
    id: 'InformationGenreTd-1',
    data01: 'Live',
    data02: 'KR',
    data03: '10',
    data04: 'Comedy',
    data05: '1',
  },
  {
    id: 'InformationGenreTd-2',
    data01: 'Live',
    data02: 'KR',
    data03: '10',
    data04: 'Music',
    data05: '1',
  },
  {
    id: 'InformationGenreTd-3',
    data01: 'Live',
    data02: 'KR',
    data03: '10',
    data04: 'Sport',
    data05: '1',
  },
  {
    id: 'InformationGenreTd-4',
    data01: 'Live',
    data02: 'KR',
    data03: '10',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationGenreTd-5',
    data01: 'Live',
    data02: 'KR',
    data03: '10',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationGenreTd-6',
    data01: 'Live',
    data02: 'KR',
    data03: '10',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationGenreTd-7',
    data01: 'Live',
    data02: 'KR',
    data03: '10',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationGenreTd-8',
    data01: 'Live',
    data02: 'KR',
    data03: '10',
    data04: 'Movie',
    data05: '1',
  },
  {
    id: 'InformationGenreTd-9',
    data01: 'Live',
    data02: 'KR',
    data03: '10',
    data04: 'Movie',
    data05: '1',
  },
];

export const InformationGenreCpTh = [
  {
    id: 'contentType',
    align: 'left',
    label: 'Type',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'countryCode',
    align: 'left',
    label: 'Country',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerId',
    align: 'left',
    label: 'CP Name',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'genreCode',
    align: 'left',
    label: 'Genre Code',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'genreName',
    align: 'left',
    label: 'Genre Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'count',
    align: 'right',
    label: 'Count',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const InformationGenreCpTd = [
  {
    id: 'InformationGenreCpTd-1',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '17',
    data05: 'Comedy',
    data06: '100',
  },
  {
    id: 'InformationGenreCpTd-2',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '17',
    data05: 'Comedy',
    data06: '100',
  },
  {
    id: 'InformationGenreCpTd-3',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '17',
    data05: 'Comedy',
    data06: '100',
  },
  {
    id: 'InformationGenreCpTd-4',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '17',
    data05: 'Comedy',
    data06: '100',
  },
  {
    id: 'InformationGenreCpTd-5',
    data01: 'Live',
    data02: 'KR',
    data03: 'AMG',
    data04: '17',
    data05: 'Comedy',
    data06: '100',
  },
];
