import React, {useMemo, useCallback, memo, useRef} from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BASE_URL } from 'constants/index';
import { getHeadersFormData } from 'utils/Header';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

const QuillEditor = memo(({ id, htmlContent, setHtmlContent, readOnly }) => {
  const quillRef = useRef();

  const imageHandler = useCallback(() => {
    const formData = new FormData();
    let url = '';
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('name', 'image');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      formData.append('image', file);
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);

      fetch(`${BASE_URL}/common/editor/image`, {
        method: 'POST',
        headers: getHeadersFormData(),
        body: formData,
      })
      .then((res) => res.json())
      .then((body) => {
        if (body.result === 'SUCCESS') {
          url = body.data.url;
          quill.setSelection(range.index + 1);
          quill.insertEmbed(range.index, 'image', url);
        }
      });
    };
  }, [quillRef]);

  const handleDropAndPaste = async (image, type) => {

    if (image.startsWith('data:image')) {
      const split = image.split(',');
      const base64 = split[1];

      const byteArray = Uint8Array.from(atob(base64), c => c.charCodeAt(0));
      const blob = new Blob([byteArray], { type: type });

      // Map MIME type to file extension
      const mimeToExt = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/bmp': 'bmp',
        'image/webp': 'webp'
      };
      const extension = mimeToExt[type] || 'png';

      const imageFile = new File([blob], `image.${extension}`, { type: type });

      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);
      let url = '';
      const formData = new FormData();
      formData.append('image', imageFile);

      fetch(`${BASE_URL}/common/editor/image`, {
        method: 'POST',
        headers: getHeadersFormData(),
        body: formData,
      })
      .then((res) => res.json())
      .then((body) => {
        if (body.result === 'SUCCESS') {
          url = body.data.url;
          quill.setSelection(range.index + 1);
          quill.insertEmbed(range.index, 'image', url);
        }
      });
    }
  };

  const modules = useMemo(
      () => {
        if (readOnly) {
          return {
            toolbar: false,
          };
        }
        return {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
                { align: [] },
              ],
              ['image'],
            ],
            handlers: {
              image: imageHandler,
            },
          },
          imageDropAndPaste: {
            handler: handleDropAndPaste
          }
        }
      }, [imageHandler, readOnly]);

  return (
      <>
        <ReactQuill
            ref={quillRef}
            value={htmlContent}
            onChange={setHtmlContent}
            modules={modules}
            theme="snow"
            id={id}
            readOnly={readOnly}
        />
      </>
  );
});

export default QuillEditor;