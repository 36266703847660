import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import TextField from 'components/component/BasicTextField';
import SearchField from 'components/component/BasicSearchField';
import Button from 'components/component/BasicButton';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import { RefreshIcon } from 'components/component/BasicIcon';
// data
import { AdminTh } from 'pages/Configuration/Account/data/AccountData';
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import PopAddAccount from "./PopAddAccount";
import PopAccountChangePassword from "./PopAccountChangePassword";
import Dialog from "../../../components/component/BasicDialog";

const NoRecords = styled('span')`
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;
// 메인 컨텐츠
function Account() {
  const [cps, setCps] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedCp, setSelectedCp] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [id, setId] = useState('');
  const [accounts, setAccounts] = useState([]);
  // 화면에서 사용할 account 데이터
  const [displayAccounts, setDisplayAccounts] = useState([]);

  // 서버에서 가져온 데이터가 없는 경우 체크
  const [isEmptyData, setEmptyData] = useState(false);

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // 검색에서 선택한 값과 입력한 값
  const [searchTerm, setSearchTerm] = useState('');

  const [addPopupOpen, setAddPopupOpen] = useState(false);
  const [pwdChangePopupOpen, setPwdChangePopupOpen] = useState(false);
  const [currentId, setCurrentId] = useState('');

  const [checkedAccounts, setCheckedAccounts] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);

  // Button
  const handleReset = () => {
    // Reset the selected CP and Role to the first item in the list
    if (cps.length > 0 && cps[0]) {
      setSelectedCp(cps[0].value);
    }
    if (roles.length > 0 && roles[0]) {
      setSelectedRole(roles[0].value);
    }
  };

  const handleClose = () => {
    accountList();
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const onClickCheckAll = (e) => {
    if (e.target.checked) {
      setCheckedAccounts(displayAccounts.map((item) => item.id));
      setAllChecked(true);
    } else {
      setCheckedAccounts([]);
      setAllChecked(false);
    }
  };

  const onClickCheckBox = (e, id) => {
    let checkedList = [...checkedAccounts];
    if (e.target.checked) {
      checkedList.push(id);
    } else {
      checkedList = checkedList.filter((item) => item !== id);
    }
    if (displayAccounts.length === checkedList.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setCheckedAccounts(checkedList);
  }

  const getIfChecked = (id) => {
    return checkedAccounts.includes(id);
  };

  const cpFilterList = () => {
    fetch(`${BASE_URL}/account/cp`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setCps(body.data.map((cp) => (
            { id: cp, value: cp, option: cp, type: 'text' }
        )))
      }
    })
  };

  const roleFilterList = () => {
    fetch(`${BASE_URL}/account/role`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setRoles(body.data.map((role) => (
            { id: role, value: role, option: role, type: 'text' }
        )))
      }
    })
  };

  const accountList = () => {
    let param = `role=${selectedRole}&cpCode=${selectedCp}&id=${id}`;
    setAccounts([]);
    setPaginatedData([]);
    setPage(1);

    fetch(`${BASE_URL}/account?${param}`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAccounts(body.data.map((object) => (
            { cpIcon: object.cpIcon,
              id: object.id,
              roleCode: object.roleCode,
              cpCode: object.cpCode,
              cpName: object.cpName,
              cpParentCode: object.cpParentCode,
              cpParentName: object.cpParentName,
            }
        )))
      }
    })
  };

  const deleteAccounts = () => {
    fetch(`${BASE_URL}/account`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(checkedAccounts)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setCheckedAccounts([]);
        handleConfirmClose();
        accountList();
      } else {
      }
    })
  };

  const addButtonClick = () => {
    setAddPopupOpen(true);
  };

  const changeButtonClick = (event, id) => {
    event.preventDefault();
    setCurrentId(id);
    setPwdChangePopupOpen(true);
  };

  const handleConfirmSave = () => {
    deleteAccounts();
    setConfirmOpen(false);
  };

  const confirmPopupOpen = () => {
    if(checkedAccounts && checkedAccounts.length > 0) {
      setConfirmOpen(true);
    }
  };

  const FilterButtons = [
    {
      color: 'tertiary',
      type: 'outline icon',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      autosize: false,
      minWidth: '107px',
      onClick: handleReset, // Add the onClick handler here
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: false,
      minWidth: '120px',
      onClick: accountList, // Add the onClick handler here
    },
  ];
  const crudButtons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      autosize: true,
      onClick: confirmPopupOpen,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Add',
      isIcon: false,
      autosize: true,
      onClick: addButtonClick,
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: handleConfirmSave
    },
  ];

// 테이블
  const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    height: '100%',
    overflowY: 'auto',
  }));

  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Account',
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayAccounts].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayAccounts([...sortedData]);
  };

  useEffect(() => {
    cpFilterList();
    roleFilterList();
  }, []);

  useEffect(() => {
    if (cps && cps.length > 0 && cps[0] && roles && roles.length > 0 && roles[0]) {
      setSelectedCp(cps[0].value);
      setSelectedRole(roles[0].value);
    }
  }, [cps, roles]);

  useEffect(() => {
    if (selectedCp && selectedRole && !isInitialized) {
      accountList();
      setIsInitialized(true);
    }
    // eslint-disable-next-line
  }, [selectedCp, selectedRole]);

  useEffect(() => {
    if (accounts.length === 0) {
      setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
    } else {
      setEmptyData(false);
    }
    setDisplayAccounts([...accounts]);
  }, [accounts]);

  // Paging 처리
  useEffect(() => {
    if (displayAccounts && displayAccounts.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayAccounts.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [displayAccounts, page, itemsPerPage]);

  useEffect(() => {
    if (searchTerm) {
      const filteredData = accounts.filter((item) => {
        return Object.entries(item)
        .values(item)
        .some((value) => String(value).toLowerCase().includes(searchTerm));
      });

      setDisplayAccounts([...filteredData]);
      if (filteredData.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    } else {
      setDisplayAccounts([...accounts]);
      if (accounts.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    }
  }, [searchTerm, accounts]);

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container pr-page-container' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Account
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        {/*  //BreadCrumb Area */}

        <Box className='content-wrap ' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Role */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Role'
                      required={false}
                      selectOptions={roles}
                      selected={selectedRole}
                      onChange={setSelectedRole}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* CP Code */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='CP Code'
                      required={false}
                      selectOptions={cps}
                      selected={selectedCp}
                      onChange={setSelectedCp}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* Id */}
                  <Box component='div' className='col'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='ID'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      required={true}
                      InputLabelProps={{ shrink: false }}
                      value={id}
                      onChange={e => {
                        setId(e.target.value);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'>
                <CustomButtonGroup buttons={FilterButtons} />
              </div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                <Box className='left-area column'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      {accounts.length}
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SearchField
                        placeholder='Please enter a search term'
                        maxWidth='230px'
                        onChange={(event) => setSearchTerm(event.target.value.toLowerCase())}
                    />
                  </Box>
                </Box>

                <Box className='right-area'>
                  <CustomButtonGroup
                      buttons={crudButtons}
                      justifyContent='flex-end'
                      // onClick={}
                  />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {AdminTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                  checked={allChecked}
                                  onChange={onClickCheckAll}
                                />

                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  // 체크박스만 있을 때도 label 표시
                                  <span className='chk-th'>{column.label}</span>
                                )}
                              </>
                            ) : column.sortable ? (
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            ) : (
                              column.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {isEmptyData ? (
                          <NoRecords>
                            No records to display
                          </NoRecords>
                      ) : (
                          paginatedData.map((td, index) => (
                          <TableRow className='tr' key={td.id || index}>
                            {AdminTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  {colIndex === 0 ? (
                                      <Box display='flex'>
                                        <CheckBox
                                            label=''
                                            value='value'
                                            defaultChecked={false}
                                            onChange={(e) => onClickCheckBox(e, td.id)}
                                            checked={getIfChecked(td.id)}
                                        />
                                        <CustomImage
                                            src={td.cpIcon}
                                            fit={false}
                                            alt=''
                                            wrapWidth='96px'
                                            wrapHeight='64px'
                                            rounded
                                        />
                                      </Box>
                                  ) : colIndex === 1 ? (
                                      td.id
                                  ) : colIndex === 2 ? (
                                      td.roleCode
                                  ) : colIndex === 3 ? (
                                      td.cpCode
                                  ) : colIndex === 4 ? (
                                      td.cpName
                                  ) : colIndex === 5 ? (
                                      td.cpParentCode
                                  ) : colIndex === 6 ? (
                                      td.cpParentName
                                  ) : colIndex === 7 ? (
                                      <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='CHANGE'
                                          isIcon={false}
                                          Icon=''
                                          iconPos={undefined}
                                          autosize
                                          onClick={(event) => changeButtonClick(event, td.id)}
                                      />
                                  ) : (
                                      td[`data0${colIndex + 1}`]
                                  )}
                                </TableCell>
                            ))}
                          </TableRow>
                          ))
                      )}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={Math.ceil(displayAccounts.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
            </div>
          </Box>
        </Box>
      </Box>
      <PopAddAccount roles={roles.slice(1)} cps={cps.slice(1)} open={addPopupOpen} close={() => setAddPopupOpen(false)} callbackClose={handleClose} />
      <PopAccountChangePassword id={currentId} open={pwdChangePopupOpen} close={() => setPwdChangePopupOpen(false)} />
      <Dialog
          open={isConfirmOpen} // boolean 값으로 설정
          handleClose={handleConfirmClose}
          closeX={false}
          size='small' // large, medium, small
          title='Confirm' // Popup Title
          contentTitle={false} // Content Title
          bottomDivider={false}
          contentText='Are you sure you want to delete the selected items?' // Popup Content Text
          buttons={confirmDialogButtons}
      />
    </Box>
  );
}

export default Account;
