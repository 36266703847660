import React, { useState } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  Box,
  IconButton,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import Snackbar from 'components/component/BasicSnackbar';
import CustomTabs from 'components/component/BasicTabs';
import SelectBox from 'components/component/BasicSelectBox';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
// css
import 'assets/scss/page/main.scss';
// Data
import { InformationTh, InformationTd } from 'pages/Organization/TabCategory/data/TabCategoryData';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1000px',
    maxWidth: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
  display: flex;
  gap: 16px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  display: flex;
  gap: 20px;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '270px',
  overflowY: 'auto',
}));

const selOptionBusinessData3 = [
  { id: 1, value: 'Auto Roll', option: 'Auto Roll', type: 'text' },
  { id: 2, value: 'Auto', option: 'Auto', type: 'text' },
];

// 메인 컨텐츠
function PopInformationAutoRule(props) {
  const { onClose } = props;

  const handleClose = () => {
    onClose();
  };
  const onCancelBtn = () => {
    handleClose();
  };

  const [openSelect, setOpenSelect] = useState(false);

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedInformationTdRows = sortRows([...InformationTd], orderBy, order);

  // Tab
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabs = [{ label: 'Latest First' }, { label: 'Popular First' }, { label: 'Start Time First' }];

  const buttons = [
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: 'OK',
      isIcon: 'false',
      IconType: '',
      iconPos: undefined,
      className: 'btn',
      onClick:onCancelBtn,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog open={true} size='medium' fullWidth className='pop-channel-count'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Information</DialogTitle>
          <SelectBox
            id='select-01'
            fullWidth
            size='medium'
            maxWidth='180px'
            selectOptions={selOptionBusinessData3}
            disabled={false}
            isFirstSelected={true}
            label=''
            open={openSelect}
            setOpen={setOpenSelect}
            shrink={false}
          />
        </HeaderArea>
        <DialogContent className='pop-content'>
          <BoxContainer>
            <Box className='information-container'>
              <CustomTabs tabStyle='type-01' value={value} handleChange={handleChange} tabs={tabs} />

              {value === 0 && (
                <div className='tab-panel'>
                  <div className='panel-wrap'>
                    <Box className='detail-list-container'>
                      {/* Table */}
                      <CustomTableContainer className='table-container'>
                        <Table className='custom-table' aria-label='table' stickyHeader>
                          {/* table Head */}
                          <TableHead className='thead'>
                            <TableRow className='tr'>
                              {InformationTh.map((column) => (
                                <TableCell
                                  key={column.id}
                                  className='th'
                                  align={column.align}
                                  sx={{ width: column.width }}
                                >
                                  <>
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          {/* table Body */}
                          <TableBody className='tbody'>
                            {sortedInformationTdRows.map((td, index) => (
                              <TableRow className='tr' key={td.id || index}>
                                {InformationTh.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align}>
                                    {colIndex === 1 ? (
                                      td.data02 ? (
                                        Array.isArray(td.data02) ? (
                                          td.data02.map((item, index) => (
                                            <span
                                              key={index}
                                              className={`td-text ${
                                                item.status === 'lv1' ? 'lv1' : item.status === 'lv2' ? 'lv2' : ''
                                              }
                                              `}
                                            >
                                              {item.text}
                                            </span>
                                          ))
                                        ) : (
                                          <span
                                            className={
                                              td.data02.status === 'lv1'
                                                ? 'lv1'
                                                : td.data02.status === 'lv2'
                                                ? 'lv2'
                                                : ''
                                            }
                                          >
                                            {td.data02.text}
                                          </span>
                                        )
                                      ) : (
                                        <span>{td[`data0${colIndex + 1}`]}</span>
                                      )
                                    ) : (
                                      td[`data0${colIndex + 1}`]
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </CustomTableContainer>

                      <Box className='message-box' marginTop='20px'>
                        <Typography component='p' className='list-text'>
                          <span className='point'>*</span> CMP VOD Genre 에서 사용하는 마이그레이션 값 사용
                        </Typography>
                        <Typography component='p' className='list-text'>
                          <span className='point'>**</span> VOD Genre가 가능해질 시 제외
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </div>
              )}
            </Box>
          </BoxContainer>
        </DialogContent>

        <Divider sx={{ margin: '24px 0' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={buttons} justifyContent='center' />
        </DialogActions>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={false}
        Duration='1000'
        bottom={'8%'}
      />
    </div>
  );
}

export default PopInformationAutoRule;
