import React, {useEffect, useState} from 'react';
import Button from 'components/component/BasicButton';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from 'components/component/BasicPagination';
import FileUpload from 'components/component/BasicFileUpload';
import SearchField from 'components/component/BasicSearchField';
import Snackbar from 'components/component/BasicSnackbar';
import * as XLSX from 'xlsx';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { styled } from '@mui/material/styles';
import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));
// 메인 컨텐츠
function CodeManagementDMA({ dmaCodes, handleSearch }) {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayDmaCodes].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayDmaCodes([...sortedData])
  };

  const [displayDmaCodes, setDisplayDmaCodes] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (handleSearch) {
      handleSearch();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisplayDmaCodes(dmaCodes);
  }, [dmaCodes]);

  useEffect(() => {
    setDisplayDmaCodes(dmaCodes.filter(
        (item) => item.channelId.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.channelName.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.dmaGroupCode.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.dmaGroupName.toLowerCase().includes(searchTextInput.toLowerCase())));
    setPage(1);
    // eslint-disable-next-line
  }, [searchTextInput]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayDmaCodes.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayDmaCodes.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayDmaCodes, page, itemsPerPage]);

  const templateDownload = () => {
    let template = ['Ch. ID', 'Ch. Name', 'DMA Group Set', 'DMA Group Code',
      'DMA Group Name', 'Zip Code', 'Default Channel'];
    let ws = XLSX.utils.aoa_to_sheet([template]);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DMA Upload Template');
    XLSX.writeFile(wb, 'DMA_Upload_Template.xlsx');
  }

  const parseDmaUploadExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: ['channelId', 'channelName', 'dmaGroupSet', 'dmaGroupCode', 'dmaGroupName', 'zipCode', 'defaultChannel'],
        range: 1
      });
      uploadDmaChannels(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadDmaChannels = (data) => {
    const dmaCodes = {
      localGroupList: parseLocalGroup(data),
      localChannelList: parseLocalChannel(data)
    }

    fetch(`${BASE_URL}/code-management/dma`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(dmaCodes)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSnackbarMessage('DMA Upload Success.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        handleSearch();
      } else {
        setSnackbarMessage('DMA Upload Failed.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    })
  }

  const parseLocalGroup = (data) => {
    const uniqueMap = new Map();
    data.forEach(item => {
      const key = `${item.dmaGroupSet}-${item.dmaGroupCode}-${item.dmaGroupName}`;
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, {
          dmaGroupSet: item.dmaGroupSet,
          dmaGroupCode: item.dmaGroupCode,
          dmaGroupName: item.dmaGroupName,
          zipCodes: [item.zipCode]
        });
      } else {
        uniqueMap.get(key).zipCodes.push(item.zipCode);
      }
    });

    return Array.from(uniqueMap.values());
  }

  const parseLocalChannel = (data) => {
    const uniqueSet = new Set();
    return data.filter(item => {
      const key = `${item.channelId}-${item.dmaGroupSet}-${item.dmaGroupCode}-${item.dmaGroupName}`;
      if (uniqueSet.has(key)) {
        return false;
      } else {
        uniqueSet.add(key);
        return true;
      }
    });
  }

  return (
    <Box className='white-box white-box-wrap'>
      <div className='box-content'>
        <Box className='white-box-content-top-area' alignItems='flex-end'>
          <Box className='left-area column'>
            <Box className='total-count'>
              <Box component='span' className='label'>
                Total
              </Box>
              <Box component='span' className='value'>
                {displayDmaCodes.length}
              </Box>
            </Box>
            <Box display='flex' gap={1}>
              <SearchField placeholder='Please enter a search term' maxWidth='230px'
                           value={searchTextInput}
                           onChange={(e) => setSearchTextInput(e.target.value)}/>
            </Box>
          </Box>
                <Box className='right-area'>
                  <Box display={'flex'} flexGrow={'1'} justifyContent={'flex-end'} gap={1}>
                    <Button color='tertiary' type='outline' size='medium' label='Template download' isIcon={false} onClick={templateDownload} />
                    <Box flexGrow='1' maxWidth={450}>
                      <FileUpload
                        id='fileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
                        buttonLabel='Upload'
                        size='large'
                        placeholder='File Upload(Only .xlsx files can be uploaded.)'
                        accept='.xlsx'
                        onFileChange={parseDmaUploadExcel}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        <TableCell key={'channelId'} className='th' align={'left'} sx={{ width: '25%' }}>
                          <TableSortLabel
                            active={orderBy === 'channelId'}
                            direction={orderBy === 'channelId' ? order : 'desc'}
                            onClick={() => handleRequestSort('channelId')}
                          >
                            Ch. ID
                          </TableSortLabel>
                        </TableCell>
                        <TableCell key={'channelName'} className='th' align={'left'} sx={{ width: '25%' }}>
                          <TableSortLabel
                            active={orderBy === 'channelName'}
                            direction={orderBy === 'channelName' ? order : 'desc'}
                            onClick={() => handleRequestSort('channelName')}
                          >
                            Ch. Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell key={'dmaGroupSet'} className='th' align={'left'} sx={{ width: '15%' }}>
                          <TableSortLabel
                            active={orderBy === 'dmaGroupSet'}
                            direction={orderBy === 'dmaGroupSet' ? order : 'desc'}
                            onClick={() => handleRequestSort('dmaGroupSet')}
                          >
                            DMA Group Set
                          </TableSortLabel>
                        </TableCell>
                        <TableCell key={'dmaGroupCode'} className='th' align={'left'} sx={{ width: '10%' }}>
                          <TableSortLabel
                            active={orderBy === 'dmaGroupCode'}
                            direction={orderBy === 'dmaGroupCode' ? order : 'desc'}
                            onClick={() => handleRequestSort('dmaGroupCode')}
                          >
                            DMA Group Code
                          </TableSortLabel>
                        </TableCell>
                        <TableCell key={'dmaGroupName'} className='th' align={'left'} sx={{ width: '15%' }}>
                          <TableSortLabel
                            active={orderBy === 'dmaGroupName'}
                            direction={orderBy === 'dmaGroupName' ? order : 'desc'}
                            onClick={() => handleRequestSort('dmaGroupName')}
                          >
                            DMA Group Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell key={'defaultChannel'} className='th' align={'left'} sx={{ width: '10%' }}>
                          <TableSortLabel
                            active={orderBy === 'defaultChannel'}
                            direction={orderBy === 'defaultChannel' ? order : 'desc'}
                            onClick={() => handleRequestSort('defaultChannel')}
                          >
                            Default Channel
                          </TableSortLabel>
                        </TableCell>
                </TableRow>
              </TableHead>

              {/* table Body */}
              <TableBody className='tbody'>
                {paginatedData.map((td, index) => (
                    <TableRow className='tr' key={td.id || index}>
                      <TableCell key={'channelId'} className='td' align={'left'}>
                        {td['channelId']}
                      </TableCell>
                      <TableCell key={'channelName'} className='td' align={'left'}>
                        {td['channelName']}
                      </TableCell>
                      <TableCell key={'dmaGroupSet'} className='td' align={'left'}>
                        {td['dmaGroupSet']}
                      </TableCell>
                      <TableCell key={'dmaGroupCode'} className='td' align={'left'}>
                        {td['dmaGroupCode']}
                      </TableCell>
                      <TableCell key={'dmaGroupName'} className='td' align={'left'}>
                        {td['dmaGroupName']}
                      </TableCell>
                      <TableCell key={'defaultChannel'} className='td' align={'left'}>
                        {td['defaultChannel']}
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </Box>

        {/* Pagination */}
        <Pagination count={Math.ceil(displayDmaCodes.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
      </div>
      {/* severity: default, error, warning, info, success */}
      <Snackbar
          severity={snackbarSeverity}
          message={snackbarMessage}
          open={isSnackbarOpen}
          duration={1000}
          bottom={'8%'}
          onClose={handleSnackbarClose}
      />
    </Box>
  );
}

export default CodeManagementDMA;
