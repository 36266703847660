// src/data.js
import imgDummy01 from 'assets/images/img_dummy_04.png';

export const CountryData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Us', value: 'Us', option: 'Us', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const cpNameOption = [
  { id: 'cpname-all', value: 'All', option: 'All', type: 'text' },
  { id: 'cpname-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'cpname-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];

export const TypeOptionData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Slider-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'Slider-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];

export const RegionOptionData = [
  { id: 'Region-all', value: 'All', option: 'All', type: 'text' },
  { id: 'Region-1', value: 'Region-1', option: 'Region-1', type: 'text' },
  { id: 'Region-2', value: 'Region-2', option: 'Region-2', type: 'text' },
];

export const CountryOptionData = [
  { id: 'country-1', value: 'Us', option: 'Us', type: 'text' },
  { id: 'country-2', value: 'Italy', option: 'Italy', type: 'text' },
  { id: 'country-3', value: 'Ger', option: 'Ger', type: 'text' },
];
export const PlatformData = [
  { id: 'webOSTV-1', value: 'webOSTV', option: 'webOS TV', type: 'text' },
  { id: 'webOSTV-2', value: 'webOSTV2', option: 'webOS2', type: 'text' },
  { id: 'webOSTV-3', value: 'webOSTV3', option: 'webOS3', type: 'text' },
];
export const sdkOptionData = [
  { id: 'sdk-1', value: '10.0.0', option: '10.0.0', type: 'text' },
  { id: 'sdk-2', value: '10.2', option: '10.1.0', type: 'text' },
  { id: 'sdk-3', value: '10.3', option: '10.2.0', type: 'text' },
];
export const TabDetailData = [
  { id: 'sdk-1', value: '10.0.0', option: '10.0.0', type: 'text' },
  { id: 'sdk-2', value: '10.2', option: '10.1.0', type: 'text' },
  { id: 'sdk-3', value: '10.3', option: '10.2.0', type: 'text' },
];
export const categoryDetailData = [
  { id: 'sdk-1', value: '10.0.0', option: '10.0.0', type: 'text' },
  { id: 'sdk-2', value: '10.2', option: '10.1.0', type: 'text' },
  { id: 'sdk-3', value: '10.3', option: '10.2.0', type: 'text' },
];

export const ShelfTh = [
  {
    id: 'sdkVer',
    align: 'left',
    label: 'SDK Version',
    width: '20%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'platformVersion',
    align: 'left',
    label: 'Paltform Version',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'tabCode',
    align: 'left',
    label: 'Tab Detail',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'categoryCode',
    align: 'left',
    label: 'Category Detail',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Action',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const ShelfTd = [
  {
    id: 'ShelfTd-1',
    data01: '4.9.0',
    data02: '3.4.0',
    data03: 'T-TVSHOW on Demand',
    data04: 'C271 Featured Series',
    data05: '',
  },
  {
    id: 'ShelfTd-2',
    data01: '4.9.0',
    data02: '3.4.0',
    data03: 'T-TVSHOW on Demand',
    data04: 'C271 Featured Series',
    data05: '',
  },
];

export const PreviewTh = [
  {
    id: 'Order',
    align: 'left',
    label: 'Order',
    width: '18%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'CP',
    align: 'left',
    label: 'CP',
    width: '18%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Image',
    align: 'left',
    label: 'Image',
    width: '20%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Type',
    align: 'left',
    label: 'Type',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Title',
    align: 'left',
    label: 'Title',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const PreviewTd = [
  {
    id: 'PreviewTd-1',
    data01: '1',
    data02: 'AMG',
    data03: '',
    data04: 'VOD',
    data05: 'LG Presents ”The Rivalries”',
  },
  {
    id: 'PreviewTd-1',
    data01: '2',
    data02: 'AMG',
    data03: '',
    data04: 'VOD',
    data05: 'LG Presents ”The Rivalries”',
  },
];

export const ShelfManualTh = [
  {
    id: 'check',
    align: 'left',
    label: '',
    width: '6%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'providerName',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentId',
    align: 'left',
    label: 'Ch. ID',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'title',
    align: 'left',
    label: 'Ch. Name',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'imageUrl',
    align: 'left',
    label: 'Image',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'genreName',
    align: 'left',
    label: 'Genre',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'licenseDate',
    align: 'left',
    label: 'License Date',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const ShelfManualTd = [
  {
    id: 'ShelfManualTd-1',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'A Rainy Day in New York',
    data05: imgDummy01,
    data06: { text: 'Sport+1', tooltip: true },
    data07: '2024-08-20 ~ 2040-08-20',
  },
  {
    id: 'ShelfManualTd-2',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'A Rainy Day in New York',
    data05: '',
    data06: { text: 'Sport', tooltip: true },
    data07: '2024-08-20 ~ 2040-08-20',
  },
  {
    id: 'ShelfManualTd-3',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'A Rainy Day in New York',
    data05: '',
    data06: { text: 'Sport+1', tooltip: false },
    data07: '2024-08-20 ~ 2040-08-20',
  },
];

export const ScheduledManualTh = [
  {
    id: 'order-sdgesg',
    align: 'left',
    label: '',
    width: '2%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'resultOrder',
    align: 'left',
    label: 'Order',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentType',
    align: 'left',
    label: 'Type',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerName',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentId',
    align: 'left',
    label: 'Content ID',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'title',
    align: 'left',
    label: 'Content Name',
    width: '22%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'fixFlag',
    align: 'left',
    label: 'Fix',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'imageUrl',
    align: 'left',
    label: 'Image',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const ScheduledManualTd = [
  {
    id: 'ScheduledManualTd-1',
    data01: '',
    data02: '1',
    data03: 'live',
    data04: 'AMG',
    data05: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data06: 'A Rainy Day in New York',
    data07: '',
    data08: '',
  },
  {
    id: 'ScheduledManualTd-2',
    data01: '',
    data02: '1',
    data03: 'live',
    data04: 'AMG',
    data05: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data06: 'A Rainy Day in New York',
    data07: '',
    data08: '',
  },
];

export const ShelfHistoryDetailTd = [
  {
    id: 'HistoryDetailTd-1',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '4',
    data7: 'On > Off',
    data8: 'Updated',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-2',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '5',
    data7: 'On > Off',
    data8: 'Create',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-3',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '2',
    data7: 'On > Off',
    data8: 'Updated',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-4',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On ',
    data8: '-',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-5',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '1 > 3',
    data7: 'On > Off',
    data8: 'Deleted',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-6',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '3 > 1',
    data7: 'On',
    data8: 'Deleted',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-7',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On > Off',
    data8: 'Create',
    data9: 'taeil.lim',
  },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const ShelfHistoryDetailTh = [
  {
    id: 'shd-1',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-2',
    align: 'left',
    label: 'ID',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-3',
    align: 'left',
    label: 'Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-4',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-5',
    align: 'Event',
    label: 'Carousel',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-6',
    align: 'left',
    label: 'Order',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-7',
    align: 'left',
    label: 'Fix',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-8',
    align: 'left',
    label: 'Event',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-9',
    align: 'left',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
