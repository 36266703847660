import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import SearchField from 'components/component/BasicSearchField';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import TextField from 'components/component/BasicTextField';
import Dialog from 'components/component/BasicDialog';
import Loading from 'components/component/Loading';
import Tooltip from 'components/component/BasicTooltip';
import dayjs from 'dayjs';

import {
  Box,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

import {
  APIOption,
  StatusOption,
  ApiCallLogTitle,
} from 'partner_pages/Monitoring/APICallLogs/data/APICallLogsData';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

function APICallLogs(props) {
  const { currentUser, providerList } = props;

  const optionMenu = {
    gnbMenu: 'Monitoring',
    lnbMenu: 'API Call Logs',
  };

  // 화면에 보이는 필터 정보
  const [providerOptionList, setProviderOptionList] = useState([]);

  // 화면에 보이는 필터에서 선택한 값
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedApi, setSelectedApi] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedMessage, setSelectedMessage] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs().subtract(7, 'day')); // 7일 전
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());

  // 서버로 전달할 때에 '2024-01-01' 형태로 전달하기 위한 용도
  const [selectedStartDateString, setSelectedStartDateString] = useState(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
  const [selectedEndDateString, setSelectedEndDateString] = useState(dayjs().format('YYYY-MM-DD'));

  // 서버에서 받은 원본 데이터
  const [originalApiCallLogs, setOriginalApiCallLogs] = useState([]);
  // 화면에서 사용할 Api Call logs
  const [displayApiCallLogs, setDisplayApiCallLogs] = useState([]);

  // 검색에서 입력한 값
  const [searchTerm, setSearchTerm] = useState('');

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // 서버에서 가져온 데이터가 없는 경우 체크
  const [isEmptyData, setEmptyData] = useState(false);

  // 로딩바를 보여 줄 때 사용
  const [isDisplayApiCallLogs, setIsDisplayApiCallLogs] = useState(false);

  useEffect(() => {
    if (providerList && currentUser) {
      let tempProvider = [];
      let tempProviderName = '';

      if (currentUser.role === 'ROLE_CP' || currentUser.role === 'ROLE_STUDIO') {
        tempProvider = [
          {
            id: currentUser.provider.providerName,
            value: currentUser.provider.providerName,
            option: currentUser.provider.providerName,
            type: 'text',
          },
        ];

        tempProviderName = currentUser.provider.providerName;
      } else {
        const providerNameList = providerList.map(item => item.providerName);

        tempProvider = [
          {
            id: 'All',
            value: 'All',
            option: 'All',
            type: 'text',
          },
          ...providerNameList.map(item => ({
            id: item,
            value: item,
            option: item,
            type: 'text',
          })),
        ];

        tempProviderName = 'All';
      }

      setProviderOptionList([...tempProvider]);
      setSelectedProvider(tempProviderName);
      getApiCallLog(tempProviderName);
    }
    // eslint-disable-next-line
  }, [currentUser, providerList]);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      setSelectedStartDateString(selectedStartDate.format('YYYY-MM-DD'));
      setSelectedEndDateString(selectedEndDate.format('YYYY-MM-DD'));
    }
  }, [selectedStartDate, selectedEndDate]);

  // Paging 처리
  useEffect(() => {
    if (displayApiCallLogs && displayApiCallLogs.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayApiCallLogs.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setIsDisplayApiCallLogs(true);
    }
  }, [displayApiCallLogs, page, itemsPerPage]);

  useEffect(() => {
    if (searchTerm) {
      const excludeKeyList = ['idx', 'filePath', 'successFlag'];

      const filteredData = originalApiCallLogs.filter((item) => {
        return Object.entries(item)
          .filter(([key, value]) => !excludeKeyList.includes(key))
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchTerm));
      });

      setDisplayApiCallLogs([...filteredData]);
      if (filteredData.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    } else {
      setDisplayApiCallLogs([...originalApiCallLogs]);
      if (originalApiCallLogs.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    }
  }, [searchTerm, originalApiCallLogs]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayApiCallLogs].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayApiCallLogs([...sortedData]);
  };

  const getApiCallLog = (provider) => {
    setEmptyData(false);
    setPage(1);
    setDisplayApiCallLogs([]);
    setIsDisplayApiCallLogs(false);

    const param = `providerName=${provider}&api=${selectedApi}&status=${selectedStatus}&file=${selectedFileName}&message=${selectedMessage}&startDate=${selectedStartDateString}&endDate=${selectedEndDateString}`;

    fetch(`${BASE_URL}/apicalllogs/logs?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setOriginalApiCallLogs([...body.data.apiCallLogList]);

        if (body.data.apiCallLogList && body.data.apiCallLogList.length === 0) {
          setEmptyData(true);
          setIsDisplayApiCallLogs(true);
        }
      } else {
        throw new Error(`[API Call Logs] api call log request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  // Button Data
  const handleReset = () => {
    setSelectedProvider('All');
    setSelectedApi('All');
    setSelectedStatus('All');
    setSelectedFileName('');
    setSelectedMessage('');
    setSelectedStartDate(dayjs().subtract(7, 'day'));
    setSelectedEndDate(dayjs());
  };

  const handleSearch = () => {
    getApiCallLog(selectedProvider);
  };

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      width: 107,
      onClick: handleReset,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      onClick: handleSearch,
    },
  ];

  const handleDownloadError = (event, filename) => {
    event.preventDefault();

    setAlertTitle(`Warning`);
    setAlertMessage(`${filename} not found`);
    setAlertOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Typography component='p' className='title'>
                API Call Logs
              </Typography>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5, col-6  */}
                <Box className='component-wrap col-5'>
                  {/* CP Name */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='API-CP'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      selectOptions={providerOptionList}
                      disabled={false}
                      placeholder=''
                      value=''
                      selected={selectedProvider}
                      onChange={setSelectedProvider}
                    />
                  </Box>

                  {/* API */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='API-API'
                      size='large'
                      shrink={true}
                      label='API'
                      selectOptions={APIOption}
                      disabled={false}
                      placeholder=''
                      selected={selectedApi}
                      onChange={setSelectedApi}
                    />
                  </Box>

                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='API-Status'
                      size='large'
                      shrink={true}
                      label='Status'
                      disabled={false}
                      selectOptions={StatusOption}
                      placeholder=''
                      selected={selectedStatus}
                      onChange={setSelectedStatus}
                    />
                  </Box>
                </Box>

                {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box className='component-wrap col-5'>
                  {/* File */}
                  <Box component='div' className='col'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='File'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      required={false}
                      InputLabelProps={{ shrink: false }}
                      value={selectedFileName}
                      onChange={(event) => setSelectedFileName(event.target.value)}
                    />
                  </Box>

                  {/* Message */}
                  <Box component='div' className='col'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Message'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      required={false}
                      InputLabelProps={{ shrink: false }}
                      value={selectedMessage}
                      onChange={(event) => setSelectedMessage(event.target.value)}
                    />
                  </Box>

                  <Box component='div' className='col auto'>
                    <DatePicker
                      className='input-date-picker'
                      format='MM/DD/YYYY'
                      size='large'
                      maxWidth='210px'
                      singleDate={false}
                      rangeDate={true}
                      wave={true}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      onChangeStartDate={setSelectedStartDate}
                      onChangeEndDate={setSelectedEndDate}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons} />
              </div>
            </Box>
          </Box>

          {isDisplayApiCallLogs ? (
            <Box className='white-box white-box-wrap single-box'>
              <div className='box-content'>
                <Box className='white-box-content-top-area'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Box className='total-count'>
                        <Box component='span' className='label'>
                          Total
                        </Box>
                        <Box component='span' className='value'>
                          {displayApiCallLogs.length}
                        </Box>
                      </Box>
                      <Typography className='sm-sub-text' ml={3}>
                        Local Time Based
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <SearchField
                      placeholder='Please enter a search term'
                      maxWidth='230px'
                      onChange={(event) => setSearchTerm(event.target.value.toLowerCase())}
                    />
                  </Box>
                </Box>
                <Box className='content-inner-wrap'>
                  <CustomTableContainer className='table-container'>
                    <Table className='custom-table' aria-label='table' stickyHeader>
                      {/* table Head */}
                      <TableHead className='thead'>
                        <TableRow className='tr'>
                          {ApiCallLogTitle.map((column) => (
                            <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                              {column.checkbox ? (
                                <Box display='flex' alignContent='center'>
                                  <CheckBox
                                    label=''
                                    value='select-all'
                                    defaultChecked={false}
                                    inputProps={{
                                      'aria-label': 'select all',
                                    }}
                                  />
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                </Box>
                              ) : (
                                <>
                                  {column.sortable ? (
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  ) : (
                                    column.label
                                  )}
                                </>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      {/* table Body */}
                      <TableBody className='tbody'>
                        {isEmptyData ? (
                          <NoRecords>
                            No records to display
                          </NoRecords>
                        ) : (
                          paginatedData.map((td, index) => (
                            <TableRow className='tr' key={td.id || index}>
                              {ApiCallLogTitle.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  {colIndex === 0 ? (
                                    td.providerName
                                  ) : colIndex === 1 ? (
                                    td.apiName
                                  ) : colIndex === 2 ? (
                                    td.file.length > 40 ? (
                                      <Tooltip
                                        title={td.file}
                                        placement='top'
                                      >
                                        {td.file.substring(0, 40) + '...'}
                                      </Tooltip>
                                    ) : (
                                      td.file
                                    )
                                  ) : colIndex === 3 ? (
                                    td.successFlag === true ? 'success' : 'fail'
                                  ) : colIndex === 4 ? (
                                    td.message.length > 54 ? (
                                      <Tooltip
                                        title={td.message}
                                        placement='top'
                                      >
                                        {td.message.substring(0, 54) + '...'}
                                      </Tooltip>
                                    ) : (
                                      td.message
                                    )
                                  ) : colIndex === 5 ? (
                                    td.crtDate.replace('T', ' ').replace('Z','')
                                  ) : colIndex === 6 ? (
                                    td.lastChgDate.replace('T', ' ').replace('Z','')
                                  ) : colIndex === 7 ? (
                                    <Button
                                      key={index}
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='Download'
                                      isIcon={false}
                                      autosize
                                      href={td.filePath ? td.filePath : undefined}
                                      onClick={td.filePath ? undefined : (event) => handleDownloadError(event, td.file)}
                                    />
                                  ) : (
                                    td[`data0${colIndex + 1}`]
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </CustomTableContainer>
                </Box>

                {/* Pagination */}
                <Pagination count={Math.ceil(displayApiCallLogs.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
              </div>
            </Box>
          ) : (
            <Loading/>
          )
        }
        </Box>
      </Box>
      <Dialog
        open={isAlertOpen} // boolean 값으로 설정
        handleClose={handleAlertClose}
        closeX={false}
        size='small' // large, medium, small
        title={alertTitle} // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </Box>
  );
}

APICallLogs.propTypes = {
  providerList: PropTypes.array.isRequired
};
const mapStateToProps = state => ({
  providerList: state.common.provider.entire
});

export default connect(mapStateToProps)(APICallLogs);
