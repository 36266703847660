// src/data.js

export const activityData = [
  {
    id: 201,
    user: 'Tail Lim',
    Ch_name: 'Channel Mapping',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Channel Mapping',
    updateContent: 'Country: US / Feed: [NEW] NEW ID / Channel Map: US-ME-6.0.0 / Channel map Status: Saved - Testing',
    date: '2024.07.16 6:35 PM',
    status: 'Testing',
    chip_type: 'testing',
    chip_label: 'Testing',
  },
  {
    id: 202,
    user: 'Tail Lim',
    Ch_name: 'Tab/Category',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Tab/Category',
    updateContent: 'Device Type: TV / Region: US(US) / Platform version: 3.4.0 / Status: Saved - Published',
    date: '2024.07.16 6:35 PM',
    chip_type: 'publishing',
    chip_label: 'Publishing',
  },
  {
    id: 203,
    user: 'Tail Lim',
    Ch_name: 'Channel Mapping',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Channel Mapping',
    updateContent: 'Country: US / Feed: [NEW] NEW ID / Channel Map: US-ME-6.0.0 / Channel map Status: Saved - Testing',
    date: '2024.07.16 6:35 PM',
    chip_type: 'testing',
    chip_label: 'Testing',
  },
  {
    id: 204,
    user: 'Tail Lim',
    Ch_name: 'Tab/Category',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Tab/Category',
    updateContent: 'Device Type: TV / Region: US(US) / Platform version: 3.4.0 / Status: Saved - Published',
    date: '2024.07.16 6:35 PM',
    chip_type: 'testing',
    chip_label: 'Testing',
  },
  {
    id: 205,
    user: 'Tail Lim',
    action: 'updated the Channel Mapping',
    location: 'HOME > ORGANIZATION > Channel Mapping',
    current_location: '',
    updateContent: 'Country: US / Feed: [NEW] NEW ID / Channel Map: US-ME-6.0.0 / Channel map Status: Saved - Testing',
    date: '2024.07.16 6:35 PM',
    chip_type: 'publishing',
    chip_label: 'Publishing',
  },
  {
    id: 206,
    user: 'Tail Lim',
    action: 'updated the Tab/Category',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Tab/Category',
    updateContent: 'Device Type: TV / Region: US(US) / Platform version: 3.4.0 / Status: Saved - Published',
    date: '2024.07.16 6:35 PM',
    chip_type: 'testing',
    chip_label: 'Testing',
  },
];
