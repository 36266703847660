import React, { useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import DatePicker from 'components/component/BasicDatePicker';
import { Grid, Select, MenuItem } from '@mui/material';
import dayjs from "dayjs";
import { StyledFormControl, StyledInputLabel } from 'pages/Organization/TabCategory/data/homeAppStyle';
import { Box } from '@mui/system';
import { postRequest } from 'utils/Request';

// 메인 컨텐츠
function PopAddSchedule(props) {
  const { categoryIdx, weekDayOptions, countryOptions, onClose, handelSetShowSnakBar, refreshTabCategory } = props;

  const [scheduleStartDate, setScheduleStartDate] = useState(dayjs());
  const [scheduleEndDate, setScheduleEndDate] = useState(dayjs());
  const [scheduleCountrys, setScheduleCountrys] = useState([countryOptions[0].value]);
  const [scheduleDays, setScheduleDays] = useState([weekDayOptions[0].value]);

  const handleClose = () => {
    onClose();
  };

  const onCancelBtn = () => {
    handleClose();
  };

  /* 스케줄 정보 수정 시 객체에 값을 변경 한다 Start */
  // schedule country
  const onChangeCountry = (event) => {
    const country = event.target.value.filter(n => n);
    setScheduleCountrys(country);
  };
  // schedule day
  const onChangeDay = (event) => {
    const day = event.target.value.filter(n => n);
    setScheduleDays(day);
  };
  /* 스케줄 정보 수정 시 객체에 값을 변경 한다 End */

  // Save버튼 클릭 시 탭 생성
  const clickSave = async event => {
    event.preventDefault();

    const scheduleData = { categoryIdx: categoryIdx, startDate: scheduleStartDate, endDate: scheduleEndDate, countryCodes: scheduleCountrys, weekdayList: scheduleDays };
    const res = await postRequest(`/home-app/category/schedule`, scheduleData, null);

    handleClose();
    refreshTabCategory();
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
  }

  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: onCancelBtn,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: clickSave,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        size='medium' // large, medium, small
        title='Add Schedule Category' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={6} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <DatePicker
                    id='Schedule-s'
                    size='large'
                    format='YYYY-MM-DD'
                    shrink
                    label='Start Date'
                    singleDate={true}
                    rangeDate={false}
                    onChange={(newValue) => setScheduleStartDate(newValue)}
                    required={true}
                    isAfterDate={true}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <DatePicker
                    id='Schedule-e'
                    size='large'
                    format='YYYY-MM-DD'
                    shrink
                    label='End Date'
                    singleDate={true}
                    rangeDate={false}
                    onChange={(newValue) => setScheduleEndDate(newValue)}
                    required={true}
                    isAfterDate={true}
                    />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box component='div' className='field-box'>
                  <StyledFormControl
                    size='large'
                    variant='outlined'
                    className='large'
                  >
                    <StyledInputLabel shrink={true}>
                      <span>Country</span>
                    </StyledInputLabel>
                    <Select
                      multiple
                      value={scheduleCountrys}
                      onChange={e => {
                        onChangeCountry(e);
                      }}>
                      {countryOptions.map(n => (
                        <MenuItem key={n.id} value={n.value}>
                          {n.option}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box component='div' className='field-box'>
                  <StyledFormControl
                    size='large'
                    variant='outlined'
                    className='large'
                  >
                    <StyledInputLabel shrink={true}>
                      <span>Week Day</span>
                    </StyledInputLabel>
                    <Select
                      multiple
                      value={scheduleDays}
                      onChange={e => {
                        onChangeDay(e);
                      }}>
                      {weekDayOptions.map(n => (
                        <MenuItem key={n.id} value={n.value}>
                          {n.option}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopAddSchedule;
