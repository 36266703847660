import {
  Box, IconButton, ListItemText,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import SelectBox from "components/component/BasicSelectBox";
import {PlatformOption, statusData} from "./data/DeviceNotificationData";
import DatePicker from "components/component/BasicDatePicker";
import CheckBox from "components/component/BasicCheckBox";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "components/component/BasicTooltip";
import {PrimaryInfoIcon} from "components/component/BasicIcon";
import TextLink from "components/component/BasicTextLink";
import Button from "components/component/BasicButton";
import Pagination from "components/component/BasicPagination";
import { connect } from "react-redux";
import React, {useEffect, useState} from "react";

import { styled } from '@mui/material/styles';
import {BASE_URL} from "constants/index";
import dayjs from "dayjs";
import {getHeaders} from "utils/Header";
import BasicSelectCheckBox from "components/component/BasicSelectCheckBox";
import {useNavigate} from "react-router-dom";
import PopAddNotificationMobile from "pages/Organization/DeviceNotification/PopAddNotificationMobile";
import Dialog from "components/component/BasicDialog";


// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Tooltip 내부에 출력될 자식 컴포넌트 //
const InfoTooltip = (props, ref) => {
  return (
      <Box className='tooltip-info-box' sx={{ padding: '0 12px' }}>
        {props.list.map((infoField, i) => (
            <Box key={i} className='info'>
              <ListItemText className='label'>{`${infoField.country} `}</ListItemText>
            </Box>
        ))}
      </Box>
  );
};

function DeviceNotificationMobile({ country }) {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const getPublishStatusAcronym = (status) => {
    switch (status) {
      case 'Save for Test':
        return 'S-T';
      case 'Save for Publish':
        return 'S-P';
      case 'Testing':
        return 'T';
      case 'Published':
        return 'P';
      default:
        return status;
    }
  }

  const [paramCountry, setParamCountry] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(['US']);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [paramDate, setParamDate] = useState(dayjs());
  const [deviceNotifications, setDeviceNotifications] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('Save for Test');
  const [checkedItem, setCheckedItem] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [openAddNotification, setOpenAddNotification] = useState(false);
  const navigate = useNavigate();

  const navigateDeviceNotificationDetail = (notificationId) => {
    navigate('/DeviceNotification', {
      replace: true, state:
          { move: true,
            targetComponent: 'DeviceNotificationDetail',
            message:
                {
                  notification: notificationId
                }
          }
    })
  }

  const handleMoveToHistory = (notificationId, title) => {
    navigate('/DeviceNotification', {
      replace: true, state:
          { move: true,
            targetComponent: 'DeviceNotificationHistory',
            message:
                {
                  notificationId: notificationId,
                  title: title
                }
          }
    })
  }

  useEffect(() => {
    if (country.regionCountry) {
      let mobileSupportCountries = [
          ...new Set(country.regionCountry.filter((item) => item.deviceType === 'mobile')
                                          .map(region => region.countryCode))]
      setParamCountry(mobileSupportCountries.map(countryCode => ({
        id: countryCode,
        value: countryCode,
        option: countryCode,
        type: 'checkbox',
        defaultChecked: countryCode === 'US'
      })));
    }
  }, [country]);

  const getNotifications = () => {
    let requestUrl = `${BASE_URL}/device-notification/notifications`;
    requestUrl += '?deviceType=mobile';
    requestUrl += `&status=${getPublishStatusAcronym(selectedStatus)}`;
    requestUrl += `&date=${paramDate}`

    fetch(requestUrl, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const data = body.data;
        data.forEach(item => {
          item.countryList.sort();
          item.country = {label: item.countryList[0]? item.countryList[0] : '', tooltip: false};
          if (item.countryList.length > 1) {
            item.country = {label: `${item.countryList[0]}+${item.countryList.length-1}`, tooltip: true};
          }
        })
        setDeviceNotifications(data);
      }
    })
  }

  const handlePublish = () => {
    const request = {
      notificationId: checkedItem,
      publishStatus: selectedStatus === 'Save for Test' ? 'T' : 'P'
    }
    fetch(`${BASE_URL}/device-notification/notifications/publish`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(request)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success');
        setAlertMessage('Notification published successfully.');
        setAlertOpen(true);
      } else {
        setAlertTitle('Failed');
        setAlertMessage('Failed to publish notification.');
        setAlertOpen(true);
      }
    })
    .finally(() => {
      handleConfirmClose();
      setCheckedItem('');
    })
  }

  useEffect(() => {
    getNotifications();
    setCheckedItem('');
    // eslint-disable-next-line
  }, [selectedCountries, selectedPlatforms, selectedStatus, paramDate]);

  const handleSelectedCountries = (country) => {
    if (country) {
      setSelectedCountries([...country]);
    }
  }

  const handleSelectedPlatforms = (platform) => {
    if (platform) {
      setSelectedPlatforms([...platform]);
    }
  }

  useEffect(() => {
    console.log(deviceNotifications)
  }, [deviceNotifications]);

  useEffect(() => {
    setPage(1);
  }, [selectedStatus]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (deviceNotifications.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(deviceNotifications.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [deviceNotifications, page, itemsPerPage]);

  const handleCheckBoxChange = (notificationId) => {
    setCheckedItem(notificationId);
  };

  const handlePublishConfirmOpen = () => {
    setConfirmMessage(`Do you want to publish the below notification?\n\n` + checkedItem);
    setConfirmOpen(true);
  }

  // TableBody Row
  const sortedPrTdRows = sortRows([...paginatedData], orderBy, order);

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: handlePublish
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  }

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
      <div className='tab-panel pr-page'>
        <div className='panel-wrap'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <BasicSelectCheckBox
                        id='deviceNoti-country'
                        size='large'
                        shrink={true}
                        label='Country'
                        required={false}
                        selectOptions={paramCountry}
                        disabled={false}
                        placeholder=''
                        multiple={true}
                        onChange={handleSelectedCountries}
                    />
                  </Box>
                  {/* Platform */}
                  <Box component='div' className='col'>
                    <BasicSelectCheckBox
                        id='deviceNoti-Platform'
                        size='large'
                        shrink={true}
                        label='Platform'
                        required={true}
                        selectOptions={PlatformOption}
                        disabled={false}
                        placeholder=''
                        multiple={true}
                        onChange={handleSelectedPlatforms}
                    />
                  </Box>
                  {/* status */}
                  <Box component='div' className='col'>
                    <SelectBox
                        id='deviceNoti-statusData'
                        size='large'
                        shrink={true}
                        label='Status'
                        required={false}
                        selectOptions={statusData}
                        disabled={false}
                        placeholder=''
                        selected={selectedStatus}
                        onChange={setSelectedStatus}
                    />
                  </Box>

                  {/* Date */}
                  {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}

                  <Box component='div' className='col auto'>
                    <DatePicker
                        format='MM/DD/YYYY'
                        size='large'
                        singleDate={true}
                        rangeDate={false}
                        wave={true}
                        value={paramDate}
                        onChange={(newValue) => setParamDate(newValue)}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='right-area'>
                  {(selectedStatus === 'Save for Test' || selectedStatus === 'Save for Publish') && (
                      <Button
                          color={'primary'}
                          type={'box'}
                          size={'small'}
                          label={'Add'}
                          autosize={false}
                          onClick={() => {setOpenAddNotification(true)}}
                      />
                  )}
                  {selectedStatus === 'Save for Test' && (
                      <Button
                          color={checkedItem === '' ? 'tertiary' : 'primary'}
                          type={'box'}
                          size={'small'}
                          label={'Testing'}
                          autosize={true}
                          disabled={checkedItem === ''}
                          onClick={handlePublishConfirmOpen}
                      />
                  )}
                  {selectedStatus === 'Save for Publish' && (
                      <Button
                          color={checkedItem === '' ? 'tertiary' : 'primary'}
                          type={'box'}
                          size={'small'}
                          label={'Publish'}
                          autosize={true}
                          disabled={checkedItem === ''}
                          onClick={handlePublishConfirmOpen}
                      />
                  )}

                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table'
                         stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        <TableCell
                            key={'notificationId'}
                            className='th'
                            align={'left'}
                            sx={{width: '22%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'notificationId'}
                              direction={orderBy === 'notificationId' ? order : 'desc'}
                              onClick={() => handleRequestSort('notificationId')}
                          >
                            Notification ID
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'Country'}
                            className='th'
                            align={'left'}
                            sx={{width: '6%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'Country'}
                              direction={orderBy === 'Country' ? order : 'desc'}
                              onClick={() => handleRequestSort('country.label')}
                          >
                            Country
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'Version'}
                            className='th'
                            align={'left'}
                            sx={{width: '12%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'Version'}
                              direction={orderBy === 'Version' ? order : 'desc'}
                              onClick={() => handleRequestSort('Version')}
                          >
                            Version
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'Enable'}
                            className='th'
                            align={'left'}
                            sx={{width: '6%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'Enable'}
                              direction={orderBy === 'Enable' ? order : 'desc'}
                              onClick={() => handleRequestSort('Enable')}
                          >
                            Enable
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'Title'}
                            className='th'
                            align={'left'}
                            sx={{width: '24%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'Title'}
                              direction={orderBy === 'Title' ? order : 'desc'}
                              onClick={() => handleRequestSort('Title')}
                          >
                            Title
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'Start'}
                            className='th'
                            align={'left'}
                            sx={{width: '6%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'Start'}
                              direction={orderBy === 'Start' ? order : 'desc'}
                              onClick={() => handleRequestSort('Start')}
                          >
                            Start Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'End'}
                            className='th'
                            align={'left'}
                            sx={{width: '6%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'End'}
                              direction={orderBy === 'End' ? order : 'desc'}
                              onClick={() => handleRequestSort('End')}
                          >
                            End Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'Last'}
                            className='th'
                            align={'left'}
                            sx={{width: '10%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'Last'}
                              direction={orderBy === 'Last' ? order : 'desc'}
                              onClick={() => handleRequestSort('Last')}
                          >
                            Last Chg Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'action'}
                            className='th'
                            align={'left'}
                            sx={{width: 'auto'}}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedPrTdRows.map((td, index) => (
                          <TableRow className='tr' key={td.id || index}>
                            <TableCell key={'notificationId'} className='td' align={'left'}>
                              <Box display='flex'>
                                <CheckBox label='' value={td['notificationId']}
                                          checked={checkedItem === td['notificationId']}
                                          onChange={() => handleCheckBoxChange(td['notificationId'])}/>
                                <Typography>{td['notificationId']}</Typography>
                              </Box>
                            </TableCell>
                            <TableCell key={'country'} className='td' align={'left'}>
                              <Box display='flex'>

                                <Typography>{td.country.label}</Typography>
                                {td.country.tooltip && (
                                    <div className='td-tooltip-wrap'>
                                      <Tooltip title={<InfoTooltip list={td.countryList}/>}
                                               placement='bottom'>
                                        <IconButton
                                            size='small'
                                            sx={{
                                              width: '24px',
                                              height: '24px',
                                              marginLeft: '4px'
                                            }}>
                                          <PrimaryInfoIcon/>
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell key={'version'} className='td' align={'left'}>
                              <TextLink href='' onClick={() => navigateDeviceNotificationDetail(td)}
                                        text={td[`version`]}/>
                            </TableCell>
                            <TableCell key={'enable'} className='td' align={'left'}>
                              <span>{td[`enable`]}</span>
                            </TableCell>
                            <TableCell key={'title'} className='td' align={'left'}>
                              <span>{td[`title`]}</span>
                            </TableCell>
                            <TableCell key={'Start'} className='td' align={'left'}>
                              <span>{td[`startDate`]}</span>
                            </TableCell>
                            <TableCell key={'End'} className='td' align={'left'}>
                              <span>{td[`endDate`]}</span>
                            </TableCell>
                            <TableCell key={'Last'} className='td' align={'left'}>
                              <span>{td[`lastChgDate`]?.replace("T", " ").replace("Z", "")}</span>
                            </TableCell>
                            <TableCell key={'action'} className='td' align={'left'}>
                              <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='HISTORY'
                                  isIcon={false}
                                  autosize
                                  onClick={() => handleMoveToHistory(td['notificationId'], td['title'])}
                              />
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={Math.ceil(deviceNotifications.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
              {openAddNotification && (
                  <PopAddNotificationMobile onClose={() => setOpenAddNotification(false)} publishStatus={getPublishStatusAcronym(selectedStatus)} afterSave={getNotifications}/>
              )}
            </div>
          </Box>
        </div>
        <Dialog
            open={isConfirmOpen} // boolean 값으로 설정
            handleClose={handleConfirmClose}
            closeX={false}
            size='small'
            title='Confirm'
            contentTitle={false}
            bottomDivider={false}
            contentText={confirmMessage}
            buttons={confirmDialogButtons}
            style={{ whiteSpace: 'pre-line' }}
        />
        <Dialog
            open={isAlertOpen}
            handleClose={handleAlertClose}
            closeX={false}
            size='small'
            title={alertTitle}
            contentTitle={false}
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
      </div>
  )
}
const mapStateToProps = state => ({
  country: state.common.country,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNotificationMobile);