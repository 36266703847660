import React, {useEffect, useState} from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  Box,
  IconButton,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TableFooter,
} from '@mui/material';

import Snackbar from 'components/component/BasicSnackbar';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';

// Data
import { ChannelCountTh } from 'pages/Inspect/ChannelStatus/data/InspectData';
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1000px',
    maxWidth: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  display: flex;
  gap: 20px;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '450px',
  overflowY: 'auto',
}));

function PopChannelCount({ openChannelCount, closeChannelCount, paramCountry, paramProvider }) {

  const [channelCountInfo, setChannelCountInfo] = useState([]);
  const [testCount, setTestCount] = useState(0);
  const [publishCount, setPublishCount] = useState(0);
  const [savedTestCount, setSavedTestCount] = useState(0);
  const [savedPublishCount, setSavedPublishCount] = useState(0);

  const handleClose = () => {
    closeChannelCount();
  };

  const buttons = [
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: 'OK',
      isIcon: 'false',
      IconType: '',
      iconPos: undefined,
      className: 'btn',
      onClick: handleClose
    },
  ];

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedChannelCountTdRows = sortRows([...channelCountInfo], orderBy, order);

  useEffect(() => {
    fetch(`${BASE_URL}/channel-status/channel/count?countryCode=${paramCountry}&providerName=${paramProvider}`,{
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setChannelCountInfo(body.data)
        if (body.data.length > 0) {
          setTestCount(body.data.map(count => count.testCount).reduce((a, b) => a + b));
          setPublishCount(body.data.map(count => count.publishCount).reduce((a, b) => a + b));
          setSavedTestCount(body.data.map(count => count.savedTestCount).reduce((a, b) => a + b));
          setSavedPublishCount(body.data.map(count => count.savedPublishCount).reduce((a, b) => a + b));
        }
      }
    })
  }, [paramCountry, paramProvider]);

  return (
    <div className='component-view'>
      <Dialog open={openChannelCount} size='medium' fullWidth className='pop-channel-count'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Channel Count</DialogTitle>
        </HeaderArea>
        <DialogContent className='pop-content'>
          <BoxContainer>
            <Box className='channel-count-container'>
              <Box className='top-area'>
                <Box className='info-item country'>
                  <Typography className='label' component='span'>
                    Country
                  </Typography>
                  <Typography className='value' component='span'>
                    {paramCountry}
                  </Typography>
                </Box>
                <divider className='divider' />
                <Box className='info-item cp'>
                  <Typography className='label' component='span'>
                    CP Name
                  </Typography>
                  <Typography className='value' component='span'>
                    {paramProvider}
                  </Typography>
                </Box>
              </Box>

              {/* Table */}
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  {/* table Head */}
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      {ChannelCountTh.map((column) => (
                        <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                          <>
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'desc'}
                              onClick={() => handleRequestSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* table Body */}
                  <TableBody className='tbody'>
                    {sortedChannelCountTdRows.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        {ChannelCountTh.map((column, colIndex) => (
                          <TableCell key={column.id} className='td' align={column.align}>
                            {td[`${column.id}`]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>

                  {/* Footer - Total */}
                  <TableFooter>
                    <TableRow className='tr'>
                      <TableCell
                        className='td'
                        colSpan='3'
                        align='center'
                        sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 100 }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        className='td total-sum'
                        sx={{ width: '15%', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 10 }}
                        align='right'
                      >
                        {publishCount}
                      </TableCell>
                      <TableCell
                        className='td total-sum'
                        sx={{ width: '15%', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 10 }}
                        align='right'
                      >
                        {savedPublishCount}
                      </TableCell>
                      <TableCell
                        className='td total-sum'
                        sx={{ width: '15%', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 10 }}
                        align='right'
                      >
                        {testCount}
                      </TableCell>
                      <TableCell
                        className='td total-sum'
                        sx={{ width: '15%', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 10 }}
                        align='right'
                      >
                        {savedTestCount}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </CustomTableContainer>
            </Box>
          </BoxContainer>
        </DialogContent>

        <Divider sx={{ margin: '24px 0' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={buttons} justifyContent='center' />
        </DialogActions>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={false}
        Duration='1000'
        bottom={'8%'}
      />
    </div>
  );
}

export default PopChannelCount;
