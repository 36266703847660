import {Box, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TableContainer} from "@mui/material";
import SelectBox from "../../../components/component/BasicSelectBox";
import {
  ChannelMapHeaderHead,
  ChannelMapHeaderTail,
} from "./data/ChannelStatusData";
import DatePicker from "../../../components/component/BasicDatePicker";
import Button from "../../../components/component/BasicButton";
import { RefreshIcon } from 'components/component/BasicIcon';
import Tooltip from "../../../components/component/BasicTooltip";
import {InfoIcon} from "../../../components/component/BasicIcon";
import CheckBox from "../../../components/component/BasicCheckBox";
import CustomButtonGroup from "../../../components/component/BasicButtonGroup";
import SearchField from "../../../components/component/BasicSearchField";
import TextField from 'components/component/BasicTextField';
import TableSortLabel from "@mui/material/TableSortLabel";
import Pagination from "../../../components/component/BasicPagination";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import dayjs from "dayjs";
import {connect} from "react-redux";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import PopChannelCount from "./PopChannelCount";
import * as XLSX from 'xlsx';

function ChannelMap({ country, provider }) {

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 200,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

      '.dark &': {
        backgroundColor: '#1b1b1b',
        border: '1px solid #484848',
      },
      '& .MuiMenu-list': {
        padding: '4px 0',
        border: 0,
      },
      '& .MuiMenuItem-root': {
        '&:active': {
          backgroundColor: '#ddd',
        },
      },
    },
  }));


  // 테이블
  const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    height: '',
    overflowY: 'auto',
  }));

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // Channel Map - channel count
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [paramCountry, setParamCountry] = useState([]);
  const [paramProvider, setParamProvider] = useState([]);
  const [paramStartDate, setParamStartDate] = useState(dayjs());
  const [paramEndDate, setParamEndDate] = useState(dayjs());
  const paramStatus = [
    { id: 'Published', value: 'Published', option: 'Published', type: 'text' },
    { id: 'Save for Publish', value: 'Save for publish', option: 'Save for publish', type: 'text' },
    { id: 'Testing', value: 'Testing', option: 'Testing', type: 'text' },
    { id: 'Save for Test', value: 'Save for Test', option: 'Save for Test', type: 'text' }
  ]
  const [paramChannelId, setParamChannelId] = useState('');
  const [paramChannelName, setParamChannelName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('US')
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('Published');
  const [isOpenChannelCount, setIsOpenChannelCount] = useState(false);
  const [updatedChannelInfo, setUpdatedChannelInfo] = useState([]);
  const [tableColumns, setTableColumns] = useState([...ChannelMapHeaderHead, ...ChannelMapHeaderTail]);
  const [targetChannelMaps, setTargetChannelMaps] = useState([]);
  const [channelMapHamburgerFilter, setChannelMapHamburgerFilter] = useState([]);
  const [checkedChannelMaps, setCheckedChannelMaps] = useState({});
  const [searchTextInput, setSearchTextInput] = useState('');
  const [channelMapTableData, setChannelMapTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    searchUpdatedChannel();
    // eslint-disable-next-line
  }, []);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (channelMapTableData.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(channelMapTableData.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [channelMapTableData, page, itemsPerPage]);

  const sortedChannelMapTdRows = sortRows([...paginatedData], orderBy, order);

  const resetParams = () => {
    setSelectedCountry('US')
    setSelectedProvider('All')
    setParamStartDate(dayjs());
    setParamEndDate(dayjs());
    setSelectedStatus('Published')
    setParamChannelId('')
    setParamChannelName('')
  }

  const getChannelMapAcronyms = (channelMapId) => {
    if (channelMapId.includes('Auto')) {
      return 'Auto';
    }
    if (channelMapId.includes('ME')) {
      return 'ME';
    }
    if (channelMapId.includes('WEE')) {
      return 'WEE';
    }
    if (channelMapId.split('-').length === 3) {
      return channelMapId.split('-')[2];
    }
    if (channelMapId.split(':').length === 3) {
      return channelMapId.split(':')[2];
    }
    return channelMapId;
  }


  useEffect(() => {
    if (country.serviceCountry) {
      let countries = country.serviceCountry.map(country => country.countryCode).sort().map(country => (
        { id: country, value: country, option: country, type: 'text' }
      ))
      setParamCountry(countries)
    }
  }, [country]);


  useEffect(() => {
    if (provider && provider.country) {
      let find = provider.country.find(item => item.countryCode === selectedCountry);
      if (find.providerList) {
        let providers = find.providerList.map(item => ({
          id: item.providerName,
          value: item.providerName,
          option: item.providerName,
          type: 'text'
        }));
        setParamProvider([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...providers])
      }
    }
    // eslint-disable-next-line
  }, [selectedCountry]);

  const searchUpdatedChannel = () => {
    const requestStatus = selectedStatus === 'Testing' ? 'T' :
        selectedStatus === 'Published' ? 'P' :
        selectedStatus === 'Save for publish' ? 'S-P' : 'S-T';
    fetch(`${BASE_URL}/channel-status/channel/updated?countryCode=${selectedCountry}&status=${requestStatus}&providerName=${selectedProvider}`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setUpdatedChannelInfo(body.data)
        setChannelMapTableData(body.data)
      }
      if (country && country.serviceCountry) {
        let find = country.serviceCountry.find(item => item.countryCode === selectedCountry);
        if (find.channelMapList) {
          let channelMaps = find.channelMapList.map(item => item.channelMapId);
          setTargetChannelMaps(channelMaps)
          let filterItem = {};
          channelMaps.forEach((item, idx) => {
            if (idx < 4) {
              filterItem[item] = true;
            }
          })
          setCheckedChannelMaps(filterItem)
          let headerMiddle = channelMaps.map(map => ({
            id: map,
            label: getChannelMapAcronyms(map),
            align: 'left',
            width: '6%',
            checkbox: false,
            sortable: true
          }));
          setTableColumns([...ChannelMapHeaderHead, ...headerMiddle, ...ChannelMapHeaderTail]);
        }
      }
    })
  }

  useEffect(() => {
    setChannelMapHamburgerFilter(targetChannelMaps.map(map => ({id: map, label: map, type: 'checkbox'})))
  }, [targetChannelMaps]);

  const onChangeHamburgerFilter = (e) => {
    let filterItem = {...checkedChannelMaps}
    filterItem[e.target.value] = e.target.checked;
    setCheckedChannelMaps(filterItem)
  }

  useEffect(() => {
    let filterChannelMaps = Object.keys(checkedChannelMaps).filter(key => checkedChannelMaps[key] === true);
    let headerMiddle = filterChannelMaps.map(map => ({
      id: map,
      label: getChannelMapAcronyms(map),
      align: 'left',
      width: '6%',
      checkbox: false,
      sortable: true
    }));
    setTableColumns([...ChannelMapHeaderHead, ...headerMiddle, ...ChannelMapHeaderTail]);
  }, [checkedChannelMaps]);

  const isValidProvider = () => {
    return paramProvider.map(cp => cp.value).includes(selectedProvider);
  }

  useEffect(() => {
    setChannelMapTableData(updatedChannelInfo.filter(
        (item) => item.channelName.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.channelId.toLowerCase().includes(searchTextInput.toLowerCase())));

    setPage(1);
    // eslint-disable-next-line
  }, [searchTextInput]);

  // Button Data
  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      width: 107,
      onClick: resetParams
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      onClick: searchUpdatedChannel
    },
  ];

  const handleExcelDownload = (event) => {
    event.preventDefault();

    const excelData = updatedChannelInfo.map((item) => {
      let channel = {};
      channel['Ch. No'] = item.channelNumber;
      channel['CP Name'] = item.providerName;
      channel['Channel Name'] = item.channelName;
      channel['Channel Genre'] = item.channelGenre;
      channel['Channel ID'] = item.channelId;
      targetChannelMaps.forEach(cm => {
        channel[cm] = item.presenceChannelMaps.includes(cm) ? 'O' : 'X';
      })
      channel['DMA'] = item.channelDmaGroupName;
      channel['Last Update'] = item.lastChgDate;
      return channel;
    })
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Channel Map');
    XLSX.writeFile(workbook, `ChannelMap_${selectedCountry}.xlsx`);
  };

  return (
    <div className='tab-panel channel-map'>
      <div className='panel-wrap'>
        {/* 필터영역 */}
        <Box component='div' className='white-box filter-box'>
          <Box className='form-wrap-box'>
            <Box className='form-area left'>
              {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
              <Box className='component-wrap col-5'>
                <Box component='div' className='col' sx={{position: 'relative'}}>
                  {/* Country */}
                  <SelectBox
                    id='slt-country'
                    size='large'
                    shrink={true}
                    label='Country'
                    required={true}
                    selected={selectedCountry}
                    selectOptions={paramCountry}
                    disabled={false}
                    placeholder=''
                    onChange={setSelectedCountry}
                  />
                </Box>
                <Box component='div' className='col'>
                  {/* CP Name */}
                  <SelectBox
                    id='select-name'
                    size='large'
                    shrink={true}
                    label='CP Name'
                    required={true}
                    selected={selectedProvider}
                    selectOptions={paramProvider}
                    disabled={false}
                    placeholder=''
                    onChange={setSelectedProvider}
                  />
                </Box>
                <Box component='div' className='col'>
                  {/* Channel Id */}
                  <TextField
                      type='outlined'
                      size='large'
                      defaultValue=''
                      placeholder='Channel Id'
                      autoComplete='off'
                      required={false}
                      InputLabelProps={{ shrink: true }}
                      value={paramChannelId}
                      onChange={(e) => setParamChannelId(e.target.value)}
                  />
                </Box>
                <Box component='div' className='col'>
                  {/* Channel Name */}
                  <TextField
                      type='outlined'
                      size='large'
                      defaultValue=''
                      placeholder='Channel Name'
                      autoComplete='off'
                      required={false}
                      InputLabelProps={{ shrink: true }}
                      value={paramChannelName}
                      onChange={(e) => setParamChannelName(e.target.value)}
                  />
                </Box>
                <Box component='div' className='col'>
                  {/* Status */}
                  <SelectBox
                    id='slt-status'
                    size='large'
                    shrink={true}
                    label='Status'
                    required={false}
                    selectOptions={paramStatus}
                    selected={selectedStatus}
                    disabled={false}
                    placeholder='Publish'
                    onChange={setSelectedStatus}
                  />
                </Box>
              </Box>

              {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
              <Box className='component-wrap'>
                <Box component='div' className='col auto'>
                  <DatePicker
                    format='MM/DD/YYYY'
                    size='large'
                    singleDate={false}
                    rangeDate={true}
                    wave={true}
                    startDate={paramStartDate}
                    endDate={paramEndDate}
                    onChangeStartDate={(newValue) => setParamStartDate(newValue)}
                    onChangeEndDate={(newValue) => setParamEndDate(newValue)}
                    sx={{maxWidth: '210px'}}
                  />

                  <Button
                    color='tertiary'
                    type='outline'
                    size='medium'
                    label='Today'
                    isIcon={false}
                    autosize
                    onClick={() => {
                      setParamStartDate(dayjs());
                      setParamEndDate(dayjs());
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className='form-area right'>
              <div className='type-button-dropdown'>
                {isValidProvider() ? (
                  <div className='button-container'>
                    <Button
                        color='primary'
                        type='box'
                        size='medium'
                        label='Channel Count'
                        isIcon={true}
                        Icon={InfoIcon}
                        iconPos='right'
                        sx={{width: '100%'}}
                        onClick={() => setIsOpenChannelCount(true)}
                    />
                  </div>
                ) : (
                  <Tooltip placement='top' title='Selcet ‘Country’ and ‘CP Name’ to active'>
                    <div className='button-container'>
                      <Button
                          color='tertiary'
                          type='box'
                          size='medium'
                          label='Channel Count'
                          isIcon={true}
                          Icon={InfoIcon}
                          iconPos='right'
                          sx={{width: '100%'}}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
              <CustomButtonGroup buttons={buttons} justifyContent='flex-end'/>
            </Box>
          </Box>
        </Box>

        {/* Result */}
        <Box className='white-box white-box-wrap'>
          <div className='box-content'>
            <Box className='white-box-content-top-area'>
              <Box className='left-area'>
                <Box className='total-count'>
                  <Box component='span' className='label'>
                    Total
                  </Box>
                  <Box component='span' className='value'>
                    {channelMapTableData.length}
                  </Box>
                </Box>
              </Box>
              <Box className='right-area'>
                <Button
                  color='tertiary'
                  type='outline'
                  size='small'
                  label='Excel Download'
                  isIcon={false}
                  autosize
                  onClick={handleExcelDownload}
                />
                <SearchField
                  id='search-2'
                  size='medium'
                  maxWidth='190px'
                  placeholder='Please enter a search term'
                  value={searchTextInput}
                  onChange={(e) => setSearchTextInput(e.target.value)}
                />

                <div className='type-button-dropdown'>
                  <Button
                    color=''
                    type='only-icon'
                    size='small'
                    label=''
                    isIcon={false}
                    className='btn-layer-popup'
                    onClick={handleClick}
                  />

                  <StyledMenu
                    id='demo-customized-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className='btn-drop-paper'
                    PaperProps={{
                      sx: {
                        maxHeight: '230px',
                      },
                    }}
                  >
                    <div className='text-label'>
                      <span>
                        Add or remove columns <br/> (Channel map id)
                      </span>
                    </div>

                    {channelMapHamburgerFilter.map((list) => (
                      <MenuItem key={list.id}>
                        {list.type === 'checkbox' && (
                          <CheckBox label={list.label} value={list.label} onChange={(e) => onChangeHamburgerFilter(e)} checked={checkedChannelMaps[list.id]} />
                        )}
                      </MenuItem>
                    ))}
                  </StyledMenu>
                </div>
              </Box>
            </Box>
            <Box className='content-inner-wrap'>
              {/* Table */}
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  {/* table Head */}
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      {tableColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          className='th'
                          align={column.align}
                          sx={{width: column.width}}
                        >
                          {column.checkbox ? (
                            <>
                              <CheckBox
                                label=''
                                value='select-all'
                                defaultChecked={false}
                                inputProps={{
                                  'aria-label': 'select all',
                                }}
                              />
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </>
                          ) : (
                            <>
                              {column.sortable ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* table Body */}
                  <TableBody className='tbody'>
                    {sortedChannelMapTdRows.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        {tableColumns.map((column, colIndex) =>
                            (colIndex >= ChannelMapHeaderHead.length && colIndex < tableColumns.length - ChannelMapHeaderTail.length) ? (
                          <TableCell key={column.id} className='td' align={column.align}>
                            {td.presenceChannelMaps.includes(column.id) ? 'O' : 'X'}
                          </TableCell>
                        ) : (
                          <TableCell key={column.id} className='td' align={column.align}>
                            {td[`${column.id}`]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
              {/* // Table */}
            </Box>

            <Pagination count={Math.ceil(channelMapTableData.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
          </div>
        </Box>
      </div>
      <PopChannelCount openChannelCount={isOpenChannelCount} closeChannelCount={() => setIsOpenChannelCount(false)} paramCountry={selectedCountry} paramProvider={selectedProvider} />
    </div>
  )
}

const mapStateToProps = state => ({
  country: state.common.country,
  provider: state.common.provider,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelMap);