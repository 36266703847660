import React, { useState, useEffect } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import Snackbar from 'components/component/BasicSnackbar';
import SearchField from 'components/component/BasicSearchField';
import Pagination from 'components/component/BasicPagination';
import SelectBox from 'components/component/BasicSelectBox';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
// css
import 'assets/scss/page/main.scss';
// Data
import {
  InformationKeywordTh,
} from 'pages/Organization/TabCategory/data/PopInformationData';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1000px',
    maxWidth: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
  display: flex;
  gap: 16px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  display: flex;
  gap: 20px;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '440px',
  overflowY: 'auto',
}));

// Select
const selOptionBusinessData3 = [
  { id: 1, value: 'Keyword Count', option: 'Keyword Count', type: 'text' },
];

// 메인 컨텐츠
function PopInformationKeywordCount(props) {
  const { countryByKeywordList, onClose } = props;

  const [displayCountryByKeywordList, setDisplayCountryByKeywordList] = useState([]);
  useEffect(() => {
    if (countryByKeywordList) {
      setDisplayCountryByKeywordList(countryByKeywordList);
    }
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    onClose();
  };

  const [searchKeywordFilter, setSearchKeywordFilter] = useState('');
  // Search Tab 연관 검색 시
  useEffect(() => {
    if (searchKeywordFilter) {
      const filteredData = countryByKeywordList.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchKeywordFilter));
      });
      setDisplayCountryByKeywordList([...filteredData]);
    } else {
      setDisplayCountryByKeywordList(countryByKeywordList);
    }
    // eslint-disable-next-line
  }, [searchKeywordFilter]);
  // SearchKeyword 연관 검색창에 검색어 입력 시
  const handleSearchKeyword = (value) => {
    setSearchKeywordFilter(value);
  };

  const [openSelect, setOpenSelect] = useState(false);

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // Paging 처리
  useEffect(() => {
    if (displayCountryByKeywordList && displayCountryByKeywordList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayCountryByKeywordList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [displayCountryByKeywordList, page, itemsPerPage]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayCountryByKeywordList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayCountryByKeywordList([...sortedData]);
  };

  return (
    <div className='component-view'>
      <Dialog open={true} size='medium' fullWidth className='pop-channel-count'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Information</DialogTitle>
          <SelectBox
            id='select-01'
            fullWidth
            size='medium'
            maxWidth='180px'
            selectOptions={selOptionBusinessData3}
            disabled={false}
            isFirstSelected={true}
            label=''
            open={openSelect}
            setOpen={setOpenSelect}
            shrink={false}
          />
        </HeaderArea>
        <DialogContent className='pop-content'>
          <BoxContainer>
            <Box className='information-container'>
              <Box className='white-box-content-top-area' justifyContent='flex-end' gap={1} sx={{ padding: 0 }}>
                <SearchField
                  placeholder='Please enter a search term'
                  maxWidth='230px'
                  onChange={(event) => handleSearchKeyword(event.target.value)}
                />
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {InformationKeywordTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            <>
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {paginatedData.map((row, index) => (
                        <TableRow className='tr' key={row.id || index}>
                          {InformationKeywordTh.map((column, colIndex) => (
                            <TableCell key={row.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                row.contentType
                              ) : colIndex === 1 ? (
                                row.countryCode
                              ) : colIndex === 2 ? (
                                row.providerId
                              ) : colIndex === 3 ? (
                                row.keyword
                              ) : colIndex === 4 ? (
                                row.count
                              ) : (
                                row.content_id
                              )
                              }
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>
              <Pagination count={Math.ceil(displayCountryByKeywordList.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
            </Box>
          </BoxContainer>
        </DialogContent>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={false}
        Duration='1000'
        bottom={'8%'}
      />
    </div>
  );
}

export default PopInformationKeywordCount;
