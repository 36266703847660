import React, {useEffect, useState} from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import { RefreshIcon } from 'components/component/BasicIcon';

import { Box, Typography } from '@mui/material';

import CodeManagementTab from "./CodeManagementTab";
import CodeManagementArea from "./CodeManagementArea";
import CodeManagementCP from "./CodeManagementCP";
import CodeManagementDMA from "./CodeManagementDMA";
import CodeManagementHomeAppCountry from "./CodeManagementHomeAppCountry";
import CodeManagementHomeAppRegion from "./CodeManagementHomeAppRegion";
import CodeManagementVodGenre from "./CodeManagementVodGenre";
import AccountPermissions from "./AccountPermissions";
import TextField from "../../../components/component/BasicTextField";
import CustomButtonGroup from "../../../components/component/BasicButtonGroup";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";

// 메인 컨텐츠
function CodeManagement({ message }) {
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Code Management',
  };

  const managementTypes = [
    { id: 'Tab', value: 'Tab', option: 'Tab', type: 'text' },
    { id: 'Area', value: 'Area', option: 'Area', type: 'text' },
    { id: 'CP', value: 'CP', option: 'CP', type: 'text' },
    { id: 'DMA', value: 'DMA', option: 'DMA', type: 'text' },
    { id: 'HomeApp-Country', value: 'HomeApp-Country', option: 'HomeApp-Country', type: 'text' },
    { id: 'HomeApp-Region', value: 'HomeApp-Region', option: 'HomeApp-Region', type: 'text' },
    { id: 'VOD-Genre', value: 'VOD-Genre', option: 'VOD-Genre', type: 'text' },
    { id: 'Account Permissions', value: 'Account Permissions', option: 'Account Permissions', type: 'text' }
  ];

  const [selectedManagementTypes, setSelectedManagementTypes] = useState('Tab');

  const [paramDeviceType, setParamDeviceType] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState('tv');
  const [regionList, setRegionList] = useState([]);

  const searchRegion = () => {
    fetch(`${BASE_URL}/code-management/region`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const deviceTypes = [...new Set(body.data.map(region => region.deviceType))]
        .map(deviceType => ({id: deviceType, value: deviceType, option: deviceType, type: 'text'}))
        setParamDeviceType(deviceTypes);
        setRegionList(body.data);
      }
    })
  }

  useEffect(() => {
    searchRegion();
  }, []);

  const [zipCodeInput, setZipCodeInput] = useState('');
  const [dmaCodes, setDmaCodes] = useState([]);

  const handleSearchDma = (zipCodeInput) => {
    let url = `${BASE_URL}/code-management/dma`;
    if (zipCodeInput && zipCodeInput.length > 0) {
      url += `?zipCode=${zipCodeInput}`;
    }
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setDmaCodes(body.data);
      }
    })
  }

  const handleRefresh = () => {
    setZipCodeInput('');
    handleSearchDma('');
  }

  useEffect(() => {
    setZipCodeInput('');
  }, [selectedManagementTypes]);

  useEffect(() => {
    if (message && message.managementType) {
      setSelectedManagementTypes(message.managementType);
    }
  }, [message]);

  const renderTabs = (codeType) => {
    switch (codeType) {
      case 'Tab': return <CodeManagementTab />;
      case 'Area': return <CodeManagementArea />;
      case 'CP': return <CodeManagementCP />;
      case 'DMA': return <CodeManagementDMA dmaCodes={dmaCodes} handleSearch={() => handleSearchDma(zipCodeInput)} />;
      case 'HomeApp-Country': return <CodeManagementHomeAppCountry />;
      case 'HomeApp-Region': return <CodeManagementHomeAppRegion regionList={regionList} deviceType={selectedDeviceType} paramDeviceType={paramDeviceType} afterSave={searchRegion} />;
      case 'VOD-Genre': return <CodeManagementVodGenre />;
      case 'Account Permissions': return <AccountPermissions />;
      default : return <></>;
    }
  }

  // Button
  const FilterButtons = [
    {
      color: 'tertiary',
      type: 'outline icon',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      autosize: false,
      minWidth: '107px',
      onClick: handleRefresh
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: false,
      minWidth: '120px',
      onClick: () => handleSearchDma(zipCodeInput)
    },
  ];

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container pr-page-container' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Code Management
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        {/*  //BreadCrumb Area */}

        <Box className='content-wrap ' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Role */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Management Type'
                      required={false}
                      selectOptions={managementTypes}
                      disabled={false}
                      placeholder=''
                      selected={selectedManagementTypes}
                      onChange={(newValue) => setSelectedManagementTypes(newValue)}
                    />
                  </Box>
                  {selectedManagementTypes === 'DMA' && (
                    // Zip Code
                    <Box component='div' className='col'>
                      <TextField
                          type='outlined'
                          size='large'
                          placeholder='ZIP Code'
                          label=''
                          autoComplete='off'
                          required={true}
                          InputLabelProps={{shrink: false}}
                          value={zipCodeInput}
                          onChange={(e) => setZipCodeInput(e.target.value)}
                      />
                    </Box>
                  )}
                  {selectedManagementTypes === 'HomeApp-Region' && (
                      <Box component='div' className='col'>
                        <SelectBox
                            id='cm-manage'
                            size='large'
                            shrink={true}
                            label='Device Type'
                            required={false}
                            selectOptions={paramDeviceType}
                            disabled={false}
                            placeholder=''
                            selected={selectedDeviceType}
                            onChange={(newValue) => setSelectedDeviceType(newValue)}
                        />
                      </Box>
                  )}
                </Box>
              </Box>
              <div className='form-area right'>
                {selectedManagementTypes === 'DMA' && (
                  <CustomButtonGroup buttons={FilterButtons}/>
                )}
              </div>
            </Box>
          </Box>
          {renderTabs(selectedManagementTypes)}


        </Box>
      </Box>
    </Box>
  );
}

export default CodeManagement;
