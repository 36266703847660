import React, {useEffect, useState} from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  Box,
  IconButton,
  Divider,
  Typography,
  Grid,
} from '@mui/material';

import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import CustomTabs from 'components/component/BasicTabs';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';

import {BASE_URL} from "constants";
import {getHeaders} from "utils/Header";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1118px', //팝업사이즈
    maxWidth: 'none',
    maxHeight: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  width: 100%;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

const tabs = [{ label: 'Basic Information' }, { label: 'Media & Image' }];

const openUrlNewWindow = (url) => {
  window.open(url);
}

const getImageTypeFromUrl = (imageUrl) => {
  const url = new URL(imageUrl).pathname;
  const extension = url.split('.').pop().toLowerCase();
  if (extension === 'jpg' || extension === 'jpeg') return 'image/jpeg';
  return 'image/' + extension;
}

// DetailItem 컴포넌트 생성
const DetailItem = ({ label, value, xs, className }) => (
  <Grid item xs={xs} className={`list-wrap ${className}`}>
    <div className='info-value-wrap'>
      <Typography component='span' className='label'>
        {label}
      </Typography>
      <div className='value'>
        <Typography component='span' className='text'>
          {value}
        </Typography>
      </div>
    </div>
  </Grid>
);

function PopProgramDetail(props) {
  const { programId, open, close } = props;

  // Tab
  const [value, setValue] = useState(0);
  const [programInfo, setProgramInfo] = useState({});
  const [programImgSize, setProgramImgSize] = useState({width: 0, height: 0});
  const [stillcutSize, setStillcutSize] = useState({width: 0, height: 0});
  const [stillcutRatio, setStillcutRatio] = useState('');
  const [stillcutImgType, setStillcutImgType] = useState('');
  const [thumbnailSize, setThumbnailSize] = useState({width: 0, height: 0});
  const [thumbnailRatio, setThumbnailRatio] = useState('');
  const [thumbnailImgType, setThumbnailImgType] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    close();
  };

  const buttons = [
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: 'OK',
      isIcon: 'false',
      IconType: '',
      iconPos: undefined,
      className: 'btn',
      onClick: handleClose
    },
  ];

  useEffect(() => {
    fetch(`${BASE_URL}/channel-status/program/detail/${programId}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result !== 'SUCCESS') {
        return;
      }
      const program = body.data;

      setProgramInfo(body.data)
      if (program.imageUrl) {
        const img = new Image();
        img.src = program.imageUrl;
        img.onload = () => {
          setProgramImgSize({width: img.width, height: img.height});
        };
      }
      if (program.stillcutUrl) {
        const img = new Image();
        img.src = program.stillcutUrl;
        img.onload = () => {
          setStillcutSize({width: img.width, height: img.height});
          setStillcutRatio(program.stillcutRatio);
          setStillcutImgType(getImageTypeFromUrl(program.stillcutUrl));
        }
      }
      if (program.thumbnailUrl) {
        const img = new Image();
        img.src = program.thumbnailUrl;
        img.onload = () => {
          setThumbnailSize({width: img.width, height: img.height});
          setThumbnailRatio(program.thumbnailRatio);
          setThumbnailImgType(getImageTypeFromUrl(program.thumbnailUrl));
        }
      }
    });
  }, [programId]);

  return (
    <div className='component-view'>
      <Dialog open={open} size='large' className='pop-program-detail'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Program Detail</DialogTitle>
        </HeaderArea>

        <DialogContent className='pop-content'>
          <BoxContainer>
            <Box className='detail-content'>
              <CustomTabs tabStyle='type-01' value={value} handleChange={handleChange} tabs={tabs} />

              {/* Basic Information */}
              {value === 0 && (
                <div className='tab-panel'>
                  <div className='panel-wrap'>
                    <Box className='detail-list-container'>
                      <Grid container className='section' columnSpacing={2.5}>
                        <Grid item xs={6}>
                          {/*
                            DetailItem - 굵은 폰트 클래스 : bold / 컬러 폰트 클래스 : red
                          */}
                          <DetailItem label='Program ID' className='' value={programInfo.programId} />
                          <DetailItem label='Duration' className='' value={programInfo.duration} />
                          <DetailItem label='Title' className='' value={programInfo.title} />
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            <CustomImage src={programInfo.imageUrl} fit={false} sx={{ width: 517, height: 202 }} alt='' rounded />
                            <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
                              <Typography className='img-size'>{programImgSize.width}px X {programImgSize.height}px</Typography>
                              <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='Image URL'
                                isIcon={true}
                                Icon={BtnTeritaryOutlineRight}
                                iconPos='right'
                                href='#'
                                onClick={() => openUrlNewWindow(programInfo.imageUrl)}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container columnSpacing={2.5}>
                        <Grid item xs={12}>
                          <DetailItem label='Description' value={programInfo.description} />
                        </Grid>
                        <Grid item xs={12}>
                          <DetailItem label='CP Name' className='bold' value={programInfo.providerName} />
                        </Grid>
                        <Grid item xs={6}>
                          <DetailItem label='Episode title' value={programInfo.episodeTitle} />
                        </Grid>
                        <Grid item xs={6}>
                          <DetailItem label='Episode No.' value={programInfo.episodeNumber} />
                        </Grid>
                        <Grid item xs={6}>
                          <DetailItem label='Season title' value={programInfo.seasonTitle} />
                        </Grid>
                        <Grid item xs={6}>
                          <DetailItem label='Season No.' value={programInfo.seasonNumber} />
                        </Grid>
                        <Grid item xs={6}>
                          <DetailItem label='First Genre Code' value={programInfo.firstGenreCode} />
                        </Grid>
                        <Grid item xs={6}>
                          <DetailItem label='Second Genre Code' value={programInfo.secondGenreCode} />
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </div>
              )}

              {/* Media & Image */}
              {value === 1 && (
                <div className='tab-panel'>
                  <div className='panel-wrap'>
                    <Box className='media-detail-container' sx={{ maxHeight: '520px' }}>
                      <Box className='media-inner-wrap'>
                        {/* Media */}
                        <Box className='media-wrap'>
                          <div className='title-area'>
                            <Typography variant='h3' className='category-title'>
                              Media
                            </Typography>
                          </div>

                          <Box className='media-item-wrap'>
                            <div className='item-image-area'>
                              <CustomImage
                                src={programInfo.imageUrl}
                                fit={false}
                                sx={{ width: 378, height: 202 }}
                                alt=''
                                rounded
                              />
                            </div>
                            <Box className='item-info-area'>
                              <Box>
                                <Box className='item-row'>
                                  {programInfo.streamContainerType && (
                                    <>
                                      <Typography className='text label'>Stream Container Type :</Typography>
                                      <Typography className='text value'>{programInfo.streamContainerType}</Typography>
                                    </>
                                  )}
                                </Box>
                                <Box className='item-row'>
                                  <Typography className='text label'>Resolution :</Typography>
                                  <Typography className='text value'>{programInfo.resolution}</Typography>
                                </Box>
                              </Box>
                              <Box className='link-url'>
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='Media URL'
                                  isIcon={true}
                                  Icon={BtnTeritaryOutlineRight}
                                  iconPos='right'
                                  href='#'
                                  target='_blank'
                                  onClick={() => openUrlNewWindow(programInfo.mediaStaticUrl)}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        {/* Image List */}
                        <Box className='media-wrap'>
                          <div className='title-area'>
                            <Typography variant='h3' className='category-title'>
                              Image List
                            </Typography>
                          </div>
                          {programInfo.stillcutUrl && (
                              <Box className='media-item-wrap'>
                                <div className='item-image-area'>
                                  <CustomImage
                                      src={programInfo.stillcutUrl}
                                      fit={false}
                                      sx={{ width: 378, height: 202 }}
                                      alt=''
                                      rounded
                                  />
                                </div>
                                <Box className='item-info-area'>
                                  <Box className=''>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Type : </Typography>
                                      <Typography className='text value'>{stillcutImgType}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Ratio/Size : </Typography>
                                      <Typography className='text value'>{stillcutRatio} / {stillcutSize.width}x{stillcutSize.height}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Group : </Typography>
                                      <Typography className='text value'>Still cut</Typography>
                                    </Box>
                                  </Box>
                                  <Box className='link-url'>
                                    <Button
                                        color='tertiary'
                                        type='outline'
                                        size='small'
                                        label='Image URL'
                                        isIcon={true}
                                        Icon={BtnTeritaryOutlineRight}
                                        iconPos='right'
                                        href='#'
                                        target='_blank'
                                        onClick={() => openUrlNewWindow(programInfo.stillcutUrl)}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                          )}
                          {programInfo.thumbnailUrl && (
                              <Box className='media-item-wrap'>
                                <div className='item-image-area'>
                                  <CustomImage
                                      src={programInfo.thumbnailUrl}
                                      fit={false}
                                      sx={{ width: 378, height: 202 }}
                                      alt=''
                                      rounded
                                  />
                                </div>
                                <Box className='item-info-area'>
                                  <Box className=''>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Type : </Typography>
                                      <Typography className='text value'>{thumbnailImgType}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Ratio/Size : </Typography>
                                      <Typography className='text value'>{thumbnailRatio} / {thumbnailSize.width}x{thumbnailSize.height}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Group : </Typography>
                                      <Typography className='text value'>Thumbnail</Typography>
                                    </Box>
                                  </Box>
                                  <Box className='link-url'>
                                    <Button
                                        color='tertiary'
                                        type='outline'
                                        size='small'
                                        label='Image URL'
                                        isIcon={true}
                                        Icon={BtnTeritaryOutlineRight}
                                        iconPos='right'
                                        href='#'
                                        target='_blank'
                                        onClick={() => openUrlNewWindow(programInfo.thumbnailUrl)}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                          )}

                        </Box>
                      </Box>
                    </Box>
                  </div>
                </div>
              )}
            </Box>
          </BoxContainer>
        </DialogContent>

        <Divider sx={{ margin: '24px 0' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={buttons} justifyContent='center' />
        </DialogActions>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={false}
        Duration='1000'
        bottom={'8%'}
      />
    </div>
  );
}

export default PopProgramDetail;