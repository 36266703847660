import React, { useMemo, useState, useEffect } from 'react';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import TextField from 'components/component/BasicTextField';
import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';

import {
  DeviceTypeOption,
  PlatformVersionOption
} from 'pages/Organization/ANTestService/data/ANTestServicedata';
import { getTabCategoryAction, updateAnTestService } from '../ANTestService/api/restApi';

// 메인 컨텐츠
function PopEditANTestService(props) {
  const { onClose, handelSetShowSnackBar, editAnTestService, handelDefaultValue, setSFlag, type } = props;
  const [tabCategoryList, setTabCategoryList] = useState([]);
  const [updateANTestService, setUpdateANTestService] = useState({
    countryCode: editAnTestService.countryCode,
    region: editAnTestService.region,
    deviceType: editAnTestService.deviceType,
    platformVersion: editAnTestService.platformVersion,
    tabCode: editAnTestService.tabCode,
    categoryCode: editAnTestService.categoryCode,
    title: editAnTestService.title,
    startDate: type === 'antest'
      ? dayjs(editAnTestService.startTime).format('YYYY-MM-DD')
      : dayjs(editAnTestService.startTimeLocal).format('YYYY-MM-DD'),
    endDate: type === 'antest'
      ? dayjs(editAnTestService.endTime).format('YYYY-MM-DD')
      : dayjs(editAnTestService.endTimeLocal).format('YYYY-MM-DD'),
    startTime: type === 'antest'
      ? dayjs(editAnTestService.startTime).format("HH:00")
      : dayjs(editAnTestService.startTimeLocal).format("HH:00"),
    endTime: type === 'antest'
      ? dayjs(editAnTestService.endTime).format("HH:00")
      : dayjs(editAnTestService.endTimeLocal).format("HH:00"),
    abtestId: editAnTestService.abtestId,
    idx: editAnTestService.idx,
    status: editAnTestService.status
  });

  const today = dayjs(new Date());

  const handleClose = () => {
    onClose();
  };

  const onChangeTab = (value) => {
    setUpdateANTestService((prevState) => ({
      ...prevState,
      tabCode: value,
      categoryCode: '',
    }));
  }

  const platformVersionSelectOptions = useMemo(() => {
    if (updateANTestService.deviceType in PlatformVersionOption) {
      const platVer = PlatformVersionOption[updateANTestService.deviceType];
      return platVer.filter(option => option.value !== 'all');
    } else {
      return [];
    }
  }, [updateANTestService.deviceType]);

  useEffect(() => {
    if (platformVersionSelectOptions.length > 0) {
      setUpdateANTestService((prevState) => ({
        ...prevState,
        platformVersion: platformVersionSelectOptions[0].value,
      }));
    } else {
      setUpdateANTestService((prevState) => ({
        ...prevState,
        platformVersion: '',
      }));
    }
  }, [platformVersionSelectOptions]);

  const tabSelectsOptions = useMemo(() => {
    const options = tabCategoryList.map((tabData) => ({
      id: tabData.tabCode + ' ' + tabData.tabName,
      value: tabData.tabCode,
      option: tabData.tabCode + ' ' + tabData.tabName,
      type: "text",
    }));

    if (options) {
      options.sort((a, b) => a.option.localeCompare(b.option));
    }

    if (options.length > 0 && !updateANTestService.tabCode) {
      setUpdateANTestService((prevState) => ({
        ...prevState,
        tabCode: options[0].value,
      }));
    }
    return options;
  }, [tabCategoryList, updateANTestService.tabCode]);

  const categorySelectOptions = useMemo(() => {
    const options = updateANTestService.tabCode && (tabCategoryList.find(n => n.tabCode === updateANTestService.tabCode) || { categoryDetails: [] }).categoryDetails.map((categoryData) => ({
      id: categoryData.categoryCode + ' ' + categoryData.categoryName,
      value: categoryData.categoryCode,
      option: categoryData.categoryCode + ' ' + categoryData.categoryName,
      type: "text",
    }));

    if (options) {
      options.sort((a, b) => a.option.localeCompare(b.option));
    }

    if (options.length > 0 && !updateANTestService.categoryCode) {
      setUpdateANTestService((prevState) => ({
        ...prevState,
        categoryCode: options[0].value,
      }));
    }
    return options;
    // eslint-disable-next-line
  }, [updateANTestService.tabCode, tabCategoryList]);

  const timeSelectOptions = useMemo(() => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, '0');
      times.push({ id: `${hour}:00`, value: `${hour}:00`, option: `${hour}:00`, type: 'text' })
    }
    return times;
  }, []);

  const adjustDateTimeFormat = (dateValue, timeValue) => {
    return `${dayjs(dateValue).format('YYYY-MM-DD')} ${timeValue}:00`;
  }

  const isDateTimeValid = useMemo(() => {
    const startDateTime = new Date(adjustDateTimeFormat(updateANTestService.startDate, updateANTestService.startTime));
    const endDateTime = new Date(adjustDateTimeFormat(updateANTestService.endDate, updateANTestService.endTime));

    return startDateTime < endDateTime;
  }, [updateANTestService.startDate, updateANTestService.startTime, updateANTestService.endDate, updateANTestService.endTime]);

  const isActive =
    updateANTestService.deviceType &&
    updateANTestService.platformVersion &&
    updateANTestService.tabCode &&
    updateANTestService.categoryCode &&
    updateANTestService.title?.trim() !== "" &&
    updateANTestService.startDate &&
    updateANTestService.endDate &&
    updateANTestService.startTime &&
    updateANTestService.endTime &&
    isDateTimeValid;

  const onChangeDeviceType = (value) => {
    setUpdateANTestService((prevState) => ({
      ...prevState,
      deviceType: value,
      categoryCode: ''
    }));
  }

  const onChangePlatformVersion = (value) => {
    setUpdateANTestService((prevState) => ({
      ...prevState,
      platformVersion: value,
      categoryCode: ''
    }));

  }

  const onStartDateChange = (newDate) => {
    if (newDate && newDate.$d) {
      const formattedDate = dayjs(newDate.$d).format("YYYY-MM-DD");
      setUpdateANTestService((prevState) => ({
        ...prevState,
        startDate: formattedDate
      }));
    } else {
      setUpdateANTestService((prevState) => ({
        ...prevState,
        startDate: null,
      }));
    }
  };

  const onEndDateChange = (newDate) => {
    if (newDate && newDate.$d) {
      const formattedDate = dayjs(newDate.$d).format("YYYY-MM-DD");
      setUpdateANTestService((prevState) => ({
        ...prevState,
        endDate: formattedDate
      }));
    } else {
      setUpdateANTestService((prevState) => ({
        ...prevState,
        endDate: null,
      }));
    }
  };

  const getTabCategoryData = async () => {
    if (updateANTestService) {
      const res = await getTabCategoryAction(updateANTestService.deviceType, editAnTestService.countryCode, updateANTestService.platformVersion, type);
      setTabCategoryList(res.data);
    }
  };

  const onChangeStartTime = (value) => {
    setUpdateANTestService((prevState) => ({
      ...prevState,
      startTime: value,
    }))
  }

  const onChangeEndTime = (value) => {
    setUpdateANTestService((prevState) => ({
      ...prevState,
      endTime: value,
    }));
  }

  const onChangeCategoryCode = (value) => {
    setUpdateANTestService((prevState) => ({
      ...prevState,
      categoryCode: value,
    }));
  }

  const onChangeTitle = (value) => {
    setUpdateANTestService((prevState) => ({
      ...prevState,
      title: value,
    }));
  }

  useEffect(() => {
    if (updateANTestService.deviceType && updateANTestService.platformVersion) {
      getTabCategoryData();
    }
    // eslint-disable-next-line
  }, [updateANTestService.deviceType, updateANTestService.platformVersion]);

  const handleUpdate = async () => {
    let startDate = adjustDateTimeFormat(updateANTestService.startDate, updateANTestService.startTime);
    let endDate = adjustDateTimeFormat(updateANTestService.endDate, updateANTestService.endTime);

    handleClose();
    const addAnTest = {
      abtestId: updateANTestService.abtestId,
      countryCode: updateANTestService.countryCode,
      region: updateANTestService.region,
      deviceType: updateANTestService.deviceType,
      title: updateANTestService.title,
      platformVersion: updateANTestService.platformVersion,
      tabCode: updateANTestService.tabCode,
      categoryCode: updateANTestService.categoryCode,
      ...(type === 'antest'
        ? { startTime: startDate, endTime: endDate }
        : { startTimeLocal: startDate, endTimeLocal: endDate }
      ),
      idx: updateANTestService.idx,
      status: editAnTestService.status
    };
    const res = await updateAnTestService(updateANTestService.idx, addAnTest, type);
    handelSetShowSnackBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    onClose();
    setSFlag(true);
    handelDefaultValue();
  }
  const timeSelectEndOptions = useMemo(() => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, '0');
      const time = `${hour}:00`;
      times.push({
        id: time,
        value: time,
        option: time,
        type: 'text'
      });
    }

    return times;
  }, []);

  const dialogButtons = useMemo(() => {
    return [
      {
        width: '150px',
        size: 'large',
        label: 'Cancel',
        type: 'outline',
        color: 'tertiary',
        onClick: handleClose,
        disabled: false,
      },
      {
        width: '150px',
        size: 'large',
        label: 'Update',
        type: 'box',
        color: 'primary',
        onClick: handleUpdate,
        disabled: !isActive,
      },
    ];
    // eslint-disable-next-line
  }, [isActive, updateANTestService]);

  const fields = [
    // Country
    {
      xs: 12,
      isLabel: true,
      isBorder: true,
      label: 'Country',
      value: (
        <Typography component='span' className='text'>
          {editAnTestService.countryCode}
        </Typography>
      ),
    },

    // Device Type
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-device-type'
            fullWidth
            label='Device Type'
            required={true}
            size='large'
            placeholder='Select'
            selectOptions={DeviceTypeOption}
            disabled={editAnTestService.status === 'ready' ? false : editAnTestService.status === 'done' ? true : true}
            shrink
            selected={updateANTestService.deviceType}
            onChange={onChangeDeviceType}
          />
        </>
      ),
    },

    // Platform version
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-platform-version'
            fullWidth
            label='Platform version'
            required={true}
            size='large'
            placeholder='Select'
            selectOptions={platformVersionSelectOptions}
            disabled={editAnTestService.status === 'ready' ? false : editAnTestService.status === 'done' ? true : true}
            shrink
            selected={updateANTestService.platformVersion}
            onChange={(value) => onChangePlatformVersion(value)}
          />
        </>
      ),
    },

    // Tab
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-tab'
            fullWidth
            label='Tab'
            required={true}
            size='large'
            selectOptions={tabSelectsOptions ? tabSelectsOptions : []}
            disabled={editAnTestService.status === 'ready' ? false : editAnTestService.status === 'done' ? true : true}
            shrink
            selected={updateANTestService.tabCode || ''}
            onChange={onChangeTab}
          />
        </>
      ),
    },

    // Category
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-category'
            fullWidth
            label='Category'
            required={true}
            size='large'
            selectOptions={categorySelectOptions ? categorySelectOptions : []}
            disabled={editAnTestService.status === 'ready' ? false : editAnTestService.status === 'done' ? true : true}
            shrink
            selected={updateANTestService.categoryCode}
            onChange={(value) => onChangeCategoryCode(value)}
          />
        </>
      ),
    },

    // Title
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <TextField
            type='outlined'
            size='large'
            placeholder=''
            defaultValue=''
            label='Title'
            autoComplete='off'
            required={true}
            InputLabelProps={{ shrink: true }}
            value={updateANTestService.title}
            onChange={(e) => onChangeTitle(e.target.value)}
            maxLength={255}
          />
        </>
      ),
    },

    // Start Date
    {
      xs: 6,
      label: 'Start Date',
      required: true,
      value:
        <DatePicker className='input-date-picker' label='Start Date' size='large' shrink required={true} singleDate={true} wave={true} format='MM/DD/YYYY'
          disabled={editAnTestService.status !== 'ready'}
          minDate={today} error={!isDateTimeValid}
          maxDate={updateANTestService.endDate ? dayjs(updateANTestService.endDate) : dayjs(null)}
          value={updateANTestService.startDate ? dayjs(updateANTestService.startDate) : dayjs(null)} onChange={(value) => onStartDateChange(value)} />,
    },
    // End Date
    {
      xs: 6,
      label: 'End Date',
      required: true,
      value:
        <DatePicker className='input-date-picker' label='End Date' size='large' shrink required={true} singleDate={true} wave={true}
          disabled={editAnTestService.status === 'done'}
          format='MM/DD/YYYY'
          maxDate={null} error={!isDateTimeValid}
          minDate={updateANTestService.startDate ? dayjs(updateANTestService.startDate) : today}
          value={updateANTestService.endDate ? dayjs(updateANTestService.endDate) : dayjs(null)} onChange={(value) => onEndDateChange(value)} />,
    },

    // Start Time
    {
      xs: 6,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-start-time'
            fullWidth
            label={`Start Time (${type === 'antest' ? 'UTC' : 'Local'})`}
            required={true}
            size='large'
            selectOptions={timeSelectOptions}
            disabled={editAnTestService.status !== 'ready'}
            shrink
            selected={updateANTestService.startTime}
            onChange={(value) => onChangeStartTime(value)}
            error={!isDateTimeValid}
          />
        </>
      ),
    },
    {
      xs: 6,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-end-time'
            fullWidth
            label={`End Time (${type === 'antest' ? 'UTC' : 'Local'})`}
            required={true}
            size='large'
            selectOptions={timeSelectEndOptions}
            disabled={editAnTestService.status === 'done'}
            shrink
            selected={updateANTestService.endTime}
            onChange={(value) => onChangeEndTime(value)}
            error={!isDateTimeValid}
          />
        </>
      ),
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title={type === 'antest' ? 'Edit A/N Test' : 'Edit A/N Service'}
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <Box className='layout-label-container'>
            <Box className='detail-list-container'>
              <Grid container columnSpacing={3}>
                {fields.map(({ xs, label, required, value, isLabel, isBorder }, index) => (
                  <Grid item xs={xs} className='list-wrap' key={index}>
                    <Box mb={1} className='info-value-wrap'>
                      {isLabel && (
                        <Typography component='span' className={`label ${required ? 'required' : ''}`}>
                          <span className='text'>{label}</span>
                        </Typography>
                      )}
                      <div className={`value ${isBorder ? 'border' : ''}`}>{value}</div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopEditANTestService;
