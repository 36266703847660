import React, {useEffect, useState} from 'react';
import Button from 'components/component/BasicButton';
import TextLink from 'components/component/BasicTextLink';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import { BASE_URL } from "../../../constants";
import { getHeaders } from "../../../utils/Header";
import PopHandleCountry from "./PopHandleCountry";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function CodeManagementHomeAppCountry() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayCountryCodes].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayCountryCodes([...sortedData])
  };

  const [countryCodes, setCountryCodes] = useState([]);
  const [displayCountryCodes, setDisplayCountryCodes] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState('');
  const [isOpenPopHandleCountry, setIsOpenPopHandleCountry] = useState(false);
  const [popupType, setPopupType] = useState('Add');
  const [countryToEdit, setCountryToEdit] = useState({});
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);

  const searchCountry = () => {
    fetch(`${BASE_URL}/code-management/country`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setCountryCodes(body.data);
      }
    })
  }

  useEffect(() => {
    searchCountry();
  }, []);

  useEffect(() => {
    setDisplayCountryCodes(countryCodes);
  }, [countryCodes]);

  useEffect(() => {
    setDisplayCountryCodes(countryCodes.filter(
        (item) => item.countryName.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.countryCode.toLowerCase().includes(searchTextInput.toLowerCase())));
    setPage(1);
    // eslint-disable-next-line
  }, [searchTextInput]);

  const openAddPopup = () => {
    setPopupType('Add');
    setIsOpenPopHandleCountry(true);
  }

  const openEditPopup = (country) => {
    setCountryToEdit(country);
    setPopupType('Edit');
    setIsOpenPopHandleCountry(true);
  }

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayCountryCodes.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayCountryCodes.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayCountryCodes, page, itemsPerPage]);

  return (
      <Box className='white-box white-box-wrap'>
        <div className='box-content'>
          <Box className='white-box-content-top-area' alignItems='flex-end'>
            <Box className='left-area column'>
              <Box display='flex' alignItems={'center'} gap={1}>
                <Box className='total-count'>
                  <Box component='span' className='label'>
                    Total
                  </Box>
                  <Box component='span' className='value'>
                    {displayCountryCodes.length}
                  </Box>
                </Box>
                <Typography className='sm-sub-text' ml={1}>
                  * If existing country information needs to be updated, please contact us via Harmony.
                </Typography>
              </Box>
              <Box display='flex' gap={1}>
                <SearchField placeholder='Please enter a search term' maxWidth='230px'
                             value={searchTextInput}
                             onChange={(e) => setSearchTextInput(e.target.value)} />
              </Box>
            </Box>

            <Box className='right-area'>
              <Button color='primary' type='box' size='medium' label='Add' isIcon={false} autosize
                      onClick={openAddPopup} />
            </Box>
          </Box>
          <Box className='content-inner-wrap'>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table' aria-label='table' stickyHeader>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    <TableCell key={'countryCode'} className='th' align={'left'} sx={{ width: '18%' }}>
                      <TableSortLabel
                          active={orderBy === 'countryCode'}
                          direction={orderBy === 'countryCode' ? order : 'desc'}
                          onClick={() => handleRequestSort('countryCode')}
                      >
                        Country Code
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'countryName'} className='th' align={'left'} sx={{ width: '18%' }}>
                      <TableSortLabel
                          active={orderBy === 'countryName'}
                          direction={orderBy === 'countryName' ? order : 'desc'}
                          onClick={() => handleRequestSort('countryName')}
                      >
                        Country Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'countryTime'} className='th' align={'left'} sx={{ width: '20%' }}>
                      <TableSortLabel
                          active={orderBy === 'countryTime'}
                          direction={orderBy === 'countryTime' ? order : 'desc'}
                          onClick={() => handleRequestSort('countryTime')}
                      >
                        Country Time
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'ric'} className='th' align={'left'} sx={{ width: '14%' }}>
                      <TableSortLabel
                          active={orderBy === 'ric'}
                          direction={orderBy === 'ric' ? order : 'desc'}
                          onClick={() => handleRequestSort('ric')}
                      >
                        RIC
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'primaryLanguage'} className='th' align={'left'} sx={{ width: '14%' }}>
                      <TableSortLabel
                          active={orderBy === 'primaryLanguage'}
                          direction={orderBy === 'primaryLanguage' ? order : 'desc'}
                          onClick={() => handleRequestSort('primaryLanguage')}
                      >
                        Primary Language
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'secondaryLanguageList'} className='th' align={'left'} sx={{ width: 'auto' }}>
                      <TableSortLabel
                          active={orderBy === 'secondaryLanguageList'}
                          direction={orderBy === 'secondaryLanguageList' ? order : 'desc'}
                          onClick={() => handleRequestSort('secondaryLanguageList')}
                      >
                        Secondary Language
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* table Body */}
                <TableBody className='tbody'>
                  {paginatedData.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        <TableCell key={'countryCode'} className='td' align={'left'}>
                          {td[`lastChgUsrNo`] ? (
                              <TextLink href='' text={td[`countryCode`]} onClick={() => openEditPopup(td)} />
                          ) : (
                              td[`countryCode`]
                          )}
                        </TableCell>
                        <TableCell key={'countryName'} className='td' align={'left'}>
                          {td[`countryName`]}
                        </TableCell>
                        <TableCell key={'countryTime'} className='td' align={'left'}>
                          {parseInt(td[`countryTime`]) >= 0 ? 'UTC+' + td[`countryTime`] : 'UTC' + td[`countryTime`]}
                        </TableCell>
                        <TableCell key={'ric'} className='td' align={'left'}>
                          {td[`ric`]}
                        </TableCell>
                        <TableCell key={'primaryLanguage'} className='td' align={'left'}>
                          {td[`primaryLanguage`]}
                        </TableCell>
                        <TableCell key={'secondaryLanguage'} className='td' align={'left'}>
                          {td[`secondaryLanguageList`].join(', ')}
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
            {/* // Table */}
          </Box>

          {/* Pagination */}
          <Pagination count={Math.ceil(displayCountryCodes.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
        </div>
        {isOpenPopHandleCountry && (
            <PopHandleCountry onClose={() => setIsOpenPopHandleCountry(false)}
                              afterSave={searchCountry} popupType={popupType} countryToEdit={countryToEdit} />
        )}

      </Box>
  );
}

export default CodeManagementHomeAppCountry;
