import React, {useEffect, useState} from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import TextLink from 'components/component/BasicTextLink';
import CheckBox from 'components/component/BasicCheckBox';

import SearchField from 'components/component/BasicSearchField';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import PopEditCPName from "./PopEditCPName";
import PopAddCPName from "./PopAddCPName";
import Dialog from "../../../components/component/BasicDialog";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function CodeManagementCP() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayCpInfo].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayCpInfo([...sortedData])
  };

  const [cpInfo, setCpInfo] = useState([]);
  const [displayCpInfo, setDisplayCpInfo] = useState([]);
  const [isOpenEditCp, setIsOpenEditCp] = useState(false);
  const [isOpenAddCp, setIsOpenAddCp] = useState(false);
  const [providerToEdit, setProviderToEdit] = useState({});
  const [searchTextInput, setSearchTextInput] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);
  const [checkedCps, setCheckedCps] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');


  const getCp = () => {
    fetch(`${BASE_URL}/code-management/cp`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setCpInfo(body.data);
        setDisplayCpInfo(body.data);
      }
    })
  }

  useEffect(() => {
    getCp();
  }, []);

  const openEditCp = (providerId) => {
    let filter = cpInfo.find(cp => cp.providerId === providerId);
    setProviderToEdit(filter);
    setIsOpenEditCp(true);
  }

  useEffect(() => {
    setDisplayCpInfo(cpInfo.filter(
        (item) => item.providerId.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.providerName.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.parentProviderId?.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.parentProviderName?.toLowerCase().includes(searchTextInput.toLowerCase())));
    setPage(1);
    //eslint-disable-next-line
  }, [searchTextInput]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayCpInfo.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayCpInfo.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayCpInfo, page, itemsPerPage]);

  const onClickCheckBox = (e) => {
    let checkedList = [...checkedCps];
    if (e.target.checked) {
      checkedList.push(e.target.value);
    } else {
      checkedList = checkedList.filter((item) => item !== e.target.value);
    }
    if (displayCpInfo.length === checkedList.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setCheckedCps(checkedList);
  }

  useEffect(() => {
    if (displayCpInfo.length === 0) {
      setAllChecked(false);
    } else if (displayCpInfo.length === checkedCps.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [displayCpInfo, checkedCps]);

  useEffect(() => {
    setCheckedCps([...checkedCps.filter(providerId => displayCpInfo.map(item => item.providerId).includes(providerId))]);
    // eslint-disable-next-line
  }, [displayCpInfo]);

  const onClickCheckAll = (e) => {
    if (e.target.checked) {
      setCheckedCps(displayCpInfo.map((item) => item.providerId));
      setAllChecked(true);
    } else {
      setCheckedCps([]);
      setAllChecked(false);
    }
  }

  const getIfChecked = (cpCode) => {
    return checkedCps.includes(cpCode);
  }

  const handleConfirmDelete = () => {
    if (checkedCps.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select the Cp you want to delete.');
      setAlertOpen(true);
      return;
    }
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to delete the selected Cps?');
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const deleteCps = () => {
    setConfirmOpen(false);

    if (checkedCps.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select the CP you want to delete.');
      setAlertOpen(true);
      return;
    }
    fetch(`${BASE_URL}/code-management/cp`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(checkedCps)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success');
        setAlertMessage('CP has been deleted successfully.');
        setAlertOpen(true);
        getCp();
        setCheckedCps([]);
        setAllChecked(false);
      } else {
        setAlertTitle('Error');
        setAlertMessage('Failed to delete CP.');
        setAlertOpen(true);
      }
    })
  }

  const crudButtons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      autosize: true,
      onClick: handleConfirmDelete,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Add',
      isIcon: false,
      autosize: true,
      onClick: () => setIsOpenAddCp(true),
    },
  ];

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: deleteCps
    },
  ];

  return (
    <Box className='white-box white-box-wrap'>
      <div className='box-content'>
        <Box className='white-box-content-top-area' alignItems='flex-end'>
          <Box className='left-area column'>
            <Box className='total-count'>
              <Box component='span' className='label'>
                Total
              </Box>
              <Box component='span' className='value'>
                {displayCpInfo.length}
              </Box>
            </Box>
            <Box display='flex' gap={1}>
              <SearchField placeholder='Please enter a search term' maxWidth='230px'
                           value={searchTextInput}
                           onChange={(e) => setSearchTextInput(e.target.value)}/>
            </Box>
          </Box>

          <Box className='right-area'>
            <CustomButtonGroup buttons={crudButtons} justifyContent='flex-end' />
          </Box>
        </Box>
        <Box className='content-inner-wrap'>
          {/* Table */}
          <CustomTableContainer className='table-container'>
            <Table className='custom-table' aria-label='table' stickyHeader>
              {/* table Head */}
              <TableHead className='thead'>
                <TableRow className='tr'>
                  <TableCell key={'logoUrl'} className='th' align={'left'}
                             sx={{width: '12%'}}>
                    <CheckBox
                        label=''
                        value='select-all'
                        checked={allChecked}
                        inputProps={{
                          'aria-label': 'select all',
                        }}
                        onChange={onClickCheckAll}
                    />
                    <span className='chk-th'>Logo</span>
                  </TableCell>
                  <TableCell key={'providerId'} className='th' align={'left'} sx={{ width: '10%' }}>
                    <TableSortLabel
                        active={orderBy === 'providerId'}
                        direction={orderBy === 'providerId' ? order : 'desc'}
                        onClick={() => handleRequestSort('providerId')}
                    >
                      CP Code
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={'providerName'} className='th' align={'left'} sx={{ width: '12%' }}>
                    <TableSortLabel
                        active={orderBy === 'providerName'}
                        direction={orderBy === 'providerName' ? order : 'desc'}
                        onClick={() => handleRequestSort('providerName')}
                    >
                      CP Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={'parentProviderId'} className='th' align={'left'} sx={{ width: '12%' }}>
                    <TableSortLabel
                        active={orderBy === 'parentProviderId'}
                        direction={orderBy === 'parentProviderId' ? order : 'desc'}
                        onClick={() => handleRequestSort('parentProviderId')}
                    >
                      CP Parents Code
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={'parentProviderName'} className='th' align={'left'} sx={{ width: '12%' }}>
                    <TableSortLabel
                        active={orderBy === 'parentProviderName'}
                        direction={orderBy === 'parentProviderName' ? order : 'desc'}
                        onClick={() => handleRequestSort('parentProviderName')}
                    >
                      CP Parents Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={'lgPlayerFlag'} className='th' align={'left'} sx={{ width: '10%' }}>
                    <TableSortLabel
                        active={orderBy === 'lgPlayerFlag'}
                        direction={orderBy === 'lgPlayerFlag' ? order : 'desc'}
                        onClick={() => handleRequestSort('lgPlayerFlag')}
                    >
                      Use LG Player
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={'interactiveUrl'} className='th' align={'left'} sx={{ width: 'auto' }}>
                    Interactive URL
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* table Body */}
              <TableBody className='tbody'>
                {paginatedData.map((td, index) => (
                  <TableRow className='tr' key={td.id || index}>
                    <TableCell key={'logoUrl'} className='td' align={'left'}>
                      <Box display='flex'>
                        <CheckBox label='' value={td.providerId} onChange={onClickCheckBox}
                                  checked={getIfChecked(td.providerId)} />
                        <CustomImage
                          src={td['logoUrl'] === '' ? null : td['logoUrl']}
                          fit={false}
                          alt=''
                          wrapWidth='96px'
                          wrapHeight='64px'
                          rounded
                        />
                      </Box>
                    </TableCell>
                    <TableCell key={'providerId'} className='td' align={'left'}>
                      {td.providerId}
                    </TableCell>
                    <TableCell key={'providerName'} className='td' align={'left'}>
                      <TextLink text={td['providerName']} onClick={() => openEditCp(td.providerId)} style={{ cursor: 'pointer' }} />
                    </TableCell>
                    <TableCell key={'parentProviderId'} className='td' align={'left'}>
                      {td.parentProviderId}
                    </TableCell>
                    <TableCell key={'parentProviderName'} className='td' align={'left'}>
                      {td.parentProviderName}
                    </TableCell>
                    <TableCell key={'lgPlayerFlag'} className='td' align={'left'}>
                      {td.lgPlayerFlag ? 'Y' : 'N'}
                    </TableCell>
                    <TableCell key={'interactiveUrl'} className='td' align={'left'}>
                      {td.interactiveUrl}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
          {/* // Table */}
        </Box>

        {/* Pagination */}
        <Pagination count={Math.ceil(displayCpInfo.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
      </div>
      {isOpenEditCp && (
          <PopEditCPName closeEditCp={() => setIsOpenEditCp(false)} providerInfo={providerToEdit} afterSave={getCp} />
      )}
      {isOpenAddCp && (
          <PopAddCPName closeAddCp={() => setIsOpenAddCp(false)} afterSave={getCp} />
      )}

      <Dialog
          open={isAlertOpen} // boolean 값으로 설정
          handleClose={handleAlertClose}
          closeX={false}
          size='small' // large, medium, small
          title={alertTitle} // Popup Title
          contentTitle={false} // Content Title
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
      />
      <Dialog
          open={isConfirmOpen} // boolean 값으로 설정
          handleClose={handleConfirmClose}
          closeX={false}
          size='small'
          title='Confirm'
          contentTitle={false}
          bottomDivider={false}
          contentText={confirmMessage}
          buttons={confirmDialogButtons}
      />
    </Box>
  );
}

export default CodeManagementCP;
