export const CategoryData = [
  { id: 'category-0', value: 'All', option: 'All', type: 'text' },
  { id: 'category-1', value: 'Update', option: 'Update', type: 'text' },
  { id: 'category-2', value: 'Manual', option: 'Manual', type: 'text' },
  { id: 'category-3', value: 'Service', option: 'Service', type: 'text' },
  { id: 'category-4', value: 'work', option: 'work', type: 'text' },
];

export const ChannelData = [
  { id: 'channel-0', value: 'All', option: 'All', type: 'text' },
  { id: 'channel-1', value: 'CMP', option: 'CMP', type: 'text' },
  { id: 'channel-2', value: 'Partner', option: 'Partner', type: 'text' },
];

export const NotificationSearchOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'Title', value: 'Title', option: 'Title', type: 'text' },
  { id: 'Display', value: 'Display', option: 'Display', type: 'text' },
];

export const CategoryData2 = [
  { id: 'category-0', value: 'All', option: 'All', type: 'text' },
  { id: 'category-1', value: 'Update', option: 'Update', type: 'text' },
  { id: 'category-2', value: 'Manual', option: 'Manual', type: 'text' },
  { id: 'category-3', value: 'Service', option: 'Service', type: 'text' },
  { id: 'category-4', value: 'work', option: 'work', type: 'text' },
];

export const ChannelData2 = [
  { id: 'channel-0', value: 'All', option: 'All', type: 'text' },
  { id: 'channel-1', value: 'CMP', option: 'CMP', type: 'text' },
  { id: 'channel-2', value: 'Partner', option: 'Partner', type: 'text' },
];

export const selectOption2 = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Title', value: 'Title', option: 'Title', type: 'text' },
  { id: 'Display', value: 'Display', option: 'Display', type: 'text' },
];

export const SupportNotificationTh = [
  {
    id: 'category',
    label: 'Category',
    align: 'left',
    width: '10%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'channel',
    label: 'Channel',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'title',
    label: 'Title',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'displayFlag',
    label: 'Display',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'fixFlag',
    label: 'Fix',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'fileList',
    label: 'File',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'crtDate',
    label: 'Registration Date',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
];

export const SupportNotificationTd = [
  {
    id: 'SupportNotificationTd-01',
    data01: 'Update',
    data02: 'Partner',
    data03: 'Update_Notice',
    data04: 'On',
    data05: 'N',
    data06: true,
    data07: '2024-04-16 15:24:48',
  },
  {
    id: 'SupportNotificationTd-02',
    data01: 'Update',
    data02: 'Partner',
    data03: 'Update_Notice',
    data04: 'On',
    data05: 'N',
    data06: false,
    data07: '2024-04-16 15:24:48',
  },
  {
    id: 'SupportNotificationTd-03',
    data01: 'Update',
    data02: 'Partner',
    data03: 'Update_Notice',
    data04: 'On',
    data05: 'N',
    data06: true,
    data07: '2024-04-16 15:24:48',
  },
];

export const SupportFaqTh = [
  {
    id: 'order',
    label: 'Order',
    align: 'left',
    width: '10%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'category',
    label: 'Category',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channel',
    label: 'Channel',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'question',
    label: 'Title',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'displayFlag',
    label: 'Display',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'fileList',
    label: 'File',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'crtDate',
    label: 'Registration Date',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
];

export const SupportFaqTd = [
  {
    id: 'faq-01',
    data01: '1',
    data02: 'CMP',
    data03: 'Partner',
    data04: 'CMP',
    data05: 'On',
    data06: true,
    data07: '2024-04-16 23:37:29',
  },
  {
    id: 'faq-02',
    data01: '2',
    data02: 'CMP',
    data03: 'Partner',
    data04: 'CMP',
    data05: 'On',
    data06: true,
    data07: '2024-04-16 23:37:29',
  },
  {
    id: 'faq-03',
    data01: '3',
    data02: 'CMP',
    data03: 'Partner',
    data04: 'CMP',
    data05: 'On',
    data06: false,
    data07: '2024-04-16 23:37:29',
  },
];
