import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// Styled
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme, width }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: '#525252',
      padding: '12px',
      borderRadius: '4px',
      boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.25)',
      fontSize: 12,
      maxWidth: width || '300px', // default: 300px
    },

    '.info-list-box': {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',

      '.info': {
        display: 'flex',
        marginBottom: '0',

        '.label': {
          width: 'fit-content',
          fontSize: '12px',
          fontWeight: '400',
          color: '#909090',
          lineHeight: 'normal',
        },

        '.info-field': {
          fontSize: '12px',
          fontWeight: '400',
          color: '#525252',
          lineHeight: 'normal',
        },
      },
    },
  })
);

// Tooltip Component
function BasicTooltip({ title, children, placement, width }) {
  return (
    <>
      <LightTooltip title={title} placement={placement} enterDelay={300} width={width}>
        <span>{children}</span>
      </LightTooltip>
    </>
  );
}
export default BasicTooltip;
