import React, { useState, useEffect } from 'react';
import Button from 'components/component/BasicButton';
import Pagination from 'components/component/BasicPagination';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'utils/Valid';

import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';

// data
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
import { HistoryTableHeader } from 'pages/Configuration/Policy/data/PolicyData';
import { getRequest } from 'utils/Request';
import SortLabel from 'components/component/BasicTableSortLabel';

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});
// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '300px',
  overflowY: 'auto',
}));

const DialogPop = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1000px',
    maxWidth: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));

const HeaderArea = styled('div')`
  padding: 10px 0;
  display: flex;
  gap: 16px;
`;
const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;

// title={popupType.title}
// contentTitle={popupType.content}
// size={'small'}
// closeX={true}
// open={true}
// ************************** 메인 컨텐츠 ***********************************
function PolicyHistory({ name, isDialogOpen, history, closeHandler }) {
  const [isOpen, setIsOpen] = useState(true);
  const [dialogTitle, setDialogTitle] = useState('');

  const [historyList, setHistoryList] = useState([]);
  const [displayHistoryList, setDisplayHistoryList] = useState([]);
  const [isPageNavigate, setIsPageNavigate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    setIsOpen(isDialogOpen);
    // eslint-disable-next-line
  }, [isDialogOpen]);

  useEffect(() => {
    if (!isEmpty(history)) {
      setDialogTitle(history.policyType === 'terms' ? 'History - Terms Of Use' : 'History - Privacy Policy');
      getPolicyHistory(history.policyId);
    }
    // eslint-disable-next-line
  }, [history]);

  const onClose = () => {
    if (closeHandler) {
      setIsOpen(false);
      closeHandler();
    }
  };

  //
  const getPolicyHistory = async (policyId) => {
    const body = await getRequest(`/policy/history?policyId=${policyId}`, null);
    if (body && body.result === 'SUCCESS') {
      setHistoryList(body.data);
      setPageNavigate(body.data, currentPage, pageSize);
    } else {
      // TODO
      // 메세지 처리
    }
  };

  const setPageNavigate = (list, current, size) => {
    if (!isEmpty(list)) {
      setIsPageNavigate(true);
      const startIndex = (current - 1) * size;
      const endIndex = startIndex + size;
      const displayList = list.slice(startIndex, endIndex);
      setDisplayHistoryList([...displayList]);
    } else {
      setIsPageNavigate(false);
      setDisplayHistoryList([]);
    }
  };

  const paginationHandler = (paging) => {
    if (paging) {
      if (paging.type === 'page') {
        setCurrentPage(paging.value);
        setPageNavigate(historyList, paging.value, pageSize);
      } else if (paging.type === 'size') {
        setPageSize(paging.value);
        setCurrentPage(1);
        setPageNavigate(historyList, 1, paging.value);
      }
    }
  };

  // sorting
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const dataSorting = (col, desc) => {
    setDisplayHistoryList(sortRows([...historyList], col, desc));
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  return (
    <div className='component-view'>
      <DialogPop open={isOpen} size='medium' fullWidth className='pop-channel-count'>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}>
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>{dialogTitle}</DialogTitle>
        </HeaderArea>
        <DialogContent className='pop-content'>
          <Box className='content-inner-wrap' sx={{ position: 'relative' }}>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table ' aria-label='table' stickyHeader>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    {HistoryTableHeader.map((column) => (
                      <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                        <SortLabel
                          labelText={column.label}
                          labelId={column.labelId}
                          sortable={column.sortable}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          order={order}
                          setOrder={setOrder}
                          sorting={dataSorting}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* table Body */}
                <TableBody className='tbody'>
                  {displayHistoryList.map((row, index) => (
                    <TableRow className='tr' key={`history-row-${row.policyIdx}${index}`}>
                      {HistoryTableHeader.map((header, index) => (
                        <TableCell key={`history-cell-${header.id}${index}`} className='td' align={header.align}>
                          {row[header.labelId]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
            {isPageNavigate && <Pagination count={Math.ceil(historyList.length / pageSize)} id='reservation-pagination' selected={pageSize} onChangeHandle={paginationHandler} page={currentPage} />}
          </Box>
          <Box textAlign={'center'} mt={5}>
            <Button color='tertiary' type='outline' size='large' label='Close' isIcon={false} onClick={onClose} />
          </Box>
        </DialogContent>
      </DialogPop>
    </div>
  );
}

export default PolicyHistory;
