import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import Pagination from 'components/component/BasicPagination';
import { deleteAnTestServiceSegment, getAnTestServiceSegment } from '../ANTestService/api/restApi';
import {
  Box,
  Link,
  ListItem,
  List,
  Divider,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

import { anTestSegmentTh, anServiceSegmentTh } from 'pages/Organization/ANTestService/data/ANTestServicedata';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import BasicSnackbar from 'components/component/BasicSnackbar';
import BasicDialog from 'components/component/BasicDialog';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

// 메인 컨텐츠
function ANTestServiceSegment(props) {
  const { message, type } = props;
  const { selectedRow, mainFilteringData } = message;
  const SegmentTh = type === 'antest' ? anTestSegmentTh : anServiceSegmentTh;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: '',
    content: '',
    button: [],
  });
  const [showSnackBar, setShowSnackBar] = useState({
    open: false,
    message: "",
    severity: "",
    action: null,
  });

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: type === 'antest' ? 'AN Test' : 'AN Service',
    normalMenu: ['Segment'],
  };
  const [anTestServiceSegmentList, setAnTestServiceSegmentList] = useState({
    data: [],
    total: 0,
  });

  const [params, setParams] = useState({
    order_type: "asc",
    order: "areaOrder",
    page: 1,
    size: 10,
  });

  const segmentButtons = [
    { btnText: 'ORGANIZE', disabled: false },
    { btnText: 'DELETE', disabled: selectedRow.status === 'done' ? true : false },
    { btnText: 'RESULT', disabled: selectedRow.status === 'ready' ? true : false }
  ];

  useEffect(() => {
    getAnTestServiceSegmentData();
    // eslint-disable-next-line
  }, [selectedRow.idx]);


  const getAnTestServiceSegmentData = async () => {
    const res = await getAnTestServiceSegment(selectedRow.idx, type);
    setAnTestServiceSegmentList(res);
  };


  const handleRequestSort = (property) => {
    const isAsc = params.order === property && params.order_type === "asc";
    setParams((prevState) => ({
      ...prevState,
      order: property,
      order_type: isAsc ? "desc" : "asc",
    }));
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  let sortedTdRows = sortRows(
    [...anTestServiceSegmentList.data],
    params.order,
    params.order_type
  );
  const count = Math.ceil(anTestServiceSegmentList.total / params.size);
  const total = anTestServiceSegmentList.total || 0;
  const pageIndex = params.page > 1 ? (params?.page - 1) * params?.size : 0;
  const sizeIndex = params?.size || 10;
  sortedTdRows = sortedTdRows.splice(pageIndex, sizeIndex);

  const handlePaginationChange = ({ type, value }) => {
    if (type === "page") {
      setParams((prevState) => ({
        ...prevState,
        page: value,
      }));
    } else if (type === "size") {
      setParams((prevState) => ({
        ...prevState,
        page: 1,
        size: parseInt(value),
      }));
    }
  };

  const infos = [
    {
      index: 1,
      label: '#Device Type',
      value: selectedRow.deviceType,
      divide: true,
    },
    {
      index: 2,
      label: '#Country',
      value: selectedRow.countryCode,
      divide: true,
    },
    {
      index: 3,
      label: '#Tab',
      value: `${selectedRow.tabCode} ${selectedRow.tabName}`,
      divide: true,
    },
    {
      index: 4,
      label: '#Category',
      value: `${selectedRow.categoryCode} ${selectedRow.categoryName}`,
      divide: true,
    },
    {
      index: 5,
      label: '#Date',
      value: type === 'antest' ? selectedRow.startTime : selectedRow.startTimeLocal + '~' + type === 'antest' ? selectedRow.forcedEndTime : selectedRow.forcedEndTimeLocal,
      divide: true,
    },
    {
      index: 6,
      label: '#Status',
      value: dayjs(new Date(type === 'antest' ? selectedRow.forcedEndTime : selectedRow.forcedEndTimeLocal)) <= dayjs(new Date()) ? "done"
        : (dayjs(new Date()) < dayjs(new Date(type === 'antest' ? selectedRow.startTime : selectedRow.startTimeLocal))) ? "ready" : "testing",
      divide: false,
    },
  ];

  const onSegmentClick = () => {
    navigate(type === 'antest' ? '/ANTest' : '/ANService', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestServiceSegmentOrganize',
        message: {
          selectedRow: selectedRow,
          mainFilteringData: mainFilteringData
        }
      }
    });
  }

  const onOrganizeClick = (row) => {
    navigate(type === 'antest' ? '/ANTest' : '/ANService', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestServiceSegmentOrganizeAddGroup',
        message: {
          selectedTestService: selectedRow,  // antest row
          segmentIdx: row.idx,
          selectedSegment: row,
          mainFilteringData: mainFilteringData
        }
      }
    });
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  const onDeleteClick = (idx) => {
    setOpenDialog(true);
    setDialogContent({
      title: 'Delete Segment',
      content: <div>Are you sure to delete this segment?</div>,
      button: [
        {
          color: 'tertiary',
          type: 'outline',
          size: 'medium',
          label: 'Cancel',
          isIcon: false,
          Icon: null,
          iconPos: undefined,
          minWidth: 85,
          disabled: false,
          onClick: handleDialogClose,
        },
        {
          color: 'primary',
          type: 'box',
          size: 'medium',
          label: 'OK',
          isIcon: false,
          Icon: null,
          iconPos: undefined,
          minWidth: 103,
          disabled: false,
          onClick: () => handleDeleteSegment(true, idx),
        }
      ],
    });
  }

  const handleDeleteSegment = async (calledFromDialog, idx) => {
    if (calledFromDialog) {
      handleDialogClose();
    }
    try {
      const res = await deleteAnTestServiceSegment(selectedRow.idx, idx, type);
      setShowSnackBar({
        open: true,
        message: res.message || 'Segment deleted successfully!',
        severity: res.severity || 'success',
        action: null,
      });
    } catch (error) {
      setShowSnackBar({
        open: true,
        message: 'Error deleting segment. Please try again.',
        severity: 'error',
        action: null,
      });
    }
    handleDialogClose();
    getAnTestServiceSegmentData();
  };

  const handleSnackbarClose = () => {
    setShowSnackBar({ open: false, message: "", severity: "" });
  };

  const onResultClick = (row) => {
    navigate(type === 'antest' ? '/ANTest' : '/ANService', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestServiceResult',
        message: {
          mainFilteringData: mainFilteringData,
          selectedTestService: selectedRow,
          selectedSegment: row,
          tComponent: 'ANTestServiceSegment'
        }
      }
    });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='#'
                onClick={(e) => {
                  e.preventDefault();
                  navigate(type === 'antest' ? '/ANTest' : '/ANService', {
                    replace: true, state: {
                      move: true,
                      targetComponent: 'ANTestService',
                      message: {
                        selectedRow: selectedRow,
                        mainFilteringData: mainFilteringData
                      }
                    }
                  });
                }}
              >
                Segment
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          <Box className='white-box white-box-wrap single-box'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <Box className='box-title-area' display='flex' alignItems='center'>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        {total}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <Button color='tertiary' type='box' size='small' label='Segment' isIcon={false} autosize
                    onClick={onSegmentClick} disabled={selectedRow.status !== 'ready'} />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {SegmentTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={params.order === column.id}
                                  direction={params.order === column.id ? params.order_type : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={params.order === column.id}
                                    direction={params.order === column.id ? params.order_type : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTdRows.length === 0 ? (
                        <NoRecords>
                          No records to display
                        </NoRecords>
                      ) : (
                        sortedTdRows.map((td, index) => (
                          <TableRow className='tr' key={td.id || index}>
                            {SegmentTh.map((column, colIndex) => (
                              <TableCell key={column.id} className='td' align={column.align}>
                                {colIndex === 1 ? (
                                  <div>
                                    {td['validity'] === 'valid' ? ( // result
                                      <span className='greenCircle'></span>
                                    ) : td['validity'] === 'invalid' ? ( // if update result
                                      <span className='greenCircle edit'></span>
                                    ) : // default
                                      ''
                                    }
                                  </div>
                                ) : colIndex === 3 ? (
                                  `${td[column.id]}%`
                                ) : colIndex === 5 ? (
                                  `${td.defaultContent === 'Y' ? td[column.id] : '-'}`
                                ) : colIndex === 6 ? (
                                  <Box display='flex' gap={1} flexWrap={'wrap'}>
                                    {segmentButtons.map((button, index) => (
                                      <Button
                                        key={index}
                                        color='tertiary'
                                        type='outline'
                                        size='small'
                                        label={button.btnText}
                                        disabled={button.disabled}
                                        isIcon={false}
                                        autosize
                                        onClick={() => {
                                          if (button.btnText === 'ORGANIZE') onOrganizeClick(td);
                                          else if (button.btnText === 'DELETE') onDeleteClick(td.idx);
                                          else if (button.btnText === 'RESULT') onResultClick(td);
                                        }}
                                      />
                                    ))}
                                  </Box>
                                ) : (
                                  td[column.id]
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>

              {/* Pagination */}
              <Pagination id='pagination-01' count={count} selected={params.size} onChangeHandle={handlePaginationChange} page={params.page} />
            </div>
            <BasicDialog
              title={dialogContent.title}
              contentTitle={dialogContent.content}
              size={'small'}
              closeX={true}
              open={openDialog}
              handleClose={handleDialogClose}
              buttons={dialogContent.button}
            />
            <div>
              <BasicSnackbar onClose={handleSnackbarClose} severity={showSnackBar.severity} message={showSnackBar.message} open={showSnackBar.open} duration={1000} action={null} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ANTestServiceSegment;
