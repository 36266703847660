export const valueFormatter = (item) => `${item.value}%`;

export const defaultColor = [
  'rgba(255, 99, 132, 0.8)',
  'rgba(54, 162, 235, 0.8)',
  'rgba(255, 206, 86, 0.8)',
  'rgba(75, 192, 192, 0.8)',
  'rgba(153, 102, 255, 0.8)',
  'rgba(201, 203, 207, 0.8)',
  'rgba(189, 163, 33, 0.8)',
  'rgba(255, 159, 64, 0.8)',
  'rgba(255, 99, 132, 0.8)',
  'rgba(54, 162, 235, 0.8)',
];

export const channelTableTitle = [
  {
    id: 'name',
    align: 'left',
    label: 'Channel Name',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'country',
    align: 'left',
    label: 'Country',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'platform',
    align: 'left',
    label: 'Platform',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'deviceType',
    align: 'left',
    label: 'Device type',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'uniqueDevice',
    align: 'left',
    label: 'Unique Device',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'play',
    align: 'left',
    label: 'Play',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'playPerUd',
    align: 'left',
    label: 'Play per Ud',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'totalDuration',
    align: 'left',
    label: 'Total Duration (hr)',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'totalDurationPerUd',
    align: 'left',
    label: 'Total Duration per Ud',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
];

export const programTableTitle = [
  {
    id: 'channelId',
    align: 'left',
    label: 'Channel ID',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelName',
    align: 'left',
    label: 'Channel Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'programName',
    align: 'left',
    label: 'Program Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'country',
    align: 'left',
    label: 'Country',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'platform',
    align: 'left',
    label: 'Platform',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'deviceType',
    align: 'left',
    label: 'Device type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'uniqueDevice',
    align: 'left',
    label: 'Unique Device',
    width: '7%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'play',
    align: 'left',
    label: 'Play',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'playPerUd',
    align: 'left',
    label: 'Play per Ud',
    width: '7%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'totalDuration',
    align: 'left',
    label: 'Total Duration (hr)',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'totalDurationPerUd',
    align: 'left',
    label: 'Total Duration per Ud',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
];

export const vodTableTitle = [
  {
    id: 'type',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodName',
    align: 'left',
    label: 'VOD Name',
    width: '26%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'country',
    align: 'left',
    label: 'Country',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'platform',
    align: 'left',
    label: 'Platform',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'deviceType',
    align: 'left',
    label: 'Device type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'uniqueDevice',
    align: 'left',
    label: 'Unique Device',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'play',
    align: 'left',
    label: 'Play',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'playPerUd',
    align: 'left',
    label: 'Play per Ud',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'totalDuration',
    align: 'left',
    label: 'Total Duration (hr)',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'totalDurationPerUd',
    align: 'left',
    label: 'Total Duration per Ud',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
];

