import React, {useEffect, useState} from 'react';
import Dialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import { Grid, Box, Typography } from '@mui/material';

// data
import {
  CountryTimeOption,
  RICOption,
} from 'pages/Configuration/CodeManagement/data/CodeSelectData';
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import SelectCheckBox from 'components/component/BasicSelectCheckBox';

// 메인 컨텐츠
function PopHandleCountry({ onClose, afterSave, popupType, countryToEdit }) {

  const handleClose = () => {
    onClose();
  };

  const [countryCodeInput, setCountryCodeInput] = useState('');
  const [countryNameInput, setCountryNameInput] = useState('');
  const [selectedRic, setSelectedRic] = useState('AIC');
  const [selectedCountryTime, setSelectedCountryTime] = useState('+0:00');
  const [selectedPrimaryLanguage, setSelectedPrimaryLanguage] = useState('en');
  const [selectedSecondaryLanguageList, setSelectedSecondaryLanguageList] = useState([]);
  const [paramLangCode, setParamLangCode] = useState([]);
  const [paramLangCodeCheckBox, setParamLangCodeCheckBox] = useState([]);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const getAllLangCode = () => {
    fetch(`${BASE_URL}/code-management/all-lang-code`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setParamLangCode(body.data.map(lang => ({id: lang, value: lang, option: lang, type: 'text'})));
        setParamLangCodeCheckBox(body.data.map(lang => ({id: lang, value: lang, option: lang, type: 'checkbox'})));
      }
    })
  }

  useEffect(() => {
    getAllLangCode();
  }, []);

  const handlesSelectedSecondaryLanguageList = (lang) => {
    if (lang && lang.length > 0) {
      setSelectedSecondaryLanguageList([...lang]);
    }
  }

  useEffect(() => {
    if (popupType === 'Add') {
      setCountryCodeInput('');
      setCountryNameInput('');
      setSelectedCountryTime('+0:00');
      setSelectedRic('AIC');
      setSelectedPrimaryLanguage('en');
      setSelectedSecondaryLanguageList([]);
      setParamLangCodeCheckBox(paramLangCodeCheckBox.map(langCode => ({...langCode, defaultChecked: false})));
    } else {
      //edit
      if (countryToEdit) {
        setCountryCodeInput(countryToEdit.countryCode);
        setCountryNameInput(countryToEdit.countryName);
        const time = parseInt(countryToEdit.countryTime);
        setSelectedCountryTime(`${time >= 0 ? '+' : ''}${time}:00`);
        setSelectedRic(countryToEdit.ric);
        setSelectedPrimaryLanguage(countryToEdit.primaryLanguage);
        setSelectedSecondaryLanguageList(countryToEdit.secondaryLanguageList);
        setParamLangCodeCheckBox(paramLangCodeCheckBox.map(langCode => {
          if (countryToEdit.secondaryLanguageList.includes(langCode.value)) {
            return {...langCode, defaultChecked: true};
          } else {
            return langCode;
          }
        }))
      }
    }
    // eslint-disable-next-line
  }, [popupType, countryToEdit]);

  const saveCountry = () => {
    if (countryCodeInput === '') {
      setAlertTitle('Warning')
      setAlertMessage('Country Code is required.');
      setAlertOpen(true);
    } else if (countryNameInput === '') {
      setAlertTitle('Warning')
      setAlertMessage('Country Name is required.');
      setAlertOpen(true);
    } else {
      const country = {
        countryCode: countryCodeInput,
        countryName: countryNameInput,
        countryTime: parseInt(selectedCountryTime.split(':')[0]),
        ric: selectedRic,
        primaryLanguage: selectedPrimaryLanguage,
        secondaryLanguageList: selectedSecondaryLanguageList
      };

      console.log(country)

      // Edit 의 경우 기존의 country code 를 path variable 로 넘김
      const endPoint = `${BASE_URL}/code-management/country`
          + (popupType === 'Add' ? '' : `/${countryToEdit.countryCode}`);

      fetch(endPoint, {
        method: popupType === 'Add' ? 'POST' : 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(country)
      })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          setAlertTitle('Success')
          setAlertMessage('Country has been saved successfully.');
          setAlertOpen(true);
          onClose();
          afterSave();
        } else {
          setAlertTitle('Error')
          setAlertMessage('Country Save Failed');
          setAlertOpen(true);
        }
      })
    }
  }

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      disabled: false,
      onClick: saveCountry
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div>
      <Dialog
        open={true}
        closeX={true}
        title={popupType === 'Add' ? 'Add Country' : 'Edit Country'}
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className=''
        contentTextProps={
          <Grid container xs={12} item>
            {/* Country Code */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='outlined'
                  size='large'
                  placeholder='ex) US'
                  label='Country Code'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={countryCodeInput}
                  onChange={(e) => setCountryCodeInput(e.target.value)}
                />
              </Box>
            </Grid>

            {/* Country Name */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='outlined'
                  size='large'
                  placeholder='ex) United States of America'
                  label='Country Name'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={countryNameInput}
                  onChange={(e) => setCountryNameInput(e.target.value)}
                />
              </Box>
            </Grid>

            {/* Country Time */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='country-Time'
                  size='large'
                  shrink={true}
                  label='Country Time'
                  required={true}
                  selectOptions={CountryTimeOption}
                  disabled={false}
                  placeholder='Select'
                  selected={selectedCountryTime}
                  onChange={setSelectedCountryTime}
                />
                <Typography className='input-sub-text'>UTC 기준 선택해주세요.</Typography>
              </Box>
            </Grid>

            {/* RIC */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='country-RIC'
                  size='large'
                  shrink={true}
                  label='RIC'
                  required={true}
                  selectOptions={RICOption}
                  disabled={false}
                  placeholder='Select'
                  selected={selectedRic}
                  onChange={setSelectedRic}
                />
              </Box>
            </Grid>

            {/* Primary language */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='country-Primary'
                  size='large'
                  shrink={true}
                  label='Primary language'
                  required={true}
                  selectOptions={paramLangCode}
                  disabled={false}
                  placeholder='Select'
                  selected={selectedPrimaryLanguage}
                  onChange={setSelectedPrimaryLanguage}
                />
              </Box>
            </Grid>

            {/* Secondary language */}
            <Grid item xs={12}>
              <Box component='div' className='field-box'>
                <SelectCheckBox
                  id='country-Secondary'
                  size='large'
                  shrink={true}
                  label='Secondary language'
                  required={true}
                  selectOptions={paramLangCodeCheckBox}
                  disabled={false}
                  multiple={true}
                  placeholder='Select'
                  onChange={handlesSelectedSecondaryLanguageList}
                />
              </Box>
            </Grid>
          </Grid>
        }
        buttons={dialogButtons}
      />
      <Dialog
          open={isAlertOpen}
          handleClose={handleAlertClose}
          closeX={false}
          size='small'
          title={alertTitle}
          contentTitle={false}
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
      />
    </div>
  );
}

export default PopHandleCountry;
