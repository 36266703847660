import React, {useEffect, useState} from 'react';
import Pagination from 'components/component/BasicPagination';
import {Box, Dialog} from '@mui/material';
import ExtraFilter from './component/ExtraFilter';
import {BASE_URL} from 'constants/index';
import {getHeaders} from 'utils/Header';
import {RefreshIcon} from 'components/component/BasicIcon';
import {useNavigate} from "react-router-dom";
import SelectBox from "components/component/BasicSelectBox";
import TextField from "components/component/BasicTextField";
import CustomButtonGroup from "components/component/BasicButtonGroup";
import {MetaSeriesTh} from "./data/MetaData";
import SeriesTable from "./component/SeriesTable";
import Loading from 'components/component/Loading';

function PartnerMetadataSeries(props) {
  const navigate = useNavigate();
  const {message, tabValue, countryByProvider, currentUser} = props;

  // filter values
  const [filterProviderList, setFilterProviderList] = useState([]);
  const [filterCountryList, setFilterCountryList] = useState([]);

  // selected filter values
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedSeriesId, setSelectedSeriesId] = useState('');
  const [selectedSeriesTitle, setSelectedSeriesTitle] = useState('');
  const [selectedSeasonId, setSelectedSeasonId] = useState('');
  const [selectedSeasonTitle, setSelectedSeasonTitle] = useState('');

  // original data
  const [originalDataList, setOriginalDataList] = useState([]);

  // display data
  const [dataList, setDataList] = useState([]);

  // 화면에 보여 줄 데이터가 없는 경우
  const [isEmptyData, setEmptyData] = useState(false);

  // 검색에서 입력한 값
  const [searchTerm, setSearchTerm] = useState('');

  // 로딩바를 보여 줄 때 사용
  const [isDisplayData, setDisplayData] = useState(false);

  // popup
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // paging
  const [paginatedData, setPaginatedData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // 타이밍 이슈로 인해서 예외 처리 추가
  const [isAfterFirstRequest, setAfterFirstRequest] = useState(false);

  useEffect(() => {
    if (currentUser && countryByProvider?.length > 0) {
      initProviderValue();
    }
    // eslint-disable-next-line
  }, [currentUser, countryByProvider]);

  useEffect(() => {
    if (selectedProvider && countryByProvider?.length > 0) {
      const tempCountry = countryByProvider.filter(item => item.providerName === selectedProvider).map(item => item.country).join(',').split(',');
      if (message?.filters) {
        setSelectedCountry(message.filters.selectedCountry);
      } else {
        setSelectedCountry(tempCountry[0]);
      }

      const tempCountryOption = [
        ...tempCountry.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text'
        })),
      ];

      setFilterCountryList([...tempCountryOption]);
    }
    // eslint-disable-next-line
  }, [selectedProvider, countryByProvider]);

  useEffect(() => {
    if (message) {
      if (message.filters) {
        setSelectedCountry(message.filters.selectedCountry);
        setSelectedProvider(message.filters.selectedProvider);
      }
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (filterCountryList.length > 0 && filterProviderList.length > 0 && !isAfterFirstRequest) {
      searchMetadata();
      setAfterFirstRequest(true);
    }
    // eslint-disable-next-line
  }, [filterCountryList, filterProviderList]);

  useEffect(() => {
    if (searchTerm) {
      const excludeKeyList = ['logoUrl', 'representativeImageList'];

      const filteredData = originalDataList.filter((item) => {
        return Object.entries(item)
          .filter(([key, value]) => !excludeKeyList.includes(key))
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchTerm));
      });

      setDataList([...filteredData]);
      if (filteredData.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    } else {
      setDataList([...originalDataList]);
      if (originalDataList.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    }
  }, [searchTerm, originalDataList]);

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = dataList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setDisplayData(true);
    }
  }, [dataList, page, itemsPerPage]);

  useEffect(() => {
    if (isEmptyData) {
      setPaginatedData([]);
    }
  }, [isEmptyData]);

  const searchMetadata = () => {
    setDisplayData(false);
    setPage(1);
    setDataList([]);
    setOriginalDataList([]);
    let param = `countryCode=${selectedCountry}&providerName=${selectedProvider}&seriesId=${selectedSeriesId}&seriesTitle=${selectedSeriesTitle}&seasonId=${selectedSeasonId}&seasonTitle=${selectedSeasonTitle}`;
    let url = `${BASE_URL}/metadata/series?${param.toString()}`;
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setOriginalDataList(body.data);

        if (body.data && body.data.length === 0) {
          setEmptyData(true);
          setDisplayData(true);
        }
      } else {
        throw new Error(`[Metadata] series request error`);
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const initProviderValue = () => {
    let tempProviderOption = [];
    if (currentUser && (currentUser.role === 'ROLE_CP' || currentUser.role === 'ROLE_STUDIO')) {
      setSelectedProvider(currentUser.provider.providerName);

      tempProviderOption = [
        {
          id: currentUser.provider.providerName,
          value: currentUser.provider.providerName,
          option: currentUser.provider.providerName,
          type: 'text',
        },
      ];
    } else {
      const tempProviderName = countryByProvider.map((item) => item.providerName);
      if (message?.filters) {
        setSelectedProvider(message.filters.selectedProvider);
      } else {
        setSelectedProvider(tempProviderName[0]);
      }

      tempProviderOption = [
        ...tempProviderName.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text',
        })),
      ];
    }

    setFilterProviderList([...tempProviderOption]);
  };

  const handleReset = () => {
    if (message?.filters) {
      message.filters = null;
    }

    initProviderValue();
    setSelectedCountry('');
    setSelectedSeriesId('');
    setSelectedSeasonTitle('');
    setSelectedSeasonId('');
    setSelectedSeriesTitle('');
  };

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      autosize: true,
      onClick: handleReset,
    },
    {
      minWidth: '120px',
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      onClick: searchMetadata,
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...dataList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDataList([...sortedData]);
  };

  const handleSelectedCountry = (country) => {
    if (message?.filters) {
      message.filters = null;
    }

    setSelectedCountry(country);
  };

  const handleSelectedProvider = (provider) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedProvider(provider);
  };

  const handleSelectedSeriesId = (value) => {
    setSelectedSeriesId(value);
  };

  const handleSelectedSeriesTitle = (value) => {
    setSelectedSeriesTitle(value);
  };

  const handleSelectedSeasonId = (value) => {
    setSelectedSeasonId(value);
  };

  const handleSelectedSeasonTitle = (value) => {
    setSelectedSeasonTitle(value);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleAction = (contentId) => {
    navigate('/Metadata',
        {
          replace: true,
          state: {
            move: true,
            targetComponent: 'MetadataSeriesDetail',
            message: {
              contentId: contentId,
              countryCode: selectedCountry,
              tabValue: tabValue,
              filters: {
                selectedCountry: selectedCountry,
                selectedProvider: selectedProvider,
              }
            }
          }
        });
  };

  return (
      <>
      <div className='tab-panel'>
        <div className='panel-wrap'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <div className='form-area left'>
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <SelectBox
                        id='series-filter-cp-name'
                        fullWidth
                        size='large'
                        width='100%'
                        selectOptions={filterProviderList}
                        disabled={false}
                        required={false}
                        label='CP Name'
                        shrink
                        onChange={value => handleSelectedProvider(value)}
                        selected={selectedProvider}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                        id='series-filter-country'
                        size='large'
                        shrink={true}
                        label='Country'
                        selectOptions={filterCountryList}
                        disabled={false}
                        onChange={value => handleSelectedCountry(value)}
                        selected={selectedCountry}
                    />
                  </Box>
                </Box>
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <TextField
                        id='series-filter-series-id'
                        type='outlined'
                        size='large'
                        placeholder='Series ID'
                        defaultValue=''
                        label=''
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        value={selectedSeriesId}
                        onChange={value => handleSelectedSeriesId(
                            value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                        id='series-filter-series-title'
                        type='outlined'
                        size='large'
                        placeholder='Series Title'
                        defaultValue=''
                        label=''
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        value={selectedSeriesTitle}
                        onChange={value => handleSelectedSeriesTitle(
                            value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                        id='series-filter-season-id'
                        type='outlined'
                        size='large'
                        placeholder='Season ID'
                        defaultValue=''
                        label=''
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        value={selectedSeasonId}
                        onChange={value => handleSelectedSeasonId(
                            value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                        id='series-filter-season-title'
                        type='outlined'
                        size='large'
                        placeholder='Season Title'
                        defaultValue=''
                        label=''
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        value={selectedSeasonTitle}
                        onChange={value => handleSelectedSeasonTitle(
                            value.target.value)}
                    />
                  </Box>
                </Box>
              </div>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons}/>
              </div>
            </Box>
          </Box>
          {isDisplayData ? (
              <Box className='white-box white-box-wrap'>
                <div className='box-content'>
                  <ExtraFilter
                    total={dataList.length}
                    data={originalDataList}
                    type='series'
                    searchPlaceholder='Please enter a search term'
                    onChange={(event) => setSearchTerm(event.target.value.toLowerCase())}
                  />
                  <Box className='content-inner-wrap'>
                    <SeriesTable
                        columns={MetaSeriesTh}
                        rows={paginatedData}
                        order={order}
                        orderBy={orderBy}
                        handleRequestSort={handleRequestSort}
                        handleAction ={handleAction}
                    />
                  </Box>
                  <Pagination
                      id='pagination-01'
                      count={Math.ceil(
                          dataList.length / itemsPerPage)}
                      selected={itemsPerPage}
                      onChangeHandle={handlePagination}
                      page={page}
                  />
                </div>
              </Box>
            ) : (
              <Loading/>
          )}
        </div>
      </div>
        <Dialog
            open={isAlertOpen}
            handleClose={handleAlertClose}
            closeX={false}
            size='small'
            title={alertTitle}
            contentTitle={false}
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
      </>
  )
}

export default PartnerMetadataSeries;