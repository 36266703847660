import React, { useState, useEffect } from 'react';
import BasicSwitches from 'components/component/BasicSwitch';
import Button from 'components/component/BasicButton';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import Tooltip from 'components/component/BasicTooltip';
import { AddPlus, DarkInfoIcon, DelMinus } from 'components/component/BasicIcon';
import { Grid, IconButton, Typography, Select, MenuItem } from '@mui/material';
import { postRequest } from 'utils/Request';

import { Box } from '@mui/system';
import { StyledFormControl, StyledInputLabel } from 'pages/Organization/TabCategory/data/homeAppStyle';

// 메인 컨텐츠
function PopAddCategory(props) {
  const { tabIdx, contentTypeOptions, mappingTypeOptions, categoryTypeOptions, multiLangOptions, genreCodeOptions, maxCategoryCode, defaultReOrderCategoryRow, onClose, handelSetShowSnakBar, refreshTabCategory } = props;
  const [addCategory, setAddCategory] = useState({
    tabIdx: tabIdx,
    categoryName: '',
    mappingType: '',
    categoryType: categoryTypeOptions[0].value,
    contentType: contentTypeOptions[0].value,
    genreCode: '',
    fixFlag: 'N',
  });

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  // 카테고리 Multi Language 정보를 저장
  const [editLangList, setEditLangList] = React.useState([{ langCode: 'en', name: '' }]);

  const [channelGenreFlag, setChannelGenreFlag] = useState(false);

  const handleClose = () => {
    onClose();
  };
  const onCancelBtn = () => {
    handleClose();
  };

  /* 카테고리 정보 수정 시 editTab 객체에 값을 변경 한다 Start */
  // Category Name 값
  const onChangeCategoryName = (event) => {
    const value = event.target.value;
    setAddCategory((pevState) => ({
      ...pevState,
      categoryName: value,
    }));
  };
  // Category Type 값
  const onChangeCategoryType = (value) => {
    setAddCategory((pevState) => ({
      ...pevState,
      categoryType: value,
    }));
    if (value === 'channel_genre') {
      setChannelGenreFlag(true);
      setAddCategory((pevState) => ({
        ...pevState,
        genreCode: genreCodeOptions[0].value,
      }));
    } else {
      setChannelGenreFlag(false);
      setAddCategory((pevState) => ({
        ...pevState,
        genreCode: '',
      }));
    }
  };
  // Genre Code 값
  const onChangeGenreCode = (value) => {
    setAddCategory((pevState) => ({
      ...pevState,
      genreCode: value,
    }));
  };
  // Content Type 값
  const onChangeContentType = (event) => {
    const content = event.target.value.filter(n => n).join(',');
    setAddCategory((pevState) => ({
      ...pevState,
      contentType: content,
    }));
  };
  // Mapping Type 값
  const onChangeMappingType = (value) => {
    setAddCategory((pevState) => ({
      ...pevState,
      mappingType: value,
    }));
  };
  // MultiLanguage 값
  const onChangeMultiLang = (i, type, value) => {
    let newData = [...editLangList];
    newData[i][type] = value;
    setEditLangList(newData);
  };
  // Fix Flag 값
  const onChangeFixFlag = (checked) => {
    const value = checked ? "Y" : "N";
    setAddCategory((pevState) => ({
      ...pevState,
      fixFlag: value,
    }));
  };
  /* 카테고리 정보 수정 시 editTab 객체에 값을 변경 한다 End */

  // Save버튼 클릭 시 탭 생성
  const clickSave = async event => {
    event.preventDefault();

    // 카테고리 코드를 생성
    let categoryCodeName = 'C' + (parseInt(maxCategoryCode) + parseInt(1));
    if (addCategory.mappingType === 'slider') {
      categoryCodeName = 'C-SLIDER'
    }
    if (addCategory.mappingType === 'carousel') {
      categoryCodeName = 'C-CAROUSEL'
    }

    // 카테고리 order를 생성
    let order = 0;
    let data = [];
    data = defaultReOrderCategoryRow();
    if (data && data.length > 0) {
      //updateTabOrder([...data], param.deviceType, param.region, param.platformVersion, param.status, false);
      let topArray = data.filter(n => n.categoryType === 'top');
      let topOrder = 0;
      if (topArray && topArray.length > 0) {
        topOrder = data.filter(n => n.categoryType === 'top').slice(-1)[0].categoryOrder;
      }

      let otherArray = data.filter(n => n.categoryType !== 'top');
      let otherOrder = 10;
      if (otherArray && otherArray.length > 0) {
        otherOrder = data.filter(n => n.categoryType !== 'top').slice(-1)[0].categoryOrder;
      }

      if (addCategory.categoryType === 'top') {
        order = topOrder + 1;
      } else {
        order = otherOrder + 1;
      }
    }

    // 동일한 MultiLanguage Code 가 존재할 경우
    const langCodes = editLangList.map(n => {
      return n.langCode;
    })
    const duplicateLangCodeCheck = langCodes.filter((n, i) => langCodes.indexOf(n) !== i);
    if (duplicateLangCodeCheck.length > 0) {
      alert('The same language code cannot be used. : ' + duplicateLangCodeCheck[0]);
      return;
    }

    if (addCategory.contentType.indexOf('tvshow-series') > -1 && addCategory.mappingType === 'auto') {
      alert('tvshow-series cannot be set auto mapping rule.');
      return;
    } else if ((addCategory.categoryType === 'upcoming' && addCategory.contentType.indexOf('livestream') > -1) ||
               (addCategory.categoryType === 'upcoming' && addCategory.contentType.indexOf('tvshow-series') > -1)) {
      alert('Upcoming is only available in live and normal VOD types.');
      return;
    } else if ((addCategory.categoryType === 'upcoming' && (addCategory.contentType.indexOf('live') > -1 && addCategory.contentType.indexOf('tvshow') > -1)) ||
               (addCategory.categoryType === 'upcoming' && (addCategory.contentType.indexOf('live') > -1 && addCategory.contentType.indexOf('movie') > -1))) {
      alert('Upcoming live and VOD cannot be set together.');
      return;
    }

    const categoryData = { ...addCategory, categoryCode: categoryCodeName, categoryOrder: order, categoryMultiLangs: editLangList };
    const res = await postRequest(`/home-app/category`, categoryData, null);

    handleClose();
    refreshTabCategory();
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
  }

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: onCancelBtn,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: clickSave,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Add Category' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-tc-addCategory'
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              {/* Category Name */}
              <Grid item xs={12} mb={1}>
                <Box component='div' className='field-box'>
                  <TextField
                    type='outlined'
                    size='large'
                    required={true}
                    placeholder='ex) Category Name'
                    defaultValue={addCategory.categoryName}
                    onChange={onChangeCategoryName}
                    label='Category Name'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              {/* Category Type */}
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-category-type'
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    selectOptions={categoryTypeOptions}
                    disabled={false}
                    label='Category Type'
                    selected={addCategory.categoryType}
                    onChange={onChangeCategoryType}
                    shrink
                  />
                </Box>
              </Grid>
              {/* Genre Code */}
              {channelGenreFlag ? (
                <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-channel-genre'
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    selectOptions={genreCodeOptions}
                    disabled={false}
                    label='Channel Genre'
                    selected={addCategory.genreCode}
                    onChange={onChangeGenreCode}
                    shrink
                  />
                </Box>
              </Grid>
              ) : <div></div>}
              {/* Content Type */}
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <StyledFormControl
                    size='large'
                    variant='outlined'
                    className='large'
                  >
                    <StyledInputLabel shrink={true}>
                      <span>Content Type</span>
                    </StyledInputLabel>
                    <Select
                      multiple
                      value={addCategory.contentType.split(',')}
                      onChange={e => {
                        onChangeContentType(e);
                      }}>
                      {contentTypeOptions.map(n => (
                        <MenuItem key={n.id} value={n.value}>
                          {n.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Box>
              </Grid>
              {/* Mapping Type */}
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-content-type'
                    fullWidth
                    size='large'
                    width='100%'
                    selectOptions={[{id: '', value: '', option: '', type: 'text'}, ...mappingTypeOptions]}
                    disabled={false}
                    label='Mapping Type'
                    selected={addCategory.mappingType}
                    onChange={onChangeMappingType}
                    shrink
                  />
                </Box>
              </Grid>
            </Grid>
            {/* Category MultiLanguage */}
            <Box component='section' className='section-container'>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12}>
                  <Typography className='field-label bold required'>Name</Typography>
                </Grid>
                <Grid item xs={12}>
                {editLangList.map((n, i) => (
                  <Box className='scroll-area' sx={{ maxHeight: '112px' }}>
                    {/* 1st Tab Name */}
                    <Box component='div' className='field-box flex-start'>
                      <SelectBox
                        id='select-tab-name-region-1'
                        size='large'
                        maxWidth='170px'
                        required={true}
                        selectOptions={multiLangOptions}
                        disabled={i === 0 ? true : false}
                        label=''
                        selected={n.langCode}
                        onChange={e => {
                          onChangeMultiLang(i, 'langCode', e);
                        }}
                        shrink
                      />
                      <TextField
                        type='outlined'
                        size='large'
                        required={false}
                        placeholder='Input Category Name'
                        defaultValue=''
                        label=''
                        autoComplete='off'
                        value={n.name}
                        onChange={e => {
                          onChangeMultiLang(i, 'name', e.target.value);
                        }}
                        InputLabelProps={{ shrink: false }}
                      />
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={i === 0 ? AddPlus : DelMinus}
                        iconPos='center'
                        onClick={() => {
                          i === 0 ?
                          setEditLangList(editLangList.concat({ langCode: 'en', name: '' }))
                          :
                          setEditLangList(editLangList.filter((n2, i2) => i2 !== i))
                        }}
                        autosize
                      />
                    </Box>
                  </Box>
                ))}
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' style={{ width: '90px' }}>
                      Fix Flag
                      {/* Icon Tooltip */}
                      <Tooltip
                        title='When Fix Flag is turned on, the category display position is fixed regardless of its random setting.'
                        placement='top'
                      >
                        <IconButton size='small' sx={{ width: '24px', height: '24px', marginLeft: '4px' }}>
                          <DarkInfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    {/* Switch */}
                    <BasicSwitches
                      disabled={false}
                      onOffMode={addCategory.fixFlag === 'Y' ? true : false}
                      setSwitchOnOff={onChangeFixFlag}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                      value={addCategory.fixFlag === 'Y' ? true : false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopAddCategory;
