import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@mui/material';
import CustomImage from 'components/component/BasicImage';
import Button from 'components/component/BasicButton';
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import FileUpload from 'components/component/BasicFileUpload';

const DetailImageItem = React.memo(({ label, xs, src, uiType, uiWidth, uiHeight, actualWidth, actualHeight, type, ratio, group, index, uploadImageId, uploadFile }) => {
  const handleOriginalImageUrlClick = () => {
    console.log('Original Image Url button clicked');
    window.open(src, '_blank');
  };

  const handleUploadFile = (file) => {
    uploadFile(file);
  }

  return (
    <Grid item xs={xs} alignItems='center' className='list-wrap'>
      <Box className='image-value-area'>
        <Typography component='span' className='label'>
          {index === 0 || index === null ? label : ''}
        </Typography>
        <Box>
          <Box className='image-wrap'>
            <CustomImage src={src} fit={false} wrapWidth={uiWidth} wrapHeight={uiHeight} alt='' rounded />
          </Box>
          <Box className='img-url-wrap'>
            {uiType.includes('TYPE') && (
              <Box>
                <Box className='item-row'>
                  <Typography className='text label'>Type : </Typography>
                  <Typography className='text value'>{type}</Typography>
                </Box>
                <Box className='item-row'>
                  <Typography className='text label'>Ratio/Size
                    : </Typography>
                  <Typography
                      className='text value'>{ratio}/{actualWidth}x{actualHeight}</Typography>
                </Box>
                <Box className='item-row'>
                  <Typography className='text label'>Group : </Typography>
                  <Typography className='text value'>{group}</Typography>
                </Box>
              </Box>
            )}
            {uiType.includes('SIZE') && (
              <Typography className='img-size'>
                Size : {actualWidth}x{actualHeight}
              </Typography>
            )}
            <Box display='flex' gap={0.8}>
              {uiType.includes('BASIC') && (
                <Button
                  color='tertiary'
                  type='outline'
                  size='small'
                  label='Image Url'
                  isIcon={true}
                  Icon={BtnTeritaryOutlineRight}
                  iconPos='right'
                  onClick={handleOriginalImageUrlClick}
                />
              )}
              {uiType.includes('EDIT') && (
                <>
                  <Grid item xs={5} mt={1}>
                    <Button
                      color='tertiary'
                      type='outline'
                      size='small'
                      label='Original Image Url'
                      isIcon={true}
                      Icon={BtnTeritaryOutlineRight}
                      iconPos='right'
                      onClick={handleOriginalImageUrlClick}
                    />
                  </Grid>
                  <Grid item xs={12} mt={0}>
                    <Box className='field-box'>
                      <FileUpload
                        id={uploadImageId} // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
                        buttonLabel='Upload'
                        size='large'
                        label=''
                        placeholder='Search files'
                        onFileChange={handleUploadFile}
                        accept='.jpg,.png'
                        subText=''
                        required={true}
                        InputLabelProps={{ shrink: true }}
                      />
                      <Typography component='p' className='upload-info-text'>
                        File Upload(Only .jpeg, png files can be uploaded.)
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
});

DetailImageItem.propTypes = {
  label: PropTypes.string.isRequired,
  xs: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  uiWidth: PropTypes.string.isRequired,
  uiHeight: PropTypes.string.isRequired,
  actualWidth: PropTypes.number.isRequired,
  actualHeight: PropTypes.number.isRequired,
};

export default DetailImageItem;