import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import SelectCheckBox from 'components/component/BasicSelectCheckBox';
import SearchField from 'components/component/BasicSearchField';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import Loading from 'components/component/Loading';
import Dialog from 'components/component/BasicDialog';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import {
  Box,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';

import {
  channelTableTitle,
  programTableTitle,
  vodTableTitle,
  defaultColor,
  valueFormatter,
} from 'partner_pages/Statistics/data/StatisticsData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController,
  ChartDataLabels
);

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  position: 'relative',
}));

const NoRecords = styled('span')`
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

function Statistics(props) {
  const { currentUser, type } = props;

  const optionMenu = {
    gnbMenu: 'Statistics',
    lnbMenu: type === 'channel' ? 'Channel' : type === 'program' ? 'Program' : 'Vod',
  };

  // 서버에서 받은 provider 별로 지원하는 국가 목록 리스트
  const [countryByProvider, setCountryByProvider] = useState([]);

  // 서버에서 받은 테이블 데이터
  const [originalDataList, setOriginalDataList] = useState([]);

  // 화면에서 사용할 테이블 데이터
  const [displayDataList, setDisplayDataList] = useState([]);

  // 화면에 보여줄 테이블 타이틀
  const [tableTitle, setTableTitle] = useState([]);

  // 서버에서 가져온 데이터가 없는 경우 체크
  const [isEmptyData, setEmptyData] = useState(false);

  // 로딩바를 보여 줄 때 사용
  const [isDisplayData, setIsDisplayData] = useState(false);
  const [isDisplayChart, setIsDisplayChart] = useState(false);

  // 필터 옵션
  const [providerOptionFilter, setProviderOptionFilter] = useState([]);
  const [countryOptionFilter, setCountryOptionFilter] = useState([]);
  const [deviceTypeOptionFilter, setDeviceTypeOptionFilter] = useState([]);
  const [platformOptionFilter, setPlatformOptionFilter] = useState([]);
  const [typeOptionFilter, setTypeOptionFilter] = useState([]);  // VOD만 사용

  // 필터 선택 값
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('All');
  const [selectedDeviceType, setSelectedDeviceType] = useState('All');
  const [selectedPlatform, setSelectedPlatform] = useState('All');
  const [selectedType, setSelectedType] = useState('All');  // VOD만 사용

  // 테이블 결과 필터
  const [selectedSearchOption, setSelectedSearchOption] = useState('All');

  // 테이블 결과 검색에 입력한 값
  const [searchTerm, setSearchTerm] = useState('');

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // 버튼 선택 값
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf('week'));
  const [activeDateButton, setActiveDateButton] = useState('Weekly');
  const [activeChartButton, setActiveChartButton] = useState(0);
  const [selectBtnData, setSelectBtnData] = useState([
    { label: 'Unique Device', title: 'uniqueDevice', value: '0', active: true, disabled: false },
    { label: 'Total Duration(hr)', title: 'totalDuration', value: '0', active: false, disabled: false },
    { label: 'Total Duration per Ud', title: 'totalDurationPerUd', value: '0', active: false, disabled: false },
    { label: 'Play', title: 'play', value: '0', active: false, disabled: false },
    { label: 'Play per Ud', title: 'playPerUd', value: '0', active: false, disabled: false },
  ]);

  // Unique Device Chart
  const [DeviceData, setDeviceData] = useState({
    labels: [],
    datasets: [
      {
        type: 'bar',
        label: 'Values',
        barPercentage: 0.8,
        data: [],
        datalabels: {
          display: false,
          align: 'center',
          anchor: 'end',
        },
        backgroundColor: '#C6BC43',
        order: 2,
      },
      {
        type: 'line',
        label: activeDateButton === 'Daily' ? 'DoD' : activeDateButton === 'Weekly' ? 'WoW' : 'MoM',
        data: [],
        borderColor: '#FF5778',
        borderWidth: 2,
        pointBackgroundColor: '#FF5778',
        datalabels: {
          display: true,
          align: 'top',
          anchor: 'end',
          formatter: (value, context) => {
            const previousValue = context.dataIndex > 0 ? context.chart.data.datasets[0].data[context.dataIndex - 1] : 0;
            const percentage = previousValue !== 0 ? ((value - previousValue) / previousValue) * 100 : 0;
            return `${percentage.toFixed(1)}%`; // Format to 1 decimal place
          },
          color: '#FF5778',
          font: {
            weight: 'bold',
          },
        },
        order: 1,
      },
    ],
  });

  const [DeviceOptions, setDeviceOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const datasetLabel = context.dataset.label || '';
            const value = context.raw;
            if (context.dataset.type === 'line') {
              const previousValue = context.dataIndex > 0 ? context.chart.data.datasets[0].data[context.dataIndex - 1] : 0;
              const percentage = previousValue !== 0 ? ((value - previousValue) / previousValue) * 100 : 0;
              return `${datasetLabel}: ${percentage.toFixed(1)}%`;
            }
            return `${datasetLabel}: ${value}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
  });

  const [top10Data, setTop10Data] = useState({
    labels: [],
    datasets: [
      {
        axis: 'y',
        label: 'Content Value',
        data: [],
        backgroundColor: defaultColor,
        borderWidth: 0,
      },
    ],
  });

  const [config, setConfig] = useState({
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'nearest',
        callbacks: {
          label: (context) => `${context.raw} units`,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 30,
        ticks: {
          stepSize: 10,
        },
      },
    },
  });

  // 원 차트 데이터
  const [pieChartData, setPieChartData] = useState([]);

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  useEffect(() => {
    if (type) {
      setTableTitle(type === 'channel' ? channelTableTitle : type === 'program' ? programTableTitle : vodTableTitle);
      initialize();
      getFilter();
    }
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (selectedProvider && countryByProvider.length > 0) {
      const tempCountry = countryByProvider.filter(item => item.providerName === selectedProvider).map(item => item.country).join(',').split(',');
      setSelectedCountry('All');

      const tempCountryOption = [
        {
          id: 'all',
          value: 'all',
          option: 'All',
          type: 'checkbox',
          defaultChecked: true,
        },
        ...tempCountry.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'checkbox',
          defaultChecked: false,
        })),
      ];

      setCountryOptionFilter([...tempCountryOption]);
    }
  }, [selectedProvider, countryByProvider]);

  useEffect(() => {
    if (selectedProvider && selectedCountry && selectedDeviceType && selectedPlatform && selectedDate && selectedType) {
      getDataInfo();
      getGraphInfo();
    }
    // eslint-disable-next-line
  }, [selectedProvider, selectedCountry, selectedDeviceType, selectedPlatform, selectedDate, selectedType]);

  useEffect(() => {
    if (activeDateButton === 'Daily') {
      setSelectedDate(dayjs());
    } else if (activeDateButton === 'Weekly') {
      setSelectedDate(dayjs().startOf('week'));
    } else if (activeDateButton === 'Monthly') {
      setSelectedDate(dayjs().startOf('month'));
    }
  }, [activeDateButton]);

  useEffect(() => {
    if (activeChartButton > -1) {
      getGraphInfo();
    }
    // eslint-disable-next-line
  }, [activeChartButton]);

  useEffect(() => {
    if (selectedSearchOption) {
      if (searchTerm) {
        const filteredData = originalDataList.filter((item) => {
          return Object.entries(item).some(([key, value]) => {
            if (selectedSearchOption === 'All' || selectedSearchOption === key) {
              return String(value).toLowerCase().includes(searchTerm.toLowerCase());
            }
            return false;
          });
        });

        setDisplayDataList([...filteredData]);

        if (filteredData.length === 0) {
          setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
        } else {
          setEmptyData(false);
        }
      } else {
        setDisplayDataList([...originalDataList]);
        if (originalDataList.length === 0) {
          setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
        } else {
          setEmptyData(false);
        }
      }
    }
  }, [selectedSearchOption, searchTerm, originalDataList]);

  // Paging 처리
  useEffect(() => {
    if (displayDataList && displayDataList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayDataList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setIsDisplayData(true);
    }
  }, [displayDataList, page, itemsPerPage]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayDataList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayDataList([...sortedData]);
  };

  const dataInitialize = () => {
    const resetData = selectBtnData.map(item => ({ ...item, value: '0' }));
    setSelectBtnData(resetData);

    // table 데이터  초기화
    setOriginalDataList([]);
    setDisplayDataList([]);
  };

  const graphInitialize = () => {
    setDeviceData({
      labels: [],
      datasets: [
        {
          ...DeviceData.datasets[0],
          data: [],
        },
        {
          ...DeviceData.datasets[1],
          data: [],
        },
      ],
    });

    setTop10Data({
      labels: [],
      datasets: [
        {
          ...top10Data.datasets[0],
          data: [],
        },
      ],
    });

    setPieChartData([]);
  };
  const initialize = () => {
    setSelectedProvider('');
    setSelectedCountry('All');
    setSelectedDeviceType('All');
    setSelectedPlatform('All');
    setSelectedType('All');
    setSelectedSearchOption('All');
    setSearchTerm('');
    setSelectedDate(dayjs().startOf('week'));
    setActiveDateButton('Weekly');
    setActiveChartButton(0);

    dataInitialize();
    graphInitialize();
  };

  const getFilter = () => {
    fetch(`${BASE_URL}/statistics/filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        if (body.data.countryByCp) {
          setCountryByProvider(body.data.countryByCp);

          let tempProviderOption = [];
          if (currentUser && (currentUser.role === 'ROLE_CP' || currentUser.role === 'ROLE_STUDIO')) {
            setSelectedProvider(currentUser.provider.providerName);

            tempProviderOption = [
              {
                id: currentUser.provider.providerName,
                value: currentUser.provider.providerName,
                option: currentUser.provider.providerName,
                type: 'text',
              },
            ];
          } else {
            const tempProviderName = body.data.countryByCp.map((item) => item.providerName);
            setSelectedProvider(tempProviderName[0]);

            tempProviderOption = [
              ...tempProviderName.map(item => ({
                id: item,
                value: item,
                option: item,
                type: 'text',
              })),
            ];
          }

          setProviderOptionFilter([...tempProviderOption]);
        }

        if (body.data.deviceType) {
          setSelectedDeviceType('All');
          const tempDeviceType = [
            {
              id: 'all',
              value: 'all',
              option: 'All',
              type: 'checkbox',
              defaultChecked: true,
            },
            ...body.data.deviceType.map(item => ({
              id: item,
              value: item,
              option: item,
              type: 'checkbox',
              defaultChecked: false,
            })),
          ];

          setDeviceTypeOptionFilter([...tempDeviceType]);
        }

        if (body.data.platform) {
          setSelectedPlatform('All');
          const tempPlatform = [
            {
              id: 'all',
              value: 'all',
              option: 'All',
              type: 'checkbox',
              defaultChecked: true,
            },
            ...body.data.platform.map(item => ({
              id: item,
              value: item,
              option: item,
              type: 'checkbox',
              defaultChecked: false,
            })),
          ];

          setPlatformOptionFilter([...tempPlatform]);
        }

        if (body.data.type) {
          setSelectedType('All');
          const tempType = [
            {
              id: 'all',
              value: 'all',
              option: 'All',
              type: 'checkbox',
              defaultChecked: true,
            },
            ...body.data.type.map(item => ({
              id: item,
              value: item,
              option: item,
              type: 'checkbox',
              defaultChecked: false,
            })),
          ];

          setTypeOptionFilter([...tempType]);
        }
      } else {
        throw new Error(`statistics/${type}/graph request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  }

  const getDataInfo = () => {
    const dateType = activeDateButton.toLowerCase();
    const date = dateType === 'monthly' ? selectedDate.format('YYYY-MM') : selectedDate.format('YYYY-MM-DD');

    let param = '';
    if (type === 'vod') {
      param = `cpName=${selectedProvider}&country=${selectedCountry}&deviceType=${selectedDeviceType}&platform=${selectedPlatform}&dateType=${dateType}&date=${date}&type=${selectedType}`;
    } else {
      param = `cpName=${selectedProvider}&country=${selectedCountry}&deviceType=${selectedDeviceType}&platform=${selectedPlatform}&dateType=${dateType}&date=${date}`;
    }

    setIsDisplayData(false);
    setEmptyData(false);
    dataInitialize();

    fetch(`${BASE_URL}/statistics/${type}?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        if (body.data.data) {
          const updatedSelectBtnData = selectBtnData.map(btn => {
            const matchingItem = body.data.data.find(item => item[btn.title]);
            if (matchingItem) {
              return { ...btn, value: String(matchingItem[btn.title]) };
            } else {
              return { ...btn, value: '0' };
            }
          });
          setSelectBtnData(updatedSelectBtnData);
        }

        if (body.data.table) {
          setOriginalDataList(body.data.table);

          if (body.data.table.length === 0) {
            setEmptyData(true);
            setIsDisplayData(true);
          }
        }
      } else {
        throw new Error(`statistics/${type} request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const getGraphInfo = () => {
    const dateType = activeDateButton.toLowerCase();
    const date = dateType === 'monthly' ? selectedDate.format('YYYY-MM') : selectedDate.format('YYYY-MM-DD');
    const refValue = selectBtnData[activeChartButton].title;

    let param = '';
    if (type === 'vod') {
      param = `cpName=${selectedProvider}&country=${selectedCountry}&deviceType=${selectedDeviceType}&platform=${selectedPlatform}&dateType=${dateType}&date=${date}&type=${selectedType}&refValue=${refValue}`;
    } else {
      param = `cpName=${selectedProvider}&country=${selectedCountry}&deviceType=${selectedDeviceType}&platform=${selectedPlatform}&dateType=${dateType}&date=${date}&refValue=${refValue}`;
    }

    setIsDisplayChart(false);
    graphInitialize();

    fetch(`${BASE_URL}/statistics/${type}/graph?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {

        if (body.data.indicator) {
          const labels = body.data.indicator.map(item => item.axis);
          const data = body.data.indicator.map(item => item.value);

          setDeviceData(prevData => ({
            ...prevData,
            labels,
            datasets: [
              {
                ...prevData.datasets[0],
                data,
              },
              {
                ...prevData.datasets[1],
                data,  // bar 차트 상단에 line 차트를 보여 주기 위해서
                label: activeDateButton === 'Daily' ? 'DoD' : activeDateButton === 'Weekly' ? 'WoW' : 'MoM',
              },
            ],
          }));

          const maxDataValue = Math.ceil(Math.max(...data));
          const stepSize = Math.ceil(maxDataValue / 5);

          setDeviceOptions(prevOptions => ({
            ...prevOptions,
            scales: {
              ...prevOptions.scales,
              y: {
                ...prevOptions.scales.y,
                max: maxDataValue + Math.max(maxDataValue * 0.2, 1),
                ticks: {
                  stepSize: stepSize,
                },
              },
            },
          }));
        }

        if (body.data.topten) {
          const labels = body.data.topten.map(item => item.axis);
          const data = body.data.topten.map(item => item.value);
          setTop10Data(prevData => ({
            ...prevData,
            labels,
            datasets: [
              {
                ...prevData.datasets[0],
                data,
              },
            ],
          }));

          const maxDataValue = Math.max(...data);
          const stepSize = Math.ceil(maxDataValue / 3);

          setConfig(prevConfig => ({
            ...prevConfig,
            scales: {
              ...prevConfig.scales,
              x: {
                ...prevConfig.scales.x,
                max: maxDataValue,
                ticks: {
                  stepSize: stepSize,
                },
              },
            },
          }));
        }

        if (body.data.genre) {
          const data = body.data.genre.map((item, index) => ({
            label: item.axis,
            value: item.value,
            color: defaultColor[index],
          }));

          setPieChartData(data);
        }
      } else {
        throw new Error(`statistics/${type}/graph request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    })
    .finally(() => {
      setIsDisplayChart(true);
    });
  };

  const handleButtonClick = (label) => {
    setActiveDateButton(label);
  };

  const handleTabClick = (index) => {
    setActiveChartButton(index);
  };

  const handleDateChange = (newValue) => {
    if (dayjs.isDayjs(newValue) && newValue.isValid()) {
      setSelectedDate(newValue);
    }
  };

  const handleSelectedItems = (type, selectedItems) => {
    if (selectedItems && selectedItems.length > 0) {
      if (type === 'country') {
      setSelectedCountry([...selectedItems]);
      } else if (type === 'deviceType') {
        setSelectedDeviceType([...selectedItems]);
      } else if (type === 'platform') {
        setSelectedPlatform([...selectedItems]);
      } else if (type === 'type') {
        setSelectedType([...selectedItems]);  // VOD만 사용
      } else {
        console.error('Invalid type');
      }
    }
  };

  const handleSearchContent = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  // Alert 팝업 처리
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleDownload = (event) => {
    event.preventDefault();

    const worksheet = XLSX.utils.json_to_sheet(originalDataList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${type}_data`);
    XLSX.writeFile(workbook, `${type}_data.xlsx`);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container partner-statistic' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Typography component='p' className='title'>
                {type === 'channel' ? 'Channel' : type === 'program' ? 'Program' : 'VOD'}
              </Typography>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box '>
            <Typography className='notice-text' mb={2}>
              ※ Only VOD Total Duration and Play data are provided for the US. / Please refer to the VOD page for US
              date
            </Typography>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap'>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='cpName'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      selectOptions={providerOptionFilter}
                      disabled={false}
                      placeholder=''
                      value=''
                      selected={selectedProvider}
                      onChange={setSelectedProvider}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectCheckBox
                      id='country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={countryOptionFilter}
                      disabled={false}
                      multiple={true}
                      onChange={(value) => handleSelectedItems('country', value)}
                    />
                  </Box>
                  {type === 'vod' && <Box component='div' className='col'>
                    <SelectCheckBox
                      id='type'
                      size='large'
                      shrink={true}
                      label='Type'
                      required={false}
                      selectOptions={typeOptionFilter}
                      disabled={false}
                      multiple={true}
                      onChange={(value) => handleSelectedItems('type', value)}
                    />
                  </Box>}
                  <Box component='div' className='col'>
                    <SelectCheckBox
                      id='deviceType'
                      size='large'
                      shrink={true}
                      label='Device Type'
                      selectOptions={deviceTypeOptionFilter}
                      disabled={false}
                      placeholder=''
                      value=''
                      multiple={true}
                      onChange={(value) => handleSelectedItems('deviceType', value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectCheckBox
                      id='summary-Platform'
                      size='large'
                      shrink={true}
                      label='Platform'
                      selectOptions={platformOptionFilter}
                      disabled={false}
                      placeholder=''
                      value=''
                      multiple={true}
                      onChange={(value) => handleSelectedItems('platform', value)}
                    />
                  </Box>
                  <Box component='div' className='col col-toggle'>
                    <Box className='toggle-btn-wrap time-period' display='flex'>
                      <Button
                        color='tertiary'
                        type={activeDateButton === 'Daily' ? 'box' : 'outline'}
                        size='medium'
                        label='Daily'
                        isIcon={false}
                        autosize
                        onClick={() => handleButtonClick('Daily')}
                      />
                      <Button
                        color='tertiary'
                        type={activeDateButton === 'Weekly' ? 'box' : 'outline'}
                        size='medium'
                        label='Weekly'
                        isIcon={false}
                        autosize
                        onClick={() => handleButtonClick('Weekly')}
                      />
                      <Button
                        color='tertiary'
                        type={activeDateButton === 'Monthly' ? 'box' : 'outline'}
                        size='medium'
                        label='Monthly'
                        isIcon={false}
                        autosize
                        onClick={() => handleButtonClick('Monthly')}
                      />
                    </Box>
                  </Box>
                  <Box component='div' className='col col-picker'>
                    <DatePicker
                      className='input-date-picker'
                      format='MM/DD/YYYY'
                      size='large'
                      maxWidth={activeDateButton === 'Weekly' ? '230px' : '160px'}
                      singleDate={activeDateButton === 'Daily'}
                      monthlyDate={activeDateButton === 'Monthly'}
                      weeklyDate={activeDateButton === 'Weekly'}
                      onChange={handleDateChange}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>

          <Box className='chart-board-container'>
            <Box className='board-btn-wrap'>
              <Box className='button-area'>
                {selectBtnData.map((tab, index) => (
                  <Box
                    key={index}
                    className={`tab-box ${activeChartButton === index ? 'active' : ''}`}
                    component='button'
                    onClick={() => handleTabClick(index)}
                    disabled={tab.disabled}
                  >
                    <Box className='box-wrap'>
                      <span className='label'>{tab.label}</span>
                      <span className='value'>{tab.value}</span>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            {isDisplayChart ? (
              <Box className='board-chart-wrap'>
                <Box className='chart-area'>
                  <Box className='white-box white-box-wrap chart-box'>
                    <Box className='title-wrap'>
                      <Box className='box-title-area' display='flex' alignItems='center'>
                        <Typography variant='h2'>{selectBtnData[activeChartButton].label}</Typography>
                      </Box>
                    </Box>
                    <Box className='box-content chart-item'>
                      <Box className='bar-line-chart' height={385}>
                        <Bar data={DeviceData} options={DeviceOptions} />
                      </Box>
                    </Box>
                  </Box>
                  <Box className='white-box white-box-wrap'>
                    <Box className='title-wrap '>
                      <Box className='box-title-area' display='flex' alignItems='center'>
                        <Typography variant='h2'>Top 10 Content</Typography>
                        <Typography component='p' className='select-count-wrap'>
                          (Based on Unique Devices)
                        </Typography>
                      </Box>
                    </Box>
                    <Box className='box-content'>
                      <Box className='bar-hor-chart' height={400}>
                        <Bar data={top10Data} options={config} />
                      </Box>
                    </Box>
                  </Box>
                  <Box className='white-box white-box-wrap'>
                    <Box className='title-wrap '>
                      <Box className='box-title-area' display='flex' alignItems='center'>
                        <Typography variant='h2'>Popular Genre</Typography>
                        <Typography component='p' className='select-count-wrap'>
                          (Based on Unique Devices)
                        </Typography>
                      </Box>
                    </Box>
                    <Box className='box-content'>
                      <Box width={'85%'}>
                        <PieChart
                          height={400}
                          margin={{ left: 10, right: 10 }}
                          series={[
                            {
                              data: pieChartData,
                              innerRadius: 80,
                              arcLabel: (params) => `${params.label}: ${params.value}%`,
                              arcLabelMinAngle: 10,
                              valueFormatter,
                            },
                          ]}
                          sx={{ fontSize: '12px' }}
                          slotProps={{
                            legend: {
                              hidden: true,
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Loading />
            )}
          </Box>

          {isDisplayData ? (
            <Box className='white-box white-box-wrap' mb={3}>
              <div className='box-content'>
                <Box className='white-box-content-top-area'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Box className='total-count'>
                        <Box component='span' className='label'>
                          Total
                        </Box>
                        <Box component='span' className='value'>
                          {displayDataList.length}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Button
                      color='tertiary'
                      type='outline'
                      size='small'
                      label='Excel Dounload'
                      isIcon={false}
                      autosize
                      onClick={(event) => handleDownload(event)}
                    />
                    <SearchField
                      placeholder='Please enter a search term'
                      maxWidth='230px'
                      onChange={(event) => handleSearchContent(event.target.value)}
                    />
                  </Box>
                </Box>
                <Box className='content-inner-wrap'>
                  <CustomTableContainer className='table-container'>
                    <Table className='custom-table' aria-label='table' stickyHeader>
                      {/* table Head */}
                      <TableHead className='thead'>
                        <TableRow className='tr'>
                          {tableTitle.map((column) => (
                            <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                              {column.checkbox ? (
                                <Box display='flex' alignContent='center'>
                                  <CheckBox
                                    label=''
                                    value='select-all'
                                    defaultChecked={false}
                                    inputProps={{
                                      'aria-label': 'select all',
                                    }}
                                  />
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                </Box>
                              ) : (
                                <>
                                  {column.sortable ? (
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  ) : (
                                    column.label
                                  )}
                                </>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      {/* table Body */}
                      <TableBody className='tbody'>
                        {isEmptyData ? (
                          <NoRecords>
                            No records to display
                          </NoRecords>
                        ) : (
                          paginatedData.map((td, index) => (
                            <TableRow className='tr' key={td.id || index}>
                              {type === 'channel' ? (
                                tableTitle.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align}>
                                    {colIndex === 0 ? (
                                      <>
                                        {td.name}
                                      </>
                                    ) : colIndex === 1 ? (
                                      <>
                                        {td.country}
                                      </>
                                    ) : colIndex === 2 ? (
                                      <>
                                        {td.platform}
                                      </>
                                    ) : colIndex === 3 ? (
                                      <>
                                        {td.deviceType}
                                      </>
                                    ) : colIndex === 4 ? (
                                      <>
                                        {td.uniqueDevice}
                                      </>
                                    ) : colIndex === 5 ? (
                                      <>
                                        {td.play}
                                      </>
                                    ) : colIndex === 6 ? (
                                      <>
                                        {td.playPerUd}
                                      </>
                                    ) : colIndex === 7 ? (
                                      <>
                                        {td.totalDuration}
                                      </>
                                    ) : colIndex === 8 ? (
                                      <>
                                        {td.totalDurationPerUd}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </TableCell>
                                ))
                              ) : type === 'program' ? (
                                tableTitle.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align}>
                                    {colIndex === 0 ? (
                                      <>
                                        {td.channelId}
                                      </>
                                    ) : colIndex === 1 ? (
                                      <>
                                        {td.channelName}
                                      </>
                                    ) : colIndex === 2 ? (
                                      <>
                                        {td.programName}
                                      </>
                                    ) : colIndex === 3 ? (
                                      <>
                                        {td.country}
                                      </>
                                    ) : colIndex === 4 ? (
                                      <>
                                        {td.platform}
                                      </>
                                    ) : colIndex === 5 ? (
                                      <>
                                        {td.deviceType}
                                      </>
                                    ) : colIndex === 6 ? (
                                      <>
                                        {td.uniqueDevice}
                                      </>
                                    ) : colIndex === 7 ? (
                                      <>
                                        {td.play}
                                      </>
                                    ) : colIndex === 8 ? (
                                      <>
                                        {td.playPerUd}
                                      </>
                                    ) : colIndex === 9 ? (
                                      <>
                                        {td.totalDuration}
                                      </>
                                    ) : colIndex === 10 ? (
                                      <>
                                        {td.totalDurationPerUd}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </TableCell>
                                ))
                              ) : (
                                tableTitle.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align}>
                                    {colIndex === 0 ? (
                                      <>
                                        {td.type}
                                      </>
                                    ) : colIndex === 1 ? (
                                      <>
                                        {td.vodName}
                                      </>
                                    ) : colIndex === 2 ? (
                                      <>
                                        {td.country}
                                      </>
                                    ) : colIndex === 3 ? (
                                      <>
                                        {td.platform}
                                      </>
                                    ) : colIndex === 4 ? (
                                      <>
                                        {td.deviceType}
                                      </>
                                    ) : colIndex === 5 ? (
                                      <>
                                        {td.uniqueDevice}
                                      </>
                                    ) : colIndex === 6 ? (
                                      <>
                                        {td.play}
                                      </>
                                    ) : colIndex === 7 ? (
                                      <>
                                        {td.playPerUd}
                                      </>
                                    ) : colIndex === 8 ? (
                                      <>
                                        {td.totalDuration}
                                      </>
                                    ) : colIndex === 9 ? (
                                      <>
                                        {td.totalDurationPerUd}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </TableCell>
                                ))
                              )}
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </CustomTableContainer>
                </Box>
                {/* Pagination */}
                <Pagination count={Math.ceil(displayDataList.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
              </div>
            </Box>
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
      <Dialog
        open={isAlertOpen} // boolean 값으로 설정
        handleClose={handleAlertClose}
        closeX={false}
        size='small' // large, medium, small
        title={alertTitle} // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </Box>
  );
}
export default Statistics;
