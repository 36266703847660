import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { Box, Typography, Link, Grid, ListItem, List, Divider } from '@mui/material';
// Data
import { carouselTypeList, contentTypeList } from 'pages/Organization/TabCategory/data/TabCategoryCarouselData';
import { defaultOption, defaultParameter, defaultParameterDisabled } from 'pages/Organization/TabCategory/Carousel/DefaultData';
import { RefreshIcon } from 'components/component/BasicIcon';
// Common
import { isEmpty } from 'utils/Valid';
import { getRequest } from 'utils/Request';
import CarouselSearchList from 'pages/Organization/TabCategory/Carousel/CarouselSearchList';
import CarouselResultList from 'pages/Organization/TabCategory/Carousel/CarouselResultList';

// Main
function OrganizeCarousel({ message }) {
  useEffect(() => {
    setCarouselInfo(message);
    setCarouselTab(message.tab);
    setCarouselCategory(message.category);
    // eslint-disable-next-line
  }, [message]);

  const [carouselTab, setCarouselTab] = useState({});
  const [carouselCategory, setCarouselCategory] = useState({});
  const [carousel, setCarousel] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [searchParameter, setSearchParameter] = useState(defaultParameter);
  const [parameterDisabled, setParameterDisabled] = useState(defaultParameterDisabled);
  // list
  const [carouselContentsList, setCarouselContentsList] = useState([]);
  const [savedResultList, setSavedResultList] = useState([]);
  const [carouselResultList, setCarouselResultList] = useState([]);
  const [carouselResultIdList, setCarouselResultIdList] = useState([]);
  const [searchCarouselType, setSearchCarouselType] = useState({});
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Category List', 'Category Organize'],
  };

  // carousel
  const setCarouselInfo = async (message) => {
    const body = await getRequest(`/home-app/carousel?categoryIdx=${message.categoryIdx}`, null);
    if (body && body.result === 'SUCCESS') {
      setCarousel(body.data);
      let initList = contentTypeList.filter((f) => f.carouselType === carouselTypeList[0].value);
      let initParameter = {
        ...defaultParameter,
        carouselIdx: body.data.carouselIdx,
        carouselId: body.data.carouselId,
        countryCode: message.countryOptions[0].id,
        carouselType: !isEmpty(carouselTypeList) ? carouselTypeList[0].value : '',
        contentType: !isEmpty(initList) ? initList[0].value : '',
        providerId: 'All',
      };
      setSearchCarouselType(!isEmpty(carouselTypeList) ? carouselTypeList[0].value : '');
      setSearchParameter(initParameter);
      setCountryList(message.countryOptions);
      setProviderList([...[defaultOption], ...message.providerOptions]);
      getCarouselContentsList(body.data.carouselIdx, initParameter);
    }
  };

  useEffect(() => {
    if (!isEmpty(searchParameter) && !isEmpty(searchParameter.carouselIdx) && !isEmpty(searchParameter.countryCode)) {
      getCarouselResultList(searchParameter.carouselIdx, searchParameter.countryCode);
    }
    // eslint-disable-next-line
  }, [searchParameter.countryCode]);

  useEffect(() => {
    setCarouselResultList(!isEmpty(savedResultList) ? JSON.parse(JSON.stringify(savedResultList)) : []);
    // eslint-disable-next-line
  }, [savedResultList]);

  useEffect(() => {
    if (!isEmpty(carouselResultList)) {
      setCarouselResultIdList(carouselResultList.map((m) => m.contentId));
    }
    // eslint-disable-next-line
  }, [carouselResultList]);

  const refreshResult = () => {
    getCarouselResultList(searchParameter.carouselIdx, searchParameter.countryCode, false);
  };

  const getResetResultList = () => {
    getCarouselResultList(searchParameter.carouselIdx, searchParameter.countryCode, true);
  };

  const editCarouselResultList = (list) => {
    if (!isEmpty(list)) {
      list.forEach((content) => {
        content.editAction = updateChecked(content, savedResultList);
      });
    }
    setCarouselResultList(list);
  };

  const updateChecked = (content, list) => {
    if (content.manualFlag === 'Y' && (isEmpty(content.resultIdx) || content.resultIdx < 0)) {
      return 'added';
    }
    let checked = list.find((f) => f.contentId === content.contentId);
    if (content.manualFlag === 'Y' && isEmpty(checked)) {
      return 'added';
    }
    if (
      content.manualFlag === 'Y' &&
      (content.resultOrder !== checked.resultOrder ||
        content.resultTitle !== checked.resultTitle ||
        content.resultDescription !== checked.resultDescription ||
        !isEmpty(content.customHorizontalImage) ||
        !isEmpty(content.customVerticalImage) ||
        !isEmpty(content.customFocusImage))
    ) {
      return 'update';
    }
    if (content.manualFlag === 'N' && checked.manualFlag === 'Y') {
      return 'update';
    }
    return '';
  };

  const getCarouselResultList = async (carouselIdx, countryCode, isReset) => {
    let param = 'N';
    if (!isEmpty(isReset) && isReset === true) {
      param = 'Y';
    }
    const body = await getRequest(`/home-app/carousel/${carouselIdx}/country/${countryCode}/result?reset=${param}`, null);
    if (body && body.result === 'SUCCESS') {
      setSavedResultList(!isEmpty(body.data) ? body.data : []);
    }
  };

  // SearchFilter
  const searchFilterHandler = (name, value) => {
    if (name === 'countryCode') {
      setParameterDisabled(defaultParameterDisabled);
      let contentType = contentTypeList.filter((f) => f.carouselType === carouselTypeList[0].value);
      setSearchParameter({
        ...defaultParameter,
        [name]: value,
        carouselType: carouselTypeList[0].value,
        contentType: contentType[0].value,
        provider: 'All',
      });
    } else if (name === 'carouselType') {
      let contentType = contentTypeList.filter((f) => f.carouselType === value);
      if (value === 'channel' || value === 'vod') {
        setParameterDisabled(defaultParameterDisabled);
      } else if (value === 'hub') {
        setParameterDisabled({ ...defaultParameterDisabled, isProvider: true });
      } else {
        setParameterDisabled({ ...defaultParameterDisabled, isContent: true, isProvider: true });
      }
      setSearchParameter({
        ...searchParameter,
        [name]: value,
        contentType: !isEmpty(contentType) && contentType.length > 0 ? contentType[0].value : '',
        provider: 'All',
      });
    } else if (name === 'contentType') {
      setSearchParameter({ ...searchParameter, [name]: value, provider: 'All' });
    } else {
      setSearchParameter({ ...searchParameter, [name]: value });
    }
  };

  const getCarouselContentsList = async (carouselIdx, parameter) => {
    let param = `?carouselType=${parameter.carouselType}`;
    if (!isEmpty(parameter.contentType)) {
      param += `&contentType=${parameter.contentType}`;
    }
    if (!isEmpty(parameter.providerId) && parameter.providerId !== 'All') {
      param += `&provider=${parameter.providerId}`;
    }
    if (!isEmpty(parameter.searchId)) {
      param += `&searchId=${parameter.searchId}`;
    }
    if (!isEmpty(parameter.searchText)) {
      param += `&searchText=${parameter.searchText}`;
    }

    const body = await getRequest(`/home-app/carousel/${carouselIdx}/country/${parameter.countryCode}/contents${param}`, null);
    if (body && body.result === 'SUCCESS') {
      setCarouselContentsList(body.data);
      setSearchCarouselType(parameter.carouselType);
    }
  };

  const setResultContents = (searchContentsList) => {
    let already = [...carouselResultList];
    let maxOrder = 0;
    if (!isEmpty(already) && already.length > 0) {
      maxOrder = already.reduce(function (previous, current) {
        return previous.resultOrder > current.resultOrder ? previous.resultOrder : current.resultOrder;
      });
    }
    searchContentsList.forEach((element) => {
      element.resultOrder = ++maxOrder;
    });

    let integrated = [...already, ...searchContentsList];
    setCarouselResultList(integrated);
  };

  const listSearch = () => {
    getCarouselContentsList(carousel.carouselIdx, searchParameter);
  };

  const listReset = () => {
    // setSearchParameter({
    //   deviceType: 'All',
    //   countryCode: 'All',
    // });
    // getPolicyList('All', 'All');
  };
  const searchButton = [
    {
      color: 'tertiary',
      type: 'outline icon',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      autosize: false,
      minWidth: '107px',
      onClick: listReset,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: false,
      minWidth: '120px',
      onClick: listSearch,
    },
  ];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Category Organize
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {/* Tab name */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Tab</Typography>
                    <Typography className='value'>{carouselTab.tabName}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Category Name */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Category</Typography>
                    <Typography className='value'>{carouselCategory.categoryName}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Content Type */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Content Type</Typography>
                    <Typography className='value'>{carouselCategory.contentType}</Typography>
                  </ListItem>
                </React.Fragment>
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-6'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='search-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={isEmpty(countryList) ? [] : countryList}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={searchParameter.countryCode}
                      onChange={(value) => searchFilterHandler('countryCode', value)}
                    />
                  </Box>
                  {/* Carousel Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='search-carousel-type'
                      size='large'
                      shrink={true}
                      label='Carousel Type'
                      required={false}
                      selectOptions={isEmpty(carouselTypeList) ? [] : carouselTypeList}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={searchParameter.carouselType}
                      onChange={(value) => searchFilterHandler('carouselType', value)}
                    />
                  </Box>
                  {/* Content Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='search-content-type'
                      size='large'
                      shrink={true}
                      label='Content Type'
                      required={false}
                      selectOptions={isEmpty(contentTypeList) ? [] : contentTypeList.filter((f) => f.carouselType === searchParameter.carouselType)}
                      disabled={parameterDisabled.isContent}
                      isFirstSelected={true}
                      placeholder=''
                      selected={searchParameter.contentType}
                      onChange={(value) => searchFilterHandler('contentType', value)}
                    />
                  </Box>
                  {/* CP Name */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='search-provider'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      required={false}
                      selectOptions={isEmpty(providerList) ? [] : providerList}
                      disabled={parameterDisabled.isProvider}
                      isFirstSelected={true}
                      placeholder=''
                      selected={searchParameter.providerId}
                      onChange={(value) => searchFilterHandler('providerId', value)}
                    />
                  </Box>
                  {/* ID */}
                  <Box component='div' className='col'>
                    <TextField
                      id='search-searchId'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='ID'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                      value={searchParameter.searchId}
                      onChange={(event) => searchFilterHandler('searchId', event.target.value)}
                    />
                  </Box>
                  {/* Name */}
                  <Box component='div' className='col'>
                    <TextField
                      id='search-searchText'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Name'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                      value={searchParameter.searchText}
                      onChange={(event) => searchFilterHandler('searchText', event.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'>
                <CustomButtonGroup buttons={searchButton} />
              </Box>
            </Box>
          </Box>
          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {/* Content List */}
            <CarouselSearchList
              carouselTab={carouselTab}
              carouselCountry={searchParameter.countryCode}
              carouselCategory={carouselCategory}
              carouselType={searchCarouselType}
              carouselContentsList={carouselContentsList}
              disabledContentList={carouselResultIdList}
              setCarouselResult={setResultContents}
              refreshContents={listSearch}
            />
            {/* Content List end*/}
            {/* Selected Content */}
            <CarouselResultList
              carouselTab={carouselTab}
              carouselCategory={carouselCategory}
              carousel={carousel}
              carouselCountry={searchParameter.countryCode}
              resetResult={getResetResultList}
              carouselResultList={carouselResultList}
              editCarouselResultList={editCarouselResultList}
              refreshResult={refreshResult}
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default OrganizeCarousel;
