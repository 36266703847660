import {
  Box,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import SelectBox from "../../../components/component/BasicSelectBox";
import {
  NotificationSearchOption,
  SupportFaqTh
} from "./data/SupportData";
import SearchField from "../../../components/component/BasicSearchField";
import CustomButtonGroup from "../../../components/component/BasicButtonGroup";
import CheckBox from "../../../components/component/BasicCheckBox";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextLink from "../../../components/component/BasicTextLink";
import Button from "../../../components/component/BasicButton";
import {FileIcon} from "../../../components/component/BasicIcon";
import Pagination from "../../../components/component/BasicPagination";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {getHeaders} from "../../../utils/Header";
import {BASE_URL} from "../../../constants";
import PopEditFAQ from "./PopEditFAQ";
import PopAddFAQ from "./PopAddFAQ";
import Dialog from "../../../components/component/BasicDialog";

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

function SupportFAQ() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // 테이블 정렬 요청 핸들러 함수
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 테이블 정렬 함수
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const [paramCategories, setParamCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [paramChannels, setParamChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('All');
  const [faqs, setFaqs] = useState([]);
  const [isOpenEditFaq, setIsOpenEditFaq] = useState(false);
  const [faqToEdit, setFaqToEdit] = useState({});
  const [isOpenAddFaq, setIsOpenAddFaq] = useState(false);
  const [checkedFaqs, setCheckedFaqs] = useState([]);
  const [faqTableData, setFaqTableData] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [searchTextInput, setSearchTextInput] = useState('');
  const [searchOption, setSearchOption] = useState('All');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/support/faq/filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        let map = body.data.categories?.map(category => ({id: category, value: category, option: category, type: 'text'}));
        setParamCategories([{ id: 'All', value: 'All', option: 'All', type: 'text' }, ...map]);
        map = body.data.channels?.map(channel => ({id: channel, value: channel, option: channel, type: 'text'}));
        setParamChannels([{ id: 'All', value: 'All', option: 'All', type: 'text' }, ...map]);
      }
    })
  }, []);

  const searchFaq = (category, channel) => {
    fetch(`${BASE_URL}/support/faq?category=${category}&channel=${channel}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        // eslint-disable-next-line
        body.data.map((faq, index) => {
          faq.order = index + 1;
        })
        setFaqs(body.data);
      }
    })
  }

  useEffect(() => {
    searchFaq(selectedCategory, selectedChannel);
  }, [selectedCategory, selectedChannel]);

  useEffect(() => {
    setFaqTableData(faqs);
  }, [faqs]);

  const openEditFaq = (faqIdx) => {
    // fetch faq data by faqIdx
    fetch(`${BASE_URL}/support/faq/${faqIdx}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setFaqToEdit(body.data);
      }
    })
    setIsOpenEditFaq(true);
  }

  useEffect(() => {
    if (searchOption === 'Title') {
      setFaqTableData(faqs.filter(
          (item) => item.question.toLowerCase().includes(searchTextInput.toLowerCase())));
    } else if (searchOption === 'Display') {
      setFaqTableData(faqs.filter(
          (item) => item.displayFlag.toLowerCase().includes(searchTextInput.toLowerCase())));
    } else {
      setFaqTableData(faqs.filter(
          (item) => item.question.toLowerCase().includes(
                  searchTextInput.toLowerCase())
              || item.displayFlag.toLowerCase().includes(
                  searchTextInput.toLowerCase())));
    }
    setPage(1);
    //eslint-disable-next-line
  }, [searchTextInput]);

  const onClickCheckBox = (e) => {
    let checkedList = [...checkedFaqs];
    if (e.target.checked) {
      checkedList.push(parseInt(e.target.value));
    } else {
      checkedList = checkedList.filter((item) => item !== parseInt(e.target.value));
    }
    if (faqTableData.length === checkedList.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setCheckedFaqs(checkedList);
  }

  useEffect(() => {
    if (faqTableData.length === 0) {
      setAllChecked(false);
    } else if (faqTableData.length === checkedFaqs.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [faqTableData, checkedFaqs]);

  useEffect(() => {
    setCheckedFaqs([...checkedFaqs.filter(idx => faqTableData.map(item => item.idx).includes(idx))]);
    // eslint-disable-next-line
  }, [faqTableData]);

  const onClickCheckAll = (e) => {
    if (e.target.checked) {
      setCheckedFaqs(faqTableData.map((item) => item.idx));
      setAllChecked(true);
    } else {
      setCheckedFaqs([]);
      setAllChecked(false);
    }
  }

  const getIfChecked = (idx) => {
    return checkedFaqs.includes(idx);
  }

  const handleConfirmDelete = () => {
    if (checkedFaqs.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select the FAQ you want to delete.');
      setAlertOpen(true);
      return;
    }
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to delete the selected FAQs?');
  }

  const deleteFaqs = () => {
    setConfirmOpen(false);

    fetch(`${BASE_URL}/support/faq`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(checkedFaqs),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info');
        setAlertMessage('FAQs deleted successfully.');
        setAlertOpen(true);
        searchFaq(selectedCategory, selectedChannel);
        setCheckedFaqs([]);
      }
    })
  }

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (faqTableData.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(faqTableData.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [faqTableData, page, itemsPerPage]);

  // 테이블의 행 데이터 (드래그 앤 드롭 가능)
  const SupportFaqTdRows = sortRows([...paginatedData], orderBy, order);

  // Button Data
  const buttons = [
    {
      minWidth: '78px',
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      onClick: handleConfirmDelete
    },
    {
      minWidth: '66px',
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Add',
      isIcon: false,
      onClick: () => setIsOpenAddFaq(true),
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: deleteFaqs
    },
  ];

  return (
      <div className='tab-panel'>
        <div className='panel-wrap'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <div className='form-area left'>
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <SelectBox
                        id='tab1-Category'
                        size='large'
                        shrink={true}
                        label='Category'
                        selectOptions={paramCategories}
                        disabled={false}
                        placeholder=''
                        selected={selectedCategory}
                        onChange={(newValue) => setSelectedCategory(newValue)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                        id='tab1-channel'
                        size='large'
                        shrink={true}
                        label='Channel'
                        selectOptions={paramChannels}
                        disabled={false}
                        placeholder=''
                        selected={selectedChannel}
                        onChange={(newValue) => setSelectedChannel(newValue)}
                    />
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                <Box className='left-area column'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      {faqTableData.length}
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SelectBox
                        id='select-search'
                        size='medium'
                        selectOptions={NotificationSearchOption}
                        label=''
                        maxWidth='180px'
                        disabled={false}
                        placeholder=''
                        selected={searchOption}
                        onChange={setSearchOption}
                    />

                    <SearchField
                        id='search-2'
                        size='medium'
                        maxWidth='230px'
                        placeholder='Please enter a search term'
                        value={searchTextInput}
                        onChange={(e) => setSearchTextInput(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={buttons}/>
                </Box>
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table'
                         stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {SupportFaqTh.map((column) => (
                          <TableCell
                            key={column.id}
                            className='th'
                            align={column.align}
                            sx={{width: column.width}}
                          >
                          {column.checkbox ? (
                            <>
                              <CheckBox
                                label=''
                                value='select-all'
                                checked={allChecked}
                                inputProps={{
                                  'aria-label': 'select all',
                                }}
                                onChange={onClickCheckAll}
                              />
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </>
                          ) : (
                            <>
                              {column.sortable ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                            </>
                          )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {SupportFaqTdRows.map((td, index) => (
                          <TableRow className='tr' key={td.id || index}>
                            {SupportFaqTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  {colIndex === 0 ? (
                                      <CheckBox label={td[`${column.id}`]} value={td.idx}
                                                onChange={onClickCheckBox}
                                                checked={getIfChecked(td.idx)} />
                                  ) : colIndex === 3 ? (
                                      <TextLink href='' text={td[`${column.id}`]}
                                                onClick={() => openEditFaq(td.idx)}/>
                                  ) : colIndex === 5 ? (
                                      td[`${column.id}`].length > 0 ? (
                                          <Button
                                            color=''
                                            type='only-icon bg-no'
                                            size='medium'
                                            label=''
                                            isIcon={true}
                                            Icon={FileIcon}
                                            sx={{cursor: 'initial'}}
                                          />
                                      ) : (
                                        ''
                                      )
                                  ) : (
                                    td[`${column.id}`]
                                  )}
                                </TableCell>
                            ))}
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              <Pagination count={Math.ceil(faqTableData.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
            </div>
          </Box>
        </div>
        <PopEditFAQ openEditFaq={isOpenEditFaq}
                    closeEditFaq={() => setIsOpenEditFaq(false)}
                    faqToEdit={faqToEdit}
                    afterSave={() => searchFaq(selectedCategory, selectedChannel)}/>
        <PopAddFAQ openAddFaq={isOpenAddFaq}
                   closeAddFaq={() => setIsOpenAddFaq(false)}
                   afterSave={() => searchFaq(selectedCategory, selectedChannel)}/>
        <Dialog
            open={isAlertOpen}
            handleClose={handleAlertClose}
            closeX={false}
            size='small'
            title={alertTitle}
            contentTitle={false}
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
        <Dialog
            open={isConfirmOpen}
            handleClose={handleConfirmClose}
            closeX={false}
            size='small'
            title='Confirm'
            contentTitle={false}
            bottomDivider={false}
            contentText={confirmMessage}
            buttons={confirmDialogButtons}
        />
      </div>
  )
}

export default SupportFAQ;