import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Button from 'components/component/BasicButton';
import SearchField from 'components/component/BasicSearchField';
import * as XLSX from 'xlsx';

const ExtraFilter = React.memo(({ total, data, type, searchPlaceholder, onChange }) => {
  const handleDownload = (event) => {
    event.preventDefault();

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${type}_data`);
    XLSX.writeFile(workbook, `${type}_data.xlsx`);
  };

  return (
    <Box className='white-box-content-top-area'>
      <Box className='left-area'>
        <Box className='total-count'>
          <Box component='span' className='label'>
            Total
          </Box>
          <Box component='span' className='value'>
            {total}
          </Box>
        </Box>
      </Box>
      <Box className='right-area'>
        <Button
            color='tertiary'
            type='outline'
            size='small'
            label='Excel Download'
            isIcon={false}
            autosize
            onClick={(event) => handleDownload(event)}
        />
        <SearchField
            id='extra-filter-search'
            size='medium'
            maxWidth='230px'
            placeholder={searchPlaceholder}
            aria-label='Search field'
            onChange={onChange}
        />
      </Box>
    </Box>
  )
});

ExtraFilter.propTypes = {
  total: PropTypes.number.isRequired,
  searchPlaceholder: PropTypes.string,
};

ExtraFilter.defaultProps = {
  searchPlaceholder: 'Search...',
};

export default ExtraFilter;