import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import SearchField from 'components/component/BasicSearchField';
import Pagination from 'components/component/BasicPagination';
import CustomImage from 'components/component/BasicImage';
import SelectBox from 'components/component/BasicSelectBox';
import CheckBox from 'components/component/BasicCheckBox';
import TextField from 'components/component/BasicTextField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CustomBasicMenu from 'components/component/BasicMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getRequest } from 'utils/Request';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'utils/Valid';
import { patchRequest } from 'utils/Request';

import { styled } from '@mui/material/styles';

import {
  Box,
  Typography,
  Link,
  Grid,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// ICON
import { BtnArrowRight } from 'components/component/BasicIcon';

// Data
import {
  CommonManualTh,
  ScheduledCommonManualTh,
} from 'pages/Organization/TabCategory/data/TabCategoryData';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import { RefreshIcon } from 'components/component/BasicIcon';

const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];

// 메인 컨텐츠
function CommonManual(props) {
  const { message } = props;
  const { tabIdx, categoryIdx, tab, category, countryOptions, providerOptions } = message;

  // 검색 Country 필터
  const [countryFilter, setCountryFilter] = useState(countryOptions[0].value);
  // 검색 provider 필터
  const [providerFilter, setProviderFilter] = useState('All');
  // 검색 Content Type 필터
  const [contentTypeFilter, setContentTypeFilter] = useState(category.contentType && category.contentType.split(',')[0]);
  // 검색 Content ID 필터
  const [contentIdFilter, setContentIdFilter] = useState('');
  // 검색 Content Name 필터
  const [contentNameFilter, setContentNameFilter] = useState('');

  // 편성 가능한 Content 데이터 목록
  const [searchContent, setSearchContent] = useState([]);
  const [displaySearchContent, setDisplaySearchContent] = useState([]);
  const [checkedSearchContent, setCheckedSearchContent] = useState([]);

  // 편성된 Content 데이터 목록
  const [resultContent, setResultContent] = useState([]);
  const [displayResultContent, setDisplayResultContent] = useState([]);
  const [checkedResultContent, setCheckedResultContent] = useState([]);

  // Search, Result 그리드에 연관검색 키워드 input
  const [searchContentFilter, setSearchContentFilter] = useState('');
  const [resultContentFilter, setResultContentFilter] = useState('');

  const navigate = useNavigate();

  // 카테고리에 설정된 contentType으로 Select Box Option 값 생성
  const [contentTypeOptions, setContentTypeOptions] = useState([]);
  useEffect(() => {
    if (category.contentType) {
      const contentTypeSplit = category.contentType.split(',').map(n => {
        const newItem = {
          id: n,
          value: n,
          option: n,
          type: 'text',
        }
        return newItem;
      });
      setContentTypeOptions(contentTypeSplit);
    }
    // eslint-disable-next-line
  }, [category]);

  // 검색 Country 필터 값 변경 시 해당 국가의 데이터를 호출
  useEffect(() => {
    resultData();
    // eslint-disable-next-line
  }, [countryFilter]);

  // Search Content가 변경될 경우 화면 렌더링 객체인 Display에 데이터 셋팅 및 Result에 존재하는 Content는 비활성화
  useEffect(() => {
    if (searchContent) {
      setDisplaySearchContent(searchContent);
      setExistResult(searchContent, resultContent);
    }
    // eslint-disable-next-line
  }, [searchContent]);

  // Result Content 변경 시 화면 렌더링 객체인 Display데 데이터 셋팅
  useEffect(() => {
    if (resultContent) {
      setDisplayResultContent(resultContent);
      setExistResult(searchContent, resultContent);
    }
    // eslint-disable-next-line
  }, [resultContent]);

  // Search Content 연관 검색 시
  useEffect(() => {
    if (searchContentFilter) {
      const filteredData = searchContent.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchContentFilter));
      });
      setDisplaySearchContent([...filteredData]);
    } else {
      setDisplaySearchContent(searchContent);
    }
    // eslint-disable-next-line
  }, [searchContentFilter]);

  // Result Content 연관 검색 시
  useEffect(() => {
    if (resultContentFilter) {
      const filteredData = resultContent.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(resultContentFilter));
      });
      setDisplayResultContent([...filteredData]);
    } else {
      setDisplayResultContent(resultContent);
    }
    // eslint-disable-next-line
  }, [resultContentFilter]);

  /* 정보 수정 시 객체에 값을 변경 한다 Start */
  // Country Filter 값
  const onChangeCountry = (value) => {
    setCountryFilter(value);
  };
  // Content Type Filter 값
  const onChangeContentType = (value) => {
    setContentTypeFilter(value);
  };
  // Provider Filter 값
  const onChangeProvider = (value) => {
    setProviderFilter(value);
  };
  // Content ID 값
  const onChangeContentId = (event) => {
    const value = event.target.value;
    setContentIdFilter(value);
  };
  // Content Name 값
  const onChangeContentName = (event) => {
    const value = event.target.value;
    setContentNameFilter(value);
  };
  /* 정보 수정 시 객체에 값을 변경 한다 End */

  // resultContent에 searchContent와 같은 컨텐츠가 있을 경우 비활성화
  const setExistResult = (searchContent, resultContent) => {
    if (!isEmpty(searchContent)) {
      searchContent.forEach((n) => {
        n.existResultFlag = isExistResult(n, resultContent);
      });
      setSearchContent(searchContent);
    }
  };

  // searchContent와 resultContent 사이에 동일한 컨텐츠가 있으면 true, 없으면 false
  const isExistResult = (searchContent, resultContent) => {
    return !isEmpty(resultContent.find((n) => searchContent.id === n.content_id && searchContent.cp === n.cp_id));
  };

  // Reset 버튼 클릭
  const resetFilter = () => {
    setCountryFilter(countryOptions[0].value);
    setProviderFilter('All');
    setContentTypeFilter(category.contentType.split(',')[0]);
    setContentIdFilter('');
    setContentNameFilter('');
  };

  // Search 버튼 클릭
  const searchData = async () => {
    const body = await getRequest(`/home-app/category/manual/content?country=${countryFilter}&type=${contentTypeFilter}&cp=${providerFilter}&id=${contentIdFilter}&name=${contentNameFilter}&status=S-T`, null);
    if (body && body.result === 'SUCCESS') {
      let content = body.data.map((item, index) => {
        const {
          cp,
          cp_idx,
          genre,
          id,
          idx,
          image,
          name,
        } = item;
        return {
          cp: cp,
          cp_idx: cp_idx,
          genre: genre,
          id: id,
          idx: idx,
          image: image,
          name: name,
          existResultFlag: false,
        };
      });
      setSearchContent(content);
    }
  };

  const resultData = async () => {
    const body = await getRequest(`/home-app/category/${categoryIdx}/result?country=${countryFilter}`, null);
    if (body && body.result === 'SUCCESS') {
      setResultContent(body.data);
    }
  };

  // history back 이전 페이지
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/TabCategoryList', { replace: true, state:
      { move: true,
        targetComponent: 'CategoryList',
        message:
        {
          tabIdx: tabIdx,
        }
      }
    });
  };

  // SearchContent 연관 검색창에 검색어 입력 시
  const handleSearchContent = (value) => {
    setSearchContentFilter(value);
  };

  // ResultContent 연관 검색창에 검색어 입력 시
  const handleResultContent = (value) => {
    setResultContentFilter(value);
  };

  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Category List','Category Organize'],
  };

  const FilterButtons = [
    {
      color: 'tertiary',
      type: 'outline icon',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      autosize: false,
      minWidth: '107px',
      onClick: resetFilter,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: false,
      minWidth: '120px',
      onClick: searchData,
    },
  ];

  // Search Content 목록에서 check box 선택
  const handleSearchContentCheckBox = (event, row) => {
    let selectContent = [];
    // 최상단 전체 선택 check box 클릭 시
    if (event.target.checked && event.target.name === "select-all") {
      selectContent = [...searchContent];
    // 최상단 전체 선택 해제 check box 클릭 시
    } else if (!event.target.checked && event.target.name === "select-all") {
      selectContent = [];
    // 개별 탭 check box 클릭 시
    } else if (event.target.checked) {
      selectContent = [...checkedSearchContent, row];
    // 개별 탭 check box 해제 시
    } else if (!event.target.checked) {
      selectContent = checkedSearchContent.filter(
        (n) => n.id !== row.id
      );
    }
    setCheckedSearchContent(selectContent);
  };

  // Result Content 목록에서 check box 선택
  const handleResultContentCheckBox = (event, row) => {
    let selectContent = [];
    // 최상단 전체 선택 check box 클릭 시
    if (event.target.checked && event.target.name === "select-all") {
      selectContent = [...resultContent];
    // 최상단 전체 선택 해제 check box 클릭 시
    } else if (!event.target.checked && event.target.name === "select-all") {
      selectContent = [];
    // 개별 탭 check box 클릭 시
    } else if (event.target.checked) {
      selectContent = [...checkedResultContent, row];
    // 개별 탭 check box 해제 시
    } else if (!event.target.checked) {
      selectContent = checkedResultContent.filter(
        (n) => n.content_id !== row.content_id
      );
    }
    setCheckedResultContent(selectContent);
  };

  // Search Content 체크박스 체크 후 Result Content로 편성 시 이벤트 동작
  const handleAddContentClick = () => {
    if (checkedSearchContent.find(n => resultContent.find(n2 => n2.content_id === n.id && n2.cp_id === n.cp))) {
      alert('content is already exist !')
      return;
    } else if (checkTvShowSearies(checkedSearchContent) || checkDuplicateSeriesList(checkedSearchContent)) {
      alert('Only one season allowed per series!');
      return;
    }

    const addContent = checkedSearchContent.map((n, i) => {
      // Result Content의 Order 값을 생성
      let insertOrder = Math.max(...resultContent.map(n2 => n2.result_order + 1));
          insertOrder = insertOrder === -Infinity ? 1 : insertOrder;

      // Result Content에 매핑
      const newItem = {
        content_id: n.id,
        country_code: countryFilter,
        provider_idx: n.cp_idx,
        cp_id: n.cp,
        genre: n.genre,
        image: n.image,
        type: contentTypeFilter,
        name: n.name,
        result_order: insertOrder + i,
      }
      return newItem;
    });
    setResultContent(resultContent.concat(addContent));
    setCheckedSearchContent([]);
  }

  const checkTvShowSearies = rows => {
    return rows.find(
      n =>
        resultContent.filter(r => {
          if (r.category_idx) {
            return r.provider_idx === n.cp_idx && r.series_id === n.series_id && n.type === 'tvshow-series';
          } else {
            return r.cp_idx === n.cp_idx && r.series_id === n.series_id && n.type === 'tvshow-series';
          }
        })[0],
    );
  };

  const checkDuplicateSeriesList = data => {
    const tvShowData = data.filter(n => n.type === 'tvshow-series');
    const lookup = tvShowData.reduce((a, e) => {
      a[e.series_id] = ++a[e.series_id] || 0;
      return a;
    }, {});

    return tvShowData.filter(e => lookup[e.series_id]).length >= 2;
  };

  // Save 버튼 클릭 시 Result Content를 저장
  const saveResultContent = async () => {
    const newResult = resultContent
      .map(n => {
        const { country_code, cp_idx, provider_idx, type, content_id, result_order, name } = n;
        let categoryResult = { categoryIdx: categoryIdx, countryCode: country_code };
        categoryResult.providerIdx = cp_idx || provider_idx;
        categoryResult.contentType = type;
        categoryResult.contentId = content_id;
        categoryResult.resultOrder = result_order;
        categoryResult.name = name;
        return categoryResult;
      });

    const body = await patchRequest(`/home-app/category/${categoryIdx}/result?country=${countryFilter}`, newResult, null);
    if (body && body.result === 'SUCCESS') {
      setResultContent([]);
      resultData();
    }
  };

  // Delete 버튼 클릭 시 Result Content에서 제거
  const removeResultContent = () => {
    if (!isEmpty(checkedResultContent)) {
      const removeContent = resultContent.filter(result => {
        let flag = true;
        checkedResultContent.forEach(check => {
          if (check.content_id === result.content_id) {
            flag = false;
          }
        })
        return flag;
      })
      setResultContent(removeContent);
      setCheckedResultContent([]);
    }
  };

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // Paging 처리
  useEffect(() => {
    if (displaySearchContent && displaySearchContent.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displaySearchContent.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [displaySearchContent, page, itemsPerPage]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displaySearchContent].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplaySearchContent([...sortedData]);
  };

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(displayResultContent);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    items.forEach((item, index) => {
      item.result_order = index + 1;
      item.checked = false;
    });

    setDisplayResultContent([...items]);
  };

  const Buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Top',
      autosize: true,
    },
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Bottom',
      autosize: true,
    },
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      autosize: true,
      onClick: removeResultContent,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Save',
      autosize: true,
      onClick: saveResultContent,
    },
  ];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link'
                to='#'
                onClick={handleBack}
              >
                Category Organize
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {/* Category Type */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Category type</Typography>
                    <Typography className='value'>{category.categoryType && category.categoryType}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Content Type */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Content Type</Typography>
                    <Typography className='value'>{category.contentType}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Category Name */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Category</Typography>
                    <Typography className='value'>{category.categoryName}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Tab name */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Tab</Typography>
                    <Typography className='value'>{tab.tabName}</Typography>
                  </ListItem>
                </React.Fragment>
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='select-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={countryOptions}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={countryFilter}
                      onChange={onChangeCountry}
                    />
                  </Box>
                  {/* Provider */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='select-cp-name'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      required={false}
                      selectOptions={[{id: 'All', value: 'All', option: 'All', type: 'text'}, ...providerOptions]}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={providerFilter}
                      onChange={onChangeProvider}
                    />
                  </Box>
                  {/* Content Type */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='select-Content'
                      size='large'
                      shrink={true}
                      label='Content Type'
                      required={false}
                      selectOptions={contentTypeOptions}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={contentTypeFilter}
                      onChange={onChangeContentType}
                    />
                  </Box>
                  {/* ID */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      id='textfield-id-1'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='ID'
                      value={contentIdFilter}
                      onChange={onChangeContentId}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                  {/* Name */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      id='textfield-name-1'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Name'
                      value={contentNameFilter}
                      onChange={onChangeContentName}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'>
                <CustomButtonGroup buttons={FilterButtons} />
              </Box>
            </Box>
          </Box>

          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {/* Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Result for Content</Typography>
                      <Typography component='p' className='select-count-wrap'>
                        Select <span className='set-color'>5</span> item
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {displaySearchContent.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label='Ch.no or ch.Name'
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                        onChange={(event) => handleSearchContent(event.target.value)}
                      />
                    </Box>
                    <Box className='right-area'>
                      <Button
                        color='tertiary'
                        type='box'
                        size='small'
                        label='Select Ch Input'
                        isIcon={true}
                        autosize
                        Icon={BtnArrowRight}
                        iconPos='right'
                        onClick={() => handleAddContentClick()}
                      />
                    </Box>
                  </Box>

                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader sx={{ width: 'auto' }}>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {CommonManualTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        checked={searchContent.length !== 0 && displaySearchContent.length === checkedSearchContent.length}
                                        onChange={handleSearchContentCheckBox}
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '24px' }}
                                      />
                                      {index === 0 && (
                                        <CustomBasicMenu
                                          type='icon'
                                          title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                          options={selPageData}
                                        />
                                      )}
                                    </Box>
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        {/* custom-table-row tr disabled - result에 편성된 search content는 비활성화 처리 */}
                        <TableBody className='tbody'>
                          {paginatedData.map((row, index) => (
                            <TableRow key={row.id || index} className={`custom-table-row${row.existResultFlag ? ' tr disabled' : ''}`}>
                              {CommonManualTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align} sx={{ height: '88px' }}>
                                  {colIndex === 0 ? (
                                    <CheckBox label={row.cp}
                                              value={row.id}
                                              disabled={row.existResultFlag}
                                              onChange={(event) => handleSearchContentCheckBox(event, row)}
                                              checked={checkedSearchContent.some((value) => String(value.id).includes(row.id))}
                                    />
                                  ) : colIndex === 1 ? (
                                    row.id
                                  ) : colIndex === 2 ? (
                                    row.name
                                  ) : colIndex === 3 ? (
                                    <CustomImage
                                      src={row.image}
                                      fit={false}
                                      alt=''
                                      wrapWidth='82px'
                                      wrapHeight='64px'
                                      rounded
                                    />
                                  ) : colIndex === 4 ? (
                                    row.genre
                                  ) : (
                                    row.idx
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  {/* Pagination */}
                  <Pagination count={Math.ceil(displaySearchContent.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Scheduled Content List</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {displayResultContent && displayResultContent.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label='Ch.no or ch.Name'
                        size='medium'
                        maxWidth='230px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                        onChange={(event) => handleResultContent(event.target.value)}
                      />
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={Buttons} justifyContent='center' />
                    </Box>
                  </Box>

                  {/* Selected Content */}
                  {/* 241011 no-page-number 클래스 추가하였습니다. */}
                  <Box className='content-inner-wrap no-page-number'>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table'>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {ScheduledCommonManualTh.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box display='flex' alignContent='center'>
                                          <IconDraggable className='icon-draggable' sx={{ flexShrink: 0 }} />
                                          <CheckBox
                                            label=''
                                            value='select-all'
                                            checked={resultContent.length !== 0 && displayResultContent.length === checkedResultContent.length}
                                            onChange={handleResultContentCheckBox}
                                            defaultChecked={false}
                                            inputProps={{
                                              'aria-label': 'select all',
                                            }}
                                            sx={{ marginRight: '4px' }}
                                          />
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'desc'}
                                              onClick={() => handleRequestSort(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}
                              <TableBody className='tbody'>
                                {displayResultContent.map((row, index) => (
                                  <Draggable key={row.content_id || index} draggableId={row.content_id || index.toString()} index={index}>
                                    {(provided) => (
                                      <TableRow
                                        className='tr'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {ScheduledCommonManualTh.map((column, colIndex) => (
                                          <TableCell
                                            key={column.id}
                                            className='td'
                                            align={column.align}
                                            sx={{ height: '88px' }}
                                          >
                                            {colIndex === 0 ? (
                                              <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                <IconDraggable className='icon-draggable' />
                                                <CheckBox
                                                  label={row.result_order}
                                                  value={row.content_id}
                                                  defaultChecked={false}
                                                  onChange={(event) => handleResultContentCheckBox(event, row)}
                                                  checked={checkedResultContent.some((value) => String(value.content_id).includes(row.content_id))}
                                                />
                                              </Box>
                                            ) : colIndex === 1 ? (
                                              row.type
                                            ) : colIndex === 2 ? (
                                              row.cp_id
                                            ) : colIndex === 3 ? (
                                              row.content_id
                                            ) : colIndex === 4 ? (
                                              row.name
                                            ) : colIndex === 5 ? (
                                              <CustomImage
                                                src={row.image}
                                                fit={false}
                                                alt=''
                                                wrapWidth='81px'
                                                wrapHeight='64px'
                                                rounded
                                              />
                                            ) : colIndex === 6 ? (
                                              row.genre
                                            ) : (
                                              row.content_id
                                            )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default CommonManual;
