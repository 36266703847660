import React, {useEffect, useState} from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomImage from 'components/component/BasicImage';
import Pagination from 'components/component/BasicPagination';
import TextLink from 'components/component/BasicTextLink';
import SearchField from 'components/component/BasicSearchField';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { styled } from '@mui/material/styles';
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import {useNavigate} from "react-router-dom";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const imagePrefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;

// 메인 컨텐츠
function CodeManagementVodGenre() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayVodGenreCodes].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayVodGenreCodes([...sortedData])
  };

  const [vodGenreCodes, setVodGenreCodes] = useState([]);
  const [displayVodGenreCodes, setDisplayVodGenreCodes] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);

  const navigate = useNavigate();

  const navigateVodGenreDetail = (genreCode) => {
    navigate('/CodeManagement', {
      replace: true, state:
        { move: true,
          targetComponent: 'VODGenreDetail',
          message:
            {
              genreCode: genreCode
            }
        }
    })
  }

  useEffect(() => {
    fetch(`${BASE_URL}/code-management/vod-genre`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setVodGenreCodes(body.data);
      }
    })
  }, []);

  useEffect(() => {
    setDisplayVodGenreCodes(vodGenreCodes);
  }, [vodGenreCodes]);

  useEffect(() => {
    setDisplayVodGenreCodes(vodGenreCodes.filter(
        (item) => item.genreName.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.genreCode.toLowerCase().includes(searchTextInput.toLowerCase())));
    setPage(1);
    // eslint-disable-next-line
  }, [searchTextInput]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayVodGenreCodes.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayVodGenreCodes.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayVodGenreCodes, page, itemsPerPage]);

  return (
      <Box className='white-box white-box-wrap'>
        <div className='box-content'>
          <Box className='white-box-content-top-area' alignItems='flex-end'>
            <Box className='left-area column'>
              <Box className='total-count'>
                <Box component='span' className='label'>
                  Total
                </Box>
                <Box component='span' className='value'>
                  {displayVodGenreCodes.length}
                </Box>
              </Box>
              <Box display='flex' gap={1}>
                <SearchField placeholder='Please enter a search term' maxWidth='230px'
                             value={searchTextInput}
                             onChange={(e) => setSearchTextInput(e.target.value)} />
              </Box>
            </Box>

            <Box className='right-area'></Box>
          </Box>
          <Box className='content-inner-wrap'>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table' aria-label='table' stickyHeader>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    <TableCell key={'genreImage'} className='th' align={'left'} sx={{ width: '16%' }}>
                      Genre Image
                    </TableCell>
                    <TableCell key={'genreName'} className='th' align={'left'} sx={{ width: '30%' }}>
                      <TableSortLabel
                          active={orderBy === 'genreName'}
                          direction={orderBy === 'genreName' ? order : 'desc'}
                          onClick={() => handleRequestSort('genreName')}
                      >
                        Genre Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'genreCode'} className='th' align={'left'} sx={{ width: '20%' }}>
                      <TableSortLabel
                          active={orderBy === 'genreCode'}
                          direction={orderBy === 'genreCode' ? order : 'desc'}
                          onClick={() => handleRequestSort('genreCode')}
                      >
                        Genre Code
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'lastChgDate'} className='th' align={'left'} sx={{ width: 'auto' }}>
                      <TableSortLabel
                          active={orderBy === 'lastChgDate'}
                          direction={orderBy === 'lastChgDate' ? order : 'desc'}
                          onClick={() => handleRequestSort('lastChgDate')}
                      >
                        Last Change Date
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* table Body */}
                <TableBody className='tbody'>
                  {paginatedData.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        <TableCell key={'genreImage'} className='td' align={'left'}>
                          <Box display='flex'>
                            <CustomImage
                                src={td['genreImage'] === '' ? null : imagePrefix + td['genreImage']}
                                fit={false}
                                alt=''
                                wrapWidth='96px'
                                wrapHeight='64px'
                                rounded
                            />
                          </Box>
                        </TableCell>
                        <TableCell key={'genreName'} className='td' align={'left'}>
                          <TextLink href='' text={td['genreName']} onClick={() => navigateVodGenreDetail(td[`genreCode`])} />
                        </TableCell>
                        <TableCell key={'genreCode'} className='td' align={'left'}>
                          {td['genreCode']}
                        </TableCell>
                        <TableCell key={'lastChgDate'} className='td' align={'left'}>
                          {td['lastChgDate']}
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
          </Box>

          {/* Pagination */}
          <Pagination count={Math.ceil(displayVodGenreCodes.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
        </div>
      </Box>
  );
}

export default CodeManagementVodGenre;
