import React, {useEffect, useState} from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import { Box, Typography, Link, Grid } from '@mui/material';
import DetailItem from './component/DetailItem';
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import {getRequest} from "utils/Request";
import DetailImageItem from "./component/DetailImageItem";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

const vodDetailTabs = [
  { label: 'Basic Information', badgeType: '' },
  { label: 'Media & Image', badgeType: '' },
  { label: 'Season Information', badgeType: '' },
];

// ************************** 메인 컨텐츠 ***********************************
const MetadataVodDetail = React.memo((props) => {
  const { darkMode, message} = props;
  const { contentId, countryCode, tabValue, filters } = message;
  const [vodData, setVodData] = useState(null);
  const [isSearchSuccessful, setIsSearchSuccessful] = useState(false);
  const [activeButton, setActiveButton] = useState(0);
  const navigate = useNavigate();
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Metadata',
    normalMenu: ['VOD Detail'],
  };

  // Tab
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (message) {
      searchVodDetail();
    }
    // eslint-disable-next-line
  }, [message]);

  const searchVodDetail = async () => {
    setIsSearchSuccessful(false);
    const body = await getRequest(`/metadata/vod/${contentId}?countryCode=${countryCode}`, null);
    if (body && body.result === 'SUCCESS') {
      setVodData(body.data);
    }
  };

  useEffect(() => {
    if (vodData) {
      setIsSearchSuccessful(true);
    }
  }, [vodData]);

  const handleUrlButtonClick = (url) => {
    window.open(url, '_blank');
  };

  const handleBack = (event) => {
    event.preventDefault();
    navigate('/Metadata', {
      replace: true, state: {
        move: true,
        targetComponent: 'Metadata',
        type: 'common',
        message: {
          filters: filters,
          tabValue: tabValue,
        }
      }
    });
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Link className='title link' href='#' onClick={handleBack}>
                VOD Detail
              </Link>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        <Box className='content-wrap vod-detail' component='div'>
          {isSearchSuccessful && (
          <Box className='white-box white-box-wrap single-box'>
            <div className='tab-content-area'>
              <CustomTabs tabStyle='type-01' value={value} handleChange={handleChange} tabs={vodDetailTabs} />
              <div className='box-content'>
                <Box className='detail-desc-container'>*Items modified in CMP are highlighted in red.</Box>
                {value === 0 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        <Box className='detail-list-container'>
                          <Grid className='section' container columnSpacing={2.5}>
                            <Grid item xs={6}>
                              <DetailItem label='VOD ID ' value={vodData.vodId} />
                              <DetailItem label='Type' value={vodData.type} />
                              <DetailItem label='Title' value={vodData.title} />
                              <DetailItem label='CP Name' value={vodData.cpName} />
                            </Grid>
                            <Grid item xs={6}>
                              <DetailImageItem xs={8} label='' uiType='BASIC'
                                               src={vodData.imageUrl}
                                               uiWidth='600px'
                                               uiHeight='200px'
                                               actualWidth={vodData.imageWidth}
                                               actualHeight={vodData.imageHeight}/>
                            </Grid>
                          </Grid>
                          <DetailItem label='Description' value={vodData.description} xs={12} />
                          <Grid container columnSpacing={2.5}>
                            <DetailItem
                              label='License Start Date'
                              className=''
                              value={vodData.licenseStartDate}
                              maxThWidth={true}
                              xs={6}
                            />
                            <DetailItem
                              label='License End Date'
                              value={vodData.licenseEndDate}
                              maxThWidth={true}
                              xs={6}
                            />
                            <DetailItem label='Start date time' value={vodData.startDateTime} xs={6} />
                            <DetailItem label='End date time' value={vodData.endDateTime} xs={6} />
                            <DetailItem label='Duration' value={vodData.duration} xs={6} />
                            <DetailItem label='Release Date' value={vodData.releaseDate} xs={6} />
                            <DetailItem label='Genre Code' value={vodData.genreCode} xs={6} />
                            <DetailItem label='Episode Number' value={vodData.episodeNumber} xs={6} />
                            <DetailItem label='Keywords' value={vodData.keywords} xs={6} />
                            <DetailItem label='Studio' value={vodData.studio} xs={6} />
                            <DetailItem label='Caption Flag' value={vodData.captionFlag} xs={12} />
                            <DetailItem label='Rating' value={vodData.rating} xs={12} />
                          </Grid>
                          <Grid
                            container
                            component='section'
                            className='section-container'
                            columnSpacing={2.5}
                            sx={{ mt: 5 }}
                          >
                            <Grid item xs={12}>
                              <Typography className='sub-title'>Credit List</Typography>
                            </Grid>
                            {vodData.creditList.map((credit, index) => (
                              <DetailItem label='Role/Name' className='' value={credit} xs={6} />
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
                {value === 1 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        <Box className='media-detail-container'>
                          <Box className='media-inner-wrap'>
                            <Box className='media-wrap'>
                              <Box className='title-area'>
                                <Typography variant='h3' className='category-title'>
                                  Media
                                </Typography>
                              </Box>
                              <Box className='media-item-wrap'>
                                <Box className='item-info-area'>
                                  <Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Stream Container Type :</Typography>
                                      <Typography className='text value'>{vodData.streamContainerType}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Codec :</Typography>
                                      <Typography className='text value'>{vodData.codec}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Drmflag :</Typography>
                                      <Typography className='text value'>{vodData.drmFlag}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>MediaUrl :</Typography>
                                      <Typography className='text value'>{vodData.mediaUrl}</Typography>
                                    </Box>
                                  </Box>
                                  <Box className='link-url'>
                                    <Button
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='Media URL'
                                      isIcon={true}
                                      Icon={BtnTeritaryOutlineRight}
                                      iconPos='right'
                                      target='_blank'
                                      onClick={() => handleUrlButtonClick(vodData.mediaUrl)}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box className='media-wrap'>
                              <div className='title-area'>
                                <Typography variant='h3' className='category-title'>
                                  Image List
                                </Typography>
                              </div>
                              <Grid container direction="column" spacing={5}>
                                {vodData.stillCutImageList.map((item, index) => (
                                  <Grid item xs={6} key={index}>
                                    <Box className='media-item-wrap'>
                                      <div className='item-image-area'>
                                        <DetailImageItem xs={8} label='' uiType='TYPE_BASIC'
                                                          src={vodData.stillCutImageList.length > 0 ? (vodData.stillCutImageList[index].replacedUrl || vodData.stillCutImageList[index].originUrl || '') : ''}
                                                          uiWidth='768px'
                                                          uiHeight='432px'
                                                          actualWidth={vodData.stillCutImageList.length > 0 ? vodData.stillCutImageList[index].width : 0}
                                                          actualHeight={vodData.stillCutImageList.length > 0 ? vodData.stillCutImageList[index].height : 0}
                                                          type={vodData.stillCutImageList[index].type}
                                                          ratio={vodData.stillCutImageList[index].ratio}
                                                          group={vodData.stillCutImageList[index].imageGroup}
                                                          />
                                      </div>
                                    </Box>
                                  </Grid>
                                ))}
                                <Grid container direction="row" spacing={5} sx={{ margin: '0px' }}>
                                  {vodData.thumbnailImageList.map((item, index) => (
                                    <Grid item xs={6} key={index}>
                                      <Box className='media-item-wrap'>
                                        <div className='item-image-area'>
                                          <DetailImageItem xs={8} label='' uiType='TYPE_BASIC'
                                                           src={vodData.thumbnailImageList.length > 0 ? (vodData.thumbnailImageList[index].replacedUrl || vodData.thumbnailImageList[index].originUrl || '') : ''}
                                                           uiWidth='768px'
                                                           uiHeight='432px'
                                                           actualWidth={vodData.thumbnailImageList.length > 0 ? vodData.thumbnailImageList[index].width : 0}
                                                           actualHeight={vodData.thumbnailImageList.length > 0 ? vodData.thumbnailImageList[index].height : 0}
                                                           type={vodData.thumbnailImageList[index].type}
                                                           ratio={vodData.thumbnailImageList[index].ratio}
                                                           group={vodData.thumbnailImageList[index].imageGroup}
                                                           />
                                        </div>
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                                <Grid container direction="row" spacing={5} sx={{ margin: '0px' }}>
                                  {vodData.posterImageList.map((item, index) => (
                                    <Grid item xs={6} key={index}>
                                      <Box className='media-item-wrap'>
                                        <div className='item-image-area'>
                                          <DetailImageItem xs={8} label='' uiType='TYPE_BASIC'
                                                           src={vodData.posterImageList.length > 0 ? (vodData.posterImageList[index].replacedUrl || vodData.posterImageList[index].originUrl || '') : ''}
                                                           uiWidth='360px'
                                                           uiHeight='540px'
                                                           actualWidth={vodData.posterImageList.length > 0 ? vodData.posterImageList[index].width : 0}
                                                           actualHeight={vodData.posterImageList.length > 0 ? vodData.posterImageList[index].height : 0}
                                                           type={vodData.posterImageList[index].type}
                                                           ratio={vodData.posterImageList[index].ratio}
                                                           group={vodData.posterImageList[index].imageGroup}
                                                           />
                                        </div>
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
                {value === 2 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        <Box container className='detail-list-container full-width'>
                          <Grid container component='section' className='section-container' columnSpacing={2.5}>
                            <Grid item xs={12}>
                              <Typography className='sub-title'>Series</Typography>
                            </Grid>
                            <DetailItem label='Series ID' className='' value={vodData.seriesId} xs={12} />
                            <DetailItem label='Series Title' className='' value={vodData.seriesTitle} xs={12} />
                            <Grid item xs={12} sx={{ mt: 4 }}>
                              <Typography className='sub-title'>Season</Typography>
                            </Grid>
                            <DetailItem label='Season ID' className='' value={vodData.seasonId} xs={12} />
                            <DetailItem label='Season Number' className='' value={vodData.seasonNumber} xs={6} />
                            <DetailItem
                              label='Total Episodes'
                              className=''
                              value={vodData.totalEpisodes}
                              xs={6}
                            />
                            <DetailItem label='Genre Code' className='' value={vodData.seasonGenreCode} xs={6} />
                            <DetailItem label='Caption Flag' className='' value={vodData.seasonCaptionFlag} xs={6} />
                            <Grid item xs={12} sx={{ mt: 4 }}>
                              <Typography className='sub-title'>Multi Language List</Typography>
                            </Grid>
                            <Grid item xs={12} mt={3}>
                              <Box className='toggle-btn-wrap' display='flex' gap={1}>
                                {vodData.seasonMultiLanguageList.map((item, index) => (
                                  <>
                                    <Button
                                      key={index}
                                      color={activeButton === index ? 'primary' : 'tertiary'}
                                      type={activeButton === index ? 'box' : 'outline'}
                                      size='small'
                                      label={item.language}
                                      isIcon={false}
                                      autosize
                                      onClick={() => handleButtonClick(index)}
                                    />

                                    <Grid item xs={12} sx={{ mt: 4 }}>
                                      <DetailItem label='Title' className='' value={item.title} xs={6}/>
                                      <DetailItem label='Description' className='' value={item.description} xs={6}/>
                                    </Grid>
                                  </>
                                ))}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box className='media-detail-container'>
                          <Box className='media-inner-wrap'>
                            <Box className='media-wrap'>
                              <div className='title-area'>
                                <Typography variant='h3' className='category-title'>
                                  Season Image List
                                </Typography>
                              </div>
                              <Grid container spacing={3}>
                                {vodData.seasonImageList.map((item, index) => (
                                  <Grid item xl={6} key={index}>
                                    <Box className='media-item-wrap'>
                                      <div className='item-image-area'>
                                        <DetailImageItem xs={8} label='' uiType='TYPE_BASIC'
                                                          src={vodData.seasonImageList && vodData.seasonImageList.length > 0 ? vodData.seasonImageList[index].originUrl : ''}
                                                          uiWidth='360px'
                                                          uiHeight='540px'
                                                          actualWidth={vodData.seasonImageList && vodData.seasonImageList.length > 0 ? vodData.seasonImageList[index].width : 0}
                                                          actualHeight={vodData.seasonImageList && vodData.seasonImageList.length > 0 ? vodData.seasonImageList[index].height : 0}
                                                          type={vodData.seasonImageList[index].type}
                                                          ratio={vodData.seasonImageList[index].ratio}
                                                          group={vodData.seasonImageList[index].imageGroup}
                                        />
                                      </div>
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Box>
          )}
        </Box>
      </Box>
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
    </Box>
  );
});

MetadataVodDetail.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    contentId: PropTypes.string.isRequired,
    countryCode: PropTypes.string,
    filters: PropTypes.object,
    tabValue: PropTypes.number,
  }).isRequired,
};

export default MetadataVodDetail;