import React, {useState} from 'react';
import Dialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';

import { Grid, Box } from '@mui/material';
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";

// 메인 컨텐츠
function PopCodeManagementAddTab({ onClose, afterSave, tabs }) {

  const handleClose = () => {
    onClose();
  };

  const [tabCodeInput, setTabCodeInput] = useState('');
  const [tabNameInput, setTabNameInput] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const createTab = () => {
    if (tabs && tabs.map(tab => tab.tabCode).includes(tabCodeInput)) {
      setAlertTitle('Error');
      setAlertMessage('Tab Code already exists.');
      setAlertOpen(true);
      return;
    }

    const tab = {
      tabCode: tabCodeInput,
      tabName: tabNameInput
    }

    fetch(`${BASE_URL}/code-management/tab`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(tab)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success');
        setAlertMessage('Tab has been created successfully.');
        setAlertOpen(true);
        handleClose();
      }
    })
    .finally(() => {
      afterSave();
    })
  }

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      disabled: false,
      onClick: createTab
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div>
      <Dialog
        open={true}
        closeX={true}
        title='Add Tab' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className=''
        contentTextProps={
          <Grid container item xs={12}>
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='outlined'
                  size='large'
                  placeholder='ex) T-LIVE'
                  label='Tab Code'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={tabCodeInput}
                  onChange={(e) => setTabCodeInput(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component='div' className='field-box'>
                <TextField
                  type='outlined'
                  size='large'
                  placeholder='ex) Live'
                  label='Tab Name'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={tabNameInput}
                  onChange={(e) => setTabNameInput(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        }
        buttons={dialogButtons}
      />
      <Dialog
          open={isAlertOpen}
          handleClose={handleAlertClose}
          closeX={false}
          size='small'
          title={alertTitle}
          contentTitle={false}
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
      />
    </div>
  );
}

export default PopCodeManagementAddTab;
