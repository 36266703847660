import React, { useState, useEffect, useRef } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import Snackbar from 'components/component/BasicSnackbar';
import {
  Box,
  Typography,
} from '@mui/material';

import ProgramScheduleGrid from "./ProgramScheduleGrid";
import ProgramScheduleList from "./ProgramScheduleList";
import ChannelMap from "./ChannelMap";
import ProgramScheduleListInPartner from "./PartnerProgramScheduleList";

// Tab Name
const tabs = [{ label: 'Program Schedule(Grid)' }, { label: 'Program Schedule(List)' }, { label: 'Channel Map' }];

function ChannelStatus(props) {
  const { darkMode, isPartnerPageShow, currentUser } = props;

  const optionMenu = {
    gnbMenu: 'Inspection',
    lnbMenu: 'Channel Status',
  };

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const bodyAreaRef = useRef(null);
  const currentTimeRef = useRef(null);

  // 시간선
  useEffect(() => {
    const updateHeight = () => {
      if (bodyAreaRef.current && currentTimeRef.current) {
        const bodyAreaHeight = bodyAreaRef.current.offsetHeight + (40 - 16); //헤더높이 - time-text 높이
        currentTimeRef.current.style.height = `${bodyAreaHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [tabValue]);

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container inspect' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Channel Status
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        {/*  //BreadCrumb Area */}

        <Box className='content-wrap ' component='div'>
          <div className='tab-container'>
            {
              isPartnerPageShow ? (
                <ProgramScheduleListInPartner currentUser={currentUser} />
              ) : (
                <>
                  <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />

                  {tabValue === 0 && (
                    <ProgramScheduleGrid />
                  )}

                  {/* Program Schedule(List) */}
                  {tabValue === 1 && (
                    <ProgramScheduleList />
                  )}

                  {/* Channel Map */}
                  {tabValue === 2 && (
                    <ChannelMap />
                  )}
                  {/* Channel Count */}
                </>
              )
            }
          </div>
        </Box>
      </Box>

      {/*
       Toast Pop-Up
       - severity: default, error, warning, info, success
      */}
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
    </Box>
  );
}

export default ChannelStatus;
