export const SupportTitle = [
  {
    id: 'idx',
    label: 'No',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'category',
    label: 'Category',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'title',
    label: 'Title',
    align: 'left',
    width: '50%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'crtDate',
    label: 'Registration Date',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
];
