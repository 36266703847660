import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CheckBox from 'components/component/BasicCheckBox';
import CustomImage from 'components/component/BasicImage';
import SelectBox from 'components/component/BasicSelectBox';
import Pagination from 'components/component/BasicPagination';
import Loading from 'components/component/Loading';
import Dialog from 'components/component/BasicDialog';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

import {
  StatusOption,
  EmulateTitle,
} from 'pages/Monitoring/Emulate/data/EmulateData';

import { BASE_URL } from 'constants';
import { getHeaders } from 'utils/Header';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

function Emulate(props) {
  const { serviceCountry } = props;

  const [regionList, setRegionList] = useState([]);
  const [regionPlatformList, setRegionPlatformList] = useState([]);

  const [deviceTypeFilter, setDeviceTypeFilter] = useState([]);
  const [regionFilter, setRegionFilter] = useState([]);
  const [countryFilter, setCountryFilter] = useState([]);
  const [channelMapFilter, setChannelMapFilter] = useState([]);
  const [platformVersionFilter, setPlatformVersionFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [tabNameFilter, setTabNameFilter] = useState([]);
  const [categoryNameFilter, setCategoryNameFilter] = useState([]);

  const [selectedDeviceType, setSelectedDeviceType] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedChannelMap, setSelectedChannelMap] = useState('All');
  const [selectedPlatformVersion, setSelectedPlatformVersion] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedTabName, setSelectedTabName] = useState('');
  const [selectedCategoryName, setSelectedCategoryName] = useState('');

  const [tabCategory, setTabCategory] = useState([]);

  // 서버에서 받은 원본 데이터
  const [originalEmulateData, setOriginalEmulateData] = useState([]);

  // 화면에서 보여 줄 데이터 (ChannelMap이 All이 아닌 경우 필터링)
  const [displayFilteredEmulateData, setDisplayFilteredEmulatedData] = useState([]);

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // 서버에서 가져온 데이터가 없는 경우 체크
  const [isEmptyData, setEmptyData] = useState(false);

  // 로딩바를 보여 줄 때 사용
  const [isDisplayEmulateData, setDisplayEmulateData ] = useState(false);

  const optionMenu = {
    gnbMenu: 'Monitoring',
    lnbMenu: 'Emulate',
  };

  useEffect(() => {
    getRegionList();
    getRegionPlatformList();
  }, []);

  useEffect(() => {
    if (serviceCountry && regionList.length > 0 && regionPlatformList.length > 0) {
      // deviceTypeFilter
      const deviceTypeOption = [...new Set(regionList.map(item => item.deviceType))].sort().reverse();
      const tempDeviceType = deviceTypeOption.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      }));
      setDeviceTypeFilter([...tempDeviceType]);
      setSelectedDeviceType(deviceTypeOption[0]);

      // statusFilter
      setStatusFilter(StatusOption);
      setSelectedStatus(StatusOption[0].value);
    }
  }, [serviceCountry, regionList, regionPlatformList]);

  useEffect(() => {
    if (selectedDeviceType && regionList.length > 0) {
      const regionOption = regionList
        .filter(item => item.deviceType.toLowerCase() === selectedDeviceType.toLowerCase())
        .map(item => item.region);
      const tempRegion = regionOption.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      }));
      setRegionFilter([...tempRegion]);
      setSelectedRegion(regionOption[0]);
      setSelectedCountry('');
    }
    // eslint-disable-next-line
  }, [selectedDeviceType]);

  useEffect(() => {
    if (selectedRegion && regionList.length > 0 && regionPlatformList.length > 0) {
      const countryOption = regionList
        .filter(item => item.deviceType.toLowerCase() === selectedDeviceType.toLowerCase() && item.region.toLowerCase() === selectedRegion.toLowerCase())
        .map(item => item.countryCode)
        .flatMap(countryCode => countryCode.split(','));
      const tempCountry = countryOption.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      }));
      setCountryFilter([...tempCountry]);
      setSelectedCountry(countryOption[0]);

      const platformVersionOption = regionPlatformList
        .filter(item => item.deviceType.toLowerCase() === selectedDeviceType.toLowerCase() && item.region.toLowerCase() === selectedRegion.toLowerCase())
        .map(item => item.platformVersion);
      const tempPlatformVersion = platformVersionOption.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      }));
      setPlatformVersionFilter([...tempPlatformVersion]);
      setSelectedPlatformVersion(platformVersionOption[0]);
    }
    // eslint-disable-next-line
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedCountry && serviceCountry) {
      const channelMapIdOption = serviceCountry
        .filter(item => item.countryCode === selectedCountry)
        .flatMap(item => item.channelMapList)
        .filter(item => item.deviceType.toLowerCase() === selectedDeviceType.toLowerCase())
        .map(item => item.channelMapId);

      let tempChannelMapId = [];
      if (channelMapIdOption.length === 0) {
        tempChannelMapId = ['All'].map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text'
        }));
      } else {
        tempChannelMapId = [
          {
            id: 'All',
            value: 'All',
            option: 'All',
            type: 'text',
          },
          ...channelMapIdOption.map(item => ({
            id: item,
            value: item,
            option: item,
            type: 'text',
          })),
        ];
      }

      setChannelMapFilter([...tempChannelMapId]);
      setSelectedChannelMap(tempChannelMapId[0].value);
    }
    // eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedPlatformVersion && selectedStatus) {
      setSelectedChannelMap('All');
      getTabCategory();
    }
    // eslint-disable-next-line
  }, [selectedPlatformVersion, selectedStatus]);

  useEffect(() => {
    if (tabCategory.length > 0) {
      const uniqueTabNames = [...new Set(tabCategory.map(item => item.tabName))];

      const tempTabName = uniqueTabNames.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      }));

      setTabNameFilter([...tempTabName]);
      setSelectedTabName(tempTabName[0].value);
    }
  }, [tabCategory]);

  useEffect(() => {
    if (selectedTabName && tabCategory.length > 0) {
      const filteredCategories = tabCategory.filter(item => item.tabName === selectedTabName);

      const tempCategoryName = filteredCategories.map(item => ({
        id: item.categoryName,
        value: item.categoryName,
        option: item.categoryName,
        type: 'text'
      }));

      setCategoryNameFilter([...tempCategoryName]);
      setSelectedCategoryName(tempCategoryName[0].value);
    }
    // eslint-disable-next-line
  }, [selectedTabName]);

  useEffect(() => {
    if (selectedCategoryName && selectedChannelMap) {
      const categoryData = tabCategory.filter(item => item.tabName === selectedTabName)?.find(item => item.categoryName === selectedCategoryName);
      getCategoryData(categoryData);
    }
    // eslint-disable-next-line
  }, [selectedCategoryName, selectedChannelMap]);

  // Paging 처리
  useEffect(() => {
    if (displayFilteredEmulateData && displayFilteredEmulateData.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayFilteredEmulateData.slice(startIndex, endIndex);

      setPaginatedData([...newPaginatedData]);
      setDisplayEmulateData(true);
    }
  }, [displayFilteredEmulateData, page, itemsPerPage]);

  useEffect(() => {
    setDisplayFilteredEmulatedData([...originalEmulateData])
  }, [originalEmulateData]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...originalEmulateData].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setOriginalEmulateData([...sortedData]);
  };

  const getRegionList = () => {
    fetch(`${BASE_URL}/home-app/region-list`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setRegionList(body.data);
      } else {
        throw new Error(`[Emulate] region-list request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const getRegionPlatformList = () => {
    fetch(`${BASE_URL}/home-app/region-platform-list`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setRegionPlatformList(body.data);
      } else {
        throw new Error(`[Emulate] region-platform-list request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const getTabCategory = () => {
    setTabNameFilter([]);
    setSelectedTabName('');
    setCategoryNameFilter([]);
    setSelectedCategoryName('');
    setDisplayFilteredEmulatedData([]);

    setDisplayEmulateData(false);

    const param = `status=${selectedStatus}&deviceType=${selectedDeviceType}&region=${selectedRegion}&platformVersion=${selectedPlatformVersion}`;

    fetch(`${BASE_URL}/emulate/getTabCategory?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setTabCategory(body.data.tabCategory);
        if (body.data.tabCategory.length === 0) {
          setEmptyData(true);
        }
      } else {
        throw new Error(`[Emulate] tab-category request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    })
    .finally(() => {
      setDisplayEmulateData(true);
    });
  };

  const getCategoryData = async (categoryData) => {
    setDisplayFilteredEmulatedData([]);
    setOriginalEmulateData([]);
    setDisplayEmulateData(false);
    setEmptyData(false);

    const param = `categoryType=${categoryData.categoryType}&mappingType=${categoryData.mappingType}&categoryIdx=${categoryData.categoryIdx}&country=${selectedCountry}&contentType=${categoryData.contentType}&channelMap=${selectedChannelMap}&status=${selectedStatus}&deviceType=${selectedDeviceType}&region=${selectedRegion}&platformVersion=${selectedPlatformVersion}`;

    fetch(`${BASE_URL}/emulate/getEmulateData?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setOriginalEmulateData(body.data.emulateData);
        if (body.data.emulateData.length === 0) {
          setEmptyData(true);
        }
      } else {
        throw new Error(`[Emulate] ${body.message}`);
      }
    })
    .catch(error => {
      setEmptyData(true);
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    })
    .finally(() => {
      setDisplayEmulateData(true);
    });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleDeviceTypeChange = (value) => {
    setSelectedRegion('');
    setSelectedCountry('');
    setSelectedPlatformVersion('');
    setSelectedDeviceType(value);
  };

  const handleRegionChange = (value) => {
    setSelectedCountry('');
    setSelectedPlatformVersion('');
    setSelectedRegion(value);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handlePlatformVersionChange = (value) => {
    setSelectedPlatformVersion(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Typography component='p' className='title'>
                Emulate
              </Typography>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>
        <Box className='content-wrap' component='div'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-6'>
                  {/* Device Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Device Type'
                      size='large'
                      shrink={true}
                      label='Device Type'
                      selectOptions={deviceTypeFilter}
                      disabled={false}
                      selected={selectedDeviceType}
                      onChange={handleDeviceTypeChange}
                    />
                  </Box>
                  {/* Region */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Region'
                      size='large'
                      shrink={true}
                      label='Region'
                      selectOptions={regionFilter}
                      disabled={false}
                      selected={selectedRegion}
                      onChange={handleRegionChange}
                    />
                  </Box>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Country'
                      size='large'
                      shrink={true}
                      label='Country'
                      selectOptions={countryFilter}
                      disabled={false}
                      selected={selectedCountry}
                      onChange={handleCountryChange}
                    />
                  </Box>
                  {/* Channel Map */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Channel Map'
                      size='large'
                      shrink={true}
                      label='Channel Map'
                      selectOptions={channelMapFilter}
                      disabled={false}
                      selected={selectedChannelMap}
                      onChange={setSelectedChannelMap}
                    />
                  </Box>
                  {/* Platform Version */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Platform Version'
                      size='large'
                      shrink={true}
                      label='Platform Version'
                      selectOptions={platformVersionFilter}
                      disabled={false}
                      selected={selectedPlatformVersion}
                      onChange={handlePlatformVersionChange}
                    />
                  </Box>
                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Status'
                      size='large'
                      shrink={true}
                      label='Status'
                      selectOptions={statusFilter}
                      disabled={false}
                      selected={selectedStatus}
                      onChange={handleStatusChange}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>
          <Box className='white-box white-box-wrap single-box'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area' alignItems={'center'}>
                  <SelectBox
                    id='Tab Name'
                    size='large'
                    shrink={true}
                    label='Tab Name'
                    maxWidth={'220px'}
                    selectOptions={tabNameFilter}
                    disabled={false}
                    selected={selectedTabName}
                    onChange={setSelectedTabName}
                  />
                  <SelectBox
                    id='Category Name'
                    size='large'
                    shrink={true}
                    label='Category Name'
                    maxWidth={'220px'}
                    selectOptions={categoryNameFilter}
                    disabled={false}
                    selected={selectedCategoryName}
                    onChange={setSelectedCategoryName}
                  />
                  <Typography className='sm-sub-text'>*The actual screen and design style are different.</Typography>
                </Box>
              </Box>
              {isDisplayEmulateData ? (
                <Box className='content-inner-wrap'>
                  <CustomTableContainer className='table-container'>
                    <Table className='custom-table' aria-label='table' stickyHeader>
                      <TableHead className='thead'>
                        <TableRow className='tr'>
                          {EmulateTitle.map((column) => (
                            <TableCell
                              key={column.id}
                              className='th'
                              align={column.align}
                              sx={{ width: column.width }}
                            >
                              {column.checkbox ? (
                                <Box display='flex' alignContent='center'>
                                  <CheckBox
                                    label=''
                                    value='select-all'
                                    defaultChecked={false}
                                    inputProps={{
                                      'aria-label': 'select all',
                                    }}
                                  />
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                </Box>
                              ) : (
                                <>
                                  {column.sortable ? (
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  ) : (
                                    column.label
                                  )}
                                </>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className='tbody'>
                        {isEmptyData ? (
                          <NoRecords>
                            No records to display
                          </NoRecords>
                        ) : (
                          paginatedData.map((td, index) => (
                            <TableRow className='tr' key={td.id || index}>
                              {EmulateTitle.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  {colIndex === 0 ? (
                                    td.resultOrder
                                  ) : colIndex === 1 ? (
                                    td.providerName
                                  ) : colIndex === 2 ? (
                                    td.contentType
                                  ) : colIndex === 3 ? (
                                    td.contentId
                                  ) : colIndex === 4 ? (
                                    td.contentName
                                  ) : colIndex === 5 ? (
                                    td.seasonNumber
                                  ) : colIndex === 6 ? (
                                    td.episodeNumber
                                  ) : colIndex === 7 ? (
                                    <CustomImage
                                      src={td.imageUrl}
                                      fit={false}
                                      alt=''
                                      wrapWidth='122px'
                                      wrapHeight='64px'
                                      rounded
                                    />
                                  ) : colIndex === 8 ? (
                                    td.genreName
                                  ) : colIndex === 9 ? (
                                    td.keyword
                                  ) : colIndex === 10 ? (
                                    td.lastChgDate?.replace('T', ' ')
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </CustomTableContainer>
                  <Pagination count={Math.ceil(originalEmulateData.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
                </Box>
              ) : (
                <Loading />
              )}
            </div>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isAlertOpen} // boolean 값으로 설정
        handleClose={handleAlertClose}
        closeX={false}
        size='small' // large, medium, small
        title={alertTitle} // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </Box>
  );
}

Emulate.propTypes = {
  serviceCountry: PropTypes.array.isRequired
};
const mapStateToProps = state => ({
  serviceCountry: state.common.country.serviceCountry
});

export default connect(mapStateToProps)(Emulate);
