import React, {useEffect, useState} from 'react';
import Button from 'components/component/BasicButton';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';

import { AddPlus, DelMinus } from 'components/component/BasicIcon';

import { Grid, Typography } from '@mui/material';

import { Box } from '@mui/system';
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";

// 메인 컨텐츠
function PopAddMultilingualInformation({ onClose, genreCode, multiLangList, afterSave }) {

  const handleClose = () => {
    onClose();
  };

  const [counter, setCounter] = useState(1);
  const [inputFields, setInputFields] = useState([{id: 1, language: '', value: ''}]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (multiLangList && multiLangList.length > 0) {
      fetch(`${BASE_URL}/code-management/all-lang-code`, {
        method: 'GET',
        headers: getHeaders()
      })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const existingLangCodes = multiLangList.map(item => item.language);
          const options = body.data.filter(
              langCode => !existingLangCodes.includes(langCode)).map(
              lang => ({id: lang, value: lang, option: lang, type: 'text'}))
          setLanguageOptions(options);
          if (options && options.length > 0) {
            setInputFields([{id: 1, language: options[0].value, value: ''}]);
          }
        }
      })
    }
  }, [multiLangList]);

  const handleAddField = () => {
    setInputFields([...inputFields, {id: counter + 1, language: languageOptions.length > 0 ? languageOptions[0].value : '', value: ''}]);
    setCounter(counter + 1);
  }

  const handleRemoveField = (id) => {
    setInputFields(inputFields.filter(field => field.id !== id));
  }
  const handleInputChange = (id, field, value) => {
    const newInputFields = inputFields.map(inputField => {
      if (inputField.id === id) {
        return {...inputField, [field]: value};
      }
      return inputField;
    });
    setInputFields(newInputFields);
  }

  const handleSave = () => {
    const genre = {
      genreCode: genreCode,
      multiLanguageList: inputFields.map(item => ({language: item.language, value: item.value}))
    }
    fetch(`${BASE_URL}/code-management/vod-genre/multi-lang`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(genre)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success')
        setAlertMessage('Multilingual Information has been saved successfully.');
        setAlertOpen(true);
        onClose();
      }
    })
    .finally(() => {
      afterSave();
    })
  }

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: handleSave
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Add Multilingual Information' // Popup Title
        noticeBgBox={false}
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-tc-addCategory'
        contentTextProps={
          <>
            <Box component='section' className='section-container'>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12}>
                  <Typography className='field-label bold required'>Genre Name</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box className='scroll-area'>
                    {inputFields.map((inputField, index) => (
                      <Box component='div' className='field-box flex-start' key={index}>
                        <SelectBox
                          id='select-tab-name-region-1'
                          size='large'
                          maxWidth='170px'
                          required={true}
                          selectOptions={languageOptions}
                          disabled={false}
                          label=''
                          placeholder='Language'
                          shrink
                          selected={inputField.language}
                          onChange={(newValue) => handleInputChange(inputField.id, 'language', newValue)}
                        />

                        <TextField
                          type='outlined'
                          size='large'
                          required
                          placeholder='ex)action'
                          defaultValue=''
                          label=''
                          autoComplete='off'
                          InputLabelProps={{ shrink: false }}
                          value={inputField.value}
                          onChange={(e) => handleInputChange(inputField.id, 'value', e.target.value)}
                        />

                        {inputField.id === 1 && (
                          <Button
                            color='tertiary'
                            type='outline icon'
                            size='medium'
                            label=''
                            isIcon={true}
                            Icon={AddPlus}
                            iconPos='center'
                            autosize
                            onClick={handleAddField}
                          />
                        )}
                        {inputField.id > 1 && (
                          <Button
                            color='tertiary'
                            type='outline icon'
                            size='medium'
                            label=''
                            isIcon={true}
                            Icon={DelMinus}
                            iconPos='center'
                            autosize
                            onClick={() => handleRemoveField(inputField.id)}
                          />
                        )}
                      </Box>
                    ))}

                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        }
        buttons={dialogButtons}
      />
      <Dialog
          open={isAlertOpen}
          handleClose={handleAlertClose}
          closeX={false}
          size='small'
          title={alertTitle}
          contentTitle={false}
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
      />
    </div>
  );
}

export default PopAddMultilingualInformation;
