import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import { BASE_URL } from 'constants';
import { getHeaders } from 'utils/Header';

function PopDepthHomeShelf({ onClose, onSave, countryCode, deviceType, regionCountry, sdkversionList }) {
  const [optionSdkVersion, setOptionSdkVersion] = useState([]);
  const [optionPlatformVersion, setOptionPlatformVersion] = useState([]);
  const [tabCategoryCodeList, setTabCategoryCodeList] = useState([]);
  const [optionTabCode, setOptionTabCode] = useState([]);
  const [optionCategoryCode, setOptionCategoryCode] = useState([]);
  const [selectedSdkVersion, setSelectedSdkVersion] = useState('');
  const [selectedPlatformVersion, setSelectedPlatformVersion] = useState('');
  const [selectedTabCode, setSelectedTabCode] = useState('');
  const [selectedCategoryCode, setSelectedCategoryCode] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    setOptionSdkVersion(sdkversionList);
    if (sdkversionList.length > 0) {
      setSelectedSdkVersion(sdkversionList[0].value);
    }
    if (regionCountry) {
      const transformedDeviceType = deviceType.replace('webOS', '').toLowerCase();
      let platformVersionList = regionCountry.filter(item => item.countryCode === countryCode && item.deviceType === transformedDeviceType).flatMap(item => item.platformVersionList);
      setOptionPlatformVersion(platformVersionList.map(item => ({
        id: item.platformVersion,
        value: item.platformVersion,
        option: item.platformVersion,
        type: 'text'
      })));
      if (platformVersionList.length > 0) {
        setSelectedPlatformVersion(platformVersionList[0].platformVersion);
      }
    }
    handleTabCategory();
    // eslint-disable-next-line
  }, [regionCountry, countryCode, deviceType, sdkversionList]);

  const handleTabCategory = async () => {
    const transformedDeviceType = deviceType.replace('webOS', '').toLowerCase();
    fetch(`${BASE_URL}/sdepth/tabCategory/countryCode/${countryCode}/deviceType/${transformedDeviceType}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setTabCategoryCodeList(body.data);
        if (body.data.length > 0) {
          setOptionTabCode(body.data.map(item => ({
            id: item.tabCode,
            value: item.tabCode,
            option: item.tabCode,
            type: 'text'
          })));
          setSelectedTabCode(body.data[0].tabCode);
          if (body.data[0].categoryList.length > 0) {
            setOptionCategoryCode(body.data[0].categoryList.map(item => ({
              id: item.categoryCode,
              value: item.categoryCode,
              option: item.categoryCode,
              type: 'text'
            })));
            setSelectedCategoryCode(body.data[0].categoryList[0].categoryCode);
          }
        }
      } else {
        throw new Error(`[PopDepthHomeShelf] handleTabCategory request error`);
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    }).finally(() => {
    });
  };

  const handleSaveClick = () => {
    onSave({ selectedSdkVersion, selectedPlatformVersion, selectedTabCode, selectedCategoryCode });
  };

  const handleSelectedSdkVersion = (sdkVersion) => {
    setSelectedSdkVersion(sdkVersion);
  };

  const handleSelectedPlatformVersion = (platformVersion) => {
    setSelectedPlatformVersion(platformVersion);
  };

  const handleSelectedTabCode = (tabCode) => {
    setSelectedTabCode(tabCode);
    const selectedTab = tabCategoryCodeList.find(item => item.tabCode === tabCode);
    if (selectedTab) {
      setOptionCategoryCode(selectedTab.categoryList.map(category => ({
        id: category.categoryCode,
        value: category.categoryCode,
        option: category.categoryCode,
        type: 'text'
      })));
      setSelectedCategoryCode(selectedTab.categoryList[0].categoryCode);
    } else {
      setOptionCategoryCode([]);
    }
  };

  const handleSelectedCategoryCode = (categoryCode) => {
    setSelectedCategoryCode(categoryCode);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: onClose,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: handleSaveClick,
    },
  ];

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Add 2nd Depth/Home shelf Setting' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={onClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-home-shelf'
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              {/* SDK Version */}
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='slt-country'
                    size='large'
                    shrink={true}
                    label='SDK Version'
                    selectOptions={optionSdkVersion}
                    selected={selectedSdkVersion}
                    required={true}
                    disabled={false}
                    placeholder='select'
                    onChange={handleSelectedSdkVersion}
                  />
                </Box>
              </Grid>
              {/* Platform Version */}
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='slt-country'
                    size='large'
                    shrink={true}
                    required={true}
                    label='Platform Version'
                    selectOptions={optionPlatformVersion}
                    selected={selectedPlatformVersion}
                    disabled={false}
                    placeholder='select'
                    onChange={handleSelectedPlatformVersion}
                  />
                </Box>
              </Grid>
              {/* Tab Detail* */}
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-content-type'
                    fullWidth
                    size='large'
                    width='100%'
                    selectOptions={optionTabCode}
                    selected={selectedTabCode}
                    required={true}
                    disabled={false}
                    label='Tab Detail'
                    shrink
                    onChange={handleSelectedTabCode}
                    placeholder='select'
                  />
                </Box>
              </Grid>
              {/* Category Detail */}
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-content-type'
                    fullWidth
                    size='large'
                    width='100%'
                    selectOptions={optionCategoryCode}
                    selected={selectedCategoryCode}
                    required={true}
                    disabled={false}
                    label='Category Detail'
                    shrink
                    onChange={handleSelectedCategoryCode}
                    placeholder='select'
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
      <Dialog
        open={isAlertOpen}
        handleClose={handleAlertClose}
        closeX={false}
        size='small'
        title={alertTitle}
        contentTitle={false}
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </div>
  );
}
PopDepthHomeShelf.propTypes = {
  regionCountry : PropTypes.array.isRequired
};
const mapStateToProps = state => ({
  regionCountry: state.common.country.regionCountry
});

export default connect(mapStateToProps)(PopDepthHomeShelf);
