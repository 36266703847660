import React, {useEffect, useState} from 'react';
import Button from 'components/component/BasicButton';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { styled } from '@mui/material/styles';
import PopAddRegion from "./PopAddRegion";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function CodeManagementHomeAppRegion({ regionList, deviceType, paramDeviceType, afterSave }) {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayRegionCodes].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayRegionCodes([...sortedData])
  };

  const [regionCodes, setRegionCodes] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState('');
  const [displayRegionCodes, setDisplayRegionCodes] = useState([]);
  const [addRegionOpen, setAddRegionOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    setRegionCodes(regionList.filter(region => region.deviceType === deviceType)
    .map(region => ({region: region.region, countryList: region.countryList})));
  }, [deviceType, regionList]);

  useEffect(() => {
    setDisplayRegionCodes(regionCodes);
  }, [regionCodes]);

  useEffect(() => {
    setDisplayRegionCodes(regionCodes.filter(
        (item) => item.region.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.countryList.join(', ').toLowerCase().includes(searchTextInput.toLowerCase())));
    setPage(1);
    // eslint-disable-next-line
  }, [searchTextInput]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayRegionCodes.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayRegionCodes.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayRegionCodes, page, itemsPerPage]);

  return (
      <Box className='white-box white-box-wrap'>
        <div className='box-content'>
          <Box className='white-box-content-top-area' alignItems='flex-end'>
            <Box className='left-area column'>
              <Box className='total-count'>
                <Box component='span' className='label'>
                  Total
                </Box>
                <Box component='span' className='value'>
                  {displayRegionCodes.length}
                </Box>
              </Box>
              <Box display='flex' gap={1}>
                <SearchField placeholder='Please enter a search term'
                             maxWidth='230px' value={searchTextInput}
                             onChange={(e) => setSearchTextInput(e.target.value)}/>
              </Box>
            </Box>

            <Box className='right-area'>
              <Button color='primary' type='box' size='medium' label='Add'
                      isIcon={false} onClick={() => setAddRegionOpen(true)} autosize/>
            </Box>
          </Box>
          <Box className='content-inner-wrap'>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table' aria-label='table' stickyHeader>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    <TableCell key={'region'} className='th' align={'left'} sx={{ width: '30%' }}>
                      <TableSortLabel
                          active={orderBy === 'region'}
                          direction={orderBy === 'region' ? order : 'desc'}
                          onClick={() => handleRequestSort('region')}
                      >
                        Region
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'countryList'} className='th' align={'left'} sx={{ width: 'auto' }}>
                      <TableSortLabel
                          active={orderBy === 'countryList'}
                          direction={orderBy === 'countryList' ? order : 'desc'}
                          onClick={() => handleRequestSort('countryList')}
                      >
                        Region
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* table Body */}
                <TableBody className='tbody'>
                  {paginatedData.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        <TableCell key={'region'} className='td' align={'left'}>
                          {td[`region`]}
                        </TableCell>
                        <TableCell key={'countryList'} className='td' align={'left'}>
                          {td[`countryList`].join(', ')}
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
            {/* // Table */}
          </Box>

          {/* Pagination */}
          <Pagination count={Math.ceil(displayRegionCodes.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
        </div>
        {addRegionOpen && (
            <PopAddRegion onClose={() => setAddRegionOpen(false)} paramDeviceType={paramDeviceType} afterSave={afterSave} />
        )}
      </Box>
  );
}

export default CodeManagementHomeAppRegion;
