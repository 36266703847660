import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import CheckBox from 'components/component/BasicCheckBox';
import CustomImage from 'components/component/BasicImage';
import Button from 'components/component/BasicButton';
import {styled} from '@mui/material/styles';

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

const CustomTableContainer = styled(TableContainer)(({theme}) => ({
  height: '100%',
  overflowY: 'auto',
}));

const ChannelTableHead = React.memo(
  ({columns, order, orderBy, handleRequestSort}) => (
    <TableHead className='thead'>
      <TableRow className='tr'>
        {columns.map((column) => (
          <TableCell key={column.id} className='th' align={column.align}
                      sx={{width: column.width}}>
            {column.checkbox ? (
              <>
                <CheckBox
                  label=''
                  value='select-all'
                  defaultChecked={false}
                  inputProps={{'aria-label': 'select all'}}
                />
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'desc'}
                  onClick={() => handleRequestSort(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </>
            ) : (
              <>
                {column.sortable ? (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order
                        : 'desc'}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                ) : (
                    column.label
                )}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  ));

const ChannelTableBody = React.memo(({columns, rows, type, handleAction}) => (
  rows.length === 0 ? (
    <NoRecords>
      No records to display
    </NoRecords>
  ) : (
    <>
      <TableBody className='tbody'>
        {rows.map((row, index) => (
          <TableRow className='tr' key={row.id || index}>
            {columns.map((column, colIndex) => (
              <TableCell key={column.id} className='td' align={column.align}>
                {colIndex === 0 ? (
                  <CustomImage src={row[column.id]} fit={false} alt=''
                                wrapWidth='198px' wrapHeight='64px' rounded/>
                ) : colIndex === 6 ? (
                  <>
                    {type === 'edit' && (
                      <Button
                        color='tertiary'
                        type='outline'
                        size='small'
                        label='EDIT'
                        isIcon={false}
                        Icon=''
                        iconPos={undefined}
                        sx={{minWidth: 'auto'}}
                        onClick={() => handleAction(row['channelId'])}
                      />
                    )}
                    {type === 'common' && (
                      <Button
                        color='tertiary'
                        type='outline'
                        size='small'
                        label='DETAIL'
                        isIcon={false}
                        Icon=''
                        iconPos={undefined}
                        sx={{minWidth: 'auto'}}
                        onClick={() => handleAction(row['channelId'])}
                      />
                    )}
                  </>
                ) : (
                  row[column.id]
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </>
  )
));

const ChannelTable = React.memo(
  ({columns, rows, order, orderBy, handleRequestSort, type, handleAction}) => (
      <CustomTableContainer className='table-container'>
        <Table className='custom-table' aria-label='table' stickyHeader>
          <ChannelTableHead columns={columns} order={order} orderBy={orderBy}
                            handleRequestSort={handleRequestSort}/>
          <ChannelTableBody columns={columns} rows={rows} orderBy={orderBy}
                            type={type} handleAction={handleAction}/>
        </Table>
      </CustomTableContainer>
  ));

ChannelTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    align: PropTypes.oneOf(['left', 'right', 'center']),
    width: PropTypes.string,
    checkbox: PropTypes.bool,
    sortable: PropTypes.bool,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
};

ChannelTable.defaultProps = {
  columns: [],
  rows: [],
  order: 'asc',
  orderBy: '',
  handleRequestSort: () => {
  },
  handleAction: () => {
  },
};

export default ChannelTable;