import * as React from 'react';
import { Chip, Badge } from '@mui/material/';
import styled from '@emotion/styled';

// Styled
const StyledChip = styled(Chip)`
  &.MuiChip-root {
    height: auto;
    padding: 4px 12px;
    border-radius: 4px;

    & + .MuiBadge-badge {
      width: 6px;
      height: 6px;
      min-width: auto;
      right: -3px;
      top: -3px;
    }
  }
  & .MuiChip-label {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  &.new {
    background: rgba(165, 0, 52, 0.1);
    border: 1px solid #a50034;
    & .MuiChip-label {
      color: #b91c1c;
    }
  }
  &.testing {
    background: rgba(33, 150, 243, 0.1);
    border: 1px solid rgba(33, 150, 243, 0.4);
    & .MuiChip-label {
      color: #2196f3;
    }
  }
  &.saved {
    background: rgba(245, 158, 11, 0.1);
    border: 1px solid rgba(245, 158, 11, 0.4);
    & .MuiChip-label {
      color: #f59e0b;
    }
  }
  &.publishing {
    background: rgba(13, 148, 136, 0.1);
    border: 1px solid rgba(13, 148, 136, 0.4);
    & .MuiChip-label {
      color: #0d9488;
    }
  }
`;

const StyledBadge = styled(Badge)`
  &.new .MuiBadge-badge {
    background: #a50034;
  }
  &.testing .MuiBadge-badge {
    background: #2196f3;
  }
  &.saved .MuiBadge-badge {
    background: #f59e0b;
  }
  &.publishing .MuiBadge-badge {
    background: #0d9488;
  }
`;

// Component
function Tag({ type, label, badgeType, sx }) {
  return (
    <StyledBadge variant='dot' className={`chip ${badgeType || ''}`} sx={sx}>
      {/*
       ** type 정의 **
      - new : new  (red)
      - Testing: testing (blue)
      - Saved: Saved (yellow)
      - Publishing: publishing (green)
      */}
      <StyledChip label={label} className={type} />
    </StyledBadge>
  );
}

export default Tag;
