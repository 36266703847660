import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import {styled} from '@mui/material/styles';

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

const CustomTableContainer = styled(TableContainer)(({theme}) => ({
  height: '100%',
  overflowY: 'auto',
}));

const SeriesTableHead = React.memo(
    ({columns, order, orderBy, handleRequestSort}) => (
        <TableHead className='thead'>
          <TableRow className='tr'>
            {columns.map((column) => (
                <TableCell key={column.id} className='th' align={column.align}
                           sx={{width: column.width}}>
                  {column.checkbox ? (
                      <>
                        <CheckBox
                            label=''
                            value='select-all'
                            defaultChecked={false}
                            inputProps={{'aria-label': 'select all'}}
                        />
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'desc'}
                            onClick={() => handleRequestSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </>
                  ) : (
                      <>
                        {column.sortable ? (
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order
                                    : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                        ) : (
                            column.label
                        )}
                      </>
                  )}
                </TableCell>
            ))}
          </TableRow>
        </TableHead>
    ));

const SeriesTableBody = React.memo(({columns, rows, handleAction}) => (
  rows.length === 0 ? (
    <NoRecords>
      No records to display
    </NoRecords>
  ) : (
    <TableBody className='tbody'>
      {rows.map((row, index) => (
          <TableRow className='tr' key={row.id || index}>
            {columns.map((column, colIndex) => (
                <TableCell key={column.id} className='td' align={column.align}>
                  {colIndex === 7 ? (
                      <Button
                          color='tertiary'
                          type='outline'
                          size='small'
                          label='DETAIL'
                          isIcon={false}
                          Icon=''
                          iconPos={undefined}
                          sx={{minWidth: 'auto'}}
                          onClick={() => handleAction(row['seasonId'])}
                      />
                  ) : (
                      row[column.id]
                  )}
                </TableCell>
            ))}
          </TableRow>
      ))}
    </TableBody>
  )
));

const SeriesTable = React.memo(
    ({columns, rows, order, orderBy, handleRequestSort, handleAction}) => (
        <CustomTableContainer className='table-container'>
          <Table className='custom-table' aria-label='table' stickyHeader>
            <SeriesTableHead columns={columns} order={order} orderBy={orderBy}
                          handleRequestSort={handleRequestSort}/>
            <SeriesTableBody columns={columns} rows={rows}
                             handleAction={handleAction}/>
          </Table>
        </CustomTableContainer>
    ));

SeriesTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    align: PropTypes.oneOf(['left', 'right', 'center']),
    width: PropTypes.string,
    checkbox: PropTypes.bool,
    sortable: PropTypes.bool,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

SeriesTable.defaultProps = {
  columns: [],
  rows: [],
  order: 'asc',
  orderBy: '',
  handleRequestSort: () => {
  },
  handleEdit: () => {
  },
};

export default SeriesTable;