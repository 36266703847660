export const metaDataTab = [{ label: 'Channel' }, { label: 'Program' }, { label: 'VOD' }, { label: 'Series' }];
export const metaDataEditTab = [{ label: 'Channel' }, { label: 'VOD' }];

export const MetaChannelTh = [
  {
    id: 'logoUrl',
    label: 'Logo',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'channelId',
    label: 'Channel ID',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelNumber',
    label: 'Channel No.',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelName',
    label: 'Channel Name',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cpName',
    label: 'CP Name',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'lastUpdateDate',
    label: 'LastUpdated',
    align: 'left',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ch-74156',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const MetaProgramTh = [
  {
    id: 'cpName',
    label: 'CP Name',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelName',
    label: 'Ch.Name',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'imageUrl',
    label: 'Image',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'programId',
    label: 'Program ID',
    align: 'left',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'programTitle',
    label: 'Program Title',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'seasonNumber',
    label: 'Season',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'episodeNumber',
    label: 'Episode',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'duration',
    label: 'Duration(sec)',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'action',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const MetaVodTh = [
  {
    id: 'cpName',
    label: 'CP Name',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodId',
    label: 'VOD ID',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'title',
    label: 'VOD Title',
    align: 'left',
    width: '24%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'duration',
    label: 'Duration(sec)',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'episodeNumber',
    label: 'Episode No.',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'releaseDate',
    label: 'Release Date',
    align: 'left',
    width: '12%',
    sortable: true,
  },
  {
    id: 'vod-Action',
    label: 'Action',
    align: 'left',
    width: 'auto',
    sortable: false,
  },
];

export const MetaSeriesTh = [
  {
    id: 'cpName',
    label: 'CP Name',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'seriesId',
    label: 'Series ID',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'seriesTitle',
    label: 'Series Title',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'seasonId',
    label: 'Season ID',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'seasonTitle',
    label: 'Season title',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'seasonNumber',
    label: 'Season Number',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'totalEpisodes',
    label: 'Total Episodes',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'series-Action',
    label: 'Action',
    align: 'left',
    width: 'auto',
    sortable: false,
  },
];

export const MetaSeriesVodTh = [
  {
    id: 'vodId',
    label: 'VOD ID',
    align: 'left',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTitle',
    label: 'VOD Title',
    align: 'left',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'episodeNumber',
    label: 'Episode Number',
    align: 'left',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'action',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];
