import React from 'react';
import Main from 'pages/Main/Launch/Main';
import Login from 'pages/Main/Login/Login';
import PartnerMain from 'partner_pages/Main/PartnerMain';

function Entry(props) {
  const { isAuthenticated
        , message
        , currentUser
        , darkMode
        , isPartnerPageShow
        , recentlyUsedMenu
        , callbackFavoriteMenuChange
        , changedFavoriteMenuItems
      } = props;

  if (isAuthenticated) {
    if (isPartnerPageShow) {
      return <PartnerMain
        currentUser={currentUser}
        darkMode={darkMode}
        recentlyUsedMenu={recentlyUsedMenu}
      />;
    } else {
      return <Main
        currentUser={currentUser}
        darkMode={darkMode}
        recentlyUsedMenu={recentlyUsedMenu}
        callbackFavoriteMenuChange={callbackFavoriteMenuChange}
        changedFavoriteMenuItems={changedFavoriteMenuItems}
      />;
    }
  } else {
    return <Login message={message} currentUser={currentUser}/>;
  }
}

export default Entry;