import React, { useState, useEffect } from 'react';
import TextField from 'components/component/BasicTextField';
import CustomTabs from 'components/component/BasicTabs';
import { Box, Typography, Grid } from '@mui/material';
// ************************** 메인 컨텐츠 ***********************************
function PolicyEditContent({ contentData, contentChangeHandler }) {
  const [selectLanguageTabValue, setSelectLanguageTabValue] = useState(0);

  useEffect(() => {
    if (contentData && contentData.length > 0) {
      if (!contentData.map((content) => content.languageCode).includes(selectLanguageTabValue)) {
        setSelectLanguageTabValue(contentData[0].languageCode);
      }
    } else {
      setSelectLanguageTabValue('');
    }
    // eslint-disable-next-line
  }, [contentData]);

  const tabChangeHandler = (event, value) => {
    event.preventDefault();
    setSelectLanguageTabValue(value);
  };

  const titleChangeHandler = (event, language) => {
    const { value } = event.target;
    const writeContentData = contentData.map((content) => {
      if (content.languageCode === language) {
        return { ...content, policyTitle: value };
      }
      return content;
    });
    contentChangeHandler(writeContentData);
  };
  const descriptionChangeHandler = (event, lang) => {
    const { value } = event.target;
    const writeContentData = contentData.map((content) => {
      if (content.languageCode === lang) {
        return { ...content, policyDescription: value };
      }
      return content;
    });
    contentChangeHandler(writeContentData);
  };

  return (
    <Grid item xs={12}>
      {contentData && contentData.map((content) => content.languageCode).length > 0 && (
        <Box className='content-tab-container'>
          <Box className='info-value-wrap align-top'>
            <Typography component='span' className='label required'>
              Content
            </Typography>
            <Box className='value'>
              <div className='tab-container'>
                {selectLanguageTabValue && (
                  <CustomTabs
                    tabStyle='type-01'
                    value={selectLanguageTabValue}
                    handleChange={tabChangeHandler}
                    tabs={contentData.map((m) => {
                      return {
                        label: m.languageCode,
                      };
                    })}
                    isLabelValue
                  />
                )}
                {contentData.map((content, index) => (
                  <div className='tab-panel' key={`tab-panel_${index}`} style={{ display: selectLanguageTabValue === content.languageCode ? 'block' : 'none' }}>
                    <div className='panel-wrap' key={`tab-wrap${index}`}>
                      <Grid container columnSpacing={2.5}>
                        <Grid xs={12} item>
                          <Box className='field-box'>
                            <TextField
                              id={`policy-title-${content.languageCode}`}
                              type='outlined'
                              size='large'
                              placeholder=''
                              label=''
                              autoComplete='off'
                              required={false}
                              InputLabelProps={{ shrink: false }}
                              value={content.policyTitle}
                              onChange={(event) => titleChangeHandler(event, content.languageCode)}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <TextField
                          id={`policy-description-${content.languageCode}`}
                          type='outlined'
                          size='large'
                          label=''
                          placeholder=''
                          multiline
                          readOnly={false}
                          rows={10}
                          variant='outlined'
                          fullWidth
                          className='textarea'
                          required={false}
                          InputLabelProps={{ shrink: true }}
                          value={content.policyDescription}
                          onChange={(event) => descriptionChangeHandler(event, content.languageCode)}
                        />
                      </Grid>
                    </div>
                  </div>
                ))}
              </div>
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  );
}

export default PolicyEditContent;
