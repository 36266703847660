export const ABtestTitleOption = [
  { id: 'ab-1', value: 'ABtest Title', option: 'ABtest Title', type: 'text' },
  { id: 'ab-2', value: 'ABtest Title', option: 'ABtest Title', type: 'text' },
];

export const AnTestServiceDeviceTypeOption = [
  { id: 'all', value: 'all', option: 'All', type: 'text' },
  { id: 'auto', value: 'auto', option: 'auto', type: 'text' },
  { id: 'mobile', value: 'mobile', option: 'mobile', type: 'text' },
  { id: 'pc', value: 'pc', option: 'pc', type: 'text' },
  { id: 'tv', value: 'tv', option: 'tv', type: 'text' },
];

export const PlatformOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'op2', value: 'op2', option: 'op2', type: 'text' },
];

export const PlatformVersionOption = {
  all: [
    { id: 'all', value: 'all', option: 'All', type: 'text' },
    { id: '3.4.0', value: '3.4.0', option: '3.4.0', type: 'text' },
    { id: '5.0.0', value: '5.0.0', option: '5.0.0', type: 'text' }
  ],
  auto: [{ id: '8.2.0', value: '8.2.0', option: '8.2.0', type: 'text' }],
  pc: [{ id: 'pc', value: 'pc', option: 'pc', type: 'text' }],
  mobile: [{ id: 'android', value: 'android', option: 'android', type: 'text' }],
  tv: [
    { id: '3.4.0', value: '3.4.0', option: '3.4.0', type: 'text' },
    { id: '5.0.0', value: '5.0.0', option: '5.0.0', type: 'text' }
  ]
};

export const TypeOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'op2', value: 'op2', option: 'op2', type: 'text' },
];

export const TabOption = [
  { id: 'Live', value: 'Live', option: 'Live', type: 'text' },
  { id: 'Status1', value: 'Status1', option: 'Status1', type: 'text' },
  { id: 'Status2', value: 'Status2', option: 'Status2', type: 'text' },
];

export const CategoryOption = [
  { id: 'all', value: 'all', option: 'All', type: 'text' },
  { id: 'Category1', value: 'Category1', option: 'Category', type: 'text' },
  { id: 'Category2', value: 'Category2', option: 'Category2', type: 'text' },
];

export const StatusOption = [
  { id: 'all', value: 'all', option: 'All', type: 'text' },
  { id: 'ready', value: 'ready', option: 'Ready', type: 'text' },
  { id: 'testing', value: 'testing', option: 'Testing', type: 'text' },
  { id: 'done', value: 'done', option: 'Done', type: 'text' },
];

export const StartDateOption = [
  { id: 'a', value: 'a', option: '2024.07.08', type: 'text' },
  { id: 'b', value: 'b', option: '2024.07.09', type: 'text' },
  { id: 'c', value: 'c', option: '2024..07.10', type: 'text' },
];
export const StartTimeOption = [
  { id: 'time-a', value: 'a', option: '06:00', type: 'text' },
  { id: 'time-b', value: 'b', option: '06:10', type: 'text' },
  { id: 'time-c', value: 'c', option: '06:20', type: 'text' },
];
export const EndTimeOption = [
  { id: 'time-a', value: 'a', option: '16:00', type: 'text' },
  { id: 'time-b', value: 'b', option: '17:00', type: 'text' },
  { id: 'time-c', value: 'c', option: '18:00', type: 'text' },
  { id: 'time-d', value: 'c', option: '19:00', type: 'text' },
  { id: 'time-e', value: 'c', option: '20:00', type: 'text' },
  { id: 'time-f', value: 'c', option: '21:00', type: 'text' },
  { id: 'time-g', value: 'c', option: '22:00', type: 'text' },
  { id: 'time-h', value: 'c', option: '23:00', type: 'text' },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const DeviceTypeOption = [
  { id: 'auto', value: 'auto', option: 'auto', type: 'text' },
  { id: 'mobile', value: 'mobile', option: 'mobile', type: 'text' },
  { id: 'pc', value: 'pc', option: 'pc', type: 'text' },
  { id: 'tv', value: 'tv', option: 'tv', type: 'text' },
];

export const AnTestTh = [
  {
    id: 'title',
    align: 'left',
    label: 'Title',
    width: '12%',
    checkbox: true,
    sortable: true,
  },

  {
    id: 'deviceType',
    align: 'left',
    label: 'Device Type',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'tabName',
    align: 'left',
    label: 'Tab Name',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'categoryName',
    align: 'left',
    label: 'Category Name',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'startTime',
    align: 'left',
    label: 'Start Date (UTC)',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'endTime',
    align: 'left',
    label: 'Initial End Date (UTC)',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'forcedEndTime',
    align: 'left',
    label: 'Actual End Date (UTC)',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'platformVersion',
    align: 'left',
    label: 'Platform Version',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'status',
    align: 'left',
    label: 'Status',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-10',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const AnServiceTh = [
  {
    id: 'title',
    align: 'left',
    label: 'Title',
    width: '12%',
    checkbox: true,
    sortable: true,
  },

  {
    id: 'deviceType',
    align: 'left',
    label: 'Device Type',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'tabName',
    align: 'left',
    label: 'Tab Name',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'categoryName',
    align: 'left',
    label: 'Category Name',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'startTimeLocal',
    align: 'left',
    label: 'Start Date (Local)',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'endTimeLocal',
    align: 'left',
    label: 'Initial End Date (Local)',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'forceEndTimeLocal',
    align: 'left',
    label: 'Actual End Date (Local)',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'platformVersion',
    align: 'left',
    label: 'Platform Version',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'status',
    align: 'left',
    label: 'Status',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-10',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const anTestSegmentTh = [
  {
    id: 'abtestSubid',
    align: 'left',
    label: 'Sub ID',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'validity',
    align: 'left',
    label: 'Validity',
    width: '10%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'segmentTarget',
    align: 'left',
    label: 'Segment Target',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'segmentTargetRate',
    align: 'left',
    label: 'Segment Sample Rate',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'recommendationContentsBType',
    align: 'left',
    label: 'B Group Recommendation Content',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'recommendationContentsCType',
    align: 'left',
    label: 'C Group Recommendation Content',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SegmentTh-7',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const anServiceSegmentTh = [
  {
    id: 'recmdSegmentSubid',
    align: 'left',
    label: 'Sub ID',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'validity',
    align: 'left',
    label: 'Validity',
    width: '10%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'segmentTarget',
    align: 'left',
    label: 'Segment Target',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'segmentTargetRate',
    align: 'left',
    label: 'Segment Sample Rate',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'recommendationContentsBType',
    align: 'left',
    label: 'B Group Recommendation Content',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'recommendationContentsCType',
    align: 'left',
    label: 'C Group Recommendation Content',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SegmentTh-7',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const AnTestResultTh = [
  {
    id: 'AnTestResultTh-1',
    align: 'left',
    label: 'Group',
    width: '10%',
    checkbox: false,
    sortable: false,
  },

  {
    id: 'AnTest-2',
    align: 'left',
    label: 'Group Code',
    width: '20%',
    checkbox: false,
    sortable: false,
  },

  {
    id: 'AnTest-3',
    align: 'right',
    label: 'Number of UDs up to 2024-10-06',
    width: '18%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'AnTest-4',
    align: 'left',
    label: 'Description',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];