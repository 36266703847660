import React, { useState, useEffect, useRef } from 'react';
import { Dialog as MuiDialog, DialogContent as MuiDialogContent, DialogTitle as MuiDialogTitle, DialogActions, Box, IconButton, Divider, Typography, Grid } from '@mui/material';

import Snackbar from 'components/component/BasicSnackbar';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import FileUpload from 'components/component/BasicFileUpload';
import ConfirmDialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
// Images
import noImage378 from 'assets/images/No-Image_378.png';
import noImageVer from 'assets/images/NoImage_ver.png';
import { multiRequest } from 'utils/Request';
// Data
import { defaultPopupType, defaultButton } from 'pages/Organization/TabCategory/Carousel/DefaultData';

import { isEmpty } from 'utils/Valid';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1118px', //팝업사이즈
    maxWidth: 'none',
    maxHeight: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  width: 100%;
`;
const MediaDetailContainer = styled('div')`
  max-height: 420px;
  overflow-y: auto;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

// Data
const DetailItem = ({ label, value, xs, className }) => (
  <Grid item xs={xs} className={`list-wrap ${className}`}>
    <div className='info-value-wrap'>
      <Typography component='span' className='label'>
        {label}
      </Typography>
      <div className='value'>
        <Typography component='span' className='text'>
          {value}
        </Typography>
      </div>
    </div>
  </Grid>
);

// 메인 컨텐츠
function ResultTextUpload({ tabCategory, resultText, setResultText, closeHandler }) {
  const [popupType, setPopupType] = useState(defaultPopupType);
  const [isOpen, setIsOpen] = useState(true);
  const [contentId, setContentId] = useState('');
  const [isRequired, setIsRequired] = useState(false);

  const [resultTitle, setResultTitle] = useState('');
  const [resultDescription, setResultDescription] = useState('');

  useEffect(() => {
    if (!isEmpty(resultText)) {
      setContentId(resultText.contentId);
      setResultTitle(isEmpty(resultText.resultTitle) ? '' : resultText.resultTitle);
      setResultDescription(isEmpty(resultText.resultDescription) ? '' : resultText.resultDescription);
    }
    // eslint-disable-next-line
  }, [resultText]);

  const titleHandler = (value) => {
    setResultTitle(!isEmpty(value) ? value.trim() : '');
  };

  const descriptionHandler = (value) => {
    setResultDescription(!isEmpty(value) ? value.trim() : '');
  };

  const onClose = () => {
    setIsOpen(false);
    if (closeHandler) {
      closeHandler();
    }
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const successDialog = () => {
    setPopupType(defaultPopupType);
    onClose();
  };

  const textUploadDialog = () => {
    if (isEmpty(resultTitle)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Text', content: 'Please register a title' });
      return;
    }
    if (isEmpty(resultDescription)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Text', content: 'Please register a description' });
      return;
    }
    setPopupType({ ...defaultPopupType, edit: true, title: 'Text', content: 'Would you like to upload an text? You must upload and save an text to complete registration.?' });
  };

  const textUpload = () => {
    setResultText(contentId, resultTitle, resultDescription);
    onClose();
  };

  // Button Data

  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButtonId',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const uploadButton = {
    ...defaultButton,
    buttonId: 'uploadButtonId',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: textUpload,
  };
  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const successButton = {
    ...defaultButton,
    buttonId: 'successButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: successDialog,
  };

  const uploadButtons = [
    {
      minWidth: '150px',
      color: 'tertiary',
      type: 'outline',
      size: 'large',
      label: 'Cancel',
      onClick: onClose,
    },
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: 'Save',
      onClick: textUploadDialog,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog open={isOpen} size='large' className='pop-program-detail'>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}>
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Content Image Upload</DialogTitle>
        </HeaderArea>
        <DialogContent className='pop-content pop-upload-image'>
          <BoxContainer>
            <Box className='detail-content'>
              <Box className='detail-list-container'>
                <Grid container columnSpacing={2.5}>
                  <Grid item xs={6}>
                    <DetailItem label='Tab/Category' value={tabCategory} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem label='Country' value={resultText.countryCode} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem label='Content ID' value={resultText.contentId} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem label='Content Name' value={resultText.contentName} />
                  </Grid>
                  <Grid item xs={12}>
                    <DetailItem label='Carousel Type' value={resultText.carouselType} />
                  </Grid>
                  <Grid item xs={12} className='list-wrap'>
                    <Typography component='span' className='label required'>
                      Title Text
                    </Typography>
                    <Box className='value-wrap'>
                      <Box className='value no-border' display='flex' gap={1.5}>
                        <TextField
                          name='CarouselResultTextTitle'
                          type='outlined'
                          size='large'
                          // maxWidth={'94px'}
                          placeholder=''
                          defaultValue=''
                          label=''
                          autoComplete='off'
                          inputProps={{ maxLength: 1024 }}
                          InputLabelProps={{ shrink: false }}
                          value={resultTitle}
                          onChange={(event) => titleHandler(event.target.value)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className='list-wrap'>
                    <Typography component='span' className='label required'>
                      Description Text
                    </Typography>
                    <Box className='value-wrap'>
                      <Box className='value no-border' display='flex' gap={1.5}>
                        <TextField
                          name='CarouselResultTextDescription'
                          type='outlined'
                          size='large'
                          // maxWidth={'94px'}
                          placeholder=''
                          defaultValue=''
                          label=''
                          autoComplete='off'
                          inputProps={{ maxLength: 4000 }}
                          InputLabelProps={{ shrink: false }}
                          value={resultDescription}
                          onChange={(event) => descriptionHandler(event.target.value)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </BoxContainer>
        </DialogContent>
        <Divider sx={{ margin: '24px 0' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={uploadButtons} justifyContent='center' />
        </DialogActions>
      </Dialog>
      {/* severity: default, error, warning, info, success */}
      <Snackbar severity='warning' message='*You can have up to 10 favorite menus.' open={false} Duration='1000' bottom={'8%'} />
      {(popupType.alarm || popupType.edit || popupType.success) && (
        <ConfirmDialog
          title={popupType.title}
          contentTitle={popupType.content}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={closeDialog}
          buttons={popupType.alarm ? [closeButton] : popupType.edit ? [cancelButton, uploadButton] : popupType.success ? [successButton] : []}
          // buttons={popupType.alarm ? [closeButton] : popupType.publish ? [cancelButton, saveButton] : popupType.success ? [successButton] : []}
        />
      )}
    </div>
  );
}
export default ResultTextUpload;
