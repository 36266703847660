export const StatusOption = [
  { id: 'Saved-Testing', value: 'S-T', option: 'Saved-Testing', type: 'text' },
  { id: 'Saved-Published', value: 'S-P', option: 'Saved-Published', type: 'text' },
  { id: 'Testing', value: 'T', option: 'Testing', type: 'text' },
  { id: 'Published', value: 'P', option: 'Published', type: 'text' },
];

export const EmulateTitle = [
  {
    id: 'resultOrder',
    align: 'left',
    label: 'Order',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerName',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentType',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentId',
    align: 'left',
    label: 'Content ID',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentName',
    align: 'left',
    label: 'Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'seasonNumber',
    align: 'left',
    label: 'Season',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'episodeNumber',
    align: 'left',
    label: 'Episode',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'imageUrl',
    align: 'left',
    label: 'Image',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'genreName',
    align: 'left',
    label: 'Genre',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'keyword',
    align: 'left',
    label: 'Keyword',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'lastChgDate',
    align: 'left',
    label: 'Last Chg Date',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
];
