import React, {useEffect, useState} from 'react';
import Dialog from 'components/component/BasicDialog';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import TextField from 'components/component/BasicTextField';
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { Typography, List, ListItem, Grid, IconButton } from '@mui/material';
import { DarkInfoTooltipIcon24, InfoTooltipIcon24 } from 'components/component/BasicIcon';
import Tooltip from 'components/component/BasicTooltip';
import {BASE_URL} from "constants/index";
import {getHeaders} from "utils/Header";

function PopDeviceNotificationHistoryDetailTV(props) {
  const { darkMode, notificationId, version, handleClose } = props;

  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Ok',
      type: 'box',
      color: 'primary',
      disabled: false,
      onClick: handleClose,
    },
  ];

  // Multi Language Button
  const [activeButton, setActiveButton] = useState('EN');
  const handleButtonClick = (label) => {
    setActiveButton(label);
    const multiLang = historyDetail.multiLangPayloads.find(item => item.langCode === label);
    if (multiLang) {
      setActiveMultiLang(multiLang);
    }
  };

  // DetailItem 컴포넌트 수정
  const DetailItem = ({ label, value, maxThWidth = false, tooltip = false, tooltipText = '' }) => (
    <Box className='list-wrap'>
      <Grid display='flex' item xs={12} alignItems='center'>
        {maxThWidth === true ? (
          <Grid item xs={3}>
            <Typography component='span' className='label'>
              {label}
            </Typography>
          </Grid>
        ) : (
          <Typography component='span' className='label'>
            {label}
          </Typography>
        )}
        <div className='value-wrap'>
          <div className='value'>
            <Typography component='span' className='text'>
              {value}
            </Typography>
            {tooltip && (
                <Tooltip title={tooltipText.split(',').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                ))} placement='top'>
                <IconButton size='small' sx={{ width: '24px', height: '24px', marginLeft: '4px' }}>
                  {darkMode ? <InfoTooltipIcon24 /> : <DarkInfoTooltipIcon24 />}
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </Grid>
    </Box>
  );

  const DetailImageItem = ({ label, xs, preview }) => (
    <Grid xs={xs} item alignItems='center' className='list-wrap'>
      <Box className='image-value-area'>
        <Typography component='span' className='label'>
          {label}
        </Typography>

        <Box className='value'>
          <Box className='image-wrap'>
            <Box className='image-item'>
              <CustomImage src={preview.src} fit={true} wrapWidth={preview.width} wrapHeight={preview.height} alt='' rounded />
              <Box component='div' className='image-size-list'>
                <Typography component='p' className='upload-info-text'>
                  Recommended Size : <strong>78px X 78px</strong>
                </Typography>
                <Typography component='p' className='upload-info-text'>
                  Actual Image Size : <strong>{preview.width}px X {preview.height}px</strong>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  const imagePrefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;
  const [historyDetail, setHistoryDetail] = useState({countryList: [], channelMaps: [], multiLangPayloads: []});
  const [imagePreview, setImagePreview] = useState({src: '', width: 0, height: 0});
  const [activeMultiLang, setActiveMultiLang] = useState({langCode: '', title: '', content: ''});

  const getHistoryDetail = (notificationId, version) => {
    fetch(`${BASE_URL}/device-notification/notifications/history/detail?notificationId=${notificationId}&version=${version}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setHistoryDetail(body.data);
        if (body.data.imageUrl) {
          const img = new Image();
          img.src = imagePrefix + body.data.imageUrl;
          img.onload = () => {
            setImagePreview({
              src: img.src,
              width: img.width,
              height: img.height,
            });
          };
        }
        if (body.data.multiLangPayloads && body.data.multiLangPayloads.length > 0) {
          setActiveMultiLang(body.data.multiLangPayloads[0]);
          setActiveButton(body.data.multiLangPayloads[0].langCode);
        }
      }
    })
  }

  useEffect(() => {
    if (notificationId && version) {
      getHistoryDetail(notificationId, version);
    }
    // eslint-disable-next-line
  }, [notificationId, version]);

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='History-Device Notification History Detail' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        handleClose={handleClose}
        size='large' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <List className='info-list-container'>
              <React.Fragment key={1}>
                <ListItem className='list-item'>
                  <Typography className='label'>Version</Typography>
                  <Typography className='value'>{version}</Typography>
                </ListItem>
                <Divider className='divider' />
              </React.Fragment>
              <React.Fragment key={2}>
                <ListItem className='list-item'>
                  <Typography className='label'>Device Type</Typography>
                  <Typography className='value'>TV</Typography>
                </ListItem>
                <Divider className='divider' />
              </React.Fragment>
            </List>

            <Divider sx={{ marginBottom: '16px' }} />

            <Box className='detail-list-container'>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12}>
                  <DetailItem label='Enable' className='' value={historyDetail.enable === 'Y' ? 'On' : 'Off'} />
                </Grid>
                <Grid item xs={6}>
                  <DetailItem
                    label='Country'
                    className=''
                    value={historyDetail.countryList.length > 10 ?
                        `${historyDetail.countryList[0]}+${historyDetail.countryList.length - 1}` :
                        historyDetail.countryList.join(', ')}
                    tooltip={historyDetail.countryList.length > 10}
                    tooltipText={historyDetail.countryList.join(',')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DetailItem
                    label='Channel Map'
                    className=''
                    value={historyDetail.channelMaps.length > 10 ?
                        `${historyDetail.channelMaps[0]}+${historyDetail.channelMaps.length - 1}` :
                        historyDetail.channelMaps.join(', ')}
                    tooltip={historyDetail.channelMaps.length > 10}
                    tooltipText={historyDetail.channelMaps.join(',')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DetailItem label='Start Date' className='' value={historyDetail.startDate} />
                </Grid>
                <Grid item xs={6}>
                  <DetailItem label='End Date' className='' value={historyDetail.endDate} />
                </Grid>
                {historyDetail.type && historyDetail.type !== 'text' && (
                  <>
                    <Grid item xs={12}>
                      <DetailImageItem label='Image' className='' preview={imagePreview} />
                    </Grid>
                    <Grid item xs={12}>
                      <DetailItem label='Deeplink URL' className='' value={historyDetail.deeplinkUrl} />
                    </Grid>
                  </>
                )}
              </Grid>

              <Box sx={{ mt: 5 }}>
                <Grid container component='section' className='section-container'>
                  {/* Device List */}
                  <Grid item xs={12}>
                    <Typography className='sub-title'>Language</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className='toggle-btn-wrap' display='flex' gap={1}>
                      {historyDetail.multiLangPayloads.map((item, index) => (
                        <Button
                          key={index}
                          color={activeButton === item.langCode ? 'primary' : 'tertiary'}
                          type={activeButton === item.langCode ? 'box' : 'outline'}
                          size='small'
                          label={item.langCode}
                          isIcon={false}
                          autosize
                          onClick={() => handleButtonClick(item.langCode)}
                        />
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <DetailItem label='Title' className='' value={activeMultiLang.title} />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <TextField
                      type='outlined'
                      size='large'
                      label=''
                      placeholder=''
                      value={activeMultiLang.content}
                      multiline
                      readOnly={false}
                      rows={7}
                      variant='outlined'
                      fullWidth
                      className='textarea'
                      required={false}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopDeviceNotificationHistoryDetailTV;
