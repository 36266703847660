import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BasicSwitches from 'components/component/BasicSwitch';
import Dialog from 'components/component/BasicDialog';

// image
import { TimeIcon, MenuIcon } from 'components/component/BasicIcon';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

const TopBarContainer = styled('div')`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: var(--TopBarContainer);

  .logo {
    display: flex;
    align-items: center;
    gap: 8px;
    .logo-img {
      width: 32px;
      height: 32px;
    }
    .logo-text {
      display: block;
      .text {
        display: block;

        &.lg {
          color: #a50034;
          font-size: 18px;
          font-weight: 700;
        }
        &.sm {
          font-size: 12px;
          font-weight: 400;
          color: #6d6e72;
        }
      }
    }
  }
  .MuiPaper-root {
    background-color: #fff;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
  }
  .time-zone {
    color: #000;
  }
  .btn-extend {
    margin-left: 12px;
  }

  .profile-zone {
    .MuiButtonBase-root {
      .icon-btn {
        transition: 0.3s;
      }
      &[aria-expanded='true'] {
        .icon-btn {
          transform: rotate(180deg);
        }
      }
    }
  }
  .profile-zone {
    .MuiAvatar-root {
      margin-right: 12px;
      border-radius: 12px;
    }
    .MuiButtonBase-root {
      padding: 7px 0;
      &:hover {
        background: none;
      }
      .icon-btn {
        margin-left: 20px;
      }
    }
  }
`;

const TimeZone = styled('div')`
  display: flex;
  align-items: center;
`;

const ProfileZone = styled('div')`
  display: flex;
  align-items: center;
`;

const TimeText = styled('span')`
  font-size: 14px;
`;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  position: 'sticky',
  '&.MuiPaper-root': {
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiToolbar-root': {
    minHeight: 'auto',
    width: '100%',
  },
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomPaper = styled(Paper)`
  width: 180px;
  height: auto;
  background-color: #fff;
  border: 1px solid #dbe3ef;
  border-radius: 4px;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.1);
  margin-right: -3px;

  .dark & {
    background: #1b1b1b;
    border-color: #484848;

    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.1);
  }
  .MuiList-root {
    width: 100%;
  }
  .MuiButtonBase-root {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    .dark & {
      color: #808080;
    }

    &:hover {
      .dark & {
        background: #0c0c0c;
        color: #fff;
      }
    }
  }
`;

export default function TopBar(props) {
  const { currentUser, darkMode, isPartnerPageShow } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = React.useState('');
  const [timeLeft, setTimeLeft] = useState(1800); // 30 minutes
  const [warningTimeLeft, setWarningTimeLeft] = useState(300); // 5 minutes
  const [switchScreenMode, setSwitchScreenMode] = useState(null);
  const [isSuperAdminRole, setSuperAdminRole] = useState(false);

  // Logout Confirm Dialog
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const timerIdRef = useRef(null);
  const currentUserRef = useRef(currentUser);
  const confirmOpenRef = useRef(isConfirmOpen);

  useEffect(() => {
    if (currentUser) {
      setName(currentUser.name);
      setTimeLeft(currentUser.sessionTimeout);
      setWarningTimeLeft(currentUser.sessionWarningTimeout);

      setSuperAdminRole(currentUser.role === 'ROLE_SUPER_ADMIN' ? true : false);

      startTimer();

      const handleMouseMove = () => {
        if (!confirmOpenRef.current) {
          handleSessionTimeExtend();
        }
      };

      window.addEventListener('mousemove', handleMouseMove);

      return () => {
        clearInterval(timerIdRef.current);
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    if (isPartnerPageShow !== null) {
      setSwitchScreenMode(isPartnerPageShow);
    }
  }, [isPartnerPageShow]);

  const startTimer = () => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
    }

    timerIdRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timerIdRef.current);
          setConfirmTitle('Extend the session');
          setConfirmMessage('Do you want to extend the session?');
          setConfirmOpen(true);
          confirmOpenRef.current = true;
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signoutRequest = () => {
    fetch(`${BASE_URL}/api/auth/signout`, {
      method: 'POST',
      headers: getHeaders()
    })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error : ${res.status} ${res.statusText}`);
      }
      return res.json();
    })
    .then(body => {
      if (body.result !== 'SUCCESS') {
        throw new Error(`Error occurred during Logout`)
      }
    })
    .catch(error => {
      console.error(error.message.replace('Error: ', ''));
    }).finally(() => {});
  };

  const handleLogout = (message) => {
    signoutRequest();

    const param = message ? message : '';

    const updateCurrentUser = {
      ...currentUserRef.current,
      accessToken: '',
      isAuthenticated: false,
      loginTime: '',
      provider: '',
      accessMenuList: [],
      role: '',
      authority: '',
    };

    localStorage.setItem('currentUser', JSON.stringify(updateCurrentUser));

    navigate('/', { replace: true, state: { auth: 'false', message: param } });
  };

  const updateSessionTime = () => {
    const updateCurrentUser = {
      ...currentUser,
      loginTime: new Date().toISOString(),
    };

    localStorage.setItem('currentUser', JSON.stringify(updateCurrentUser));
  };

  const handleSessionTimeExtend = () => {
    setTimeLeft(currentUser.sessionTimeout);
    updateSessionTime();
  };

  const handleCMPHome = (event) => {
    event.preventDefault();
    navigate('/', { replace: true, state: { move: true, targetComponent: 'Home', selectedId: 10000 } }); // Lnb 선택할 필요가 없어서 10000 으로 설정
  };

  const handleMyProfile = (event) => {
    setAnchorEl(null);
    event.preventDefault();
    navigate('/', { replace: true, state: { move: true, targetComponent: 'MyProfile', selectedId: 10000 } });
  };

  const handleChangeSwitchMode = (value) => {
    if (value) {  // Move to partner page
      navigate('/', { replace: true, state: { move: true, targetComponent: 'PartnerMain' } });
    } else { // Move to main page
      navigate('/', { replace: true, state: { move: true, targetComponent: 'Main' } });
    }

    setSwitchScreenMode(value);
  };

  const handleSupport = (event) => {
    setAnchorEl(null);
    event.preventDefault();
    navigate('/', { replace: true, state: { move: true, targetComponent: 'Notification', selectedId: 10000 } });
  };

  const handleConfirmClose = () => {
    handleLogout('Your session timed out. Please login again');
    setConfirmOpen(false);
    confirmOpenRef.current = false;
  };

  const handleConfirmSave = () => {
    handleSessionTimeExtend();
    startTimer();
    setConfirmOpen(false);
    confirmOpenRef.current = false;
  };

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: handleConfirmSave
    },
  ];

  return (
    <TopBarContainer className='top-bar-container'>
      <AppBar className='gnb-container'>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography variant='h1' component='h1'>
            <Link href="#" onClick={handleCMPHome} className='logo' underline='none'>
              <img src={require('assets/images/logo.png')} alt='Logo' className='logo-img' />
              <div className='logo-text'>
                <span className='text lg'>CMP</span>
                <span className='text sm'>Channel Management Platform</span>
              </div>
            </Link>
          </Typography>

          <Stack direction='row' spacing={4} alignItems='center'>
            {isSuperAdminRole && <BasicSwitches
              disabled={false}
              onOffMode={switchScreenMode === null ? isPartnerPageShow : switchScreenMode}
              setSwitchOnOff={handleChangeSwitchMode}
              isTextInner={true}
              onText='Partner'
              offText='Admin'
            />}

            <TimeZone className='time-zone'>
              <TimeIcon />
              <TimeText
                style={{color: timeLeft < warningTimeLeft ? '#ff0000' : darkMode ? '#ffffff' : '#000'}}
              >
                {Math.floor(timeLeft / 60).toString().padStart(2, '0')} : {(timeLeft % 60).toString().padStart(2, '0')}
              </TimeText>
            </TimeZone>
            <ProfileZone className='profile-zone'>
              <Avatar alt='Remy Sharp' src={require('assets/images/Profile_Image.png')} />
              <Button
                id='basic-button'
                sx={{ fontSize: '14px', fontWeight: 700, color: '#000' }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                {name}
                <MenuIcon className='icon-btn' />
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  component: CustomPaper,
                }}
                className='user-menu-wrap'
                disableScrollLock
              >
                <MenuItem onClick={handleMyProfile} sx={{ fontSize: '14px' }}>
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleSupport} sx={{ fontSize: '14px' }}>
                  Support
                </MenuItem>
                <MenuItem onClick={() => handleLogout('')} sx={{ fontSize: '14px' }}>
                  Logout
                </MenuItem>
              </Menu>
            </ProfileZone>
          </Stack>
        </Toolbar>
      </AppBar>
      <Dialog
        open={isConfirmOpen} // boolean 값으로 설정
        handleClose={handleConfirmClose}
        closeX={false}
        size='small' // large, medium, small
        title={confirmTitle} // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={confirmMessage}
        buttons={confirmDialogButtons}
      />
    </TopBarContainer>
  );
}
