import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import { Box, Typography } from '@mui/material';
import SupportNotification from "./SupportNotification";
import SupportFAQ from "./SupportFAQ";

// Tab Name
const tabs = [{ label: 'Notification' }, { label: 'FAQ' }];

function Support() {

  // BreadCrumb Data
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Support',
  };

  // Tab
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography className='title'>Support</Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap' component='div'>
          <div className='tab-container'>
            <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />

            {/* Notification */}
            {tabValue === 0 && (
              <SupportNotification />
            )}

            {/* FAQ */}
            {tabValue === 1 && (
              <SupportFAQ />
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
}
export default Support;
