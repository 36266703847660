import { BASE_URL } from 'constants';
import { getHeaders } from 'utils/Header';
import { SET_HOMEAPP_PARAM, SET_TAB_CATEGORY } from 'actions/actionTypes';

export const getRegionAction = () => dispatch => {
  return fetch(`${BASE_URL}/home-app/region-list`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_REGION', payload: body.data });
    })
    .catch(ex => {
      dispatch({ type: 'SET_REGION', payload: ex });
    });
};

export const getRegionPlatformAction = () => dispatch => {
  return fetch(`${BASE_URL}/home-app/region-platform-list`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_REGION_PLATFORM', payload: body.data });
    })
    .catch(ex => {
      dispatch({ type: 'SET_REGION_PLATFORM', payload: ex });
    });
};

export const getTabCategoryAction = (deviceType, region, platformVersion, status) => dispatch => {
  console.log('getTabCategory Call')
  if (!platformVersion) {
    platformVersion = '-';
  }
  return fetch(`${BASE_URL}/home-app/device/${deviceType}/region/${region}/platform/${platformVersion}/status/${status}/tab-category`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: SET_TAB_CATEGORY, payload: body.data }))
    .catch(ex => dispatch({ type: SET_TAB_CATEGORY, payload: ex }));
};


export const setParamAction = (deviceType, region, status, platformVersion) => dispatch => {
  dispatch({ type: SET_HOMEAPP_PARAM, payload: { deviceType: deviceType, region: region, status: status, platformVersion: platformVersion } });
};
