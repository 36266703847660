import React, { useState, useEffect, Fragment } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import TextField from 'components/component/BasicTextField';
import RadioGroup from '@mui/material/RadioGroup';
import CustomRadio from 'components/component/BasicRadio';
import SelectBox from 'components/component/BasicSelectBox';
import SelectMultipleBox from 'components/component/BasicSelectMultipleBox';
import { Box, Typography, Link, Grid } from '@mui/material';
// data
import { defaultPolicy, displayOption } from 'pages/Configuration/Policy/data/PolicyData';
// Icon
import { ReactComponent as ItemDelLight } from 'assets/images/icon/ic_close_24_outline_gray.svg';
import { ReactComponent as ItemDelDark } from 'assets/images/icon/ic_close_24_outline_black.svg';
// common
import EditContent from 'pages/Configuration/Policy/PolicyEditContent';
import BasicDialog from 'components/component/BasicDialog';
import { useNavigate } from 'react-router-dom';
import { postRequest } from 'utils/Request';
import { isEmpty } from 'utils/Valid';
// ************************** 메인 컨텐츠 ***********************************
function PolicyAdd({ darkMode, message }) {
  const navigate = useNavigate();
  const compareVersions = require('compare-versions');
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Terms of Use',
    normalMenu: ['Terms Add'],
  };
  const defaultPopupType = {
    add: false,
    cancel: false,
    alarm: false,
    success: false,
    title: '',
    content: '',
  };
  const defaultOption = {
    displayTempId: '',
    displayOption: '',
    displayOrder: '',
  };
  const defaultContent = {
    displayTempId: '',
    contentIdx: null,
    languageCode: '',
    policyTitle: '',
    policyDescription: '',
  };
  const [popupType, setPopupType] = useState(defaultPopupType);
  const [policy, setPolicy] = useState(defaultPolicy);
  const [editFilter, setEditFilter] = useState([]);
  const [supportLanguage, setSupportLanguage] = useState({});
  const [multipleLanguageList, setMultipleLanguageList] = useState([]);
  const [displayTempOption, setDisplayTempOption] = useState('');
  const [displayTempOrder, setDisplayTempOrder] = useState('');
  const [isAddOptionText, setIsAddOptionText] = useState(false);
  const [displayTempIdCount, setDisplayTempIdCount] = useState(1);
  const [appliedOptionId, setAppliedOptionId] = useState('');

  useEffect(() => {
    if (!isEmpty(message)) {
      setEditFilter(message.editFilter);
      setPolicy({
        ...message.policy,
      });

      let language = [];
      if (!isEmpty(message.supportLanguage)) {
        language = message.supportLanguage.map((m) => {
          return {
            id: m.languageCode,
            value: m.languageCode,
            option: m.languageCode,
            type: 'text',
          };
        });
      }
      setSupportLanguage(language);
    }
    // eslint-disable-next-line
  }, [message]);

  const handleRadioChange = (event, tempId) => {
    setAppliedOptionId(event.target.value);
    let appliedContentList = [...policy.contentList].filter((f) => f.displayTempId === tempId);
    setMultipleLanguageList(appliedContentList.map((f) => f.languageCode));
  };

  const onChangeHandler = (name, value) => {
    if (name === 'deviceType') {
      setPolicy({ ...policy, [name]: value, devicePlatformCodeList: [], countryCodeList: [] });
    } else if (name === 'countryCode') {
      let ric = '';
      if (!isEmpty(value)) {
        ric = editFilter.find((f) => policy.deviceType === f.id).countryList.find((f) => f.id === value).ric;
      }
      setPolicy({ ...policy, ...{ [name]: value, ricCode: ric } });
    } else {
      setPolicy({ ...policy, [name]: value });
    }
  };

  const isValidVersion = (previousVersion, inputVersion) => {
    if (
      isEmpty(previousVersion) ||
      isEmpty(inputVersion) ||
      !compareVersions.validate(previousVersion) ||
      !compareVersions.validate(inputVersion) ||
      !compareVersions.compare(previousVersion, inputVersion, '<=')
    ) {
      return false;
    }
    return true;
  };

  const versionInputHandler = (event) => {
    event.preventDefault();
    let { name, value } = event.target;
    if (!isEmpty(value)) {
      if (!isFinite(value)) {
        return;
      }
    }
    let inputVersion = isEmpty(value) ? '' : Number(value);
    if (name === 'policyMajorVersion') {
      setPolicy({
        ...policy,
        ...{
          policyMajorVersion: inputVersion,
          policyVersion: ''.concat(inputVersion, '.', policy.policyMinorVersion, '.', policy.policyPatchVersion),
        },
      });
    } else if (name === 'policyMinorVersion') {
      setPolicy({
        ...policy,
        ...{
          policyMinorVersion: inputVersion,
          policyVersion: ''.concat(policy.policyMajorVersion, '.', inputVersion, '.', policy.policyPatchVersion),
        },
      });
    } else if (name === 'policyPatchVersion') {
      setPolicy({
        ...policy,
        ...{
          policyPatchVersion: inputVersion,
          policyVersion: ''.concat(policy.policyMajorVersion, '.', policy.policyMinorVersion, '.', inputVersion),
        },
      });
    }
  };

  const numberChangeHandler = (value, setValue) => {
    if (!isEmpty(value)) {
      if (!isFinite(value)) {
        return;
      }
    }
    let inputValue = isEmpty(value) ? '' : Number(value);
    setValue(inputValue);
    let displayList = [...policy.multipleDisplay];
    if (isFinite(inputValue) && displayList && displayList.length > 0 && displayList.map((m) => m.displayOrder).findIndex((f) => inputValue === f) > -1) {
      setIsAddOptionText(true);
    } else {
      setIsAddOptionText(false);
    }
  };

  const addOptionHandler = (event) => {
    event.preventDefault();
    let displayList = [...policy.multipleDisplay];
    if (displayList && displayList.length > 0) {
      if (displayList.map((m) => m.displayOrder).findIndex((f) => displayTempOrder === f) > -1) {
        setIsAddOptionText(true);
        return;
      }
    }
    let option = { ...defaultOption };
    option.displayTempId = displayTempOption.concat('_', displayTempIdCount);
    option.displayOption = displayTempOption;
    option.displayOrder = displayTempOrder;
    displayList.push(option);

    displayList.sort((a, b) => {
      return a.displayOrder < b.displayOrder ? -1 : 1;
    });

    setPolicy({ ...policy, ...{ multipleDisplay: displayList } });
    setDisplayTempOption('');
    setDisplayTempOrder('');
    setDisplayTempIdCount(displayTempIdCount + 1);
    setIsAddOptionText(false);
    if (displayList && displayList.length === 1) {
      setAppliedOptionId(option.displayTempId);
    }
  };

  const removeOptionHandler = (event, displayTempId) => {
    event.preventDefault();
    let excludedList = [...policy.multipleDisplay].filter((f) => f.displayTempId !== displayTempId);
    setPolicy({ ...policy, ...{ multipleDisplay: excludedList } });
    if (appliedOptionId === displayTempId && excludedList.length > 0) {
      setAppliedOptionId(excludedList[0].displayTempId);
    }
  };

  const multipleDisplayLanguageChangeHandler = (displayId, language) => {
    setMultipleLanguageList(language);
    if (!isEmpty(displayId)) {
      setMultipleLanguageList(language);
      let multipleContentList = [...policy.contentList];
      let focusContentList = multipleContentList.filter((f) => f.displayTempId === displayId);
      let excludedContentList = multipleContentList.filter((f) => f.displayTempId !== displayId);
      if (isEmpty(language)) {
        setMultipleLanguageList([]);
        setPolicy({ ...policy, ...{ contentList: excludedContentList } });
      } else if (focusContentList.length > language.length) {
        let removeContentList = focusContentList.filter((f) => language.includes(f.languageCode));
        setPolicy({ ...policy, ...{ contentList: [...excludedContentList, ...removeContentList] } });
      } else if (focusContentList.length < language.length) {
        let newLanguage = language.find((f) => !focusContentList.map((m) => m.languageCode).includes(f));
        let addContent = { ...defaultContent };
        addContent.displayTempId = displayId;
        addContent.languageCode = newLanguage.toString();
        focusContentList.push(addContent);
        setPolicy({ ...policy, ...{ contentList: [...excludedContentList, ...focusContentList] } });
      } else {
        setMultipleLanguageList(language);
        setPolicy({ ...policy, ...{ contentList: multipleContentList } });
      }
    }
  };

  const contentChangeHandler = (contentData) => {
    let multipleContentList = [...policy.contentList];
    let excludedContentList = multipleContentList.filter((f) => f.displayTempId !== appliedOptionId);
    setPolicy({ ...policy, ...{ contentList: [...excludedContentList, ...contentData] } });
  };

  const saveConfirmHandler = (e) => {
    e.preventDefault();
    let title = 'Policy';
    let dialogContent = '';
    // common
    if (isEmpty(policy.policyType)) {
      dialogContent = 'No policy type has been selected.?';
    } else if (isEmpty(policy.deviceType)) {
      dialogContent = 'No device type has been selected.';
    } else if (isEmpty(policy.devicePlatformCodeList)) {
      dialogContent = 'No device Platform has been selected.';
    } else if (isEmpty(policy.countryCode)) {
      dialogContent = 'Select the country this policy applies to.';
    } else if (isEmpty(policy.policyVersion) || !compareVersions.validate(policy.policyVersion)) {
      dialogContent = 'The version format to apply was entered incorrectly.';
    } else if (!compareVersions.compare(policy.definedVersion, policy.policyVersion, '<=')) {
      dialogContent = 'It should be upgraded from the previous version.';
    }

    if (!isEmpty(dialogContent)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: dialogContent });
      return;
    }

    // multipleDisplay
    if (isEmpty(policy.multipleDisplay)) {
      dialogContent = 'Policy options and sequence have not been determined.';
    } else {
      let unwritten = policy.multipleDisplay.find((f) => isEmpty(f.displayOrder));
      if (unwritten) {
        dialogContent = '[' + unwritten.displayOption + '] sort number for the option is not recorded.';
      } else {
        let arrOrder = policy.multipleDisplay.map((m) => m.displayOrder);
        let orderValid = arrOrder.some(function (x) {
          return arrOrder.indexOf(x) !== arrOrder.lastIndexOf(x);
        });
        if (orderValid) {
          dialogContent = 'Duplicate policy sort number.';
        }
      }
    }
    if (!isEmpty(dialogContent)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: dialogContent });
      return;
    }

    // content
    policy.multipleDisplay.forEach((fo) => {
      let option = policy.contentList.filter((f) => fo.displayTempId === f.displayTempId);
      if (isEmpty(option)) {
        dialogContent = 'No policy has been entered.[' + fo.displayOption + ']';
      } else {
        option.forEach((op) => {
          if (isEmpty(op.policyTitle)) {
            dialogContent = 'Please enter a title for your [' + fo.displayOption + '][' + op.languageCode + '] policy.';
            return;
          } else if (isEmpty(op.policyDescription)) {
            dialogContent = 'Please enter description for your [' + fo.displayOption + '][' + op.languageCode + '] policy.';
          }
        });
      }
    });

    if (!isEmpty(dialogContent)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: dialogContent });
      return;
    }

    let policyVersion = '';
    policyVersion = ''.concat(policy.policyMajorVersion, '.', policy.policyMinorVersion, '.', policy.policyPatchVersion);
    setPolicy({ ...policy, ...{ policyVersion: policyVersion } });
    addPolicyDialog();
  };

  const cancelPolicyDialog = () => {
    setPopupType({ ...defaultPopupType, cancel: true, title: 'Policy', content: 'Do you want to cancel writing?' });
  };

  const addPolicyDialog = () => {
    setPopupType({ ...defaultPopupType, add: true, title: 'Policy', content: 'Would you like to save your Policy?' });
  };

  const savePolicy = async () => {
    let requestBody = { ...policy };
    const body = await postRequest('/policy', requestBody, null);
    let title = '';
    let content = '';
    if (body && body.result === 'SUCCESS') {
      title = body.result;
      content = 'Saved successfully.';
      setPopupType({ ...defaultPopupType, success: true, title: title, content: content });
    } else {
      if (body && body.result === 'FAILED') {
        title = body.result;
        content = body.message;
      } else {
        title = body.error;
        content = 'Contact your administrator!';
      }
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
    }
  };

  // Button
  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const returnList = () => {
    let link = '/TermsOfUse';
    if (policy.policyType !== 'terms') {
      link = '/PrivacyPolicy';
    }
    navigate(link, {
      replace: true,
      state: {
        move: true,
      },
    });
  };

  const defaultButton = {
    buttonId: 'buttonId',
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    isIcon: false,
    Icon: null,
    fontWeight: 'bold',
    disabled: false,
    label: 'Button',
  };
  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButton',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButton',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };

  const saveButton = {
    ...defaultButton,
    buttonId: 'saveButton',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: savePolicy,
  };
  const linkButton = {
    ...defaultButton,
    buttonId: 'linkButton',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: returnList,
  };

  const cancelActionButtons = [cancelButton, linkButton];
  const saveActionButtons = [cancelButton, saveButton];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Link className='title link' href=''>
                Terms Add
              </Link>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        <Box className='content-wrap vod-detail' component='div'>
          <Box className='white-box white-box-wrap single-box'>
            <Box component='div' className='title-wrap space-between flex-end'>
              <Box component='div' className='left-area'></Box>
              <Box component='div' className='right-area' gap={1}>
                <Button color='tertiary' type='outline' size='medium' label='Cancel' isIcon={false} autosize onClick={cancelPolicyDialog} />
                <Button color='primary' type='box' size='medium' label='Save' isIcon={false} autosize onClick={saveConfirmHandler} />
              </Box>
            </Box>
            <div className='box-content'>
              <Box className='content-inner-wrap'>
                <Box className='detail-list-container' mt={0}>
                  <Grid container columnSpacing={2.5}>
                    <Grid item xs={6}>
                      <Box className='field-box'>
                        {/* //editFilter */}
                        {!isEmpty(editFilter) && (
                          <SelectBox
                            id='policy-device-type'
                            size='large'
                            shrink={true}
                            label='Device type'
                            required={false}
                            disabled={false}
                            isEmpty={true}
                            placeholder=''
                            selectOptions={editFilter}
                            selected={isEmpty(policy.deviceType) ? '' : policy.deviceType}
                            onChange={(value) => onChangeHandler('deviceType', value)}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className='field-box'>
                        {!isEmpty(editFilter) && (
                          <SelectMultipleBox
                            id='policy-platform'
                            size='large'
                            shrink={true}
                            label='Platform'
                            required={false}
                            disabled={false}
                            multiple={true}
                            placeholder=''
                            selectOptions={isEmpty(policy.deviceType) ? [] : editFilter.find((f) => f.id === policy.deviceType).platformList}
                            selected={isEmpty(policy.devicePlatformCodeList) ? [] : policy.devicePlatformCodeList}
                            onChange={(value) => onChangeHandler('devicePlatformCodeList', value)}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className='field-box'>
                        {!isEmpty(editFilter) && (
                          <SelectBox
                            id='policy-country'
                            size='large'
                            shrink={true}
                            label='Country'
                            required={false}
                            disabled={false}
                            isEmpty={true}
                            placeholder=''
                            selectOptions={isEmpty(policy.deviceType) ? [] : editFilter.find((f) => f.id === policy.deviceType).countryList}
                            selected={isEmpty(policy.countryCode) ? '' : policy.countryCode}
                            onChange={(value) => onChangeHandler('countryCode', value)}
                          />
                        )}
                      </Box>
                    </Grid>

                    {/* Comment */}
                    <Grid item xs={12}>
                      <Box className='info-value-wrap'>
                        <Typography component='span' className='label'>
                          Comment
                        </Typography>
                        <Box className='value'>
                          <Typography component='span' className='text'>
                            Automatically generated based on ‘Device Type’, ‘Option Type’, and ‘Sort Order’.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} className={`list-wrap ${false ? 'alignTop' : ''}`}>
                      <Typography component='span' className={`label ${true ? 'required' : ''}`}>
                        {'Version'}
                      </Typography>
                      <Box className='value-wrap'>
                        <Box className='value no-border' display='flex' gap={1.5}>
                          <TextField
                            id='policyMajorVersion'
                            name='policyMajorVersion'
                            type='outlined'
                            size='large'
                            maxWidth={'94px'}
                            inputProps={{ maxLength: 3 }}
                            placeholder=''
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            value={policy.policyMajorVersion}
                            onChange={(event) => versionInputHandler(event)}
                          />
                          <TextField
                            id='policyMinorVersion'
                            name='policyMinorVersion'
                            type='outlined'
                            size='large'
                            maxWidth={'94px'}
                            inputProps={{ maxLength: 3 }}
                            placeholder=''
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            value={policy.policyMinorVersion}
                            onChange={(event) => versionInputHandler(event)}
                          />
                          <TextField
                            id='policyPatchVersion'
                            name='policyPatchVersion'
                            type='outlined'
                            size='large'
                            maxWidth={'94px'}
                            inputProps={{ maxLength: 3 }}
                            placeholder=''
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            value={policy.policyPatchVersion}
                            onChange={(event) => versionInputHandler(event)}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box className='info-value-wrap'>
                        <Typography component='span' className='label required'>
                          Update Version
                        </Typography>
                        <Box className='value'>
                          <Typography component='span' className='text' style={{ color: isValidVersion(policy.definedVersion, policy.policyVersion, policy.upgradedVersionFlag) ? '#000' : 'red' }}>
                            {policy.policyVersion}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className='field-box'>
                        <SelectBox
                          label='Display Option'
                          fullWidth
                          required={true}
                          size='large'
                          disabled={false}
                          isEmpty={true}
                          shrink
                          selectOptions={isEmpty(displayOption) ? [] : displayOption}
                          selected={displayTempOption}
                          onChange={(value) => setDisplayTempOption(value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className='field-box' display='flex' gap={1.5}>
                        <TextField
                          id='displayOrder'
                          name='displayOrder'
                          type='outlined'
                          label='Display Order'
                          inputProps={{ maxLength: 3 }}
                          size='large'
                          placeholder=''
                          defaultValue=''
                          autoComplete='off'
                          required={true}
                          InputLabelProps={{ shrink: true }}
                          value={displayTempOrder}
                          onChange={(event) => numberChangeHandler(event.target.value, setDisplayTempOrder)}
                        />
                        <Button
                          color='tertiary'
                          type='outline'
                          size='medium'
                          label='Add Option'
                          isIcon={false}
                          autosize
                          disabled={displayTempOption && displayTempOrder && displayTempOrder > 0 && !isAddOptionText ? false : true}
                          onClick={addOptionHandler}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  {/* !!!!! 컨텐츠 노출 / 비노출 영역 */}
                  <Box>
                    {/* Option */}
                    <Grid container columnSpacing={2.5}>
                      <Grid item xs={12}>
                        <Box className='content-tab-container'>
                          <Box className='info-value-wrap align-top'>
                            <Typography component='span' className='label'>
                              Option
                            </Typography>
                            {/* // TODO */}
                            <Box className='value no-border'>
                              {policy.multipleDisplay && policy.multipleDisplay.length > 0 && (
                                <Fragment>
                                  {policy.multipleDisplay.map((display) => (
                                    <Box display='flex' gap={3} width={'100%'}>
                                      <RadioGroup value={setAppliedOptionId} className='align-h' onChange={(event) => handleRadioChange(event, display.displayTempId)}>
                                        <CustomRadio
                                          key={display.displayTempId}
                                          value={display.displayTempId}
                                          checked={appliedOptionId === display.displayTempId}
                                          label={display.displayOption + ' : ' + display.displayOrder + ' (Order Number)'}
                                          // onChange={(event) => handleRadioChange(event, display.displayTempId)}
                                          disabled={false}
                                          name='option'
                                        />
                                      </RadioGroup>
                                      <Button
                                        color=''
                                        type='only-icon bg-no'
                                        size='medium'
                                        label=''
                                        isIcon={true}
                                        Icon={darkMode ? ItemDelDark : ItemDelLight}
                                        onClick={(event) => removeOptionHandler(event, display.displayTempId)}
                                      />
                                    </Box>
                                  ))}
                                </Fragment>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>

                      {/* Language */}
                      <Grid item xs={12}>
                        <Box className='content-tab-container'>
                          <Box className='info-value-wrap align-top'>
                            <Typography component='span' className='label'>
                              Language
                            </Typography>
                            <Box className='value no-border'>
                              <Box className='field-box'>
                                {!isEmpty(editFilter) && (
                                  <SelectMultipleBox
                                    id='policy-language'
                                    size='large'
                                    shrink={true}
                                    label='Country'
                                    required={false}
                                    disabled={false}
                                    multiple={true}
                                    placeholder=''
                                    selectOptions={isEmpty(supportLanguage) ? [] : supportLanguage}
                                    selected={isEmpty(multipleLanguageList) ? [] : multipleLanguageList}
                                    onChange={(value) => multipleDisplayLanguageChangeHandler(appliedOptionId, value)}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      {/* Content */}
                      {/* <EditContent></EditContent> */}
                      {!isEmpty(appliedOptionId) && (
                        <Fragment>
                          {policy.multipleDisplay.map((display) => (
                            <Fragment>
                              {display.displayTempId === appliedOptionId && (
                                <EditContent
                                  contentData={
                                    policy.contentList && policy.contentList.map((m) => m.displayTempId === display.displayTempId).length > 0
                                      ? policy.contentList.filter((m) => m.displayTempId === appliedOptionId)
                                      : []
                                  }
                                  contentChangeHandler={contentChangeHandler}></EditContent>
                              )}
                            </Fragment>
                          ))}
                        </Fragment>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
      {(popupType.add || popupType.cancel || popupType.alarm || popupType.success) && (
        <BasicDialog
          title={popupType.title}
          contentTitle={popupType.content}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={closeDialog}
          buttons={popupType.add ? saveActionButtons : popupType.cancel ? cancelActionButtons : popupType.alarm ? [closeButton] : [{ ...closeButton, onClick: returnList }]}
        />
      )}
    </Box>
  );
}

export default PolicyAdd;
