import * as React from 'react';
import { Tabs, Tab, Badge } from '@mui/material';
import styled from '@emotion/styled';

// Styled
const StyledTabs = styled(Tabs)`
  & .MuiTabs-flexContainer {
    border-bottom: 1px solid #ddd;

    .dark & {
      border-bottom: 1px solid #484848;
    }
  }
  /* Type 01 */
  &.type-01 {
    & .MuiTab-root {
      min-width: auto;
      margin: 0 24px;
      padding: 10px 0;
      font-size: 16px;
      color: #838a97;
      font-weight: 400;
      text-transform: initial;
      .dark & {
        color: #fff;
      }
      .MuiBadge-root {
        padding: 0 12px;
      }
      &.Mui-selected {
        color: #a50034;
        font-weight: 700;
      }
    }
    & .MuiTabs-indicator {
      background-color: #a50034;
    }
  }
  /* Type 02 */
  &.type-02 {
    .MuiTab-root {
      min-width: 140px;
      padding: 12px 24px;
      margin-right: 4px;
      border-radius: 8px 8px 0 0;
      border: 1px solid #ddd;
      border-bottom: 0;
      background: #fff;
      .MuiBadge-root {
        font-size: 16px;
        color: #c6c6c6;
        text-transform: none;
      }

      &.Mui-selected {
        background: #525252;
        .MuiBadge-root {
          color: #fff;
        }
      }

      .dark & {
        background: #1b1b1b;
        border-color: #484848;

        .MuiBadge-root {
          color: #909090;
        }
        &.Mui-selected {
          background: #808080;
          .MuiBadge-root {
            color: #fff;
          }
        }
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
`;

// Tabs Component
function BasicTabs({ tabStyle, value, handleChange, tabs, isLabelValue }) {
  return (
    <StyledTabs className={tabStyle} value={value} onChange={handleChange} scrollButtons='auto'>
      {tabs.map((tab, index) => (
        <Tab key={index} value={isLabelValue ? tab.label : index} className={`badge-${tab.badgeType}`} label={<Badge variant='dot'>{tab.label}</Badge>} />
      ))}
    </StyledTabs>
  );
}

export default BasicTabs;
