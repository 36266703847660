import React, {useEffect, useState} from 'react';
import Button from 'components/component/BasicButton';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { AddPlus } from 'components/component/BasicIcon';
import DatePicker from 'components/component/BasicDatePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  CategoryOption, LanguageOption, PlatformOption,
} from 'pages/Organization/DeviceNotification/data/DeviceNotificationData';
import { Box } from '@mui/system';
import { connect } from "react-redux";
import BasicSelectCheckBox from "components/component/BasicSelectCheckBox";
import dayjs from "dayjs";

const EditorContainer = styled(Box)`
  .quill {
    height: calc(100% - 42px);
  }
`;

// 메인 컨텐츠
function PopAddNotificationMobile({ name, onClose, afterSave, country, publishStatus }) {

  const handleClose = () => {
    onClose();
  };

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
    },
  ];

  const [editContent, setEditContent] = useState('');
  const [paramCountry, setParamCountry] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState(PlatformOption.map(item => item.option));
  const [selectedCategory, setSelectedCategory] = useState(CategoryOption[0]?.option);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [titleInput, setTitleInput] = useState('');



  useEffect(() => {
    if (country.regionCountry) {
      let mobileSupportCountries = [
        ...new Set(country.regionCountry.filter((item) => item.deviceType === 'mobile')
        .map(region => region.countryCode))]
      setParamCountry([{ id: 'all', value: 'all', option: 'All', type: 'checkbox', defaultChecked: true }]
      .concat(mobileSupportCountries.map(countryCode => ({
        id: countryCode,
        value: countryCode,
        option: countryCode,
        type: 'checkbox'
      }))));
      setSelectedCountries(mobileSupportCountries);
    }
  }, [country]);

  // eslint-disable-next-line
  const addNotification = () => {
    // eslint-disable-next-line
    const data = {
      title: titleInput,
      deviceCategory: selectedCategory,
      startDate: startDate,
      endDate: endDate,
      countryList: selectedCountries,
      platform: selectedPlatforms,
    }
  }



  const handleSelectedCountries = (country) => {
    if (country) {
      setSelectedCountries([...country]);
    }
  }

  const handleSelectedPlatforms = (platform) => {
    if (platform) {
      setSelectedPlatforms([...platform]);
    }
  }

  return (
    <div>
      <Dialog
        open={true}
        closeX={true}
        title='Add Notification' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <Grid container columnSpacing={1.5}>
              {/* Country* */}
              <Grid item xs={4}>
                <Box component='div' className='field-box'>
                  <BasicSelectCheckBox
                      id='deviceNoti-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={paramCountry}
                      disabled={false}
                      placeholder=''
                      multiple={true}
                      onChange={handleSelectedCountries}
                  />
                </Box>
              </Grid>
              {/* Platform* */}
              <Grid item xs={4}>
                <Box component='div' className='field-box'>
                  <BasicSelectCheckBox
                      id='deviceNoti-Platform'
                      size='large'
                      shrink={true}
                      label='Platform'
                      required={true}
                      selectOptions={PlatformOption}
                      disabled={false}
                      placeholder=''
                      multiple={true}
                      onChange={handleSelectedPlatforms}
                  />
                </Box>
              </Grid>
              {/* Category* */}
              <Grid item xs={4}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='deviceNoti-Category'
                    size='large'
                    shrink={true}
                    label='Category'
                    required={true}
                    selectOptions={CategoryOption}
                    disabled={false}
                    selected={selectedCategory}
                    onChange={setSelectedCategory}

                  />
                </Box>
              </Grid>

              {/* Title */}
              <Grid item xs={12} mt={1}>
                <Box component='div' className='field-box flex-start'>
                  <TextField
                    type='outlined'
                    size='large'
                    placeholder='placeholder'
                    label='Title'
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    value={titleInput}
                    onChange={(e) => setTitleInput(e.target.value)}
                  />
                </Box>
              </Grid>

              {/* Start Date */}
              <Grid item xs={6} mt={1}>
                <Box component='div' className='field-box'>
                  <DatePicker size='large' label='Start Date' shrink singleDate={true}
                              value={startDate} onChange={(newValue) => setStartDate(newValue)} />
                </Box>
              </Grid>
              {/* End Date */}
              <Grid item xs={6} mt={1}>
                <Box component='div' className='field-box'>
                  <DatePicker size='large' label='End Date' shrink singleDate={true}
                              value={endDate} onChange={(newValue) => setEndDate(newValue)} />
                </Box>
              </Grid>

              {/* Language */}
              <Grid item xs={12} mt={1}>
                <Typography className='field-label bold required'>Language</Typography>
                <Box component='div' className='field-box flex-start'>
                  <SelectBox
                    id='select-tab-name-region-1'
                    size='large'
                    maxWidth='170px'
                    required={true}
                    selectOptions={LanguageOption}
                    disabled={false}
                    label=''
                    shrink
                  />

                  <TextField
                    type='outlined'
                    size='large'
                    placeholder='placeholder'
                    defaultValue=''
                    label=''
                    autoComplete='off'
                    required={false}
                    InputLabelProps={{ shrink: false }}
                  />

                  <Button
                    color='tertiary'
                    type='outline icon'
                    size='medium'
                    label=''
                    isIcon={true}
                    Icon={AddPlus}
                    iconPos='center'
                    autosize
                  />
                </Box>
              </Grid>
              <Grid item xs={12} mt={3}>
                <EditorContainer height={230}>
                  <ReactQuill
                    theme='snow'
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                  />
                </EditorContainer>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  country: state.common.country,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PopAddNotificationMobile);
