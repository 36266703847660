import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRequest, deleteRequest } from 'utils/Request';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import TextField from 'components/component/BasicTextField';
import PopAddCategory from 'pages/Organization/TabCategory/PopAddCategory';
import PopEditCategory from 'pages/Organization/TabCategory/PopEditCategory';
import PopAddSchedule from 'pages/Organization/TabCategory/PopAddSchedule';
import PopEditSchedule from 'pages/Organization/TabCategory/PopEditSchedule';
import Tooltip from 'components/component/BasicTooltip';
import Tag from 'components/component/BasicTag';
import BasicDialog from 'components/component/BasicDialog';
import BasicSnackbar from 'components/component/BasicSnackbar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { RefreshIcon, PrimaryInfoIcon, CalendarIcon } from 'components/component/BasicIcon';

import { styled } from '@mui/material/styles';

// Dummy Data //
import {
  CategoryListTh,
} from 'pages/Organization/WebService/data/CategoryListData';


const Buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Reorder List',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Delete',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    disabled: true,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Add Schedule',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Add Category',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Save',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
];

const weekDayOptions = [
  {id: 'MON', value: 'MON', option: 'Monday', type: 'text'},
  {id: 'TUE', value: 'TUE', option: 'Tuesday', type: 'text'},
  {id: 'WED', value: 'WED', option: 'Wednesday', type: 'text'},
  {id: 'THU', value: 'THU', option: 'Thursday', type: 'text'},
  {id: 'FRI', value: 'FRI', option: 'Friday', type: 'text'},
  {id: 'SAT', value: 'SAT', option: 'Saturday', type: 'text'},
  {id: 'SUN', value: 'SUN', option: 'Sunday', type: 'text'},
];
/*
const contentTypeOptions = [
  {id: 'live', value: 'live', option: 'live', type: 'text'},
  {id: 'tvshow', value: 'tvshow', option: 'tvshow', type: 'text'},
  {id: 'movie', value: 'movie', option: 'movie', type: 'text'},
  {id: 'tvshow-series', value: 'tvshow-series', option: 'tvshow-series', type: 'text'},
  {id: 'livestream', value: 'livestream', option: 'livestream', type: 'text'},
];

const mappingTypeOptions = [
  {id: 'manual', value: 'manual', option: 'manual', type: 'text'},
  {id: 'auto', value: 'auto', option: 'auto', type: 'text'},
  {id: 'carousel', value: 'carousel', option: 'carousel', type: 'text'},
  {id: 'slider', value: 'slider', option: 'slider', type: 'text'},
  {id: 'position_manual', value: 'position_manual', option: 'position_manual', type: 'text'},
  {id: 'position_auto', value: 'position_auto', option: 'position_auto', type: 'text'},
];

const categoryTypeOptions = [
  {id: 'common', value: 'common', option: 'common', type: 'text'},
  {id: 'favorite', value: 'favorite', option: 'favorite', type: 'text'},
  {id: 'recent', value: 'recent', option: 'recent', type: 'text'},
  {id: 'livestream', value: 'livestream', option: 'livestream', type: 'text'},
  {id: 'genre', value: 'genre', option: 'genre', type: 'text'},
  {id: 'top', value: 'top', option: 'top', type: 'text'},
  {id: 'rank', value: 'rank', option: 'rank', type: 'text'},
  {id: 'channel_genre', value: 'channel_genre', option: 'channel_genre', type: 'text'},
  {id: 'upcoming', value: 'upcoming', option: 'upcoming', type: 'text'},
];
*/
// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Tooltip 내부에 출력될 자식 컴포넌트 //
const ScheduleTooltip = (props, ref) => {
  return (
    <div className='info-list-box'>
        <div className='info'>
          <Typography className='label'>{`Status : `}</Typography>
          <span className='info-field'>{props.list.scheduleStatus}</span>
        </div>
        <div className='info'>
          <Typography className='label'>{`Schedule : `}</Typography>
          <span className='info-field'>{`${props.list.reqStartDate} ~ ${props.list.reqEndDate}`}</span>
        </div>
    </div>
  );
};

const MappingTypeTooltip = (props, ref) => {
  return (
    <div className='info-list-box'>
        <div className='info'>
          <Typography className='label'>{`Order Type : `}</Typography>
          <span className='info-field'>{props.list.orderType}</span>
        </div>
        <div className='info'>
          <Typography className='label'>{`Rule : `}</Typography>
          <span className='info-field'>{props.list.categoryMappingRule.rule}</span>
        </div>
        <div className='info'>
          <Typography className='label'>{`CP List : `}</Typography>
          <span className='info-field'>{props.list.cpList}</span>
        </div>
    </div>
  );
};

// 메인 컨텐츠
function CategoryList(props) {
  const { message, param, regionList, providers } = props;
  const { tabIdx, region, deviceType, platformVersion, status } = message;
  // Category List 화면 진입 시 Tab 정보를 저장
  const [tab, setTab] = useState({});
  // Category List 화면 진입 시 Categorys 정보를 저장
  const [categorys, setCategorys] = useState([]);
  // 검색 Filter 조건에 맞는 Categorys 정보를 저장
  const [displayCategorys, setDisplayCategorys] = useState([]);

  // 홈앱 편집 가능 유무 (Testing/Published 상태는 편집 수정 불가)
  const [editFlag, setEditFlag] = useState(false);

  // Category List 검색 필터
  const [contentTypeFilter, setContentTypeFilter] = useState('All');
  const [categoryTypeFilter, setCategoryTypeFilter] = useState('All');
  const [mappingTypeFilter, setMappingTypeFilter] = useState('All');
  const [categoryNameFilter, setCategoryNameFilter] = useState('');

  const [maxCategoryCode, setMaxCategoryCode] = useState(0);

  // Search 그리드에 연관검색 키워드 input
  const [searchCategoryFilter, setSearchCategoryFilter] = useState('');
  // Search Tab 연관 검색 시
  useEffect(() => {
    if (searchCategoryFilter) {
      const filteredData = categorys.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchCategoryFilter));
      });
      setDisplayCategorys([...filteredData]);
    } else {
      setDisplayCategorys(categorys);
    }
    // eslint-disable-next-line
  }, [searchCategoryFilter]);

  useEffect(() => {
    // 페이지 진입 및 새로고침 시 또는 카테고리 데이터 변경 시 정보 재호출
    refreshTabCategory();
    getMultiLanguageList();
    getCountrys();
    getGenreCode();
    getHomeAppCode();
    // eslint-disable-next-line
  }, []);

  const [contentTypeOptions, setContentTypeOptions] = useState([]);
  const [mappingTypeOptions, setMappingTypeOptions] = useState([]);
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);

  const getHomeAppCode = async () => {
    const body = await getRequest(`/home-app/home-app-code`, null);
    if (body && body.result === 'SUCCESS') {
      // 카테고리 관련 options
    const contentType = body.data.filter(option => option.code === 'CONTENT_TYPE').map(n => {
      const newItem = {
        id: n.name,
        value: n.name,
        option: n.name,
        type: 'text',
      }
      return newItem;
    })
    setContentTypeOptions(contentType);

    const mappingType = body.data.filter(option => option.code === 'MAPPING_TYPE').map(n => {
      const newItem = {
        id: n.name,
        value: n.name,
        option: n.name,
        type: 'text',
      }
      return newItem;
    })
    setMappingTypeOptions(mappingType);

    const categoryType = body.data.filter(option => option.code === 'CATEGORY_TYPE').map(n => {
      const newItem = {
        id: n.name,
        value: n.name,
        option: n.name,
        type: 'text',
      }
      return newItem;
    })
    setCategoryTypeOptions(categoryType);
    }
  }

  useEffect(() => {
    // tab 정보에 따라 수정,편성 가능 flag 설정
    if (tab) {
      if (tab.status === 'S-T' || tab.status === 'S-P') {
        setEditFlag(true);
      } else {
        setEditFlag(false);
      }
    }
    // eslint-disable-next-line
  }, [tab]);

  useEffect(() => {
    getMaxCategoryNumber();
    if (categorys) {
      setDisplayCategorys(categorys);
    }
    // eslint-disable-next-line
  }, [categorys]);

  useEffect(() => {
    if (providers) {
      const cpList = providers.entire.map(n => {
        const newItem = {
          id: n.providerId,
          value: n.providerId,
          option: n.providerId,
          type: 'text',
        }
        return newItem;
      })
      setProviderOptions(cpList);
    }
    // eslint-disable-next-line
  }, [providers]);

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Category List'],
  };

  // 선택(클릭) Category 정보
  const [categoryInfo, setCategoryInfo] = useState({});
  // 선택(클릭) CategorySchedule 정보
  const [categoryScheduleInfo, setCategoryScheduleInfo] = useState({});
  // 체크박스 체크된 Category의 idx 데이터 저장
  const [checkedCategory, setCheckedCategory] = useState([]);
  // MultiLanguage 전체 정보 select box options
  const [multiLangOptions, setMultiLangOptions] = useState([]);
  // 홈앱 지원 전체 Multi Language 데이터 호출
  const getMultiLanguageList = async () => {
    const body = await getRequest(`/home-app/lang-list`, null);
    if (body && body.result === 'SUCCESS') {
      setMultiLangOptions(body.data);
    }
  };
  const [countryOptions, setCountryOptions] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [genreCodeOptions, setGenreCodeOptions] = useState([]);

  const getGenreCode = async () => {
    const body = await getRequest(`/home-app/category/channel-genre/${param.region}`, null);
    if (body && body.result === 'SUCCESS') {
      // setMultiLangOptions(body.data);
      const genre = body.data.map(n => {
        const newItem = {
          id: n.genreCode,
          value: n.genreCode,
          option: n.genreText,
          type: 'text',
        }
        return newItem;
      })
      setGenreCodeOptions(genre);
    }
  };

  const navigate = useNavigate();

  // PopUp창 노출(Add Category, EditCategory, Schedule)
  const [actionDialog, setActionDialog] = useState("");
  // PopUp창 이벤트 액션 이후에 서버에서 받은 메세지를 출력하는 알림창 기본값
  const [showSnakBar, setShowSnakBar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [params, setParams] = useState({
    order_type: "asc",
    order: "areaOrder",
    region: "",
    country: "",
    status: "",
    page: 1,
    size: 10,
  });

  // 카테고리 목록 그리드 화면에서 check box 이벤트를 컨트롤(선택된 탭의 idx 값을 저장)
  const handleCheckBox = (event) => {
    let idxs = [];
    // 최상단 전체 선택 check box 클릭 시
    if (event.target.checked && event.target.name === "select-all") {
      idxs = displayCategorys.map((row) => row.idx);
    // 최상단 전체 선택 해제 check box 클릭 시
    } else if (!event.target.checked && event.target.name === "select-all") {
      idxs = [];
    // 개별 탭 check box 클릭 시
    } else if (event.target.checked) {
      idxs = [...checkedCategory, parseInt(event.target.name)];
    // 개별 탭 check box 해제 시
    } else if (!event.target.checked) {
      idxs = checkedCategory.filter(
        (row) => row !== parseInt(event.target.name)
      );
    }
    setCheckedCategory(idxs);
  };

  // 서버 메세지를 띄운 알림창을 close 버튼 클릭 했을 때
  const handleSnackbarClose = () => {
    setShowSnakBar({ open: false, message: "", severity: "" });
  };

  // 카테고리 명 링크를 클릭 시 수정 팝업을 open하고 선택한 정보를 매개객체로 전달
  const handleCategoryClick = (row) => {
    // 선택한 정보를 전체정보에서 필터링하여 seleteRow에 저장
    const seletedRow = displayCategorys.find(n => n.idx === row.idx);
      // editCategory 객체는 선택된 탭의 데이터를 저장
      setCategoryInfo(seletedRow);
      if (seletedRow) {
        // edit category 팝업 open
        setActionDialog('Edit Category');
      }
  };

  // 카테고리 row에 특정 schedule 아이콘 클릭 시 수정 팝업을 open하고 선택한 정보를 매개객체로 전달
  const handleCategoryScheduleClick = (column) => {
    // 선택한 정보를 전체정보에서 필터링하여 seleteRow에 저장
    if (editFlag) {
      const seletedRow = displayCategorys.find(n => n.idx === column.categoryIdx).categorySchedules.find(n => n.idx === column.idx);
      setCategoryScheduleInfo(seletedRow);
      if (seletedRow) {
        // edit schedule 팝업 open
        setActionDialog('Edit Schedule');
      }
    }
  };

  // Search Filter 선택 시
  // content type filter
  const searchContentTypeFilter = (value) => {
    setContentTypeFilter(value);
  };
  // content type filter
  const searchCategoryTypeFilter = (value) => {
    setCategoryTypeFilter(value);
  };
  // mapping type filter
  const searchMappingTypeFilter = (value) => {
    setMappingTypeFilter(value);
  };
  // category name filter
  const searchCategoryNameFilter = (event) => {
    const value = event.target.value;
    setCategoryNameFilter(value);
  };
  // Search Filter 선택 시

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayCategorys].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayCategorys([...sortedData]);
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // history back 이전 페이지
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/TabCategoryList', { replace: true, state:
      { move: true,
        targetComponent: 'TabCategoryList',
        message:
        {
          param: param,
        }
      }
    });
  };

  // Reorder List 버튼 클릭 시 페이지 전환
  const handleReorderButtonClick = () => {
    let openComponent = 'CategoryListReorder';

    navigate('/TabCategoryList', {
      replace: true, state: {
        move: true,
        targetComponent: openComponent,
        message: {
          tabIdx: tabIdx,
          tab: tab,
          region: region,
          deviceType: deviceType,
          platformVersion: platformVersion,
          status: status,
        }
      }
    });
  };

  // Delete 버튼은 check box 체크된 탭이 있을 경우 활성화
  const customButtons = useMemo(() => {
    return Buttons.map((button) => {
      button.onClick = () => handleButtonClick(button.label);
      if (button.label === "Delete") {
        if (editFlag) {
          button.disabled = checkedCategory.length !== 0 ? false : true;
        } else {
          button.disabled = true;
        }
      }
      if (button.label === "Add Schedule") {
        if (editFlag) {
          button.disabled = checkedCategory.length !== 0 ? false : true;
        } else {
          button.disabled = true;
        }
      }
      if (button.label === "Add Category") {
        if (editFlag) {
          button.disabled = false;
        } else {
          button.disabled = true;
        }
      }
      if (button.label === "Reorder List") {
        if (editFlag) {
          button.disabled = false;
          button.onClick = handleReorderButtonClick;
        } else {
          button.disabled = true;
        }
      }
      if (button.label === "Save") {
        if (editFlag) {
          button.disabled = false;
        } else {
          button.disabled = true;
        }
      }
      return button;
    });
    // eslint-disable-next-line
  }, [tab][checkedCategory]);

  // 팝업창 관리(label 매개값을 전달 받으면 해당 값으로 정의된 팝업창을 open)
  const handleButtonClick = (label) => {
    setActionDialog(label);
  };

  // open된 팝업창에서 Cancel 버튼을 클릭하면 해당 팝업을 닫음
  const onActionCancelBtn = () => {
    setActionDialog("");
  };

  // 팝업창 안에서 save 이벤트를 수행 후 서버에서 받은 메세지 값들을 알림창으로 띄워서 전달
  const handelSetShowSnakBar = ({ open, message, severity }) => {
    setShowSnakBar({
      open,
      message,
      severity,
    });
    setParams({ ...params });
  };

  // Organize 버튼 클릭 시 카테고리 Mapping Type에 따라 이동되는 페이지 선택
  const handleOrganizeButtonClick = (row) => {
    let openComponent = 'ManualOrganize';

    if (row.mappingType === 'auto') {
      openComponent = 'AutoOrganize';
    } else if (row.mappingType === 'carousel') {
      openComponent = 'CarouselOrganize';
    } else if (row.mappingType === 'slider') {
      openComponent = 'SliderOrganize';
    } else if (row.categoryType === 'upcoming') {
      openComponent = 'UpcomingOrganize';
    }

    navigate('/TabCategoryList', {
      replace: true, state: {
        move: true,
        targetComponent: openComponent,
        message: {
          tabIdx: tabIdx,
          categoryIdx: row.idx,
          tab: tab,
          category: row,
          countryOptions: countryOptions,
          providerOptions: providerOptions,
          region: region,
          deviceType: deviceType,
          platformVersion: platformVersion,
          status: status,
        }
      }
    });
  };

  // Reset 버튼 클릭
  const resetFilter = () => {
    setContentTypeFilter('All');
    setCategoryTypeFilter('All');
    setMappingTypeFilter('All');
    setCategoryNameFilter('');

    setDisplayCategorys(categorys);
  };

  // Search 버튼 클릭
  const searchData = () => {
    const searchCategory = categorys.reduce((acc, cur) => {
      const contentTypeCondition = contentTypeFilter !== 'All'
        ? cur.contentType.includes(contentTypeFilter)
        : true;
      const categoryTypeCondition = categoryTypeFilter !== 'All'
        ? cur.categoryType === categoryTypeFilter
        : true;
      const mappingTypeCondition = mappingTypeFilter !== 'All'
        ? cur.mappingType === mappingTypeFilter
        : true;
      const categoryNameCondition = categoryNameFilter !== ''
        ? cur.categoryName.toLowerCase().includes(categoryNameFilter.toLowerCase())
        : true;

      if (contentTypeCondition &&
          categoryTypeCondition &&
          mappingTypeCondition &&
          categoryNameCondition) {
            acc.push(cur);
          }
          return acc;
    }, []);

    setDisplayCategorys(searchCategory);
  };

  // CountryOptions 생성
  // store 에 저장된 메인의 device, region 정보로 regionList 에서 해당 국가를 추출
  // Schedule(수정,삭제), Auto/Manual/Upcoming/Carousel/Slider Organize 시 사용
  const getCountrys = () => {
    const regionInDevice = regionList.find(n => n.deviceType === param.deviceType).list
                                     .filter(n => n.region === param.region)[0].countryCode;
    const countryInRegion = regionInDevice.map(n => {
      const newItem = {
        id: n,
        value: n,
        option: n,
        type: 'text',
      }
      return newItem;
    })
    setCountryOptions(countryInRegion);
  }

  // Add Category시 필요한 카테고리 코드의 최대 값을 전달
  const getMaxCategoryNumber = () => {
    let maxCategoryCode = Math.max(...categorys.filter(n => n.categoryCode && !n.categoryCode.startsWith('C-')).map(n2 => n2.categoryCode.slice(1))) || 0;
    if (maxCategoryCode === -Infinity || maxCategoryCode === Infinity) {
      maxCategoryCode = 0;
    }
    setMaxCategoryCode(maxCategoryCode);
  }

  // Add Category시 필요한 카테고리 순서의 최대 값을 전달
  const defaultReOrderCategoryRow = () => {
    let indexCount = 1;
    categorys.filter(n => n.categoryType === 'top').map((element, index) => (element.categoryOrder = index + indexCount));

    indexCount = 11;
    categorys.filter(n => n.categoryType !== 'top').map((element, index) => (element.categoryOrder = index + indexCount));

    categorys.sort((a, b) => a.categoryOrder - b.categoryOrder);
    return categorys;
  };

  // 카테고리 관련 생성/수정/삭제 등 이벤트 발생 시 categorys를 재호출
  const refreshTabCategory = async () => {
    const body = await getRequest(`/home-app/tab/${tabIdx}`, null);
    setTab(body.data);
    setCategorys(body.data.categorys);
    // setCategoryList(body.data.categorys);
    setCheckedCategory([]);
  }

  // SearchCategory 연관 검색창에 검색어 입력 시
  const handleSearchCategory = (value) => {
    setSearchCategoryFilter(value);
  };

  // Delete Button 클릭 시 check box 선탠된 탭을 삭제
  const onDeleteOKBtn = async () => {
    if (checkedCategory.length > 1) {
      alert('Please select one category.');
    } else if (checkedCategory.length === 1) {
      const categoryData = {
        devicetype: deviceType,
        region: region,
        platformVersion: platformVersion,
        status: status,
        tabCode: tab.tabCode,
        categoryCode: categorys.find(n => n.idx === checkedCategory[0]).categoryCode,
        categoryIdx: checkedCategory[0],
      };
      const body = await deleteRequest(`/home-app/category`, categoryData, null);
      if (body && body.result === 'SUCCESS') {
        refreshTabCategory();
      }
      onActionCancelBtn();
      handelSetShowSnakBar({
        open: true,
        message: body.message,
        severity: body.severity,
      });
    }
  };

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // Paging 처리
  useEffect(() => {
    if (displayCategorys && displayCategorys.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayCategorys.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [displayCategorys, page, itemsPerPage]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  let sortedPrTdRows = sortRows(
    [...displayCategorys],
    params.order,
    params.order_type
  );

  // Search Filter Buttons
  const FilterButtons = [
    {
      color: 'tertiary',
      type: 'outline icon',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      autosize: false,
      minWidth: '107px',
      onClick: resetFilter,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: false,
      minWidth: '120px',
      onClick: searchData,
    },
  ];

  // Delete 버튼 클릭 시 해당 팝업의 버튼을 정의
  const deleteActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onDeleteOKBtn,
    },
  ];

  // TableBody Row
  // const sortedBnrTdRows = sortRows([...displayCategorys], orderBy, order);

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' onClick={handleBack}>
                Tab / Category
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                  <React.Fragment key='1'>
                    <ListItem className='list-item'>
                      <Typography className='label'>#Tab</Typography>
                      <Typography className='value'>{tab.tabName}</Typography>
                    </ListItem>
                    {true && <Divider className='divider' />}
                  </React.Fragment>
                  <React.Fragment key='2'>
                    <ListItem className='list-item'>
                      <Typography className='label'>#Random</Typography>
                      <Typography className='value'>{tab.randomOrderFlag === 'Y' ? 'On' : 'Off'}</Typography>
                    </ListItem>
                    {true && <Divider className='divider' />}
                  </React.Fragment>
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Content Type */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Content Type'
                      required={false}
                      selectOptions={[{id: 'All', value: 'All', option: 'All', type: 'text'}, ...contentTypeOptions]}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={contentTypeFilter}
                      onChange={searchContentTypeFilter}
                    />
                  </Box>
                  {/* Category Type */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Category Type'
                      required={false}
                      selectOptions={[{id: 'All', value: 'All', option: 'All', type: 'text'}, ...categoryTypeOptions]}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={categoryTypeFilter}
                      onChange={searchCategoryTypeFilter}
                    />
                  </Box>
                  {/* Mapping Type*/}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Mapping Type'
                      required={false}
                      selectOptions={[{id: 'All', value: 'All', option: 'All', type: 'text'}, ...mappingTypeOptions]}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={mappingTypeFilter}
                      onChange={searchMappingTypeFilter}
                    />
                  </Box>

                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Name'
                      value={categoryNameFilter}
                      label=''
                      autoComplete='off'
                      onChange={searchCategoryNameFilter}
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'>
                <CustomButtonGroup buttons={FilterButtons} />
              </Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      {displayCategorys.length}
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SearchField
                      id='search-1'
                      placeholder='Please enter a search term'
                      maxWidth='230px'
                      onChange={(event) => handleSearchCategory(event.target.value)}
                    />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={customButtons} />
                </Box>
                {/* right-area */}
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {CategoryListTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  checked={categorys.length !== 0 && sortedPrTdRows.length === checkedCategory.length}
                                  onChange={handleCheckBox}
                                  indeterminate={categorys.length !== 0 && sortedPrTdRows.length !== checkedCategory.length}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {paginatedData.map((row, index) => (
                        <TableRow className='tr' key={row.id || index}>
                          {CategoryListTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <Box display='flex' gap='3px'>
                                  {/* CheckBox 컴포넌트 */}
                                  <CheckBox
                                    label={row.categoryOrder} // 데이터의 텍스트를 label로 사용
                                    value={row.idx}
                                    onChange={handleCheckBox}
                                    checked={checkedCategory.indexOf(row.idx) > -1}
                                  />

                                  {row.status && (
                                    <Tag type='testing' label='Unsaved' badgeType='' sx={{ marginLeft: '2px' }} />
                                  )}
                                </Box>
                              ) : colIndex === 1 ? (
                                row.categoryCode
                              ) : colIndex === 2 ? (
                                (editFlag) ? (
                                <>
                                  <span onClick={() => handleCategoryClick(row)} style={{ color: '#2196f3', cursor: 'pointer' }} >
                                    {row.categoryName}
                                  </span>
                                </>
                                )
                                :
                                (
                                  row.categoryName
                                )
                              ) : colIndex === 3 ? (
                                row.contentType
                              ) : colIndex === 4 ? (
                                <>
                                  <Typography
                                    component='span'
                                    sx={{ verticalAlign: 'bottom' }}
                                  >
                                    {row.mappingType}
                                  </Typography>
                                  {/* Auto이면서 Auto Rule 셋팅값이 있을 경우에만 Tooltip 출력 */}
                                  {(row.mappingType === 'auto' && row.categoryMappingRule) && (
                                    <>
                                      {/* Icon Tooltip */}
                                      <Tooltip title={<MappingTypeTooltip list={row} />} placement='top'>
                                        <IconButton
                                          size='small'
                                          sx={{ width: '24px', height: '24px', marginLeft: '4px' }}
                                        >
                                          <PrimaryInfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </>
                              ) : colIndex === 5 ? (
                                row.categoryType
                              ) : colIndex === 6 ? (
                                row.fixFlag === 'Y' ? 'on' : 'off'
                              ) : colIndex === 7 ? (
                                <>
                                  {row.categorySchedules && (
                                    row.categorySchedules.map((column, colIndex) => (
                                      <>
                                      <Typography
                                        component='span'
                                        sx={{
                                          color:
                                              column.scheduleStatus === 'Completed'
                                              ? '#0D9488 !important'
                                              : '#525252',
                                          verticalAlign: 'bottom',
                                        }}
                                      >
                                        {column.scheduleStatus}
                                      </Typography>
                                      {/* Icon Tooltip */}
                                      {column.idx !== null && (
                                        <div>
                                          <Tooltip title={<ScheduleTooltip list={column} />} placement='top'>
                                            <IconButton
                                              size='small'
                                              sx={{ width: '24px', height: '24px', marginLeft: '4px' }}
                                            >
                                              <CalendarIcon onClick={() => handleCategoryScheduleClick(column)} />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      )}
                                    </>
                                    ))
                                  )}
                                </>
                              ) : colIndex === 8 ? (
                                (editFlag) ? (
                                <>
                                {row.mappingType !== '' && (
                                  <>
                                    <Button
                                    color='tertiary'
                                    type='outline'
                                    size='small'
                                    label='ORGANIZE'
                                    isIcon={false}
                                    Icon=''
                                    iconPos={undefined}
                                    onClick={() => handleOrganizeButtonClick(row)}
                                    autosize
                                    />
                                  </>
                                )}
                                </>
                                )
                                :
                                (
                                  <div></div>
                                )
                              ) : (
                                row.categoryIdx
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
                <div>
                  {actionDialog === 'Edit Category' && (
                    <PopEditCategory
                      categoryInfo={categoryInfo}
                      contentTypeOptions={contentTypeOptions}
                      mappingTypeOptions={mappingTypeOptions}
                      categoryTypeOptions={categoryTypeOptions}
                      multiLangOptions={multiLangOptions}
                      genreCodeOptions={genreCodeOptions}
                      onClose={() => setActionDialog('')}
                      handelSetShowSnakBar={handelSetShowSnakBar}
                      refreshTabCategory={refreshTabCategory}
                    />
                  )}
                </div>
                <div>
                  {actionDialog === 'Add Category' && (
                    <PopAddCategory
                      tabIdx={tab.idx}
                      contentTypeOptions={contentTypeOptions}
                      mappingTypeOptions={mappingTypeOptions}
                      categoryTypeOptions={categoryTypeOptions}
                      multiLangOptions={multiLangOptions}
                      genreCodeOptions={genreCodeOptions}
                      maxCategoryCode={maxCategoryCode}
                      defaultReOrderCategoryRow={defaultReOrderCategoryRow}
                      onClose={() => setActionDialog('')}
                      handelSetShowSnakBar={handelSetShowSnakBar}
                      refreshTabCategory={refreshTabCategory}
                    />
                  )}
                </div>
                <div>
                  {actionDialog === 'Add Schedule' && (
                    <PopAddSchedule
                      categoryIdx={checkedCategory[0]}
                      countryOptions={countryOptions}
                      weekDayOptions={weekDayOptions}
                      onClose={() => setActionDialog('')}
                      handelSetShowSnakBar={handelSetShowSnakBar}
                      refreshTabCategory={refreshTabCategory}
                    />
                  )}
                </div>
                <div>
                  {actionDialog === 'Edit Schedule' && (
                    <PopEditSchedule
                      categoryScheduleInfo={categoryScheduleInfo}
                      countryOptions={countryOptions}
                      weekDayOptions={weekDayOptions}
                      onClose={() => setActionDialog('')}
                      handelSetShowSnakBar={handelSetShowSnakBar}
                      refreshTabCategory={refreshTabCategory}
                    />
                  )}
                </div>
                <div>
                  {(actionDialog === 'Delete') &&
                  <BasicDialog
                    title={actionDialog === 'Delete' ? 'Delete Category' : ''}
                    contentTitle={actionDialog === 'Delete' ? ' Are you sure to delete?' : ''}
                    size={'small'}
                    closeX={true}
                    open={true}
                    handleClose={() => setActionDialog('')}
                    buttons={actionDialog === 'Delete' ? deleteActionButtons : []}
                  />}
                </div>
                <div>
                  <BasicSnackbar onClose={handleSnackbarClose} severity={showSnakBar.severity} message={showSnakBar.message} open={showSnakBar.open} duration={3000} />
                </div>
              </Box>
              {/* Pagination */}
              <Pagination count={Math.ceil(displayCategorys.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CategoryList.propTypes = {
  param: PropTypes.object.isRequired,
  regionList: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  param: state.homeapp.param,
  regionList: state.homeapp.regionList,
  providers: state.common.provider,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
