import React, {useEffect, useState} from 'react';
import Dialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import { Grid, Box, Typography } from '@mui/material';
import { connect } from "react-redux";

import {getHeaders} from "../../../utils/Header";
import {BASE_URL} from "../../../constants";

// 메인 컨텐츠
function PopAddCPName({ closeAddCp, afterSave, provider }) {

  const handleClose = () => {
    closeAddCp();
  };

  const boolOptions = [
    { id: 'Y', value: 'Y', option: 'Y', type: 'text' },
    { id: 'N', value: 'N', option: 'N', type: 'text' }
  ]

  const [providerIdInput, setProviderIdInput] = useState('');
  const [providerNameInput, setProviderNameInput] = useState('');
  const [appIdInput, setAppIdInput] = useState('');
  const [selectedCpParents, setSelectedCpParents] = useState('');
  const [selectedLgPlayerFlag, setSelectedLgPlayerFlag] = useState('N');
  const [interactiveUrlInput, setInteractiveUrlInput] = useState('');
  const [cpLogoUrlInput, setCpLogoUrlInput] = useState('');
  const [allCpOption, setAllCpOption] = useState([]);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (provider && provider.entire) {
      let map = provider.entire.map(cp => ({ id: cp.providerId, value: cp.providerId, option: cp.providerId, type: 'text' }));
      setAllCpOption([{ id: 'None', value: 'None', option: 'None', type: 'text' },...map]);
    }
  }, [provider]);

  const createCp = () => {
    if (providerIdInput === '') {
      setAlertTitle('Error');
      setAlertMessage('Please enter CP Code.');
      setAlertOpen(true);
      return;
    }
    if (providerNameInput === '') {
      setAlertTitle('Error');
      setAlertMessage('Please enter CP Name.');
      setAlertOpen(true);
      return;
    }
    if (appIdInput === '') {
      setAlertTitle('Error');
      setAlertMessage('Please enter App ID.');
      setAlertOpen(true);
      return;
    }

    const provider = {
      providerId: providerIdInput,
      providerName: providerNameInput,
      appId: appIdInput,
      parentProviderId: selectedCpParents,
      lgPlayerFlag: selectedLgPlayerFlag === 'Y',
      interactiveUrl: interactiveUrlInput,
      logoUrl: cpLogoUrlInput
    };

    fetch(`${BASE_URL}/code-management/cp`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(provider)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success');
        setAlertMessage('CP has been created successfully.');
        setAlertOpen(true);
      } else {
        setAlertTitle('Error');
        setAlertMessage(body.message);
        setAlertOpen(true);
      }
    })
    .finally(() => {
      handleClose();
      afterSave();
    })
  }

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      disabled: false,
      onClick: createCp
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div>
      <Dialog
        open={true}
        closeX={true}
        title='Add CP Name' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-addChannel'
        contentTextProps={
          <Grid container item xs={12}>
            {/* CP Code */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <TextField
                  id='cp-code'
                  type='outlined'
                  size='large'
                  placeholder='ex)RKT'
                  label='CP Code'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={providerIdInput}
                  onChange={(e) => setProviderIdInput(e.target.value)}
                />
                <Typography className='input-sub-text'>CP Code must be capitalized 3-5 characters, e.g ABC</Typography>
              </Box>
            </Grid>

            {/* CP Name */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <TextField
                  id='cp-name'
                  type='outlined'
                  size='large'
                  placeholder='ex)Rakuten'
                  label='CP Name'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={providerNameInput}
                  onChange={(e) => setProviderNameInput(e.target.value)}
                />
              </Box>
            </Grid>

            {/* App ID */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <TextField
                    id='cp-appid'
                    type='outlined'
                    size='large'
                    placeholder='ex)rakuten'
                    label='App ID'
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    value={appIdInput}
                    onChange={(e) => setAppIdInput(e.target.value)}
                />
              </Box>
            </Grid>

            {/* CP Parents */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='admin-Parents'
                  size='large'
                  shrink={true}
                  label='CP Parents'
                  required={false}
                  selectOptions={allCpOption}
                  disabled={false}
                  placeholder='Select'
                  selected={selectedCpParents}
                  onChange={setSelectedCpParents}
                />
              </Box>
            </Grid>

            {/* Use LGPlayer* */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='admin-LGPlayer'
                  size='large'
                  shrink={true}
                  label='Use LGPlayer'
                  required={true}
                  selectOptions={boolOptions}
                  disabled={false}
                  placeholder='Select'
                  selected={selectedLgPlayerFlag}
                  onChange={setSelectedLgPlayerFlag}
                />
              </Box>
            </Grid>

            {/* Interactive URL */}
            <Grid item xs={12} mt={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='text'
                  label='Interactive URL'
                  size='large'
                  fullWidth
                  placeholder='Http://'
                  name=''
                  id='InteractiveURL'
                  required={false}
                  error={false}
                  InputLabelProps={{ shrink: true }}
                  value={interactiveUrlInput}
                  onChange={(e) => setInteractiveUrlInput(e.target.value)}
                />
                <Typography className='input-sub-text'>
                  If LG Player is not used, this Interactive URL is mandatory
                </Typography>
              </Box>
            </Grid>

            {/* CP Logo URL */}
            <Grid item xs={12} mt={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='text'
                  label='CP Logo URL'
                  size='large'
                  fullWidth
                  placeholder='Http://'
                  name=''
                  id='LogoURL'
                  required={true}
                  error={false}
                  InputLabelProps={{ shrink: true }}
                  value={cpLogoUrlInput}
                  onChange={(e) => setCpLogoUrlInput(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        }
        buttons={dialogButtons}
      />
      <Dialog
          open={isAlertOpen}
          handleClose={handleAlertClose}
          closeX={false}
          size='small'
          title={alertTitle}
          contentTitle={false}
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  provider: state.common.provider,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PopAddCPName);
