// src/data.js
import imgDummy01 from 'assets/images/img_dummy_04.png';
import imgDummy02 from 'assets/images/img_dummy_02.png';
//const carouselTypeList = ['channel', 'vod', 'more', 'hub', 'notification', 'event'];
export const carouselTypeList = [
  { id: 'Channel', value: 'channel', option: 'Channel', type: 'text' },
  { id: 'VOD', value: 'vod', option: 'VOD', type: 'text' },
  { id: 'More', value: 'more', option: 'More', type: 'text' },
  { id: 'Hub', value: 'hub', option: 'Hub', type: 'text' },
  { id: 'Notification', value: 'notification', option: 'Notification', type: 'text' },
  { id: 'Event', value: 'event', option: 'Event', type: 'text' },
];

export const contentTypeList = [
  { id: 'live', value: 'live', option: 'live', type: 'text', carouselType: 'channel' },
  { id: 'tvshow', value: 'tvshow', option: 'tvshow', type: 'text', carouselType: 'vod' },
  { id: 'movie', value: 'movie', option: 'movie', type: 'text', carouselType: 'vod' },
  { id: 'tvshow-series', value: 'tvshow-series', option: 'tvshow-series', type: 'text', carouselType: 'vod' },
  { id: 'livestream', value: 'livestream', option: 'livestream', type: 'text', carouselType: 'vod' },
  { id: 'tab', value: 'tab', option: 'tab', type: 'text', carouselType: 'hub' },
];

export const deepLinkCarouselTypeList = [
  { id: 'NoLink', value: 'noLink', option: 'No Deep Link', type: 'text' },
  { id: 'Channel', value: 'channel', option: 'Channel', type: 'text' },
  { id: 'VOD', value: 'vod', option: 'VOD', type: 'text' },
  { id: 'Hub', value: 'hub', option: 'Hub', type: 'text' },
  { id: 'Notification', value: 'notification', option: 'Notification', type: 'text' },
];

// TODO DELETE
export const CarouselTbodyTh = [
  {
    id: 'contentTh-01',
    align: 'left',
    label: 'CP Name',
    width: '8%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'contentTh-02',
    align: 'left',
    label: 'Content ID',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentTh-03',
    align: 'left',
    label: 'Content Name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentTh-04',
    align: 'left',
    label: 'Image',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'contentTh-05',
    align: 'left',
    label: 'Map',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const CarouselTbodyTd = [
  {
    id: 'CarouselCh-1',
    data01: 'AMG1',
    data02: '123gospanish_amg02051c18eng_AR',
    data03: '123 GO!',
    data04: '',
    data05: 'AR-ME-6.0.0(T)',
  },
  {
    id: 'CarouselCh-2',
    data01: 'PLT',
    data02: '5dcb62e63d4d8f0009f36881',
    data03: 'Pluto TV Cine Acción',
    data04: imgDummy01,
    data05: 'AR-ME-6.0.0(T)',
  },
  {
    id: 'CarouselCh-3',
    data01: 'PLT',
    data02: '5dcb62e63d4d8f0009f36881',
    data03: 'Pluto TV Cine Acción',
    data04: imgDummy01,
    data05: 'AR-ME-6.0.0(T)',
  },
];

export const SelectedOrgContentTd = [
  {
    id: 'order-1',
    data01: '1',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
  {
    id: 'order-2',
    data01: '2',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
  {
    id: 'order-3',
    data01: '3',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
  {
    id: 'order-4',
    data01: '4',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
];

export const Region = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Eu', value: 'Eu', option: 'Eu', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];
export const CarouselTypeOption = [
  { id: 'Channel', value: 'Channel', option: 'Channel', type: 'text' },
  { id: 'VOD', value: 'VOD', option: 'VOD', type: 'text' },
  { id: 'Hub', value: 'Hub', option: 'Hub', type: 'text' },
  { id: 'Category', value: 'Category', option: 'Category', type: 'text' },
  { id: 'Collection', value: 'Collection', option: 'Collection', type: 'text' },
  { id: 'Notification', value: 'Notification', option: 'Notification', type: 'text' },
  { id: 'Event', value: 'Event', option: 'Event', type: 'text' },
];
export const CarouselTVShoweOption = [
  { id: 'VOD-1', value: 'VOD', option: 'VOD', type: 'text' },
  { id: 'VOD-2', value: 'VOD-2', option: 'VOD-2', type: 'text' },
  { id: 'VOD-3', value: 'VOD-3', option: 'VOD-3', type: 'text' },
];
export const CarouselCPNametOption = [
  { id: 'VOD-1', value: 'VOD', option: 'VOD', type: 'text' },
  { id: 'VOD-2', value: 'VOD-2', option: 'VOD-2', type: 'text' },
  { id: 'VOD-3', value: 'VOD-3', option: 'VOD-3', type: 'text' },
];
export const CarouselContentOption = [
  { id: 'Live', value: 'Movie', option: 'Movie', type: 'text' },
  { id: 'Tvshow', value: 'Tvshow', option: 'Tvshow', type: 'text' },
  { id: 'Tvshow-Series', value: 'Tvshow-Series', option: 'Tvshow-Series', type: 'text' },
  { id: 'Livestream', value: 'Livestream', option: 'Livestream', type: 'text' },
];
export const ContentTvShowOption = [
  { id: 'TvShow-1', value: 'TvShow', option: 'TvShow', type: 'text' },
  { id: 'TvShow-2', value: 'Live-2', option: 'TvShow-2', type: 'text' },
  { id: 'TvShow-3', value: 'Live-3', option: 'TvShow-3', type: 'text' },
];
export const CarouselCPNameOption = [
  { id: 'CPName-1', value: 'All', option: 'All', type: 'text' },
  { id: 'CPName-2', value: 'CPName-2', option: 'CPName-2', type: 'text' },
  { id: 'CPName-3', value: 'CPName-3', option: 'CPName-3', type: 'text' },
];

export const selOptionCountryData = [
  { id: 'country1', value: 'US', option: 'US', type: 'text' },
  { id: 'country2', value: 'FR', option: 'FR', type: 'text' },
  { id: 'country3', value: 'GR', option: 'GR', type: 'text' },
];

export const programData = {
  TabCategory: 'Home/CAROUSEL',
  Country: 'US',
  ContentID: 'notification_US_20240626-004926',
  ContentName: 'LG Channels Now on Your Phone!',
  CPName: 'LG Channels Now on Your Phone!',
  CarouselType: 'Notification',
};

export const SelectedCarouselTh = [
  {
    id: 'Order',
    align: 'left',
    label: 'Order',
    width: '24%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Carousel',
    align: 'left',
    label: 'Carousel',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Type',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ContentID',
    align: 'left',
    label: 'Content ID',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CarouselContentName',
    align: 'left',
    label: 'Content Name',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Fix',
    align: 'center',
    label: 'Fix',
    width: '9%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Image',
    align: 'left',
    label: 'Image',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Vertical',
    align: 'left',
    label: 'Vertical',
    width: '13%',
    checkbox: false,
    sortable: false,
  },
];

export const SelectedCarouselTd = [
  {
    id: 'SelectedCarouselTd-1',
    data01: '1',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'SelectedCarouselTd-2',
    data01: '2',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
];

export const CarouselVodTh = [
  {
    id: 'VodCPName',
    align: 'left',
    label: 'CP Name',
    width: '8%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Vodcontent',
    align: 'left',
    label: 'Content ID',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'VodContentName',
    align: 'left',
    label: 'Content Name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'VodContentTh',
    align: 'left',
    label: 'Image',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
];

export const CarouselVodTd = [
  {
    id: 'CarouselVod-1',
    data01: 'XUMO',
    data02: 'XM0C6LNWFGWKNK',
    data03: 'The Wedding Video',
    data04: '',
  },
  {
    id: 'CarouselVod-2',
    data01: 'XUMO',
    data02: 'XM0C6LNWFGWKNK',
    data03: '123 GO!',
    data04: '',
  },
  {
    id: 'CarouselVod-3',
    data01: 'XUMO',
    data02: 'XM0C6LNWFGWKNK',
    data03: '123 GO!',
    data04: '',
  },
];

export const CarouselCollectionTh = [
  {
    id: 'TabID',
    align: 'left',
    label: 'Tab ID',
    width: '8%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Tabname',
    align: 'left',
    label: 'Tab name',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Tab',
    align: 'left',
    label: 'Tab',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryType',
    align: 'left',
    label: 'Category Type',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
];

export const CarouselCollectionTd = [
  {
    id: 'Collection-1',
    data01: 'C-FAVORITE',
    data02: 'Local News Channels test',
    data03: 'Live',
    data04: 'common',
  },
  {
    id: 'Collection-2',
    data01: 'C-FAVORITE',
    data02: 'Local News Channels test',
    data03: 'Live',
    data04: 'common',
  },
  {
    id: 'Collection-3',
    data01: 'C-FAVORITE',
    data02: 'Local News Channels test',
    data03: 'Live',
    data04: 'common',
  },
];

export const SelectedCarouselVodTd = [
  {
    id: 'SelectedCarouselTd-1',
    data01: '1',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '',
  },
  {
    id: 'SelectedCarouselTd-2',
    data01: '2',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'SelectedCarouselTd-3',
    data01: '3',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
];
export const SelectedCarouselTvTd = [
  {
    id: 'CarouselTv1',
    data01: '1',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'CarouselTv2',
    data01: '2',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: imgDummy02,
  },
  {
    id: 'CarouselTv3',
    data01: '3',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'CarouselTv4',
    data01: '4',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'CarouselTv5',
    data01: '5',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'CarouselTv6',
    data01: '6',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
];

export const CarouselMoreTh = [
  {
    id: 'VodCPName',
    align: 'left',
    label: 'Tab ID',
    width: '8%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Vodcontent',
    align: 'left',
    label: 'Tab Name',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'VodContentName',
    align: 'left',
    label: 'Tab',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'VodContentTh',
    align: 'left',
    label: 'Category Type',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
];

export const CarouselMoreTd = [
  {
    id: 'CarouselMore1',
    data01: 'C-Favorite',
    data02: 'Local News Channel Test',
    data03: 'Live',
    data04: 'Common',
  },
  {
    id: 'CarouselMore2',
    data01: 'C-Favorite',
    data02: 'Local News Channel Test',
    data03: 'Live',
    data04: 'Common',
  },
  {
    id: 'CarouselMore3',
    data01: 'C-Favorite',
    data02: 'Local News Channel Test',
    data03: 'Live',
    data04: 'Common',
  },
];

export const CarouselEventTh = [
  {
    id: 'eventChk',
    align: 'left',
    label: 'Event ID',
    width: '20%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'eventTitle',
    align: 'left',
    label: 'Event Title',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'eventPublish',
    align: 'left',
    label: 'Publish',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'eventHorizontal',
    align: 'left',
    label: 'Horizontal',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'eventEpisode',
    align: 'left',
    label: 'Episode No.',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'eventFocus',
    align: 'left',
    label: 'Focus',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'eventLanding',
    align: 'left',
    label: 'Landing',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
];
export const CarouselEventTd = [
  {
    id: 'CarouselEvent1',
    data01: '202309130426077',
    data02: 'LOST IN DREAMS 2023',
    data03: 'On',
    data04: imgDummy02,
    data05: imgDummy02,
    data06: imgDummy02,
    data07: 'channel',
  },
  {
    id: 'CarouselEvent2',
    data01: '202309130426077',
    data02: 'LOST IN DREAMS 2023',
    data03: 'On',
    data04: '',
    data05: '',
    data06: '',
    data07: 'notification',
  },
  {
    id: 'CarouselEvent3',
    data01: '202309130426077',
    data02: 'LOST IN DREAMS 2023',
    data03: 'On',
    data04: '',
    data05: '',
    data06: '',
    data07: '',
  },
  {
    id: 'CarouselEvent4',
    data01: '202309130426077',
    data02: 'LOST IN DREAMS 2023',
    data03: 'On',
    data04: '',
    data05: '',
    data06: '',
    data07: '',
  },
  {
    id: 'CarouselEvent5',
    data01: '202309130426077',
    data02: 'LOST IN DREAMS 2023',
    data03: 'On',
    data04: '',
    data05: '',
    data06: '',
    data07: '',
  },
];

export const CarouselNoticeTh = [
  {
    id: 'VodCPName',
    align: 'left',
    label: 'Content  ID',
    width: '10%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Vodcontent2',
    align: 'left',
    label: 'Content Name',
    width: '22%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Vodcontent3',
    align: 'left',
    label: 'Start Date',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Vodcontent4',
    align: 'left',
    label: 'End Date',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'VodContent5',
    align: 'left',
    label: 'Category Type',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const CarouselNoticeTd = [
  {
    id: 'CarouselNotice1',
    data01: 'notification_US_20240613-060757',
    data02: 'LG Channles with ThinQ App',
    data03: '2024-06-12',
    data04: '2024-06-12',
    data05: 'US-ME-6.0.0 +5',
  },
  {
    id: 'CarouselNotice2',
    data01: 'notification_US_20240613-060757',
    data02: 'LG Channles with ThinQ App',
    data03: '2024-06-12',
    data04: '2024-06-12',
    data05: 'US-ME-6.0.0 +5',
  },
];

export const CarouselHubTh = [
  {
    id: 'tabCode',
    align: 'left',
    label: 'Tab Code',
    width: '35%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'tabName',
    align: 'left',
    label: 'Tab Name',
    width: '40%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'enable',
    align: 'left',
    label: 'Enable',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const CarouselHubTd = [
  {
    id: 'CarouselHubTd-1',
    data01: 'T-MY',
    data02: 'My',
    data03: 'On',
  },
  {
    id: 'CarouselHubTd-2',
    data01: 'T-MY',
    data02: 'My',
    data03: 'On',
  },
  {
    id: 'CarouselHubTd-3',
    data01: 'T-MY',
    data02: 'My',
    data03: 'On',
  },
];

export const CarouselCategoryTh = [
  {
    id: 'categoryCode',
    align: 'left',
    label: 'Category Code',
    width: '20%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'categoryName',
    align: 'left',
    label: 'Category Name',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentType',
    align: 'left',
    label: 'Content Type',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'mappingType',
    align: 'left',
    label: 'Mapping Type',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'categoryType',
    align: 'left',
    label: 'Category Type',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
];
export const CarouselCategoryTd = [
  {
    id: 'categoryCode1',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode2',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode3',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode4',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode5',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode6',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode7',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode8',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode9',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode10',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode11',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'top',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'categoryCode12',
    data01: 'C-favorite',
    data02: 'My Favorite',
    data03: 'top',
    data04: 'slider',
    data05: 'top',
  },
];

export const OrgScheduledTh = [
  {
    id: 'OrgSchedule-Order',
    align: 'left',
    label: 'Order',
    width: '16%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'OrgSchedule-Name',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'OrgSchedule-chid',
    align: 'left',
    label: 'Content ID',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'OrgSchedule-CName',
    align: 'left',
    label: 'Content Name',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'OrgSchedule-StartTime',
    align: 'left',
    label: 'Start Time',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'OrgSchedule-EndTime',
    align: 'left',
    label: 'End Time',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'OrgSchedule-Modifier',
    align: 'left',
    label: 'Image',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'OrgSchedule-Genre',
    align: 'left',
    label: 'Genre',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
];

export const OrgScheduledTd = [
  {
    id: 'OrgScheduledTd-row-1',
    data01: '1',
    data02: 'AMG',
    data03: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data04: 'Xumo FreeMovies LG DRMTest Channel',
    data05: '2024-09-20 19:00:00',
    data06: '2024-09-20 19:00:00',
    data07: '',
    data08: 'sport +1',
  },
  {
    id: 'OrgScheduledTd-row-2',
    data01: '1',
    data02: 'AMG',
    data03: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data04: 'Xumo FreeMovies LG DRMTest Channel',
    data05: '2024-09-20 19:00:00',
    data06: '2024-09-20 19:00:00',
    data07: '',
    data08: 'sport +1',
  },
  {
    id: 'OrgScheduledTd-row-3',
    data01: '1',
    data02: 'AMG',
    data03: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data04: 'Xumo FreeMovies LG DRMTest Channel',
    data05: '2024-09-20 19:00:00',
    data06: '2024-09-20 19:00:00',
    data07: '',
    data08: 'sport +1',
  },
];

export const CarouselHubCategoryTh = [
  {
    id: 'hc-cateCode',
    align: 'left',
    label: 'Category Code',
    width: '24%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'hc-cateyName',
    align: 'left',
    label: 'Category Name',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'hc-contentType',
    align: 'left',
    label: 'Content Type',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'hc-MappingType',
    align: 'left',
    label: 'Mapping Type',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'hc-Categorytype',
    align: 'left',
    label: 'Category type',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const CarouselHubCategoryTd = [
  {
    id: 'CarouselHubCategoryTd-1',
    data01: 'C-Favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'CarouselHubCategoryTd-2',
    data01: 'C-Favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
  {
    id: 'CarouselHubCategoryTd-3',
    data01: 'C-Favorite',
    data02: 'My Favorite',
    data03: 'live',
    data04: 'slider',
    data05: 'top',
  },
];

export const ScheduledHubCategoryTh = [
  {
    id: 'Order',
    align: 'left',
    label: 'Order',
    width: '18%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Carousel',
    align: 'left',
    label: 'Carousel',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Type',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ContentID',
    align: 'left',
    label: 'Content ID',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CarouselContentName',
    align: 'left',
    label: 'Content Name',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Fix',
    align: 'center',
    label: 'Fix',
    width: '9%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Image',
    align: 'left',
    label: 'Image',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Vertical',
    align: 'left',
    label: 'Vertical',
    width: '13%',
    checkbox: false,
    sortable: false,
  },
];

export const ScheduledHubCategoryTd = [
  {
    id: 'CarouselTv1',
    data01: '1',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'CarouselTv2',
    data01: '2',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: imgDummy02,
  },
  {
    id: 'CarouselTv3',
    data01: '3',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'CarouselTv4',
    data01: '4',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'CarouselTv5',
    data01: '5',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
  {
    id: 'CarouselTv6',
    data01: '6',
    data02: 'Channel',
    data03: 'Live',
    data04: '9992398',
    data05: 'Xumo Free Movies LG DRM Test Channel',
    data06: '',
    data07: '',
    data08: '-',
  },
];

export const ResultProgramTh = [
  {
    id: 'PopChNo',
    align: 'left',
    label: 'CP Name',
    width: '18%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'PopChName',
    align: 'left',
    label: 'Program ID',
    width: '33%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'PopChID',
    align: 'left',
    label: 'Program Name',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'PopDMAGroupName',
    align: 'left',
    label: 'Start Time',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const ResultProgramTd = [
  {
    id: 'ResultProgramTd-1',
    data01: 'AMG',
    data02: 'e9914a701b469dbe0385ab87ff51cba7996b479b315-91bd17cf',
    data03: 'A Rainy Day in New York',
    data04: '2024-09-20 19:00:00',
  },
  {
    id: 'ResultProgramTd-2',
    data01: 'AMG',
    data02: 'e9914a701b469dbe0385ab87ff51cba7996b479b315-91bd17cf',
    data03: 'A Rainy Day in New York',
    data04: '2024-09-20 19:00:00',
  },
  {
    id: 'ResultProgramTd3',
    data01: 'AMG',
    data02: 'e9914a701b469dbe0385ab87ff51cba7996b479b315-91bd17cf',
    data03: 'A Rainy Day in New York',
    data04: '2024-09-20 19:00:00',
  },
];
