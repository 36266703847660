export const CountryTimeOption = [
  { id: '-12:00', value: '-12:00', option: '-12:00', type: 'text' },
  { id: '-11:00', value: '-11:00', option: '-11:00', type: 'text' },
  { id: '-10:00', value: '-10:00', option: '-10:00', type: 'text' },
  { id: '-9:00', value: '-9:00', option: '-9:00', type: 'text' },
  { id: '-8:00', value: '-8:00', option: '-8:00', type: 'text' },
  { id: '-7:00', value: '-7:00', option: '-7:00', type: 'text' },
  { id: '-6:00', value: '-6:00', option: '-6:00', type: 'text' },
  { id: '-5:00', value: '-5:00', option: '-5:00', type: 'text' },
  { id: '-4:00', value: '-4:00', option: '-4:00', type: 'text' },
  { id: '-3:00', value: '-3:00', option: '-3:00', type: 'text' },
  { id: '-2:00', value: '-2:00', option: '-2:00', type: 'text' },
  { id: '-1:00', value: '-1:00', option: '-1:00', type: 'text' },
  { id: '+0:00', value: '+0:00', option: '+0:00', type: 'text' },
  { id: '+1:00', value: '+1:00', option: '+1:00', type: 'text' },
  { id: '+2:00', value: '+2:00', option: '+2:00', type: 'text' },
  { id: '+3:00', value: '+3:00', option: '+3:00', type: 'text' },
  { id: '+4:00', value: '+4:00', option: '+4:00', type: 'text' },
  { id: '+5:00', value: '+5:00', option: '+5:00', type: 'text' },
  { id: '+6:00', value: '+6:00', option: '+6:00', type: 'text' },
  { id: '+7:00', value: '+7:00', option: '+7:00', type: 'text' },
  { id: '+8:00', value: '+8:00', option: '+8:00', type: 'text' },
  { id: '+9:00', value: '+9:00', option: '+9:00', type: 'text' },
  { id: '+10:00', value: '+10:00', option: '+10:00', type: 'text' },
  { id: '+11:00', value: '+11:00', option: '+11:00', type: 'text' },
  { id: '+12:00', value: '+12:00', option: '+12:00', type: 'text' }
];

export const RICOption = [
  { id: 'AIC', value: 'AIC', option: 'AIC', type: 'text' },
  { id: 'EIC', value: 'EIC', option: 'EIC', type: 'text' },
  { id: 'KIC', value: 'KIC', option: 'KIC', type: 'text' },
];

export const CPOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'r-1', value: 'Role', option: 'Role', type: 'text' },
  { id: 'r-2', value: 'Role2', option: 'Role2', type: 'text' },
];

export const ManagementOption = [
  { id: 'tab', value: 'tab', option: 'tab', type: 'text' },
  { id: 't-1', value: 'tab1', option: 'tab1', type: 'text' },
  { id: 't-2', value: 'tab2', option: 'tab2', type: 'text' },
];
export const ManagementHomeAppOption = [
  { id: 'm-type-1', value: 'HomeApp-Region', option: 'HomeApp-Region', type: 'text' },
  { id: 'm-type-2', value: 'app2', option: 'app2', type: 'text' },
  { id: 'm-type-3', value: 'app3', option: 'app3', type: 'text' },
];
export const ManagementVodGenreOption = [
  { id: 'VodGenre-1', value: 'VodGenre1', option: 'Vod-Genre', type: 'text' },
  { id: 'VodGenre-2', value: 'VodGenre2', option: 'VodGenre2', type: 'text' },
  { id: 'VodGenre-3', value: 'VodGenre3', option: 'VodGenre3', type: 'text' },
];
export const codeAreaOption = [
  { id: 'Area', value: 'Area', option: 'Area', type: 'text' },
  { id: 'a-2', value: 'Area2', option: 'Area2', type: 'text' },
];

export const DeviceTypeOption = [
  { id: 'dtha-1', value: 'TV', option: 'TV', type: 'text' },
  { id: 'dtha-2', value: 'Phone', option: 'Phone', type: 'text' },
];
export const PrimaryOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'player-1', value: 'Role', option: 'Role', type: 'text' },
];

export const SecondaryOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'player-1', value: 'Role', option: 'Role', type: 'text' },
];
